import withIntl from "../utils/withIntl";
import { createMuiTheme, Grid, ThemeProvider, Typography } from "@material-ui/core";
import mynOberStore from "../utils/flux/MynOberStore";
import GetCurrency from "../utils/functions/GetCurrency";
import { OrderTypes } from "../utils/order/Order";
import React from "react";
import { getTaPostalCode } from "../utils/merchant/TaPostalCode";
import OpeningHoursTakeawayInfoScreen from "./OpeningHours/OpeningHoursTakeawayInfoScreen";

const theme = createMuiTheme();

theme.typography.body1 = {
  fontSize: "0.6rem",
  "@media (min-width:320px)": {
    fontSize: "0.8rem",
  },
  "@media (min-width:360px)": {
    fontSize: "0.9rem",
  },
  "@media (min-width:400px)": {
    fontSize: "1rem",
  },
  // [theme.breakpoints.up("md")]: {
  //   fontSize: "2.4rem",
  // },
};

function TakeawayDeliveryInfo(props) {
  const minAmount = (orderType, taMerchant, taPostalCode) => {
    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        return taMerchant.minAmount;
      case OrderTypes.DELIVERY:
        if (taPostalCode) {
          return taPostalCode.minAmount;
        } else {
          return taMerchant.minAmount;
        }
      default:
        return 0;
    }
  };
  const amountWithExtraCosts = (orderType, taMerchant, taPostalCode) => {
    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        return taMerchant.amountWithExtraCosts;
      case OrderTypes.DELIVERY:
        if (taPostalCode) {
          return taPostalCode.amountWithExtraCosts;
        } else {
          return taMerchant.amountWithExtraCosts;
        }
      default:
        return 0;
    }
  };

  const extraCosts = (orderType, taMerchant, taPostalCode) => {
    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        return taMerchant.extraCosts;
      case OrderTypes.DELIVERY:
        if (taPostalCode) {
          return taPostalCode.extraCosts;
        } else {
          return taMerchant.extraCosts;
        }
      default:
        return 0;
    }
  };

  const taPostalCode = getTaPostalCode(mynOberStore.contactDetails.postalCode);
  const deliveryExtraCosts =
    taPostalCode != null ? extraCosts(OrderTypes.DELIVERY, mynOberStore.merchant, taPostalCode) : null;
  const deliveryAmountWithExtraCosts =
    taPostalCode != null ? amountWithExtraCosts(OrderTypes.DELIVERY, mynOberStore.merchant, taPostalCode) : null;
  const deliveryMinAmount =
    taPostalCode != null ? minAmount(OrderTypes.DELIVERY, mynOberStore.merchant, taPostalCode) : null;

  const minTakeawayOrderingAmount = minAmount(OrderTypes.TAKEAWAY, mynOberStore.merchant);
  return (
    <Grid container spacing={1}>
      {mynOberStore.merchant.takeaway && !props.onlyHours && (
        <Grid item xs={12} md={6}>
          <Typography style={{ fontWeight: 800 }}>Takeaway</Typography>
          <Typography>{props.intl.formatMessage({ id: "text_min_wait_time" })}:</Typography>
          <Typography>{mynOberStore.merchant.taMinimumPickupMinutes} min</Typography>
          {minTakeawayOrderingAmount > 0 && (
            <>
              <Typography>{props.intl.formatMessage({ id: "text_min_takeaway_ordering_amount" })}:</Typography>
              <Typography>{GetCurrency() + " " + (minTakeawayOrderingAmount / 100).toFixed(2)}</Typography>
            </>
          )}
        </Grid>
      )}
      {mynOberStore.merchant.delivery && !props.onlyHours && (
        <>
          {taPostalCode != null ? (
            <Grid item xs={12} md={6}>
              <Typography style={{ fontWeight: 800 }}>
                {props.intl.formatMessage(
                  { id: "text_delivery_costs_for" },
                  { postalCode: mynOberStore.contactDetails.postalCode }
                )}
              </Typography>
              <Typography>{props.intl.formatMessage({ id: "text_min_wait_time" })}:</Typography>
              <Typography>{mynOberStore.merchant.deMinimumPickupMinutes} min</Typography>
              <Typography>{props.intl.formatMessage({ id: "text_min_delivery_ordering_amount" })}:</Typography>
              <Typography>{GetCurrency() + " " + (deliveryMinAmount / 100).toFixed(2)}</Typography>
              <Typography>{props.intl.formatMessage({ id: "text_delivery_costs" })}:</Typography>
              <Typography>{GetCurrency() + " " + (deliveryExtraCosts / 100).toFixed(2)}</Typography>
              {deliveryAmountWithExtraCosts > 0 && (
                <>
                  <Typography>{props.intl.formatMessage({ id: "text_delivery_costs_free_from" })}:</Typography>
                  <Typography>{GetCurrency() + " " + (deliveryAmountWithExtraCosts / 100).toFixed(2)}</Typography>
                </>
              )}
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Typography style={{ fontWeight: 800 }}>Delivery</Typography>
              <Typography>{props.intl.formatMessage({ id: "there_is_no_postal_code" })}</Typography>
            </Grid>
          )}
        </>
      )}
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
          <OpeningHoursTakeawayInfoScreen
            onSave={() => {}}
            takeawayOpeningHours={mynOberStore.merchant.takeawayOrderHours}
            deliveryOpeningHours={mynOberStore.merchant.deliveryOrderHours}
          />
        </Grid>
      </ThemeProvider>
      {!props.onlyHours && mynOberStore.merchant.info?.contact_details_merchant && (
        <Grid item xs={12} lg={6}>
          <Typography style={{ fontWeight: 800, marginTop: 16 }}>Restaurant</Typography>
          <Typography>{mynOberStore.merchant.info?.contact_details_merchant?.address}</Typography>
          <Typography>
            {mynOberStore.merchant.info?.contact_details_merchant?.postal_code}{" "}
            {mynOberStore.merchant.info?.contact_details_merchant?.city}
          </Typography>
          <Typography>{mynOberStore.merchant.info?.contact_details_merchant?.phone_number}</Typography>
          <Typography>{mynOberStore.merchant.info?.contact_details_merchant?.email}</Typography>
        </Grid>
      )}
    </Grid>
  );
}

export default withIntl(TakeawayDeliveryInfo);
