import React, { Component } from "react";
import "react-phone-number-input/style.css";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-number-input";
import mynOberStore from "../../../utils/flux/MynOberStore";
import { TaMerchant } from "../../../utils/merchant/TaMerchant";
import InputTextFieldPhoneNumber from "./InputTextFieldPhoneNumber";
import Typography from "@material-ui/core/Typography";

class InputPhoneNumber extends Component {
  constructor(props) {
    super(props);

    this.extraMessage = null;

    if (mynOberStore.merchant instanceof TaMerchant) {
      if (mynOberStore.merchant.info != null) {
        if (mynOberStore.merchant.info.phoneExtraMessage != null) {
          this.extraMessage = mynOberStore.merchant.info.phoneExtraMessage;
        }
      }
    }

    this.textFieldRef = React.createRef();
  }

  onMobileNumberChange = (number) => {
    this.props.onChange(number);
  };

  render() {
    const options = mynOberStore.merchant.countryCodes ?? ["NL", "BE", "DE"];
    const defaultCountry =
      mynOberStore.merchant.countryCodes?.[0] ??
      navigator.language.substr(navigator.language.length - 2, 2).toUpperCase();
    const country = defaultCountry;

    return (
      <div
        style={{
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography>{this.props.intl.formatMessage({ id: this.props.header })}</Typography>
        <div style={{ marginBottom: "8px", maxWidth: "331px" }}>
          <PhoneInput
            options={options}
            defaultCountry={defaultCountry}
            country={country}
            value={this.props.value}
            onChange={this.onMobileNumberChange}
            inputComponent={InputTextFieldPhoneNumber}
          />

          {this.extraMessage != null && (
            <Typography style={{ fontSize: "0.8em" }}>
              {this.props.intl.formatMessage({ id: this.extraMessage })}
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

InputPhoneNumber.propTypes = {
  onChange: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired,
  value: PropTypes.string,
  header: PropTypes.string.isRequired,
};

export default injectIntl(InputPhoneNumber);
