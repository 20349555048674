import React from "react";
import withIntl from "../utils/withIntl";
import mynOberStore from "../utils/flux/MynOberStore";
import { IconButton } from "@material-ui/core";
import { AccessTime, InfoOutlined, Restaurant, RoomService } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { useDialog } from "./dialogs/DialogProvider";
import DialogInhouseInfo from "./dialogs/DialogInhouseInfo";
import CountDownTimer from "./handheld/CountDownTimer";
import Typography from "@material-ui/core/Typography";
import { getApplicationDate } from "../utils/functions/HelperFunctions";

const useStyles = makeStyles(() => ({
  div: {
    display: "flex",
    flexFlow: "wrap",
    alignItems: "center",
  },
  text: {
    marginLeft: "8px",
  },
  tooltip: {
    fontSize: "0.9rem",
  },
}));

function SpInfoView(props) {
  const classes = useStyles();
  const [openDialog] = useDialog();

  const maxWeight = mynOberStore.merchant.maxWeight;
  const weight = mynOberStore.order.weight;

  return (
    <div style={{ display: "flex", flexFlow: "wrap", marginTop: mynOberStore.isWidescreen ? 0 : 4 }}>
      <div className={classes.div}>
        {mynOberStore.merchant.info?.info_message && (
          <div style={{ width: "100%" }}>
            <IconButton
              onClick={() => {
                openDialog({ children: <DialogInhouseInfo /> });
              }}
            >
              <InfoOutlined />
            </IconButton>
          </div>
        )}

        {mynOberStore.receipt.spAyceCovers?.length > 0 && (
          <div style={{ display: "flex", margin: 8 }}>
            <AccessTime style={{ margin: 8 }} />

            <div>
              {mynOberStore.receipt.ayceTimeLimit > 0 ? (
                <CountDownTimer
                  seconds={Math.round(mynOberStore.receipt.ayceTimeLimit - getApplicationDate().getTime() / 1000)}
                />
              ) : (
                <Typography style={{ fontWeight: 800 }}>∞</Typography>
              )}
              <Typography style={{ fontSize: "0.8em" }}>{props.intl.formatMessage({ id: "Time" })}</Typography>
            </div>
          </div>
        )}
        {mynOberStore.receipt.spAyceCovers?.length > 0 && (
          <div style={{ display: "flex", margin: 8, borderLeft: "1px solid #88888855" }}>
            <Restaurant style={{ margin: 8 }} />

            <div>
              {mynOberStore.receipt.maxRounds > 0 ? (
                <Typography style={{ fontWeight: 800 }}>
                  {Math.min(mynOberStore.receipt.rounds + 1, mynOberStore.receipt.maxRounds)} /{" "}
                  {mynOberStore.receipt.maxRounds}
                </Typography>
              ) : (
                <Typography style={{ fontWeight: 800 }}>{mynOberStore.receipt.rounds + 1} / ∞</Typography>
              )}
              <Typography style={{ fontSize: "0.8em" }}>{props.intl.formatMessage({ id: "Rounds" })}</Typography>
            </div>
          </div>
        )}
        {mynOberStore.receipt.spAyceCovers?.length > 0 && (
          <div style={{ display: "flex", margin: 8, borderLeft: "1px solid #88888855" }}>
            <RoomService style={{ margin: 8 }} />

            <div>
              <Typography style={{ fontWeight: 800 }}>
                {weight} / {maxWeight}
              </Typography>
              <Typography style={{ fontSize: "0.8em" }}>{props.intl.formatMessage({ id: "Dishes" })}</Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(withIntl(SpInfoView));
