import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";

function DialogSuccess(props) {
  const [, closeDialog] = useDialog();
  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_success" })}</DialogTitle>
      <DialogContent>{props.intl.formatMessage({ id: props.content })}</DialogContent>
      <DialogActions>
        <Button style={{ width: "240px" }} variant={"outlined"} onClick={closeDialog}>
          {props.intl.formatMessage({ id: "button_ok" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withIntl(DialogSuccess);
