export class Modifiergroup {
  /**
   *
   * @param {number} id
   * @param {number} maxN
   * @param {number[]} modifierarticleIds
   * @param {boolean} multiple
   * @param {string} name
   * @param {boolean} numerable
   * @param {boolean} optional
   * @param {number} priority
   */
  constructor(id, maxN, modifierarticleIds, multiple, name, numerable, optional, priority) {
    this.id = id;
    this.maxN = maxN;
    this.modifierarticleIds = modifierarticleIds;
    this.multiple = multiple;
    this.name = name;
    this.numerable = numerable;
    this.optional = optional;
    this.priority = priority;
  }
}
