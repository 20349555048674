import { ReceiptArticle } from "./ReceiptArticle";

export class Bill {
  constructor(spReceiptId = null, spMerchantId, receipt) {
    this.id = spReceiptId;
    this.receiptArticles = [];
    this.spMerchantId = spMerchantId;
    this.receipt = receipt;
  }

  /**
   *
   * @return {number}
   */
  get amount() {
    let totalAmount = 0;

    this.receiptArticles.forEach((receiptArticle) => {
      totalAmount += receiptArticle.amount;
    });

    return totalAmount;
  }

  /**
   *
   * @returns {number}
   */
  get amountClosed() {
    let totalAmount = 0;

    this.receiptArticles.forEach((receiptArticle) => {
      totalAmount += receiptArticle.amountClosed;
    });

    return totalAmount;
  }

  /**
   *
   * @returns {number}
   */
  get amountOpen() {
    let totalAmount = 0;

    this.receiptArticles.forEach((receiptArticle) => {
      totalAmount += receiptArticle.amountOpen;
    });

    return totalAmount;
  }

  /**
   *
   * @param {ReceiptArticle} receiptArticleToAdd
   */
  addReceiptArticleToReceipt(receiptArticleToAdd) {
    let indexReceiptArticle = this.receiptArticles.findIndex((receiptArticle: ReceiptArticle) =>
      receiptArticle.equals(receiptArticleToAdd)
    );
    if (indexReceiptArticle >= 0) {
      this.receiptArticles[indexReceiptArticle].count += receiptArticleToAdd.count;
      this.receiptArticles[indexReceiptArticle].countClosed += receiptArticleToAdd.countClosed;
    } else {
      this.receiptArticles.push(receiptArticleToAdd);
    }
  }
}
