import React from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import mynOberStore from "../../../utils/flux/MynOberStore";
import GetCurrency from "../../../utils/functions/GetCurrency";
import { MenuItem, Select } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

function InputPaymentMethod(props) {
  return (
    <div>
      <Typography className={"input-text"}>{props.intl.formatMessage({ id: "label_method" })}</Typography>
      <Select
        margin={"dense"}
        fullWidth
        style={{ maxWidth: "331px" }}
        variant={"outlined"}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.selectedPaymentMethod.key}
        disabled={props.paymentMethods.length <= 1 && props.selectedPaymentMethod.key != null}
      >
        {props.paymentMethods.map((value, index) => (
          <MenuItem value={value.key} key={index}>
            {props.intl.formatMessage({
              id: value.description != null ? value.description : value.name != null ? value.name : value.key,
            })}
            {value.extraPercentage > 0 &&
            (mynOberStore.order.amount < value.amountWithExtraCosts || value.amountWithExtraCosts === 0)
              ? " + " + value.extraPercentage + "%"
              : ""}
            {value.extraCosts > 0 &&
            (mynOberStore.order.amount < value.amountWithExtraCosts || value.amountWithExtraCosts === 0)
              ? " + " +
                GetCurrency("€") +
                parseFloat(value.extraCosts / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

InputPaymentMethod.propTypes = {
  paymentMethods: PropTypes.array.isRequired,
  selectedPaymentMethod: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default injectIntl(InputPaymentMethod);
