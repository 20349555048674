import React, { useEffect } from "react";
import MobileNumberShow from "../components/web/ViewPhoneNumber";
import * as listeners from "../utils/Listeners";
import * as requestFunctions from "../utils/functions/RequestFunctions";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { SpMerchant } from "../utils/merchant/SpMerchant";
import "./ReceiptPage.css";
import HeaderPage from "../components/HeaderPage";
import { useDialog } from "../components/dialogs/DialogProvider";
import DialogChangeMobileNumber from "../components/dialogs/DialogChangeMobileNumber";
import DialogError from "../components/dialogs/DialogError";
import DialogOk from "../components/dialogs/DialogOk";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import OrdergroupOverview from "../components/handheld/smartphone/OrdergroupOverview";
import mynOberStore from "../utils/flux/MynOberStore";
import withIntl from "../utils/withIntl";
import { localStore } from "../utils/local_storage/LocalStorage";
import { AyceMerchant } from "../utils/merchant/AyceMerchant";

function ReceiptPage(props) {
  const [phoneNumber, setPhoneNumber] = React.useState(props.receipt.phoneNumber);
  const [openDialog] = useDialog();
  const [showBill, setShowBill] = React.useState(localStore.getItem("receiptPageShowBill") !== "false");

  let showPhoneNumber = false;
  if (props.merchant instanceof SpMerchant) {
    showPhoneNumber = props.merchant.canNotifications && props.hasReceiptAtCurrentMerchant;
  }

  // --------------------------------------------------

  const onMobileNumberChangeClicked = () => {
    openDialog(
      {
        children: (
          <DialogChangeMobileNumber
            onOkClicked={(phoneNumber) => {
              requestFunctions
                .sendPhoneNumber(phoneNumber, props.merchant)
                .then(() => {
                  openDialog({
                    children: <DialogOk title={"alert_title_success"} content={"alert_text_success_phone_number"} />
                  });
                })
                .catch((text) => {
                  openDialog({ children: <DialogError content={text} /> });
                });
            }}
          />
        )
      },
      true
    );
  };

  const onPhoneNumberChanged = () => {
    setPhoneNumber(props.receipt.phoneNumber);
  };

  useEffect(() => {
    const onMoveRequestSuccessful = () => {
      openDialog({
        children: <DialogOk title={"alert_title_success"} content={"alert_text_success_move_request"} />
      });
    };

      requestFunctions.getReceipt(false, null).catch((text) => {
        openDialog({ children: <DialogError content={text} /> });
      });


    listeners.registerMoveListeners(onMoveRequestSuccessful);
    listeners.registerPhoneNumberListeners(onPhoneNumberChanged);

    return () => {
      listeners.unregisterMoveListeners(onMoveRequestSuccessful);
      listeners.unregisterPhoneNumberListeners(onPhoneNumberChanged);
    };
  }, []);

  // --------------------------------------------------
  let roundNumber = 0;
  let ordergroupViews = [];
  if (!showBill) {
    let ordergroups = mynOberStore.receipt.ordergroups.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      }
      if (a.createdAt > b.createdAt) {
        return 1;
      }

      return 0;
    });
    ordergroups = ordergroups.filter((ordergroup) => {
      let found = false;
      for (let i = 0; i < ordergroup.receiptArticles.length; i++) {
        let receiptArticle = ordergroup.receiptArticles[i];
        if (receiptArticle.count > 0) {
          found = true;
          break;
        }
      }
      return found;
    });
    ordergroupViews = ordergroups.map((ordergroup, index) => {
      if (
        (ordergroup.isUser && !mynOberStore.merchant instanceof AyceMerchant) ||
        (ordergroup.isUser && ordergroup.isAyceOrdergroup)
      ) {
        roundNumber++;
        return (
          <OrdergroupOverview
            handheldOrdergroup={ordergroup}
            roundNumber={roundNumber}
            key={ordergroup.id}
            isFirst={index === ordergroups.length - 1}
          />
        );
      } else {
        return (
          <OrdergroupOverview
            handheldOrdergroup={ordergroup}
            key={ordergroup.id}
            isFirst={index === ordergroups.length - 1}
          />
        );
      }
    });

    ordergroupViews = ordergroupViews.reverse();
  }

  return (
    <div className={"receipt-page"}>
      {props.HeaderPage}

      <div className={"receipt-page-phone"}>
        {showPhoneNumber && (
          <MobileNumberShow
            stringText={"text_phone_number_show"}
            phoneNumber={phoneNumber}
            onClickChange={onMobileNumberChangeClicked}
            stringButton={"button_change"}
          />
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <RadioGroup
          style={{ flexDirection: "row" }}
          value={showBill ? "bill" : "orders"}
          onChange={(event) => {
            if (event.target.value === "bill") {
              if (!showBill) {
                setShowBill(true);
                localStore.setItem("receiptPageShowBill", true);
              }
            } else {
              if (showBill) {
                setShowBill(false);
                localStore.setItem("receiptPageShowBill", false);
              }
            }
          }}
        >
          <FormControlLabel
            value="bill"
            control={<Radio color={"primary"} />}
            label={props.intl.formatMessage({ id: "show_bill" })}
          />
          <FormControlLabel
            value="orders"
            control={<Radio color={"primary"} />}
            label={props.intl.formatMessage({ id: "show_orders" })}
          />
        </RadioGroup>
      </div>
      {showBill ? (
        <div className={"receipt-page-content"}>
          {props.children}
          {props.receiptArticleViewsClosed.length > 0 ? (
            <div>
              <HeaderPage headerStringId={"already_closed"} headerStringData={{}} />
              <div id={"receipt-articles-list"} className={"list-receipt-articles"}>
                {props.receiptArticleViewsClosed}
              </div>
            </div>
          ) : null}
        </div>
      ) : (
        <div className={"receipt-page-content"}>{ordergroupViews}</div>
      )}

      {props.hasReceiptAtCurrentMerchant ? (
        <div id={"receipt-page-bottom"} className={"receipt-page-bottom position-sticky sticky-footer"}>
          {props.ButtonReceiptActions}
        </div>
      ) : null}
    </div>
  );
}

ReceiptPage.propTypes = {
  ButtonReceiptActions: PropTypes.element.isRequired,
  merchant: PropTypes.object.isRequired,
  receipt: PropTypes.object.isRequired,
  hasReceiptAtCurrentMerchant: PropTypes.bool.isRequired,
  HeaderPage: PropTypes.element.isRequired,
  receiptArticleViewsClosed: PropTypes.array,
  webcode: PropTypes.string
};

export default withIntl(withRouter(ReceiptPage));
