import { HandheldReceipt } from "../receipt/HandheldReceipt";
import { HandheldOrdergroup } from "../receipt/HandheldOrdergroup";
import { processJsonReceiptArticle } from "./JsonProcessingFunctions";
import TaReceipt from "../receipt/TaReceipt";

export function processHandheldReceipt(jsonReceipt) {
  let menuTags = [];

  let handheldReceipt = new HandheldReceipt(
    jsonReceipt.id,
    jsonReceipt.party_id,
    jsonReceipt.table_id,
    [],
    jsonReceipt.updated_at,
    menuTags,
    jsonReceipt.status,
    jsonReceipt.sp_merchant_id,
    jsonReceipt.created_at,
    jsonReceipt.transactions,
    jsonReceipt.random_pincode
  );

  if (jsonReceipt.sp_ayce_couverts != null && jsonReceipt.sp_ayce_couverts.length > 0) {
    handheldReceipt.spAyceCover = jsonReceipt.sp_ayce_couverts[0];
  }

  let jsonSpOrdergroups = jsonReceipt.sp_ordergroups.filter((spOrdergroup) => {
    return spOrdergroup.ordergroup != null && spOrdergroup.ordergroup.status !== "PENDING";
  });

  handheldReceipt.handheldOrdergroups = jsonSpOrdergroups.map((jsonSpOrdergroup) =>
    processHandheldOrdergroup(jsonSpOrdergroup, menuTags)
  );
  return handheldReceipt;
}

function processHandheldOrdergroup(jsonOrdergroup, menuTags) {
  let handheldOrdergroup = new HandheldOrdergroup(
    jsonOrdergroup.id,
    jsonOrdergroup.sp_handheld_user_id != null,
    jsonOrdergroup.sp_user_id != null,
    jsonOrdergroup.created_at,
    [],
    jsonOrdergroup.sp_ayce_ordergroup,
    jsonOrdergroup.ordergroup.status,
    jsonOrdergroup.ordergroup.payment_status,
    jsonOrdergroup.ordergroup.pos_status,
    jsonOrdergroup.ordergroup.pos_attempts,
    jsonOrdergroup.ordergroup.id
  );
  handheldOrdergroup.receiptArticles = jsonOrdergroup.ordergroup.o_m_articles.map((article) =>
    processJsonReceiptArticle(article, menuTags)
  );
  return handheldOrdergroup;
}

export function processHandheldTaReceipt(jsonReceipt) {
  let receiptArticles = jsonReceipt.ordergroup.o_m_articles.map((article) => processJsonReceiptArticle(article));
  let contactInformation = null;
  if (jsonReceipt.contact_information != null) {
    contactInformation = JSON.parse(jsonReceipt.contact_information);
  }
  let taReceipt = new TaReceipt(
    jsonReceipt.id,
    jsonReceipt.table_id,
    jsonReceipt.updated_at,
    jsonReceipt.pickup_timestamp,
    jsonReceipt.number,
    jsonReceipt.is_paid,
    receiptArticles,
    contactInformation,
    jsonReceipt.payment_method,
    jsonReceipt.extra_delivery_costs,
    jsonReceipt.extra_payment_costs,
    jsonReceipt.remark,
    jsonReceipt.receipt_type,
    jsonReceipt.status,
    jsonReceipt.ta_merchant_id,
    jsonReceipt.platform,
    jsonReceipt.takeaway_order,
    jsonReceipt.ordergroup.tip_amount ?? 0,
    jsonReceipt.distance,
    jsonReceipt.ubereats_order,
  );

  return taReceipt;
}
