import { DialogContent, DialogTitle, Grid } from "@material-ui/core";
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";
import Button from "@material-ui/core/Button";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import DialogError from "./DialogError";
import mynOberStore from "../../utils/flux/MynOberStore";
import { OrderArticle } from "../../utils/order/OrderArticle";
import DialogPasswordProtectedAction from "./DialogPasswordProtectedAction";

function DialogHandheldReceiptArticleActions(props) {
  const [openDialog, closeDialog] = useDialog();

  const undoTransactionOMArticle = useCallback((transactionOMArticle, count) => {
    const undo = () => {
      requestFunctions
        .send("/receipt/undo", {
          transactionOMArticleId: transactionOMArticle.id,
          count,
          table_identifier: props.table.id,
          sp_merchant_id: props.receipt.spMerchantId,
        })
        .then((resolve) => {
          closeDialog();
        })
        .catch((text) => {
          openDialog({ children: <DialogError content={"ERROR"} /> });
        });
    };

    let password = mynOberStore.merchant.styling?.void_password;
    if (password?.length > 0) {
      openDialog({
        children: (
          <DialogPasswordProtectedAction
            password={password}
            title={"Undo?"}
            onPasswordOk={() => {
              undo();
            }}
          />
        ),
      });
    } else {
      undo();
    }
  }, []);

  return (
    <>
      <DialogTitle>{props.receiptArticle.displayName}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {props.receiptArticle.transactionOMArticles
            .filter((transactionOMArticle) => transactionOMArticle.amount > 0)
            .map((transactionOMArticle) => {
              let transaction = props.handheldReceipt.transactions.find(
                (transaction) => transaction.id === transactionOMArticle.transaction_id
              );
              if (transaction?.turnover_transactions?.[0]?.turnover_group) {
                return (
                  <>
                    <Grid item xs={4}>
                      {transaction.turnover_transactions?.[0]?.turnover_group}
                    </Grid>
                    <Grid item xs={4}>
                      {transactionOMArticle.amount}
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        variant={"outlined"}
                        style={{ margin: 4 }}
                        onClick={() => {
                          undoTransactionOMArticle(transactionOMArticle, 1);
                        }}
                      >
                        {props.intl.formatMessage({ id: "undo" })} 1x
                      </Button>
                      {transactionOMArticle.amount > 1 && (
                        <Button
                          variant={"outlined"}
                          style={{ margin: 4 }}
                          onClick={() => {
                            undoTransactionOMArticle(transactionOMArticle, transactionOMArticle.amount);
                          }}
                        >
                          {props.intl.formatMessage({ id: "undo" })} {transactionOMArticle.amount}x
                        </Button>
                      )}
                    </Grid>
                  </>
                );
              }
              return null;
            })}
        </Grid>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Button
            disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const voidItem = () => {
                let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                console.log(props.receiptArticle);
                if (article) {
                  let orderArticle = new OrderArticle(
                    article,
                    1,
                    props.receiptArticle.orderModifiergroups,
                    null,
                    props.receiptArticle.name,
                    props.receiptArticle.price
                  );
                  let voidData = [orderArticle.toApiForm()];
                  // console.log(this.props.table);
                  requestFunctions
                    .sendVoid(voidData, props.table.id, props.receipt.spMerchantId)
                    .then((resolve) => {
                      closeDialog();
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={"ERROR"} /> });
                    });
                }
              };

              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"VOID_ITEMS"}
                      onPasswordOk={() => {
                        voidItem();
                      }}
                    />
                  ),
                });
              } else {
                voidItem();
              }
            }}
          >
            {props.intl.formatMessage({ id: "VOID_ITEMS" })} 1x
          </Button>
          {props.receiptArticle.countOpen > 1 && (
            <Button
              disabled={props.receiptArticle.countOpen === 0}
              variant={"outlined"}
              style={{ margin: 4 }}
              onClick={() => {
                const voidItems = () => {
                  let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                  if (article) {
                    let orderArticle = new OrderArticle(
                      article,
                      props.receiptArticle.countOpen,
                      props.receiptArticle.orderModifiergroups,
                      null,
                      props.receiptArticle.name,
                      props.receiptArticle.price
                    );
                    let voidData = [orderArticle.toApiForm()];
                    // console.log(this.props.table);
                    requestFunctions
                      .sendVoid(voidData, props.table.id, props.receipt.spMerchantId)
                      .then((resolve) => {
                        closeDialog();
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={"ERROR"} /> });
                      });
                  }
                };

                let password = mynOberStore.merchant.styling?.void_password;
                if (password?.length > 0) {
                  openDialog({
                    children: (
                      <DialogPasswordProtectedAction
                        password={password}
                        title={"VOID_ITEMS"}
                        onPasswordOk={() => {
                          voidItems();
                        }}
                      />
                    ),
                  });
                } else {
                  voidItems();
                }
              }}
            >
              {props.intl.formatMessage({ id: "VOID_ITEMS" })} {props.receiptArticle.countOpen}x
            </Button>
          )}
          <Button
            disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const addPayment = () => {
                let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                if (article) {
                  let orderArticle = new OrderArticle(
                    article,
                    1,
                    props.receiptArticle.orderModifiergroups,
                    null,
                    props.receiptArticle.name,
                    props.receiptArticle.price
                  );
                  let MArticles = [orderArticle.toApiForm()];

                  requestFunctions
                    .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                      amount: orderArticle.amount,
                      is_turnover: false,
                      name: props.intl.formatMessage({ id: "On the house" }),
                    })
                    .then((resolve) => {
                      closeDialog();
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={"ERROR"} /> });
                    });
                }
              };
              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"On the house"}
                      onPasswordOk={() => {
                        addPayment();
                      }}
                    />
                  ),
                });
              } else {
                addPayment();
              }
            }}
          >
            {props.intl.formatMessage({ id: "On the house" })} 1x
          </Button>
          {props.receiptArticle.countOpen > 1 && (
            <Button
              disabled={props.receiptArticle.countOpen === 0}
              variant={"outlined"}
              style={{ margin: 4 }}
              onClick={() => {
                const addPayment = () => {
                  let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                  if (article) {
                    let orderArticle = new OrderArticle(
                      article,
                      props.receiptArticle.countOpen,
                      props.receiptArticle.orderModifiergroups,
                      null,
                      props.receiptArticle.name,
                      props.receiptArticle.price
                    );
                    let MArticles = [orderArticle.toApiForm()];

                    requestFunctions
                      .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                        amount: orderArticle.amount,
                        is_turnover: false,
                        name: props.intl.formatMessage({ id: "On the house" }),
                      })
                      .then((resolve) => {
                        closeDialog();
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={"ERROR"} /> });
                      });
                  }
                };

                let password = mynOberStore.merchant.styling?.void_password;
                if (password?.length > 0) {
                  openDialog({
                    children: (
                      <DialogPasswordProtectedAction
                        password={password}
                        title={"On the house"}
                        onPasswordOk={() => {
                          addPayment();
                        }}
                      />
                    ),
                  });
                } else {
                  addPayment();
                }
              }}
            >
              {props.intl.formatMessage({ id: "On the house" })} {props.receiptArticle.countOpen}x
            </Button>
          )}
          <Button
            disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const addPayment = () => {
                let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                if (article) {
                  let orderArticle = new OrderArticle(
                    article,
                    1,
                    props.receiptArticle.orderModifiergroups,
                    null,
                    props.receiptArticle.name,
                    props.receiptArticle.price
                  );
                  let MArticles = [orderArticle.toApiForm()];

                  requestFunctions
                    .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                      amount: orderArticle.amount,
                      is_turnover: false,
                      name: props.intl.formatMessage({ id: "Loss" }),
                    })
                    .then((resolve) => {
                      closeDialog();
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={"ERROR"} /> });
                    });
                }
              };
              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"Loss"}
                      onPasswordOk={() => {
                        addPayment();
                      }}
                    />
                  ),
                });
              } else {
                addPayment();
              }
            }}
          >
            {props.intl.formatMessage({ id: "Loss" })} 1x
          </Button>
          {props.receiptArticle.countOpen > 1 && (
            <Button
              disabled={props.receiptArticle.countOpen === 0}
              variant={"outlined"}
              style={{ margin: 4 }}
              onClick={() => {
                const addPayment = () => {
                  let article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
                  if (article) {
                    let orderArticle = new OrderArticle(
                      article,
                      props.receiptArticle.countOpen,
                      props.receiptArticle.orderModifiergroups,
                      null,
                      props.receiptArticle.name,
                      props.receiptArticle.price
                    );
                    let MArticles = [orderArticle.toApiForm()];

                    requestFunctions
                      .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                        amount: orderArticle.amount,
                        is_turnover: false,
                        name: props.intl.formatMessage({ id: "Loss" }),
                      })
                      .then((resolve) => {
                        closeDialog();
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={"ERROR"} /> });
                      });
                  }
                };

                let password = mynOberStore.merchant.styling?.void_password;
                if (password?.length > 0) {
                  openDialog({
                    children: (
                      <DialogPasswordProtectedAction
                        password={password}
                        title={"Loss"}
                        onPasswordOk={() => {
                          addPayment();
                        }}
                      />
                    ),
                  });
                } else {
                  addPayment();
                }
              }}
            >
              {props.intl.formatMessage({ id: "Loss" })} {props.receiptArticle.countOpen}x
            </Button>
          )}
        </div>
      </DialogContent>
    </>
  );
}

DialogHandheldReceiptArticleActions.propTypes = {
  receiptArticle: PropTypes.object.isRequired,
};

export default withIntl(DialogHandheldReceiptArticleActions);
