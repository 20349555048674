import mynOberStore from "../flux/MynOberStore";
import { getApplicationDate } from "../functions/HelperFunctions";

export class Article {
  constructor(
    id,
    allergenIds,
    articlegroupId,
    description,
    modifiergroupIds,
    name,
    path,
    addImagePrefix,
    price,
    priority,
    weight,
    openingHours,
    isActive,
    menuTags,
    category,
    isMenu,
    type,
    originalPrice,
    plu,
    maxN,
    extraMessage,
    allowRemark,
    handheld_bg_color,
    min_amount,
    pos_name,
    supergroup_tags,
    shortKey,
    view_only
  ) {
    this.id = id;
    this.allergenIds = allergenIds;
    this.articlegroupId = articlegroupId;
    this.description = description;
    this.modifiergroupIds = modifiergroupIds;
    this.name = name;
    this.path = path;
    this.addImagePrefix = addImagePrefix;
    this.price = price;
    this.priority = priority;
    this.weight = weight;
    this.openingHours = openingHours;
    this.isActive = isActive;
    this.menuTags = menuTags;
    this.category = category;
    this.isMenu = isMenu;
    this.type = type;
    this.originalPrice = originalPrice;
    this.plu = plu;
    this.maxN = maxN;
    this.extraMessage = extraMessage;
    this.allowRemark = allowRemark;
    this.handheld_bg_color = handheld_bg_color;
    this.min_amount = min_amount;
    this.pos_name = pos_name;
    this.supergroup_tags = supergroup_tags;
    this.shortKey = shortKey;
    this.view_only = view_only;
  }

  get isInOpeningHours() {
    let currentTimestamp = getApplicationDate();
    currentTimestamp.setSeconds(0);
    currentTimestamp.setMilliseconds(0);

    let dayOfWeek = currentTimestamp.getDay();
    let openingHoursDay;

    openingHoursDay = this.openingHours[dayOfWeek];

    for (let k = 0; k < openingHoursDay.length; k++) {
      let openingHoursRange = openingHoursDay[k];
      let openingTime = new Date(openingHoursRange.openingTime);
      let closingTime = new Date(openingHoursRange.closingTime);
      if (currentTimestamp >= openingTime && currentTimestamp < closingTime) {
        return true;
      }
    }

    return false;
  }

  getSupergroupTags() {
    if (this.supergroup_tags != null) {
      return this.supergroup_tags.split("|");
    }
    return [];
  }

  get canBeOrderedByUser() {
    let menuTags = null;
    if (!mynOberStore.isHandheld() && mynOberStore.menuTagsWithLag != null) {
      menuTags = mynOberStore.menuTagsWithLag;
    }

    if (!(this.type === "ARTICLE" && this.isActive && this.isInOpeningHours && !this.isMenu)) {
      return false;
    }

    if (menuTags != null) {
      if (this.menuTags.length === 0) {
        return true;
      }
      return menuTags.some((activatedMenuTag) => {
        return this.menuTags.some((articleMenuTag) => {
          return articleMenuTag === activatedMenuTag;
        });
      });
    }
    return true;
  }

  get supergroupTags() {}
}
