import React, { Component } from "react";
import { PathNames } from "../../routers/PathNames";
import HeaderPage from "../../components/HeaderPage";
import * as platformFunctions from "../../utils/functions/PlatformFunctions.web";
import "./TakeawayPage.css";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import mynOberStore from "../../utils/flux/MynOberStore";
import InputEmail from "../../components/web/form_fields/InputEmail";
import InputPhoneNumber from "../../components/web/form_fields/InputPhoneNumber";
import InputPaymentMethod from "../../components/web/form_fields/InputPaymentMethod";
import { OrderTypes } from "../../utils/order/Order";
import { Button, Checkbox, Link, TextField } from "@material-ui/core";
import { hasOnlinePayment } from "../../utils/PaymentMethod";
import TipSelector from "../../components/TipSelector";
import GetCurrency from "../../utils/functions/GetCurrency";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogOk from "../../components/dialogs/DialogOk";
import DialogError from "../../components/dialogs/DialogError";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormField from "../../components/FormField";
import CustomTimestampField from "../../components/customFields/CustomTimestampField";
import Typography from "@material-ui/core/Typography";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";
import PackagingInput from "../../components/packaging/PackagingInput";

class TakeawayPage extends Component {
  constructor(props) {
    super(props);
    this.locale = platformFunctions.getCountry();
    this.state = { ...this._getState(), refreshKey: "" };

    this.paymentMethods = mynOberStore.merchant.paymentMethods.filter((paymentMethod) => {
      if (
        (paymentMethod.takeaway !== null || paymentMethod.takeaway !== undefined) &&
        paymentMethod.takeaway === false
      ) {
        return false;
      } else {
        return true;
      }
    });

    window.takeawayPage = this;
  }

  _getState = () => {
    let askBussinessName = mynOberStore.merchant.askContactDetails;
    let askName = mynOberStore.merchant.askContactDetails;
    let askFirstName = mynOberStore.merchant.askContactDetails;
    let askLastName = mynOberStore.merchant.askContactDetails;
    let askAddress = mynOberStore.merchant.askContactDetails;
    let askCity = mynOberStore.merchant.askContactDetails;
    let askPostalCode = mynOberStore.merchant.askContactDetails;
    let askEmail = mynOberStore.merchant.askContactDetails;
    let askPhone = mynOberStore.merchant.askContactDetails;

    if (
      mynOberStore.merchant.askContactDetails &&
      mynOberStore.merchant.info != null &&
      mynOberStore.merchant.info.ask_contact_details != null &&
      mynOberStore.merchant.info.ask_contact_details.takeaway != null
    ) {
      askBussinessName =
        mynOberStore.merchant.info.ask_contact_details.takeaway.business_name != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.business_name === 1
          : false;
      askName =
        mynOberStore.merchant.info.ask_contact_details.takeaway.name != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.name === 1
          : false;
      askFirstName =
        mynOberStore.merchant.info.ask_contact_details.takeaway.first_name != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.first_name === 1
          : false;
      askLastName =
        mynOberStore.merchant.info.ask_contact_details.takeaway.last_name != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.last_name === 1
          : false;
      askAddress =
        mynOberStore.merchant.info.ask_contact_details.takeaway.address != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.address === 1
          : false;
      askCity =
        mynOberStore.merchant.info.ask_contact_details.takeaway.city != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.city === 1
          : false;
      askPostalCode =
        mynOberStore.merchant.info.ask_contact_details.takeaway.postal_code != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.postal_code === 1
          : false;
      askEmail =
        mynOberStore.merchant.info.ask_contact_details.takeaway.email != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.email === 1
          : false;
      askPhone =
        mynOberStore.merchant.info.ask_contact_details.takeaway.phone != null
          ? mynOberStore.merchant.info.ask_contact_details.takeaway.phone === 1
          : false;
    }

    const newState = {
      contactDetails: mynOberStore.contactDetails,
      paymentMethod: mynOberStore.takeawayOrder.paymentMethod,
      userSelectedPackaging: mynOberStore.userSelectedPackaging,
      takeawayTime: mynOberStore.takeawayOrder.takeawayTime,
      acceptMarketing: mynOberStore.takeawayOrder.acceptMarketing,
      remark: mynOberStore.takeawayOrder.remark == null ? "" : mynOberStore.takeawayOrder.remark,
      askBussinessName: askBussinessName,
      askName: askName,
      askFirstName: askFirstName,
      askLastName: askLastName,
      askAddress: askAddress,
      askCity: askCity,
      askPostalCode: askPostalCode,
      askEmail: askEmail,
      askPhone: askPhone,
      max_ta_orders_blocks: mynOberStore.merchant.max_ta_orders_blocks,
    };

    if (this.state?.max_ta_orders_blocks !== mynOberStore.merchant?.max_ta_orders_blocks) {
      newState.refreshKey = new Date().toISOString();
    }

    return newState;
  };

  getDefaultValue = (key) => {
    let value = mynOberStore.contactDetails[key];

    if (value == null || value.length === 0) {
      value = mynOberStore.merchant.default_contact_details?.takeaway?.[key];
    }
    if (value == null) {
      return "";
    } else {
      return value;
    }
  };

  _updateState = () => {
    this.setState(this._getState);
  };

  get showPrivacy(): boolean {
    return (
      mynOberStore.merchant.askContactDetails ||
      mynOberStore.merchant.useEmail ||
      mynOberStore.merchant.useNotifications ||
      mynOberStore.merchant.usePhone ||
      mynOberStore.merchant.askMarketing
    );
  }

  componentDidMount() {
    // this.state = this._getState();

    mynOberStore.on(mynOberStore.events.CONTACT_DETAILS_UPDATED, this._updateState);
    mynOberStore.on(mynOberStore.events.TAKEAWAY_ORDER_UPDATED, this._updateState);
    mynOberStore.on(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendAlertClosed);
    mynOberStore.on(mynOberStore.events.TIMESTAMP_NOT_AVAILABLE, this._updateState);

    // window.takeawayPage = this;
  }

  componentWillUnmount() {
    mynOberStore.removeListener(mynOberStore.events.TIMESTAMP_NOT_AVAILABLE, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.CONTACT_DETAILS_UPDATED, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.TAKEAWAY_ORDER_UPDATED, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendAlertClosed);
  }

  onOrderSendAlertClosed = () => {
    // if (mynOberStore.merchant.numberOfBlocks === 0 && mynOberStore.merchant.askContactDetails === false && mynOberStore.merchant.useEmail === false && mynOberStore.merchant.useNotifications === false && mynOberStore.merchant.usePhone === false && mynOberStore.merchant.askMarketing === false) {
    if (hasOnlinePayment([this.state.paymentMethod])) {
      this.props.history.push(PathNames.RETURNPAGE + "?taMerchantId=" + mynOberStore.merchant.id);
    }
    // window.location.pathname = PathNames.RETURNPAGE;
    // } else {
    //   window.location.pathname = PathNames.HOMEPAGE;
    // }
  };

  onContactDetailChanged = (key, value) => {
    mynOberActions.setContactDetails({ [key]: value || "" });
  };

  onTakeawayTimeChange = (timestamp) => {
    mynOberActions.updateTakeawayOrder({ takeawayTime: timestamp });
  };

  onPaymentMethodChange = (paymentMethodKey) => {
    let paymentMethod = mynOberStore.merchant.paymentMethods.find((paymentMethod) => {
      return paymentMethod.key === paymentMethodKey;
    });

    mynOberActions.updateTakeawayOrder({ paymentMethod: paymentMethod });
  };

  onUserSelectedPackagingChange = (articleId) => {
    let packaging = mynOberStore.merchant.info?.packagings?.find((packagingArticle) => {
      return packagingArticle.id === articleId;
    });

    if (packaging) {
      mynOberActions.updateUserSelectedPackaging(packaging);
    }
  };

  onURLCreated = (url) => {
    if (
      mynOberStore.merchant.styling.iframe === true &&
      this.state.paymentMethod["key"] === "IDEAL" &&
      (mynOberStore.merchant.info == null || mynOberStore.merchant.info.paynl_return_domain == null)
    ) {
      this.windowReference = window.open();
    }

    if (mynOberStore.merchant.styling.iframe === true && this.state.paymentMethod["key"] === "IDEAL") {
      if (this.windowReference != null) {
        this.windowReference.location = url;
      } else {
        window.top.location.href = url;
      }
    } else {
      window.location = url;
    }
  };

  sendOrderTakeaway = (e) => {
    if (e !== null) {
      e.preventDefault();
    }

    if (
      mynOberStore.merchant.info != null &&
      mynOberStore.merchant.info.minimum_amount != null &&
      mynOberStore.merchant.info.minimum_amount.takeaway != null
    ) {
      if (mynOberStore.order.amount < mynOberStore.merchant.info.minimum_amount.takeaway) {
        const [openDialog] = this.context;
        openDialog({
          children: (
            <DialogOk
              title={"alert_title_warning"}
              rawContent={this.props.intl.formatMessage(
                {
                  id: "minimum_amount",
                },
                {
                  minimumAmount: parseFloat(mynOberStore.merchant.info.minimum_amount.takeaway / 100).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  ),
                }
              )}
            />
          ),
        });
        return;
      }
    }

    if (this.state.takeawayTime > 0 && getApplicationDate().getTime() > this.state.takeawayTime) {
      this.onTakeawayTimeChange(null);
      this.setState({ takeawayTime: null, refreshKey: new Date().toISOString });
      setTimeout(() => {
        const element = document.getElementById("button-takeaway-submit");
        if (element) element.click();
      }, 200);
      return;
    }

    if (this.state.takeawayTime !== "") {
      let doContactDetails = JSON.parse(JSON.stringify(mynOberStore.contactDetails));
      delete doContactDetails.address;
      delete doContactDetails.businessName;
      delete doContactDetails.city;
      delete doContactDetails.name;
      delete doContactDetails.firstName;
      delete doContactDetails.lastName;
      delete doContactDetails.postalCode;
      delete doContactDetails.email;
      delete doContactDetails.phone;
      let contactDetails = {};
      if (this.state.askAddress) {
        contactDetails["address"] = mynOberStore.contactDetails.address;
      } else {
        contactDetails["address"] = "";
      }
      if (this.state.askBussinessName) {
        contactDetails["businessName"] = mynOberStore.contactDetails.businessName;
      } else {
        contactDetails["businessName"] = "";
      }
      if (this.state.askCity) {
        contactDetails["city"] = mynOberStore.contactDetails.city;
      } else {
        contactDetails["city"] = "";
      }
      if (this.state.askName) {
        contactDetails["name"] = mynOberStore.contactDetails.name;
      } else {
        contactDetails["name"] = "";
      }
      if (this.state.askFirstName) {
        contactDetails["firstName"] = mynOberStore.contactDetails.firstName;
      } else {
        contactDetails["firstName"] = "";
      }
      if (this.state.askLastName) {
        contactDetails["lastName"] = mynOberStore.contactDetails.lastName;
      } else {
        contactDetails["lastName"] = "";
      }
      if (this.state.askPostalCode) {
        contactDetails["postalCode"] = mynOberStore.contactDetails.postalCode;
      } else {
        contactDetails["postalCode"] = "";
      }
      if (mynOberStore.merchant.useEmail || this.state.askEmail) {
        contactDetails["email"] = mynOberStore.contactDetails.email;
      } else {
        contactDetails["email"] = "";
      }
      if (mynOberStore.merchant.useNotifications || mynOberStore.merchant.usePhone || this.state.askPhone) {
        contactDetails["phone"] = mynOberStore.contactDetails.phone;
      } else {
        contactDetails["phone"] = "";
      }

      // contactDetails["custom_fields"] = doContactDetails;

      requestFunctions
        .sendTakeawayOrder(
          contactDetails,
          this.state.takeawayTime,
          this.state.paymentMethod,
          this.state.remark,
          mynOberStore.takeawayOrder,
          this.onURLCreated
        )
        .then(() => {
          const [openDialog] = this.context;
          openDialog(
            {
              children: (
                <DialogOk
                  title={"alert_title_success"}
                  content={"alert_text_success_order"}
                  onOkClicked={() => {
                    this.props.history.goBack();
                  }}
                />
              ),
            },
            true
          );
        })
        .catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
    } else {
      const [openDialog] = this.context;
      openDialog({
        children: (
          <DialogOk
            title={"alert_title_warning"}
            rawContent={this.props.intl.formatMessage({
              id: "There is no time slot available, try it again another time!",
            })}
          />
        ),
      });
    }
  };

  render() {
    return (
      <div className="takeaway-page">
        <HeaderPage headerStringId={"header_takeaway"} />

        {mynOberStore.merchant.takeaway &&
        mynOberStore.merchant.takeawayActive &&
        mynOberStore.merchant.delivery &&
        mynOberStore.merchant.deliveryActive ? (
          <Button
            style={{ margin: "10px", fontSize: "1.3rem" }}
            variant={"outlined"}
            onClick={() => {
              this.props.history.replace(PathNames.DELIVERYPAGE);
              mynOberActions.setOrderType(OrderTypes.DELIVERY);
              mynOberActions.setContactDetails({});
            }}
          >
            {this.props.intl.formatMessage({ id: "text_deliver_anyway" })}
          </Button>
        ) : null}

        {mynOberStore.merchant.takeaway && !mynOberStore.merchant.delivery && mynOberStore.merchant.numberOfBlocks > 0 && (
          <Typography
            style={{
              color: "red",
              fontWeight: "bold",
            }}
          >
            {this.props.intl.formatMessage({ id: "alert_only_takeaway" })}
          </Typography>
        )}

        <form onSubmit={this.sendOrderTakeaway} id={"takeaway-form"}>
          <div
            className={"takeaway-page-scrollable"}
            style={{
              overflowY: mynOberStore.isWidescreen ? "auto" : "none",
              height: mynOberStore.isWidescreen ? "0" : "auto",
            }}
          >
            {this.state.askAddress ? (
              <FormField
                required={true}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.address?.hide}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.address?.disabled}
                titleLabel={"label_address"}
                pattern={".+ [0-9]+.*"}
                name={"address"}
                value={mynOberStore.contactDetails.address}
                onChange={(value) => this.onContactDetailChanged("address", value)}
              />
            ) : null}
            {this.state.askPostalCode ? (
              <FormField
                required={true}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.postalCode?.hide}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.postalCode?.disabled}
                value={mynOberStore.contactDetails.postalCode}
                name={"postal-code"}
                pattern={"[0-9]{4,5} ?[A-Za-z]?[A-Za-z]?"}
                titleLabel={"label_postal_code"}
                onChange={(value) => this.onContactDetailChanged("postalCode", value)}
              />
            ) : null}
            {this.state.askCity ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.city?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.city?.hide}
                titleLabel={"label_city"}
                pattern={".+"}
                name={"city"}
                value={mynOberStore.contactDetails.city}
                onChange={(value) => this.onContactDetailChanged("city", value)}
              />
            ) : null}
            {mynOberStore.merchant?.info?.ask_contact_details?.takeaway?.district === 1 ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.district?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.district?.hide}
                titleLabel={"label_district"}
                pattern={".+"}
                name={"district"}
                value={mynOberStore.contactDetails.district}
                onChange={(value) => this.onContactDetailChanged("district", value)}
              />
            ) : null}
            {this.state.askBussinessName ? (
              <FormField
                required={
                  mynOberStore.merchant?.info?.default_contact_details?.takeaway?.businessName?.required ?? false
                }
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.businessName?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.businessName?.hide}
                name={"business_name"}
                value={mynOberStore.contactDetails.businessName}
                pattern={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.businessName?.pattern ?? ".+"}
                titleLabel={"label_business_name"}
                onChange={(value) => this.onContactDetailChanged("businessName", value)}
              />
            ) : null}

            {this.state.askName ? (
              <FormField
                required={true}
                titleLabel={"label_name"}
                value={mynOberStore.contactDetails.name}
                pattern={".+"}
                name={"name"}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.name?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.name?.hide}
                onChange={(value) => this.onContactDetailChanged("name", value)}
              />
            ) : null}

            {this.state.askFirstName ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.firstName?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.firstName?.hide}
                value={mynOberStore.contactDetails.firstName}
                name={"first_name"}
                pattern={".+"}
                titleLabel={"label_first_name"}
                onChange={(value) => this.onContactDetailChanged("firstName", value)}
              />
            ) : null}

            {this.state.askLastName ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.lastName?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.lastName?.hide}
                value={mynOberStore.contactDetails.lastName}
                name={"last_name"}
                pattern={".+"}
                titleLabel={"label_last_name"}
                onChange={(value) => this.onContactDetailChanged("lastName", value)}
              />
            ) : null}

            {mynOberStore.merchant.useEmail || this.state.askEmail ? (
              <InputEmail
                onEmailChange={(email) => this.onContactDetailChanged("email", email)}
                email={mynOberStore.contactDetails.email}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.email?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.takeaway?.email?.hide}
              />
            ) : null}

            {mynOberStore.merchant.useNotifications ||
            mynOberStore.merchant.usePhone ||
            this.state.askPhone ||
            (mynOberStore.merchant.info != null &&
              mynOberStore.merchant.info.ask_contact_details != null &&
              mynOberStore.merchant.info.ask_contact_details.takeaway != null &&
              mynOberStore.merchant.info.ask_contact_details.takeaway.phone != null &&
              mynOberStore.merchant.info.ask_contact_details.takeaway.phone === 1) ? (
              <InputPhoneNumber
                country={platformFunctions.getCountry()}
                onChange={(phoneNumber) => this.onContactDetailChanged("phone", phoneNumber)}
                header="label_phone"
                value={mynOberStore.contactDetails.phone}
              />
            ) : null}

            {mynOberStore.merchant.info?.ask_contact_details?.takeaway?.customFields?.map((customField) => (
              <FormField
                required={customField.required ?? false}
                disabled={customField.disabled ?? false}
                value={mynOberStore.contactDetails[customField.name]}
                name={customField.name}
                pattern={customField.pattern ?? null}
                titleLabel={customField.label}
                onChange={(value) => this.onContactDetailChanged(customField.name, value)}
              />
            ))}

            {mynOberStore.merchant.numberOfBlocks > 0 ? (
              <CustomTimestampField
                key={"TAKEAWAY" + this.state.refreshKey}
                max_ta_orders_blocks={this.state.max_ta_orders_blocks}
                orderType={"takeaway"}
                timeslots={mynOberStore.merchant.timeslots}
                customField={{
                  key: "timestamp",
                  type: "timestamp",
                  required: true,
                  max_weight_per_timeblock: mynOberStore.merchant.maxWeightPerTimeBlock,
                  minimum_waiting_time: mynOberStore.merchant.taMinimumPickupMinutes,
                  asap: mynOberStore.merchant.asap,
                  label: "label_pickup",
                  value: this.state.takeawayTime,
                  order_rules: mynOberStore.merchant.orderRules
                    ? mynOberStore.merchant.orderRules
                    : {
                        repeated_patterns: [
                          {
                            numberOfDaysAhead:
                              mynOberStore.merchant.orderMaxNumberOfDaysAhead === 0
                                ? 0
                                : mynOberStore.merchant.orderMaxNumberOfDaysAhead - 1,
                            repeat: { minutes: mynOberStore.merchant.minutesPerBlock },
                          },
                        ],
                        // timestamps: [{ timestamp: 1613304000, repeat: { minutes: 15 }, numberOfDaysAhead: 0 }],
                      },
                }}
                openingHours={mynOberStore.merchant.openingHours}
                onChange={(value) => {
                  this.onTakeawayTimeChange(value * 1000);
                }}
              />
            ) : null}
            {/*{mynOberStore.merchant.numberOfBlocks > 0 ? (*/}
            {/*  <TimeInfoSelector*/}
            {/*    timeslots={mynOberStore.merchant.timeslots}*/}
            {/*    maxWeightPerTimeBlock={mynOberStore.merchant.maxWeightPerTimeBlock}*/}
            {/*    minimumPickupMinutes={mynOberStore.merchant.taMinimumPickupMinutes}*/}
            {/*    minutesPerBlock={mynOberStore.merchant.minutesPerBlock}*/}
            {/*    numberOfBlocks={mynOberStore.merchant.numberOfBlocks}*/}
            {/*    orderMaxNumberOfDaysAhead={mynOberStore.merchant.orderMaxNumberOfDaysAhead}*/}
            {/*    openingHours={mynOberStore.merchant.openingHours}*/}
            {/*    onTimeChange={this.onTakeawayTimeChange}*/}
            {/*    time={this.state.takeawayTime}*/}
            {/*    asap={mynOberStore.merchant.asap}*/}
            {/*    orderRules={mynOberStore.merchant.orderRules}*/}
            {/*    setInitialTime={(time) => {*/}
            {/*      // if (this.state.takeawayTime === 0 && time !== 0) {*/}
            {/*      // this.onTakeawayTimeChange(time);*/}
            {/*      // this.setState({ takeawayTime: time });*/}
            {/*      // }*/}
            {/*    }}*/}
            {/*    headerMessageId={"label_pickup"}*/}
            {/*  />*/}
            {/*) : null}*/}

            {mynOberStore.merchant.askRemark ? (
              <div>
                <Typography>{this.props.intl.formatMessage({ id: "any_remarks" })}</Typography>
                <TextField
                  margin={"dense"}
                  variant={"outlined"}
                  multiline={true}
                  rows={"5"}
                  placeholder={mynOberStore.merchant.info?.default_remark}
                  value={this.state.remark}
                  onChange={(e) => {
                    mynOberActions.updateTakeawayOrder({ remark: e.target.value });
                  }}
                />
              </div>
            ) : null}

            <PackagingInput
              userSelectedPackaging={this.state.userSelectedPackaging}
              onChange={this.onUserSelectedPackagingChange}
            />

            <InputPaymentMethod
              paymentMethods={this.paymentMethods}
              selectedPaymentMethod={this.state.paymentMethod}
              onChange={this.onPaymentMethodChange}
            />

            {mynOberStore.merchant.tip != null && (
              <TipSelector
                amount={mynOberStore.order.amount}
                onTipSelect={(tipAmount) => {
                  let takeawayOrder = mynOberStore.takeawayOrder;
                  takeawayOrder.tip = tipAmount;
                  mynOberActions.updateTakeawayOrder(takeawayOrder);
                }}
              />
            )}

            {mynOberStore.merchant.paymentMethods.some((paymentMethod) => paymentMethod.extraCosts > 0) === true ? (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_subtotal" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(mynOberStore.order.amount / 100.0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            ) : null}

            {mynOberStore.merchant.tip != null && mynOberStore.takeawayOrder.tip != null && (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_tip" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(mynOberStore.takeawayOrder.tip / 100.0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            )}
            {mynOberStore.merchant.paymentMethods.some((paymentMethod) => paymentMethod.extraCosts > 0) === true ? (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_payment_costs" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(
                    this.state.paymentMethod.extraCosts / 100.0 +
                      ((mynOberStore.order.amount / 100.0) * this.state.paymentMethod.extraPercentage) / 100.0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            ) : null}
            <div className={"horizontal-div"}>
              <Typography>{this.props.intl.formatMessage({ id: "text_total" })}</Typography>
              <Typography>
                {GetCurrency("€")}{" "}
                {parseFloat(
                  (mynOberStore.order.amount +
                    mynOberStore.takeawayOrder.tip +
                    this.state.paymentMethod.extraCosts +
                    ((mynOberStore.order.amount + mynOberStore.takeawayOrder.tip) / 100.0) *
                      this.state.paymentMethod.extraPercentage) /
                    100.0
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </div>

            {mynOberStore.merchant.askMarketing ? (
              <FormControlLabel
                style={{ maxWidth: "331px" }}
                control={
                  <Checkbox
                    color={"primary"}
                    checked={this.state.acceptMarketing}
                    onChange={() => {
                      mynOberActions.updateTakeawayOrder({ acceptMarketing: !this.state.acceptMarketing });
                    }}
                  />
                }
                label={this.props.intl.formatMessage({ id: "mark_checkbox" })}
              />
            ) : null}

            {this.showPrivacy ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Link
                  style={{ maxWidth: "331px" }}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  href={this.props.intl.formatMessage({ id: "privacy_policy_url" })}
                >
                  {this.props.intl.formatMessage({ id: "text_vga_phone" })}
                </Link>
              </div>
            ) : null}
            <div className={"filler"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "10px",
              alignItems: "center",
              justifyContent: "space-evenly",
              paddingBottom: mynOberStore.isWidescreen ? "0" : "16px",
            }}
          >
            <Button
              style={{ fontSize: "1.1rem", margin: "0 8px" }}
              variant={"outlined"}
              onClick={this.props.history.goBack}
              className={"button-back"}
            >
              {this.props.intl.formatMessage({ id: "button_back" })}
            </Button>
            <Button
              id={"button-takeaway-submit"}
              style={{ fontSize: "1.1rem", margin: "0 8px" }}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              className={"button-pay"}
            >
              {this.props.intl.formatMessage({
                id: hasOnlinePayment(mynOberStore.merchant.paymentMethods) ? "button_pay" : "button_send_order",
              })}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

TakeawayPage.propTypes = {
  headerMerchant: PropTypes.element,
};

TakeawayPage.contextType = DialogContext;

export default injectIntl(TakeawayPage);
