import { Button, DialogContent, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";
import mynOberStore from "../../utils/flux/MynOberStore";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

const useStyles = makeStyles(() => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogSendSharedOrder(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const [endTime] = React.useState(mynOberStore.sharedOrderUuidSeconds);
  const [, setSeconds] = React.useState(endTime - getApplicationDate().getTime() / 1000);

  let secondsLeft = Math.ceil(endTime - getApplicationDate().getTime() / 1000);
  if (secondsLeft < 0) {
    secondsLeft = 0;
  }

  let timer = setTimeout(() => {
    if (secondsLeft - 1 <= 0) {
      closeDialog();
      setSeconds(secondsLeft - 1);
      props.onSend();
    } else {
      setSeconds(secondsLeft - 1);
    }
  }, 1000);

  const sharedOrderUuidUpdated = () => {
    if (mynOberStore.sharedOrderUuid == null) {
      clearTimeout(timer);
      closeDialog();
    }
  };

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.SHARED_ORDER_UUID_UPDATED, sharedOrderUuidUpdated);

    return () => {
      mynOberStore.removeListener(mynOberStore.events.SHARED_ORDER_UUID_UPDATED, sharedOrderUuidUpdated);
    };
  }, [sharedOrderUuidUpdated]);

  return (
    <>
      <DialogContent>
        <Typography style={{ fontWeight: 800, textAlign: "center" }}>
          {props.intl.formatMessage({ id: "seconds_left" }, { seconds: secondsLeft })}
        </Typography>

        <div className={classes.div}>
          <Typography>
            {props.intl.formatMessage(
              { id: "send_shared_order" },
              {
                br: (
                  <>
                    <br style={{ lineHeight: 1 }} />
                    <br style={{ lineHeight: 1 }} />
                  </>
                ),
              }
            )}
          </Typography>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              clearTimeout(timer);
              closeDialog();
              props.onCanceled();
            }}
          >
            {props.intl.formatMessage({ id: "button_continue_ordering" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogSendSharedOrder);
