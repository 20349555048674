import { Modifierarticle } from "../menu/Modifierarticle";

export class OrderModifierarticle extends Modifierarticle {
  /**
   *
   * @param {Modifierarticle} modifierarticle
   * @param {number} count
   */
  constructor(modifierarticle, count, info = null) {
    super(
      modifierarticle.id,
      modifierarticle.description,
      modifierarticle.maxN,
      modifierarticle.name,
      modifierarticle.price,
      modifierarticle.priority,
      modifierarticle.handheldOnly,
      modifierarticle.isSideDish
    );
    this.count = count;
    this.info = info;
  }

  /**
   *
   * @param {*} obj
   * @returns {boolean}
   */
  equals(obj) {
    if (!(obj instanceof OrderModifierarticle)) {
      return false;
    }

    if (obj.id !== this.id) {
      return false;
    }

    if (obj.count !== this.count) {
      return false;
    }

    return true;
  }

  toApiForm = () => {
    return {
      m_modifierarticle_id: this.id,
      amount: this.count,
    };
  };
}
