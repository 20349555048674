/**
 * Returns distance in meters between the two given locations.
 * @param {{latitude: number, longitude: number}} merchantLocation
 * @param {Object}location
 * @return {number}
 * @private
 */
export function _calcDistance(merchantLocation, location) {
  if (location === null || location === undefined) {
    return 0;
  }
  if (merchantLocation === null || merchantLocation === undefined) {
    return 0;
  }

  let lat1 = location.coords.latitude;
  let lon1 = location.coords.longitude;
  let lat2 = merchantLocation.latitude;
  let lon2 = merchantLocation.longitude;

  let radlat1 = (Math.PI * lat1) / 180;
  let radlat2 = (Math.PI * lat2) / 180;
  let theta = lon1 - lon2;
  let radtheta = (Math.PI * theta) / 180;

  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  return parseInt(Math.round(dist * 1609.344, 10));
}

/**
 * Get location from navigator and returns distance to given onSuccess listener or error to given onError listener.
 * @param {{latitude: number, longitude: number}}merchantLocation
 * @param {function} onSuccess
 * @param {function} onError
 */
export function getDistanceToMerchant(merchantLocation, onSuccess, onError) {
  let options = {
    enableHighAccuracy: true,
    timeout: 10000,
  };

  navigator.geolocation.getCurrentPosition(
    (location) => {
      onSuccess(_calcDistance(merchantLocation, location));
    },
    onError,
    options
  );
}
