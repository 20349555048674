import { useDialog } from "./DialogProvider";
import PropTypes from "prop-types";
import { Button, DialogContent, DialogTitle, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import DialogError from "./DialogError";
import { localStore } from "../../utils/local_storage/LocalStorage";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputText: {
    textAlign: "center",
    padding: "10px",
    fontSize: "20px",
    width: "70%",
  },
}));

function DialogGuestRegistration(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const [customFields, setCustomFields] = React.useState(props.guestRegistration?.custom_fields ?? []);
  const [retries, setRetries] = useState(0);

  useEffect(() => {
    const localStorageCustomFields = JSON.parse(localStorage.getItem("guest_registration"));
    const newCustomFields = [...customFields];
    if (localStorageCustomFields) {
      customFields.forEach((customField, index) => {
        localStorageCustomFields.forEach((lsCustomField) => {
          if (lsCustomField.label === customField.label && lsCustomField.type === customField.type) {
            newCustomFields[index].value = lsCustomField.value;
          }
        });
      });
    }

    setCustomFields(newCustomFields);
  }, []);

  return (
    <>
      <DialogTitle>{props.guestRegistration.title}</DialogTitle>
      <DialogContent>
        <div
          dangerouslySetInnerHTML={{
            __html: props.guestRegistration.content,
          }}
        ></div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            mynOberAPIClientManager
              .sendGuestRegistration(mynOberStore.webcode, customFields, mynOberStore.merchant.id)
              .then(() => {
                closeDialog();
                const guestRegistrations = JSON.parse(localStore.getItem("guest_registrations")) ?? {};
                guestRegistrations[mynOberStore.webcode] = getApplicationDate();
                localStore.setItem("guest_registrations", JSON.stringify(guestRegistrations));
              })
              .catch((err) => {
                setRetries(retries + 1);
                if (retries < 2) {
                  openDialog({ children: <DialogError content={"Something went wrong, please try again"} /> });
                } else {
                  closeDialog();
                  openDialog({ children: <DialogError content={"Something went wrong"} /> });
                }
              });
            localStore.setItem("guest_registration", JSON.stringify(customFields));
          }}
        >
          <div className={classes.div}>
            {customFields.map((customField, index) => {
              switch (customField.type) {
                case "text":
                  return (
                    <TextField
                      inputProps={{
                        minlength: customField.min_length ?? 0,
                        pattern: customField.required ? ".*\\S.*" : null,
                      }}
                      className={classes.inputText}
                      type={"text"}
                      label={customField.label}
                      value={customField.value ?? ""}
                      onChange={(e) => {
                        let newCustomFields = [...customFields];
                        newCustomFields[index].value = e.target.value;
                        setCustomFields(newCustomFields);
                      }}
                      required={customField.required}
                    />
                  );
                default:
                  return null;
              }
            })}

            <Button type={"submit"} className={classes.button} variant={"contained"} color={"primary"}>
              {props.intl.formatMessage({ id: "button_ok" })}
            </Button>
          </div>
        </form>
      </DialogContent>
    </>
  );
}

DialogGuestRegistration.propTypes = {
  guestRegistration: PropTypes.object.isRequired,
};

export default withIntl(DialogGuestRegistration);
