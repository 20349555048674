export const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export class WeekHours {
  constructor(openingHours: Object) {
    if (openingHours != null) {
      Object.keys(openingHours).forEach((day) => {
        this[day] = openingHours[day];
      });
    }
  }

  getHoursDay(index) {
    return this[days[index]];
  }
}
