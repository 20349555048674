import React, { Component } from "react";
import PropTypes from "prop-types";
import "./HeaderPage.css";
import { injectIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";
import mynOberStore from "../utils/flux/MynOberStore";

class HeaderPage extends Component {
  render() {
    return (
      <div className={"header-page"}>
        <div className={"header-page-title"} style={{ flexDirection: "column" }}>
          <Typography>
            {this.props.headerString == null && this.props.headerStringId != null
              ? this.props.intl.formatMessage({ id: this.props.headerStringId }, this.props.headerStringData)
              : this.props.headerString}
          </Typography>
          {mynOberStore.receipt.randomPincode && (
            <>
              <Typography style={{ fontSize: "0.8rem" }}>Pincode </Typography>
              <Typography style={{ fontSize: "0.8rem", fontWeight: 800 }}>
                {mynOberStore.receipt.randomPincode}
              </Typography>
            </>
          )}
        </div>
      </div>
    );
  }
}

HeaderPage.propTypes = {
  headerString: PropTypes.string,
  headerStringId: PropTypes.string,
  headerStringData: PropTypes.object,
};

export default injectIntl(HeaderPage);
