import React, { Component } from "react";
import ViewOrderArticle from "../../components/menu/ViewOrderArticle";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import * as listeners from "../../utils/Listeners";
import OrderPage from "../../pages/OrderPage";
import ListOrderArticles from "../../components/menu/ListOrderArticles";
import HeaderPage from "../../components/HeaderPage";
import { TaMerchant } from "../../utils/merchant/TaMerchant";
import ViewOrderModifierarticle from "../../components/menu/ViewOrderModifierarticle";
import PropTypes from "prop-types";
import * as platformFunctions from "../../utils/functions/PlatformFunctions";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import { getSharedOrderTransactions } from "../../utils/functions/RequestFunctions";
import mynOberStore from "../../utils/flux/MynOberStore";
import { injectIntl } from "react-intl";
import DialogOk from "../../components/dialogs/DialogOk";
import DialogError from "../../components/dialogs/DialogError";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import { PathNames } from "../../routers/PathNames";
import { withRouter } from "react-router";
import DialogSendSharedOrder from "../../components/dialogs/DialogSendSharedOrder";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import GetCurrency from "../../utils/functions/GetCurrency";
import Typography from "@material-ui/core/Typography";

class OrderContainer extends Component {
  constructor(props) {
    super(props);

    // window.orderContainer = this;
    if (
      mynOberStore.merchant instanceof TaMerchant &&
      mynOberStore.merchant.usePhone === false &&
      mynOberStore.merchant.useNotifications === false &&
      mynOberStore.merchant.useEmail === false &&
      mynOberStore.merchant.askContactDetails === false &&
      mynOberStore.merchant.numberOfBlocks === 0 &&
      mynOberStore.merchant.paymentMethods.find((paymentMethod) => paymentMethod.name === "IDEAL") == null
    ) {
      this.buttonStringId = "button_send_order";
    } else if (
      mynOberStore.merchant instanceof TaMerchant &&
      mynOberStore.merchant.paymentMethods.find((paymentMethod) => paymentMethod.name === "IDEAL") == null
    ) {
      this.buttonStringId = "button_to_order";
    } else {
      this.buttonStringId = mynOberStore.merchant instanceof TaMerchant ? "button_to_payment" : "button_send_order";
    }

    this.uriMerchantImage = mynOberStore.merchant.imageURL;

    this.state = {
      isLoading: false,
      defeaultOrderArticles: [],
      orderArticleViews: this.createOrderArticleViews(mynOberStore.order.orderArticles).concat(
        mynOberStore.defaultOrderArticles
      ),
      orderAmount: mynOberStore.order.amount,
      countInOrder: mynOberStore.order.count,
    };
  }

  componentDidMount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.registerLoadingListeners(this.onLoadingChanged);
    }
    listeners.registerOrderChangedListeners(this.onOrderChanged);
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, this.onOrderChanged);
  }

  componentWillUnmount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.unregisterLoadingListeners(this.onLoadingChanged);
    }
    listeners.unregisterOrderChangedListeners(this.onOrderChanged);
    mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, this.onOrderChanged);
  }

  // --------------------------------------------------

  createOrderArticleViews = (orderArticles) => {
    return orderArticles.map((orderArticle, i) => {
      let orderModifierarticleViews = [];
      orderArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewOrderArticle
          key={i}
          remark={orderArticle.remark}
          number_in_order={i}
          name={orderArticle.name}
          price={orderArticle.amount}
          count={orderArticle.count.toString()}
          orderModifierarticleViews={orderModifierarticleViews}
          onRemoveClicked={() => {
            let orderArticleToAdd = Object.assign(Object.create(Object.getPrototypeOf(orderArticle)), orderArticle);
            orderArticleToAdd.count = -1;
            mynOberActions.addOrderArticle(orderArticleToAdd);

            let amount = mynOberStore.order.amount;
            mynOberStore.order.orderArticles.forEach((orderArticle) => {
              if (amount < orderArticle.min_amount && !mynOberStore.isHandheld()) {
                let orderArticleToAdd = Object.assign(Object.create(Object.getPrototypeOf(orderArticle)), orderArticle);
                orderArticleToAdd.count = -orderArticleToAdd.count;
                mynOberActions.addOrderArticle(orderArticleToAdd);
                amount = mynOberStore.order.amount;
              }
            });
          }}
          onAddClicked={() => {
            let orderArticleToAdd = Object.assign(Object.create(Object.getPrototypeOf(orderArticle)), orderArticle);
            orderArticleToAdd.count = 1;

            if (orderArticleToAdd.min_amount > 0 && !mynOberStore.isHandheld()) {
              if (mynOberStore.order.amount < orderArticleToAdd.min_amount) {
                const [openDialog] = this.context;
                openDialog({
                  children: (
                    <DialogOk
                      title={"alert_title_warning"}
                      rawContent={this.props.intl.formatMessage(
                        { id: "minimal_order_amount" },
                        { min_amount: (orderArticleToAdd.min_amount / 100.0).toFixed(2) + " " + GetCurrency() }
                      )}
                    />
                  ),
                });
                return;
              }
            }

            mynOberActions.addOrderArticle(orderArticleToAdd);
          }}
        />
      );
    });
  };

  onLoadingChanged = () => {
    if (this.state.isLoading !== mynOberStore.isLoading) {
      this.setState({ isLoading: mynOberStore.isLoading });
    }
  };

  onOrderChanged = () => {
    this.setState({
      orderArticleViews: this.createOrderArticleViews(mynOberStore.order.orderArticles).concat(
        mynOberStore.defaultOrderArticles
      ),
      orderAmount: mynOberStore.order.amount,
      countInOrder: mynOberStore.order.count,
    });
  };

  onURLCreated = (url) => {
    window.location = url;
  };

  onOrderSendAlertClosed = () => {
    if (mynOberStore.isLoading) {
      this.props.history.goBack();
    } else {
      if (this.props.merchant instanceof TaMerchant) {
        mynOberActions.orderSendSuccessful();
        if (!mynOberStore.isWidescreen) {
          this.props.history.replace(PathNames.MENUPAGE);
        }
      } else {
        requestFunctions.getReceipt(false).catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
        if (!mynOberStore.isWidescreen) {
          this.props.history.replace(PathNames.MENUPAGE);
        }
        if (mynOberStore.isHandheld()) {
          this.props.history.goBack();
        }
      }
    }
  };

  sendOrder = (phoneNumber, paymentMethods = [], table = null, printInhouseBill = true) => {
    if (mynOberStore.merchant instanceof TaMerchant) {
      requestFunctions
        .sendTakeawayOrder(
          {},
          0,
          mynOberStore.merchant.paymentMethods[0],
          null,
          mynOberStore.takeawayOrder,
          this.onURLCreated
        )
        .then(() => {
          const [openDialog] = this.context;
          openDialog(
            {
              children: <DialogOk title={"alert_title_success"} content={"alert_text_success_order"} />,
            },
            true
          );
        })
        .catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
    } else {
      requestFunctions
        .checkLocation()
        .then((distance) => {
          this.doSendOrderRequest(phoneNumber, distance, paymentMethods, table, printInhouseBill);
        })
        .catch((error) => {
          if (error.code === 1) {
            const [openDialog] = this.context;
            openDialog({
              children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
            });
          } else {
            const [openDialog] = this.context;
            openDialog({
              children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
            });
          }
        });
    }
  };

  showOrderSuccessfulDialog = () => {
    const [openDialog] = this.context;
    openDialog(
      {
        children: (
          <DialogOk
            title={"alert_title_success"}
            content={"alert_text_success_order"}
            timer={mynOberStore.isHandheld()}
            dialogClosed={() => {
              this.onOrderSendAlertClosed();
            }}
            onOkClicked={() => {
              this.onOrderSendAlertClosed();
            }}
          />
        ),
      },
      true
    );
  };

  doSendOrderRequest = (phoneNumber, distance, paymentMethods = [], qr_code = null, printInhouseBill = true) => {
    let extraData = {};
    if (mynOberStore.sharedOrderUuid != null) {
      extraData.sharedOrderUuid = mynOberStore.sharedOrderUuid;
    }
    if (mynOberStore.order.remark != null) {
      extraData.remark = mynOberStore.order.remark;
    }
    if (mynOberStore.order.customFields) {
      extraData.customFields = mynOberStore.order.customFields;
    }
    if (paymentMethods.length > 0) {
      extraData.paymentMethods = paymentMethods;
    }
    if (qr_code) {
      extraData.qr_code = qr_code;
    }
    extraData.print_inhouse_bill = printInhouseBill;

    mynOberAPIClientManager.orderSending = true;
    // for (let i = 0; i < 30; i++) {
    //   setTimeout(() => {
    const sendOrderRequest = () => {
      requestFunctions
        .sendOrder(phoneNumber, mynOberStore.merchant, distance, this.onURLCreated, extraData)
        .catch((text) => {
          console.log(text)
          mynOberAPIClientManager.orderSending = false;
          mynOberActions.setSharedOrderUuid(null, false);
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        })
        .then((resolved) => {
          if (resolved instanceof Object && resolved["sharedOrderUuid"] !== undefined) {
            mynOberActions.setSharedOrderUuid(resolved["sharedOrderUuid"], false);

            if (resolved["sharedOrderUuid"] === null) {
              const [openDialog] = this.context;
              openDialog({
                children: (
                  <DialogSendSharedOrder
                    onCanceled={() => {
                      requestFunctions
                        .cancelSharedOrder()
                        .then((resolved) => {})
                        .catch((error) => {
                          openDialog({ children: <DialogError /> });
                        });
                    }}
                    onSend={() => {}}
                  />
                ),
                disableBackdropClick: true,
              });
              return;
            }

            const [openDialog] = this.context;
            openDialog({
              children: (
                <DialogSendSharedOrder
                  onCanceled={() => {
                    requestFunctions
                      .cancelSharedOrder()
                      .then((resolved) => {})
                      .catch((error) => {
                        openDialog({ children: <DialogError /> });
                      });
                  }}
                  onSend={() => {
                    this.doSendOrderRequest(phoneNumber, distance);
                  }}
                />
              ),
              disableBackdropClick: true,
            });
          } else {
            this.showOrderSuccessfulDialog();
          }
          mynOberAPIClientManager.orderSending = false;
        })
        ;
    };

    if (!mynOberStore.isHandheld() && mynOberStore.merchant.canShareOrder) {
      mynOberActions.setIsLoading(true);
      getSharedOrderTransactions()
        .then((response) => {
          if (!mynOberAPIClientManager._removeWhenFirstIsMissingTransactions(response)) {
            sendOrderRequest();
          } else {
            mynOberActions.setIsLoading(false);
          }
        })
        .catch((error) => {
          mynOberActions.setIsLoading(false);
          mynOberAPIClientManager.orderSending = false;
          mynOberActions.setSharedOrderUuid(null, false);
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={error} /> });
        });
    } else {
      sendOrderRequest();
    }
    // }, 8000 * i);
    // }
  };

  // --------------------------------------------------

  render() {
    return (
      <OrderPage
        order={mynOberStore.order}
        countInOrder={this.state.countInOrder}
        buttonStringId={this.buttonStringId}
        firstAskNotification={mynOberStore.user.firstAskNotification}
        hasOrderArticles={mynOberStore.order.count > 0}
        hasReceipt={mynOberStore.hasReceipt()}
        hasReceiptOrWebcode={mynOberStore.hasReceiptOrWebcode()}
        isHandheld={mynOberStore.isHandheld()}
        isLoading={this.state.isLoading}
        uriMerchantImage={this.uriMerchantImage}
        merchant={mynOberStore.merchant}
        sendOrder={this.sendOrder}
        HeaderPage={<HeaderPage headerStringId={"header_tray"} />}
      >
        {this.state.orderArticleViews.length === 0 ? (
          <Typography className={"empty-message"}>
            {this.props.intl.formatMessage({ id: "text_order_empty" })}
          </Typography>
        ) : (
          <ListOrderArticles
            amount={this.state.orderAmount}
            orderArticles={this.state.orderArticleViews}
            totalStringId={"text_subtotal"}
          />
        )}
      </OrderPage>
    );
  }
}

OrderContainer.propTypes = {
  headerMerchant: PropTypes.element,
};

OrderContainer.contextType = DialogContext;

export default injectIntl(withRouter(OrderContainer));
