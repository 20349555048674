import React, { Component } from "react";
import { withRouter } from "react-router";
import HistoryTaReceiptView from "../../../components/handheld/takeaway/HistoryTaReceiptView";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import { withTheme } from "@material-ui/core";
import withIntl from "../../../utils/withIntl";
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../../components/SearchBar";

class TaReceiptHistoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taReceipts: Object.keys(taHandheldStore.taReceipts)
        .sort(this.sort)
        .map((taHandheldReceiptId) => taHandheldStore.taReceipts[taHandheldReceiptId]),
      searchTableNumber: "",
      searchT: "",
    };

    this.isTables = Object.keys(taHandheldStore.taMerchants).some(
      (taMerchantId) => taHandheldStore.taMerchants[taMerchantId].isTables
    );
    window.TaReceiptHistoryContainer = this;
  }

  sort = (a, b) => {
    if (taHandheldStore.taReceipts[a].updatedAt < taHandheldStore.taReceipts[b].updatedAt) {
      return 1;
    } else if (taHandheldStore.taReceipts[a].updatedAt > taHandheldStore.taReceipts[b].updatedAt) {
      return -1;
    } else {
      return 0;
    }
  };

  componentDidMount(): void {
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_CLOSED, this.receiptUpdated);
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_UPDATED, this.receiptUpdated);
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_CREATED, this.receiptUpdated);
  }

  componentWillUnmount(): void {
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_CLOSED, this.receiptUpdated);
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_UPDATED, this.receiptUpdated);
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_CREATED, this.receiptUpdated);
  }

  receiptUpdated = () => {
    this.setState({
      taReceipts: Object.keys(taHandheldStore.taReceipts)
        .sort(this.sort)
        .map((taHandheldReceiptId) => taHandheldStore.taReceipts[taHandheldReceiptId]),
    });
  };

  render() {
    return (
      <>
        <div
          className={"top-navigation position-sticky"}
          style={{ backgroundColor: this.props.theme.palette.primary.main, top: 0 }}
        >
          <div className={"top-navigation-merchant-box-name"}>
            <Typography className={"top-navigation-merchant-name"}>
              {this.props.intl.formatMessage({ id: "TAKEAWAY" })} / {this.props.intl.formatMessage({ id: "DELIVERY" })}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {this.isTables && (
            <SearchBar
              dontScroll={true}
              value={this.state.searchTableNumber}
              onChange={(value) => {
                this.setState({ searchTableNumber: value });
              }}
              placeHolder={"table"}
            />
          )}
          <SearchBar
            dontScroll={true}
            value={this.state.searchT}
            onChange={(value) => {
              this.setState({ searchT: value });
            }}
            placeHolder={"T"}
          />
        </div>
        {this.state.taReceipts
          .filter(
            (taReceipt) =>
              taReceipt.tableId.includes(this.state.searchTableNumber) &&
              (this.state.searchT === "" || taReceipt.number === parseInt(this.state.searchT.replace("T", "")))
          )
          .map((taHandheldReceipt) => {
            return <HistoryTaReceiptView key={taHandheldReceipt.id} taHandheldReceipt={taHandheldReceipt} />;
          })}
      </>
    );
  }
}

export default withTheme(withIntl(withRouter(TaReceiptHistoryContainer)));
