import React from "react";
import "./BottomTab.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Badge, Tooltip } from "@material-ui/core";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#48BB41",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontFamily: "Poppins",
    fontWeight: "bold",
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
    flex: "1",
    height: "53px",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default function BottomTab(props) {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => props.onClick()}>
      {props.activeIcon == null && <img alt={props.title} src={props.icon} />}
      {props.activeIcon != null && props.selected ? (
        <Badge badgeContent={props.badge} color={"primary"}>
          <img alt={props.title} src={props.activeIcon} />
        </Badge>
      ) : (
        <Badge badgeContent={props.badge} color={"primary"}>
          <img alt={props.title} src={props.icon} />
        </Badge>
      )}
      {props.tooltipTitle != null && (
        <LightTooltip title={props.tooltipTitle} open={!!props.isTooltipOpen} placement={"top"} arrow>
          <div style={{ position: "absolute", transform: "translateY(-10px)" }}></div>
        </LightTooltip>
      )}

      {props.title != null && (
        <span className={"bottom-tab-title"} style={{ fontSize: "0.8em" }}>
          {props.title}
        </span>
      )}
      {props.subtitle != null && <span className={"bottom-tab-subtitle"}>{props.subtitle}</span>}
    </div>
  );
}
