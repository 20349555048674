import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ViewReceiptArticle from "../../menu/ViewReceiptArticle";
import ViewOrderModifierarticle from "../../menu/ViewOrderModifierarticle";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";
import handheldStore from "../../../utils/flux/HandheldStore";
import "./TotalReceiptOverview.css";
import { injectIntl } from "react-intl";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { Table } from "../../../utils/Table";
import VoidReceipt from "./VoidReceipt";
import { Button, Grid, Paper } from "@material-ui/core";
import TableSelector from "./TableSelector";
import { withRouter } from "react-router";
import SplitTableDialog from "./SplitTableDialog";
import { receiptArticlesToJSON } from "../../../utils/order/Order";
import GetCurrency from "../../../utils/functions/GetCurrency";
import { DialogContext } from "../../dialogs/DialogProvider";
import DialogPaymentHandheldSimple from "../../dialogs/DialogPaymentHandheldSimple";
import DialogError from "../../dialogs/DialogError";
import DialogOk from "../../dialogs/DialogOk";
import DialogPasswordProtectedAction from "../../dialogs/DialogPasswordProtectedAction";
import { Bill } from "../../../utils/receipt/Bill";
import Checkout from "./Checkout";
import DialogOkOrCancel from "../../dialogs/DialogOkOrCancel";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import mynOberAPIClientManager from "../../../utils/api/MynOberAPIClientManager";
import { getApplicationDate } from "../../../utils/functions/HelperFunctions";

class TotalReceiptOverview extends Component {
  constructor(props) {
    super(props);

    window.totalReceiptOverview = this;

    this.state = this.getState();
  }

  componentDidMount(): void {
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.on(handheldStore.events.RECEIPT_CLOSED, this.updateState);
  }

  componentWillUnmount(): void {
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CLOSED, this.updateState);
  }

  getState = () => {
    let bill = null;
    if (this.props.table != null && this.props.table.receiptId != null) {
      bill = new Bill(
        this.props.table.receiptId,
        handheldStore.handheldReceipts[this.props.table.receiptId].spMerchantId,
        handheldStore.handheldReceipts[this.props.table.receiptId]
      );

      handheldStore.handheldReceipts[this.props.table.receiptId].handheldOrdergroups.forEach((handheldOrdergroup) => {
        handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
          if (receiptArticle.count > 0) {
            bill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
          }
        });
      });
    }

    let isSplittingTable = false;
    if (this.state != null) {
      isSplittingTable = this.state.isSplittingTable;
    }
    let isVoiding = false;
    if (this.state != null) {
      isVoiding = this.state.isVoiding;
    }

    return {
      receipt: bill,
      isVoiding: isVoiding,
      isSplittingTable: isSplittingTable,
    };
  };

  updateState = () => {
    // console.log("Update State");
    this.setState(this.getState());
  };

  createReceiptArticleViews = (receiptArticles, type) => {
    let countKey = type === "paid" ? "countClosed" : "countOpen";
    let amountKey = type === "paid" ? "amountClosed" : "amountOpen";

    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      if (type === "paid") {
        return receiptArticle[countKey] > 0;
      } else {
        return receiptArticle[amountKey] > 0;
      }
    });

    return filteredReceiptArticles.map((receiptArticle, i) => {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          showTurnoverGroups={type === "paid"}
          receipt={this.state.receipt}
          table={this.props.table}
          key={i}
          name={receiptArticle.displayName}
          amount={receiptArticle[amountKey]}
          count={receiptArticle[countKey]}
          orderModifierarticleViews={orderModifierarticleViews}
          receiptArticle={receiptArticle}
          onClickNegative={() => {
            if (
              this.state.toVoid[receiptArticle.id] != null &&
              this.state.toVoid[receiptArticle.id].equals(receiptArticle)
            ) {
              receiptArticle.countClosed += 1;
              this.setState({ ...this.state, ...{ toVoid: { [receiptArticle.id]: receiptArticle } } });
            } else {
              // console.log(receiptArticle);
              this.setState({ ...this.state, ...{ toVoid: { [receiptArticle.id]: receiptArticle } } });
            }
          }}
        />
      );
    });
  };

  render(): React.ReactNode {
    let merchant = null;

    if (this.state.receipt != null) {
      merchant = handheldStore.spMerchants[this.state.receipt.spMerchantId];
    }
    const [openDialog, closeDialog] = this.context;
    return (
      <div className={"total-receipt-overview"}>
        <Typography className={"bold"}>{this.props.intl.formatMessage({ id: "text_receipt_overview" })}</Typography>
        {this.state.receipt?.receipt?.randomPincode && (
          <>
            <Typography style={{ fontSize: "0.8rem" }}>Pincode </Typography>
            <Typography style={{ fontSize: "0.8rem", fontWeight: 800 }}>
              {this.state.receipt.receipt.randomPincode}
            </Typography>
          </>
        )}
        <Paper style={{ position: "sticky", top: -7, zIndex: 5 }}>
          {this.state.receipt != null && (
            <Grid container direction={"row"} style={{ width: "auto" }} justify={"center"}>
              <Grid item>
                {!this.state.isVoiding ? (
                  <Button
                    style={{ margin: "8px", padding: "15px" }}
                    variant={"outlined"}
                    onClick={() => {
                      if (
                        merchant &&
                        merchant.styling != null &&
                        merchant &&
                        merchant.styling.void_password?.length > 0
                      ) {
                        let password = merchant && merchant.styling.void_password;

                        openDialog({
                          children: (
                            <DialogPasswordProtectedAction
                              password={password}
                              title={"VOID_ITEMS"}
                              onPasswordOk={() => {
                                this.setState({ isVoiding: true });
                              }}
                            />
                          ),
                        });
                      } else {
                        this.setState({ isVoiding: true });
                      }
                    }}
                  >
                    {this.props.intl.formatMessage({ id: "VOID_ITEMS" })}
                  </Button>
                ) : null}
                {this.state.isVoiding ? (
                  <Button
                    style={{ margin: "8px", padding: "15px" }}
                    variant={"outlined"}
                    onClick={() => {
                      this.setState({ isVoiding: false });
                    }}
                  >
                    {this.props.intl.formatMessage({ id: "CANCEL_VOID" })}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                <Button
                  variant={"outlined"}
                  style={{ margin: "8px", padding: "15px" }}
                  onClick={() => {
                    openDialog({
                      children: (
                        <TableSelector
                          spMerchantId={this.props.match.params["spMerchantId"]}
                          exceptTable={this.props.table}
                          onSelectTable={(table) => {
                            closeDialog();

                            openDialog({
                              children: (
                                <DialogOkOrCancel
                                  title={"alert_text_sure"}
                                  content={this.props.intl.formatMessage(
                                    { id: "Are you sure you want to transfer the items to " },
                                    { tableId: table.id }
                                  )}
                                  onOkClicked={() => {
                                    requestFunctions
                                      .transferTable(this.state.receipt, table)
                                      .then(() => {
                                        this.props.history.goBack();
                                      })
                                      .catch((text) => {
                                        openDialog({ children: <DialogError content={text} /> });
                                      });
                                  }}
                                />
                              ),
                              maxWidth: "lg",
                            });
                          }}
                        />
                      ),
                    });
                  }}
                >
                  {this.props.intl.formatMessage({ id: "TRANSFER" })}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={"outlined"}
                  style={{ margin: "8px", padding: "15px" }}
                  onClick={() => {
                    openDialog({
                      children: (
                        <TableSelector
                          spMerchantId={this.props.match.params["spMerchantId"]}
                          exceptTable={this.props.table}
                          onSelectTable={(table) => {
                            closeDialog();
                            this.setState({ splittingTable: table, isSplittingTable: true });
                          }}
                          onClose={() => {
                            closeDialog();
                          }}
                        />
                      ),
                      maxWidth: "lg",
                    });
                  }}
                >
                  {this.props.intl.formatMessage({ id: "SPLIT" })}
                </Button>

                <SplitTableDialog
                  key={"" + this.state.receipt.id + getApplicationDate().getTime()}
                  open={this.state.isSplittingTable && this.state.splittingTable != null}
                  receipt={this.state.receipt}
                  fromTable={this.props.table}
                  toTable={this.state.splittingTable}
                  onSplit={(right) => {
                    this.setState({ isSplittingTable: false, splittingTable: null });
                    requestFunctions
                      .sendSplit(
                        merchant && merchant,
                        receiptArticlesToJSON(right),
                        this.state.splittingTable,
                        this.props.table
                      )
                      .then(() => {
                        this.props.history.goBack();
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                  onClose={() => {
                    this.setState({ isSplittingTable: false, splittingTable: null });
                  }}
                />
              </Grid>
              <Grid item>
                {this.state.receipt != null &&
                ((merchant && merchant.styling == null) ||
                  (merchant && merchant.styling.payment_methods == null) ||
                  (merchant && merchant.styling.payment_methods.length === 0)) ? (
                  <Button
                    style={{ margin: "8px", padding: "15px" }}
                    variant={"outlined"}
                    onClick={() => {
                      openDialog({
                        children: (
                          <DialogPaymentHandheldSimple tableId={this.props.table.id} spMerchantId={merchant.id} />
                        ),
                      });
                    }}
                  >
                    {this.props.intl.formatMessage({ id: "PRINT_CLOSE" })}
                  </Button>
                ) : null}

                {this.state.receipt != null &&
                merchant &&
                merchant.styling != null &&
                merchant &&
                merchant.styling.payment_methods != null &&
                merchant &&
                merchant.styling.payment_methods.length > 0 ? (
                  <Button
                    style={{ margin: "8px", padding: "15px" }}
                    variant={"outlined"}
                    onClick={() => {
                      requestFunctions
                        .printClose(this.props.table.id, false, "button_proforma", merchant.id)
                        .then(() => {
                          openDialog({
                            children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} />,
                          });
                        })
                        .catch((text) => {
                          openDialog({ children: <DialogError content={text} /> });
                        });
                    }}
                  >
                    {this.props.intl.formatMessage({ id: "button_proforma" })}
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {this.state.receipt != null && merchant?.styling?.payment_methods?.length > 0 && (
                  <Checkout
                    table={this.props.table}
                    merchant={merchant}
                    onPayment={(paymentMethods, printInhouseBill) => {
                      requestFunctions
                        .sendPaymentMethods(
                          this.props.table.id,
                          true,
                          paymentMethods,
                          merchant.id,
                          this.state.receipt,
                          printInhouseBill
                        )
                        .then((resolved) => {
                          if (resolved === "receipt_out_of_date") {
                            mynOberAPIClientManager.getHandheldReceipt(this.state.receipt.receipt.id, merchant.id);
                            openDialog({
                              children: (
                                <DialogError content={"Account was out of date, please try again!"} />
                              ),
                            });
                          } else {
                            this.props.history.goBack();
                          }
                        })
                        .catch((error) => {
                          if (error?.message) {
                            openDialog({ children: <DialogError content={error.message} /> });
                          } else {
                            openDialog({ children: <DialogError content={"Error"} /> });
                          }
                        });
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Paper>
        {!this.state.isVoiding && this.state.receipt != null ? (
          <div className={"receipt-article-views"}>
            <Typography style={{ fontWeight: 800, textAlign: "center" }}>
              {this.props.intl.formatMessage({ id: "OPEN" })}
            </Typography>
            {this.createReceiptArticleViews(this.state.receipt.receiptArticles)}
          </div>
        ) : null}

        {this.state.isVoiding && this.state.receipt != null ? (
          <VoidReceipt
            receipt={this.state.receipt}
            table={this.props.table}
            onVoided={() => {
              this.setState({ isVoiding: false });
            }}
          />
        ) : null}

        {this.state.receipt != null ? (
          <>
            <Typography className={"align-right"}>
              {this.props.intl.formatMessage({ id: "Total open" })} {GetCurrency("€")}{" "}
              {(this.state.receipt.amountOpen / 100.0).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          </>
        ) : null}
        <Divider style={{ width: "100%" }} />

        {!this.state.isVoiding && this.state.receipt != null ? (
          <div className={"receipt-article-views"}>
            {this.createReceiptArticleViews(this.state.receipt.receiptArticles, "paid")}
          </div>
        ) : null}
        {this.state.receipt != null ? (
          <Typography>
            {this.props.intl.formatMessage({ id: "Total paid" })} {GetCurrency("€")}{" "}
            {(this.state.receipt.amountClosed / 100.0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        ) : null}

        {this.state.receipt != null ? (
          <Typography>
            {this.props.intl.formatMessage({ id: "text_total" })} {GetCurrency("€")}{" "}
            {(this.state.receipt.amount / 100.0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        ) : null}
      </div>
    );
  }
}

TotalReceiptOverview.propTypes = {
  table: PropTypes.instanceOf(Table),
};

TotalReceiptOverview.contextType = DialogContext;

export default withRouter(injectIntl(TotalReceiptOverview));
