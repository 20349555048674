import React, { Component } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import "./ButtonMyn.css";

class ButtonMyn extends Component {
  constructor(props) {
    super(props);

    this.state = { hover: false };
  }

  mouseOver = () => {
    this.setState({ hover: true });
  };

  mouseOut = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <button
        type={this.props.inputType}
        onMouseOver={this.mouseOver}
        onMouseOut={this.mouseOut}
        className={"button-myn"}
        onClick={this.props.onClick}
        style={this.state.hover ? this.props.styleHover : this.props.style}
      >
        {this.props.image == null ? null : (
          <img src={this.props.image} alt={this.props.intl.formatMessage({ id: this.props.imageAlt })} />
        )}
        {this.props.intl.formatMessage({ id: this.props.stringId })}
        {this.props.icon != null ? this.props.icon : null}
      </button>
    );
  }
}

ButtonMyn.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.object,
  imageAlt: PropTypes.string,
  stringId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  styleHover: PropTypes.object,
};

export default injectIntl(ButtonMyn);
