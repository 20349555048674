import React from "react";
import { injectIntl } from "react-intl";
import icOrders from "./../../../../assets/images/bottombar/ic-orders.svg";
import icOrdersActive from "./../../../../assets/images/bottombar/ic-orders-active.svg";
import BottomTab from "./BottomTab";

function OrderBottomTab(props) {
  return (
    <BottomTab
      icon={icOrders}
      activeIcon={icOrdersActive}
      onClick={() => props.onClick()}
      selected={props.selected}
      badge={null}
      title={props.intl.formatMessage({ id: "tab_orders" })}
    />
  );
}

export default injectIntl(OrderBottomTab);
