import { localStore } from "../../utils/local_storage/LocalStorage";
import TextField from "../web/modals/Fields/TextField";
import { FormattedMessage } from "react-intl";
import NumberField from "../web/modals/Fields/NumberField";
import React from "react";
import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";

function FreeTPArticleModal(props) {
  let defaultValues = localStore.getItem("default_freetparticle_" + props.article.id);
  let initialName = "";
  let initialPrice = 0;
  if (defaultValues != null) {
    initialName = defaultValues["name"];
    initialPrice = parseFloat(defaultValues["price"]);
  }

  const [nameArticle, setNameArticle] = React.useState(initialName);
  const [priceArticle, setPriceArticle] = React.useState(initialPrice);
  const [, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle>{props.article.name}</DialogTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          props.onClick(nameArticle, priceArticle);
          closeDialog();
        }}
        onReset={(e) => {
          closeDialog();
        }}
      >
        <DialogContent>
          <TextField
            autoFocus={true}
            placeholder={<FormattedMessage id={"text_article_description"} />}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.nativeEvent.stopImmediatePropagation();
              }
            }}
            setText={(newText) => {
              setNameArticle(newText);
            }}
          />
          <NumberField

            placeholder={<FormattedMessage id={"text_block_price"} />}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                e.nativeEvent.stopImmediatePropagation();
              }
            }}
            setNumber={(newNumber) => {
              setPriceArticle(newNumber);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button type={"submit"} color={"primary"} variant={"contained"}>
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button type={"reset"} variant={"outlined"}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </DialogActions>
      </form>
    </>
  );
}

export default withIntl(FreeTPArticleModal);
