export function getCountry() {
  return navigator.language.substr(0, 2);
}

export function getDeviceType() {
  return "WEB";
}

export function getFirebaseToken() {
  return Promise.resolve(null);
}

export function getLocale() {
  return navigator.language.substr(0, 2);
}

export function getVersionCode() {
  return "40";
}
