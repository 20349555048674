import React from "react";
import withIntl from "../../utils/withIntl";
import GetCurrency from "../../utils/functions/GetCurrency";
import mynOberStore from "../../utils/flux/MynOberStore";
import { Chip, IconButton, Tooltip, Typography } from "@material-ui/core";
import "./ViewMenuArticle.css";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  borderAccent: {
    borderLeft: "1px solid " + theme.palette.text.primary,
  },
}));

function ViewMenuArticleSimple(props) {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", flexDirection: "row", flex: "1 1 auto", minHeight: 0, paddingRight: "8px" }}>
      {props.article.path !== null ? (
        <div
          style={{
            flex: "0 0 30%",
            margin: "0 3px",
            textAlign: "center",
            overflow: "hidden",
          }}
        >
          <img
            style={{
              borderRadius: "8px",
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
            alt={props.article.name}
            src={props.article.path}
          />
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          width: 0,
          justifyContent: "space-between",
        }}
      >
        <div
          className={classes.borderAccent}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "8px",
            marginLeft: "4px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Typography
            color={"textPrimary"}
            style={{
              fontFamily: props.theme.article?.typography?.fontFamily,
              color: props.theme.article?.palette?.text?.primary,
              fontSize: "12pt",
              paddingBottom: "3px",
              fontWeight: "900",
            }}
            noWrap={true}
          >
            {props.article.name}
          </Typography>

          {mynOberStore.merchant?.showDescription !== false && props.article.description != null ? props.descriptionElement : null}
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
            }}
          >
            {mynOberStore.merchant.showAllergens && !mynOberStore.isHandheld()
              ? mynOberStore.menu.getAllergensByAllergenIds(props.article.allergenIds).map((allergen) => {
                  return (
                    <div
                      key={allergen.id}
                      style={{
                        margin: "0 1px 1px 1px",
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip placement={"top"} title={allergen.name} style={{ fontSize: "18pt" }}>
                        <img
                          style={{
                            height: "24px",
                            width: "24px",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          alt={allergen.name}
                          src={allergen.getImagePath()}
                        />
                      </Tooltip>
                    </div>
                  );
                })
              : null}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/*{props.article.count !== 0 ? <Chip size={"small"} label={props.article.count} color={"primary"} /> : null}*/}

            <div
              className={props.enabled ? "article-price" : "article-price text-disabled"}
              style={{
                fontWeight: 500,
                color: "#888888",
                marginLeft: "16px",
                display: "flex",
                alignItems: "baseline",
                marginRight: 48,
              }}
            >
              {props.article.originalPrice !== null && (
                <Typography style={{ textDecoration: "line-through", marginRight: "6px", fontSize: "0.8em" }}>
                  {GetCurrency("€") +
                    parseFloat(props.article.originalPrice / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Typography>
              )}
              {props.article.price !== 0 ? (
                <Typography
                  color={"textSecondary"}
                  style={{
                    color:
                      props.article.originalPrice !== null
                        ? "red"
                        : props.theme.article_price?.palette?.text?.secondary,
                    fontFamily: props.theme.article_price?.typography?.fontFamily,
                  }}
                >
                  {GetCurrency("€") +
                    parseFloat(props.article.price / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </Typography>
              ) : null}
              {mynOberStore.merchant.isOrdering && !props.article.view_only && (
                <IconButton
                  disabled={!mynOberStore.isOrderingPossible()}
                  onClick={(e) => {
                    props.onClickAdd(e);
                  }}
                  style={{
                    borderRadius: 5,
                    position: "absolute",
                    bottom: -4,
                    right: -4,
                    height: 48,
                    width: 48,
                    backgroundColor: mynOberStore.isOrderingPossible() ? props.theme.palette.primary.main : null,
                  }}
                  size={"medium"}
                >
                  {props.article.count === 0 ? <Add /> : props.article.count}
                </IconButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTheme(withIntl(ViewMenuArticleSimple));
