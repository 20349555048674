import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogClosedInfo(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_ordering_possible" })}</DialogTitle>
      <DialogContent>
        <h3 dangerouslySetInnerHTML={{ __html: mynOberStore.merchant.info?.closed_message }}></h3>
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              if (props.onOkClicked) {
                props.onOkClicked();
              }
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogClosedInfo);
