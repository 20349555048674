import { Component } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { PathNames } from "../routers/PathNames";
import mynOberStore from "./flux/MynOberStore";

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    let pathname = this.props.location.pathname;

    if (mynOberStore.isWidescreen) {
      if (
        pathname === PathNames.TAKEAWAYPAGE ||
        pathname === PathNames.DELIVERYPAGE ||
        pathname === PathNames.ORDERPAGE ||
        pathname === PathNames.RECEIPTPAGE
      ) {
        return;
      }
    }

    if (pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(ScrollToTop);
