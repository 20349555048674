import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-number-input";
import InputTextFieldPhoneNumber from "../web/form_fields/InputTextFieldPhoneNumber";
import mynOberStore from "../../utils/flux/MynOberStore";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogChangeMobileNumber(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const [phoneNumber, setPhoneNumber] = React.useState("");

  const options = mynOberStore.merchant.countryCodes ?? ["NL", "BE", "DE"];
  const defaultCountry =
    mynOberStore.merchant.countryCodes?.[0] ??
    navigator.language.substr(navigator.language.length - 2, 2).toUpperCase();
  const country = defaultCountry;

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_phone_number" })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: "alert_text_phone_number" })}</h3>
        <div className={classes.div}>
          <div className={"input-phone"}>
            <PhoneInput
              placeholder={props.intl.formatMessage({ id: "input_phone_number" })}
              options={options}
              defaultCountry={defaultCountry}
              country={country}
              value={phoneNumber}
              inputComponent={InputTextFieldPhoneNumber}
              onChange={(value) => {
                setPhoneNumber(value);
              }}
            />
          </div>

          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              props.onOkClicked(phoneNumber);
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogChangeMobileNumber);
