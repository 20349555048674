export class TakeawayOrder {
  /**
   *
   */
  constructor(takeawayTime, paymentMethod, acceptMarketing, remark, tip) {
    this.takeawayTime = takeawayTime;
    this.paymentMethod = paymentMethod;
    this.acceptMarketing = acceptMarketing;
    this.remark = remark;
    this.tip = tip;
  }
}
