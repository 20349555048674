import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import "./ListOrderArticles.css";
import GetCurrency from "../../utils/functions/GetCurrency";
import Typography from "@material-ui/core/Typography";

class ListOrderArticles extends Component {
  render() {
    return (
      <div style={{ width: "100%" }}>
        {this.props.orderArticles}
        <Typography className={"text-order-total"}>
          {this.props.intl.formatMessage({ id: this.props.totalStringId })}:{GetCurrency("€")}{" "}
          {parseFloat(this.props.amount / 100.0).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Typography>
      </div>
    );
  }
}

ListOrderArticles.propTypes = {
  amount: PropTypes.number.isRequired,
  orderArticles: PropTypes.array.isRequired,
  totalStringId: PropTypes.string.isRequired,
};

export default injectIntl(ListOrderArticles);
