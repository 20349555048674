import { DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";
import ReactCodeInput from "../ReactCodeInput";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import { localStore } from "../../utils/local_storage/LocalStorage";
import mynOberStore from "../../utils/flux/MynOberStore";

function DialogPincode(props) {
  const [, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        <ReactCodeInput
          type={"number"}
          inputMode={"numeric"}
          maskInput={false}
          fields={4}
          onChange={(value) => {
            if (value.length === 4) {
              localStore.setItem("pincode", value);
              mynOberStore.dialogPincodeIsOpen = false;
              closeDialog();
              requestFunctions.getReceipt(false, null);
            }
          }}
        />
      </DialogContent>
    </>
  );
}

export default withIntl(DialogPincode);
