import React, { useEffect } from "react";
import mynOberStore from "../../../utils/flux/MynOberStore";
import DialogOk from "../../../components/dialogs/DialogOk";
import FreeTPArticleModal from "../../../components/dialogs/FreeTPArticleModal";
import { OrderArticle } from "../../../utils/order/OrderArticle";
import * as mynOberActions from "../../../utils/flux/MynOberActions";
import HandheldViewMenuArticleDetailed from "../../../components/menu/HandheldViewMenuArticleDetailed";
import { useDialog } from "../../../components/dialogs/DialogProvider";
import HandheldRouter from "../../../routers/handheld/HandheldRouter";

function TableContainerWrapper() {
  const prevShortKey = React.useRef("");
  const shortKey = React.useRef("");
  const [openDialog] = useDialog();
  const rootRef = React.useRef(null);

  if (rootRef.current) {
    rootRef.current.focus();
  }

  const onArticleClicked = (article) => {
    if (!mynOberStore.isAYCEEnabled()) {
      if (article.weight !== null) {
        openDialog({
          children: <DialogOk title={"alert_title_ordering_possible"} content={"alert_text_no_couverts_on_table"} />,
        });
        return;
      }
    }

    if (article.type === "FREETPARTICLE") {
      openDialog({
        children: (
          <FreeTPArticleModal
            article={article}
            onClick={(articleName, articlePrice) => {
              let orderArticle = new OrderArticle(article, 1, []);
              orderArticle.name = articleName;
              orderArticle.price = Math.round(articlePrice * 100.0);
              mynOberActions.addOrderArticle(orderArticle);
            }}
          />
        ),
      });
    } else if (!article.allowRemark && article.modifiergroupIds.length === 0) {
      mynOberActions.addOrderArticle(new OrderArticle(article, 1, []));
    } else {
      openDialog({
        children: (
          <HandheldViewMenuArticleDetailed
            article={article}
            enabled={!(!mynOberStore.isAYCEEnabled() && article.weight != null)}
          />
        ),
        fullWidth: true,
      });
    }
  };

  useEffect(() => {
    function onKeyPress(e) {
      if (e.key === "Escape") {
      } else if (e.key === "Enter") {
      } else if (e.key === "Backspace" || e.key === "Delete") {
      } else if (e.key.length === 1) {
        shortKey.current = shortKey.current + e.key;
      }
      console.log(e.key);
    }

    function onKeyUp(e) {
      if (e.key === "Escape") {
        shortKey.current = "";
        prevShortKey.current = "";
      } else if (e.key === "Enter") {
        let key = shortKey.current;
        if (shortKey.current === "" && prevShortKey.current !== "") {
          key = prevShortKey.current;
        }
        let article = mynOberStore.menu.getArticleByShortKey(key);
        if (article) {
          onArticleClicked(article);
        }
        prevShortKey.current = key;
        shortKey.current = "";
      } else if (e.key === "Backspace" || e.key === "Delete") {
        shortKey.current = "";
        prevShortKey.current = "";
      }
    }

    let divElement = rootRef.current;

    rootRef.current.addEventListener("keypress", onKeyPress);
    rootRef.current.addEventListener("keyup", onKeyUp);
    return () => {
      divElement.removeEventListener("keyup", onKeyUp);
      divElement.removeEventListener("keypress", onKeyPress);
    };
  }, [shortKey, prevShortKey]);

  return (
    <div ref={rootRef} style={{ height: "100%", width: "100%" }} tabIndex={0}>
      <HandheldRouter />
    </div>
  );
}

export default TableContainerWrapper;
