import React from "react";
import HeaderPage from "../../components/HeaderPage";
import "./TakeawayPage.css";
import mynOberStore from "../../utils/flux/MynOberStore";
import InputPaymentMethod from "../../components/web/form_fields/InputPaymentMethod";
import { Button } from "@material-ui/core";
import { PaymentMethod } from "../../utils/PaymentMethod";
import TipSelector from "../../components/TipSelector";
import GetCurrency from "../../utils/functions/GetCurrency";
import withIntl from "../../utils/withIntl";
import { withRouter } from "react-router";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import * as sessionStorage from "../../utils/session_storage/SessionStorage";
import DialogError from "../../components/dialogs/DialogError";
import DialogOk from "../../components/dialogs/DialogOk";
import { useDialog } from "../../components/dialogs/DialogProvider";
import Typography from "@material-ui/core/Typography";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { PathNames } from "../../routers/PathNames";

const tipOptions = [{ free: 1 }, { percentage: 5 }, { percentage: 10 }, { percentage: 15 }];

function CheckoutPage(props) {
  const [openDialog] = useDialog();
  let paymentMethods = mynOberStore.spQrCode.sp_payment_methods?.reduce((paymentMethods, paymentMethod) => {
    if (paymentMethod.active === 0) {
      return paymentMethods;
    }
    const spPaymentMethod = paymentMethod[Object.keys(paymentMethod)[0]];
    const extraCosts = spPaymentMethod.extra_costs ?? 0;
    const extraPercentage = spPaymentMethod.extra_percentage ?? 0;
    const amountWithExtraCosts = spPaymentMethod.amount_with_extra_costs ?? 0;
    const description = spPaymentMethod.description ? spPaymentMethod.description : null;
    paymentMethods.push(
      new PaymentMethod(
        Object.keys(paymentMethod)[0],
        Object.keys(paymentMethod)[0],
        extraCosts,
        extraPercentage,
        amountWithExtraCosts,
        false,
        false,
        description
      )
    );
    return paymentMethods;
  }, []);

  if (paymentMethods == null) {
    paymentMethods = mynOberStore.merchant.paymentMethods?.filter((paymentMethod) => paymentMethod.spActive);
  }
  if (paymentMethods == null) {
    paymentMethods = [];
  }

  const [selectedPaymentMethodIndex, setSelectedPaymentMethodIndex] = React.useState(0);
  const [tipAmount, setTipAmount] = React.useState(0);

  const amount = mynOberStore.spQrCode.sp_is_every_order_paid
    ? mynOberStore.order.amount
    : mynOberStore.receipt.amountOpen;

  const onURLCreated = (url) => {
    window.location = url;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (mynOberStore.spQrCode.sp_is_every_order_paid) {
      requestFunctions
        .checkLocation()
        .then((distance) => {
          requestFunctions
            .sendOrder(
              sessionStorage.getItem(sessionStorage.storageNames.PHONE_NUMBER),
              mynOberStore.merchant,
              distance,
              onURLCreated,
              {
                paymentMethodIndex: selectedPaymentMethodIndex,
                paymentMethod: paymentMethods[selectedPaymentMethodIndex],
                tipAmount: tipAmount,
              }
            )
            .then(() => {
              mynOberActions.orderSendSuccessful();

              openDialog({
                children: (
                  <DialogOk
                    title={"alert_title_success"}
                    content={"alert_text_success_order"}
                    onOkClicked={() => {
                      if (!mynOberStore.isWidescreen) {
                        props.history.replace(PathNames.MENUPAGE);
                      }
                    }}
                  />
                ),
              });
            })
            .catch((text) => {
              openDialog({ children: <DialogError content={text} /> });
            });
        })
        .catch((error) => {
          if (error.code === 1) {
            openDialog({
              children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
            });
          } else {
            openDialog({
              children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
            });
          }
        });
    } else {
      requestFunctions
        .sendPayment(paymentMethods[selectedPaymentMethodIndex]["name"], { tipAmount: tipAmount })
        .then(() => {
          openDialog({
            children: <DialogOk title={"alert_title_success"} content={"alert_text_success_order"} />,
          });
        })
        .catch((text) => {
          openDialog({ children: <DialogError content={text} /> });
        });
    }
  };

  return (
    <div className="takeaway-page">
      <HeaderPage headerStringId={"header_takeaway"} />

      <form onSubmit={onSubmit}>
        <div className={"takeaway-page-scrollable"}>
          {paymentMethods.length === 0 && <Typography color={"error"}>No payment methods available</Typography>}

          {paymentMethods.length > 0 && (
            <>
              <InputPaymentMethod
                paymentMethods={paymentMethods}
                selectedPaymentMethod={paymentMethods[selectedPaymentMethodIndex]}
                onChange={(value) => {
                  setSelectedPaymentMethodIndex(
                    paymentMethods.findIndex((paymentMethod) => paymentMethod.key === value)
                  );
                }}
              />

              {
                <TipSelector
                  tipOptions={tipOptions}
                  amount={amount}
                  onTipSelect={(tipAmount) => {
                    setTipAmount(tipAmount);
                  }}
                />
              }

              {paymentMethods.some((paymentMethod) => paymentMethod.extraCosts > 0) === true ? (
                <div className={"horizontal-div"}>
                  <Typography>{props.intl.formatMessage({ id: "text_subtotal" })}</Typography>
                  <Typography>
                    {GetCurrency("€")}{" "}
                    {parseFloat((amount + mynOberStore.defaultOrderAmount) / 100.0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              ) : null}

              {
                <div className={"horizontal-div"}>
                  <Typography>{props.intl.formatMessage({ id: "text_tip" })}</Typography>
                  <Typography>
                    {GetCurrency("€")}{" "}
                    {parseFloat(tipAmount / 100.0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              }
              {paymentMethods.some((paymentMethod) => paymentMethod.extraCosts > 0) === true ? (
                <div className={"horizontal-div"}>
                  <Typography>{props.intl.formatMessage({ id: "text_payment_costs" })}</Typography>
                  <Typography>
                    {GetCurrency("€")}{" "}
                    {parseFloat(
                      paymentMethods[selectedPaymentMethodIndex].extraCosts / 100.0 +
                        (((amount + mynOberStore.defaultOrderAmount) / 100.0) *
                          paymentMethods[selectedPaymentMethodIndex].extraPercentage) /
                          100.0
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              ) : null}
              <div className={"horizontal-div"}>
                <Typography>{props.intl.formatMessage({ id: "text_total" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(
                    (amount +
                      tipAmount +
                      mynOberStore.defaultOrderAmount +
                      paymentMethods[selectedPaymentMethodIndex].extraCosts +
                      ((amount + tipAmount + mynOberStore.defaultOrderAmount) / 100.0) *
                        paymentMethods[selectedPaymentMethodIndex].extraPercentage) /
                      100.0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>

              <div className={"filler"} />
            </>
          )}
        </div>
        {paymentMethods.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "10px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              style={{ fontSize: "20pt", margin: "0 8px" }}
              variant={"outlined"}
              onClick={props.history.goBack}
              className={"button-back"}
            >
              {props.intl.formatMessage({ id: "button_back" })}
            </Button>
            <Button
              style={{ fontSize: "20pt", margin: "0 8px" }}
              variant={"contained"}
              color={"primary"}
              type={"submit"}
              className={"button-pay"}
            >
              {props.intl.formatMessage({
                id: !mynOberStore.spQrCode.sp_is_every_order_paid ? "button_pay" : "button_send_order",
              })}
            </Button>
          </div>
        )}
      </form>
    </div>
  );
}

export default withRouter(withIntl(CheckoutPage));
