import React, { useEffect } from "react";
import mynOberStore from "../../utils/flux/MynOberStore";
import Typography from "@material-ui/core/Typography";
import { MenuItem, Select } from "@material-ui/core";
import GetCurrency from "../../utils/functions/GetCurrency";
import { injectIntl } from "react-intl";

function PackagingInput(props) {
  const packagings =
    mynOberStore.merchant?.info?.packagings?.filter((item) => {
      return mynOberStore.menu.articlesMapId[item.id] != null;
    }) ?? [];

  useEffect(() => {
    if (packagings.length === 0 && props.userSelectedPackaging) {
      props.onChange(null);
    } else if (packagings.length === 1 && !props.userSelectedPackaging) {
      props.onChange(packagings[0].id);
    } else if (props.userSelectedPackaging) {
      if (
        !packagings.find((packaging) => {
          return packaging.id == props.userSelectedPackaging.id;
        })
      ) {
        if (packagings.length === 1) {
          props.onChange(packagings[0].id);
        } else {
          props.onChange(null);
        }
      }
    }
  }, []);

  if (packagings.length === 0) {
    return null;
  }
  return (
    <div>
      <Typography className={"input-text"}>{props.intl.formatMessage({ id: "label_packaging_choice" })}</Typography>
      <Select
        margin={"dense"}
        fullWidth
        style={{ maxWidth: "331px" }}
        variant={"outlined"}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.userSelectedPackaging?.id ?? null}
        inputProps={{ required: true }}
        disabled={packagings.length <= 1 && props.userSelectedPackaging?.id != null}
      >
        {packagings.map((value, index) => (
          <MenuItem value={value.id} key={index}>
            {props.intl.formatMessage({
              id: value.name != null ? value.name : value.id,
            })}

            {value.price > 0 &&
              " + " +
                GetCurrency("€") +
                parseFloat(value.price / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default injectIntl(PackagingInput);
