import { Button, DialogContent, DialogTitle, List, ListItem, Typography } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogOrderArticlesRemoved(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();

  const orderArticles = {};
  props.orderArticles.forEach((orderArticle) => {
    if (!orderArticles[orderArticle.name]) {
      orderArticles[orderArticle.name] = { count: 0, name: orderArticle.name };
    }
    orderArticles[orderArticle.name].count += orderArticle.count * -1;
  });

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        <Typography>{props.intl.formatMessage({ id: "following_items_removed" })}</Typography>
        <List dense>
          {Object.keys(orderArticles).map((orderArticleName) => {
            return (
              <ListItem>
                <Typography>
                  {orderArticles[orderArticleName].count} x {orderArticleName}
                </Typography>
              </ListItem>
            );
          })}
        </List>
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogOrderArticlesRemoved);
