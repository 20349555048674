import React from "react";
import "./SearchBar.css";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import withIntl from "../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import scrollIntoView from "scroll-into-view";
import mynOberStore from "../utils/flux/MynOberStore";

const withStyles = makeStyles((theme) => ({
  textField: {
    borderRadius: "25px",
    flex: "1 1 auto",
    backgroundColor: theme.palette.background.paper,

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
}));

function SearchBar(props) {
  const classes = withStyles();
  const [onKey, setOnKey] = React.useState(false);

  const focusInput = () => {
    let input = document.getElementById("search-bar-input");
    if (input != null) {
      input.focus();
    }
  };
  const onChange = (event) => {
    if (!onKey || !mynOberStore.isWidescreen || !mynOberStore.isHandheld() || props.dontScroll) {
      props.onChange(event.target.value);
    }

    scrollToTop();
  };

  const onClickClearFilter = (event) => {
    if (event != null) {
      event.preventDefault();
    }

    props.onChange("");

    focusInput();
  };

  const onClickSearch = (event) => {
    if (event != null) {
      event.preventDefault();
    }
    focusInput();
  };

  const scrollToTop = () => {
    if (!props.dontScroll) {
      let element = document.getElementById("sticky-header-menu-bar");
      if (element !== null) {
        scrollIntoView(element, {
          time: 1000,
          align: {
            top: 0,
            topOffset: 0, // margin
          },
          validTarget: (target, parentScrolled) => {
            return target.id === "menu-container"; //&& target === window;
          },
        });
      }
    }
  };

  return (
    <div className={"search-bar-container"} onClick={onClickSearch}>
      {/*<IconButton onClick={onClickSearch}>*/}
      {/*  */}
      {/*</IconButton>*/}
      <TextField
        size={"small"}
        className={classes.textField}
        type={"text"}
        variant={"outlined"}
        value={props.value}
        onFocus={(e) => {
          setOnKey(false);
        }}
        onBlur={(e) => setOnKey(true)}
        onClick={(event) => {
          scrollToTop();
          // alert("on click");
          // setOnKey(false);
        }}
        // onKeyPress={(e) => {
        //   setOnKey(true);
        //   alert("press");
        // }}
        // onKeyDown={(e) => {
        //   setOnKey(true);
        //   alert("down");
        // }}
        // onKeyUp={(e) => {
        //   setOnKey(true);
        //   alert("up");
        // }}
        onChange={onChange}
        placeholder={props.intl.formatMessage({ id: props.placeHolder })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              style={{ visibility: props.value.length === 0 ? "hidden" : "visible" }}
              onClick={onClickClearFilter}
            >
              <CloseIcon />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeHolder: PropTypes.string.isRequired,
  dontScroll: PropTypes.bool,
};

export default withIntl(SearchBar);
