import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import GetCurrency from "../../../utils/functions/GetCurrency";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";
import { Button } from "@material-ui/core";
import { printBillSpReceipt, reopenSpReceipt } from "../../../utils/functions/RequestFunctions";
import handheldStore from "../../../utils/flux/HandheldStore";
import { injectIntl } from "react-intl";
import mynOberStore from "../../../utils/flux/MynOberStore";
import DialogError from "../../dialogs/DialogError";
import { useDialog } from "../../dialogs/DialogProvider";
import DialogOk from "../../dialogs/DialogOk";
import DialogPasswordProtectedAction from "../../dialogs/DialogPasswordProtectedAction";
import { parse } from "date-fns";
import { getApplicationDate } from "../../../utils/functions/HelperFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  column: {
    flexBasis: "20%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  hide: {
    [theme.breakpoints.down("xl")]: {
      visibility: "hidden",
    },
  },
  div: {
    borderLeft: "1px solid",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
    },
  },
}));

function HistoryReceiptView(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [passwordChecked, setPasswordChecked] = React.useState(false);

  const [openDialog] = useDialog();

  if (passwordChecked) {
    if (handheldStore.tables[props.handheldReceipt.spMerchantId]?.[props.handheldReceipt.tableId]?.receiptId == null) {
      reopenSpReceipt(props.handheldReceipt.id).catch((text) => {
        openDialog({ children: <DialogError content={text} /> });
      });
    } else {
      openDialog({
        children: <DialogOk title={"alert_title_warning"} content={"table_busy"} />,
      });
    }
    setPasswordChecked(false);
  }

  return (
    <div style={{ marginBottom: "10px" }}>
      <Accordion expanded={expanded} variant="outlined" onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.heading} color="primary">
                {props.handheldReceipt.tableId}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>
                {parse(props.handheldReceipt.updatedAt + "Z", "yyyy-MM-dd HH:mm:ssX", getApplicationDate()).toLocaleTimeString()}
              </Typography>
              <Typography className={clsx(classes.secondaryHeading)}>
                {GetCurrency("€")}{" "}
                {props.handheldReceipt.status === "OPEN"
                  ? (props.handheldReceipt.amountOpen / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : (props.handheldReceipt.amountClosed / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                className={classes.secondaryHeading}
                style={{ color: props.handheldReceipt.status === "CLOSED" ? "grey" : "red" }}
              >
                {props.handheldReceipt.status}
              </Typography>
            </Grid>
            <Grid item xs={2} md={2} lg={2} xl={2} className={classes.hide}>
              <Typography className={clsx(classes.secondaryHeading)}>
                {props.handheldReceipt.status === "OPEN"
                  ? props.handheldReceipt.countOpen
                  : props.handheldReceipt.countClosed}{" "}
                item(s)
              </Typography>
            </Grid>
            <Grid item xs={3}>
              {props.handheldReceipt.transactions
                ?.filter((transaction) => transaction.status === "SUCCEEDED")
                .map((transaction) => {
                  return transaction.turnover_transactions?.map((turnoverTransaction) => {
                    return (
                      <Grid container>
                        <Grid item xs={6}>
                          <Typography className={clsx(classes.secondaryHeading)}>
                            {turnoverTransaction.turnover_group}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} justify={"flex-end"} container>
                          <Typography className={clsx(classes.secondaryHeading)}>
                            {GetCurrency("€")}{" "}
                            {(turnoverTransaction.amount / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  });
                })}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              {handheldStore.spMerchants[props.handheldReceipt.spMerchantId].isMynbonnenMerchant && (
                <Button
                  style={{ margin: 5 }}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    printBillSpReceipt(props.handheldReceipt.id)
                      .then(() => {
                        openDialog({
                          children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} />,
                        });
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                >
                  {props.intl.formatMessage({ id: "rekening printen" })}
                </Button>
              )}
              {!props.handheldReceipt.hasTransactionable && props.handheldReceipt.status === "CLOSED" && (
                <Button
                  style={{ margin: 5 }}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    if (
                      mynOberStore.merchant.styling != null &&
                      mynOberStore.merchant.styling.void_password?.length > 0
                    ) {
                      let password = mynOberStore.merchant.styling.void_password;
                      openDialog({
                        children: (
                          <DialogPasswordProtectedAction
                            password={password}
                            title={"reopen"}
                            onPasswordOk={() => {
                              setPasswordChecked(true);
                            }}
                          />
                        ),
                      });
                    } else {
                      setPasswordChecked(true);
                    }
                  }}
                >
                  {props.intl.formatMessage({ id: "reopen" })}
                </Button>
              )}
            </div>
            <Grid container>
              <div className={classes.div} style={{ display: "flex", flexDirection: "column", width: "inherit" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ flexBasis: "35%", fontWeight: "bold" }} variant="caption">
                    {props.intl.formatMessage({ id: "name" })}
                  </Typography>
                  <Typography style={{ flexBasis: "25%", fontWeight: "bold", textAlign: "right" }} variant="caption">
                    {props.intl.formatMessage({ id: "price_per_unit" })}
                  </Typography>
                  <Typography style={{ flexBasis: "15%", fontWeight: "bold", textAlign: "right" }} variant="caption">
                    {props.intl.formatMessage({ id: "count_open" })}
                  </Typography>
                  <Typography style={{ flexBasis: "15%", fontWeight: "bold", textAlign: "right" }} variant="caption">
                    {props.intl.formatMessage({ id: "count_paid" })}
                  </Typography>
                </div>
                <Divider></Divider>
                <br />
                {props.handheldReceipt.handheldOrdergroups.map((handheldOrdergroup, i) => {
                  return (
                    <div key={handheldOrdergroup.id}>
                      <Typography>Ronde {i + 1}</Typography>
                      {handheldOrdergroup.receiptArticles.map((receiptArticle: ReceiptArticle) => {
                        return (
                          <div key={handheldOrdergroup.id + "-" + receiptArticle.id}>
                            {receiptArticle.name === "Bezorgkosten" || receiptArticle.name === "Betaalkosten" ? (
                              <br />
                            ) : null}
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Typography style={{ flexBasis: "35%" }} variant="caption">
                                {receiptArticle.displayName}
                              </Typography>
                              <Typography style={{ flexBasis: "25%", textAlign: "right" }} variant="caption">
                                {GetCurrency("€")}{" "}
                                {(receiptArticle.price / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                              <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                                {receiptArticle.countOpen}
                                {receiptArticle.countOpen === "" ? "" : "x"}
                              </Typography>
                              <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                                {receiptArticle.countClosed}
                                {receiptArticle.countClosed === "" ? "" : "x"}
                              </Typography>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Typography style={{ flexBasis: "35%" }} variant="caption" />
                              <Typography style={{ flexBasis: "25%", textAlign: "right" }} variant="caption" />
                              <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                                {GetCurrency("€")}{" "}
                                {((receiptArticle.countOpen * receiptArticle.price) / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                              <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                                {GetCurrency("€")}{" "}
                                {((receiptArticle.countClosed * receiptArticle.price) / 100).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </div>
                            <div>
                              {receiptArticle.modifiers
                                ? receiptArticle.modifiers.map((modifier) => {
                                    return (
                                      <div
                                        style={{ display: "flex", flexDirection: "row", marginTop: "0px" }}
                                        key={modifier.id}
                                      >
                                        <Typography
                                          style={{ flexBasis: "35%", fontSize: "80%", color: "grey" }}
                                          variant="caption"
                                        >
                                          {" "}
                                          > {modifier.name}
                                        </Typography>
                                        <Typography
                                          style={{ flexBasis: "25%", fontSize: "80%", color: "grey" }}
                                          variant="caption"
                                        >
                                          {modifier.cost === 0
                                            ? ""
                                            : "+" +
                                              GetCurrency("€") +
                                              " " +
                                              (modifier.cost / 100).toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                              })}
                                        </Typography>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>
                          </div>
                        );
                      })}
                      <br />
                      <Divider />
                    </div>
                  );
                })}
                <Divider variant="fullWidth" />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ flexBasis: "60%" }}>{props.intl.formatMessage({ id: "text_total" })}</Typography>
                  <Typography
                    style={{
                      flexBasis: "15%",
                      textAlign: "right",
                    }}
                  >
                    {GetCurrency("€")}{" "}
                    {(props.handheldReceipt.amountOpen / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                  <Typography
                    style={{
                      flexBasis: "15%",
                      textAlign: "right",
                    }}
                  >
                    {GetCurrency("€")}{" "}
                    {(props.handheldReceipt.amountClosed / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default injectIntl(HistoryReceiptView);
