import { EventEmitter } from "events";
import mynOberDispatcher from "./MynOberDispatcher";
import { AyceMerchant } from "../merchant/AyceMerchant";
import { Receipt } from "../receipt/Receipt";
import { Order, OrderTypes } from "../order/Order";
import * as localStorage from "../local_storage/LocalStorage";
import { localStore } from "../local_storage/LocalStorage";
import { HandheldUser } from "../user/HandheldUser";
import mynOberAPIClientManager from "../api/MynOberAPIClientManager";
import { SpMerchant } from "../merchant/SpMerchant";
import { TaMerchant } from "../merchant/TaMerchant";
import * as sessionStorage from "../../utils/session_storage/SessionStorage";
import * as errorHandler from "../error_handler/ErrorHandler";
import uuidv4 from "uuid/v4";
import { DeliveryOrder } from "../order/DeliveryOrder";
import { TakeawayOrder } from "../order/TakeawayOrder";
import ViewOrderArticle from "../../components/menu/ViewOrderArticle";
import React from "react";
import * as Sentry from "@sentry/browser";
import { ReceiptArticle } from "../receipt/ReceiptArticle";
import { getApplicationDate } from "../functions/HelperFunctions";
import MynOberAPIClientManager from "../api/MynOberAPIClientManager";

const defaultContactDetails = { address: "", postalCode: "", city: "", name: "", email: "", phone: "" };

class MynOberStore extends EventEmitter {
  constructor() {
    super();

    // Constants related to MynOberStore.
    this.actions = {
      ADD_ORDER_ARTICLE: "ADD_ORDER_ARTICLE",
      CHANGE_FILTER: "CHANGE_FILTER",
      CLEAR_MERCHANT_UPDATE_TIMER: "CLEAR_MERCHANT_UPDATE_TIMER",
      CLEAR_ORDER: "CLEAR_ORDER",
      CLEAR_RECEIPT: "CLEAR_RECEIPT",
      EXIT_MERCHANT: "EXIT_MERCHANT",
      GET_DATA_FROM_LOCAL_STORAGE: "GET_DATA_FROM_LOCAL_STORAGE",
      LOGIN_HANDHELD: "LOGIN_HANDHELD",
      LOGIN_HANDHELD_FROM_INDEX: "LOGIN_HANDHELD_FROM_INDEX",
      LOGOUT_HANDHELD: "LOGOUT_HANDHELD",
      ORDER_SEND_SUCCESSFUL: "ORDER_SEND_SUCCESSFUL",
      RESET_USER: "RESET_USER",
      UPDATE_CONTACT_DETAILS: "UPDATE_CONTACT_DETAILS",
      UPDATE_DELIVERY_ORDER: "UPDATE_DELIVERY_ORDER",
      UPDATE_TAKEAWAY_ORDER: "UPDATE_TAKEAWAY_ORDER",
      UPDATE_RECEIPT: "UPDATE_RECEIPT",
      SET_FIRST_ASK_NOTIFICATION: "SET_FIRST_ASK_NOTIFICATION",
      SET_FIRST_TIME_APP: "FIRST_TIME_APP",
      SET_IS_APP_VISIBLE: "SET_IS_APP_VISIBLE",
      SET_IS_LOADING: "SET_IS_LOADING",
      SET_IS_WIDESCREEN: "SET_IS_WIDESCREEN",
      SET_MERCHANT: "SET_MERCHANT",
      SET_MERCHANT_WITH_MENU_AND_WEBCODE: "SET_MERCHANT_WITH_MENU_AND_WEBCODE",
      SET_ORDER: "SET_ORDER",
      SET_FAILED_ORDER_DIALOG_IS_OPEN: "SET_FAILED_ORDER_DIALOG_IS_OPEN",
      SET_ORDER_TYPE: "SET_ORDER_TYPE",
      SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
      SET_RECEIPT: "SET_RECEIPT",
      SET_RECEIPT_WITH_MERCHANT_AND_MENU: "SET_RECEIPT_WITH_MERCHANT_AND_MENU",
      SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
      SET_WEBCODE: "SET_WEBCODE",
      SET_SP_QR_CODE: "SET_SP_QR_CODE",
      SET_SHARED_ORDER_UUID: "SET_SHARED_ORDER_UUID",
      UPDATE_ORDER: "UPDATE_ORDER",
      SET_PIN_TRANSACTION: "SET_PIN_TRANSACTION",
      USER_SELECTED_PACKAGING: "USER_SELECTED_PACKAGING",
    };
    this.events = {
      CONTACT_DETAILS_UPDATED: "CONTACT_DETAILS_UPDATED",
      DELIVERY_ORDER_UPDATED: "DELIVERY_ORDER_UPDATED",
      TAKEAWAY_ORDER_UPDATED: "TAKEAWAY_ORDER_UPDATED",
      IS_LOADING_CHANGED: "IS_LOADING_CHANGED",
      IS_WIDESCREEN_CHANGED: "IS_WIDESCREEN_CHANGED",
      LATEST_ORDER_FAILED: "LATEST_ORDER_FAILED",
      LOGIN_HANDHELD_SUCCESSFUL: "LOGIN_HANDHELD_SUCCESSFUL",
      LOGIN_HANDHELD_FROM_INDEX: "LOGIN_HANDHELD_FROM_INDEX",
      LOGOUT_HANDHELD_SUCCESSFUL: "LOGOUT_HANDHELD_SUCCESSFUL",
      MENU_CREATED: "MENU_CREATED",
      MERCHANT_CREATED: "MERCHANT_CREATED",
      MERCHANT_EXITED: "MERCHANT_EXITED",
      ORDER_CHANGED: "ORDER_CHANGED",
      ORDER_ARTICLES_REMOVED_AUTOMATICALLY: "ORDER_ARTICLES_REMOVED_AUTOMATICALLY",
      ORDER_CHANGED_FROM_SHARED_ORDERS: "ORDER_CHANGED_FROM_SHARED_ORDERS",
      MAX_ARTICLES_PER_ORDER: "MAX_ARTICLES_PER_ORDER",
      MAX_ARTICLES_PER_CATEGORY: "MAX_ARTICLES_PER_CATEGORY",
      MAX_TOTAL_ARTICLES_PER_CATEGORY: "MAX_TOTAL_ARTICLES_PER_CATEGORY",
      MAX_ROUND_PER_CATEGORY: "MAX_ROUND_PER_CATEGORY",
      MINIMAL_AMOUNT_PRODUCTS_EXCEED_MINIMAL_AMOUNT: "MINIMAL_AMOUNT_PRODUCTS_EXCEED_MINIMAL_AMOUNT",
      MAX_DIFFERENT_ARTICLES_PER_ORDER: "MAX_DIFFERENT_ARTICLES_PER_ORDER",
      ORDER_TYPE_CHANGED: "ORDER_TYPE_CHANGED",
      ORDER_SEND_SUCCESSFUL: "ORDER_SEND_SUCCESSFUL",
      PHONE_NUMBER_CHANGED: "PHONE_NUMBER_CHANGED",
      RECEIPT_CHANGED: "RECEIPT_CHANGED",
      RECEIPT_CLEARED: "RECEIPT_CLEARED",
      RECEIPT_NOT_CLEARED: "RECEIPT_NOT_CLEARED",
      USER_CHANGED: "USER_CHANGED",
      WEBCODE_CHANGED: "WEBCODE_CHANGED",
      WRONG_QR_PINCODE: "WRONG_QR_PINCODE",
      TIMESTAMP_NOT_AVAILABLE: "TIMESTAMP_NOT_AVAILABLE",
      TIMER_30: "TIMER_30",
      TIMER_15: "TIMER_15",
      SHARED_ORDER_UUID_UPDATED: "SHARED_ORDER_UUID_UPDATED",
      PIN_TRANSACTION_UPDATED: "PIN_TRANSACTION_UPDATED",
      USER_SELECTED_PACKAGING_UPDATED: "USER_SELECTED_PACKAGING_UPDATED",
    };

    // Variables related to MynOberStore.
    this._contactDetails =
      sessionStorage.getItem(sessionStorage.storageNames.CONTACT_DETAILS) == null
        ? defaultContactDetails
        : JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.CONTACT_DETAILS));
    this._isAppVisible = true;
    this._isLoading = false;
    this._isWidescreen = false;
    this._menu = null;
    this._merchant = null;
    this._spQrCode = null;
    this._merchantUpdateTimer = null;
    this._merchantShouldUpdate = false;
    this._deliveryOrder = null;
    let deliveryOrder = sessionStorage.getItem(sessionStorage.storageNames.DELIVERY_ORDER);
    if (deliveryOrder != null) {
      this._deliveryOrder = new DeliveryOrder();
      this._updateDeliveryOrder(JSON.parse(deliveryOrder));
    }
    this._takeawayOrder = null;
    let takeawayOrder = sessionStorage.getItem(sessionStorage.storageNames.TAKEAWAY_ORDER);
    if (takeawayOrder != null) {
      this._takeawayOrder = new TakeawayOrder();
      this._updateTakeawayOrder(JSON.parse(takeawayOrder));
    }
    this._order = new Order();
    this._receipt = null;
    this._user = null;
    this._webcode = null;
    this._defaultOrderArticles = [];
    this._defaultOrderAmount = 0;

    this.userSelectedPackaging = null;
    this.transactionUUIDs = [];
    this._sharedOrderUuid = null;

    this._updateMenusTimer = null;
    this._menus = null;
    this._menusWithLag = null;
    this._failedOrderDialogIsOpen = false;
    this.isInvalidPincode = false;
    this.dialogPincodeIsOpen = false;
    this.filteredOutOrderArticles = [];
  }

  /**
   * Action handler for MynOberStore.
   * @param {object} action
   */
  _handleActions(action) {
    switch (action.type) {
      case this.actions.ADD_ORDER_ARTICLE:
        if (action.orderArticle.count > 0 && !this.isHandheld()) {
          if (action.orderArticle.maxN > 0) {
            let maxN = this.order.orderArticles.reduce((count, orderArticle) => {
              if (orderArticle.id === action.orderArticle.id) {
                count += orderArticle.count;
              }
              return count;
            }, 0);

            if (action.orderArticle.maxN > 0 && action.orderArticle.maxN < maxN + action.orderArticle.count) {
              console.log("maxN reached");
              return;
            }
          }
        }

        if (action.orderArticle.count < 0 && !this.isHandheld()) {
          /// Check minimal order amount
          let newAmount = mynOberStore.order.amount + action.orderArticle.amount;

          let limitingOrderArticle = mynOberStore.order.orderArticles.find((orderArticle) => {
            if (orderArticle.id === action.orderArticle.id) {
              return false;
            }
            if (isNaN(orderArticle.min_amount)) {
              return false;
            }
            return newAmount < orderArticle.min_amount;
          });
          if (limitingOrderArticle) {
            this.emit(this.events.MINIMAL_AMOUNT_PRODUCTS_EXCEED_MINIMAL_AMOUNT, {
              orderArticle: limitingOrderArticle,
            });
            break;
          }
        }

        if (action.orderArticle.count > 0 && !this.isHandheld() && this.merchant instanceof AyceMerchant) {
          // Check max articles per order per category
          let articleFlags = this.merchant.ayceSettings.articleFlags;
          let activeMArticleIds = this.menusWithLag?.activeMArticleIds;
          let order = this.order;
          if (action.orderArticle.category != null) {
            // NORMAL Category
            let category = action.orderArticle.category;
            let maxWeightForCategory = null;
            let totalMaxWeightForCategory = null;
            let lastArticleFlagUsed = null;
            let articleFlagsIncludingNullCategory = [];

            let maxNumberOfRoundsForCategory = null;

            articleFlags.forEach((articleFlag) => {
              if (
                articleFlag["round_categories"]?.[category]?.["max_rounds"] != null &&
                activeMArticleIds[articleFlag.articleId] != null
              ) {
                maxNumberOfRoundsForCategory = articleFlag["round_categories"][category]["max_rounds"];
              }
              if (articleFlag["categories"]?.[category] != null && activeMArticleIds[articleFlag.articleId] != null) {
                lastArticleFlagUsed = articleFlag;
                if (articleFlag["categories"][category]["max_weight_per_cover"] != null) {
                  maxWeightForCategory +=
                    activeMArticleIds[articleFlag.articleId]["count"] *
                    articleFlag["categories"][category]["max_weight_per_cover"];
                }
                if (articleFlag["categories"][category]["total_max_weight_per_cover"] != null) {
                  totalMaxWeightForCategory +=
                    activeMArticleIds[articleFlag.articleId]["count"] *
                    articleFlag["categories"][category]["total_max_weight_per_cover"];
                }
                return true;
              }

              if (activeMArticleIds[articleFlag.articleId] != null) {
                articleFlagsIncludingNullCategory.push(articleFlag);
              }
            });

            if (lastArticleFlagUsed) {
              articleFlagsIncludingNullCategory.forEach((articleFlag) => {
                maxWeightForCategory +=
                  activeMArticleIds[articleFlag.articleId]["count"] *
                  lastArticleFlagUsed["categories"][category]["max_weight_per_cover"];
              });
            }

            let currentTotalWeightWithCategoryByUser = 0;
            let currentNumberOfRoundsWithCategoryByUser = 0;
            mynOberStore.receipt.ordergroups.forEach((handheldOrdergroup) => {
              if (handheldOrdergroup.isUser) {
                let found = false;

                handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
                  let menuArticle = mynOberStore.menu.getArticleById(receiptArticle.id);
                  if (menuArticle.category === category && receiptArticle.count > 0) {
                    if (!found) {
                      currentNumberOfRoundsWithCategoryByUser += 1;
                      found = true;
                    }

                    currentTotalWeightWithCategoryByUser += receiptArticle.count;
                  }
                });
              }
            });

            if (
              maxNumberOfRoundsForCategory != null &&
              currentNumberOfRoundsWithCategoryByUser != null &&
              maxNumberOfRoundsForCategory <= currentNumberOfRoundsWithCategoryByUser
            ) {
              this.emit(this.events.MAX_ROUND_PER_CATEGORY, {
                maxCount: maxNumberOfRoundsForCategory,
                category: category,
              });
              break;
            }

            let currentWeightForCategory = 0;

            order.orderArticles.forEach((orderArticle) => {
              if (orderArticle.category === action.orderArticle.category) {
                currentWeightForCategory += orderArticle.count * orderArticle.weight;
              }
            });

            let newWeightForCategory =
              currentWeightForCategory + action.orderArticle.count * action.orderArticle.weight;

            if (maxWeightForCategory != null && newWeightForCategory > maxWeightForCategory) {
              this.emit(this.events.MAX_ARTICLES_PER_CATEGORY, {
                maxCount: maxWeightForCategory,
                category: category,
              });
              break;
            }

            if (
              totalMaxWeightForCategory != null &&
              currentTotalWeightWithCategoryByUser != null &&
              totalMaxWeightForCategory < currentTotalWeightWithCategoryByUser + newWeightForCategory
            ) {
              this.emit(this.events.MAX_TOTAL_ARTICLES_PER_CATEGORY, {
                maxCount: totalMaxWeightForCategory,
                category: category,
              });
              break;
            }
          }

          // Check max different articles per order if needed
          if (action.orderArticle.weight !== null && this.merchant.ayceSettings.maxDifferentPlus > 0) {
            let currentNumberOfDifferentPlusWithAWeight = 0;
            this.order.orderArticles.forEach((orderArticle) => {
              if (orderArticle.id === action.orderArticle.id) {
                currentNumberOfDifferentPlusWithAWeight -= 1;
              }
              if (orderArticle.weight !== null) {
                currentNumberOfDifferentPlusWithAWeight += 1;
              }
            });

            if (currentNumberOfDifferentPlusWithAWeight === this.merchant.ayceSettings.maxDifferentPlus) {
              this.emit(this.events.MAX_DIFFERENT_ARTICLES_PER_ORDER);
              break;
            }
          }

          // Check max articles per order
          let currentWeight = this.order.weight;
          let maxWeight = this.merchant.maxWeight;
          if (currentWeight + action.orderArticle.weight * action.orderArticle.count > maxWeight && maxWeight > 0) {
            this.emit(this.events.MAX_ARTICLES_PER_ORDER);
            break;
          } else {
            this._addArticleToOrder(action.orderArticle, action.callSharedOrderUpdated);
            if (action.callSharedOrderUpdated) {
              this.emit(this.events.ORDER_CHANGED, action.orderArticle.id);
            } else {
              this.emit(this.events.ORDER_CHANGED_FROM_SHARED_ORDERS, action.orderArticle.id);
            }
          }
        } else {
          this._addArticleToOrder(action.orderArticle, action.callSharedOrderUpdated);
          if (action.callSharedOrderUpdated) {
            this.emit(this.events.ORDER_CHANGED, action.orderArticle.id);
          } else {
            this.emit(this.events.ORDER_CHANGED_FROM_SHARED_ORDERS, action.orderArticle.id);
          }
        }

        if (this._merchant instanceof TaMerchant) {
          if (this._deliveryOrder != null) {
            this._updateDeliveryOrder({ deliveryCosts: this._getDeliveryCosts(this._contactDetails.postalCode) });
          }
        }
        break;
      case this.actions.CLEAR_MERCHANT_UPDATE_TIMER:
        this._clearMerchantUpdateTimer();
        break;
      case this.actions.EXIT_MERCHANT:
        this._exitMerchant();
        this.emit(this.events.MERCHANT_EXITED);
        break;
      case this.actions.GET_DATA_FROM_LOCAL_STORAGE:
        this._getDataFromLocalStorage(action.isHandheld);
        // this.emit(this.events.USER_CHANGED);
        break;
      case this.actions.LOGIN_HANDHELD:
        this._loginHandheldUser(action.password, action.username).then(() => {
          this.emit(this.events.LOGIN_HANDHELD_SUCCESSFUL);
        });
        break;
      case this.actions.LOGIN_HANDHELD_FROM_INDEX:
        this._user.loggedIn = true;
        this._user.handheldUser = action.handheldUser;
        Sentry.configureScope((scope) => {
          scope.setTag("handheldUsername", action.handheldUser.username);
        });
        break;
      case this.actions.LOGOUT_HANDHELD:
        this._logoutHandheldUser().then(() => {
          this.emit(this.events.LOGOUT_HANDHELD_SUCCESSFUL);
        });
        break;
      case this.actions.ORDER_SEND_SUCCESSFUL:
        this._sharedOrderUuid = null;
        if (action.byUser) {
          localStore.setItem("firstTimeOrder", false);
        }
        this.transactionUUIDs = [];
        this._clearOrder(this._webcode);

        if (this._merchant?.info?.ask_contact_details?.save_contact_information_in_session === 0) {
          this._contactDetails = defaultContactDetails;
        }

        this.emit(this.events.ORDER_SEND_SUCCESSFUL, action.byUser);
        this.emit(this.events.ORDER_CHANGED);
        break;
      case this.actions.RESET_USER:
        this._resetUser().then(() => {
          this.emit(this.events.USER_CHANGED);
        });
        break;
      case this.actions.UPDATE_CONTACT_DETAILS:
        this._updateContactDetails(action.contactDetails);
        break;
      case this.actions.UPDATE_DELIVERY_ORDER:
        this._updateDeliveryOrder(action.deliveryOrder);
        break;
      case this.actions.UPDATE_TAKEAWAY_ORDER:
        this._updateTakeawayOrder(action.takeawayOrder);
        break;
      case this.actions.USER_SELECTED_PACKAGING:
        this.userSelectedPackaging = action.packaging;
        this.emit(this.events.TAKEAWAY_ORDER_UPDATED);
        this.emit(this.events.DELIVERY_ORDER_UPDATED);
        break;
      case this.actions.UPDATE_RECEIPT: // handheld action
        this._updateReceipt(action.receipt);
        break;
      case this.actions.SET_FIRST_ASK_NOTIFICATION:
        this._setFirstAskNotification(action.value).then(() => {});
        break;
      case this.actions.SET_FIRST_TIME_APP:
        this._setFirstTimeApp(action.value).then(() => {});
        break;
      case this.actions.SET_IS_APP_VISIBLE:
        this._setIsAppVisible(action.visible);
        if (action.visible && this._merchantShouldUpdate) {
          this._updateMerchant();
        }
        break;
      case this.actions.SET_IS_LOADING:
        if (this._setIsLoading(action.isLoading)) {
          this.emit(this.events.IS_LOADING_CHANGED);
        }
        break;
      case this.actions.SET_IS_WIDESCREEN:
        this._setIsWidescreen(action.isWidescreen);
        this.emit(this.events.IS_WIDESCREEN_CHANGED);
        break;
      case this.actions.SET_MERCHANT:
        this._setMerchant(action.merchant);
        this.emit(this.events.MERCHANT_CREATED);
        break;
      case this.actions.SET_MERCHANT_WITH_MENU_AND_WEBCODE:
        if (window.iframe === false) {
          if (action.merchant.styling?.iframe != null) {
            action.merchant.styling.iframe = window.iframe;
          }
          if (action.merchant.info?.paynl_return_domain != null) {
            action.merchant.info.paynl_return_domain = null;
          }
          // action.merchant.doTaPostalCodeCheck = true;
        }
        this._menus = null;
        this._menusWithLag = null;
        this._setMenu(action.menu);
        this._setMerchant(action.merchant);
        this._setMerchantUpdateTimer();
        this._setWebcode(action.webcode);

        Sentry.configureScope((scope) => {
          scope.setTag("merchantId", this._merchant.id);
          scope.setTag("merchantName", this._merchant.name);
        });

        this._defaultOrderArticles = [];
        if (this._merchant?.info?.default_order_items instanceof Array) {
          this._merchant.info.default_order_items
            .filter((item) => {
              return this._menu.articlesMapId[item.id] != null;
            })
            .forEach((orderItem, i) => {
              this._defaultOrderAmount += orderItem.price * orderItem.amount;
              this._defaultOrderArticles.push(
                <ViewOrderArticle
                  key={"default_" + i}
                  name={orderItem.name}
                  price={orderItem.price}
                  count={orderItem.amount.toString()}
                />
              );
            });
        }

        if (this._merchant instanceof SpMerchant && this._merchant.canShareOrder) {
          this._order.orderType = OrderTypes.RECEIPT;
        } else {
          if (this._order.webcode !== this._webcode) {
            this._clearOrder(this._webcode);
            this._deliveryOrder = null;
            this._takeawayOrder = null;
          }
          if (this._merchant instanceof TaMerchant) {
            if (this._merchant.takeaway === false && this._merchant.delivery === true) {
              this._order.orderType = OrderTypes.DELIVERY;
            } else if (this._merchant.takeaway === true && this._merchant.delivery === false) {
              this._order.orderType = OrderTypes.TAKEAWAY;
            }

            if (this._deliveryOrder?.paymentMethod == null) {
              this._deliveryOrder = null;
            }
            if (this._deliveryOrder != null) {
              this._updateDeliveryOrder({ deliveryCosts: this._getDeliveryCosts(this._contactDetails.postalCode) });
              // this._updateDeliveryOrder({ paymentMethod: this._merchant.paymentMethods[0] });
            } else {
              let paymentMethod = this._merchant.paymentMethods.find((paymentMethod) => {
                return paymentMethod.delivery === true;
              });
              this._deliveryOrder = new DeliveryOrder(
                null,
                paymentMethod,
                false,
                this._getDeliveryCosts(this._contactDetails.postalCode),
                null,
                0
              );
              sessionStorage.setItem(sessionStorage.storageNames.DELIVERY_ORDER, JSON.stringify(this._deliveryOrder));
            }
            if (this._takeawayOrder?.paymentMethod == null) {
              this._takeawayOrder = null;
            }
            if (this._takeawayOrder == null) {
              let paymentMethod = this._merchant.paymentMethods.find((paymentMethod) => {
                return paymentMethod.takeaway === true;
              });
              this._takeawayOrder = new TakeawayOrder(null, paymentMethod, false, null, 0);
              sessionStorage.setItem(sessionStorage.storageNames.TAKEAWAY_ORDER, JSON.stringify(this._takeawayOrder));
            } else {
              if (this._takeawayOrder.paymentMethod.takeaway === false) {
                let paymentMethod = this._merchant.paymentMethods.find((paymentMethod) => {
                  return paymentMethod.takeaway === true;
                });
                this._takeawayOrder.paymentMethod = paymentMethod;
                sessionStorage.setItem(sessionStorage.storageNames.TAKEAWAY_ORDER, JSON.stringify(this._takeawayOrder));
              }
            }
            this._updateContactDetails(this._contactDetails);
          } else {
            this._order.orderType = OrderTypes.RECEIPT;
          }
        }

        this.checkIfOrderArticlesAreProperArticles();
        this.emit(this.events.MENU_CREATED, { merchant: action.merchant });
        break;
      case this.actions.SET_ORDER:
        this._setOrder(action.order);
        break;
      case this.actions.SET_FAILED_ORDER_DIALOG_IS_OPEN:
        this._failedOrderDialogIsOpen = action.isOpen;
        break;
      case this.actions.SET_ORDER_TYPE:
        this._setOrderType(action.orderType);
        this._updateContactDetails(this._contactDetails);
        this.emit(this.events.ORDER_TYPE_CHANGED);
        break;
      case this.actions.SET_PHONE_NUMBER:
        this._setPhoneNumberOfReceipt(action.phoneNumber);
        this.emit(this.events.PHONE_NUMBER_CHANGED);
        break;
      case this.actions.SET_RECEIPT:
        this._setReceipt(action.receipt);
        mynOberAPIClientManager.sendUserLog2({ SET_RECEIPT: "this.actions.SET_RECEIPT" }, "this.actions.SET_RECEIPT");

        Sentry.configureScope((scope) => {
          scope.setTag("merchantId", action.receipt.merchant.id);
          scope.setTag("merchantName", action.receipt.merchant.name);
        });
        this.emit(this.events.RECEIPT_CHANGED);
        break;
      case this.actions.CLEAR_RECEIPT:
        if (this._receipt != null) {
          mynOberAPIClientManager.sendUserLog2({ CLEARED_RECEIPT: true }, "CLEARED_RECEIPT");
          this._menus = null;
          this._menusWithLag = null;
          this._setReceipt(null);
          this._setSpQrCode(null)
          MynOberAPIClientManager.resetPusher();
          this.emit(this.events.RECEIPT_CLEARED);
        } else {
          this.emit(this.events.RECEIPT_NOT_CLEARED);
        }
        break;
      case this.actions.SET_RECEIPT_WITH_MERCHANT_AND_MENU:
        this._menus = null;
        this._menusWithLag = null;
        mynOberAPIClientManager.sendUserLog2(
          { SET_RECEIPT: "this.actions.SET_RECEIPT_WITH_MERCHANT_AND_MENU" },
          "this.actions.SET_RECEIPT_WITH_MERCHANT_AND_MENU"
        );
        this._setReceipt(action.receipt);
        this._setMerchant(action.merchant);
        this._setMerchantUpdateTimer();
        this._setMenu(action.menu);
        if (action.webcode != null) {
          this._setWebcode(action.webcode);
        }
        if (action.receipt.webcode != null) {
          this._setWebcode(action.receipt.webcode);
        }
        if (!(this._merchant instanceof SpMerchant && this._merchant.canShareOrder)) {
          this._clearOrder(this._webcode, OrderTypes.RECEIPT);
        }

        Sentry.configureScope((scope) => {
          scope.setTag("merchantId", this._merchant.id);
          scope.setTag("merchantName", this._merchant.name);
        });

        this.checkIfOrderArticlesAreProperArticles();
        this.emit(this.events.MENU_CREATED, { merchant: action.merchant });
        break;
      case this.actions.SET_REFRESH_TOKEN:
        this._setRefreshToken(action.refreshToken).then(() => {});
        break;
      case this.actions.SET_WEBCODE:
        this._setWebcode(action.webcode);
        this.emit(this.events.WEBCODE_CHANGED);
        break;
      case this.actions.SET_SP_QR_CODE:
        this._setSpQrCode(action.spQrCode);
        break;
      case this.actions.SET_SHARED_ORDER_UUID:
        if (this._sharedOrderUuid !== action.sharedOrderUuid) {
          this._sharedOrderUuid = action.sharedOrderUuid;
          this.sharedOrderUuidSeconds =
            getApplicationDate().getTime() / 1000 + (action.shareOrderSeconds ?? this._merchant.shareOrderSeconds);
          this.emit(this.events.SHARED_ORDER_UUID_UPDATED, action.isFromWebsocket);
        }
        break;
      case this.actions.UPDATE_ORDER:
        let customFields = false;
        Object.keys(action.properties).forEach((property) => {
          this._order[property] = action.properties[property];
          if (property === "customFields") {
            customFields = true;
          }
        });
        sessionStorage.setItem(sessionStorage.storageNames.ORDER, JSON.stringify(this._order));
        if (!customFields) {
          this.emit(this.events.ORDER_CHANGED);
        }
        break;
      case this.actions.CLEAR_ORDER:
        this._clearOrder(this._order.webcode, this._order.orderType);
        this.emit(this.events.ORDER_CHANGED);
        break;
      case this.actions.SET_PIN_TRANSACTION:
        this.pinTransaction = action.pinTransaction;
        this.emit(this.events.PIN_TRANSACTION_UPDATED);
        break;
      default:
        break;
    }
  }

  get sharedOrderUuid() {
    return this._sharedOrderUuid;
  }

  get contactDetails() {
    return this._contactDetails;
  }

  get deliveryOrder() {
    return this._deliveryOrder;
  }

  get takeawayOrder() {
    return this._takeawayOrder;
  }

  get failedOrderDialogIsOpen() {
    return this._failedOrderDialogIsOpen;
  }

  /**
   * Returns if app is isLoading.
   * @return {boolean}
   */
  get isLoading() {
    return this._isLoading;
  }

  /**
   * Returns if app is in wide screen mode.
   * @return {boolean}
   */
  get isWidescreen() {
    return this._isWidescreen;
  }

  get menu() {
    return this._menu;
  }

  /**
   * Returns merchant if there is any.
   * @returns {Merchant|null}
   */
  get merchant() {
    return this._merchant;
  }

  get spQrCode() {
    return this._spQrCode;
  }

  /**
   * Returns current order.
   * @returns {Order}
   */
  get order() {
    return this._order;
  }

  get defaultOrderArticles() {
    return this._defaultOrderArticles;
  }

  get defaultOrderAmount() {
    return this._defaultOrderAmount;
  }

  /**
   * Returns current receipt or a dummy receipt when current receipt is null.
   * @returns {Receipt}
   */
  get receipt() {
    if (this._receipt === null) {
      return new Receipt(0, { en: "Table" }, -1, "", "", "");
    } else {
      return this._receipt;
    }
  }

  /**
   * Returns current user.
   * @returns {User}
   */
  get user() {
    return this._user;
  }

  /**
   * Returns saved webcode, returns null if there isn't one.
   * @returns {string|null}
   */
  get webcode() {
    return this._webcode;
  }

  /**
   *
   * @returns {string|null}
   */
  get searchParameters() {
    return this._searchParameters;
  }

  get isAppVisible() {
    return this._isAppVisible;
  }

  /**
   * Adds article with amount and modifiergroups as OrderArticle to order.
   * @param {OrderArticle} orderArticle
   * @param callSharedOrderUpdated
   */
  _addArticleToOrder(orderArticle, callSharedOrderUpdated) {
    if (this._merchant instanceof SpMerchant && callSharedOrderUpdated) {
      if (!mynOberStore.isHandheld() && this._merchant.canShareOrder) {
        let uuid = uuidv4();

        let modifiergroupsTransaction = orderArticle.orderModifiergroups.map((orderModifiergroup) => {
          return {
            modifierarticles: orderModifiergroup.orderModifierarticles.map((orderModifierarticle) => {
              return { modifierarticle_id: orderModifierarticle.id, amount: orderModifierarticle.count };
            }),
            modifiergroup_id: orderModifiergroup.id,
          };
        });

        let transaction = {
          uuid: uuid,
          article_id: orderArticle.id,
          amount: orderArticle.count,
          modifiergroups: modifiergroupsTransaction,
        };
        mynOberAPIClientManager
          .updateShareOrder(transaction, this._webcode)
          .then((response) => {})
          .catch((rejected) => {});
      }
    }

    this._order.addOrderArticleToOrder(orderArticle);
    sessionStorage.setItem(sessionStorage.storageNames.ORDER, JSON.stringify(this._order));
  }

  /**
   * Clears the timer for the Merchant.
   */
  _clearMerchantUpdateTimer() {
    if (this._merchantUpdateTimer !== null) {
      clearTimeout(this._merchantUpdateTimer);
      this._merchantUpdateTimer = null;
    }
  }

  /**
   * Creates new order.
   * @private
   */
  _clearOrder(webcode, orderType) {
    this.transactionUUIDs = [];
    sessionStorage.removeItem(sessionStorage.storageNames.ORDER);
    sessionStorage.removeItem(sessionStorage.storageNames.DELIVERY_ORDER);
    sessionStorage.removeItem(sessionStorage.storageNames.TAKEAWAY_ORDER);
    this._order = new Order(webcode, orderType);

    if (this.filteredOutOrderArticles?.length > 0) {
      this.filteredOutOrderArticles.forEach((orderArticle) => {
        this._addArticleToOrder(orderArticle, true);
      });
      this.filteredOutOrderArticles = [];
    }
  }

  /**
   * Exits current merchant by clearing menu, merchant, order, selectedArticle and webcode.
   * @private
   */
  _exitMerchant() {
    this._menu = null;
    this._clearMerchantUpdateTimer();
    this._merchant = null;
    this._webcode = null;
    this._clearOrder();
    this._order = new Order(this._webcode);
    this._selectedArticle = null;
    this.transactionUUIDs = [];
  }

  _getDataFromLocalStorage(isHandheld) {
    this._user = localStorage.getUserFromLocalStorage(isHandheld);
  }

  /**
   * Returns true if current receipt is not null.
   * @returns {boolean}
   */
  hasReceipt() {
    if (this._receipt != null) {
      return this._receipt.receiptArticles.length !== 0;
    }

    return false;
  }

  /**
   * Returns true if user has active receipt at current merchant.
   * @return {boolean}
   */
  hasReceiptAtCurrentMerchant() {
    if (this.hasReceipt()) {
      if (this._merchant instanceof SpMerchant && this._receipt.merchant.id === this._merchant.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns true if user has active receipt at another merchant than the current one stored.
   * @return {boolean}
   */
  hasReceiptElsewhere() {
    if (this._merchant === null) {
      return false;
    } else if (this._merchant instanceof TaMerchant) {
      return false;
    } else if (this.hasReceipt()) {
      if (this._receipt.merchant.id !== this._merchant.id) {
        return true;
      }
    }

    return false;
  }

  /**
   * Returns true if there is a active receipt or webcode stored.
   * @return {boolean}
   */
  hasReceiptOrWebcode() {
    return this.hasReceipt() || this._webcode !== null;
  }

  /**
   * Returns if AYCE articles can be selected.
   * @returns {boolean}
   */
  isAYCEEnabled() {
    if (!this.isHandheld() && this._merchant instanceof AyceMerchant) {
      return this._menusWithLag?.covers > 0;
    }

    return true;
  }

  /**
   * Returns if current user is a HandheldUser.
   * @returns {boolean}
   */
  isHandheld() {
    return this._user instanceof HandheldUser;
  }

  /**
   * Returns true if there can be ordered at the current merchant.
   * @return {boolean}
   */
  isOrderingPossible() {
    if (this.isHandheld()) {
      return true;
    }
    if (this._merchant !== null) {
      if (this._merchant.manuallyClosed) {
        return false;
      }
      if (this._merchant instanceof TaMerchant) {
        if (this._merchant.active && (this._merchant.takeawayActive || this._merchant.deliveryActive)) {
          return true;
        } else {
          return false;
        }
      }
      if (this._merchant.isOpen) {
        if (!this.hasReceipt()) {
          return true;
        } else if (this._receipt.merchant.id === this._merchant.id) {
          return true;
        }
      }
    }

    return false;
  }

  async _loginHandheldUser(password, username) {
    localStore.setItem(localStorage.storageNames.HANDHELD_USERNAME, username);
    localStore.setItem(localStorage.storageNames.HANDHELD_PASSWORD, password);

    this._user.login(username, password);
  }

  async _logoutHandheldUser() {
    await localStore.removeItem(localStorage.storageNames.HANDHELD_USERNAME);
    await localStore.removeItem(localStorage.storageNames.HANDHELD_PASSWORD);

    this._user.logout();
  }

  /**
   * Returns true if distance should be calculated before sending action to server.
   * @return {boolean}
   */
  needsDistanceForActions() {
    if (this._merchant === null) {
      return false;
    } else {
      if (this.isHandheld()) {
        return false;
      } else if (this._merchant instanceof TaMerchant) {
        return false;
      } else {
        return this._merchant.useGeoLocation;
      }
    }
  }

  /**
   * Resets user device id, device pass and refresh token.
   * @private
   */
  async _resetUser() {
    this._user = await localStorage.createNewAccount(this.isHandheld());
  }

  _updateDeliveryOrder(deliveryOrder) {
    Object.keys(deliveryOrder).forEach((key) => {
      this._deliveryOrder[key] = deliveryOrder[key];
    });
    sessionStorage.setItem(sessionStorage.storageNames.DELIVERY_ORDER, JSON.stringify(this._deliveryOrder));
    this.emit(this.events.DELIVERY_ORDER_UPDATED);
  }

  _updateTakeawayOrder(takeawayOrder) {
    Object.keys(takeawayOrder).forEach((key) => {
      this._takeawayOrder[key] = takeawayOrder[key];
    });
    sessionStorage.setItem(sessionStorage.storageNames.TAKEAWAY_ORDER, JSON.stringify(this._takeawayOrder));
    this.emit(this.events.TAKEAWAY_ORDER_UPDATED);
  }

  _updateContactDetails(contactDetails) {
    let newContactDetails = { ...this._contactDetails, ...contactDetails };

    if (this._merchant?.info?.default_contact_details != null) {
      let details = null;
      switch (this._order.orderType) {
        case OrderTypes.TAKEAWAY:
          details = this._merchant?.info?.default_contact_details?.takeaway;
          break;
        case OrderTypes.DELIVERY:
          details = this._merchant?.info?.default_contact_details?.delivery;
          break;
      }
      if (details) {
        Object.keys(details).forEach((key) => {
          if (newContactDetails[key] == null || newContactDetails[key].length === 0) {
            newContactDetails[key] = details[key].value;
          }
        });
      }
    }
    this._contactDetails = newContactDetails;

    if (this._order.orderType === OrderTypes.DELIVERY) {
      if (contactDetails.postalCode != null) {
        // console.log(this._order.amount);
        this._updateDeliveryOrder({ deliveryCosts: this._getDeliveryCosts(contactDetails.postalCode) });
      }
    }

    if (this._merchant?.info?.ask_contact_details?.save_contact_information_in_session !== 0) {
      sessionStorage.setItem(sessionStorage.storageNames.CONTACT_DETAILS, JSON.stringify(this._contactDetails));
    }
    this.emit(this.events.CONTACT_DETAILS_UPDATED);
  }

  _getDeliveryCosts = (value) => {
    let taPostalCode = this._merchant.taPostalCodes.find((taPostalCode) => {
      return value.includes(taPostalCode.postalCode);
    });
    if (taPostalCode != null) {
      if (taPostalCode.amountWithExtraCosts === 0 || this._order.amount < taPostalCode.amountWithExtraCosts) {
        return taPostalCode.extraCosts;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  _updateReceipt(receipt) {
    // handheld action
    this._receipt = { ...receipt };
    this.emit(this.events.RECEIPT_CHANGED);
  }

  forceSetMenus(menus) {
    this._menus = menus;
    this._menusWithLag = menus;
    mynOberStore.emit(mynOberStore.events.MERCHANT_CREATED);
  }

  _setMenus = () => {
    let menus = this._calculateMenus();

    this._menus = menus;
    if (this._menusWithLag == null) {
      this._menusWithLag = this._menus;
    } else {
      if (
        this._menus.menuTags?.length > this._menusWithLag.menuTags?.length ||
        this._menus?.covers > this._menusWithLag?.covers
      ) {
        this._menusWithLag = this._menus;
      } else {
        if (!this._updateMenusTimer) {
          this._updateMenusTimer = setTimeout(() => {
            this._updateMenusTimer = null;
            this._menusWithLag = this._menus;
            mynOberStore.emit(mynOberStore.events.MERCHANT_CREATED);
          }, 60000);
        }
      }
    }
  };

  _calculateMenus = () => {
    if (
      !this.isHandheld() &&
      !this._merchant.useMenuTags &&
      !(mynOberStore.merchant instanceof AyceMerchant) &&
      !(mynOberStore.merchant instanceof TaMerchant)
      // && mynOberStore.merchant != null
    ) {
      return null;
    }

    let menuTags = this._merchant.menuTags ?? []; // QRCODE menu Tags
    let covers = 0;
    let activeMArticleIds = {};

    // if (false && this._merchant.activeMArticlePlus) {
    if (mynOberStore.receipt?.receiptArticles.length > 0) {
      // Calculate from receipt

      mynOberStore.receipt.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
        if (!activeMArticleIds[receiptArticle.id]) {
          activeMArticleIds[receiptArticle.id] = { count: receiptArticle.countOpen ?? receiptArticle.count };
        } else {
          activeMArticleIds[receiptArticle.id].count += receiptArticle.countOpen ?? receiptArticle.count;
        }

        let articleFromMenu = mynOberStore.menu.getArticleById(receiptArticle.id);
        if (articleFromMenu && (receiptArticle.countOpen ?? receiptArticle.count) > 0 && articleFromMenu.isMenu) {
          articleFromMenu.menuTags.forEach((menuTag) => {
            if (!menuTags.includes(menuTag)) {
              menuTags.push(menuTag);
            }
          });

          covers += receiptArticle.countOpen ?? receiptArticle.count;
        }
      });
    } else if (mynOberStore.merchant?.activeMArticlePlus) {
      // Calculate from merchant
      Object.keys(mynOberStore.merchant.activeMArticlePlus).forEach((plu) => {
        let articleFromMenu = mynOberStore.menu.getArticleByPlu(plu);
        let count = mynOberStore.merchant.activeMArticlePlus[plu].count;
        if (articleFromMenu && count > 0) {
          if (!activeMArticleIds[articleFromMenu.id]) {
            activeMArticleIds[articleFromMenu.id] = { count: count };
          } else {
            activeMArticleIds[articleFromMenu.id].count += count;
          }

          if (count > 0 && articleFromMenu.isMenu) {
            articleFromMenu.menuTags.forEach((menuTag) => {
              if (!menuTags.includes(menuTag)) {
                menuTags.push(menuTag);
              }
            });

            covers += count;
          }
        }
      });
    }

    return { covers, activeMArticleIds, menuTags };
  };

  get merchantShouldUpdate() {
    return this._merchantShouldUpdate;
  }

  get updateMenusTimer() {
    return this._updateMenusTimer;
  }

  get menus() {
    return this._menus;
  }

  get menusWithLag() {
    return this._menusWithLag;
  }

  get menuTagsWithLag() {
    return this._menusWithLag?.menuTags;
  }

  get coversWithLag() {
    return this._menusWithLag?.covers;
  }

  get activeMArticleIdsWithLag() {
    return this._menusWithLag?.activeMArticleIds;
  }

  /**
   * Sets boolean for FirstAskNotification and saves it to localStorage.
   * @param value
   */
  async _setFirstAskNotification(value) {
    await localStore.setItem(localStorage.storageNames.FIRST_ASK_NOTIFICATION, value);
    this._user.firstAskNotification = value;
  }

  /**
   * Sets boolean for FirstTimeApp and saves it to localStorage.
   * @param firstTime
   */
  async _setFirstTimeApp(firstTime) {
    await localStore.setItem(localStorage.storageNames.FIRST_TIME_APP, firstTime);
    this._user.firstTimeApp = firstTime;
  }

  /**
   * Sets isAppVisible.
   * @param {boolean} visible
   * @private
   */
  _setIsAppVisible(visible) {
    this._isAppVisible = visible;
  }

  /**
   * Sets boolean for _isLoading.
   * @param {boolean} isLoading
   * @private
   */
  _setIsLoading(isLoading) {
    if (this._isLoading !== isLoading) {
      this._isLoading = isLoading;
      return true;
    }
    return false;
  }

  /**
   * Sets new isWidescreen.
   * @param isWidescreen
   * @private
   */
  _setIsWidescreen(isWidescreen) {
    this._isWidescreen = isWidescreen;
  }

  /**
   * Sets new menu.
   * @param menu
   * @private
   */
  _setMenu(menu) {
    this._menu = menu;
  }

  /**
   * Sets new merchant and calls _setMerchantUpdateTimer.
   * @param merchant
   * @private
   */
  _setMerchant(merchant) {
    this._merchant = merchant;

    this._setMenus();
  }

  _setSpQrCode(spQrCode) {
    this._spQrCode = spQrCode;
  }

  /**
   * Sets update timer for AyceMerchant.
   * @private
   */
  _setMerchantUpdateTimer(milliseconds = 30000) {
    if (this._merchantUpdateTimer != null) {
      clearTimeout(this._merchantUpdateTimer);
      this._merchantUpdateTimer = null;
    }

    if (this._merchant instanceof AyceMerchant && !this.isHandheld()) {
      this._merchantUpdateTimer = setTimeout(() => {
        if (this._isAppVisible) {
          this._updateMerchant();
        } else {
          this._merchantShouldUpdate = true;
        }
      }, milliseconds);
    }
  }

  _setOrder(order) {
    this.transactionUUIDs = [];
    this._order = order;
    sessionStorage.setItem(sessionStorage.storageNames.ORDER, JSON.stringify(this._order));
  }

  _setOrderType(orderType) {
    this._order.orderType = orderType;
    sessionStorage.setItem(sessionStorage.storageNames.ORDER, JSON.stringify(this._order));
  }

  /**
   * Sets phoneNumber of receipt
   * @param {number} phoneNumber
   * @private
   */
  _setPhoneNumberOfReceipt(phoneNumber) {
    this._receipt.phoneNumber = phoneNumber;
  }

  /**
   * Sets receipt for MynOber user, returns TRUE if param is NULL but receipt is NOT NULL.
   * @param {Receipt|null} receipt
   * @return {boolean}
   * @private
   */
  _setReceipt(receipt) {
    let bodyElement = document.getElementsByTagName("body")[0];
    if (bodyElement !== null) {
      if (receipt != null && receipt.id > 0) {
        bodyElement.dispatchEvent(new Event("hasReceipt"));
      } else {
        bodyElement.dispatchEvent(new Event("hasNoReceipt"));
      }
    }
    this._receipt = receipt;
    this._setMenus();
    if (receipt) {
      this.emit(this.events.MERCHANT_CREATED);
    }
  }

  /**
   * Sets new refreshToken.
   * @param refreshToken
   */
  async _setRefreshToken(refreshToken) {
    await localStore.setItem(localStorage.storageNames.REFRESH_TOKEN, refreshToken);
    this._user.refreshToken = refreshToken;
  }

  /**
   * Sets new webcode.
   * @param {string=} webcode
   * @private
   */
  _setWebcode(webcode) {
    if (webcode != null) {
      if (!this.isHandheld()) {
        webcode = webcode.toUpperCase();
      }
      this._webcode = webcode;
      sessionStorage.setItem(sessionStorage.storageNames.WEBCODE, webcode);
    }
  }

  /**
   *
   * @param searchParameters
   * @private
   */
  _setSearchParameters(searchParameters) {
    this._searchParameters = searchParameters;
  }

  /**
   * Will update the couverts at current AyceMerchant via webcode or receipt.
   * @private
   */
  _updateMerchant() {
    this._merchantShouldUpdate = false;
    if (this._webcode == null || this.hasReceipt()) {
      this._updateMerchantViaReceipt();
    } else {
      this._updateMerchantViaWebcode(this._webcode);
    }
  }

  /**
   * Updates merchant in MynOberStore via getting receipt of user.
   * @private
   */
  _updateMerchantViaReceipt() {
    if (this._merchant != null && this._merchant instanceof AyceMerchant) {
      mynOberAPIClientManager
        .getReceipt()
        .then(({ receipt, wrongPincode }) => {
          if (receipt != null) {
            this._setMerchantUpdateTimer(180000);
            mynOberAPIClientManager.sendUserLog2(
              { SET_RECEIPT: "updateMerchantViaReceipt" },
              "updateMerchantViaReceipt"
            );
            this._setReceipt(receipt);
            this.emit(this.events.RECEIPT_CHANGED);
          }
        })
        .catch((error) => {
          errorHandler.sendError(error);
          this._setMerchantUpdateTimer();
        });
    }
  }

  /**
   * Updates merchant in MynOberStore via sending it's webcode.
   * @param webcode
   * @private
   */
  _updateMerchantViaWebcode(webcode) {
    if (this._merchant != null && this._merchant instanceof AyceMerchant) {
      mynOberAPIClientManager
        .getMerchantForWebcode(webcode)
        .then((merchant) => {
          this._setMerchantUpdateTimer(30000);
          this._setMerchant(merchant);
          this.emit(this.events.MERCHANT_CREATED);
          // this._merchant.activeMArticleIds = merchant.activeMArticleIds;
        })
        .catch((error) => {
          this._setMerchantUpdateTimer();
        });
    }
  }

  checkIfOrderArticlesAreProperArticles = () => {
    let menuObject = {};
    this._menu.articles.forEach((article) => {
      menuObject[article.id] = article;
    });

    let orderArticles = [];
    let orderArticlesRemoved = [];
    Object.assign(orderArticles, this._order.orderArticles);
    orderArticles.forEach((orderArticle: OrderArticle) => {
      let article = menuObject[orderArticle.id];
      if (article == null || !article.isActive) {
        let orderArticleToRemove = new OrderArticle(orderArticle);
        Object.assign(orderArticleToRemove, orderArticle);
        orderArticleToRemove.count *= -1;
        this._addArticleToOrder(orderArticleToRemove, false);
        orderArticlesRemoved.push(orderArticleToRemove);
      } else {
        Object.assign(orderArticle, article);
      }
    });

    if (orderArticlesRemoved.length > 0) {
      this.emit(this.events.ORDER_ARTICLES_REMOVED_AUTOMATICALLY, orderArticlesRemoved);
    }

    this.emit(this.events.ORDER_CHANGED);
  };
}

const mynOberStore = new MynOberStore();
mynOberDispatcher.register(mynOberStore._handleActions.bind(mynOberStore));
export default mynOberStore;
window.mynOberStore = mynOberStore;
