import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import "./ViewPhoneNumber.css";
import { Button, Typography } from "@material-ui/core";

class ViewPhoneNumber extends Component {
  render() {
    return (
      <div className={"view-phone-number"}>
        <Typography>
          {this.props.intl.formatMessage({ id: this.props.stringText }, { phoneNumber: this.props.phoneNumber })}
        </Typography>
        <Button style={{ margin: "8px 8px" }} variant={"outlined"} onClick={this.props.onClickChange}>
          {this.props.intl.formatMessage({ id: this.props.stringButton })}
        </Button>
      </div>
    );
  }
}

ViewPhoneNumber.propTypes = {
  stringText: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  stringButton: PropTypes.string.isRequired,
  onClickChange: PropTypes.func.isRequired,
};

export default injectIntl(ViewPhoneNumber);
