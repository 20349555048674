import { addMilliseconds, parse } from "date-fns";

window.serverUnixTimestampOffset = 0;

export function getValue(obj, key, defaultValue = undefined) {
  let o = key.split(".").reduce(function (o, x) {
    return typeof o == "undefined" || o === null ? (defaultValue === undefined ? o : defaultValue) : o[x];
  }, obj);

  if (o === undefined) {
    return defaultValue;
  } else {
    return o;
  }
}

export function getLocalDateFromUTC(utcDate) {
  return parse(utcDate + "Z", "yyyy-MM-dd HH:mm:ssX", getApplicationDate());
}

export function getApplicationDate() {
  const date = new Date()
  return addMilliseconds(date, window.serverUnixTimestampOffset);
}

export function getLocalDateFromTimestampFormattedString(timestamp) {
  let date = new Date(timestamp * 1000);
  return formatDateTime(date);
}

export function getLocalDateFromUTCFormattedString(utcDate) {
  let date = getLocalDateFromUTC(utcDate);
  return formatDateTime(date);
}

function formatDateTime(date) {
  let padTime = (unit) => {
    if (unit.toString().length === 1) {
      return "0" + unit;
    } else {
      return unit;
    }
  };

  let hours = padTime(date.getHours());
  let minutes = padTime(date.getMinutes());

  if (getApplicationDate().getDate() !== date.getDate()) {
    let years = date.getFullYear();
    let month = padTime((Number.parseInt(date.getMonth()) + 1).toString());
    let day = padTime(date.getDate());
    return years + "-" + month + "-" + day + " " + hours + ":" + minutes;
  } else {
    return hours + ":" + minutes;
  }
}

export function mergeObjects(object1, object2, mergeNulls = true) {
  object1 = { ...object1 };
  if (!object1) {
    object1 = {};
  }
  if (object2) {
    Object.keys(object2).forEach((key) => {
      if (object1[key] === undefined) {
        object1[key] = object2[key];
      } else {
        if (object2[key] instanceof Object) {
          object1[key] = mergeObjects(object1[key], object2[key], mergeNulls);
        } else {
          if (mergeNulls) {
            object1[key] = object2[key];
          } else if (object2[key] != null) {
            object1[key] = object2[key];
          }
        }
      }
    });
  }
  return object1;
}

export function isInDirectSaleMode(location) {
  if (location.search == null) {
    return false;
  }
  let urlSearchParams = new URLSearchParams(location.search);
  return urlSearchParams.has("directsalemode") && urlSearchParams.get("directsalemode") === "1";
}

window.getValue = getValue;
