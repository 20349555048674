import * as sentryInterface from "./SentryInterface";
import mynOberStore from "../flux/MynOberStore";
import mynOberAPIClientManager from "../api/MynOberAPIClientManager";
import { TaMerchant } from "../merchant/TaMerchant";

/**
 * Captures error in Sentry.
 * @param {Error} error
 */
export function sendError(error) {
  if (error == null) {
    return;
  }
  if (
    error.message === mynOberAPIClientManager.apiMessages.UNAUTHORIZED ||
    error.message === mynOberAPIClientManager.apiMessages.UNKNOWN_ERROR
  ) {
    return;
  }
  sentryInterface.captureException(error);
}

export function setup() {
  if (process.env.NODE_ENV !== "development") {
    // sentryInterface.config("https://8875753da52b4466a0095604c7db3d35@o97419.ingest.sentry.io/1433197", process.env.NODE_ENV === "development" ? "staging" : "production", process.env.REACT_APP_VERSION);
    sentryInterface.config(
      "https://0d438784fc1b42dc86a92d8592447755@o210199.ingest.sentry.io/5432395",
      process.env.NODE_ENV === "development" ? "staging" : "production",
      process.env.REACT_APP_VERSION
    );
  }
}

export function setUser(deviceId, isHandheld) {
  sentryInterface.setUser(deviceId, isHandheld);
}

export function qrCodeMissingError() {
  sentryInterface.captureException(new Error("Test Error with options"), {
    merchantId: mynOberStore.merchant.id,
    hasReceipt: mynOberStore.hasReceipt(),
    isTaMerchant: mynOberStore.merchant instanceof TaMerchant,
  });
}
