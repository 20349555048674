import mynOberAPIClientManager from "../api/MynOberAPIClientManager";
import * as mynOberActions from "../flux/MynOberActions";
import mynOberStore from "../flux/MynOberStore";
import * as sessionStorage from "../session_storage/SessionStorage";
import * as jsonProcessingFunctions from "./JsonProcessingFunctions";
import * as errorHandler from "../error_handler/ErrorHandler";
import { PathNames } from "../../routers/PathNames";
import ReactDOM from "react-dom";
import App from "../../../App";
import { HostTypes } from "../HostTypes";
import React from "react";
import { SpMerchant } from "../merchant/SpMerchant";

function getMenuForReceipt(receipt) {
  mynOberAPIClientManager
    .getMerchantWithMenuForWebcode(receipt.webcode)
    .then((response) => {
      mynOberActions.setReceiptWithMerchantAndMenu(receipt, response.merchant, response.menu, receipt.webcode);
      startApp();
    })
    .catch((error) => {
      startApp();
    });
  return;
}

function getMenuForWebcode(webcode) {
  mynOberAPIClientManager
    .getMerchantWithMenuForWebcode(webcode)
    .then((response) => {
      if (mynOberStore.isHandheld()) {
        if (sessionStorage.getItem(sessionStorage.storageNames.WEBCODE) != null) {
          webcode = sessionStorage.getItem(sessionStorage.storageNames.WEBCODE);
        }
        if (sessionStorage.getItem(sessionStorage.storageNames.ORDER) != null) {
          mynOberActions.setOrder(
            jsonProcessingFunctions.processJsonOrder(
              JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER))
            )
          );
        }
      }

      if (!mynOberStore.isHandheld() && response.merchant instanceof SpMerchant) {
        mynOberAPIClientManager
          .getReceipt(webcode, !response.merchant?.shieldedReceipt)
          .then(({ receipt, wrongPincode }) => {
            if (receipt != null) {
              mynOberActions.setReceiptWithMerchantAndMenu(receipt, response.merchant, response.menu, webcode);
            } else {
              mynOberActions.setMerchantWithMenuAndWebcode(response.merchant, response.menu, webcode);
            }
            startApp();
          })
          .catch((error) => {
            mynOberActions.setMerchantWithMenuAndWebcode(response.merchant, response.menu, webcode);
            startApp();
          });
      } else {
        mynOberActions.setMerchantWithMenuAndWebcode(response.merchant, response.menu, webcode);
        if (mynOberStore.isHandheld()) {
          mynOberAPIClientManager.getAllHandheldReceipts(true);
        }
        startApp();
      }
    })
    .catch((error) => {
      startApp();
    });
}

function getReceipt() {
  mynOberAPIClientManager
    .getReceipt()
    .then(({ receipt, wrongPincode }) => {
      if (receipt != null) {
        getMenuForReceipt(receipt);
      } else {
        let bodyElement = document.getElementsByTagName("body")[0];
        if (bodyElement !== null) {
          bodyElement.dispatchEvent(new Event("hasNoReceipt"));
        }
        startApp();
      }
    })
    .catch((error) => {
      errorHandler.sendError(error);
      startApp();
    });
}

function loadApp() {
  if (mynOberStore.isHandheld()) {
    if (mynOberStore.user.loggedIn) {
      getMenuForWebcode();
    } else {
      startApp();
    }
  } else {
    if (window.location.pathname.substr(0, 3) === PathNames.QRPAGE) {
      if (sessionStorage.getItem(sessionStorage.storageNames.ORDER) != null) {
        mynOberActions.setOrder(
          jsonProcessingFunctions.processJsonOrder(
            JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER))
          )
        );
      }
      getMenuForWebcode(window.location.pathname.substr(4));
    } else if (window.location.pathname.substr(0, 10) === PathNames.MERCHANT + "/") {
      if (
        sessionStorage.getItem(sessionStorage.storageNames.WEBCODE) != null &&
        (mynOberStore.merchant == null || mynOberStore.menu == null)
      ) {
        if (sessionStorage.getItem(sessionStorage.storageNames.ORDER) != null) {
          mynOberActions.setOrder(
            jsonProcessingFunctions.processJsonOrder(
              JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER))
            )
          );
        }
        getMenuForWebcode(sessionStorage.getItem(sessionStorage.storageNames.WEBCODE));
      } else {
        window.history.replaceState(null, "landingpage", PathNames.HOMEPAGE);
        window.location.reload();
      }
    } else {
      getReceipt();
    }
  }
}

function login() {
  errorHandler.setUser(mynOberStore.user.deviceId, mynOberStore.isHandheld());
  mynOberAPIClientManager
    .login(mynOberAPIClientManager.constructLoginData(null))
    .then((handheldUser) => {
      if (handheldUser) {
        mynOberActions.loginHandheldFromIndex(handheldUser);
      }
      loadApp();
    })
    .catch((error) => {
      loadApp();
    });
}

function startApp() {
  // mynOberStore.removeListener(mynOberStore.events.USER_CHANGED, login);
  // mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, startApp);
  ReactDOM.render(<App store={mynOberStore} />, document.getElementById("root"));
}

function checkHost() {
  let host = "";
  if (process.env.NODE_ENV !== "production") {
    host = window.location.host.split(".").reverse()[1];
    // return true;
  } else {
    host = window.location.host.split(".").reverse()[2];
  }

  return host === HostTypes.HANDHELD || host === HostTypes.STAGING_HANDHELD;
}

// registerServiceWorker();
let isHandheld = checkHost();
if (isHandheld) {
  mynOberAPIClientManager.baseURL = "https://handheldapi.mynober.nl";
}

errorHandler.setup();

// mynOberStore.on(mynOberStore.events.MENU_CREATED, startApp);
// mynOberStore.once(mynOberStore.events.USER_CHANGED, login);
mynOberActions.setIsWidescreen(window.innerWidth >= 960);
mynOberActions.getDataFromLocalStorage(isHandheld);
login();

let searchParams = new URLSearchParams(window.location.search);
if (typeof searchParams.get("postalcode") === "string") {
  mynOberActions.setContactDetails({ postalCode: searchParams.get("postalcode") });
}
if (typeof searchParams.get("city") === "string") {
  mynOberActions.setContactDetails({ city: searchParams.get("city") });
}
