import React, { Component } from "react";
import PropTypes from "prop-types";
import "./WideScreenRouter.css";
import { PathNames } from "./PathNames";
import * as listeners from "../utils/Listeners";
import { SpMerchant } from "../utils/merchant/SpMerchant";
import TakeawayPage from "../pages/web/TakeawayPage";
import DeliveryPage from "../pages/web/DeliveryPage";
import { Route, Switch, withRouter } from "react-router";
import OrderContainer from "../containers/smartphone/OrderContainer";
import ReceiptContainer from "../containers/smartphone/ReceiptContainer";
import mynOberStore from "../utils/flux/MynOberStore";
import CheckoutPage from "../pages/web/CheckoutPage";
import { HandheldPathNames } from "./handheld/HandheldRouter";
import HandheldReceiptContainer from "../containers/handheld/smartphone/HandheldReceiptContainer";
import MenuContainerHandheldWide from "../containers/MenuContainerHandheldWide";
import HandheldOrdersContainer from "../containers/handheld/smartphone/HandheldOrdersContainer";
import MenuContainerV2 from "../containers/smartphone/MenuContainerV2";

class WideScreenRouter extends Component {
  constructor(props) {
    super(props);

    if (mynOberStore.hasReceiptAtCurrentMerchant() && mynOberStore.order.count === 0) {
      if (mynOberStore.isHandheld()) {
        if (props.location.pathname.includes("menupage")) {
          props.history.replace(this.props.match.url + PathNames.RECEIPTPAGE);
        }
      } else {
        props.history.replace(PathNames.RECEIPTPAGE);
      }
    } else if (
      this.props.location.pathname !== PathNames.TAKEAWAYPAGE &&
      this.props.location.pathname !== PathNames.DELIVERYPAGE
    ) {
      if (mynOberStore.isHandheld()) {
        if (props.location.pathname.includes("menupage")) {
          props.history.replace(this.props.match.url + PathNames.ORDERPAGE);
        }
      } else {
        props.history.replace(PathNames.ORDERPAGE);
      }
    }

    this.splitScreenRightId = "split-screen-right";
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onDocumentScroll);
    if (!mynOberStore.isHandheld()) {
      if (this.props.location.pathname === PathNames.ORDERPAGE) {
        listeners.registerReceiptListeners(this.onReceiptChanged, this.onReceiptChanged);
      } else {
        listeners.registerOrderChangedListeners(this.onOrderChanged);
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onDocumentScroll);
    if (!mynOberStore.isHandheld()) {
      listeners.unregisterOrderChangedListeners(this.onOrderChanged);
      listeners.unregisterReceiptListeners(this.onReceiptChanged, this.onReceiptChanged);
    }
  }

  onDocumentScroll = () => {
    let splitScreenRight = document.getElementById(this.splitScreenRightId);
    if (splitScreenRight == null) {
      return;
    }
    if (window.scrollY < 0) {
      if (splitScreenRight.classList.contains("position-fixed")) {
        splitScreenRight.classList.add("position-absolute");
        splitScreenRight.classList.remove("position-fixed");
      }
    } else {
      if (splitScreenRight.classList.contains("position-absolute")) {
        splitScreenRight.classList.remove("position-absolute");
        splitScreenRight.classList.add("position-fixed");
      }
    }
  };

  onOrderChanged = () => {
    this.props.history.replace(PathNames.ORDERPAGE);
    listeners.unregisterOrderChangedListeners(this.onOrderChanged);
    listeners.registerReceiptListeners(this.onReceiptChanged, this.onReceiptChanged);
  };

  onReceiptChanged = () => {
    this.props.history.replace(PathNames.RECEIPTPAGE);
    listeners.unregisterReceiptListeners(this.onReceiptChanged, this.onReceiptChanged);
    listeners.registerOrderChangedListeners(this.onOrderChanged);
  };

  render() {
    return (
      <div className={"wide-screen-page"}>
        <div className={"split-screen-left"}>
          {mynOberStore.isHandheld() ? <MenuContainerHandheldWide /> : <MenuContainerV2 />}
        </div>
        <div id={this.splitScreenRightId} className={"split-screen-right"}>
          <Switch>
            <Route exact path={PathNames.TAKEAWAYPAGE} render={(props) => <TakeawayPage {...props} />} />

            <Route exact path={PathNames.DELIVERYPAGE} render={(props) => <DeliveryPage {...props} />} />
            <Route exact path={PathNames.CHECKOUTPAGE} render={(props) => <CheckoutPage {...props} />} />
            <Route exact path={PathNames.ORDERPAGE} render={(props) => <OrderContainer {...props} />} />
            <Route
              exact
              path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.ORDERPAGE}`}
              render={(props) => <OrderContainer {...props} />}
            />
            <Route exact path={PathNames.RECEIPTPAGE} render={(props) => <ReceiptContainer {...props} />} />
            <Route
              exact
              path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.RECEIPTPAGE}`}
              render={(props) => <HandheldReceiptContainer {...props} />}
            />
            <Route
              exact
              path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.ORDERSPAGE}`}
              render={(props) => <HandheldOrdersContainer {...props} />}
            />
          </Switch>
          {mynOberStore.receipt !== null && mynOberStore.merchant instanceof SpMerchant ? this.props.BottomTabs : null}
        </div>
      </div>
    );
  }
}

WideScreenRouter.propTypes = {
  hasReceiptAtCurrentMerchant: PropTypes.bool.isRequired,
  BottomTabs: PropTypes.element.isRequired,
};

export default withRouter(WideScreenRouter);
