import React, { useState } from "react";
import { PropTypes } from "prop-types";
import ViewReceiptArticle from "../../menu/ViewReceiptArticle";
import ViewOrderModifierarticle from "../../menu/ViewOrderModifierarticle";
import { HandheldOrdergroup } from "../../../utils/receipt/HandheldOrdergroup";
import "./OrdergroupOverview.css";
import withIntl from "../../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../../utils/flux/MynOberStore";
import { getLocalDateFromUTCFormattedString } from "../../../utils/functions/HelperFunctions";
import Typography from "@material-ui/core/Typography";
import { SpMerchant } from "../../../utils/merchant/SpMerchant";
import { CircularProgress, IconButton, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { Check, Clear, MoreHoriz } from "@material-ui/icons";
import DialogOk from "../../dialogs/DialogOk";
import GetCurrency from "../../../utils/functions/GetCurrency";
import { OrderModifiergroup } from "../../../utils/order/OrderModifiergroup";
import { OrderArticle } from "../../../utils/order/OrderArticle";
import * as mynOberActions from "../../../utils/flux/MynOberActions";
import { useDialog } from "../../dialogs/DialogProvider";
import { printInhouseOrder } from "../../../utils/functions/RequestFunctions";
import DialogHandheldOrdergroupActions from "../../dialogs/DialogHandheldOrdergroupActions";
import DialogOrdergroupFailed from "../../dialogs/DialogOrdergroupFailed";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTop: "1px solid " + theme.palette.text.primary,
    padding: "4px",
  },
}));

function OrdergroupOverview(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog] = useDialog();

  const createReceiptArticleViews = (receiptArticles) => {
    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      return true; //return receiptArticle.countOpen > 0;
    });

    return filteredReceiptArticles.map((receiptArticle, i) => {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          table={props.table}
          receipt={props.receipt}
          key={i}
          name={receiptArticle.displayName}
          amount={receiptArticle.amount}
          count={receiptArticle.count}
          orderModifierarticleViews={orderModifierarticleViews}
          receiptArticle={receiptArticle}
          showTurnoverGroups={true}
          showCountClosed={true}
        />
      );
    });
  };

  const addToOrder = () => {
    props.handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
      let article = mynOberStore.menu.getArticleById(receiptArticle.id);
      if (article == null) {
        return;
      }
      if (article.min_amount > 0) {
        if (mynOberStore.order.amount < article.min_amount) {
          openDialog({
            children: (
              <DialogOk
                title={"alert_title_warning"}
                rawContent={props.intl.formatMessage(
                  { id: "minimal_order_amount" },
                  { min_amount: (article.min_amount / 100.0).toFixed(2) + " " + GetCurrency() }
                )}
              />
            ),
          });
          return;
        }
      }

      let orderModifiergroupsToAdd = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        if (orderModifiergroup.count > 0) {
          let orderModifierarticles = [];
          orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
            if (orderModifierarticle.count > 0) {
              orderModifierarticles.push(orderModifierarticle);
            }
          });
          if (orderModifierarticles.length > 0) {
            orderModifiergroupsToAdd.push(new OrderModifiergroup(orderModifiergroup, orderModifierarticles));
          }
        }
      });

      let orderArticle = new OrderArticle(
        article,
        receiptArticle.count,
        orderModifiergroupsToAdd,
        receiptArticle.remark
      );
      mynOberActions.addOrderArticle(orderArticle);
    });
  };

  return (
    <div className={classes.root}>
      <div
        className={"ordergroup-header"}
        style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
      >
        <div>
          {props.roundNumber != null ? (
            <Typography>{props.intl.formatMessage({ id: "text_round" }, { number: props.roundNumber })}</Typography>
          ) : (
            <Typography>
              {props.intl.formatMessage({
                id: mynOberStore.isHandheld()
                  ? "text_ordered_by_handheld"
                  : props.handheldOrdergroup.isUser && mynOberStore.merchant instanceof SpMerchant
                  ? "ordered_with_app"
                  : "not_ordered_with_app",
              })}
            </Typography>
          )}
          <Typography>{getLocalDateFromUTCFormattedString(props.handheldOrdergroup.createdAt)}</Typography>
        </div>
        {props.handheldOrdergroup.posStatus === "PENDING" && (
          <Tooltip title={props.intl.formatMessage({ id: "order_is_being_processed" })}>
            <CircularProgress color="primary" size={25} style={{ margin: 8 }} />
          </Tooltip>
        )}
        {props.handheldOrdergroup.posStatus === "ORDERED" && (
          <Tooltip title={props.intl.formatMessage({ id: "order_is_processed" })}>
            <Check color="primary" size={25} style={{ margin: 8 }} />
          </Tooltip>
        )}
        {props.handheldOrdergroup.posStatus === "FAILED" && (
          <Tooltip title={props.intl.formatMessage({ id: "order_processing_failed" })}>
            <IconButton
              disabled={!props.handheldOrdergroup.isUser && !mynOberStore.isHandheld()}
              size={"small"}
              onClick={() => {
                openDialog({
                  children: <DialogOrdergroupFailed handheldOrdergroup={props.handheldOrdergroup} />,
                  fullWidth: true,
                  maxWidth: "md",
                  disableBackdropClick: true,
                });
              }}
            >
              <Clear color="error" size={25} style={{ margin: 8 }} />
            </IconButton>
          </Tooltip>
        )}
        {false && (
          <IconButton
            style={{ float: "right" }}
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
            }}
          >
            <MoreHoriz />
          </IconButton>
        )}
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem
            onClick={() => {
              addToOrder();
              setAnchorEl(null);
            }}
          >
            {props.intl.formatMessage({ id: "Add to order" })}
          </MenuItem>
          {mynOberStore.isHandheld() && (
            <MenuItem
              onClick={() => {
                printInhouseOrder(props.handheldOrdergroup.id);
                setAnchorEl(null);
              }}
            >
              {props.intl.formatMessage({ id: "Print" })}
            </MenuItem>
          )}
          {mynOberStore.isHandheld() && (
            <MenuItem
              onClick={() => {
                openDialog({
                  children: (
                    <DialogHandheldOrdergroupActions
                      handheldOrdergroup={props.handheldOrdergroup}
                      table={props.table}
                      receipt={props.receipt}
                    />
                  ),
                  maxWidth: "md",
                  fullWidth: true,
                });
                setAnchorEl(null);
              }}
            >
              {props.intl.formatMessage({ id: "Other" })}
            </MenuItem>
          )}
        </Menu>
      </div>
      {createReceiptArticleViews(props.handheldOrdergroup.receiptArticles)}

      {/*<p>*/}
      {/*SubTotaal € {(this.state.receipt.amountOpen / 100.0).toFixed(2)}*/}
      {/*</p>*/}
    </div>
  );
}

OrdergroupOverview.propTypes = {
  isFirst: PropTypes.bool.isRequired,
  handheldOrdergroup: PropTypes.instanceOf(HandheldOrdergroup).isRequired,
  roundNumber: PropTypes.number,
};

export default withIntl(OrdergroupOverview);
