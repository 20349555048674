import React, { Component } from "react";
import TotalReceiptOverview from "../../../components/handheld/smartphone/TotalReceiptOverview";
import handheldStore from "../../../utils/flux/HandheldStore";
import { withRouter } from "react-router";
import "./HandheldReceiptContainer.css";
import { injectIntl } from "react-intl";

class HandheldReceiptContainer extends Component {
  constructor(props) {
    super(props);

    let tableId = props.match.params.tableId;

    let handheldReceipt = null;
    if (
      handheldStore.tables[props.match.params["spMerchantId"]][tableId] &&
      handheldStore.tables[props.match.params["spMerchantId"]][tableId].receiptId > 0
    ) {
      handheldReceipt =
        handheldStore.handheldReceipts[handheldStore.tables[props.match.params["spMerchantId"]][tableId].receiptId];
    }
    this.state = {
      table: handheldStore.tables[props.match.params["spMerchantId"]][tableId],
      handheldReceipt: handheldReceipt,
    };

    window.handheldReceiptContainer = this;
  }

  componentDidMount(): void {
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.on(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  componentWillUnmount(): void {
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  updateState = () => {
    if (handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId] != null)
      this.setState({
        handheldReceipt:
          handheldStore.handheldReceipts[
            handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].receiptId
          ],
      });
  };

  render(): React.ReactNode {
    return (
      <div className={"handheld-receipt-container"}>
        <TotalReceiptOverview table={this.state.table} />
      </div>
    );
  }
}

export default injectIntl(withRouter(HandheldReceiptContainer));
