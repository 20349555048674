import mynOberStore from "../flux/MynOberStore";

export default () => {
  let currency = mynOberStore.merchant.currency;

  if (currency == null) {
    return "€";
  } else {
    switch (currency) {
      case "ang":
        return "ƒ";
      default:
        return "€";
    }
  }
};
