import React from "react";
import { Typography } from "@material-ui/core";

const formatCounter = (counter) => {
  let hours = Math.floor(counter / 3600);
  let minutes = Math.floor((counter % 3600) / 60);
  if (hours === 0 && minutes !== 0) {
    return minutes + " min";
  } else if (hours !== 0 || minutes !== 0) {
    if (minutes < 10) {
      return hours + ":0" + minutes + " min";
    } else {
      return hours + ":" + minutes + " min";
    }
  } else {
    return (counter % 3600) % 60;
  }
};

export default function CountDownTimer(props) {
  const [counter, setCounter] = React.useState(props.seconds);

  React.useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return <Typography style={{ fontWeight: 800 }}>{counter > 0 ? formatCounter(counter) : "-"}</Typography>;
}
