import { useDialog } from "./DialogProvider";
import { DialogContent, IconButton, Typography } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import CloseIcon from "@material-ui/icons/Close";
import TakeawayDeliveryInfo from "../TakeawayDeliveryInfo";
import mynOberStore from "../../utils/flux/MynOberStore";

function DialogTakeawayInfo(props) {
  const [, closeDialog] = useDialog();
  return (
    <>
      <div
        style={{
          marginTop: 8,
          textAlign: "center",
        }}
      >
        <Typography variant={"h5"}>{props.intl.formatMessage({ id: "info" })}</Typography>
        <div style={{ textAlign: "right", position: "relative", top: "-40px" }}>
          <IconButton
            aria-label="close"
            onClick={() => {
              closeDialog();
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <Typography
          variant={"subtitle1"}
          dangerouslySetInnerHTML={{ __html: mynOberStore.merchant.info?.info_message }}
        />
        <TakeawayDeliveryInfo />
      </DialogContent>
    </>
  );
}

export default withIntl(DialogTakeawayInfo);
