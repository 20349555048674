export const PathNames = {
  HOMEPAGE: "/",
  MENUPAGE: "/merchant/menupage",
  MERCHANT: "/merchant",
  ORDERPAGE: "/merchant/orderpage",
  QRPAGE: "/qr",
  QRSCANNERPAGE: "/qr_scanner",
  ORDERSPAGE: "/merchant/orderspage",
  RECEIPTPAGE: "/merchant/receiptpage",
  RETURNPAGE: "/return",
  TAKEAWAYPAGE: "/merchant/takeaway",
  TAKEAWAYOVERVIEW: "/takeaway-overview",
  DELIVERYPAGE: "/merchant/delivery",
  CHECKOUTPAGE: "/merchant/checkout",
};
