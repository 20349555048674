import { sessionStore, localStore } from "../local_storage/LocalStorage";

export const storageNames = {
  COMPLETE_METHOD: "COMPLETE_METHOD",
  CONTACT_DETAILS: "CONTACT_DETAILS",
  DELIVERY_ORDER: "DELIVERY_ORDER",
  TAKEAWAY_ORDER: "TAKEAWAY_ORDER",
  WEBCODE: "WEBCODE",
  ORDER: "ORDER",
  PHONE_NUMBER: "PHONE_NUMBER",
  PICKUP_TIME: "PICKUP_TIME",
};

/**
 *
 * @param key
 */
export function getItem(key) {
  if (window.self !== window.top) {
    return localStore.getItem(key);
  } else {
    return sessionStore.getItem(key);
  }
}

/**
 *
 * @param key
 */
export function removeItem(key) {
  if (window.self !== window.top) {
    localStore.removeItem(key);
  } else {
    sessionStore.removeItem(key);
  }
}

/**
 *
 * @param key
 * @param value
 */
export function setItem(key, value) {
  if (window.self !== window.top) {
    localStore.setItem(key, value);
  } else {
    sessionStore.setItem(key, value);
  }
}

/**
 *
 */
export function clear() {
  if (window.self !== window.top) {
    localStore.clear();
  } else {
    sessionStore.clear();
  }
}
