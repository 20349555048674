import { createMuiTheme, CssBaseline, ThemeProvider, useMediaQuery } from "@material-ui/core";
import Main from "./javascript/routers/Main";
import mynOberStore from "./javascript/utils/flux/MynOberStore";
import React, { useEffect } from "react";
import { red } from "@material-ui/core/colors";
import DialogProvider from "./javascript/components/dialogs/DialogProvider";
import { mergeObjects } from "./javascript/utils/functions/HelperFunctions";

export let theme = null;

function MainWrapper(props) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [merchant, setMerchant] = React.useState(mynOberStore.merchant);

  const merchantUpdated = () => {
    setMerchant(mynOberStore.merchant);
  };

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, merchantUpdated);
    mynOberStore.on(mynOberStore.events.MENU_CREATED, merchantUpdated);
    return () => {
      mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, merchantUpdated);
      mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, merchantUpdated);
    };
  });

  const darkModePalette = {
    background: {
      default: "#000000",
      paper: "#242424",
      tertiary: "#212121",
    },
  };

  const whiteModePalette = {
    background: {
      default: "#fafafa",
      paper: "#fff",
      tertiary: "#EFEFEF",
    },
  };

  const ils = {
    type: "dark",
    primary: { main: red[500] },
  };

  const beige = {
    type: "light",
    background: {
      default: "#E6D5C3",
      paper: "#F6E5D3",
    },
  };

  let themeObj = {
    palette: {
      type: prefersDarkMode ? "dark" : "light",
      primary: { main: "#FF8C00" },
      secondary: { main: red[500] },
    },
    typography: {
      fontSize: 14,
      fontFamily: "Poppins",
      button: {
        fontWeight: 800,
      },
    },
  };

  if (props.forceDarkmode) {
    themeObj.palette = { ...themeObj.palette, ...darkModePalette };
  }

  if (merchant?.styling?.ilovesushi) {
    themeObj.palette = { ...themeObj.palette, ...ils };
  }

  if (window.whiteLabel === "ilovesushi") {
    themeObj.palette = { ...themeObj.palette, ...ils };
  }

  if (merchant?.styling?.beige) {
    themeObj.palette = { ...themeObj.palette, ...beige };
  }

  if (merchant?.styling?.palette?.type) {
    themeObj.palette.type = merchant?.styling?.palette?.type;
  }

  themeObj.palette = {
    ...themeObj.palette,
    ...(themeObj.palette.type === "dark" ? darkModePalette : whiteModePalette),
  };

  if (merchant?.styling?.palette) {
    Object.keys(merchant.styling.palette).forEach((k) => {
      if (merchant.styling.palette?.[k]) {
        Object.keys(merchant.styling.palette[k]).forEach((l) => {
          if (merchant.styling.palette[k][l] == null) {
            delete merchant.styling.palette[k][l];
          }
        });
      }

      if (merchant.styling.palette[k] == null) {
        delete merchant.styling.palette[k];
      }
    });
    themeObj.palette = mergeObjects(themeObj.palette, merchant.styling.palette, false);
  }

  if (merchant?.styling?.typography) {
    themeObj.typography = mergeObjects(themeObj.typography, merchant.styling.typography, false);
  }

  // if (merchant?.styling?.articleName) {
  themeObj.header_image = merchant?.styling?.header_image ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#F00" } } };
  themeObj.top_navigation = merchant?.styling?.top_navigation ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#F00" } } };
  themeObj.articlegroup = merchant?.styling?.articlegroup ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#F00" } } };
  themeObj.article = merchant?.styling?.article ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#F00" } } };
  themeObj.article_description = merchant?.styling?.article_description ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#FF0" } } };
  themeObj.article_price = merchant?.styling?.article_price ?? {}; // { typography: { fontFamily: "Vapiano" }, palette: { text: { primary: "#FF0" } } };
  // }

  theme = createMuiTheme(themeObj);
  const DisableZoom = React.lazy(() => import("./DisableZoom"));

  return (
    <ThemeProvider theme={theme}>
      <DialogProvider>
        <CssBaseline />
        <React.Suspense fallback={<></>}>{merchant?.styling?.disable_zoom && <DisableZoom />}</React.Suspense>
        <Main isHandheldLoggedIn={props.isHandheldLoggedIn} store={mynOberStore} />
      </DialogProvider>
    </ThemeProvider>
  );
}

export default MainWrapper;
