import React from "react";
import { TextField } from "@material-ui/core";

export default function NumberField(props) {
  const [number, setNumber] = React.useState("");

  let onChange = (e) => {
    let value = e.target.value;
    if (value.includes(",")) {
      value = value.replace(",", ".");
    }
    value = value.replace(/[^0-9,.-]/, "");
    if (!Number.isNaN(Number(value)) || (value === "-" && !(props.min >= 0) )) {
      setNumber(value);
      props.setNumber(value);
    }
  };

  return (
    <TextField
      style={{ margin: "10px" }}
      value={number}
      required={true}
      inputMode={"numeric"}
      InputLabelProps={{
        shrink: true,
      }}
      min={props.min}
      variant={"outlined"}
      label={props.placeholder}
      onChange={onChange}
    />
  );
}
