import React, { Component } from "react";
import { PathNames } from "../routers/PathNames";
import InputPhoneNumber from "../components/web/form_fields/InputPhoneNumber";
import "./OrderPage.css";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import * as platformFunctions from "../utils/functions/PlatformFunctions";
import { SpMerchant } from "../utils/merchant/SpMerchant";
import { TaMerchant } from "../utils/merchant/TaMerchant";
import * as sessionStorage from "../utils/session_storage/SessionStorage";
import { OrderTypes } from "../utils/order/Order";
import mynOberStore from "../utils/flux/MynOberStore";
import withIntl from "../utils/withIntl";
import { CircularButton } from "../components/menu/ViewMenuArticleDetailed";
import DialogOk from "../components/dialogs/DialogOk";
import { DialogContext } from "../components/dialogs/DialogProvider";
import { Button, TextField, Typography } from "@material-ui/core";
import * as mynOberActions from "../utils/flux/MynOberActions";
import CustomTextField from "../components/customFields/CustomTextField";
import CustomTimestampField from "../components/customFields/CustomTimestampField";
import handheldStore from "../utils/flux/HandheldStore";
import Checkout from "../components/handheld/smartphone/Checkout";
import TableSelector from "../components/handheld/smartphone/TableSelector";
import CountDownTimer from "../components/handheld/CountDownTimer";
import PaymentDialogWithAmount from "../components/handheld/smartphone/PaymentDialogWithAmount";

class OrderPage extends Component {
  constructor(props) {
    super(props);

    this.showPhoneInput = false;
    if (props.merchant instanceof SpMerchant) {
      this.showPhoneInput = !props.hasReceipt && !props.isHandheld && props.merchant.canNotifications;
    }

    this.isTaMerchant = this.props.merchant instanceof TaMerchant;

    this.state = {
      phoneNumber:
        sessionStorage.getItem(sessionStorage.storageNames.PHONE_NUMBER) == null
          ? null
          : sessionStorage.getItem(sessionStorage.storageNames.PHONE_NUMBER),
      remark: mynOberStore.order.remark,
    };

    if (this.state.phoneNumber === "undefined") {
      this.state.phoneNumber = null;
    }
  }

  componentDidMount() {
    if (!this.props.hasReceiptOrWebcode) {
      const [openDialog] = this.context;
      openDialog(
        {
          children: (
            <DialogOk
              title={"alert_title_failed"}
              content={"alert_text_qr_code_missing"}
              onOkClicked={() => {
                this.props.history.goBack();
              }}
            />
          ),
        },
        true
      );
    }
  }

  // --------------------------------------------------

  checkOrderForSending = (table = null, paymentMethods = [], printInhouseBill = true) => {
    if (this.props.merchant instanceof TaMerchant) {
      if (this.props.order.orderType === OrderTypes.DELIVERY) {
        this.props.history.push(PathNames.DELIVERYPAGE);
      } else {
        if (
          this.props.merchant instanceof TaMerchant &&
          this.props.merchant.usePhone === false &&
          this.props.merchant.useNotifications === false &&
          this.props.merchant.useEmail === false &&
          this.props.merchant.askContactDetails === false &&
          this.props.merchant.numberOfBlocks === 0 &&
          this.props.merchant.paymentMethods.find((paymentMethod) => paymentMethod.name === "IDEAL") == null
        ) {
          this.props.sendOrder();
        } else {
          this.props.history.push(PathNames.TAKEAWAYPAGE);
        }
      }
    } else {
      if (this.showPhoneInput) {
        if (this.state.phoneNumber !== "" && this.state.phoneNumber != null) {
        } else {
          const [openDialog] = this.context;
          openDialog({
            children: (
              <DialogOk title={"alert_title_ordering_possible"} content={"alert_text_failed_no_phone_number"} />
            ),
          });
          return;
        }
      }

      if (mynOberStore.spQrCode && mynOberStore.spQrCode.sp_is_every_order_paid) {
        this.props.history.push(PathNames.CHECKOUTPAGE);
      } else {
        this.props.sendOrder(
          this.showPhoneInput ? this.state.phoneNumber : null,
          paymentMethods,
          table,
          printInhouseBill
        );
      }
    }
  };

  onMobileNumberChange = (number) => {
    if (number != null) {
      sessionStorage.setItem(sessionStorage.storageNames.PHONE_NUMBER, number);
    } else {
      sessionStorage.removeItem(sessionStorage.storageNames.PHONE_NUMBER);
    }
    this.setState({ phoneNumber: number });
  };

  // --------------------------------------------------

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.checkOrderForSending();
        }}
        className={"order-page"}
        style={{ width: "100%", maxWidth: "800px", alignSelf: "center" }}
      >
        {this.props.HeaderPage}
        <div>
          {this.props.hasOrderArticles && this.showPhoneInput && (
            <InputPhoneNumber
              onChange={this.onMobileNumberChange}
              value={this.state.phoneNumber}
              country={platformFunctions.getLocale()}
              header={this.isTaMerchant ? "label_phone" : "text_merchant_sends_notifications"}
            />
          )}
        </div>
        <div
          className={"order-page-content"}
          style={{
            minHeight: 200,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {handheldStore.isInDirectSaleMode && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Checkout
                merchant={mynOberStore.merchant}
                amountOpen={mynOberStore.order.amount}
                onPayment={(paymentMethods, printInhouseBill) => {
                  this.checkOrderForSending(null, paymentMethods, printInhouseBill);
                }}
              />

              <Button
                variant={"outlined"}
                style={{ margin: "8px", padding: "15px" }}
                onClick={() => {
                  const [openDialog, closeDialog] = this.context;
                  openDialog({
                    children: (
                      <TableSelector
                        spMerchantId={this.props.match.params["spMerchantId"]}
                        exceptTable={null}
                        onSelectTable={(table) => {
                          closeDialog();
                          this.checkOrderForSending(table.id);
                        }}
                      />
                    ),
                    maxWidth: "lg",
                  });
                }}
              >
                {this.props.intl.formatMessage({ id: "BOOK ON TABLE" })}
              </Button>
              <Button
                variant={"outlined"}
                style={{ margin: "8px", padding: "15px" }}
                onClick={() => {
                  const [openDialog, closeDialog] = this.context;
                  openDialog({
                    children: (
                      <TableSelector
                        markTablesInuse={false}
                        spMerchantId={this.props.match.params["spMerchantId"]}
                        exceptTable={null}
                        onSelectTable={(table) => {
                          closeDialog();
                          openDialog({
                            children: (
                              <PaymentDialogWithAmount
                                amountOpen={mynOberStore.order.amount}
                                onPayment={(paymentMethods, printInhouseBill) => {
                                  this.checkOrderForSending(table.id, paymentMethods, printInhouseBill);
                                }}
                              />
                            ),
                          });
                        }}
                      />
                    ),
                    maxWidth: "lg",
                  });
                }}
              >
                {this.props.intl.formatMessage({ id: "CHECKOUT ON TABLE" })}
              </Button>
            </div>
          )}
          {this.props.children}
          {!mynOberStore.isHandheld() && mynOberStore.merchant.customFields?.length > 0 && (
            <div style={{ margin: "24px", textAlign: "center" }}>
              {this.props.hasOrderArticles &&
                mynOberStore.merchant.customFields?.map((customField) => {
                  switch (customField.type) {
                    case "text":
                      return <CustomTextField customField={customField} />;
                    case "timestamp":
                      let value =
                        mynOberStore.order.customFields && mynOberStore.order.customFields[customField.key]?.value;
                      if (Number.isInteger(value)) {
                        value *= 1000;
                        customField.value = value.toString();
                      }

                      return (
                        <CustomTimestampField
                          key={"INHOUSE"}
                          customField={customField}
                          openingHours={mynOberStore.merchant.openingHours}
                        />
                      );
                    default:
                      return <></>;
                  }
                })}
            </div>
          )}
          {this.props.hasOrderArticles ? (
            <>
              {mynOberStore.merchant.enableRemarkInOrdergroup && !mynOberStore.isHandheld() ? (
                <div style={{ margin: "24px", textAlign: "center" }}>
                  <Typography>{this.props.intl.formatMessage({ id: "any_remarks" })}</Typography>
                  <TextField
                    margin={"dense"}
                    rows={5}
                    placeholder={"Vul hier je wensen in"}
                    variant={"outlined"}
                    value={this.state.remark}
                    onChange={(e) => {
                      mynOberActions.updateOrder({ remark: e.target.value });
                      this.setState({ remark: e.target.value });
                    }}
                    inputProps={{ maxLength: 1023 }}
                    multiline
                  />
                </div>
              ) : null}
              {mynOberStore.isHandheld() ? (
                <div style={{ margin: "24px", textAlign: "center" }}>
                  <TextField
                    margin={"dense"}
                    rows={5}
                    placeholder={this.props.intl.formatMessage({ id: "any_remarks" })}
                    variant={"outlined"}
                    value={this.state.remark}
                    onBlur={(e) => {
                      mynOberActions.updateOrder({ remark: e.target.value });
                      this.setState({ remark: e.target.value });
                    }}
                    onChange={(e) => {
                      this.setState({ remark: e.target.value });
                    }}
                    inputProps={{ maxLength: 1023 }}
                    multiline
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        {this.props.hasOrderArticles && !handheldStore.isInDirectSaleMode && (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {mynOberStore.receipt.timeInterval > 0 &&
              mynOberStore.receipt.timeIntervalRemaining > 0 &&
              mynOberStore.order.countItemsWithWeightNotNull > 0 && (
                <>
                  <Typography style={{ textAlign: "center" }}>
                    {this.props.intl.formatMessage({ id: "Next All-You-Can-Eat round can be ordered in" })}
                  </Typography>
                  <CountDownTimer seconds={mynOberStore.receipt.timeIntervalRemaining} />
                  {mynOberStore.order.countItemsWithWeightNull > 0 && (
                    <Typography style={{ textAlign: "center" }}>
                      {this.props.intl.formatMessage({ id: "send-weight-null-items" })}
                    </Typography>
                  )}
                </>
              )}

            <CircularButton
              type={"submit"}
              variant={"contained"}
              disabled={
                mynOberStore.receipt.timeIntervalRemaining > 0 &&
                mynOberStore.order.countItemsWithWeightNotNull > 0 &&
                mynOberStore.order.countItemsWithWeightNull === 0
              }
              color={"primary"}
              style={{
                width: "66%",
                marginBottom: 8,
                marginTop: 8,
                height: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? 80 : null,
              }}
            >
              {this.props.intl.formatMessage({ id: this.props.buttonStringId })}
            </CircularButton>
          </div>
        )}
      </form>
      // </div>
    );
  }
}

OrderPage.propTypes = {
  buttonStringId: PropTypes.string.isRequired,
  hasOrderArticles: PropTypes.bool.isRequired,
  hasReceipt: PropTypes.bool.isRequired,
  hasReceiptOrWebcode: PropTypes.bool.isRequired,
  isHandheld: PropTypes.bool.isRequired,
  merchant: PropTypes.object.isRequired,
  sendOrder: PropTypes.func.isRequired,
  HeaderPage: PropTypes.element.isRequired,
};

OrderPage.contextType = DialogContext;

export default withRouter(withIntl(OrderPage));
