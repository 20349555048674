import { Button, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import handheldStore from "../../../utils/flux/HandheldStore";
import React from "react";
import { injectIntl } from "react-intl";
import { useDialog } from "../../dialogs/DialogProvider";

function TableSelector(props) {
  const [, closeDialog] = useDialog();
  let tables = [];

  Object.keys(handheldStore.tables[props.spMerchantId]).forEach((tableKey) => {
    tables.push(handheldStore.tables[props.spMerchantId][tableKey]);
  });

  tables = tables.sort(function (a, b) {
    if (Number(a.id).toString() === a.id && Number(b.id).toString() === b.id) {
      if (Number(a.id) > Number(b.id)) {
        return 1;
      } else if (Number(a.id) < Number(b.id)) {
        return -1;
      } else {
        return 0;
      }
    }
    if (a.id > b.id) {
      return 1;
    } else if (a.id < b.id) {
      return -1;
    } else {
      return 0;
    }
  });
  let tableRows = [];
  let tablesPerRow = 10;

  if (props.exceptTable != null) {
    tables = tables.filter((table) => {
      return table.id !== props.exceptTable.id;
    });
  }

  tables = tables.filter((table) => {
    return table.hasQrCode;
  });

  tables.forEach((table, index) => {
    if (index % tablesPerRow === 0) {
      tableRows[Math.trunc(index / tablesPerRow)] = [table];
    } else {
      tableRows[Math.trunc(index / tablesPerRow)].push(table);
    }
  });

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "select_table" })}</DialogTitle>
      <DialogContent>
        {tableRows.map((tableRow, index) => {
          return (
            <Grid container key={index}>
              {tableRow.map((table, i) => {
                return (
                  <Button
                    key={i}
                    variant={"outlined"}
                    style={{
                      margin: "8px",
                      padding: "15px",
                      backgroundColor: table.receiptId != null && (props.markTablesInuse ?? true) ? "#0F0" : null,
                    }}
                    onClick={() => {
                      props.onSelectTable(table);
                    }}
                  >
                    {table.id}
                  </Button>
                );
              })}
            </Grid>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"contained"}
          onClick={() => {
            closeDialog();
          }}
        >
          {props.intl.formatMessage({ id: "button_back" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default injectIntl(TableSelector);
