import { Button, Checkbox, DialogContent, DialogTitle, FormControlLabel, MenuItem, Select } from "@material-ui/core";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";
import { localStore } from "../../utils/local_storage/LocalStorage";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
  },
  iconOutlined: { color: theme.palette.primary.main },
}));

function DialogCheckoutTakeawayOrder(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const [paymentMethod, setPaymentMethod] = React.useState(props.taReceipt.paymentMethod);
  const [printTakeawayBill, setPrintTakeawayBill] = React.useState(
    (localStore.getItem("print_ta_bill") ?? "1") === "1"
  );

  const taMerchantHasSelectedPaymentMethod = useMemo(() => {
    return props.taMerchant.paymentMethods.some((paymentMethod) => paymentMethod.key === props.taReceipt.paymentMethod);
  }, [props.taMerchant, props.taReceipt]);

  const paymentMethods = useMemo(() => {
    const paymentMethods = props.taMerchant.paymentMethods.filter(
      (paymentMethod) =>
        !paymentMethod.isOnline() &&
        ((props.taReceipt.receiptType === "TAKEAWAY" && paymentMethod.takeaway) ||
          (props.taReceipt.receiptType === "DELIVERY" && paymentMethod.delivery))
    );

    if (!taMerchantHasSelectedPaymentMethod) {
      paymentMethods.unshift({ key: props.taReceipt.paymentMethod, name: props.taReceipt.paymentMethod });
    }
    return paymentMethods;
  }, [props.taReceipt, props.taMerchant]);

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: props.content })}</h3>

        {!props.taReceipt.isPaid && (
          <div>
            <Select
              style={{ width: "100%" }}
              variant={"outlined"}
              classes={{ root: classes.root, iconOutlined: classes.iconOutlined }}
              value={paymentMethod}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
              }}
              disabled={!taMerchantHasSelectedPaymentMethod}
            >
              {paymentMethods.map((paymentMethod) => (
                <MenuItem value={paymentMethod.key}>
                  {paymentMethod.description ?? paymentMethod.name ?? paymentMethod.key}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <FormControlLabel
          style={{ margin: "8px", padding: "15px" }}
          label={props.intl.formatMessage({ id: "Print bill" })}
          control={
            <Checkbox
              color={"primary"}
              checked={printTakeawayBill}
              onChange={(e) => {
                localStore.setItem("print_ta_bill", e.target.checked ? "1" : "0");
                setPrintTakeawayBill(e.target.checked);
              }}
            />
          }
        />
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              props.onOkClicked(printTakeawayBill, paymentMethod);
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

DialogCheckoutTakeawayOrder.propTypes = {
  onOkClicked: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
  taMerchant: PropTypes.object,
  taReceipt: PropTypes.object,
};

export default withIntl(DialogCheckoutTakeawayOrder);
