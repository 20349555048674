import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import ButtonMyn from "./ButtonMyn";
import scrollIntoView from "scroll-into-view";
import "./QrCodeForm.css";
import Typography from "@material-ui/core/Typography";

class QrCodeForm extends Component {
  onFocus() {
    let scrollToNode = document.getElementById("input-webcode");
    scrollIntoView(scrollToNode, {
      time: 500,
      align: {
        top: 0,
        topOffset: 15,
      },
    });
  }

  onWebcodeChange(event) {
    this.props.onWebcodeChange(event.target.value);
  }

  onSubmit(event) {
    if (event !== null) {
      event.preventDefault();
    }

    document.activeElement.blur();
    this.props.sendWebcode(event);
  }

  render() {
    return (
      <div className={"qr-code-form"}>
        <Typography variant={"h3"}>{this.props.intl.formatMessage({ id: "text_welcome" })}</Typography>
        <Typography>{this.props.intl.formatMessage({ id: "text_explanation" })}</Typography>
        <form onSubmit={this.onSubmit.bind(this)}>
          <input
            id={"input-webcode"}
            onFocus={this.onFocus.bind(this)}
            autoComplete={"off"}
            type={this.props.isHandheld ? "number" : "text"}
            name={"webcode"}
            value={this.props.webcode}
            onChange={this.onWebcodeChange.bind(this)}
            placeholder={this.props.intl.formatMessage({ id: this.props.isHandheld ? "input_table" : "input_webcode" })}
          />
          <ButtonMyn stringId={"button_start"} onClick={this.onSubmit.bind(this)} />
        </form>
      </div>
    );
  }
}

QrCodeForm.propTypes = {
  isHandheld: PropTypes.bool.isRequired,
  webcode: PropTypes.string.isRequired,
  onWebcodeChange: PropTypes.func.isRequired,
  sendWebcode: PropTypes.func.isRequired,
};

export default injectIntl(QrCodeForm);
