import React, { Component } from "react";
import * as listeners from "../../utils/Listeners";
import ReceiptPage from "../../pages/ReceiptPage";
import ViewReceiptArticle from "../../components/menu/ViewReceiptArticle";
import * as platformFunctions from "../../utils/functions/PlatformFunctions";
import ListReceiptArticles from "../../components/menu/ListReceiptArticles";
import HeaderPage from "../../components/HeaderPage";
import { SpMerchant } from "../../utils/merchant/SpMerchant";
import ViewOrderModifierarticle from "../../components/menu/ViewOrderModifierarticle";
import PropTypes from "prop-types";
import mynOberStore from "../../utils/flux/MynOberStore";
import withIntl from "../../utils/withIntl";
import { CircularButton } from "../../components/menu/ViewMenuArticleDetailed";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import { openDrawer } from "../../components/drawer_menu/DrawerMenu";
import { Bill } from "../../utils/receipt/Bill";
import { ReceiptArticle } from "../../utils/receipt/ReceiptArticle";
import Typography from "@material-ui/core/Typography";

export const PaymentMethods = {
  CARD: "CARD",
  CASH: "CASH",
};

class ReceiptContainer extends Component {
  constructor(props) {
    super(props);

    this.bottomMarginLevel = 0;
    this.canWaiter = false;
    this.canMove = false;
    this.canPay = false;
    if (mynOberStore.merchant instanceof SpMerchant) {
      if (mynOberStore.merchant.canPay) {
        this.bottomMarginLevel++;
        this.canPay = true;
      }
      if (mynOberStore.merchant.canMove) {
        this.bottomMarginLevel++;
        this.canMove = true;
      }
      if (mynOberStore.merchant.canWaiter) {
        this.bottomMarginLevel++;
        this.canWaiter = true;
      }
    }

    this.isHandheld = mynOberStore.isHandheld();
    this.uriMerchantImage = mynOberStore.merchant.imageURL;

    let bill = new Bill(mynOberStore.receipt.id, mynOberStore.receipt.spMerchantId, mynOberStore.receipt);
    mynOberStore.receipt.ordergroups.forEach((ordergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        if (receiptArticle.count > 0) {
          bill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
        }
      });
    });
    this.state = {
      identifierName: mynOberStore.receipt.identifierName(platformFunctions.getLocale()),
      identifierNumber: mynOberStore.receipt.tableId,
      identifierParty: mynOberStore.merchant.maximumReceiptsPerTable === 1 ? -1 : mynOberStore.receipt.partyId,
      isLoading: false,
      hasReceiptAtCurrentMerchant: mynOberStore.hasReceiptAtCurrentMerchant(),
      receiptArticleViews: this.createReceiptArticleViews(bill.receiptArticles),
      receiptArticleViewsClosed: this.createReceiptArticleViewsClosed(bill.receiptArticles),
      receiptPrice: mynOberStore.receipt.amountOpen,
    };
  }

  componentDidMount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.registerLoadingListeners(this.onLoadingChanged);
    }

    mynOberStore.on(mynOberStore.events.RECEIPT_CHANGED, this.onReceiptUpdated);
  }

  componentWillUnmount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.unregisterLoadingListeners(this.onLoadingChanged);
    }

    mynOberStore.removeListener(mynOberStore.events.RECEIPT_CHANGED, this.onReceiptUpdated);
  }

  // --------------------------------------------------

  createPageHeader = () => {
    if (this.state.hasReceiptAtCurrentMerchant) {
      if (this.state.identifierParty === -1) {
        return (
          <HeaderPage
            headerStringId={"header_table"}
            headerStringData={{
              identifierName: this.state.identifierName,
              number: this.state.identifierNumber,
            }}
          />
        );
      } else {
        return (
          <HeaderPage
            headerStringId={"header_table_party"}
            headerStringData={{
              identifierName: this.state.identifierName,
              number: this.state.identifierNumber,
              party: this.state.identifierParty,
            }}
          />
        );
      }
    } else {
      return <HeaderPage headerStringId={"header_receipt"} />;
    }
  };

  createReceiptArticleViews = (receiptArticles) => {
    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      return receiptArticle.countOpen > 0 && receiptArticle.price > 0;
    });

    return filteredReceiptArticles.map(function (receiptArticle, i) {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          key={i}
          receiptArticle={receiptArticle}
          name={receiptArticle.displayName}
          amount={receiptArticle.amountOpen}
          count={receiptArticle.countOpen}
          orderModifierarticleViews={orderModifierarticleViews}
        />
      );
    });
  };

  createReceiptArticleViewsClosed = (receiptArticles) => {
    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      return receiptArticle.countClosed > 0; //&& receiptArticle.price > 0;
    });

    return filteredReceiptArticles.map(function (receiptArticle, i) {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          showTurnoverGroups={true}
          key={i}
          name={receiptArticle.displayName}
          amount={0}
          count={receiptArticle.countClosed}
          orderModifierarticleViews={orderModifierarticleViews}
          receiptArticle={receiptArticle}
        />
      );
    });
  };

  onLoadingChanged = () => {
    if (this.state.isLoading !== mynOberStore.isLoading) {
      this.setState({ isLoading: mynOberStore.isLoading });
    }
  };

  onReceiptUpdated = () => {
    let bill = new Bill(mynOberStore.receipt.id, mynOberStore.receipt.spMerchantId, mynOberStore.receipt);
    mynOberStore.receipt.ordergroups.forEach((ordergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        if (receiptArticle.count > 0) {
          bill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
        }
      });
    });
    this.setState({
      identifierName: mynOberStore.receipt.identifierName(platformFunctions.getLocale()),
      identifierNumber: mynOberStore.receipt.tableId,
      identifierParty: mynOberStore.merchant.maximumReceiptsPerTable === 1 ? -1 : mynOberStore.receipt.partyId,
      hasReceiptAtCurrentMerchant: mynOberStore.hasReceiptAtCurrentMerchant(),
      receiptArticleViews: this.createReceiptArticleViews(bill.receiptArticles),
      receiptArticleViewsClosed: this.createReceiptArticleViewsClosed(bill.receiptArticles),
      receiptPrice: mynOberStore.receipt.amountOpen,
    });
  };

  // --------------------------------------------------

  render() {
    return (
      <ReceiptPage
        bottomMarginLevel={this.bottomMarginLevel}
        isHandheld={this.isHandheld}
        merchant={mynOberStore.merchant}
        hasReceiptAtCurrentMerchant={this.state.hasReceiptAtCurrentMerchant}
        headerMerchant={this.props.headerMerchant}
        receipt={mynOberStore.receipt}
        uriMerchantImage={this.uriMerchantImage}
        webcode={mynOberStore.webcode}
        isLoading={this.state.isLoading}
        HeaderPage={this.createPageHeader()}
        receiptArticleViewsClosed={this.state.receiptArticleViewsClosed}
        ButtonReceiptActions={
          <CircularButton style={{ width: "66%" }} color={"primary"} variant={"contained"} onClick={openDrawer}>
            {this.props.intl.formatMessage({ id: "button_show_actions" })}
          </CircularButton>
        }
      >
        {this.state.hasReceiptAtCurrentMerchant ? (
          <ListReceiptArticles
            amount={this.state.receiptPrice}
            receiptArticles={this.state.receiptArticleViews}
            totalStringId={"text_total"}
          />
        ) : (
          <Typography className={"empty-message"}>
            {this.props.intl.formatMessage({
              id: this.state.receiptArticleViews.length === 0 ? "text_receipt_empty" : "text_receipt_elsewhere",
            })}
          </Typography>
        )}
      </ReceiptPage>
    );
  }
}

ReceiptContainer.propTypes = {
  headerMerchant: PropTypes.element,
};

ReceiptContainer.contextType = DialogContext;

export default withIntl(ReceiptContainer);
