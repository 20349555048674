import React, { Component } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import "./Loader.css";

class Loader extends Component {
  render() {
    return (
      <div className={"background-overlay background-overlay-visible"} style={{ zIndex: 1500 }}>
        <Spinner color="#FFFFFF" size={24} speed={1} animating={true} />
      </div>
    );
  }
}

export default Loader;
