import { EventEmitter } from "events";
import mynOberDispatcher from "./MynOberDispatcher";

class TaHandheldStore extends EventEmitter {
  constructor() {
    super();

    this.actions = {
      SET_TA_MERCHANTS: "TaHandheldStoreActions.SET_TA_MERCHANTS",
      CLOSE_TARECEIPT: "TaHandheldStoreActions.CLOSE_TARECEIPT",
      DELETE_TARECEIPT: "TaHandheldStoreActions.DELETE_TARECEIPT",
      UPDATE_TARECEIPT: "TaHandheldStoreActions.UPDATE_TARECEIPT",
      CREATE_TARECEIPT: "TaHandheldStoreActions.CREATE_TARECEIPT",
    };

    this.events = {
      TA_MERCHANTS_UPDATED: "TA_MERCHANTS_UPDATED",
      TARECEIPT_UPDATED: "TARECEIPT_UPDATED",
      TARECEIPT_CREATED: "TARECEIPT_CREATED",
      TARECEIPT_CLOSED: "TARECEIPT_CLOSED",
    };

    this.taReceipts = {};
    this.taMerchants = {};
  }

  /**
   * @param {object} action
   */
  _handleActions(action) {
    switch (action.type) {
      case this.actions.SET_TA_MERCHANTS:
        action.taMerchants.forEach((taMerchant) => {
          this.taMerchants[taMerchant.id] = taMerchant;
        });
        this.emit(this.events.TA_MERCHANTS_UPDATED);
        break;
      case this.actions.CLOSE_TARECEIPT:
      case this.actions.UPDATE_TARECEIPT:
        if (this.taReceipts[action.receipt.id] != null) {
          this.taReceipts[action.receipt.id] = action.receipt;
          this.emit(this.events.TARECEIPT_UPDATED, action.receipt.id);
          break;
        } else {
          this.taReceipts[action.receipt.id] = action.receipt;
          this.emit(this.events.TARECEIPT_CREATED, action.receipt.id);
          break;
        }
      case this.actions.CREATE_TARECEIPT:
        this.taReceipts[action.receipt.id] = action.receipt;
        this.emit(this.events.TARECEIPT_CREATED, action.receipt.id);
        break;
      case this.actions.DELETE_TARECEIPT:
        delete this.taReceipts[action.receiptId];
        this.emit(this.events.TARECEIPT_CLOSED, action.receiptId);
        break;

      default:
        // console.log("Unknown action in Handheld Store");
        break;
    }
  }
}

const taHandheldStore = new TaHandheldStore();
mynOberDispatcher.register(taHandheldStore._handleActions.bind(taHandheldStore));
export default taHandheldStore;
window.taHandheldStore = taHandheldStore;
