import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import ButtonMyn from "../ButtonMyn";
import scrollIntoView from "scroll-into-view";
import "./LoginForm.css";
import Typography from "@material-ui/core/Typography";

class LoginForm extends Component {
  onFocus(id) {
    let scrollToNode = document.getElementById(id);
    scrollIntoView(scrollToNode, {
      time: 500,
      align: {
        top: 0,
        topOffset: 15,
      },
    });
  }

  render() {
    return (
      <div className={"login-form"}>
        <Typography variant={"h3"}>
          {this.props.intl.formatMessage({ id: "header_handheld" }, { labelName: window.label.title.text })}
        </Typography>
        <Typography>{this.props.intl.formatMessage({ id: "text_explanation_handheld" })}</Typography>

        <form onSubmit={this.props.sendHandheldCode}>
          <input
            id={"input-username"}
            onClick={() => {
              this.onFocus("input-username");
            }}
            autoCapitalize={"off"}
            type={"text"}
            name={"username"}
            value={this.props.username}
            onChange={this.props.onUsernameChange}
            placeholder={this.props.intl.formatMessage({ id: "input_username" })}
          />
          <input
            id={"input-password"}
            onClick={() => {
              this.onFocus("input-password");
            }}
            autoComplete={"off"}
            type={"password"}
            name={"password"}
            value={this.props.password}
            onChange={this.props.onPasswordChange}
            placeholder={this.props.intl.formatMessage({ id: "input_password" })}
          />
          <ButtonMyn type={"submit"} stringId={"button_login"} onClick={this.props.sendHandheldCode} />
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  onUsernameChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  sendHandheldCode: PropTypes.func.isRequired,
};

export default injectIntl(LoginForm);
