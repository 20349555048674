import React from "react";
import PropTypes from "prop-types";
import "./ViewArticleHandheld.css";
import { Article } from "../../utils/menu/Article";
import withIntl from "../../utils/withIntl";
import GetCurrency from "../../utils/functions/GetCurrency";
import { ButtonBase, Paper, Typography } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";
import DialogOk from "../dialogs/DialogOk";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { OrderArticle } from "../../utils/order/OrderArticle";
import { useDialog } from "../dialogs/DialogProvider";
import FreeTPArticleModal from "../dialogs/FreeTPArticleModal";
import HandheldViewMenuArticleDetailed from "./HandheldViewMenuArticleDetailed";

function ViewArticle2Handheld(props) {
  const [openDialog] = useDialog();
  const enabled = !(!mynOberStore.isAYCEEnabled() && props.article.weight != null);

  const onArticleClicked = () => {
    if (!mynOberStore.isAYCEEnabled()) {
      if (props.article.weight !== null) {
        openDialog({
          children: <DialogOk title={"alert_title_ordering_possible"} content={"alert_text_no_couverts_on_table"} />,
        });
        return;
      }
    }

    if (props.article.type === "FREETPARTICLE") {
      openDialog({
        children: (
          <FreeTPArticleModal
            article={props.article}
            onClick={(articleName, articlePrice) => {
              let orderArticle = new OrderArticle(props.article, 1, []);
              orderArticle.name = articleName;
              orderArticle.price = Math.round(articlePrice * 100.0);
              mynOberActions.addOrderArticle(orderArticle);
            }}
          />
        ),
      });
    } else if (!props.article.allowRemark && props.article.modifiergroupIds.length === 0) {
      mynOberActions.addOrderArticle(new OrderArticle(props.article, 1, []));
    } else {
      openDialog({
        children: <HandheldViewMenuArticleDetailed article={props.article} enabled={enabled} />,
        fullWidth: true,
      });
    }
  };

  return (
    <ButtonBase
      component={"div"}
      className={"article-view article-view-handheld"}
      onClick={() => {
        onArticleClicked();
      }}
    >
      <Paper
        className={"article-right-container MuiButton-containedPrimary"}
        style={{ backgroundColor: props.article.handheld_bg_color }}
      >
        <div className={"truncate-overflow"}>
          <Typography style={{ fontWeight: 800, color: enabled ? null : "lightgray" }}>{props.article.name}</Typography>
        </div>
        <div className="article-bottom-info">
          <div className={enabled ? "article-price" : "article-price text-disabled"}>
            {props.article.originalPrice !== null && (
              <span style={{ textDecoration: "line-through", marginRight: "6px", fontSize: "1.1em" }}>
                {GetCurrency("€") +
                  parseFloat(props.article.originalPrice / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            )}
            {props.article.price !== 0 ? (
              <span style={{ color: props.article.originalPrice !== null ? "red" : null }}>
                {(props.article.hasModifiergroupsWithPrice
                  ? props.intl.formatMessage({ id: "text_price_from" }) + " " + GetCurrency("€")
                  : GetCurrency("€")) +
                  parseFloat(props.article.price / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            ) : null}
          </div>
          {props.article.count !== 0 ? (
            <div className="column-amount-content">
              <Typography style={{ fontWeight: 800 }}>{"x" + props.article.count}</Typography>
            </div>
          ) : null}
        </div>
      </Paper>
    </ButtonBase>
  );
}

ViewArticle2Handheld.propTypes = {
  article: PropTypes.instanceOf(Article).isRequired,
};

export default withIntl(ViewArticle2Handheld);
