import React from "react";
import { Dialog } from "@material-ui/core";

export function DialogContainer(props) {
  const {
    children,
    open,
    onClose,
    onKill,
    disableBackdropClick,
    disableEscapeKeyDown,
    fullWidth = false,
    maxWidth = "sm",
  } = props;

  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      open={open}
      onClose={onClose}
      onExited={onKill}
      disableBackdropClick={disableBackdropClick ?? false}
      disableEscapeKeyDown={disableEscapeKeyDown ?? false}
    >
      {children}
    </Dialog>
  );
}
