import React from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import TaReceipt from "../../../utils/receipt/TaReceipt";
import makeStyles from "@material-ui/core/styles/makeStyles";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { printBillTaReceipt, printTaReceipt } from "../../../utils/functions/RequestFunctions";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import { getLocalDateFromTimestampFormattedString, getValue } from "../../../utils/functions/HelperFunctions";
import mynOberStore from "../../../utils/flux/MynOberStore";
import GetCurrency from "../../../utils/functions/GetCurrency";
import DialogError from "../../dialogs/DialogError";
import { useDialog } from "../../dialogs/DialogProvider";
import DialogOk from "../../dialogs/DialogOk";
import DialogOkOrCancel from "../../dialogs/DialogOkOrCancel";
import TakeawayComActions from "./TakeawayComActions";
import DialogCheckoutTakeawayOrder from "../../dialogs/DialogCheckoutTakeawayOrder";
import UbereatsActions from "./UbereatsActions";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  column: {
    flexBasis: "20%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
    },
  },
  div: {
    borderLeft: "1px solid",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
    },
  },
}));

const getNotificationButtons = (props, taReceiptId, openDialog, closeDialog) => {
  let buttons = [];
  let notificationOptions = getValue(mynOberStore, "merchant.info.notifications");
  if (notificationOptions == null) {
    buttons.push(
      <Button
        variant={"outlined"}
        color={"primary"}
        onClick={(e) => {
          e.stopPropagation();
          closeDialog();

          openDialog({
            children: (
              <DialogOkOrCancel
                title={"alert_title_warning"}
                content={"alert_text_sure"}
                onOkClicked={() => {
                  requestFunctions
                    .sendTaNotification(taReceiptId)
                    .then(() => {
                      openDialog({
                        children: <DialogOk title={"alert_title_success"} />,
                      });
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={text} /> });
                    });
                }}
              />
            ),
          });
        }}
      >
        {props.intl.formatMessage({ id: "SEND_NOTIFICATION" })}
      </Button>
    );
  } else {
    buttons = notificationOptions.map((option) => {
      return (
        <Button
          variant={"outlined"}
          color={"primary"}
          onClick={(e) => {
            e.stopPropagation();
            closeDialog();
            openDialog({
              children: (
                <DialogOkOrCancel
                  title={"alert_title_warning"}
                  content={"alert_text_sure"}
                  onOkClicked={() => {
                    requestFunctions
                      .sendTaNotification(taReceiptId, option.name)
                      .then(() => {
                        openDialog({
                          children: <DialogOk title={"alert_title_success"} />,
                        });
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                />
              ),
            });
          }}
        >
          {option.name}
        </Button>
      );
    });
  }
  return buttons;
};

export function TaReceiptDetailedView(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle>
        {props.intl.formatMessage({ id: "Bon" })}
        <IconButton
          color="primary"
          onClick={() => {
            closeDialog();
          }}
          aria-label="close"
          className={classes.root}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div style={{ marginBottom: "10px" }}>
          <Grid container direction="row">
            <Grid item lg={1} xs={4}>
              <Typography className={classes.heading} color="primary">
                T{props.taReceipt.number}
                {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].isTables
                  ? ", " + props.intl.formatMessage({ id: "table" }) + " " + props.taReceipt.tableId
                  : null}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={4}>
              <Typography className={classes.heading}>
                {/*{props.intl.formatMessage({id: "ordered_at"})}{getLocalDateFromUTCFormattedString(props.taReceipt.updatedAt)}*/}
              </Typography>
            </Grid>
            {props.taReceipt.contactInformation != null && (
              <Grid item lg={2} xs={4}>
                <Typography className={classes.heading}>
                  {props.taReceipt.receiptType === "TAKEAWAY"
                    ? props.taReceipt.contactInformation.name
                    : props.taReceipt.contactInformation.address}
                </Typography>
              </Grid>
            )}
            <Grid item lg={1} xs={4}>
              <Typography
                className={classes.secondaryHeading}
                style={{ color: props.taReceipt.isPaid ? "grey" : "red" }}
              >
                {props.taReceipt.isPaid ? "Betaald" : "Niet betaald"}
              </Typography>
            </Grid>
            <Grid item container direction="row" lg={2} xs={4}>
              {props.taReceipt.receiptType === "TAKEAWAY" ? <LocalMallIcon /> : <DirectionsBikeIcon />}
              <Typography style={{ marginLeft: "5px" }} className={clsx(classes.secondaryHeading, classes.hide)}>
                {props.taReceipt.receiptType}
              </Typography>
            </Grid>
            <Grid item lg={2} xs={4}>
              <Typography className={classes.secondaryHeading}>
                {props.taReceipt.receiptArticles.length} item(s)
              </Typography>
            </Grid>
            <Grid item lg={2} xs={4}>
              <Typography className={classes.secondaryHeading}>
                {GetCurrency("€")}{" "}
                {(props.taReceipt.getAmount() / 100.0).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                ({props.taReceipt.paymentMethod})
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item container lg={6} md={6} sm={12} xs={12} style={{ paddingBottom: 20 }}>
              <div>
                <Typography variant="caption">
                  Bestelling {props.taReceipt.receiptType === "TAKEAWAY" ? "afhalen" : "bezorgen"}
                  {props.taReceipt.pickupTimestamp === 0
                    ? ": " + "Zo snel mogelijk".toLowerCase()
                    : " om " + getLocalDateFromTimestampFormattedString(props.taReceipt.pickupTimestamp)}
                </Typography>
                <br />

                {props.taReceipt.contactInformation != null &&
                  Object.keys(props.taReceipt.contactInformation)
                    .filter(
                      (key) => props.taReceipt.contactInformation[key] !== "" && props.taReceipt.contactInformation[key]
                    )
                    .map((key) => {
                      return (
                        <div key={key}>
                          <Typography variant="caption">{props.taReceipt.contactInformation[key]}</Typography>
                          <br />
                        </div>
                      );
                    })}
              </div>

              <br />
              {props.taReceipt.remark ? (
                <Typography variant="caption">Opmerking: {props.taReceipt.remark}</Typography>
              ) : null}
            </Grid>
            <Grid item container lg={6} md={6} sm={12} xs={12}>
              <Divider></Divider>
              <div className={classes.div} style={{ display: "flex", flexDirection: "column", width: "inherit" }}>
                <div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Typography style={{ flexBasis: "35%", fontWeight: "bold", padding: "0 5px" }} variant="caption">
                      Naam
                    </Typography>
                    <Typography
                      style={{ flexBasis: "25%", fontWeight: "bold", padding: "0 5px", textAlign: "end" }}
                      variant="caption"
                    >
                      Prijs per stuk
                    </Typography>
                    <Typography
                      style={{ flexBasis: "15%", fontWeight: "bold", padding: "0 5px", textAlign: "end" }}
                      variant="caption"
                    >
                      Aantal
                    </Typography>
                    <Typography
                      style={{ flexBasis: "25%", fontWeight: "bold", padding: "0 5px", textAlign: "end" }}
                      variant="caption"
                    >
                      Totaal
                    </Typography>
                  </div>
                </div>
                {props.taReceipt.receiptArticles.map((item) => {
                  return (
                    <div key={item.id}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Typography style={{ flexBasis: "35%", padding: "0 5px" }} variant="caption">
                          {item.name} {item.info?.remark}
                        </Typography>
                        <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                          {GetCurrency("€")}{" "}
                          {(item.price / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                        <Typography style={{ flexBasis: "15%", padding: "0 5px", textAlign: "end" }} variant="caption">
                          {item.count} {"x"}
                        </Typography>
                        <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                          {GetCurrency("€")}{" "}
                          {(item.amount / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </div>

                      {item.orderModifiergroups.map((orderModifiergroup) => {
                        return orderModifiergroup.orderModifierarticles.map((orderModifierarticle) => {
                          return (
                            <div style={{ display: "flex", flexDirection: "row", paddingTop: "0px" }}>
                              <Typography
                                style={{ flexBasis: "35%", padding: "0 5px", fontSize: "80%", color: "grey" }}
                                variant="caption"
                              >
                                {" "}
                                > {orderModifierarticle.name}
                              </Typography>
                              <Typography
                                style={{
                                  flexBasis: "25%",
                                  padding: "0 5px",
                                  fontSize: "80%",
                                  color: "grey",
                                  textAlign: "end",
                                }}
                                variant="caption"
                              >
                                {orderModifierarticle.price === 0
                                  ? ""
                                  : "+" +
                                    GetCurrency("€") +
                                    " " +
                                    (orderModifierarticle.price / 100).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}
                              </Typography>
                              <Typography
                                style={{
                                  flexBasis: "15%",
                                  padding: "0 5px",
                                  fontSize: "80%",
                                  color: "grey",
                                  textAlign: "end",
                                }}
                                variant="caption"
                              >
                                {orderModifierarticle.count > 1 ? orderModifierarticle.count + " x" : ""}
                              </Typography>
                              <Typography
                                style={{ flexBasis: "25%", padding: "0 5px", fontSize: "80%", color: "grey" }}
                                variant="caption"
                              />
                            </div>
                          );
                        });
                      })}
                    </div>
                  );
                })}
                <br />
                {props.taReceipt.deliveryCosts > 0 ? (
                  <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography style={{ flexBasis: "35%", padding: "0 5px" }} variant="caption">
                        Bezorgkosten
                      </Typography>
                      <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                        {GetCurrency("€")}{" "}
                        {(props.taReceipt.deliveryCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography
                        style={{ flexBasis: "15%", padding: "0 5px", textAlign: "end" }}
                        variant="caption"
                      ></Typography>
                      <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                        {GetCurrency("€")}{" "}
                        {(props.taReceipt.deliveryCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </div>
                  </div>
                ) : null}
                {props.taReceipt.paymentCosts > 0 ? (
                  <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Typography style={{ flexBasis: "35%", padding: "0 5px" }} variant="caption">
                        Betaalkosten
                      </Typography>
                      <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                        {GetCurrency("€")}{" "}
                        {(props.taReceipt.paymentCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                      <Typography
                        style={{ flexBasis: "15%", padding: "0 5px", textAlign: "end" }}
                        variant="caption"
                      ></Typography>
                      <Typography style={{ flexBasis: "25%", padding: "0 5px", textAlign: "end" }} variant="caption">
                        {GetCurrency("€")}{" "}
                        {(props.taReceipt.paymentCosts / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </div>
                  </div>
                ) : null}
                <Divider variant="fullWidth" />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography style={{ flexBasis: "75%", padding: "0 5px" }}>Totaal</Typography>
                  <Typography
                    style={{
                      flexBasis: "25%",
                      padding: "0 5px",
                      textAlign: "end",
                    }}
                  >
                    {GetCurrency("€")}{" "}
                    {(props.taReceipt.getAmount() / 100.0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        {props.taReceipt.platform === "takeaway.com" && <TakeawayComActions taReceipt={props.taReceipt} />}
        {props.taReceipt.platform === "UBER_EATS" && <UbereatsActions taReceipt={props.taReceipt} />}
        {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].useNotifications &&
          getNotificationButtons(props, props.taReceipt.id, openDialog, closeDialog)}
        {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].isMynbonnenMerchant && !props.taReceipt.isPaid && (
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              openDialog({
                children: (
                  <DialogOkOrCancel
                    title={"alert_title_warning"}
                    content={"alert_text_sure"}
                    onOkClicked={() => {
                      requestFunctions
                        .taReceiptVoid(props.taReceipt.id)
                        .then(() => {
                          closeDialog();
                          openDialog({
                            children: (
                              <DialogOk title={"alert_title_success"} content={"alert_text_success_payment_handheld"} />
                            ),
                          });
                        })
                        .catch((text) => {
                          openDialog({ children: <DialogError content={text} /> });
                        });
                    }}
                  />
                ),
              });
            }}
          >
            {props.intl.formatMessage({ id: "CANCEL_VOID" })}
          </Button>
        )}
        {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].isMynbonnenMerchant && (
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              printTaReceipt(props.taReceipt.id)
                .then(() => {
                  openDialog({ children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} /> });
                })
                .catch((text) => {
                  openDialog({ children: <DialogError content={text} /> });
                });
            }}
          >
            {props.intl.formatMessage({ id: "werkbonnen printen" })}
          </Button>
        )}
        {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].isMynbonnenMerchant && (
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              printBillTaReceipt(props.taReceipt.id)
                .then(() => {
                  openDialog({ children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} /> });
                })
                .catch((text) => {
                  openDialog({ children: <DialogError content={text} /> });
                });
            }}
          >
            {props.intl.formatMessage({ id: "rekening printen" })}
          </Button>
        )}
        {taHandheldStore.taMerchants[props.taReceipt.taMerchantId].isMynbonnenMerchant && (
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={() => {
              openDialog({
                children: (
                  <DialogCheckoutTakeawayOrder
                    taReceipt={props.taReceipt}
                    taMerchant={taHandheldStore.taMerchants[props.taReceipt.taMerchantId]}
                    title={"alert_title_warning"}
                    content={"alert_text_sure"}
                    onOkClicked={(printTakeawayBill, paymentMethod) => {
                      requestFunctions
                        .taReceiptClose(props.taReceipt.id, printTakeawayBill, paymentMethod)
                        .then((response) => {
                          closeDialog();
                          openDialog({
                            children: (
                              <DialogOk title={"alert_title_success"} content={"alert_text_success_payment_handheld"} />
                            ),
                          });
                        })
                        .catch((text) => {
                          openDialog({ children: <DialogError content={text} /> });
                        });
                    }}
                  />
                ),
              });
            }}
          >
            {props.intl.formatMessage({ id: "button_pay" })}
          </Button>
        )}
      </DialogActions>
    </>
  );
}

TaReceiptDetailedView.propTypes = {
  taReceipt: PropTypes.instanceOf(TaReceipt).isRequired,
  onClick: PropTypes.func,
  onClickPrintClose: PropTypes.func,
};

export default withRouter(injectIntl(TaReceiptDetailedView));
