import React from "react";
import TableOverviewPage from "../../../pages/handheld/TableOverviewPage";
import TopNavigation from "../../../components/web/TopNavigation";
import { ReactComponent as IconOptions } from "../../../../assets/images/ic-menu.svg";
import mynOberStore from "../../../utils/flux/MynOberStore";
import DrawerMenu, { openDrawer } from "../../../components/drawer_menu/DrawerMenu";
import "./HandheldContainer.css";
import { injectIntl } from "react-intl";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { matchPath, Route, Switch, withRouter } from "react-router";
import { getDrawerButtons } from "../../../components/floorplan/FloorPlan";
import FloorPlanPage from "../../../pages/handheld/FloorPlanPage";
import ReceiptHistoryContainer from "./ReceiptHistoryContainer";
import DialogOkOrCancel from "../../../components/dialogs/DialogOkOrCancel";
import { useDialog } from "../../../components/dialogs/DialogProvider";
import DialogSuccess from "../../../components/dialogs/DialogSuccess";
import DialogError from "../../../components/dialogs/DialogError";
import { HandheldPathNames } from "../../../routers/handheld/HandheldRouter";
import handheldStore from "../../../utils/flux/HandheldStore";
import { localStore } from "../../../utils/local_storage/LocalStorage";
import { uuidv4 } from "../../../components/ReactCodeInput";
import { PathNames } from "../../../routers/PathNames";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import { AllInbox, Assessment, FormatListNumbered, History, Home, Payment, Refresh } from "@material-ui/icons";
import DialogSelectMenuTag from "../../../components/dialogs/DialogSelectMenuTag";

function HandheldContainer(props) {
  const [openDialog] = useDialog();

  const makeReportClicked = () => {
    openDialog({
      children: (
        <DialogOkOrCancel
          title={"z_report"}
          content={"alert_text_sure"}
          onOkClicked={() => {
            requestFunctions
              .report("Z")
              .then(() => {
                openDialog({
                  children: <DialogSuccess content={"alert_text_report_being_printed"} />,
                });
              })
              .catch((text) => {
                openDialog({
                  children: <DialogError content={text} />,
                });
              });
          }}
        />
      ),
    });
  };

  const openCashDrawerClicked = () => {
    requestFunctions.opencashdrawer().catch((text) => {
      openDialog({
        children: <DialogError content={text} />,
      });
    });
  };

  let drawerMenuButtons = [];

  const onLongPress = () => {
    openDialog({
      children: (
        <DialogSelectMenuTag
          onOkClicked={(menuTag) => {
            if (menuTag) {
              const menus = { covers: 0, activeMArticleIds: {}, menuTags: [menuTag] };
              mynOberStore.forceSetMenus(menus);
            } else {
              const menus = { covers: 0, activeMArticleIds: {}, menuTags: null };
              mynOberStore.forceSetMenus(menus);
            }
          }}
        />
      ),
    });
  };

  Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
    let button = {
      stringId: "Direct Sale Mode",
      subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
      icon: <Payment />,
      key: "direct_sale_mode-" + spMerchantId,
      onLongPress,
      onClick: () => {
        props.history.replace(
          `/${spMerchantId}${HandheldPathNames.TABLE}/SALE-` +
            uuidv4().substr(0, 4) +
            `${PathNames.ORDERPAGE}?directsalemode=1`
        );
      },
    };
    drawerMenuButtons.push(button);
  });

  Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
    let button = {
      stringId: "table_overview",
      subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
      icon: <FormatListNumbered />,
      key: "table_overview-" + spMerchantId,
      onClick: () => {
        props.history.replace("/" + spMerchantId + HandheldPathNames.TABLEOVERVIEW);
      },
    };
    drawerMenuButtons.push(button);
  });

  if (mynOberStore.merchant != null) {
    drawerMenuButtons = drawerMenuButtons.concat(getDrawerButtons(props.history));
  }

  if (Object.keys(handheldStore.spMerchants).length === 0) {
    let button = {
      stringId: "tab_orders",
      key: "tab_orders",
      icon: null,
      onClick: () => {
        props.history.replace("/");
      },
    };
    drawerMenuButtons.push(button);
  }

  if (Object.keys(handheldStore.spMerchants).length > 0 || Object.keys(taHandheldStore.taMerchants).length > 0) {
    let button = {
      stringId: "bill_history",
      key: "bill_history",
      icon: <History />,
      onClick: () => {
        props.history.replace("/history");
      },
    };
    drawerMenuButtons.push(button);
  }

  drawerMenuButtons.push({
    stringId: "open_cash_drawer",
    key: "open_cash_drawer",
    icon: <AllInbox />,
    onClick: () => {
      openCashDrawerClicked();
    },
  });

  drawerMenuButtons.push({
    stringId: "z_report",
    key: "z_report",
    icon: <Assessment />,
    onClick: () => {
      makeReportClicked();
    },
  });

  if (
    props.location.pathname.includes(HandheldPathNames.TABLEOVERVIEW) ||
    props.location.pathname.includes(HandheldPathNames.FLOORPLAN)
  ) {
    drawerMenuButtons.push({
      stringId: "set home page",
      key: "set home page",
      icon: <Home />,
      onClick: () => {
        if (props.location.pathname.includes(HandheldPathNames.TABLEOVERVIEW)) {
          localStore.setItem("default_home_page", HandheldPathNames.TABLEOVERVIEW);
        } else if (props.location.pathname.includes(HandheldPathNames.FLOORPLAN)) {
          localStore.setItem("default_home_page", HandheldPathNames.FLOORPLAN);
        }
      },
    });
  }
  drawerMenuButtons.push({
    stringId: "reload",
    key: "reload",
    icon: <Refresh />,
    onClick: () => {
      window.location = "/";
    },
  });

  let title = mynOberStore.merchant?.name;

  let match = matchPath(props.location.pathname, { path: "/:spMerchantId(\\d+)" });
  if (match && handheldStore.spMerchants[match.params.spMerchantId]?.salesAreaName != null) {
    title += " - " + handheldStore.spMerchants[match.params.spMerchantId].salesAreaName;
  }

  let defaultHomePage = localStore.getItem("default_home_page") ?? HandheldPathNames.TABLEOVERVIEW;

  return (
    <div className={"handheld-container-root"} style={{ height: "100%" }}>
      <TopNavigation
        iconSrc={<IconOptions />}
        titleId={"button_options"}
        merchantName={title}
        onClickOptions={openDrawer}
      />

      <DrawerMenu buttons={drawerMenuButtons} />
      {Object.keys(handheldStore.spMerchants).length > 0 ? (
        <div className={"handheld-container"} style={{ overflowY: "auto" }}>
          <Switch>
            <Route path={HandheldPathNames.HISTORY}>
              <ReceiptHistoryContainer />
            </Route>
            <Route path={`/:spMerchantId${HandheldPathNames.FLOORPLAN}`}>
              <FloorPlanPage />
            </Route>
            <Route path={`/:spMerchantId${HandheldPathNames.TABLEOVERVIEW}`}>
              <TableOverviewPage />
            </Route>
            <Route path={`/:spMerchantId`}>
              <>
                {defaultHomePage === HandheldPathNames.FLOORPLAN && <FloorPlanPage />}
                {defaultHomePage === HandheldPathNames.TABLEOVERVIEW && <TableOverviewPage />}
              </>
            </Route>
          </Switch>
        </div>
      ) : null}
    </div>
  );
}

export default withRouter(injectIntl(HandheldContainer));
