import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { articlegroupViewId } from "../components/menu/ViewArticlegroup";
import mynOberStore from "../utils/flux/MynOberStore";
import handheldStore from "../utils/flux/HandheldStore";
import TabArticlegroup from "../components/web/TabArticlegroup";
import { stickyHeaderId } from "./smartphone/TableOrderContainer";
import scrollIntoView from "scroll-into-view";
import SearchBar from "../components/SearchBar";
import ScrollableTabBar from "../components/web/ScrollableTabBar";
import { addOrderArticle } from "../utils/flux/MynOberActions";
import { OrderModifiergroup } from "../utils/order/OrderModifiergroup";
import { OrderModifierarticle } from "../utils/order/OrderModifierarticle";
import { OrderArticle } from "../utils/order/OrderArticle";
import ViewArticlegroup2 from "../components/menu/ViewArticlegroup2";
import { withRouter } from "react-router-dom";

function MenuContainerHandheldWide(props) {
  const [articlegroups, setArticlegroups] = React.useState([]);

  const [selectedArticlegroupObject, setSelectedArticlegroupObject] = React.useState({ index: null, id: null });
  const [filter, setFilter] = React.useState("");

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED, onOrderChanged);
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, onMerchantUpdated);
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, updateMenu);
    mynOberStore.on(mynOberStore.events.MENU_CREATED, updateMenu);
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, orderChangedFromSharedOrders);

    setArticlegroups(processArticlesForModifierPricesAndAmount());
    return () => {
      mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED, onOrderChanged);
      mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, onMerchantUpdated);
      mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, orderChangedFromSharedOrders);
      mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, updateMenu);
      mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, updateMenu);
    };
  }, []);

  useEffect(() => {
    setArticlegroups(processArticlesForModifierPricesAndAmount());
    setSelectedArticlegroupObject({ index: null, id: null });
  }, [filter]);

  const addAllArticles = () => {
    articlegroups.forEach((articlegroup) => {
      articlegroup.articles.forEach((article) => {
        if (article.modifiergroupIds.length === 0) {
          addOrderArticle(new OrderArticle(article, 1, []));
        } else {
          let modifiergroups = mynOberStore.menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(
            article.modifiergroupIds
          );
          let orderModifiergroups = [];
          modifiergroups.forEach((modifiergroup) => {
            if (!modifiergroup.optional && modifiergroup.modifierarticles.length > 0) {
              orderModifiergroups.push(
                new OrderModifiergroup(modifiergroup, [new OrderModifierarticle(modifiergroup.modifierarticles[0], 1)])
              );
            }
          });
          addOrderArticle(new OrderArticle(article, 1, orderModifiergroups));
        }
      });
    });
  };
  window.menuContainer = { addAllArticles };
  const updateMenu = (merchantId) => {
    setArticlegroups(processArticlesForModifierPricesAndAmount());
  };

  const orderChangedFromSharedOrders = (articleId) => {
    onOrderChanged(articleId, false);
  };

  const onChangeFilter = (newFilter) => {
    setFilter(newFilter);
  };

  const processArticlesForModifierPricesAndAmount = () => {
    mynOberStore.menu.filter(filter);

    let menuTags = null;
    if (
      (!mynOberStore.isHandheld() || props.location.search.includes("directsalemode=1")) &&
      mynOberStore.menuTagsWithLag != null
    ) {
      menuTags = mynOberStore.menuTagsWithLag;
    }
    if (
      mynOberStore.isHandheld() &&
      props.location.search.includes("directsalemode=1") &&
      menuTags != null &&
      menuTags.length === 0
    ) {
      menuTags = null;
    }

    if (
      mynOberStore.isHandheld() &&
      handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId] != null &&
      handheldStore.handheldReceipts[
        handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId].receiptId
      ] != null
    ) {
      menuTags =
        handheldStore.handheldReceipts[
          handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId].receiptId
        ].menuTags;
    }
    if (
      mynOberStore.isHandheld() &&
      handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId] != null
    ) {
      if (menuTags == null) {
        menuTags = [];
      }
      if (handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId].menuTags) {
        menuTags = menuTags.concat(
          handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId].menuTags
        );
      }
    }

    let articlegroups = [];

    mynOberStore.menu.articlegroups.forEach((articlegroup, index) => {
      const articlegroupObject = {
        articlegroup,
        articles: articlegroup.articles.filter((article) => {
          article.count = mynOberStore.order.getCountByArticleId(article.id);

          let filter1 =
            (article.type === "ARTICLE" || (article.type === "FREETPARTICLE" && mynOberStore.isHandheld())) &&
            article.isActive &&
            (article.isInOpeningHours || mynOberStore.isHandheld()) &&
            (mynOberStore.isHandheld() || !article.isMenu);
          if (!filter1) {
            return false;
          }
          if (
            (!mynOberStore.isHandheld() ||
              (mynOberStore.user.handheldUser != null && mynOberStore.user.handheldUser.use_menu_tags)) &&
            menuTags != null
          ) {
            if (article.menuTags.length === 0) {
              return true;
            }
            return menuTags.some((activatedMenuTag) => {
              return article.menuTags.some((articleMenuTag) => {
                return articleMenuTag === activatedMenuTag;
              });
            });
          }

          return true;
        }),
      };
      if (articlegroupObject.articles.length > 0) {
        articlegroups.push(articlegroupObject);
      }
    });

    return articlegroups;
  };

  const onClickArticlegroupTab = (index, id) => {
    let element = document.getElementById(articlegroupViewId + index);

    let topOffset = 0;
    let stickyHeader = document.getElementById(stickyHeaderId);
    if (stickyHeader != null) {
      topOffset += stickyHeader.getBoundingClientRect().height;
    }

    setSelectedArticlegroupObject({ id, index });

    if (filter.length > 0) {
      setFilter("");
    }

    if (element !== null) {
      scrollIntoView(element, {
        time: 1000,
        align: {
          top: 0,
          topOffset: topOffset, // margin
        },
        validTarget: (target, parentScrolled) => {
          return target.id === "menu-container"; //&& target === window;
        },
      });
    }
  };

  const onMerchantUpdated = () => {
    setArticlegroups(processArticlesForModifierPricesAndAmount());
  };

  const onOrderChanged = (articleId = null) => {
    if (articleId) {
      let article = mynOberStore.menu.getArticleById(articleId);
      article.count = mynOberStore.order.getCountByArticleId(article.id);
    } else {
      mynOberStore.menu.articles.forEach((article) => {
        article.count = mynOberStore.order.getCountByArticleId(article.id);
      });
    }
  };

  // --------------------------------------------------

  return (
    <div
      style={{ overflowY: "hidden", flex: "1 1 auto", display: "flex", flexDirection: "row" }}
      id={"menu-container"}
      // tabIndex={0}
    >
      <div
        id={stickyHeaderId}
        style={{
          top: "0",
          zIndex: 5,
          paddingTop: "8px",
          width: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? 320 : null,
          overflowY: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "auto" : null,
          height: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "auto" : null,
        }}
      >
        <SearchBar value={filter} onChange={onChangeFilter} placeHolder={"Search"} />

        <ScrollableTabBar
          orientation={mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "column" : "row"}
          tabs={articlegroups.map((articlegroup, index) => {
            return (
              <TabArticlegroup
                key={articlegroup.articlegroup.id}
                articlegroup={articlegroup.articlegroup}
                selected={
                  (index === 0 && selectedArticlegroupObject.id == null) ||
                  articlegroup.articlegroup.id === selectedArticlegroupObject.id
                }
                onTabClicked={() => onClickArticlegroupTab(index, articlegroup.articlegroup.id)}
                index={index}
              />
            );
          })}
        />
      </div>
      <div style={{ flex: "1 1 0%", overflowY: "auto", paddingLeft: 16, paddingRight: 16 }}>
        {articlegroups[selectedArticlegroupObject.index ?? 0] && (
          <ViewArticlegroup2
            articlegroup={articlegroups[selectedArticlegroupObject.index ?? 0]}
            index={0}
            key={articlegroups[selectedArticlegroupObject.index ?? 0].articlegroup.id}
          />
        )}
      </div>
    </div>
  );
}

MenuContainerHandheldWide.propTypes = {
  headerMerchant: PropTypes.element,
};

export default withRouter(MenuContainerHandheldWide);
