import withIntl from "../utils/withIntl";
import { TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

function FormField(props) {
  if (props.hide === true) {
    return <div></div>;
  }
  return (
    <div>
      <Typography>{props.intl.formatMessage({ id: props.titleLabel })}</Typography>
      <TextField
        disabled={props.disabled === true}
        margin={"dense"}
        variant={props.disabled ? "standard" : "outlined"}
        onChange={(e) => {
          props.onChange(e.target.value);
        }}
        type={props.type ? props.type : "text"}
        name={props.name}
        placeholder={props.intl.formatMessage({ id: props.titleLabel })}
        value={props.value}
        required={props.required}
        multiline={props.multiline}
        rows={props.rows ?? 1}
        inputProps={{ maxLength: props.maxLength, pattern: props.pattern }}
      />
    </div>
  );
}

FormField.propTypes = {
  titleLabel: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  pattern: PropTypes.string,
  hide: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  multiline: PropTypes.bool.isRequired,
};

export default withIntl(FormField);
