import React, { useEffect, useMemo, useState } from "react";
import { OrderModifiergroup } from "../../utils/order/OrderModifiergroup";
import { OrderModifierarticle } from "../../utils/order/OrderModifierarticle";
import mynOberStore from "../../utils/flux/MynOberStore";
import GetCurrency from "../../utils/functions/GetCurrency";
import {
  Button,
  Checkbox,
  Chip,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  List,
  ListItem,
  Paper,
  Popper,
  Radio,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import AmountAddRemove from "../AmountAddRemove";
import withIntl from "../../utils/withIntl";
import { OrderArticle } from "../../utils/order/OrderArticle";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import zIndex from "@material-ui/core/styles/zIndex";
import WarningIcon from "@material-ui/icons/Warning";
import scrollIntoView from "scroll-into-view";
import CloseIcon from "@material-ui/icons/Close";
import { useDialog } from "../dialogs/DialogProvider";
import DialogExtra from "../dialogs/DialogExtra";
import DialogOk from "../dialogs/DialogOk";
import { ArrowBackIos } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
  list: {
    backgroundColor: theme.palette.background.default,
    borderTop: "1px solid " + theme.palette.background.tertiary,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: "8px",
    borderBottom: "1px solid " + theme.palette.background.tertiary,
  },
  listItem: {
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.background.tertiary,
  },
}));

export const CircularButton = withStyles({
  root: {
    borderRadius: "25px",
  },
})(Button);

const DialogContentWithoutPadding = withStyles({
  root: {
    padding: 0,
  },
})(DialogContent);

var modifiergroupName = null;
var numerable = false;
var max = 0;

function ViewMenuArticleDetailed(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const [isScrollable, setIsScrollable] = useState(false);

  let modifiergroups = [];
  if (props.article != null) {
    modifiergroups = mynOberStore.menu
      .getModifiergroupsWithModifierarticlesByModifiergroupIds(props.article.modifiergroupIds)
      .filter((modifiergroup) => modifiergroup.modifierarticles.length > 0);
  }
  const [orderModifiergroups, setOrderModifiergroups] = React.useState(
    modifiergroups.map((modifiergroup) => {
      return new OrderModifiergroup(
        modifiergroup,
        modifiergroup.modifierarticles.map((modifierarticle) => {
          let count =
            props.orderModifiergroups?.reduce((count, orderModifiergroup) => {
              orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
                if (orderModifierarticle.id === modifierarticle.id) {
                  count += orderModifierarticle.count ?? 0;
                }
              });
              return count;
            }, 0) ?? 0;

          return new OrderModifierarticle(modifierarticle, count);
        })
      );
    })
  );

  const [count, setCount] = React.useState(1);
  const [remark, setRemark] = React.useState("");
  const [anchorPopover, setAnchorPopover] = React.useState(null);
  const popoverIsOpen = Boolean(anchorPopover);
  const id = popoverIsOpen ? "transitions-popper" : undefined;

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED, closeDialog);
    return () => {
      mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED, closeDialog);
    };
  }, [closeDialog]);

  useEffect(() => {
    let element = document.getElementById("view-menu-article-detailed-dialog-content");
    if (element) {
      setIsScrollable(element.scrollHeight > element.clientHeight);
    }
  }, []);

  const handleClosePopover = () => {
    setAnchorPopover(null);
  };

  const handleAnchorPopover = (orderModifiergroupId) => {
    let domElement = document.getElementById("orderModifiergroupSpan-" + orderModifiergroupId);
    if (domElement != null) {
      modifiergroupName = domElement.innerHTML;
      setAnchorPopover(domElement);
      setTimeout(() => {
        handleClosePopover();
      }, 3000);

      scrollIntoView(domElement, {
        time: 500,
        align: {
          top: 0,
          topOffset: 0,
        },
      });
    }
  };

  const onModifierarticleClicked = (
    orderModifiergroup: OrderModifiergroup,
    orderModifierarticle: OrderModifierarticle
  ) => {
    if (!orderModifiergroup.numerable) {
      if (orderModifiergroup.multiple && orderModifiergroup.optional) {
        if (orderModifierarticle.count === 0 && orderModifiergroup.maxN > 0) {
          // check max n

          let countOthers = 0;

          orderModifiergroup.orderModifierarticles.forEach((other) => {
            if (orderModifierarticle.id !== other.id) {
              countOthers += other.count;
            }
          });

          if (countOthers + 1 > orderModifiergroup.maxN) {
            return;
          }
        }

        orderModifierarticle.count = orderModifierarticle.count === 0 ? 1 : 0;
        setOrderModifiergroups([...orderModifiergroups]);
      } else if (orderModifiergroup.multiple && !orderModifiergroup.optional) {
        if (orderModifierarticle.count === 0 && orderModifiergroup.maxN > 0) {
          // check max n

          let countOthers = 0;

          orderModifiergroup.orderModifierarticles.forEach((other) => {
            if (orderModifierarticle.id !== other.id) {
              countOthers += other.count;
            }
          });

          if (countOthers + 1 > orderModifiergroup.maxN) {
            return;
          }
        }

        orderModifierarticle.count = orderModifierarticle.count === 0 ? 1 : 0;
        setOrderModifiergroups([...orderModifiergroups]);
      } else if (!orderModifiergroup.multiple && orderModifiergroup.optional) {
        if (orderModifierarticle.count === 0) {
          orderModifiergroup.orderModifierarticles.forEach((other) => {
            other.count = 0;
          });
        }
        orderModifierarticle.count = orderModifierarticle.count === 0 ? 1 : 0;
        setOrderModifiergroups([...orderModifiergroups]);
      } else if (!orderModifiergroup.multiple && !orderModifiergroup.optional) {
        orderModifiergroup.orderModifierarticles.forEach((other) => {
          other.count = 0;
        });
        orderModifierarticle.count = 1;
        setOrderModifiergroups([...orderModifiergroups]);
      }
    }
  };

  const checkExtraMessage = () => {
    closeDialog();
    openDialog({
      children: (
        <DialogExtra
          content={props.article.extraMessage}
          onOkClicked={() => {
            addToOrderClicked();
          }}
        />
      ),
    });
  };

  const addToOrderClicked = () => {
    if (props.article.min_amount > 0) {
      if (mynOberStore.order.amount < props.article.min_amount) {
        openDialog({
          children: (
            <DialogOk
              title={"alert_title_warning"}
              rawContent={props.intl.formatMessage(
                { id: "minimal_order_amount" },
                { min_amount: (props.article.min_amount / 100.0).toFixed(2) + " " + GetCurrency() }
              )}
            />
          ),
        });
        return;
      }
    }

    let modifierCheck = hasCorrectModifiergroupSelection();
    if (modifierCheck.isCorrect) {
      let orderModifiergroupsToAdd = [];
      orderModifiergroups.forEach((orderModifiergroup) => {
        if (orderModifiergroup.count > 0) {
          let orderModifierarticles = [];
          orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
            if (orderModifierarticle.count > 0) {
              orderModifierarticles.push(orderModifierarticle);
            }
          });
          if (orderModifierarticles.length > 0) {
            orderModifiergroupsToAdd.push(new OrderModifiergroup(orderModifiergroup, orderModifierarticles));
          }
        }
      });

      let orderArticle = new OrderArticle(
        props.article,
        count,
        orderModifiergroupsToAdd,
        remark !== "" ? remark : null
      );
      mynOberActions.addOrderArticle(orderArticle);
    } else {
      numerable = modifierCheck.numerable;
      max = modifierCheck.max;
      handleAnchorPopover(modifierCheck.modifiergroupId);
      // alertHandler.showWrongSelectionAlert(modifierCheck.modifiergroupName, this.modifiergroupNodes[modifierCheck.modifiergroupId], this.articleModifiergroupsView);
    }
  };

  const hasCorrectModifiergroupSelection = () => {
    let result = { isCorrect: true };
    // If incorrect one found, return error
    orderModifiergroups.some((orderModifiergroup) => {
      if (!orderModifiergroup.optional) {
        // Find minimal one selected
        if (
          !orderModifiergroup.orderModifierarticles.some((orderModifierarticle) => {
            return orderModifierarticle.count;
          })
        ) {
          result = {
            isCorrect: false,
            modifiergroupName: orderModifiergroup.name,
            modifiergroupId: orderModifiergroup.id,
            numerable: false,
            max: 0,
          };
          return true; // Found incorrect modifiergroup selection
        }
      }
      if (orderModifiergroup.numerable && orderModifiergroup.maxN > 0) {
        let countModifierarticles = 0;
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          countModifierarticles += orderModifierarticle.count;
        });
        if (countModifierarticles > orderModifiergroup.maxN * count) {
          // Numerable is not followed up
          result = {
            isCorrect: false,
            modifiergroupName: orderModifiergroup.name,
            modifiergroupId: orderModifiergroup.id,
            numerable: true,
            max: orderModifiergroup.maxN * count,
          };
          return true;
        }
      }
      return false;
    });
    return result;
  };

  const orderArticlePrice = useMemo(() => {
    if (props.article == null) {
      return 0;
    } else {
      let total = 0;
      total += props.article.price * count;
      orderModifiergroups.forEach((orderModifiergroup: OrderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle: OrderModifierarticle) => {
          if (orderModifierarticle.isSideDish && false) {
            total += orderModifierarticle.count * orderModifierarticle.price;
          } else {
            total += orderModifierarticle.count * orderModifierarticle.price * count;
          }
        });
      });
      return total;
    }
  }, [count, orderModifiergroups, props.article]);

  return (
    <>
      <DialogContentWithoutPadding style={{ position: "relative" }} id={"view-menu-article-detailed-dialog-content"}>
        <IconButton
          style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
          aria-label="close"
          onClick={closeDialog}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            textAlign: "center",
          }}
        >
          {props.article != null ? (
            <div>
              <div
                style={{
                  display: "inline-flex",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingTop: "16px",
                  paddingBottom: "16px",
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    flex: "1 1 auto",
                  }}
                >
                  {!mynOberStore.isHandheld() && props.article.path !== null ? (
                    <img
                      style={{
                        overflow: "hidden",
                        borderRadius: "8px",
                        maxWidth: "calc(100% - 32px)",
                        maxHeight: "calc(100% - 32px)",
                        height: "300px",
                        width: "450px",
                        objectFit: "contain",
                      }}
                      alt={props.article.name}
                      src={props.article.path}
                    />
                  ) : null}
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "inline-flex",
                  flexDirection: "column",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <div
                  style={{
                    display: "inline-flex",
                    flexDirection: "column",
                    textAlign: "start",
                    width: "calc(100% - 64px)",
                    alignSelf: "center",
                    margin: "0 32px",
                  }}
                >
                  <Typography
                    color={"textPrimary"}
                    style={{
                      color: props.theme.article?.palette?.text?.primary,
                      fontFamily: props.theme.article?.typography?.fontFamily,
                      fontSize: "12pt",
                      whiteSpace: "wrap",
                      textOverflow: "ellipsis",
                      paddingBottom: "3px",
                      fontWeight: "bold",
                    }}
                  >
                    {props.article.name}
                  </Typography>

                  {mynOberStore.merchant?.showDescription !== false && props.article.description != null ? (
                    <Typography
                      color={"textSecondary"}
                      style={{
                        color: props.theme.article_description?.palette?.text?.secondary,
                        fontFamily: props.theme.article_description?.typography?.fontFamily,
                        fontSize: "14px",
                        lineHeight: "14px",
                        margin: "3px 0",
                      }}
                    >
                      {props.article.description}
                    </Typography>
                  ) : null}
                </div>
                <div
                  style={{
                    display: "inline-flex",
                    width: "calc(100% - 64px)",
                    alignSelf: "center",
                    marginBottom: "8px",
                    marginLeft: "32px",
                    marginRight: "32px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      marginRight: "12px",
                      flex: "1 1 auto",
                    }}
                  >
                    {mynOberStore.merchant.showAllergens && !mynOberStore.isHandheld()
                      ? mynOberStore.menu.getAllergensByAllergenIds(props.article.allergenIds).map((allergen) => {
                          return (
                            <div
                              key={allergen.id}
                              style={{
                                margin: "0 1px 1px 1px",
                                width: "24px",
                                height: "24px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip placement={"top"} title={allergen.name} style={{ fontSize: "18pt" }}>
                                <img
                                  style={{
                                    height: "24px",
                                    width: "24px",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                  alt={allergen.name}
                                  src={allergen.getImagePath()}
                                />
                              </Tooltip>
                              {/*<p style={{*/}
                              {/*  textAlign: 'center',*/}
                              {/*  fontSize: '0.75em',*/}
                              {/*  margin: '0'*/}
                              {/*}}>{allergen.name}</p>*/}
                            </div>
                          );
                        })
                      : null}
                  </div>

                  {props.article.count !== 0 ? (
                    <Chip
                      style={{ alignSelf: "flex-end" }}
                      size={"small"}
                      label={props.article.count}
                      color={"primary"}
                    />
                  ) : null}

                  <div
                    className={props.enabled ? "article-price" : "article-price text-disabled"}
                    style={{
                      fontWeight: 500,
                      color: "#888888",
                      marginLeft: "16px",
                      alignSelf: "flex-end",
                    }}
                  >
                    {props.article.originalPrice !== null && (
                      <span style={{ textDecoration: "line-through", marginRight: "6px", fontSize: "1.1em" }}>
                        {GetCurrency("€") +
                          parseFloat(props.article.originalPrice / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </span>
                    )}
                    {props.article.price !== 0 ? (
                      <Typography
                        color={"textSecondary"}
                        style={{
                          color:
                            props.article.originalPrice !== null
                              ? "red"
                              : props.theme.article_price?.palette?.text?.secondary,
                          fontFamily: props.theme.article_price?.typography?.fontFamily,
                        }}
                      >
                        {(props.article.hasModifiergroupsWithPrice
                          ? props.intl.formatMessage({ id: "text_price_from" }) + " " + GetCurrency("€")
                          : GetCurrency("€")) +
                          parseFloat(props.article.price / 100).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                      </Typography>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <Popper
          id={id}
          open={popoverIsOpen}
          anchorEl={anchorPopover}
          onClick={handleClosePopover}
          transition
          placement={"bottom-start"}
          style={{ zIndex: zIndex.modal }}
        >
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={classes.paper}>
                  <WarningIcon color={"error"} />
                  {numerable ? (
                    <Typography className={classes.typography}>
                      {modifiergroupName == null
                        ? props.intl.formatMessage({ id: "alert_text_selection_no_name" })
                        : props.intl.formatMessage(
                            { id: "alert_text_selection_max" },
                            {
                              modifiergroupName: modifiergroupName,
                              max: max,
                            }
                          )}
                    </Typography>
                  ) : (
                    <Typography className={classes.typography}>
                      {modifiergroupName == null
                        ? props.intl.formatMessage({ id: "alert_text_selection_no_name" })
                        : props.intl.formatMessage(
                            { id: "alert_text_selection" },
                            { modifiergroupName: modifiergroupName }
                          )}
                    </Typography>
                  )}
                </Paper>
              </Fade>
            );
          }}
        </Popper>
        {isScrollable && (
          <div
            style={{
              position: "sticky",
              bottom: 0,
              right: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              style={{
                zIndex: 10,
                transform: "rotateZ(-90deg)",
              }}
              aria-label="close"
              onClick={() => {
                let element = document.getElementById("ordermodifiergroup-0");
                scrollIntoView(
                  element,
                  {
                    time: 1000,
                    align: {
                      top: 0,
                      topOffset: 0, // margin
                    },
                    validTarget: (target, parentScrolled) => {
                      return true; // "view-menu-article-detailed-dialog-content"; // target.id === "view-menu-article-detailed-dialog-content"; //&& target === window;
                    },
                  },
                  () => {}
                );
              }}
            >
              <ArrowBackIos />
            </IconButton>
          </div>
        )}
        {orderModifiergroups.map((orderModifiergroup: OrderModifiergroup, index) => {
          return (
            <div
              style={{ display: "flex", flexDirection: "column", paddingLeft: "20px" }}
              key={orderModifiergroup.id}
              id={"ordermodifiergroup-" + index}
            >
              <span style={{ margin: "16px 0" }} id={"orderModifiergroupSpan-" + orderModifiergroup.id}>
                {orderModifiergroup.name}
              </span>
              <List className={classes.list}>
                {orderModifiergroup.orderModifierarticles.map((orderModifierarticle: OrderModifierarticle, index) => {
                  return (
                    <ListItem
                      className={index !== orderModifiergroup.orderModifierarticles.length - 1 ? classes.listItem : ""}
                      style={{
                        marginLeft: "32px",
                        display: "flex",
                        flexDirection: "row",
                        minHeight: "50px",
                        justifyContent: "space-between",
                        paddingRight: "8px",
                        paddingLeft: "0",
                        width: "auto",
                      }}
                      onClick={(event) => {
                        onModifierarticleClicked(orderModifiergroup, orderModifierarticle);
                      }}
                      key={orderModifierarticle.id}
                    >
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                        <div style={{ fontSize: "14px", color: "#888888" }}>
                          <Typography>{orderModifierarticle.name}</Typography>
                        </div>
                        <div style={{ fontSize: "12px", color: "#888888", height: "18px" }}>
                          {orderModifierarticle.price !== 0 && (
                            <div>
                              + {GetCurrency("€")}{" "}
                              {parseFloat(orderModifierarticle.price / 100.0).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          visibility: mynOberStore.merchant.isOrdering ? "visible" : "hidden",
                        }}
                      >
                        {orderModifiergroup.numerable ? (
                          <AmountAddRemove
                            onRemoveClicked={() => {
                              if (orderModifierarticle.count > 0) {
                                orderModifierarticle.count--;
                                setOrderModifiergroups([...orderModifiergroups]);
                              }
                            }}
                            onAddClicked={() => {
                              if (orderModifiergroup.maxN > 0) {
                                if (orderModifiergroup.count === orderModifiergroup.maxN * count) {
                                  console.log("maxN reached");
                                  return;
                                }
                              }
                              orderModifierarticle.count++;
                              setOrderModifiergroups([...orderModifiergroups]);
                            }}
                            count={orderModifierarticle.count}
                          />
                        ) : null}
                        {!orderModifiergroup.numerable &&
                        (orderModifiergroup.multiple || orderModifiergroup.optional) ? (
                          <Checkbox color={"primary"} checked={orderModifierarticle.count === 1} />
                        ) : null}
                        {!orderModifiergroup.numerable &&
                        !orderModifiergroup.multiple &&
                        !orderModifiergroup.optional ? (
                          <Radio color={"primary"} checked={orderModifierarticle.count === 1} />
                        ) : null}
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </div>
          );
        })}
        {Number.isInteger(props.article?.allowRemark) && (
          <div style={{ margin: "24px", textAlign: "center" }}>
            <TextField
              margin={"dense"}
              rows={5}
              placeholder={"Vul hier je wensen in"}
              variant={"outlined"}
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              inputProps={{
                maxLength:
                  Number.isInteger(props.article.allowRemark) && props.article.allowRemark !== 0
                    ? props.article.allowRemark
                    : 128,
              }}
              multiline
            />
          </div>
        )}
      </DialogContentWithoutPadding>
      {mynOberStore.merchant.isOrdering ? (
        <DialogActions style={{ justifyContent: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {props.article != null ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  flexWrap: "wrap",
                  margin: "10px 20px",
                  width: "100%",
                }}
              >
                <div>
                  <AmountAddRemove
                    canNotRemove={count <= 1}
                    onRemoveClicked={() => {
                      if (count > 1) {
                        setCount(count - 1);
                      }
                    }}
                    onAddClicked={() => {
                      if (props.article.maxN > 0) {
                        if (count >= props.article.maxN) {
                          console.log("maxN reached");
                          return;
                        }
                      }
                      setCount(count + 1);
                    }}
                    count={count}
                  />
                </div>
                {orderArticlePrice !== 0 ? (
                  <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0 16px" }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        minWidth: "80px",
                        fontSize: "15px",
                      }}
                    >
                      {GetCurrency("€")}{" "}
                      {parseFloat(orderArticlePrice / 100).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </Typography>
                  </div>
                ) : null}
                <CircularButton
                  color={"primary"}
                  variant={"contained"}
                  style={{ margin: "8px 0" }}
                  disabled={!mynOberStore.isOrderingPossible()}
                  onClick={() => {
                    if (props.article.extraMessage) {
                      checkExtraMessage();
                    } else {
                      addToOrderClicked();
                    }
                  }}
                >
                  {props.intl.formatMessage({ id: "button_add_to_order" })}
                </CircularButton>
              </div>
            ) : null}
          </div>
        </DialogActions>
      ) : null}
    </>
  );
}

export default withTheme(withIntl(ViewMenuArticleDetailed));
