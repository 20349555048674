import React, { Component } from "react";
import { Group, Layer, Rect, Stage, Text } from "react-konva";
import { PropTypes } from "prop-types";
import handheldStore from "../../utils/flux/HandheldStore";
import { withRouter } from "react-router-dom";
import { Map } from "@material-ui/icons";
import { withTheme } from "@material-ui/core/styles";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

// let floorData = {
//   1: { text: "T1", x: 4, y: 4, width: 100, height: 100 },
//   2: { text: "T3", x: 4, y: 4, width: 100, height: 100 }
// };

const widthItem = 100;
const heightItem = 100;

function Table(text, id, x, y, width, height, backgroundColor) {
  this.text = text;
  this.id = id;
  this.x = x;
  this.y = y;
  this.width = width;
  this.height = height;
  this.backgroundColor = backgroundColor;
}

class FloorPlan extends Component {
  constructor(props) {
    super(props);

    let floor = {};
    if (this.props.floorPlan != null && this.props.floorPlan.tables != null) {
      Object.keys(props.floorPlan.tables).forEach((key) => {
        if (props.tables[key]?.is_every_order_paid) {
          return true;
        }
        if (props.tables[key] != null) {
          let json = props.floorPlan.tables[key];
          let timeLimitExceeded = false;

          let handheldReceipt =
            this.props.tables[key].receiptId != null
              ? handheldStore.handheldReceipts[this.props.tables[key].receiptId]
              : null;
          if (handheldReceipt != null) {
            if (handheldReceipt.spAyceCover != null && handheldReceipt.spAyceCover.time_limit != null) {
              let date = getApplicationDate();
              if (date.getTime() / 1000 > handheldReceipt.spAyceCover.time_limit) {
                timeLimitExceeded = true;
              }
            }
          }
          floor[key] = new Table(
            json.text,
            key,
            json.x,
            json.y,
            json.width,
            json.height,
            this.props.tables[key].receiptId != null ? (timeLimitExceeded ? "orangered" : "lawngreen") : "#d3d3d3"
          );
        }
      });
    }

    let searchParams = new URLSearchParams(window.location.search);

    let toBeAdded = [];
    Object.keys(props.tables).forEach((key) => {
      if (props.tables[key].is_every_order_paid) {
        return true;
      }
      if (floor[key] == null) {
        toBeAdded.push(this.props.tables[key]);
      }
    });

    toBeAdded = toBeAdded.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else if (a.name > b.name) {
        return 1;
      } else {
        return 0;
      }
    });

    let toBeAddedIndex = 0;
    if (this.state != null) {
      toBeAddedIndex = this.state.toBeAddedIndex;
    }

    this.state = {
      floor: floor,
      tables: handheldStore.tables[this.props.match.params["spMerchantId"]],
      toBeAdded: toBeAdded,
      toBeAddedIndex: toBeAddedIndex,
      isEditMode: searchParams.has("edit"),
    };

    window.floorPlan = this;
  }

  componentDidMount(): void {
    window.addEventListener("resize", this.onResize);
    handheldStore.on(handheldStore.events.TABLE_UPDATED, this.updateState);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.onResize);
    handheldStore.removeListener(handheldStore.events.TABLE_UPDATED, this.updateState);
  }

  onResize = () => {
    let stageHeight = 0;
    if (this.container != null) {
      stageHeight = this.container.getBoundingClientRect().height;
    }
    this.setState({ stageHeight: stageHeight });
  };

  updateState = (tableId) => {
    let floor = this.state.floor;
    if (this.props.floorPlan != null && this.props.floorPlan.tables != null) {
      let json = this.props.floorPlan.tables[tableId];
      if (json != null) {
        if (floor[tableId] != null) {
          clearTimeout(floor[tableId].timer);
        }
        let timeLimitExceeded = false;
        let timer = null;
        let handheldReceipt =
          this.props.tables[tableId].receiptId != null
            ? handheldStore.handheldReceipts[this.props.tables[tableId].receiptId]
            : null;
        if (handheldReceipt != null) {
          if (handheldReceipt.spAyceCover != null && handheldReceipt.spAyceCover.time_limit != null) {
            let date = getApplicationDate();
            if (date.getTime() / 1000 > handheldReceipt.spAyceCover.time_limit) {
              timeLimitExceeded = true;
            } else {
              timer = setTimeout(() => {
                this.updateState(tableId);
              }, handheldReceipt.spAyceCover.time_limit * 1000 - date.getTime() + 2000);
            }
          }
        }
        floor[tableId] = new Table(
          json.text,
          tableId,
          json.x,
          json.y,
          json.width,
          json.height,
          this.props.tables[tableId].receiptId != null ? (timeLimitExceeded ? "orangered" : "lawngreen") : "#d3d3d3"
        );
        floor[tableId].timer = timer;
        floor[tableId].timeLimitExceeded = timeLimitExceeded;
      }
    }

    this.setState({
      floor: floor,
    });
  };

  newFloorPlanCell = (cell, key) => {
    return (
      <Group
        x={cell.x}
        y={cell.y}
        draggable={this.state.isEditMode}
        onDragEnd={(e) => {
          console.table(e.target.attrs.id);
          let id = e.target.attrs.id;

          let floor = {};
          let floorItem = this.state.floor[id];

          floorItem = { ...floorItem, x: e.target.attrs.x, y: e.target.attrs.y };

          Object.assign(floor, this.state.floor);
          floor[id] = floorItem;
          this.setState({ floor: floor });
          // this.state.floor[id].x = e.target.attrs.x;
          // this.state.floor[id].y = e.target.attrs.y;
        }}
        dragBoundFunc={(pos) => {
          let maxX = window.innerWidth - cell.width;
          let maxY = window.innerHeight - 54 - cell.height;
          let x = maxX > pos.x ? (pos.x < 4 ? 4 : pos.x) : maxX;
          let y = maxY > pos.y ? (pos.y < 4 ? 4 : pos.y) : maxY;
          return {
            x: x,
            y: y,
          };
        }}
        onClick={() => {
          if (this.state.isEditMode) {
          } else {
            this.props.onOpen(cell.text);
          }
          // console.log("ONCLICK");
        }}
        onTap={() => {
          if (this.state.isEditMode) {
          } else {
            this.props.onOpen(cell.text);
          }
        }}
        key={key}
        id={key}
      >
        <Rect width={cell.width} height={cell.height} fill={cell.backgroundColor} cornerRadius={5} name={"rect"}></Rect>
        <Text
          padding={10}
          text={cell.text}
          fontSize={30}
          width={cell.width}
          fill={"33333388"}
          height={cell.height}
          align={"center"}
        ></Text>
        <Text
          padding={30}
          text={"Remove"}
          fontSize={14}
          width={cell.width}
          height={cell.height}
          align={"center"}
          visible={this.state.isEditMode}
          onClick={() => {
            let floor = this.state.floor;
            delete floor[cell.id];
            this.setState({ floor: floor });
          }}
          onTap={() => {
            let floor = this.state.floor;
            delete floor[cell.id];
            this.setState({ floor: floor });
          }}
        />
      </Group>
    );
  };

  createItemToBeAddedOnFloorPlan = () => {
    let textNode = React.createRef();
    return (
      <Group
        id={"itemToBeAdded"}
        x={100}
        y={10}
        draggable={true}
        onTouchEnd={(e) => {
          if (e.target.attrs.y + heightItem > 0) {
            let toBeAdded = this.state.toBeAdded;
            let itemAdded = toBeAdded.splice(this.state.toBeAddedIndex, 1)[0];
            // console.log(itemAdded);

            let toBeAddedIndex = this.state.toBeAddedIndex;
            if (toBeAdded.length <= toBeAddedIndex) {
              toBeAddedIndex = toBeAdded.length - 1;
            }
            this.setState({
              floor: {
                ...this.state.floor,
                ...{
                  [itemAdded.id]: new Table(
                    itemAdded.name,
                    itemAdded.id,
                    e.target.attrs.x,
                    e.target.attrs.y,
                    widthItem,
                    heightItem,
                    "#d3d3d3"
                  ),
                },
              },
              toBeAdded: toBeAdded,
              toBeAddedIndex: toBeAddedIndex,
            });
            e.target.position({ x: 100, y: 10 });
          } else {
            // console.log("NOT IN BOUNDS");
          }
        }}
        onDragEnd={(e) => {
          if (e.target.attrs.y + heightItem > 0) {
            let toBeAdded = this.state.toBeAdded;
            let itemAdded = toBeAdded.splice(this.state.toBeAddedIndex, 1)[0];
            // console.log(itemAdded);

            let toBeAddedIndex = this.state.toBeAddedIndex;
            if (toBeAdded.length <= toBeAddedIndex) {
              toBeAddedIndex = toBeAdded.length - 1;
            }
            this.setState({
              floor: {
                ...this.state.floor,
                ...{
                  [itemAdded.id]: new Table(
                    itemAdded.name,
                    itemAdded.id,
                    e.target.attrs.x,
                    e.target.attrs.y,
                    widthItem,
                    heightItem,
                    "#d3d3d3"
                  ),
                },
              },
              toBeAdded: toBeAdded,
              toBeAddedIndex: toBeAddedIndex,
            });
            e.target.position({ x: 100, y: 10 });
          } else {
            // console.log("NOT IN BOUNDS");
          }
        }}
        dragBoundFunc={(pos) => {
          let maxX = window.innerWidth - 100;
          let maxY = window.innerHeight - 54 - 100;
          let x = maxX > pos.x ? (pos.x < 4 ? 4 : pos.x) : maxX;
          let y = maxY > pos.y ? (pos.y < 4 ? 4 : pos.y) : maxY;
          return {
            x: x,
            y: y,
          };
        }}
      >
        <Rect width={100} height={100} fill={"yellow"} cornerRadius={5}></Rect>
        <Text
          padding={10}
          text={this.state.toBeAdded[Object.keys(this.state.toBeAdded)[this.state.toBeAddedIndex]].name}
          fontSize={30}
          width={100}
          height={100}
          align={"center"}
          ref={textNode}
          onDblClick={() => {
            var textPosition = textNode.current.getAbsolutePosition();

            // so position of textarea will be the sum of positions above:
            var areaPosition = {
              x: 0 + textPosition.x,
              y: 54 + 42 + textPosition.y,
            };

            // create textarea and style it
            var textarea = document.createElement("textarea");
            document.body.appendChild(textarea);

            textarea.value = textNode.current.text();
            textarea.style.position = "absolute";
            textarea.style.top = areaPosition.y + "px";
            textarea.style.left = areaPosition.x + "px";
            textarea.style.width = textNode.current.width();

            textarea.focus();

            textarea.addEventListener("keydown", (e) => {
              // hide on enter
              if (e.keyCode === 13) {
                let toBeAddedIndex = this.state.toBeAdded.findIndex((toBeAdded) => {
                  if (toBeAdded.name.startsWith(textarea.value)) {
                    return true;
                  } else {
                    return false;
                  }
                });

                this.setState({ toBeAddedIndex: toBeAddedIndex });

                document.body.removeChild(textarea);
              }
            });
          }}
        ></Text>
      </Group>
    );
  };

  handleStageClick = () => {};

  render() {
    let rects = [];
    Object.keys(this.state.floor).forEach((key) => {
      let cell = this.state.floor[key];
      rects.push(this.newFloorPlanCell(cell, key));
    });

    let itemToBeAdded = this.state.toBeAdded.length === 0 ? null : this.createItemToBeAddedOnFloorPlan();

    return (
      <div style={{ display: "flex", flex: "1 1 0", overflow: "hidden" }} ref={this.container}>
        <Stage
          width={window.innerWidth}
          height={window.innerHeight - 54 - 56}
          onClick={this.handleStageClick}
          onTap={this.handleStageClick}
          ref={this.stage}
        >
          <Layer draggable={!this.state.isEditMode && false}>{rects}</Layer>

          {this.state.isEditMode ? (
            <Layer x={0} y={0} width={500} height={150} draggable={true}>
              <Rect fill={"red"} x={0} y={0} width={25} height={25} />
              <Text
                x={10}
                y={10}
                text={"Save"}
                fill={this.props.theme.palette.type === "dark" ? "#FFFFFF88" : "#00000088"}
                padding={20}
                fontSize={30}
                onTap={() => {
                  this.props.floorPlan.tables = this.state.floor;
                  this.props.updateFloorPlan();
                }}
                onClick={() => {
                  this.props.floorPlan.tables = this.state.floor;
                  this.props.updateFloorPlan();
                }}
              />

              {this.state.toBeAdded.length > 0 ? itemToBeAdded : null}
            </Layer>
          ) : null}
        </Stage>
      </div>
    );
  }
}

FloorPlan.propTypes = {
  tables: PropTypes.object,
  floorPlan: PropTypes.object,
  onOpen: PropTypes.func,
};

export default withTheme(withRouter(FloorPlan));

export function getDrawerButtons(history) {
  let drawerButtons = [];
  Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
    if (handheldStore.spMerchants[spMerchantId].floorPlans?.length > 0) {
      drawerButtons.push({
        stringId: "floor_plan",
        key: "floor_plan_" + spMerchantId,
        subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
        icon: <Map />,
        onClick: () => {
          history.replace(`/${spMerchantId}/floorplan`);
        },
      });
    }
  });

  return drawerButtons;
}
