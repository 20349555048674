import { Modifierarticle } from "../menu/Modifierarticle";
import { Allergen } from "../menu/Allergen";
import { Articlegroup } from "../menu/Articlegroup";
import { Article } from "../menu/Article";
import { Modifiergroup } from "../menu/Modifiergroup";
import { Menu } from "../menu/Menu";
import { SpMerchant } from "../merchant/SpMerchant";
import { AyceMerchant } from "../merchant/AyceMerchant";
import { TaMerchant } from "../merchant/TaMerchant";
import { OrderModifierarticle } from "../order/OrderModifierarticle";
import { OrderModifiergroup } from "../order/OrderModifiergroup";
import { ReceiptArticle } from "../receipt/ReceiptArticle";
import { Receipt } from "../receipt/Receipt";
import { Order } from "../order/Order";
import { OrderArticle } from "../order/OrderArticle";
import { TaPostalCode } from "../merchant/TaPostalCode";
import { PaymentMethod } from "../PaymentMethod";
import mynOberStore from "../flux/MynOberStore";
import { Table } from "../Table";
import * as handheldActions from "../flux/HandheldActions";
import handheldStore from "../flux/HandheldStore";
import ArticleFlag from "../menu/ArticleFlag";
import * as platformFunctions from "./PlatformFunctions.web";
import { getApplicationDate, getValue, mergeObjects } from "./HelperFunctions";
import { HandheldOrdergroup } from "../receipt/HandheldOrdergroup";

/**
 *
 * @param {Object} jsonAllergen
 * @param {number} jsonAllergen.id
 * @param {string|null} jsonAllergen.description
 * @param {string} jsonAllergen.name
 * @param {number} jsonAllergen.path
 * @return {Allergen}
 */
export function processJsonAllergen(jsonAllergen) {
  return new Allergen(
    jsonAllergen.id,
    jsonAllergen.description,
    jsonAllergen.name,
    jsonAllergen.path,
    jsonAllergen.merchant_id,
    jsonAllergen.translation_id,
    jsonAllergen.image
  );
}

/**
 *
 * @param {Object} jsonArticle
 * @param {Array} jsonArticle.m_article_m_modifiergroups
 * @param {Array} jsonArticle.allergens
 * @param {number} jsonArticle.id
 * @param {number} jsonArticle.article.articlegroup_id
 * @param {string|null} jsonArticle.description
 * @param {string|null} jsonArticle.article.description
 * @param {string} jsonArticle.article.name
 * @param {number} jsonArticle.image_md5
 * @param {number} jsonArticle.price
 * @param {number|null} jsonArticle.priority
 * @param {number|null} jsonArticle.article.priority
 * @param {number|null} jsonArticle.weight
 * @param {Modifiergroup[]} modifiergroups
 * @return {Article}
 */
export function processJsonArticle(jsonArticle, modifiergroups) {
  let modifiergroupIds = [];
  jsonArticle.m_article_m_modifiergroups.forEach((pivot) => {
    let found = false;
    for (let i = 0; i < modifiergroups.length; i++) {
      if (modifiergroups[i].id === pivot.m_modifiergroup_id) {
        found = true;
        break;
      }
    }
    if (found) {
      modifiergroupIds.push(pivot.m_modifiergroup_id);
    }
  });

  let allergenIds = [];
  if (jsonArticle.allergens instanceof Array) {
    jsonArticle.allergens.forEach((jsonAllergen) => {
      allergenIds.push(jsonAllergen.id);
    });
  }

  let menuTags = [];
  if (jsonArticle.menu_tags != null) {
    menuTags = jsonArticle.menu_tags.split("|");
  }

  let articleName = getTranslation(jsonArticle.translation, jsonArticle.article.name);
  let articleDescription = getTranslation(
    jsonArticle.description_translation,
    jsonArticle.description !== null ? jsonArticle.description : jsonArticle.article.description
  );
  // if (jsonArticle.id % 2 == 1) {
  //   jsonArticle.meta_json = { originalPrice: 699 };
  // }
  return new Article(
    jsonArticle.id,
    allergenIds,
    jsonArticle.article.articlegroup_id,
    articleDescription,
    modifiergroupIds,
    mynOberStore.isHandheld() ? jsonArticle.pos_name ?? articleName : articleName,
    jsonArticle.image,
    jsonArticle.add_image_prefix === 1,
    jsonArticle.price,
    jsonArticle.priority !== null ? jsonArticle.priority : jsonArticle.article.priority,
    jsonArticle.weight,
    processJsonOpeningHours(jsonArticle),
    jsonArticle.manual_active && jsonArticle.active && (mynOberStore.isHandheld() || !jsonArticle.handheld_only),
    menuTags,
    jsonArticle.category,
    jsonArticle.is_menu,
    jsonArticle.type,
    getValue(jsonArticle, "meta_json.originalPrice", null),
    jsonArticle.plu,
    jsonArticle.max_n,
    getValue(jsonArticle, "meta_json.extraMessage", null),
    getValue(jsonArticle, "meta_json.remark", false),
    jsonArticle.handheld_bg_color,
    jsonArticle.min_amount,
    jsonArticle.pos_name,
    jsonArticle.supergroup_tags,
    jsonArticle.short_key ?? jsonArticle.plu,
    jsonArticle.view_only
  );
}

const getTranslation = (translation, defaultValue) => {
  let locale = platformFunctions.getLocale();
  let lang = "";
  switch (locale) {
    case "en":
      lang = "english";
      break;
    case "nl":
      lang = "dutch";
      break;
    case "de":
      lang = "german";
      break;
    case "fr":
      lang = "french";
      break;
    case "zh":
      lang = "chinese";
      break;
    case "it":
      lang = "italian";
      break;
    default:
      lang = "english";
      break;
  }
  if (translation != null && translation[lang] != null) {
    return translation[lang];
  } else {
    return defaultValue;
  }
};

/**
 *
 * @param {Object} jsonArticlegroup
 * @param {number} jsonArticlegroup.id
 * @param {string} jsonArticlegroup.name
 * @param {number} jsonArticlegroup.priority
 * @return {Articlegroup}
 */
export function processJsonArticlegroup(jsonArticlegroup) {
  let articlegroupTranslation = getTranslation(jsonArticlegroup.translation, jsonArticlegroup.name);
  return new Articlegroup(
    jsonArticlegroup.id,
    articlegroupTranslation,
    jsonArticlegroup.priority,
    jsonArticlegroup.handheld_bg_color,
    [],
    []
  );
}

/**
 *
 * @param {Object} jsonMenu
 * @param {Array} jsonMenu.m_modifierarticles
 * @param {Array} jsonMenu.m_modifiergroups
 * @param {Array} jsonMenu.m_articles
 * @return {Menu}
 */
export function processJsonMenu(jsonMenu) {
  let allergens = [];
  let articlegroups = [];
  let articles = [];
  let modifierarticles = processJsonModifierarticles(jsonMenu.m_modifierarticles);
  let modifiergroups = processJsonModifiergroups(jsonMenu.m_modifiergroups, modifierarticles);

  if (jsonMenu.best_sellers != null) {
    let bestSellerArticlegroup = processJsonArticlegroup({
      id: 0,
      name: jsonMenu.best_sellers["name"]["nl"],
      priority: Infinity,
    });

    jsonMenu.best_sellers.articles.forEach((article) => {
      bestSellerArticlegroup.articleIds.push(article.id);
    });
    articlegroups.push(bestSellerArticlegroup);
  }

  jsonMenu.m_articles.forEach((jsonArticle) => {
    if (!jsonArticle.active) {
      return;
    }
    articles.push(processJsonArticle(jsonArticle, modifiergroups));

    if (jsonArticle.article.articlegroup !== undefined) {
      let foundArticlegroup = false;
      for (let i = 0; i < articlegroups.length; i++) {
        if (articlegroups[i].id === jsonArticle.article.articlegroup.id) {
          foundArticlegroup = true;
          break;
        }
      }
      if (!foundArticlegroup) {
        articlegroups.push(processJsonArticlegroup(jsonArticle.article.articlegroup));
      }
    }

    if (jsonArticle.allergens instanceof Array) {
      jsonArticle.allergens.forEach((jsonAllergen) => {
        let foundAllergen = false;
        for (let i = 0; i < allergens.length; i++) {
          if (allergens[i].id === jsonAllergen.id) {
            foundAllergen = true;
            break;
          }
        }

        if (!foundAllergen) {
          allergens.push(processJsonAllergen(jsonAllergen));
        }
      });
    }
  });

  articles.sort(function (a, b) {
    if (a.priority > b.priority) {
      return -1;
    }
    if (a.priority < b.priority) {
      return 1;
    }

    return 0;
  });

  articlegroups.sort(function (a, b) {
    if (a.priority > b.priority) {
      return -1;
    }
    if (a.priority < b.priority) {
      return 1;
    }

    return 0;
  });

  let serviceArticles = articles.filter((article) => article.type === "SERVICE");

  let supergroups = jsonMenu.supergroups?.filter((supergroup) => supergroup.manual_active) ?? [];
  if (supergroups) {
    supergroups.sort(function (a, b) {
      if (a.priority > b.priority) {
        return -1;
      }
      if (a.priority < b.priority) {
        return 1;
      }

      return 0;
    });
    supergroups.forEach((supergroup) => {
      supergroup.name = getTranslation(supergroup.translation, supergroup.tag);
    });
  }
  return new Menu(allergens, articlegroups, articles, modifierarticles, modifiergroups, serviceArticles, supergroups);
}

/**
 *
 * @param {Array} jsonModifierarticles
 * @return {Modifierarticle[]}
 */
export function processJsonModifierarticles(jsonModifierarticles) {
  let modifierarticles = [];

  jsonModifierarticles.forEach(
    /**
     *
     * @param {Object} jsonModifierarticle
     * @param {number} jsonModifierarticle.id
     * @param {string|null} jsonModifierarticle.description
     * @param {string|null} jsonModifierarticle.modifierarticle.description
     * @param {number|null} jsonModifierarticle.max_n
     * @param {string} jsonModifierarticle.modifierarticle.name
     * @param {number} jsonModifierarticle.price
     * @param {number|null} jsonModifierarticle.priority
     * @param {number|null} jsonModifierarticle.modifierarticle.priority
     */
    (jsonModifierarticle) => {
      if (!jsonModifierarticle.manual_active || !jsonModifierarticle.active) {
        return;
      }
      if (jsonModifierarticle.modifierarticle.name.length !== 0) {
        modifierarticles.push(
          new Modifierarticle(
            jsonModifierarticle.id,
            jsonModifierarticle.description !== null
              ? jsonModifierarticle.description
              : jsonModifierarticle.modifierarticle.description,
            jsonModifierarticle.max_n,
            getTranslation(jsonModifierarticle.translation, jsonModifierarticle.modifierarticle.name),
            jsonModifierarticle.price,
            jsonModifierarticle.priority !== null
              ? jsonModifierarticle.priority
              : jsonModifierarticle.modifierarticle.priority,
            !!jsonModifierarticle.handheld_only,
            !!jsonModifierarticle.is_side_dish
          )
        );
      }
    }
  );

  return modifierarticles;
}

/**
 *
 * @param {Array} jsonModifiergroups
 * @param {Modifierarticle[]} modifierarticles
 * @return {Modifiergroup[]}
 */
export function processJsonModifiergroups(jsonModifiergroups, modifierarticles) {
  let modifiergroups = [];

  jsonModifiergroups.forEach(
    /**
     *
     * @param {Object} jsonModifiergroup
     * @param {Array} jsonModifiergroup.m_modifiergroup_m_modifierarticles
     * @param {number} jsonModifiergroup.id
     * @param {number|null} jsonModifiergroup.max_n
     * @param {number} jsonModifiergroup.modifiergroup.multiple
     * @param {string} jsonModifiergroup.name
     * @param {number} jsonModifiergroup.id
     * @param {number} jsonModifiergroup.modifiergroup.numerable
     * @param {number} jsonModifiergroup.modifiergroup.optional
     * @param {number} jsonModifiergroup.priority
     */
    (jsonModifiergroup) => {
      if (!jsonModifiergroup.manual_active) {
        return;
      }

      let modifierarticleIds = [];
      jsonModifiergroup.m_modifiergroup_m_modifierarticles.forEach((pivot) => {
        let found = false;
        for (let i = 0; i < modifierarticles.length; i++) {
          if (modifierarticles[i].id === pivot.m_modifierarticle_id) {
            found = true;
            break;
          }
        }

        if (found) {
          modifierarticleIds.push(pivot.m_modifierarticle_id);
        }
      });

      if (modifierarticleIds.length > 0) {
        modifiergroups.push(
          new Modifiergroup(
            jsonModifiergroup.id,
            jsonModifiergroup.max_n,
            modifierarticleIds,
            jsonModifiergroup.modifiergroup.multiple === 1,
            getTranslation(jsonModifiergroup.translation, jsonModifiergroup.name),
            jsonModifiergroup.modifiergroup.numerable === 1,
            jsonModifiergroup.modifiergroup.optional === 1,
            jsonModifiergroup.priority
          )
        );
      }
    }
  );

  return modifiergroups;
}

function processJsonOpeningHoursForOneDay(day, index) {
  let dayOfWeek = getApplicationDate().getDay();

  let openingHours = [];

  Object.keys(day).forEach((key) => {
    let openingTime = key.split(":");
    let openingDate = getApplicationDate();
    openingDate.setMilliseconds(0);
    openingDate.setSeconds(0);
    openingDate.setDate(openingDate.getDate() + ((index - dayOfWeek) % 7));
    openingDate.setHours(parseInt(openingTime[0], 10));
    openingDate.setMinutes(parseInt(openingTime[1], 10));

    let closingTime = day[key].split(":");
    let closingDate = getApplicationDate();
    closingDate.setMilliseconds(0);
    closingDate.setSeconds(0);
    closingDate.setDate(closingDate.getDate() + ((index - dayOfWeek) % 7));
    closingDate.setHours(parseInt(closingTime[0], 10));
    closingDate.setMinutes(parseInt(closingTime[1], 10));

    openingHours.push({ openingTime: openingDate, closingTime: closingDate });
  });
  return openingHours;
}

export function processJsonOpeningHours(jsonMerchant) {
  return processOpeningHours(
    JSON.parse(jsonMerchant.monday),
    JSON.parse(jsonMerchant.tuesday),
    JSON.parse(jsonMerchant.wednesday),
    JSON.parse(jsonMerchant.thursday),
    JSON.parse(jsonMerchant.friday),
    JSON.parse(jsonMerchant.saturday),
    JSON.parse(jsonMerchant.sunday)
  );
  // openingHours[1] = processJsonOpeningHoursForOneDay(dayOpeningHours, 8);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.tuesday);
  // openingHours[2] = processJsonOpeningHoursForOneDay(dayOpeningHours, 9);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.wednesday);
  // openingHours[3] = processJsonOpeningHoursForOneDay(dayOpeningHours, 10);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.thursday);
  // openingHours[4] = processJsonOpeningHoursForOneDay(dayOpeningHours, 11);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.friday);
  // openingHours[5] = processJsonOpeningHoursForOneDay(dayOpeningHours, 12);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.saturday);
  // openingHours[6] = processJsonOpeningHoursForOneDay(dayOpeningHours, 13);
  //
  // dayOpeningHours = JSON.parse(jsonMerchant.sunday);
  // openingHours[0] = processJsonOpeningHoursForOneDay(dayOpeningHours, 14);
  //
  // return openingHours;
}

export function processOpeningHours(monday, tuesday, wednesday, thursday, friday, saturday, sunday) {
  let openingHours = new Array(7);

  let dayOpeningHours = monday;
  openingHours[1] = processJsonOpeningHoursForOneDay(dayOpeningHours, 8);

  dayOpeningHours = tuesday;
  openingHours[2] = processJsonOpeningHoursForOneDay(dayOpeningHours, 9);

  dayOpeningHours = wednesday;
  openingHours[3] = processJsonOpeningHoursForOneDay(dayOpeningHours, 10);

  dayOpeningHours = thursday;
  openingHours[4] = processJsonOpeningHoursForOneDay(dayOpeningHours, 11);

  dayOpeningHours = friday;
  openingHours[5] = processJsonOpeningHoursForOneDay(dayOpeningHours, 12);

  dayOpeningHours = saturday;
  openingHours[6] = processJsonOpeningHoursForOneDay(dayOpeningHours, 13);

  dayOpeningHours = sunday;
  openingHours[0] = processJsonOpeningHoursForOneDay(dayOpeningHours, 14);

  return openingHours;
}

export function processJsonOrder(jsonOrder) {
  let order = Object.assign(new Order(), jsonOrder);

  let orderArticles = [];
  jsonOrder.orderArticles.forEach((jsonOrderArticle) => {
    orderArticles.push(processJsonOrderArticle(jsonOrderArticle));
  });

  order.orderArticles = orderArticles;
  order.orderType = jsonOrder.orderType;

  return order;
}

export function processJsonOrderArticle(jsonOrderArticle) {
  let article = Object.assign(new Article(), jsonOrderArticle);
  let orderArticle = new OrderArticle(article, jsonOrderArticle.count, []);

  let orderModifiergroups = [];
  jsonOrderArticle.orderModifiergroups.forEach((jsonOrderModifiergroup) => {
    orderModifiergroups.push(processJsonOrderModifiergroup(jsonOrderModifiergroup));
  });

  orderArticle.orderModifiergroups = orderModifiergroups;

  return orderArticle;
}

export function processJsonOrderModifiergroup(jsonOrderModifiergroup) {
  let modifiergroup = Object.assign(new Modifiergroup(), jsonOrderModifiergroup);
  let orderModifiergroup = new OrderModifiergroup(modifiergroup, []);

  let orderModifierarticles = [];
  jsonOrderModifiergroup.orderModifierarticles.forEach((jsonOrderModifierarticle) => {
    orderModifierarticles.push(processJsonOrderModifierarticle(jsonOrderModifierarticle));
  });

  orderModifiergroup.orderModifierarticles = orderModifierarticles;

  return orderModifiergroup;
}

export function processJsonOrderModifierarticle(jsonOrderModifierarticle) {
  let modifierarticle = Object.assign(new Modifierarticle(), jsonOrderModifierarticle);
  return new OrderModifierarticle(modifierarticle, jsonOrderModifierarticle.count, jsonOrderModifierarticle.info);
}

/**
 *
 * @param response
 * @return {Receipt}
 */
export function processJsonReceipt(response) {
  let merchant = processJsonSpMerchant(response.spReceipt.sp_merchant, response.spReceipt.sp_qr_code);
  let partyId = response.spReceipt.party_id;

  let flagMArticleIds = {};
  if (mynOberStore.merchant && mynOberStore.merchant.ayceSettings) {
    mynOberStore.merchant.ayceSettings.articleFlags.forEach((flag) => {
      flagMArticleIds[flag.articleId] = true;
    });
  }
  let covers = 0;
  let menuTags = null;

  if (response.spReceipt.sp_merchant.use_menu_tags) {
    menuTags = [];
  }

  let spReceiptId = response.spReceipt.id;
  let mobileNumber = null;
  response.spReceipt.sp_mobile_numbers.some((spMobileNumber) => {
    mobileNumber = spMobileNumber.mobile_number;
    return true;
  });

  let receipt = new Receipt(
    spReceiptId,
    JSON.parse(response.spReceipt.sp_receipt_custom_name.json_name),
    merchant,
    mobileNumber,
    partyId,
    response.spReceipt.table_id,
    response.spReceipt.sp_qr_code_identifier,
    [],
    response.spReceipt.sp_ayce_couverts,
    response.spReceipt.transactions,
    response.spReceipt.random_pincode,
    response.spReceipt.updated_at
  );

  let jsonSpOrdergroups = response.spReceipt.sp_ordergroups.filter((spOrdergroup) => {
    return spOrdergroup.ordergroup != null && spOrdergroup.ordergroup.status !== "PENDING";
  });

  receipt.ordergroups = jsonSpOrdergroups.map((jsonOrdergroup) => {
    let handheldOrdergroup = new HandheldOrdergroup(
      jsonOrdergroup.id,
      jsonOrdergroup.sp_handheld_user_id != null,
      jsonOrdergroup.sp_user_id != null,
      jsonOrdergroup.created_at,
      [],
      jsonOrdergroup.sp_ayce_ordergroup,
      jsonOrdergroup.ordergroup.status,
      jsonOrdergroup.ordergroup.payment_status,
      jsonOrdergroup.ordergroup.pos_status,
      jsonOrdergroup.ordergroup.pos_attempts,
      jsonOrdergroup.ordergroup.id
    );
    handheldOrdergroup.receiptArticles = jsonOrdergroup.ordergroup.o_m_articles.map((jsonArticle) => {
      if (flagMArticleIds[jsonArticle.m_article_id]) {
        covers += jsonArticle.amount_open;
      }
      if (mynOberStore.menu && response.spReceipt.sp_merchant.use_menu_tags && jsonArticle.amount_open > 0) {
        let article = mynOberStore.menu.articles.find((article) => article.id === jsonArticle.m_article_id);
        if (article && article.isMenu && article.menuTags) {
          menuTags = menuTags.concat(article.menuTags);
        }
      }
      return processJsonReceiptArticle(jsonArticle);
    });
    return handheldOrdergroup;
  });

  merchant.couverts = covers;
  if (merchant.menuTags && Array.isArray(merchant.menuTags)) {
    if (menuTags == null) {
      menuTags = [];
    }
    menuTags = menuTags.concat(merchant.menuTags);
  }
  merchant.menuTags = menuTags;

  return receipt;
}

/**
 *
 * @param jsonReceiptArticle
 * @param {Array} jsonReceiptArticle.o_m_modifiergroups
 * @param {number} jsonReceiptArticle.m_article_id
 * @param {number} jsonReceiptArticle.amount
 * @param {number} jsonReceiptArticle.amount_closed
 * @param {string} jsonReceiptArticle.name
 * @param {number} jsonReceiptArticle.price
 * @return {ReceiptArticle}
 * @private
 */
export function processJsonReceiptArticle(jsonReceiptArticle, menuTags = null) {
  let orderModifiergroups = [];
  jsonReceiptArticle.o_m_modifiergroups.forEach(
    /**
     *
     * @param {Object} jsonOrderModifiergroup
     * @param {Array} jsonOrderModifiergroup.o_m_modifierarticles
     * @param {number} jsonOrderModifiergroup.m_modifiergroup_id
     * @param {string} jsonOrderModifiergroup.name
     */
    (jsonOrderModifiergroup) => {
      let orderModifierarticles = [];
      jsonOrderModifiergroup.o_m_modifierarticles.forEach(
        /**
         *
         * @param {Object} jsonOrderModifierarticle
         * @param {number} jsonOrderModifierarticle.m_modifierarticle_id
         * @param {string} jsonOrderModifierarticle.name
         * @param {number} jsonOrderModifierarticle.price
         * @param {number} jsonOrderModifierarticle.amount
         */
        (jsonOrderModifierarticle) => {
          orderModifierarticles.push(
            new OrderModifierarticle(
              new Modifierarticle(
                jsonOrderModifierarticle.m_modifierarticle_id,
                null,
                null,
                jsonOrderModifierarticle.name,
                jsonOrderModifierarticle.price,
                0,
                false,
                !!jsonOrderModifierarticle.is_side_dish
              ),
              jsonOrderModifierarticle.amount,
              jsonOrderModifierarticle.info
            )
          );
        }
      );

      orderModifiergroups.push(
        new OrderModifiergroup(
          new Modifiergroup(
            jsonOrderModifiergroup.m_modifiergroup_id,
            null,
            [],
            false,
            jsonOrderModifiergroup.name,
            jsonOrderModifiergroup.numerable === 1,
            false,
            0
          ),
          orderModifierarticles
        )
      );
    }
  );

  let articleName = jsonReceiptArticle.name;

  if (menuTags != null && mynOberStore.menu) {
    let article = mynOberStore.menu.articles.find((article) => article.id === jsonReceiptArticle.m_article_id);
    if (article && article.type !== "FREETPARTICLE") {
      articleName = article.name;
    }
    if (article && article.isMenu && article.menuTags) {
      article.menuTags.forEach((menuTag) => {
        if (!menuTags.includes(menuTag)) {
          menuTags.push(menuTag);
        }
      });
    }
  }

  return new ReceiptArticle(
    jsonReceiptArticle.m_article_id,
    jsonReceiptArticle.amount_open,
    jsonReceiptArticle.amount_paid,
    orderModifiergroups,
    jsonReceiptArticle.name,
    articleName,
    jsonReceiptArticle.price,
    jsonReceiptArticle.info,
    jsonReceiptArticle.transaction_o_m_articles
  );
}

/**
 *
 * @param {Object} jsonSpMerchant
 * @param {number} jsonSpMerchant.id
 * @param {string} jsonSpMerchant.merchant.name
 * @param {number} jsonSpMerchant.latitude
 * @param {number} jsonSpMerchant.longitude
 * @param {boolean} jsonSpMerchant.is_open
 * @param {number} jsonSpMerchant.show_allergens
 * @param {number} jsonSpMerchant.show_description
 * @param {number} jsonSpMerchant.can_move
 * @param {number} jsonSpMerchant.notifications
 * @param {number} jsonSpMerchant.can_pay
 * @param {number} jsonSpMerchant.can_pay_handheld
 * @param {number} jsonSpMerchant.can_pay_online
 * @param {number} jsonSpMerchant.can_waiter
 * @param {number} jsonSpMerchant.user_geo_location
 * @param {=Object} jsonSpMerchant.sp_ayce_setting
 * @param {=number} jsonSpMerchant.sp_ayce_setting.time_interval
 * @param {=number} jsonSpMerchant.sp_ayce_setting.couvert_multiplier
 * @param {=number} jsonSpMerchant.sp_ayce_setting.ordergroups
 * @param {=number} jsonSpMerchant.couverts
 * @param {=string} jsonSpMerchant.image_md5
 */
export function processJsonSpMerchant(jsonSpMerchant, spQrCode) {
  let id = jsonSpMerchant.id;
  let name = jsonSpMerchant.merchant.name;
  let currency = jsonSpMerchant.merchant.currency;
  let location = { latitude: jsonSpMerchant.latitude, longitude: jsonSpMerchant.longitude };
  let isOpen = jsonSpMerchant.is_open;
  let showAllergens = jsonSpMerchant.show_allergens === 1;
  let showDescription = jsonSpMerchant.show_description === 1;
  let canMove = jsonSpMerchant.can_move === 1;
  let canNotification = jsonSpMerchant.notifications === 1;
  let canPay = jsonSpMerchant.can_pay === 1;
  let canPayHandheld = jsonSpMerchant.can_pay_handheld === 1;
  let canPayOnline = jsonSpMerchant.can_pay_online === 1;
  let canWaiter = jsonSpMerchant.can_waiter === 1;
  let canShareOrder = jsonSpMerchant.share_order === true;
  let sharedOrder = [];
  if (canShareOrder) {
    sharedOrder = jsonSpMerchant.shared_order;
  }
  let useGeoLocation = jsonSpMerchant.user_geo_location === 1;
  let imageMd5 = jsonSpMerchant.image_md5;

  let manuallyClosed = false;
  let mustBuildupReceipt = jsonSpMerchant.must_buildup_receipt;
  let paymentMethods = parsePaymentMethods(jsonSpMerchant.payment_methods);

  let styling = JSON.parse(jsonSpMerchant.styling);
  if (styling == null) {
    styling = {};
  }
  if (styling["iframe"] == null) {
    styling["iframe"] = false;
  }
  if (styling["background_color"] == null) {
    styling["background_color"] = "white";
  }
  if (styling["articlegroup_tabs"] == null) {
    styling["articlegroup_tabs"] = {};
  }
  if (styling["articles"] == null) {
    styling["articles"] = {};
  }

  let openingHours = processJsonOpeningHours(jsonSpMerchant);

  if (mynOberStore.isHandheld()) {
    if (handheldStore.tables[jsonSpMerchant.id] == null) {
      handheldStore.tables[jsonSpMerchant.id] = {};
    }
    if (jsonSpMerchant.sp_qr_codes != null) {
      jsonSpMerchant.sp_qr_codes.forEach((spQrCode) => {
        if (
          handheldStore.tables[jsonSpMerchant.id] == null ||
          handheldStore.tables[jsonSpMerchant.id][spQrCode.table_id] == null
        ) {
          handheldActions.createTable(
            new Table(
              spQrCode.table_id,
              spQrCode.identifier,
              spQrCode.table_id,
              null,
              spQrCode.sp_is_every_order_paid,
              true,
              spQrCode.menu_tag?.split("|") ?? []
            ),
            jsonSpMerchant.id
          );
        }
      });
    }
  }

  let floorPlans = jsonSpMerchant.floor_plan === null ? null : JSON.parse(jsonSpMerchant.floor_plan);

  let menuTags = null;
  if (jsonSpMerchant.menu_tags != null) {
    // console.log(jsonSpMerchant.menu_tags);
    if (jsonSpMerchant.menu_tags === "") {
      menuTags = [];
    } else {
      menuTags = jsonSpMerchant.menu_tags.split("|");
    }
  }
  if (spQrCode != null && spQrCode.menu_tag != null) {
    if (menuTags != null && menuTags instanceof Array) {
      menuTags = menuTags.concat(spQrCode.menu_tag.split("|"));
    } else {
      menuTags = spQrCode.menu_tag.split("|");
    }
  }

  let isOrdering = true;
  let enableRemarkInOrdergroup = false;
  let askContactDetails = false;
  if (!mynOberStore.isHandheld()) {
    if (spQrCode) {
      isOrdering = !!spQrCode.is_ordering && !!jsonSpMerchant.is_ordering;
    } else {
      isOrdering = !!jsonSpMerchant.is_ordering;
    }
    enableRemarkInOrdergroup = !!jsonSpMerchant.enable_remark_in_ordergroup;
    askContactDetails = !!jsonSpMerchant.ask_contact_details;
  }

  if (jsonSpMerchant.sp_ayce_setting !== undefined && jsonSpMerchant.sp_ayce_setting !== null) {
    let articleFlags = [];
    if (
      jsonSpMerchant.sp_ayce_setting.sp_ayce_article_flags != null &&
      Array.isArray(jsonSpMerchant.sp_ayce_setting.sp_ayce_article_flags)
    ) {
      articleFlags = jsonSpMerchant.sp_ayce_setting.sp_ayce_article_flags.map((flag) => {
        return new ArticleFlag(flag.m_article_id, flag.categories, flag.round_categories);
      });
    }
    let ayceSettings = {
      timeInterval: jsonSpMerchant.sp_ayce_setting.time_interval,
      couvertMultiplier: jsonSpMerchant.sp_ayce_setting.couvert_multiplier,
      ordergroups: jsonSpMerchant.sp_ayce_setting.ordergroups,
      articleFlags: articleFlags,
      maxDifferentPlus: jsonSpMerchant.sp_ayce_setting.max_different_plus,
    };

    let couverts = jsonSpMerchant.couverts;

    return new AyceMerchant(
      id,
      jsonSpMerchant.merchant.id,
      name,
      jsonSpMerchant.merchant.timezone,
      jsonSpMerchant.merchant.country_codes,
      currency,
      location,
      isOpen,
      showAllergens,
      showDescription,
      openingHours,
      styling,
      manuallyClosed,
      paymentMethods,
      null,
      menuTags,
      canMove,
      canNotification,
      canPay,
      canPayHandheld,
      canPayOnline,
      canWaiter,
      canShareOrder,
      useGeoLocation,
      imageMd5,
      sharedOrder,
      floorPlans,
      jsonSpMerchant.activeMArticleIds,
      jsonSpMerchant.active_plus,
      mustBuildupReceipt,
      isOrdering,
      enableRemarkInOrdergroup,
      askContactDetails,
      jsonSpMerchant.maximum_receipts_per_table,
      ayceSettings,
      couverts,
      jsonSpMerchant.name,
      jsonSpMerchant.image,
      jsonSpMerchant.custom_fields,
      jsonSpMerchant.guest_registration,
      jsonSpMerchant.info,
      jsonSpMerchant.share_order_seconds,
      jsonSpMerchant.activeMArticlePlus,
      jsonSpMerchant.shielded_receipt,
      jsonSpMerchant.use_menu_tags,
      jsonSpMerchant.use_pincode,
      jsonSpMerchant.merchant.mynbonnen_merchant != null
    );
  } else {
    return new SpMerchant(
      id,
      jsonSpMerchant.merchant.id,
      name,
      jsonSpMerchant.merchant.timezone,
      jsonSpMerchant.merchant.country_codes,
      currency,
      location,
      isOpen,
      showAllergens,
      showDescription,
      openingHours,
      styling,
      manuallyClosed,
      paymentMethods,
      null,
      menuTags,
      canMove,
      canNotification,
      canPay,
      canPayHandheld,
      canPayOnline,
      canWaiter,
      canShareOrder,
      useGeoLocation,
      imageMd5,
      sharedOrder,
      floorPlans,
      jsonSpMerchant.activeMArticleIds,
      jsonSpMerchant.active_plus,
      mustBuildupReceipt,
      isOrdering,
      enableRemarkInOrdergroup,
      askContactDetails,
      jsonSpMerchant.maximum_receipts_per_table,
      jsonSpMerchant.name,
      jsonSpMerchant.image,
      jsonSpMerchant.custom_fields,
      jsonSpMerchant.guest_registration,
      jsonSpMerchant.info,
      jsonSpMerchant.share_order_seconds,
      jsonSpMerchant.activeMArticlePlus,
      jsonSpMerchant.shielded_receipt,
      jsonSpMerchant.use_menu_tags,
      jsonSpMerchant.use_pincode,
      jsonSpMerchant.merchant.mynbonnen_merchant != null,
    );
  }
}

/**
 * @param {Object} jsonTaMerchant
 * @param {number} jsonTaMerchant.id
 * @param {string} jsonTaMerchant.merchant.name
 * @param {number} jsonTaMerchant.latitude
 * @param {number} jsonTaMerchant.longitude
 * @param {boolean} jsonTaMerchant.is_open
 * @param {number} jsonTaMerchant.show_allergens
 * @param {number} jsonTaMerchant.show_description
 * @param {string} jsonTaMerchant.complete_method
 * @param {number} jsonTaMerchant.minimum_pickup_minutes
 * @param {number} jsonTaMerchant.minutes_per_block
 * @param {number} jsonTaMerchant.number_of_blocks
 * @param {boolean} jsonTaMerchant.sms
 * @param {boolean} jsonTaMerchant.notifications
 * @param {boolean} jsonTaMerchant.email
 * @param {boolean} jsonTaMerchant.asap
 * @param {boolean} jsonTaMerchant.delivery
 * @param {boolean} jsonTaMerchant.takeaway
 * @param {string} menuTags
 */
export function processJsonTaMerchant(jsonTaMerchant, menuTags = null, spQrCode = null) {
  let openingHours = processJsonOpeningHours(jsonTaMerchant);
  let styling = JSON.parse(jsonTaMerchant.styling);
  if (styling == null) {
    styling = {};
  }
  if (styling["iframe"] == null) {
    styling = { ...styling, iframe: false };
  }

  if (styling["articlegroup_tabs"] == null) {
    styling["articlegroup_tabs"] = {};
  }
  if (styling["articles"] == null) {
    styling["articles"] = {};
  }
  if (styling["background_color"] == null) {
    styling["background_color"] = "whitesmoke";
  }

  if (jsonTaMerchant.payment_methods == null) {
    jsonTaMerchant.payment_methods = JSON.stringify({ option_at_pickup: { extra_costs: 0 } });
  }

  if (typeof jsonTaMerchant.payment_methods === "string") {
    jsonTaMerchant.payment_methods = JSON.parse(jsonTaMerchant.payment_methods);
  }

  let payment_methods = jsonTaMerchant.payment_methods;
  if (spQrCode?.sp_payment_methods != null) {
    payment_methods = spQrCode.sp_payment_methods;
  }

  let paymentMethods = parsePaymentMethods(payment_methods);

  let taPostalCodes = jsonTaMerchant.ta_postal_codes.map(
    (taPostalCode) =>
      new TaPostalCode(
        taPostalCode.postal_code,
        taPostalCode.amount_with_extra_costs,
        taPostalCode.extra_costs,
        taPostalCode.min_amount,
        taPostalCode.except
      )
  );

  let info = null;
  let deliveryTimes = null;
  let deliveryOrderHours = {};
  if (jsonTaMerchant.info != null) {
    info = JSON.parse(jsonTaMerchant.info);
    if (info.delivery_times != null) {
      deliveryTimes = processOpeningHours(
        info.delivery_times.monday,
        info.delivery_times.tuesday,
        info.delivery_times.wednesday,
        info.delivery_times.thursday,
        info.delivery_times.friday,
        info.delivery_times.saturday,
        info.delivery_times.sunday
      );

      deliveryOrderHours = {
        monday: JSON.stringify(info.delivery_times.monday),
        tuesday: JSON.stringify(info.delivery_times.tuesday),
        wednesday: JSON.stringify(info.delivery_times.wednesday),
        thursday: JSON.stringify(info.delivery_times.thursday),
        friday: JSON.stringify(info.delivery_times.friday),
        saturday: JSON.stringify(info.delivery_times.saturday),
        sunday: JSON.stringify(info.delivery_times.sunday),
      };
    }
  }
  if (info instanceof Object && spQrCode?.ta_info instanceof Object) {
    info = mergeObjects(info, spQrCode.ta_info);
  }

  let maxWeightPerTimeBlock = 0;
  let timeslots = null;
  if (jsonTaMerchant.max_weight_per_time_block > 0) {
    maxWeightPerTimeBlock = jsonTaMerchant.max_weight_per_time_block;
    if (jsonTaMerchant.timeslots != null) {
      timeslots = jsonTaMerchant.timeslots;
    }
  }
  if (menuTags != null) {
    menuTags = menuTags.split("|");
  }

  let doPostalCodeCheck = !!jsonTaMerchant.do_ta_postal_code_check;

  if (spQrCode) {
    doPostalCodeCheck = doPostalCodeCheck && !!spQrCode.do_ta_postal_code_check;
  }

  return new TaMerchant(
    jsonTaMerchant.id,
    jsonTaMerchant.merchant.id,
    jsonTaMerchant.merchant.name,
    jsonTaMerchant.merchant.timezone,
    jsonTaMerchant.merchant.country_codes,
    jsonTaMerchant.merchant.currency,
    { latitude: jsonTaMerchant.latitude, longitude: jsonTaMerchant.longitude },
    jsonTaMerchant.is_open,
    jsonTaMerchant.show_allergens === 1,
    jsonTaMerchant.show_description === 1,
    openingHours,
    styling,
    jsonTaMerchant.manually_closed,
    paymentMethods,
    jsonTaMerchant.merchant.mynbonnen_merchant != null,
    jsonTaMerchant.tip,
    menuTags,
    spQrCode?.ta_takeaway_minimum_pickup_minutes
      ? spQrCode?.ta_takeaway_minimum_pickup_minutes
      : jsonTaMerchant.minimum_pickup_minutes,
    jsonTaMerchant.delivery_minimum_pickup_minutes,
    jsonTaMerchant.minutes_per_block,
    jsonTaMerchant.number_of_blocks,
    jsonTaMerchant.sms,
    jsonTaMerchant.notifications,
    jsonTaMerchant.email,
    jsonTaMerchant.asap === 1,
    jsonTaMerchant.delivery === true,
    jsonTaMerchant.takeaway === true,
    jsonTaMerchant.min_amount,
    taPostalCodes,
    jsonTaMerchant.ask_marketing,
    jsonTaMerchant.ask_contact_details,
    jsonTaMerchant.ask_remark === 1,
    info,
    maxWeightPerTimeBlock,
    timeslots,
    deliveryTimes,
    jsonTaMerchant.active,
    jsonTaMerchant.order_max_number_of_days_ahead,
    jsonTaMerchant.allow_ordering_until_closing,
    jsonTaMerchant.is_tables,
    jsonTaMerchant.takeaway_active === 1,
    jsonTaMerchant.delivery_active === 1,
    jsonTaMerchant.ta_master_merchant_id,
    spQrCode?.order_rules != null ? spQrCode.order_rules : jsonTaMerchant.order_rules,
    doPostalCodeCheck,
    {
      monday: jsonTaMerchant.monday,
      tuesday: jsonTaMerchant.tuesday,
      wednesday: jsonTaMerchant.wednesday,
      thursday: jsonTaMerchant.thursday,
      friday: jsonTaMerchant.friday,
      saturday: jsonTaMerchant.saturday,
      sunday: jsonTaMerchant.sunday,
    },
    deliveryOrderHours,
    jsonTaMerchant.image,
    jsonTaMerchant.max_ta_orders_blocks
  );
}

function parsePaymentMethods(payment_methods) {
  let paymentMethods = [];

  if (Array.isArray(payment_methods)) {
    paymentMethods = payment_methods.map((paymentMethod) => {
      let extraCosts = 0;
      let amountWithExtraCosts = 0;
      let keyPaymentMethod = Object.keys(paymentMethod)[0];
      let description = paymentMethod[keyPaymentMethod]["description"];
      let namePaymentMethod = paymentMethod[keyPaymentMethod]["name"];

      let extraPercentage = 0;

      if (paymentMethod[keyPaymentMethod]["extra_costs"] != null) {
        extraCosts = paymentMethod[keyPaymentMethod]["extra_costs"];
      }
      if (paymentMethod[keyPaymentMethod]["extra_percentage"] != null) {
        extraPercentage = paymentMethod[keyPaymentMethod]["extra_percentage"];
      }
      if (paymentMethod[keyPaymentMethod]["amount_with_extra_costs"] != null) {
        amountWithExtraCosts = paymentMethod[keyPaymentMethod]["amount_with_extra_costs"];
      }

      let takeaway = true;
      if (paymentMethod[keyPaymentMethod]["takeaway"] != null) {
        takeaway = paymentMethod[keyPaymentMethod]["takeaway"] === 1;
      }
      let delivery = true;
      if (paymentMethod[keyPaymentMethod]["delivery"] != null) {
        delivery = paymentMethod[keyPaymentMethod]["delivery"] === 1;
      }

      const spActive = paymentMethod[keyPaymentMethod]["active"] !== 0;

      return new PaymentMethod(
        keyPaymentMethod,
        namePaymentMethod != null ? namePaymentMethod : keyPaymentMethod,
        extraCosts,
        extraPercentage,
        amountWithExtraCosts,
        takeaway,
        delivery,
        description,
        spActive
      );
    });
  } else if (payment_methods != null) {
    paymentMethods = Object.keys(payment_methods).map((paymentMethod) => {
      let extraCosts = 0;
      let extraPercentage = 0;
      let amountWithExtraCosts = 0;
      let description = payment_methods[paymentMethod]["description"];

      if (payment_methods[paymentMethod]["extra_costs"] != null) {
        extraCosts = payment_methods[paymentMethod]["extra_costs"];
      }
      if (payment_methods[paymentMethod]["extra_percentage"] != null) {
        extraPercentage = payment_methods[paymentMethod]["extra_percentage"];
      }
      if (payment_methods[paymentMethod]["amount_with_extra_costs"] != null) {
        amountWithExtraCosts = payment_methods[paymentMethod]["amount_with_extra_costs"];
      }
      let takeaway = true;
      if (payment_methods[paymentMethod]["takeaway"] != null) {
        takeaway = payment_methods[paymentMethod]["takeaway"] === 1;
      }
      let delivery = true;
      if (payment_methods[paymentMethod]["delivery"] != null) {
        delivery = payment_methods[paymentMethod]["delivery"] === 1;
      }

      const spActive = payment_methods[paymentMethod]["active"] !== 0;

      return new PaymentMethod(
        paymentMethod,
        extraCosts,
        extraPercentage,
        amountWithExtraCosts,
        takeaway,
        delivery,
        description,
        spActive
      );
    });
  }

  return paymentMethods;
}
