import React from "react";
import withIntl from "../../../utils/withIntl";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { useDialog } from "../../dialogs/DialogProvider";
import PaymentDialogWithTable from "./PaymentDialogWithTable";
import PaymentDialogWithAmount from "./PaymentDialogWithAmount";

function Checkout(props) {
  const [openDialog] = useDialog();
  const { merchant, amountOpen, table } = props;

  return (
    <>
      {merchant &&
        merchant.styling != null &&
        merchant &&
        merchant.styling.payment_methods != null &&
        merchant &&
        merchant.styling.payment_methods.length > 0 && (
          <Button
            variant={"outlined"}
            style={{ margin: "8px", padding: "15px" }}
            onClick={() => {
              if (props.table) {
                openDialog({
                  children: (
                    <PaymentDialogWithTable
                      table={props.table}
                      onPayment={(paymentMethods, printInhouseBill) => {
                        props.onPayment(paymentMethods, printInhouseBill);
                      }}
                    />
                  ),
                });
              } else if (props.amountOpen != null) {
                openDialog({
                  children: (
                    <PaymentDialogWithAmount
                      amountOpen={amountOpen}
                      onPayment={(paymentMethods, printInhouseBill) => {
                        props.onPayment(paymentMethods, printInhouseBill);
                      }}
                    />
                  ),
                });
              }
            }}
          >
            {props.intl.formatMessage({ id: "button_pay_handheld" })}
          </Button>
        )}
    </>
  );
}

export default withRouter(withIntl(Checkout));
