import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import withIntl from "../utils/withIntl";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  message: {
    fontWeight: 800,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function InfoMessageForOrdering(props) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (reason !== "timeout") {
      props.snackbarClickedaway();
    }

    props.setOpen(false);
  };

  return (
    <Snackbar style={{ bottom: "70px" }} open={props.open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={"warning"}>
        <Typography className={classes.message}>{props.intl.formatMessage({ id: "help_ordering" })}</Typography>
      </Alert>
    </Snackbar>
  );
}

export default withIntl(InfoMessageForOrdering);
