import React from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { Button, Paper, Tooltip, Typography } from "@material-ui/core";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { PathNames } from "../../routers/PathNames";
import DialogError from "../dialogs/DialogError";
import { OrderTypes } from "../../utils/order/Order";
import { processJsonOpeningHours, processOpeningHours } from "../../utils/functions/JsonProcessingFunctions";
import { DirectionsBike, DirectionsWalk, Lock } from "@material-ui/icons";
import { format } from "date-fns";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDialog } from "../dialogs/DialogProvider";
import { withRouter } from "react-router-dom";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

const useStyles = makeStyles(() => ({
  div: {
    display: "flex",
    flexFlow: "wrap",
    margin: "0 16px",
    alignItems: "center",
  },
  text: {
    marginLeft: "8px",
  },
  tooltip: {
    fontSize: "0.9rem",
  },
}));

function RestaurantListItem(props) {
  const classes = useStyles();
  const { taMerchant, postalCode } = props;
  const [openDialog] = useDialog();

  const showOpeningHours = (taMerchant, orderType) => {
    let now = getApplicationDate();
    let openinghours = null;

    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        openinghours = processJsonOpeningHours(taMerchant);
        break;
      case OrderTypes.DELIVERY:
        let deliveryTimes = null;
        let info = null;
        if (taMerchant.info != null) {
          info = JSON.parse(taMerchant.info);
          if (info.delivery_times != null) {
            deliveryTimes = processOpeningHours(
              info.delivery_times.monday,
              info.delivery_times.tuesday,
              info.delivery_times.wednesday,
              info.delivery_times.thursday,
              info.delivery_times.friday,
              info.delivery_times.saturday,
              info.delivery_times.sunday
            );
            taMerchant.deliveryTimes = deliveryTimes;
          }
        }
        openinghours = taMerchant.deliveryTimes ? taMerchant.deliveryTimes : [];
        break;
      default:
        return "";
    }

    let dayOfWeek = now.getDay();

    let startOpeningTimes = [];
    let endOpeningTimes = [];

    dayLoop: for (let i = 0; i < 7; i++) {
      if (startOpeningTimes.length === 0 && i > 0) {
        // Closed today
        break;
      }
      let openingHoursDay = openinghours[(dayOfWeek + i) % 7];
      if (!openingHoursDay) {
        break;
      }

      for (let k = 0; k < openingHoursDay.length; k++) {
        let openingHoursRange = openingHoursDay[k];
        let openingTime = new Date(openingHoursRange.openingTime);
        let closingTime = new Date(openingHoursRange.closingTime);

        if (
          endOpeningTimes.length > 0 &&
          openingTime.getTime() === endOpeningTimes[endOpeningTimes.length - 1].getTime()
        ) {
          endOpeningTimes[endOpeningTimes.length - 1] = closingTime;
        } else if (startOpeningTimes.length > 0 && i > 0) {
          break dayLoop;
        }

        if (
          (i === 0 && startOpeningTimes.length > 0) ||
          ((openingTime.getTime() <= now.getTime() || i === 0) && closingTime.getTime() > now.getTime())
        ) {
          // if (startOpeningTimes) {
          startOpeningTimes.push(openingTime);
          endOpeningTimes.push(closingTime);
          // }
        }
      }
    }
    if (startOpeningTimes.length > 0) {
      return (
        <Tooltip
          title={props.intl.formatMessage({
            id: orderType === OrderTypes.TAKEAWAY ? "text_takeaway_times_today" : "text_delivery_times_today",
          })}
          placement={"top"}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className={classes.div}>
            {orderType === OrderTypes.TAKEAWAY ? <DirectionsWalk /> : <DirectionsBike />}

            <div>
              {startOpeningTimes.map((startOpeningTime, index) => {
                const endOpeningTime = endOpeningTimes[index];
                let hoursString = format(startOpeningTime, "HH:mm") + " - " + format(endOpeningTime, "HH:mm");
                return (
                  <Typography key={hoursString} classes={{ root: classes.text }}>
                    {orderType === OrderTypes.TAKEAWAY ? "TAKEAWAY" : "DELIVERY"}
                    {hoursString}
                  </Typography>
                );
              })}
            </div>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div className={classes.div}>
          <Lock />
          <Typography classes={{ root: classes.text }}>
            {props.intl.formatMessage({ id: "text_today_closed" })}
          </Typography>
        </div>
      );
    }
  };
  const info = JSON.parse(taMerchant.info);

  const RedButton = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      fontSize: "1.2rem",
      minWidth: "14ch",
    },
  }))(Button);
  const RedLightButton = withStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      fontSize: "1.2rem",
      minWidth: "14ch",
    },
  }))(Button);

  const CustomPaper = withStyles((theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      padding: 16,
      borderBottomColor: theme.palette.primary.light,
      borderBottomStyle: "solid",
      borderBottomWidth: 8,
    },
  }))(Paper);

  return (
    <CustomPaper elevation={10}>
      <Typography variant={"h6"} style={{ marginBottom: "1rem", fontWeight: 600 }}>
        {taMerchant.merchant.name}
      </Typography>
      <Typography>{info?.contact_details_merchant?.address}</Typography>
      <Typography>
        {info?.contact_details_merchant?.postal_code} {info?.contact_details_merchant?.city}
      </Typography>
      <Typography>{info?.contact_details_merchant?.phone_number}</Typography>

      <RedButton
        style={{ marginTop: 16, marginBottom: 8 }}
        variant={"contained"}
        onClick={() => {
          taMerchant.sp_qr_codes.some((spQrCode) => {
            requestFunctions
              .sendWebcode(spQrCode.identifier)
              .then(() => {
                mynOberActions.setContactDetails({ postalCode: postalCode });
                props.history.push(PathNames.MENUPAGE + "?ordertype=takeaway");
              })
              .catch((text) => {
                openDialog({ children: <DialogError content={text} /> });
              });
            return true;
          });
        }}
      >
        TAKEAWAY
      </RedButton>
      <Typography variant={"h6"}>{props.intl.formatMessage({ id: "OF" })}</Typography>
      <RedLightButton
        style={{ marginTop: 8, marginBottom: 16 }}
        variant={"contained"}
        onClick={() => {
          taMerchant.sp_qr_codes.some((spQrCode) => {
            requestFunctions
              .sendWebcode(spQrCode.identifier)
              .then(() => {
                mynOberActions.setContactDetails({ postalCode: postalCode });
                props.history.push(PathNames.MENUPAGE + "?ordertype=delivery");
              })
              .catch((text) => {
                openDialog({ children: <DialogError content={text} /> });
              });
            return true;
          });
        }}
      >
        DELIVERY
      </RedLightButton>
    </CustomPaper>
  );
}

RestaurantListItem.propTypes = {
  taMerchant: PropTypes.object.isRequired,
  postalCode: PropTypes.string.isRequired,
};

export default withRouter(withIntl(RestaurantListItem));
