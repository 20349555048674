import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ViewOrderModifierarticle from "../../menu/ViewOrderModifierarticle";
import ViewOrderArticle from "../../menu/ViewOrderArticle";
import { Receipt } from "../../../utils/receipt/Receipt";
import { OrderArticle } from "../../../utils/order/OrderArticle";
import { Article } from "../../../utils/menu/Article";
import "./VoidReceipt.css";
import * as requestFunctions from "./../../../utils/functions/RequestFunctions";
import { Table } from "../../../utils/Table";
import { Button } from "@material-ui/core";
import { injectIntl } from "react-intl";
import DialogError from "../../dialogs/DialogError";
import { DialogContext } from "../../dialogs/DialogProvider";

class VoidReceipt extends Component {
  constructor(props) {
    super(props);

    this.state = this.getState();
  }

  getState = () => {
    let receipt = this.props.receipt;
    let orderArticles = null;
    if (this.state == null) {
      orderArticles = receipt.receiptArticles.map((receiptArticle) => {
        let orderArticle = new OrderArticle(
          Object.assign(new Article(), receiptArticle),
          0,
          receiptArticle.orderModifiergroups
        );
        orderArticle.count = 0;
        orderArticle.maxCount = receiptArticle.count - receiptArticle.countClosed;
        return orderArticle;
      });
    } else {
      orderArticles = this.state.orderArticles;
    }

    return {
      orderArticles: orderArticles,
      orderArticleViews: this.createOrderArticleViews(orderArticles),
      toVoidMax: {},
    };
  };

  updateState = () => {
    this.setState(this.getState());
  };

  createOrderArticleViews = (orderArticles) => {
    return orderArticles.map((orderArticle, i) => {
      let orderModifierarticleViews = [];
      orderArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewOrderArticle
          key={i}
          number_in_order={i}
          name={orderArticle.name}
          price={0}
          count={orderArticle.count + "/" + orderArticle.maxCount}
          orderModifierarticleViews={orderModifierarticleViews}
          onRemoveClicked={() => {
            orderArticle.count += -1;
            if (orderArticle.count < 0) {
              orderArticle.count = 0;
            }
            this.updateState();
          }}
          onAddClicked={() => {
            orderArticle.count += 1;
            if (orderArticle.count > orderArticle.maxCount) {
              orderArticle.count = orderArticle.maxCount;
            }
            this.updateState();
          }}
        />
      );
    });
  };

  sendVoid = () => {
    let orderArticles = this.state.orderArticles.filter((orderArticle) => orderArticle.count > 0);
    let voidData = orderArticles.map((orderArticle) => orderArticle.toApiForm());
    // console.log(this.props.table);
    requestFunctions
      .sendVoid(voidData, this.props.table.id, this.props.receipt.spMerchantId)
      .then((resolve) => {
        this.props.onVoided();
      })
      .catch((text) => {
        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={text} /> });
      });
  };

  canVoid = () => {
    return this.state.orderArticles.some((orderArticle) => {
      return orderArticle.count > 0;
    });
  };

  render(): React.ReactNode {
    return (
      <div className={"void-receipt"}>
        {this.state.orderArticleViews}
        <div style={{ textAlign: "center" }}>
          <Button
            disabled={!this.canVoid()}
            color={"primary"}
            style={{ margin: "8px", padding: "15px" }}
            variant={"contained"}
            onClick={this.sendVoid}
          >
            {this.props.intl.formatMessage({ id: "VOID" })}
          </Button>
        </div>
      </div>
    );
  }
}

VoidReceipt.propTypes = {
  receipt: PropTypes.instanceOf(Receipt),
  table: PropTypes.instanceOf(Table),
};

VoidReceipt.contextType = DialogContext;

export default injectIntl(VoidReceipt);
