import React from "react";
import withIntl from "../../../utils/withIntl";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogOkOrCancel from "../../dialogs/DialogOkOrCancel";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { useDialog } from "../../dialogs/DialogProvider";
import DialogError from "../../dialogs/DialogError";
import { format } from "date-fns";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const takeawayStatuses = [
  { value: "new", enabled: false },
  { value: "received", enabled: false },
  { value: "printed", enabled: false },
  { value: "confirmed", enabled: false },
  { value: "error", enabled: false },
  { value: "kitchen", enabled: true },
  { value: "in_delivery", enabled: true },
  { value: "delivered", enabled: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
  },
  iconOutlined: { color: theme.palette.primary.main },
}));

const times = [50, 40, 30, 20, 10, 0, -10, -20, -30, -40, -50];

function TakeawayComActions(props) {
  const takeawayOrder = props.taReceipt.takeawayOrder;
  const classes = useStyles();
  const [status, setStatus] = React.useState(takeawayOrder.status);
  const [changedDeliveryTime, setChangedDeliveryTime] = React.useState(
    takeawayOrder.changed_delivery_time ? new Date(takeawayOrder.changed_delivery_time) : null
  );

  let valuePreorder =
    changedDeliveryTime != null
      ? (Math.floor(changedDeliveryTime.getTime() / 1000) - props.taReceipt.pickupTimestamp) / 60
      : 0;
  const [openDialog] = useDialog();

  return (
    <>
      {props.taReceipt.pickupTimestamp === 0 ? (
        <Select
          label={props.intl.formatMessage({ id: "Expected delivery time in minutes" })}
          variant={"outlined"}
          classes={{ root: classes.root, iconOutlined: classes.iconOutlined }}
          value={0}
          onChange={(e) => {
            openDialog({
              children: (
                <DialogOkOrCancel
                  title={"alert_title_warning"}
                  content={"Are you sure you want to set expected delivery in " + e.target.value + " minutes?"}
                  onOkClicked={() => {
                    requestFunctions
                      .updateTakeawayOrderStatus(
                        props.taReceipt.id,
                        status === "new" ? "confirmed" : status,
                        e.target.value
                      )
                      .then((data) => {
                        // closeDialog();
                        setStatus(data.status);
                        setChangedDeliveryTime(new Date(data.changed_delivery_time));
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                />
              ),
            });
          }}
        >
          {changedDeliveryTime && (
            <MenuItem value={0} disabled={true}>
              {changedDeliveryTime.toLocaleString()}
            </MenuItem>
          )}
          <MenuItem value={10}>+10 min</MenuItem>
          <MenuItem value={20}>+20 min</MenuItem>
          <MenuItem value={30}>+30 min</MenuItem>
          <MenuItem value={40}>+40 min</MenuItem>
          <MenuItem value={50}>+50 min</MenuItem>
          <MenuItem value={60}>+60 min</MenuItem>
          <MenuItem value={90}>+90 min</MenuItem>
          <MenuItem value={120}>+120 min</MenuItem>
        </Select>
      ) : (
        <Select
          label={props.intl.formatMessage({ id: "Expected delivery time in minutes" })}
          variant={"outlined"}
          classes={{ root: classes.root, iconOutlined: classes.iconOutlined }}
          value={valuePreorder}
          onChange={(e) => {
            openDialog({
              children: (
                <DialogOkOrCancel
                  title={"alert_title_warning"}
                  content={
                    "Are you sure you want to set expected delivery time at " +
                    format(new Date((props.taReceipt.pickupTimestamp + e.target.value * 60) * 1000), "HH:mm") +
                    "?"
                  }
                  onOkClicked={() => {
                    requestFunctions
                      .updatePreorderTakeawayOrderStatus(
                        props.taReceipt.id,
                        status === "new" ? "confirmed" : status,
                        e.target.value
                      )
                      .then((data) => {
                        // closeDialog();
                        setStatus(data.status);
                        setChangedDeliveryTime(new Date(data.changed_delivery_time));
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                />
              ),
            });
          }}
        >
          {times.map((minutes) => (
            <MenuItem value={minutes}>
              <Grid container spacing={3}>
                <Grid item xs={6} container>
                  <Typography>
                    {format(new Date((props.taReceipt.pickupTimestamp + minutes * 60) * 1000), "HH:mm")}
                  </Typography>
                </Grid>

                <Grid item xs={6} container justify={"end"}>
                  <Typography>{minutes > 0 ? "+" + minutes + " min" : minutes < 0 ? minutes + " min" : ""}</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      )}
      <Select
        variant={"outlined"}
        classes={{ root: classes.root, iconOutlined: classes.iconOutlined }}
        value={status}
        onChange={(e) => {
          openDialog({
            children: (
              <DialogOkOrCancel
                title={"alert_title_warning"}
                content={
                  "Are you sure you want to update status to " + props.intl.formatMessage({ id: e.target.value })
                }
                onOkClicked={() => {
                  requestFunctions
                    .updateTakeawayOrderStatus(props.taReceipt.id, e.target.value)
                    .then((data) => {
                      // closeDialog();
                      setStatus(data.status);
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={text} /> });
                    });
                }}
              />
            ),
          });
        }}
      >
        {takeawayStatuses.reduce((menuItems, takeawayStatus) => {
          if (status !== takeawayStatus.value && !takeawayStatus.enabled) {
            return menuItems;
          }
          menuItems.push(
            <MenuItem value={takeawayStatus.value} disabled={!takeawayStatus.enabled}>
              {props.intl.formatMessage({ id: takeawayStatus.value })}
            </MenuItem>
          );
          return menuItems;
        }, [])}
      </Select>
    </>
  );
}

export default withIntl(TakeawayComActions);
