import React, { Component } from "react";
import { withRouter } from "react-router";
import { injectIntl } from "react-intl";
import handheldStore from "../../utils/flux/HandheldStore";
import mynOberStore from "../../utils/flux/MynOberStore";
import TableView from "../../components/handheld/smartphone/TableView";
import "./TableOverviewPage.css";
import { HandheldPathNames } from "../../routers/handheld/HandheldRouter";
import SearchBar from "../../components/SearchBar";
import { ReceiptArticle } from "../../utils/receipt/ReceiptArticle";
import FloorPlan from "../../components/floorplan/FloorPlan";
import DialogPaymentHandheldSimple from "../../components/dialogs/DialogPaymentHandheldSimple";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import { Bill } from "../../utils/receipt/Bill";
import { setMerchant } from "../../utils/flux/MynOberActions";
import { getApplicationDate, getLocalDateFromUTC } from "../../utils/functions/HelperFunctions";
import { differenceInSeconds } from "date-fns";
import Grid from "@material-ui/core/Grid";

class TableOverviewPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tables: handheldStore.tables[props.match.params.spMerchantId],
      handheldReceipts: handheldStore.handheldReceipts,
      filter: "",
      spMerchantId: props.match.params.spMerchantId,
    };

    if (parseInt(props.match.params.spMerchantId) !== mynOberStore.merchant.id) {
      setMerchant(handheldStore.spMerchants[props.match.params.spMerchantId]);
    }

    window.tableOverviewPage = this;
  }

  componentDidMount() {
    handheldStore.on(handheldStore.events.TABLE_UPDATED, this.updateState);
    handheldStore.on(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  componentWillUnmount() {
    handheldStore.removeListener(handheldStore.events.TABLE_UPDATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  updateState = (id) => {
    let tables = this.state.tables;
    if (tables[id] == null) {
      return;
    }
    tables[id] = handheldStore.tables[this.props.match.params["spMerchantId"]][id];
    let handheldReceipts = this.state.handheldReceipts;
    if (
      handheldStore.handheldReceipts[handheldStore.tables[this.props.match.params["spMerchantId"]][id].receiptId] !=
      null
    ) {
      handheldReceipts[handheldStore.tables[this.props.match.params["spMerchantId"]][id].receiptId] =
        handheldStore.handheldReceipts[handheldStore.tables[this.props.match.params["spMerchantId"]][id].receiptId];
    }
    this.setState({ tables: tables, handheldReceipts: handheldReceipts });
  };

  // onClickTableView = (id) => {
  //   this.props.history.push("table/" + id);
  // };

  onChangeFilter = (newFilter) => {
    // mynOberActions.changeFilter(newFilter);
    this.setState({ filter: newFilter });
  };

  render() {
    if (this.state.spMerchantId !== this.props.match.params["spMerchantId"]) {
      this.setState({
        spMerchantId: this.props.match.params["spMerchantId"],
        tables: handheldStore.tables[this.props.match.params["spMerchantId"]],
      });
      setMerchant(handheldStore.spMerchants[this.props.match.params["spMerchantId"]]);
    }

    let needToActivateTableFirst = mynOberStore.menu.articles.some((article) => article.isMenu);
    let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: "base" });
    let tableKeys = Object.keys(this.state.tables).sort(collator.compare);
    tableKeys = tableKeys.filter((tableKey) => {
      if (this.state.tables[tableKey].is_every_order_paid) {
        return false;
      }

      if (this.state.filter === "" || this.state.filter === null) {
        return true;
      } else {
        if (tableKey.toLowerCase().includes(this.state.filter.toLowerCase())) {
          return true;
        }
        return false;
      }
    });
    let tables = tableKeys.map((key) => {
      let url =
        needToActivateTableFirst && this.state.tables[key].receiptId == null
          ? `/${this.props.match.params["spMerchantId"]}${HandheldPathNames.TABLE}/${key}/activate`
          : `/${this.props.match.params["spMerchantId"]}${HandheldPathNames.TABLE}/${key}/merchant/menupage`;

      if (!this.state.tables[key].hasQrCode) {
        if (this.state.tables[key].receiptId > 0) {
          if (
            Math.abs(
              differenceInSeconds(
                getLocalDateFromUTC(this.state.handheldReceipts[this.state.tables[key].receiptId].createdAt),
                getApplicationDate()
              )
            ) < 30
          ) {
            return null;
          }
        } else {
          return null;
        }
      }
      return (
        <TableView
          key={this.state.tables[key].id}
          handheldReceipt={this.state.handheldReceipts[this.state.tables[key].receiptId]}
          table={this.state.tables[key]}
          onClick={() => {
            this.props.history.push(url);
          }}
          onClickPrintClose={() => {
            let bill = null;
            let tableId = key;
            let handheldTable = handheldStore.tables[this.props.match.params["spMerchantId"]][tableId];
            if (handheldTable != null) {
              let handheldReceipt =
                handheldStore.handheldReceipts[
                  handheldStore.tables[this.props.match.params["spMerchantId"]][key].receiptId
                ];

              if (handheldReceipt != null) {
                bill = new Bill(handheldReceipt.id, handheldReceipt.spMerchantId, handheldReceipt);
                handheldReceipt.handheldOrdergroups.forEach((handheldOrdergroup) => {
                  handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
                    if (receiptArticle.countOpen > 0) {
                      bill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
                    }
                  });
                });
              }

              const [openDialog] = this.context;
              openDialog({
                children: (
                  <DialogPaymentHandheldSimple
                    tableId={tableId}
                    spMerchantId={this.state.handheldReceipts[this.state.tables[key].receiptId].id}
                  />
                ),
              });
            }
          }}
        />
      );
    });

    return (
      <div className={"table-overview-page"}>
        {this.props.location.pathname.startsWith("/floorplan") ? (
          <FloorPlan
            tables={this.state.tables}
            onOpen={(tableName) => {
              this.props.history.push(`${HandheldPathNames.TABLE}/${tableName}/merchant/menupage`);
            }}
          />
        ) : (
          <div style={{ flex: 1, height: 0, overflowY: "auto", overflowX: "hidden", padding: "0 16px" }}>
            <SearchBar placeHolder={"input_table"} value={this.state.filter} onChange={this.onChangeFilter} />
            <Grid container spacing={3}>
              {tables}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

TableOverviewPage.contextType = DialogContext;

export default withRouter(injectIntl(TableOverviewPage));
