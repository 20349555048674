import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import { getTransaction } from "../../utils/functions/RequestFunctions";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import DialogError from "./DialogError";
import { useDialog } from "./DialogProvider";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogProcessingPinTransaction(props) {
  const classes = useStyles();
  const [pinTransaction, setPinTransaction] = useState(null);

  const updateDialogOpenState = () => {
    if (props.isTakeaway) {
      if (mynOberStore.pinTransaction?.ta_receipt_id) {
        setPinTransaction(mynOberStore.pinTransaction);
      } else {
        setPinTransaction(null);
      }
    } else {
      if (mynOberStore.pinTransaction?.sp_receipt_id) {
        setPinTransaction(mynOberStore.pinTransaction);
      } else {
        setPinTransaction(null);
      }
    }
  };

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.PIN_TRANSACTION_UPDATED, updateDialogOpenState);

    return () => {
      mynOberStore.removeListener(mynOberStore.events.PIN_TRANSACTION_UPDATED, updateDialogOpenState);
    };
  }, []);

  return (
    <Dialog open={!!pinTransaction}>
      <DialogContent>
        <h3>Processing PIN transaction...</h3>
        <div className={classes.div}>
          <CircularProgress />
        </div>
        {pinTransaction && <PinTransactionPoller isTakeaway={props.isTakeaway} />}
      </DialogContent>
    </Dialog>
  );
}

const PinTransactionPoller = withRouter(PinTransactionPollerContent);

function PinTransactionPollerContent(props) {
  const [openDialog] = useDialog();
  useEffect(() => {
    let isRequestPending = false;
    let interval = setInterval(() => {
      if (!isRequestPending) {
        // do request

        if (mynOberStore.pinTransaction) {
          isRequestPending = true;
          getTransaction(mynOberStore.pinTransaction.id).then((data) => {
            console.log(data);
            isRequestPending = false;
            if (data?.transaction?.status === "SUCCEEDED") {
              mynOberActions.setPinTransaction(null);
              if(!data?.transaction.ta_receipt_id) {
                props.history.goBack();
                mynOberActions.orderSendSuccessful(true);
              }
            } else if (data?.transaction?.status === "FAILED") {
              mynOberActions.setPinTransaction(null);
              openDialog({
                children: <DialogError content={"Transaction failed! Please try again!"} />,
              });
            }
          });
        }
      }
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return null;
}

export default withIntl(DialogProcessingPinTransaction);
