// import * as localStorageFunctions from "./LocalStorageFunctions";
import { HandheldUser } from "../user/HandheldUser";
import { MynOberUser } from "../user/MynOberUser";
import * as randomStringCreator from "./RandomStringCreator";
import { storageFactory } from "./StorageFactory";

export const localStore = storageFactory(() => localStorage);
export const sessionStore = storageFactory(() => sessionStorage);

export const oldStorageNames = {
  DEVICE_ID: "DEVICE_ID",
  DEVICE_PASS: "DEVICE_PASS",
  FIRST_ASK_NOTIFICATION: "FIRST_ASK_NOTIFICATION",
  FIRST_TIME_APP: "FIRST_TIME_APP",
  HANDHELD_PASSWORD: "HANDHELD_PASSWORD",
  HANDHELD_USERNAME: "HANDHELD_USERNAME",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};
export const storageNames = {
  DEVICE_ID: "VgsbSG98SBV15dafsas",
  DEVICE_PASS: "YJ5214ULcopwNUTyvgsvhtw",
  FIRST_ASK_NOTIFICATION: "FGHYRTGgyuw8967tr43",
  FIRST_TIME_APP: "GHFINTYR89juhex5w4er",
  HANDHELD_PASSWORD: "DSTF67ui4354vfwvSERTE344",
  HANDHELD_USERNAME: "HUGIjunire6acxac3w3DASD",
  REFRESH_TOKEN: "HBKYUTthwe3434cfgaa3yueb",
};

export function createNewAccount(isHandheld) {
  let deviceId = randomStringCreator.createRandomString(18);
  let devicePass = randomStringCreator.createRandomString(25);

  setItem(storageNames.DEVICE_ID, deviceId);
  setItem(storageNames.DEVICE_PASS, devicePass);
  removeItem(storageNames.REFRESH_TOKEN);

  let firstAskNotification = getItem(storageNames.FIRST_ASK_NOTIFICATION);
  let firstTimeApp = getItem(storageNames.FIRST_TIME_APP);

  if (isHandheld) {
    let username = getItem(storageNames.HANDHELD_USERNAME);
    let password = getItem(storageNames.HANDHELD_PASSWORD);

    return new HandheldUser(deviceId, devicePass, firstAskNotification, firstTimeApp, null, password, username);
  } else {
    return new MynOberUser(deviceId, devicePass, firstAskNotification, firstTimeApp, null);
  }
}

/**
 * Returns HandheldUser or MynOberUser made from data in local storage.
 * @param {boolean} isHandheld
 * @return {Promise<*>}
 */
export function getUserFromLocalStorage(isHandheld) {
  let deviceId = getItem(oldStorageNames.DEVICE_ID);
  if (deviceId !== null) {
    setItem(storageNames.DEVICE_ID, deviceId);
    removeItem(oldStorageNames.DEVICE_ID);
  } else {
    deviceId = getItem(storageNames.DEVICE_ID);
  }

  let devicePass = getItem(oldStorageNames.DEVICE_PASS);
  if (devicePass !== null) {
    setItem(storageNames.DEVICE_PASS, devicePass);
    removeItem(oldStorageNames.DEVICE_PASS);
  } else {
    devicePass = getItem(storageNames.DEVICE_PASS);
  }

  if (deviceId === null || devicePass === null) {
    return createNewAccount(isHandheld);
  } else {
    let refreshToken = getItem(oldStorageNames.REFRESH_TOKEN);
    if (refreshToken !== null) {
      setItem(storageNames.REFRESH_TOKEN, refreshToken);
      removeItem(oldStorageNames.REFRESH_TOKEN);
    } else {
      refreshToken = getItem(storageNames.REFRESH_TOKEN);
    }

    let firstAskNotification = getItem(oldStorageNames.FIRST_ASK_NOTIFICATION);
    if (firstAskNotification !== null) {
      setItem(storageNames.FIRST_ASK_NOTIFICATION, firstAskNotification);
      removeItem(oldStorageNames.FIRST_ASK_NOTIFICATION);
    } else {
      firstAskNotification = getItem(storageNames.FIRST_ASK_NOTIFICATION);
    }

    let firstTimeApp = getItem(oldStorageNames.FIRST_TIME_APP);
    if (firstTimeApp !== null) {
      setItem(storageNames.FIRST_TIME_APP, firstTimeApp);
      removeItem(oldStorageNames.FIRST_TIME_APP);
    } else {
      firstTimeApp = getItem(storageNames.FIRST_TIME_APP);
    }

    if (isHandheld) {
      let username = getItem(oldStorageNames.HANDHELD_USERNAME);
      if (username !== null) {
        setItem(storageNames.HANDHELD_USERNAME, username);
        removeItem(oldStorageNames.HANDHELD_USERNAME);
      } else {
        username = getItem(storageNames.HANDHELD_USERNAME);
      }

      let password = getItem(oldStorageNames.HANDHELD_PASSWORD);
      if (password !== null) {
        setItem(storageNames.HANDHELD_PASSWORD, password);
        removeItem(oldStorageNames.HANDHELD_PASSWORD);
      } else {
        password = getItem(storageNames.HANDHELD_PASSWORD);
      }

      return new HandheldUser(
        deviceId,
        devicePass,
        firstAskNotification,
        firstTimeApp,
        refreshToken,
        password,
        username
      );
    } else {
      return new MynOberUser(deviceId, devicePass, firstAskNotification, firstTimeApp, refreshToken);
    }
  }
}

export function getItem(key) {
  let result = localStore.getItem(key);

  if (result === null) {
    if (key === storageNames.FIRST_ASK_NOTIFICATION || key === storageNames.FIRST_TIME_APP) {
      result = true;
    }
  } else {
    if (result === "false") {
      return false;
    } else if (result === "true") {
      return true;
    }
  }

  return result;
}

export function setItem(key, value) {
  return localStore.setItem(key, value.toString());
}

export function removeItem(key) {
  return localStore.removeItem(key);
}
