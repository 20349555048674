import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ButtonDrawerMenu from "../ButtonDrawerMenu";
import { backgroundOverlayId } from "../../../App";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";

const drawerMenuId = "drawer-menu";

export function openDrawer() {
  let backgroundOverlay = document.getElementById(backgroundOverlayId);
  if (backgroundOverlay !== null) {
    if (!backgroundOverlay.classList.contains("background-overlay-visible")) {
      backgroundOverlay.classList.add("background-overlay-visible");
    }
  }

  let drawerMenu = document.getElementById(drawerMenuId);
  if (drawerMenu != null) {
    if (!drawerMenu.classList.contains("slide-in")) {
      drawerMenu.classList.remove("slide-out");
      drawerMenu.classList.add("slide-in");
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

function DrawerMenu(props) {
  const classes = useStyles();
  const handleClickOutside = (event) => {
    if (event.target === document.getElementById(backgroundOverlayId)) {
      closeDrawer();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const closeDrawer = () => {
    let drawerMenu = document.getElementById(drawerMenuId);
    if (drawerMenu != null) {
      if (drawerMenu.classList.contains("slide-in")) {
        let backgroundOverlay = document.getElementById(backgroundOverlayId);
        if (backgroundOverlay !== null) {
          if (backgroundOverlay.classList.contains("background-overlay-visible")) {
            backgroundOverlay.classList.remove("background-overlay-visible");
          }
        }
        drawerMenu.classList.remove("slide-in");
        drawerMenu.classList.add("slide-out");
      }
    }
  };

  let buttons = props.buttons.filter((button) => {
    return button.enabled == null || button.enabled === true;
  });
  const buttonViews = buttons.map((button) => {
    return (
      <ButtonDrawerMenu
        {...button}
        onLongPress={() => {
          if (button.onLongPress) {
            closeDrawer();
            button.onLongPress();
          }
        }}
        onClick={() => {
          closeDrawer();
          button.onClick();
        }}
      />
    );
  });

  return (
    <div id={drawerMenuId} className={"drawer-menu " + classes.root}>
      <div className={"drawer-menu-header"}>
        <Button onClick={closeDrawer} startIcon={<MenuIcon />} style={{ height: 54 }}>
          {props.intl.formatMessage({ id: "button_close" })}
        </Button>
      </div>
      <div className={"drawer-menu-content"}>{buttonViews}</div>
    </div>
  );
}

DrawerMenu.propTypes = {
  buttons: PropTypes.array.isRequired,
};

export default withIntl(DrawerMenu);
