import { OrderModifiergroup } from "../order/OrderModifiergroup";

export class ReceiptArticle {
  constructor(id, countOpen, countClosed, orderModifiergroups, name, displayName, price, info, transactionOMArticles) {
    this.id = id;
    this.count = countOpen + countClosed;
    this.countClosed = countClosed;
    this.orderModifiergroups = orderModifiergroups;
    this.name = name;
    this.displayName = displayName;
    this.price = price;
    this.info = info;
    this.transactionOMArticles = transactionOMArticles;
  }

  /**
   *
   * @returns {number}
   */
  get countOpen() {
    return this.count - this.countClosed;
  }

  /**
   *
   * @returns {number}
   */
  get amount() {
    if (this.count === 0) {
      return 0;
    }
    let totalAmount = this.price * this.count;
    this.orderModifiergroups.forEach((orderModifiergroup) => {
      orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
        if (orderModifierarticle.isSideDish && false) {
          totalAmount += orderModifierarticle.count * orderModifierarticle.price;
        } else {
          totalAmount += orderModifierarticle.count * orderModifierarticle.price * this.count;
        }
      });

      // if (orderModifiergroup.numerable) {
      //   totalAmount += orderModifiergroup.amount;
      // } else {
      //   totalAmount += orderModifiergroup.amount * this.count;
      // }
    });
    return totalAmount;
  }

  get oldOpenAmount() {
    let oldOpenAmount = 0;
    let articleInfo = this.info;
    if (articleInfo != null && articleInfo.oldPrice != null) {
      oldOpenAmount += articleInfo.oldPrice * this.countOpen;
    }

    this.orderModifiergroups.forEach((orderModifiergroup: OrderModifiergroup) => {
      orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
        if (orderModifierarticle.isSideDish && false) {
          if (orderModifierarticle.info != null && orderModifierarticle.info.oldPrice != null) {
            oldOpenAmount += orderModifierarticle.info.oldPrice * orderModifierarticle.count;
          }

        } else {
          if (orderModifierarticle.info != null && orderModifierarticle.info.oldPrice != null) {
            oldOpenAmount += orderModifierarticle.info.oldPrice * orderModifierarticle.count * this.countOpen;
          }
        }
      });
    });
    return oldOpenAmount;
  }

  /**
   *
   * @returns {number}
   */
  get amountClosed() {
    if (this.count === 0) {
      return 0;
    } else {
      return (this.amount / this.count) * this.countClosed;
    }
  }

  /**
   *
   * @returns {number}
   */
  get amountOpen() {
    return this.amount - this.amountClosed;
  }

  get hasNumerableOrderModifiergroup() {
    return this.orderModifiergroups.some((orderModifiergroup: OrderModifiergroup) => {
      return orderModifiergroup.numerable;
    });
  }

  getPaymentMethods(transactionsObject) {
    let paymentMethods = {};
    this.transactionOMArticles.forEach((transactionOMArticle) => {
      let transaction = transactionsObject[transactionOMArticle.transaction_id];

      let totalOfTurnoverTransactions = 0;

      if (transaction) {
        transaction.turnover_transactions.forEach((turnoverTransaction) => {
          totalOfTurnoverTransactions += turnoverTransaction.amount;
        });

        transaction.turnover_transactions.forEach((turnoverTransaction) => {
          if (!paymentMethods[turnoverTransaction.turnover_group]) {
            paymentMethods[turnoverTransaction.turnover_group] = { amount: 0, count: 0 };
          }

          paymentMethods[turnoverTransaction.turnover_group].amount +=
            transactionOMArticle.amount *
            (this.amount / this.count) *
            (turnoverTransaction.amount / totalOfTurnoverTransactions);

          paymentMethods[turnoverTransaction.turnover_group].count += parseInt(transactionOMArticle.amount);
        });
      }
    });

    return paymentMethods;
  }

  /**
   *
   * @param {*} obj
   * @returns {boolean}
   */
  equals(obj) {
    if (!(obj instanceof ReceiptArticle)) {
      return false;
    }

    if (obj.id !== this.id) {
      return false;
    }

    if (obj.name !== this.name) {
      return false;
    }
    if (obj.price !== this.price) {
      return false;
    }

    if (obj.orderModifiergroups.length !== this.orderModifiergroups.length) {
      return false;
    }
    if (obj !== this && (obj.hasNumerableOrderModifiergroup || this.hasNumerableOrderModifiergroup)) {
      return false;
    }

    return !obj.orderModifiergroups.some((objOrderModifiergroup) => {
      return (
        this.orderModifiergroups.findIndex((orderModifiergroup) => {
          return objOrderModifiergroup.equals(orderModifiergroup);
        }) === -1
      );
    });
  }
}
