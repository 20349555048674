import React, { Component } from "react";
import "../smartphone/HandheldContainer.css";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import TaReceiptView from "../../../components/handheld/takeaway/TaReceiptView";
import { getLocalDateFromUTC } from "../../../utils/functions/HelperFunctions";
import { withTheme } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import SearchBar from "../../../components/SearchBar";
import mynOberStore from "../../../utils/flux/MynOberStore";
import DialogProcessingPinTransaction from "../../../components/dialogs/DialogProcessingPinTransaction";

class TaHandheldContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      taReceipts: taHandheldStore.taReceipts,
      searchT: "",
      searchTableNumber: "",
    };

    window.taHandheldContainer = this;

    this.isTables = Object.keys(taHandheldStore.taMerchants).some(
      (taMerchantId) => taHandheldStore.taMerchants[taMerchantId].isTables
    );
  }

  componentDidMount() {
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_CREATED, this.updateState);
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_UPDATED, this.updateState);
    taHandheldStore.on(taHandheldStore.events.TARECEIPT_CLOSED, this.updateState);
  }

  componentWillUnmount() {
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_CREATED, this.updateState);
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_UPDATED, this.updateState);
    taHandheldStore.removeListener(taHandheldStore.events.TARECEIPT_CLOSED, this.updateState);
  }

  updateState = (id) => {
    let taReceipts = this.state.taReceipts;
    if (taHandheldStore.taReceipts[id] == null) {
      delete taReceipts[id];
    } else {
      taReceipts[id] = taHandheldStore.taReceipts[id];
    }
    this.setState({ taReceipts: taReceipts });
  };

  // --------------------------------------------------

  render() {
    let taReceiptIds = Object.keys(this.state.taReceipts).filter(
      (taReceiptId) => this.state.taReceipts[taReceiptId].status === "ORDERED"
    );

    taReceiptIds.sort((a, b) => {
      // console.log(a);
      // console.log(this.state.taReceipts[a]);
      // console.log(this.state.taReceipts[b]);
      if (this.state.taReceipts[a].pickupTimestamp !== this.state.taReceipts[b].pickupTimestamp) {
        return this.state.taReceipts[a].pickupTimestamp - this.state.taReceipts[b].pickupTimestamp;
      } else {
        return (
          -1 *
          (getLocalDateFromUTC(this.state.taReceipts[a].updatedAt) -
            getLocalDateFromUTC(this.state.taReceipts[b].updatedAt))
        );
      }
    });
    // console.log(taReceiptIds);

    let taReceipts = taReceiptIds
      .filter(
        (taReceiptId) =>
          this.state.taReceipts[taReceiptId].tableId.includes(this.state.searchTableNumber) &&
          (this.state.searchT === "" ||
            this.state.taReceipts[taReceiptId].number === parseInt(this.state.searchT.replace("T", "")))
      )
      .map((id) => {
        return <TaReceiptView key={this.state.taReceipts[id].id} taReceipt={this.state.taReceipts[id]} />;
      });

    return (
      <div className={"ta-handheld-container"} style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
        {mynOberStore.isHandheld() && <DialogProcessingPinTransaction isTakeaway={true} />}
        <div
          className={"top-navigation position-sticky"}
          style={{ backgroundColor: this.props.theme.palette.primary.main, top: 0 }}
        >
          <div className={"top-navigation-merchant-box-name"}>
            <Typography className={"top-navigation-merchant-name"}>
              {this.props.intl.formatMessage({ id: "TAKEAWAY" })} / {this.props.intl.formatMessage({ id: "DELIVERY" })}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {this.isTables && (
            <SearchBar
              dontScroll={true}
              value={this.state.searchTableNumber}
              onChange={(value) => {
                this.setState({ searchTableNumber: value });
              }}
              placeHolder={"table"}
            />
          )}
          <SearchBar
            dontScroll={true}
            value={this.state.searchT}
            onChange={(value) => {
              this.setState({ searchT: value });
            }}
            placeHolder={"T"}
          />
        </div>
        {taReceipts}
      </div>
    );
  }
}

export default withTheme(withRouter(injectIntl(TaHandheldContainer)));
