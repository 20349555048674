import { Route, Switch, withRouter } from "react-router-dom";
import React, { Component } from "react";
import LoginHandheldPage from "../pages/web/LoginHandheldPage";
import ReturnPage from "../pages/web/ReturnPage";
import HomeContainer from "../containers/smartphone/HomeContainer";
import TableOrderContainer from "../containers/smartphone/TableOrderContainer";
import { PathNames } from "./PathNames";
import "../../font.css";
import "./Main.css";
import PropTypes from "prop-types";
import QrScannerContainer from "../containers/smartphone/QrScannerContainer";
import mynOberStore from "../utils/flux/MynOberStore";
import HandheldRouter from "./handheld/HandheldRouter";
import TakeawayOverviewContainer from "../containers/smartphone/TakeawayOverviewContainer";
import TableContainerWrapper from "../containers/handheld/smartphone/TableContainerWrapper";

class Main extends Component {
  constructor(props) {
    super(props);

    if (mynOberStore.merchant != null && mynOberStore.menu != null && !mynOberStore.isHandheld()) {
      if (window.location.pathname === PathNames.HOMEPAGE && props.store.receipt != null) {
        props.history.push(PathNames.MENUPAGE);
      } else if (window.location.pathname.substr(0, 3) === PathNames.QRPAGE) {
        props.history.replace(PathNames.MENUPAGE);
      }
    }
  }

  render() {
    return mynOberStore.isHandheld() ? (
      <Switch>
        {/*<Route*/}
        {/*  path={PathNames.MERCHANT}*/}
        {/*  render={(props) => <MerchantContainer {...props} store={mynOberStore}/>*/}
        {/*  }*/}
        {/*/>*/}
        <Route
          render={
            !this.props.isHandheldLoggedIn
              ? (props) => <LoginHandheldPage {...props} />
              : (props) => <TableContainerWrapper {...props} />
            // : (props) => <HomeContainer {...props} store={this.props.store}/>)
          }
        />
      </Switch>
    ) : (
      <Switch>
        <Route exact path={PathNames.TAKEAWAYOVERVIEW} render={(props) => <TakeawayOverviewContainer {...props} />} />
        <Route exact path={PathNames.QRPAGE} render={(props) => <HomeContainer {...props} />} />
        <Route exact path={PathNames.QRSCANNERPAGE} render={(props) => <QrScannerContainer {...props} />} />
        <Route exact path={PathNames.QRPAGE + "/:qrcode"} render={(props) => <HomeContainer {...props} />} />
        <Route path={PathNames.MERCHANT} render={(props) => <TableOrderContainer {...props} />} />
        <Route path={PathNames.RETURNPAGE} render={(props) => <ReturnPage {...props} />} />
        {window.homeContainer === "TakeawayOverviewContainer" ? (
          <Route render={(props) => <TakeawayOverviewContainer {...props} />} />
        ) : (
          <Route render={(props) => <HomeContainer {...props} />} />
        )}
      </Switch>
    );
  }
}

Main.propTypes = {
  isHandheldLoggedIn: PropTypes.bool.isRequired,
};

export default withRouter(Main);
