import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputText: {
    textAlign: "center",
    padding: "10px",
    fontSize: "20px",
    width: "70%",
  },
}));

function DialogMove(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const [webcode, setWebcode] = React.useState("");

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_move" })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: "text_qr_move" })}</h3>
        <div className={classes.div}>
          <input
            autoComplete={"off"}
            className={classes.inputText}
            type={"text"}
            placeholder={props.intl.formatMessage({ id: "input_webcode" })}
            value={webcode}
            onChange={(e) => {
              setWebcode(e.target.value.toUpperCase());
            }}
          />

          <Button
            className={classes.button}
            variant={"outlined"}
            disabled={webcode.length === 0}
            onClick={() => {
              closeDialog();
              props.onOkClicked(webcode);
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogMove);
