export const PaymentMethods = {
  CASH: 0,
  CARD: 0,
  IDEAL: 0,
  PAYPAL: 0,
  MASTERCARD: 0,
  GIROPAY: 0,
  SOFORT: 0,
  BANCONTACT: 0,
  PAYCONIQ: 0,
};

export class PaymentMethod {
  constructor(key, name, extraCosts, extraPercentage, amountWithExtraCosts, takeaway, delivery, description, spActive) {
    this.key = key;
    this.name = name;
    this.extraCosts = extraCosts;
    this.extraPercentage = extraPercentage;
    this.amountWithExtraCosts = amountWithExtraCosts;
    this.takeaway = takeaway;
    this.delivery = delivery;
    this.description = description;
    this.spActive = spActive;
  }

  isOnline() {
    if (
      paynlPaymentMethods.includes(this.key) ||
      onlinePaymentMethodParts.some((sub) => {
        return this.key.includes(sub);
      })
    ) {
      return true;
    }
  }
}

const paynlPaymentMethods = [
  "IDEAL",
  "GIROPAY",
  "SOFORT",
  "BANCONTACT",
  "IDEALQR",
  "PAYPAL",
  "MASTERCARD",
  "PAYCONIQ",
  "EPS",
];
const onlinePaymentMethodParts = ["OPP_"];

export function hasOnlinePayment(paymentMethods) {
  return paymentMethods.some((paymentMethod) => {
    if (paynlPaymentMethods.includes(paymentMethod.key)) {
      return true;
    } else {
      return onlinePaymentMethodParts.some((sub) => {
        return paymentMethod.key.includes(sub);
      });
    }
  });
}
