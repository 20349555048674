export class Merchant {
  constructor(
    id,
    merchantId,
    name,
    timezone,
    countryCodes,
    currency,
    location,
    isOpen,
    showAllergens,
    showDescription,
    openingHours,
    styling,
    manuallyClosed,
    paymentMethods,
    isMynbonnenMerchant,
    tip,
    menuTags,
  ) {
    this._id = id;
    this.merchantId = merchantId;
    this._name = name;
    this.timezone = timezone;
    this.countryCodes = countryCodes;
    this.currency = currency;
    this._location = location;
    this._isOpen = isOpen;
    this._showAllergens = showAllergens;
    this._showDescription = showDescription;
    this._imageURL = null;
    this._openingHours = openingHours;
    this._styling = styling;
    this.manuallyClosed = manuallyClosed;
    this.paymentMethods = paymentMethods;
    this.isMynbonnenMerchant = isMynbonnenMerchant;
    this.tip = tip;
    this.menuTags = menuTags;
  }

  /**
   *
   * @return {number}
   */
  get id() {
    return this._id;
  }

  /**
   *
   * @return {string}
   */
  get name() {
    return this._name;
  }

  /**
   *
   * @return {{latitude: number, longitude: number}}
   */
  get location() {
    return this._location;
  }

  /**
   *
   * @return {boolean}
   */
  get isOpen() {
    return this._isOpen;
  }

  /**
   *
   * @return {boolean}
   */
  get showAllergens() {
    return this._showAllergens;
  }

  /**
   *
   * @return {boolean}
   */
  get showDescription() {
    return this._showDescription;
  }

  /**
   *
   * @returns {String}
   */
  get imageURL() {
    return this._imageURL;
  }

  /**
   *
   * @param {string} imageURL
   */
  set imageURL(imageURL) {
    this._imageURL = imageURL;
  }

  /**
   *
   * @returns {{openingTime: Date, closingTime: Date}[]}
   */
  get openingHours() {
    return this._openingHours;
  }

  /**
   *
   * @returns {Object}
   */
  get styling(): Object {
    return this._styling;
  }

  /**
   *
   * @param value
   */
  set styling(value: Object) {
    this._styling = value;
  }
}
