import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import { useDialog } from "./DialogProvider";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogError(props) {
  const classes = useStyles();
  let text = props.content;
  const [, closeDialog] = useDialog();
  if (
    text == null ||
    text.length === 0 ||
    text === mynOberAPIClientManager.apiMessages.SERVER_TIMEOUT ||
    text === mynOberAPIClientManager.apiMessages.UNKNOWN_ERROR
  ) {
    text = "alert_text_failed_something";
  }
  if (text === mynOberAPIClientManager.apiMessages.UNAUTHORIZED) {
    text = "alert_text_failed_login";
  }
  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_failed" })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: text })}</h3>
        <div className={classes.div}>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
}

DialogError.propTypes = {
  content: PropTypes.string,
};

export default withIntl(DialogError);
