import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import DialogError from "./DialogError";
import DialogOk from "./DialogOk";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogPaymentHandheldSimple(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const buttonTitles = ["button_card", "button_cash", "button_proforma"];
  const tableId = props.tableId;

  const onOptionClicked = (buttonTitle) => {
    closeDialog();
    if (buttonTitle === "button_proforma") {
      requestFunctions
        .printClose(tableId, false, buttonTitle, props.spMerchantId)
        .then(() => {
          openDialog({ children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} /> });
        })
        .catch((text) => {
          openDialog({ children: <DialogError content={text} /> });
        });
    } else {
      requestFunctions
        .printClose(tableId, true, buttonTitle, props.spMerchantId)
        .then(() => {
          openDialog({
            children: <DialogOk title={"alert_title_success"} content={"alert_text_success_payment_handheld"} />,
          });
        })
        .catch((text) => {
          openDialog({ children: <DialogError content={text} /> });
        });
    }
  };
  return (
    <>
      <DialogTitle>
        <h3>{props.intl.formatMessage({ id: "alert_title_payment_details" })}</h3>
      </DialogTitle>
      <DialogContent>
        <div className={classes.div}>
          {buttonTitles.map((buttonTitle) => {
            return (
              <Button className={classes.button} variant={"outlined"} onClick={() => onOptionClicked(buttonTitle)}>
                {props.intl.formatMessage({ id: buttonTitle })}
              </Button>
            );
          })}

          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogPaymentHandheldSimple);
