import { useDialog } from "./DialogProvider";
import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { OrderTypes } from "../../utils/order/Order";
import { DeliveryOrder } from "../../utils/order/DeliveryOrder";
import { TakeawayOrder } from "../../utils/order/TakeawayOrder";
import DialogPostalcodeCheck from "./DialogPostalcodeCheck";
import TakeawayDeliveryInfo from "../TakeawayDeliveryInfo";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
}));

export const takeawayOrDeliverySelected = (type, openDialog, closeDialog) => {
  if (closeDialog) {
    closeDialog();
  }
  if (type === "delivery") {
    mynOberActions.setOrderType(OrderTypes.DELIVERY);
    if (mynOberStore.deliveryOrder == null) {
      mynOberActions.updateDeliveryOrder(
        new DeliveryOrder(null, mynOberStore.merchant.paymentMethods[0], false, 0, null, 0)
      );
    }
    if (mynOberStore.merchant.doTaPostalCodeCheck) {
      if (openDialog) {
        openDialog({
          children: (
            <DialogPostalcodeCheck
              onPostalcode={(postalcode) => {
                console.log(postalcode);
                mynOberActions.setContactDetails({ postalCode: postalcode });
              }}
            />
          ),
        });
      }
    }
  } else if (type === "takeaway") {
    mynOberActions.setOrderType(OrderTypes.TAKEAWAY);
    if (mynOberStore.takeawayOrder == null) {
      mynOberActions.updateTakeawayOrder(
        new TakeawayOrder(null, mynOberStore.merchant.paymentMethods[0], false, null, 0)
      );
    }
  }
};

function DialogDeliveryOrTakeaway(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();

  let buttonTitles = [];
  if (mynOberStore.merchant.takeawayActive) {
    buttonTitles.push("button_to_takeaway");
  }
  if (mynOberStore.merchant.deliveryActive) {
    buttonTitles.push("button_to_delivery");
  }

  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>{props.intl.formatMessage({ id: "welcome" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          variant={"subtitle1"}
          dangerouslySetInnerHTML={{ __html: mynOberStore.merchant.info?.welcome_message }}
        />
        {props.ordertype == null && (
          <div style={{ textAlign: "center", marginBottom: 6 }}>
            {buttonTitles.map((buttonTitle) => {
              return (
                <Button
                  color={"primary"}
                  key={buttonTitle}
                  className={classes.button}
                  variant={"contained"}
                  onClick={() =>
                    takeawayOrDeliverySelected(
                      buttonTitle === "button_to_takeaway" ? "takeaway" : "delivery",
                      openDialog,
                      closeDialog
                    )
                  }
                >
                  {props.intl.formatMessage({ id: buttonTitle })}
                </Button>
              );
            })}
          </div>
        )}
        <TakeawayDeliveryInfo onlyHours={true} />
      </DialogContent>
      {props.ordertype != null && (
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={() => {
              takeawayOrDeliverySelected(props.ordertype, openDialog, closeDialog);
            }}
          >
            CONTINUE
          </Button>
        </DialogActions>
      )}
    </>
  );
}

export default withIntl(DialogDeliveryOrTakeaway);
