import React, { Component } from "react";
import PropTypes from "prop-types";
import "./EmptyMessage.css";
import { injectIntl } from "react-intl";
import { scrollableTabBarId } from "./web/ScrollableTabBar";
import Typography from "@material-ui/core/Typography";

class EmptyMessage extends Component {
  render() {
    const element = document.getElementById("menu-container");
    const element2 = document.getElementById("sticky-header-menu-bar");
    const element3 = document.getElementById(scrollableTabBarId);
    let minHeight = null;
    if (element && element2) {
      minHeight =
        element.getBoundingClientRect().height -
        element2.getBoundingClientRect().height +
        element3.getBoundingClientRect().height;
    }

    return (
      <div style={{ minHeight: minHeight }}>
        <Typography className={"empty-message"}>
        {this.props.messageStringIds.map((messageStringId) => {
          return this.props.intl.formatMessage({ id: messageStringId }) + "\n";

        })}
        </Typography>
      </div>
    );
  }
}

EmptyMessage.propTypes = {
  messageStringIds: PropTypes.array.isRequired,
};

export default injectIntl(EmptyMessage);
