import React, { Component } from "react";
import PropTypes from "prop-types";

class BottomTabs extends Component {
  render() {
    return (
      <div
        style={{
          bottom: 0,
          width: "100%",
          height: this.props.bottomTabs.length === 0 ? "0" : "54px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 300, width: "100%", height: "100%", display: "flex" }}>{this.props.bottomTabs}</div>
      </div>
    );
  }
}

BottomTabs.propTypes = {
  bottomTabs: PropTypes.array.isRequired,
};

export default BottomTabs;
