import React, { Component } from "react";
import handheldStore from "../../../utils/flux/HandheldStore";
import { withRouter } from "react-router";
import OrdergroupOverview from "../../../components/handheld/smartphone/OrdergroupOverview";
import { injectIntl } from "react-intl";
import Typography from "@material-ui/core/Typography";

class HandheldOrdersContainer extends Component {
  constructor(props) {
    super(props);

    let tableId = props.match.params.tableId;

    let handheldReceipt = null;
    if (
      handheldStore.tables[props.match.params["spMerchantId"]][tableId] &&
      handheldStore.tables[props.match.params["spMerchantId"]][tableId].receiptId > 0
    ) {
      handheldReceipt =
        handheldStore.handheldReceipts[handheldStore.tables[props.match.params["spMerchantId"]][tableId].receiptId];
    }
    this.state = {
      table: handheldStore.tables[props.match.params["spMerchantId"]][tableId],
      handheldReceipt: handheldReceipt,
    };

    window.HandheldOrdersContainer = this;
  }

  componentDidMount(): void {
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.on(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  componentWillUnmount(): void {
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, this.updateState);
    handheldStore.removeListener(handheldStore.events.TABLE_CREATED, this.updateState);
  }

  updateState = () => {
    if (handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId] != null)
      this.setState({
        handheldReceipt:
          handheldStore.handheldReceipts[
            handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].receiptId
          ],
      });
  };

  render(): React.ReactNode {
    let roundNumber = 0;
    let ordergroupViews;
    if (this.state.handheldReceipt != null) {
      let handheldOrdergroups = this.state.handheldReceipt.handheldOrdergroups.sort((a, b) => {
        if (a.createdAt < b.createdAt) {
          return -1;
        }
        if (a.createdAt > b.createdAt) {
          return 1;
        }

        return 0;
      });
      handheldOrdergroups = handheldOrdergroups.filter((handheldOrdergroup) => {
        let found = false;
        for (let i = 0; i < handheldOrdergroup.receiptArticles.length; i++) {
          let receiptArticle = handheldOrdergroup.receiptArticles[i];
          if (receiptArticle.count > 0) {
            found = true;
            break;
          }
        }
        return found;
      });
      ordergroupViews = handheldOrdergroups.map((handheldOrdergroup, index) => {
        if (handheldOrdergroup.isUser) {
          roundNumber++;
          return (
            <OrdergroupOverview
              isFirst={index === handheldOrdergroups.length - 1}
              receipt={this.state.handheldReceipt}
              table={this.state.table}
              handheldOrdergroup={handheldOrdergroup}
              roundNumber={roundNumber}
              key={handheldOrdergroup.id}
            />
          );
        } else {
          return (
            <OrdergroupOverview
              isFirst={index === handheldOrdergroups.length - 1}
              handheldOrdergroup={handheldOrdergroup}
              key={handheldOrdergroup.id}
              receipt={this.state.handheldReceipt}
              table={this.state.table}
            />
          );
        }
      });

      ordergroupViews = ordergroupViews.reverse();
    }
    return (
      <div
        style={{
          display: "flex",
          padding: 5,
          overflowY: "auto",
          flex: "1 1 auto",
          flexBasis: 0,
          maxWidth: 800,
          width: "100%",
          alignSelf: "center",
          flexDirection: "column",
        }}
      >
        <Typography style={{ fontWeight: 800, textAlign: "center" }}>
          {this.props.intl.formatMessage({ id: "text_order_overview" })}
        </Typography>
        {ordergroupViews}
      </div>
    );
  }
}

export default injectIntl(withRouter(HandheldOrdersContainer));
