import mynOberDispatcher from "./MynOberDispatcher";
import mynOberStore from "./MynOberStore";
import ReactGA from "react-ga";

/**
 * Adds orderArticle to order.
 * @param orderArticle
 * @param {boolean} callSharedOrderUpdated
 */
export function addOrderArticle(orderArticle, callSharedOrderUpdated = true) {
  if (orderArticle.count > 0) {
    let trackerNames = ["MynOber"];
    if (mynOberStore.merchant?.taMasterMerchantId === 1) {
      trackerNames.push("ILS");
    }
    ReactGA.event(
      {
        category: "User Actions",
        action: "Added article to shopping cart",
        label: orderArticle.plu + "-" + orderArticle.name,
        value: orderArticle.count,
      },
      trackerNames
    );
  }

  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.ADD_ORDER_ARTICLE,
    orderArticle,
    callSharedOrderUpdated,
  });
}

window.addOrderArticle = addOrderArticle;

/**
 * Clears timer for updating AYCE merchant.
 */
export function clearMerchantUpdateTimer() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.CLEAR_MERCHANT_UPDATE_TIMER,
  });
}

/**
 * Clears merchant, menu and order.
 */
export function exitMerchant() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.EXIT_MERCHANT,
  });
}

/**
 * Loads data from local storage and sets user.
 * @param {boolean} isHandheld
 */
export function getDataFromLocalStorage(isHandheld) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.GET_DATA_FROM_LOCAL_STORAGE,
    isHandheld: isHandheld,
  });
}

/**
 * Will login the HandheldUser.
 * @param {string} password
 * @param {string} username
 */
export function loginHandheld(password, username) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.LOGIN_HANDHELD,
    username,
    password,
  });
}

/**
 * Will login the HandheldUser when username and password already set.
 */
export function loginHandheldFromIndex(handheldUser) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.LOGIN_HANDHELD_FROM_INDEX,
    handheldUser: handheldUser,
  });
}

/**
 * Will logout the HandheldUser.
 */
export function logoutHandheld() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.LOGOUT_HANDHELD,
  });
}

/**
 * Will clear current order and emit ORDER_SEND event.
 */
export function orderSendSuccessful(byUser = true) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.ORDER_SEND_SUCCESSFUL,
    byUser: byUser,
  });
}

/**
 * Resets device id, device pass and refresh token for user.
 */
export function resetUser() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.RESET_USER,
  });
}

export function setContactDetails(contactDetails) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.UPDATE_CONTACT_DETAILS,
    contactDetails: contactDetails,
  });
}

export function updateDeliveryOrder(deliveryOrder) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.UPDATE_DELIVERY_ORDER,
    deliveryOrder: deliveryOrder,
  });
}

export function updateTakeawayOrder(takeawayOrder) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.UPDATE_TAKEAWAY_ORDER,
    takeawayOrder: takeawayOrder,
  });
}

export function updateUserSelectedPackaging(packaging) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.USER_SELECTED_PACKAGING,
    packaging: packaging,
  });
}

/**
 * Sets FirstAskNotification of user.
 * @param value
 */
export function setFirstAskNotification(value) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_FIRST_ASK_NOTIFICATION,
    value,
  });
}

/**
 * Sets FirstTimeApp of user.
 * @param value
 */
export function setFirstTimeApp(value) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_FIRST_TIME_APP,
    value,
  });
}

/**
 * Sets new app state.
 * @param {boolean} visible
 */
export function setIsAppVisible(visible) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_IS_APP_VISIBLE,
    visible: visible,
  });
}

/**
 * Sets to app to isLoading or not.
 * @param {boolean} isLoading
 */
export function setIsLoading(isLoading) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_IS_LOADING,
    isLoading: isLoading,
  });
}

/**
 * Sets isWidescreen.
 * @param {boolean} isWidescreen
 */
export function setIsWidescreen(isWidescreen) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_IS_WIDESCREEN,
    isWidescreen: isWidescreen,
  });
}

/**
 * Sets Merchant and Webcode when Merchant is recovered successfully
 * @param {Merchant} merchant
 * @param {Menu} menu
 * @param {?string} webcode
 */
export function setMerchantWithMenuAndWebcode(merchant, menu, webcode) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_MERCHANT_WITH_MENU_AND_WEBCODE,
    merchant: merchant,
    menu: menu,
    webcode: webcode,
  });
}

export function setMerchant(merchant) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_MERCHANT,
    merchant: merchant,
  });
}

export function setOrder(order) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_ORDER,
    order: order,
  });
}

export function setFailedOrderDialogIsOpen(isOpen) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_FAILED_ORDER_DIALOG_IS_OPEN,
    isOpen: isOpen,
  });
}

export function updateOrder(properties) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.UPDATE_ORDER,
    properties: properties,
  });
}

export function setOrderType(orderType) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_ORDER_TYPE,
    orderType: orderType,
  });
}

/**
 * Sets new phoneNumber for receipt.
 * @param phoneNumber
 */
export function setPhoneNumber(phoneNumber) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_PHONE_NUMBER,
    phoneNumber: phoneNumber,
  });
}

/**
 * Sets new receipt.
 * @param {Receipt} receipt
 */
export function setReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_RECEIPT,
    receipt: receipt,
  });
}

/**
 * Clears receipt
 */
export function clearReceipt() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.CLEAR_RECEIPT,
  });
}

export function setReceiptWithMerchantAndMenu(receipt, merchant, menu, webcode) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_RECEIPT_WITH_MERCHANT_AND_MENU,
    receipt: receipt,
    merchant: merchant,
    menu: menu,
    webcode: webcode,
  });
}

/**
 * Sets new refreshToken for user.
 * @param refreshToken
 */
export function setRefreshToken(refreshToken) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_REFRESH_TOKEN,
    refreshToken,
  });
}

/**
 * Saves given webcode.
 * @param webcode
 */
export function setWebcode(webcode) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_WEBCODE,
    webcode: webcode,
  });
}

/**
 * Saves given spQrCode.
 * @param spQrCode
 */
export function setSpQrCode(spQrCode) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_SP_QR_CODE,
    spQrCode: spQrCode,
  });
}

export function setSharedOrderUuid(sharedOrderUuid, isFromWebsocket, shareOrderSeconds) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_SHARED_ORDER_UUID,
    sharedOrderUuid: sharedOrderUuid,
    isFromWebsocket: isFromWebsocket,
    shareOrderSeconds: shareOrderSeconds,
  });
}

export function clearOrder() {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.CLEAR_ORDER,
  });
}

export function setPinTransaction(pinTransaction) {
  mynOberDispatcher.dispatch({
    type: mynOberStore.actions.SET_PIN_TRANSACTION,
    pinTransaction: pinTransaction
  });
}
