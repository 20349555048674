import { Button, DialogActions, DialogContent } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";
import { Warning } from "@material-ui/icons";

function DialogExtra(props) {
  const [, closeDialog] = useDialog();
  return (
    <>
      <DialogContent>
        <div style={{ display: "flex", alignItems: "center", fontSize: "1.5rem" }}>
          <Warning style={{ fontSize: 40 }} color={"secondary"} />
          {props.content}
        </div>
      </DialogContent>
      <DialogActions>
        <Button style={{ width: "240px" }} variant={"outlined"} onClick={closeDialog}>
          {props.intl.formatMessage({ id: "button_cancel" })}
        </Button>
        <Button
          style={{ width: "240px" }}
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            if (props.onOkClicked) {
              props.onOkClicked();
            }
            closeDialog();
          }}
        >
          {props.intl.formatMessage({ id: "button_add_to_order" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withIntl(DialogExtra);
