import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./TableView.css";
import { Table } from "../../../utils/Table";
import { HandheldPathNames } from "../../../routers/handheld/HandheldRouter";
import mynOberStore from "../../../utils/flux/MynOberStore";
import handheldStore from "../../../utils/flux/HandheldStore";
import CountDownTimer from "../CountDownTimer";
import { Button, ButtonBase, Paper, Typography } from "@material-ui/core";
import { getApplicationDate, getLocalDateFromUTCFormattedString } from "../../../utils/functions/HelperFunctions";
import Grid from "@material-ui/core/Grid";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";

class TableView extends Component {
  constructor(props) {
    super(props);

    let timeLimitExceeded = false;
    let timer = null;
    let handheldReceipt = this.props.handheldReceipt;
    if (handheldReceipt != null) {
      if (handheldReceipt.spAyceCover != null && handheldReceipt.spAyceCover.time_limit != null) {
        let date = getApplicationDate();
        if (date.getTime() / 1000 > handheldReceipt.spAyceCover.time_limit) {
          timeLimitExceeded = true;
        } else {
          if (timer != null) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            this.setState({ timeLimitExceeded: true, timer: null });
          }, 1000 + handheldReceipt.spAyceCover.time_limit * 1000 - date.getTime());
        }
      }
    }

    this.state = {
      timeLimitExceeded: timeLimitExceeded,
      timer: timer,
    };
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    this.updateState(nextProps);
  }

  updateState = (nextProps) => {
    let timeLimitExceeded = false;
    let timer = this.state.timer;
    let handheldReceipt = nextProps.handheldReceipt;
    if (handheldReceipt != null) {
      if (handheldReceipt.spAyceCover != null && handheldReceipt.spAyceCover.time_limit != null) {
        let date = getApplicationDate();
        if (date.getTime() / 1000 > handheldReceipt.spAyceCover.time_limit) {
          timeLimitExceeded = true;
        } else {
          if (timer != null) {
            clearTimeout(timer);
          }
          timer = setTimeout(() => {
            this.setState({ timeLimitExceeded: true, timer: null });
          }, 1000 + handheldReceipt.spAyceCover.time_limit * 1000 - date.getTime());
        }
      }
    }
    this.setState({ timeLimitExceeded: timeLimitExceeded, timer: timer });
  };

  render() {
    let hasTaMerchants = Object.keys(taHandheldStore.taMerchants).length > 0;
    return (
      <Grid
        item
        xs={hasTaMerchants ? 12 : 6}
        sm={hasTaMerchants ? 6 : 4}
        md={hasTaMerchants ? 4 : 3}
        lg={hasTaMerchants ? 3 : 2}
        xl={hasTaMerchants ? 3 : 1}
        className={"table-view"}
      >
        <ButtonBase
          style={{ width: "100%" }}
          component={"div"}
          onClick={(e) => {
            if (this.props.onClick != null) {
              this.props.onClick(this.props.table.id);
            }
          }}
        >
          <Paper
            style={{
              width: "100%",
              minHeight: 100,
              color: "black",
              backgroundColor:
                this.props.handheldReceipt == null
                  ? "lightgrey"
                  : this.state.timeLimitExceeded
                  ? "orangered"
                  : "lawngreen",
              padding: 5,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography style={{ fontWeight: 800 }} noWrap={true}>
                {this.props.table.name}
              </Typography>
              {handheldStore.handheldReceipts[this.props.table.receiptId]?.randomPincode && (
                <Typography style={{ fontWeight: 800 }} noWrap={true}>
                  {handheldStore.handheldReceipts[this.props.table.receiptId].randomPincode}
                </Typography>
              )}
            </div>
            {mynOberStore.isWidescreen &&
            this.props.table.receiptId != null &&
            handheldStore.handheldReceipts[this.props.table.receiptId] != null &&
            handheldStore.handheldReceipts[this.props.table.receiptId].handheldOrdergroups.length > 0 &&
            this.state.timer == null ? (
              <Typography style={{ fontWeight: 800 }}>
                {getLocalDateFromUTCFormattedString(
                  handheldStore.handheldReceipts[this.props.table.receiptId].handheldOrdergroups[0].createdAt
                )}
              </Typography>
            ) : null}
            {this.props.handheldReceipt != null &&
              this.props.handheldReceipt.getSecondsBeforeTimeLimitExceeds() > 0 && (
                <CountDownTimer
                  seconds={Math.round(this.props.handheldReceipt.getSecondsBeforeTimeLimitExceeds() / 1000)}
                />
              )}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/*<Link to={`${HandheldPathNames.TABLE}/${this.props.table.id}/activate`}>*/}
              {mynOberStore.menu.articles.some((article) => article.isMenu) ? (
                <Button
                  style={{ maxWidth: "100%", flex: "0 1", width: "auto" }}
                  variant={"contained"}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.history.push(
                      "/" +
                        this.props.match.params["spMerchantId"] +
                        `${HandheldPathNames.TABLE}/${this.props.table.id}/activate`
                    );
                  }}
                >
                  {this.props.intl.formatMessage({ id: "MENUS" })}
                </Button>
              ) : null}

              {this.props.table.status === "Active" ? (
                <Button
                  style={{ maxWidth: "100%", flex: "0 1", width: "auto", fontSize: "0.9em" }}
                  variant={"contained"}
                  onClick={(e) => {
                    this.props.history.push(
                      "/" +
                        this.props.match.params["spMerchantId"] +
                        `${HandheldPathNames.TABLE}/${this.props.table.id}/merchant/receiptpage`
                    );
                    e.stopPropagation();
                  }}
                >
                  {this.props.intl.formatMessage({ id: "RECEIPT" })}
                </Button>
              ) : null}
              {this.props.table.status === "Active" && mynOberStore.merchant.styling.payment_methods == null ? (
                <Button
                  variant={"contained"}
                  style={{ overflow: "hidden", whiteSpace: "nowrap" }}
                  onClick={() => {
                    this.props.onClickPrintClose(this.props.table.id);
                  }}
                >
                  {this.props.intl.formatMessage({ id: "PRINT_CLOSE" })}
                </Button>
              ) : null}
            </div>
          </Paper>
        </ButtonBase>
      </Grid>
    );
  }
}

TableView.propTypes = {
  table: PropTypes.instanceOf(Table).isRequired,
  onClick: PropTypes.func,
  onClickPrintClose: PropTypes.func,
};

export default withRouter(injectIntl(TableView));
