import React, { Component } from "react";
import PropTypes from "prop-types";
import "./HeaderMerchant.css";
import { withTheme } from "@material-ui/core/styles";

export const headerMerchantId = "header-merchant";

class HeaderMerchant extends Component {
  render() {
    return (
      <div
        id={headerMerchantId}
        className={"header-merchant"}
        style={{ backgroundColor: this.props.theme.header_image?.palette?.background?.default }}
      >
        <img id={"header-merchant-img"} alt={this.props.name} src={this.props.uriMerchantImage} />
      </div>
    );
  }
}

HeaderMerchant.propTypes = {
  name: PropTypes.string.isRequired,
  uriMerchantImage: PropTypes.string.isRequired,
};

export default withTheme(HeaderMerchant);
