import React, { forwardRef } from "react";
import { TextField } from "@material-ui/core";
import withIntl from "../../../utils/withIntl";

function InputTextFieldPhoneNumber(props, ref) {
  return (
    <TextField
      required
      placeholder={props.intl.formatMessage({ id: "input_phone_number" })}
      name={"phone"}
      margin={"dense"}
      variant={"outlined"}
      inputRef={ref}
      fullWidth={true}
      {...props}
    />
  );
}

export default withIntl(forwardRef(InputTextFieldPhoneNumber));
