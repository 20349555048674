import React from "react";
import withIntl from "../../utils/withIntl";
import FormField from "../FormField";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import mynOberStore from "../../utils/flux/MynOberStore";

function CustomTextField(props) {
  const customField = props.customField;
  const [value, setValue] = React.useState(
    mynOberStore.order.customFields && mynOberStore.order.customFields[customField.key]
      ? mynOberStore.order.customFields[customField.key].value
      : customField.value
  );
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <FormField
        maxLength={customField.maxLength ?? 512}
        rows={customField.rows ?? 1}
        multiline={customField.multiline ?? false}
        required={customField.required}
        disabled={customField.disabled}
        value={value}
        hide={false}
        name={customField.label}
        pattern={customField.pattern}
        titleLabel={
          customField.translate_label ? props.intl.formatMessage({ id: customField.label }) : customField.label
        }
        onChange={(value) => {
          let customFields = Object.assign({}, mynOberStore.order.customFields);
          if (customFields[customField.key] == null) {
            customFields[customField.key] = {};
          }
          customFields[customField.key].value = value;
          customFields[customField.key].type = customField.type;
          mynOberActions.updateOrder({ customFields: customFields });
          setValue(value);
        }}
      />
    </div>
  );
}

export default withIntl(CustomTextField);
