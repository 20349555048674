import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./MenuPage.css";
import EmptyMessage from "../components/EmptyMessage";
import mynOberStore from "../utils/flux/MynOberStore";
import ListArticlegroup from "../components/menu/ListArticlegroup";

export const menuPageId = "menu-page";

function MenuPage(props) {
  const content = useMemo(() => {
    if (props.articlegroups.length === 0) {
      if (mynOberStore.menus.covers === 0 && mynOberStore.menus.menuTags.length === 0) {
        return <EmptyMessage messageStringIds={["Please, wait for the waiter"]} />;
      } else {
        return <EmptyMessage messageStringIds={["text_menu_empty"]} />;
      }
    } else {
      return <ListArticlegroup articlegroup={props.articlegroups} />;
    }
  }, [props.articlegroups]);

  if (mynOberStore.isHandheld() && mynOberStore.isWidescreen) {
    return (
      <div className={"menu-page"} id={menuPageId} style={{ display: "flex", height: "100%" }}>
        {props.children}
        {content}
      </div>
    );
  }
  return (
    <div className={"menu-page"} id={menuPageId}>
      {props.children}
      {content}
    </div>
  );
}

MenuPage.propTypes = {
  articlegroups: PropTypes.array,
  articlegroup: PropTypes.element,
};

export default MenuPage;
