import React from "react";
import { injectIntl } from "react-intl";
import icOrder from "./../../../../assets/images/bottombar/ic-order.svg";
import icOrderActive from "./../../../../assets/images/bottombar/ic-order-active.svg";
import BottomTab from "./BottomTab";
import mynOberStore from "../../../utils/flux/MynOberStore";
import { AyceMerchant } from "../../../utils/merchant/AyceMerchant";

function OrderBottomTab(props) {
  return (
    <BottomTab
      icon={icOrder}
      activeIcon={icOrderActive}
      onClick={() => props.onClick()}
      selected={props.selected}
      badge={
        mynOberStore.merchant instanceof AyceMerchant &&
        !mynOberStore.isHandheld() &&
        mynOberStore.merchant.maxWeight > 0
          ? mynOberStore.order.weight + "/" + mynOberStore.merchant.maxWeight
          : mynOberStore.order.count === 0
          ? null
          : mynOberStore.order.count
      }
      title={props.intl.formatMessage({ id: "tab_order" })}
      tooltipTitle={props.intl.formatMessage({ id: "item_added" })}
      isTooltipOpen={props.isTooltipOpen}
    />
  );
}

export default injectIntl(OrderBottomTab);
