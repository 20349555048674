import mynOberDispatcher from "./MynOberDispatcher";
import taHandheldStore from "./TaHandheldStore";

/**
 * Sets Merchant
 * @param {array} taMerchants
 */
export function setTaMerchants(taMerchants) {
  mynOberDispatcher.dispatch({
    type: taHandheldStore.actions.SET_TA_MERCHANTS,
    taMerchants: taMerchants,
  });
}

export function createTaReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: taHandheldStore.actions.CREATE_TARECEIPT,
    receipt: receipt,
  });
}

export function updateTaReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: taHandheldStore.actions.UPDATE_TARECEIPT,
    receipt: receipt,
  });
}

export function closeTaReceipt(receiptId) {
  mynOberDispatcher.dispatch({
    type: taHandheldStore.actions.CLOSE_TARECEIPT,
    receiptId: receiptId,
  });
}

export function deleteTaReceipt(receiptId) {
  mynOberDispatcher.dispatch({
    type: taHandheldStore.actions.DELETE_TARECEIPT,
    receiptId: receiptId,
  });
}
