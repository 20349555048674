import React from "react";
import withIntl from "../../../utils/withIntl";
import { MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogOkOrCancel from "../../dialogs/DialogOkOrCancel";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { useDialog } from "../../dialogs/DialogProvider";
import DialogError from "../../dialogs/DialogError";

const ubereatsStates = [
  { value: "CREATED", enabled: true },
  { value: "ACCEPTED", enabled: true },
  // { value: "DENIED", enabled: true },
  { value: "FINISHED", enabled: true },
  { value: "CANCELED", enabled: true },
];

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
  },
  iconOutlined: { color: theme.palette.primary.main },
}));



function UbereatsActions(props) {
  console.log(props.taReceipt)
  const ubereatsOrder = props.taReceipt.ubereatsOrder;
  const classes = useStyles();
  const [status, setStatus] = React.useState(ubereatsOrder.status);

  const [openDialog] = useDialog();
  return (
    <>

      <Select
        variant={"outlined"}
        classes={{ root: classes.root, iconOutlined: classes.iconOutlined }}
        value={status}
        onChange={(e) => {
          openDialog({
            children: (
              <DialogOkOrCancel
                title={"alert_title_warning"}
                content={
                  "Are you sure you want to update status to " + props.intl.formatMessage({ id: e.target.value })
                }
                onOkClicked={() => {
                  requestFunctions
                    .updateUbereatsOrderStatus(props.taReceipt.id, e.target.value)
                    .then((data) => {
                      // closeDialog();
                      setStatus(data.status);
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={text} /> });
                    });
                }}
              />
            ),
          });
        }}
      >
        {ubereatsStates.reduce((menuItems, takeawayStatus) => {
          if (status !== takeawayStatus.value && !takeawayStatus.enabled) {
            return menuItems;
          }
          menuItems.push(
            <MenuItem value={takeawayStatus.value} disabled={!takeawayStatus.enabled}>
              {props.intl.formatMessage({ id: takeawayStatus.value })}
            </MenuItem>
          );
          return menuItems;
        }, [])}
      </Select>
    </>
  );
}

export default withIntl(UbereatsActions);
