import mynOberDispatcher from "./MynOberDispatcher";
import handheldStore from "./HandheldStore";

export function createTable(table, spMerchantId) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.CREATE_TABLE,
    table: table,
    spMerchantId: spMerchantId,
  });
}

export function updateTable(table, spMerchantId) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.UPDATE_TABLE,
    table: table,
    spMerchantId: spMerchantId,
  });
}

export function createReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.CREATE_RECEIPT,
    receipt: receipt,
    spMerchantId: receipt.spMerchantId,
  });
}

export function updateReceipt(receipt) {
  // handheld action
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.UPDATE_RECEIPT,
    receipt: receipt,
    spMerchantId: receipt.spMerchantId,
  });
}

export function closeReceipt(receiptId, tableId, spMerchantId) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.CLOSE_RECEIPT,
    receiptId: receiptId,
    tableId: tableId,
    spMerchantId: spMerchantId,
  });
}

export function deleteReceipt(receiptId, tableId, spMerchantId) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.DELETE_RECEIPT,
    receiptId: receiptId,
    tableId: tableId,
    spMerchantId: spMerchantId,
  });
}

export function createTaReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.CREATE_TARECEIPT,
    receipt: receipt,
  });
}

export function updateTaReceipt(receipt) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.UPDATE_TARECEIPT,
    receipt: receipt,
  });
}

export function closeTaReceipt(receiptId) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.CLOSE_TARECEIPT,
    receiptId: receiptId,
  });
}

export function setSpMerchants(spMerchants) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.SET_SP_MERCHANTS,
    spMerchants: spMerchants,
  });
}

export function setDirectSaleMode(isInDirectSaleMode) {
  mynOberDispatcher.dispatch({
    type: handheldStore.actions.SET_DIRECT_SALE_MODE,
    isInDirectSaleMode: isInDirectSaleMode,
  });
}
