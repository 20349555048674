import React from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { days, WeekHours } from "./WeekHours";
import { DayHour, hourToMinutes } from "./DayHour";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

export function getOpeningHoursOfObject(object) {
  let openingHoursDays = {};
  days.forEach((day) => {
    if (object[day]) {
      let parsedOpeningHours = JSON.parse(object[day]);

      let dayHours = Object.keys(parsedOpeningHours).map((openingHour) => {
        return new DayHour(openingHour, parsedOpeningHours[openingHour]);
      });
      dayHours.sort((a, b) => {
        return a.openingHour.localeCompare(b.openingHour);
      });

      openingHoursDays[day] = dayHours;
    } else {
      openingHoursDays[day] = [];
    }
  });

  //// Fix overlapping times
  days.forEach((day, index) => {
    let dayRanges = openingHoursDays[day];
    let previousDayRanges = openingHoursDays[days[(index + 7 - 1) % 7]];

    if (dayRanges.length > 0 && previousDayRanges.length > 0) {
      if (
        !dayRanges[0].isClosed() &&
        dayRanges[0].openingHour === "00:00" &&
        !previousDayRanges[previousDayRanges.length - 1].isClosed() &&
        previousDayRanges[previousDayRanges.length - 1].closingHour === "24:00"
      ) {
        if (
          hourToMinutes(dayRanges[0].closingHour) <
          hourToMinutes(previousDayRanges[previousDayRanges.length - 1].openingHour)
        ) {
          previousDayRanges[previousDayRanges.length - 1].closingHour = dayRanges[0].closingHour;
          dayRanges.splice(0, 1);
        }
      }
    }
  });

  return new WeekHours(openingHoursDays);
}

const useStyles = makeStyles(() => ({
  disabledTitle: { color: "lightgray" },
}));

function OpeningHoursTakeawayInfoScreen(props) {
  const classes = useStyles();
  const weekOpenRanges = getOpeningHoursOfObject(props.takeawayOpeningHours);
  const deliveryWeekOpenRanges = getOpeningHoursOfObject(props.deliveryOpeningHours);

  // if (props.disabled) {
  //   theme.text.disabled = { color: "lightGray" };
  // }

  const columnWidth = mynOberStore.merchant.takeaway && mynOberStore.merchant.delivery ? 4 : 8;

  return (
    <Grid container alignItems={"center"} spacing={1}>
      <Grid item xs={4} />
      <Grid item xs={4}>
        {mynOberStore.merchant.takeaway ? <Typography style={{ fontWeight: 800 }}>Takeaway</Typography> : null}
      </Grid>
      <Grid item xs={4}>
        {mynOberStore.merchant.delivery ? <Typography style={{ fontWeight: 800 }}>Delivery</Typography> : null}
      </Grid>
      {days.map((day, index) => {
        return (
          <>
            <Grid item xs={4} container alignItems={"center"}>
              <Typography
                classes={{ root: props.disabled && classes.disabledTitle }}
                style={{ fontWeight: (getApplicationDate().getDay() + 6) % 7 === index ? 1000 : null }}
              >
                {props.intl.formatMessage({ id: day })}
              </Typography>
            </Grid>

            {mynOberStore.merchant.takeaway && (
              <Grid item xs={columnWidth} container alignItems={"flex-start"} style={{ flexDirection: "column" }}>
                {weekOpenRanges[day].length === 0 && (
                  <Typography>{props.intl.formatMessage({ id: "closed" })}</Typography>
                )}
                {weekOpenRanges[day].map((openRange) => {
                  if (openRange.isClosed()) {
                    return (
                      <Typography
                        classes={{ root: props.disabled && classes.disabledTitle }}
                        style={{
                          fontWeight: (getApplicationDate().getDay() + 6) % 7 === index ? 1000 : null,
                          letterSpacing: (getApplicationDate().getDay() + 6) % 7 === index ? "-0.03rem" : null,
                        }}
                      >
                        {props.intl.formatMessage({ id: "closed" })}
                      </Typography>
                    );
                  } else {
                    return (
                      <div>
                        <Typography
                          classes={{ root: props.disabled && classes.disabledTitle }}
                          style={{
                            fontWeight: (getApplicationDate().getDay() + 6) % 7 === index ? 1000 : null,
                            letterSpacing: (getApplicationDate().getDay() + 6) % 7 === index ? "-0.03rem" : null,
                          }}
                        >
                          {openRange.openingHour}
                          {" - "}
                          {openRange.closingHour}
                        </Typography>
                      </div>
                    );
                  }
                })}
              </Grid>
            )}

            {mynOberStore.merchant.delivery && (
              <Grid item xs={columnWidth} container alignItems={"flex-start"} style={{ flexDirection: "column" }}>
                {deliveryWeekOpenRanges[day].length === 0 && (
                  <Typography>{props.intl.formatMessage({ id: "closed" })}</Typography>
                )}
                {deliveryWeekOpenRanges[day].map((openRange) => {
                  if (openRange.isClosed()) {
                    return (
                      <Typography
                        classes={{ root: props.disabled && classes.disabledTitle }}
                        style={{
                          fontWeight: (getApplicationDate().getDay() + 6) % 7 === index ? 1000 : null,
                          letterSpacing: (getApplicationDate().getDay() + 6) % 7 === index ? "-0.03rem" : null,
                        }}
                      >
                        {props.intl.formatMessage({ id: "closed" })}
                      </Typography>
                    );
                  } else {
                    return (
                      <div>
                        <Typography
                          classes={{ root: props.disabled && classes.disabledTitle }}
                          style={{
                            fontWeight: (getApplicationDate().getDay() + 6) % 7 === index ? 1000 : null,
                            letterSpacing: (getApplicationDate().getDay() + 6) % 7 === index ? "-0.03rem" : null,
                          }}
                        >
                          {openRange.openingHour}
                          {" - "}
                          {openRange.closingHour}
                        </Typography>
                      </div>
                    );
                  }
                })}
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
}

OpeningHoursTakeawayInfoScreen.propTypes = {
  takeawayOpeningHours: PropTypes.instanceOf(WeekHours).isRequired,
  deliveryOpeningHours: PropTypes.instanceOf(WeekHours).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default withIntl(OpeningHoursTakeawayInfoScreen);
