import React from "react";
import { injectIntl } from "react-intl";
import icon from "./../../../../assets/images/bottombar/ic-service.svg";
import iconActive from "./../../../../assets/images/bottombar/ic-service-active.svg";
import BottomTab from "./BottomTab";

function ServiceBottomTab(props) {
  return (
    <BottomTab
      icon={icon}
      activeIcon={iconActive}
      onClick={() => props.onClick()}
      selected={props.selected}
      badge={null}
      title={props.intl.formatMessage({ id: "tab_service" })}
    />
  );
}

export default injectIntl(ServiceBottomTab);
