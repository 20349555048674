import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ListArticlegroups.css";
import mynOberStore from "../../utils/flux/MynOberStore";

class ListArticlegroup extends Component {
  render() {
    const element = document.getElementById("menu-container");
    const element2 = document.getElementById("sticky-header-menu-bar");
    let minHeight = null;
    if (element && element2 && !mynOberStore.isHandheld()) {
      minHeight = element.getBoundingClientRect().height - element2.getBoundingClientRect().height;
    }
    return (
      <div className="list-articlegroups" id={"list-articlegroups"} style={{ minHeight: minHeight, width: "100%" }}>
        {this.props.articlegroup}
      </div>
    );
  }
}

ListArticlegroup.propTypes = {
  articlegroup: PropTypes.element.isRequired,
};

export default ListArticlegroup;
