import React, { Component } from "react";
import { matchPath, Route, Switch, withRouter } from "react-router";
import HandheldContainer from "../../containers/handheld/smartphone/HandheldContainer";
import TableContainer from "../../containers/handheld/smartphone/TableContainer";
import ActivateContainer from "../../containers/handheld/smartphone/ActivateContainer";
import "./HandheldRouter.css";
import taHandheldStore from "./../../utils/flux/TaHandheldStore";
import TaHandheldContainer from "../../containers/handheld/takeaway/TaHandheldContainer";
import handheldStore from "../../utils/flux/HandheldStore";
import mynOberStore from "../../utils/flux/MynOberStore";
import { getDrawerButtons } from "../../components/floorplan/FloorPlan";
import withIntl from "../../utils/withIntl";
import { ButtonBase, withTheme } from "@material-ui/core";
import { uuidv4 } from "../../components/ReactCodeInput";
import TaReceiptHistoryContainer from "../../containers/handheld/smartphone/TaReceiptHistoryContainer";
import { PathNames } from "../PathNames";
import { FormatListNumbered, History, Payment } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogSelectMenuTag from "../../components/dialogs/DialogSelectMenuTag";

export const HandheldPathNames = Object.freeze({
  TABLE: "/table",
  FLOORPLAN: "/floorplan",
  HISTORY: "/history",
  TABLEOVERVIEW: "/overview",
});

class HandheldRouter extends Component {
  constructor(props) {
    super(props);
    window.handheldRouter = this;
  }

  render() {
    let bottomMenuButtons = [];

    Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
      let button = {
        stringId: "Direct Sale Mode",
        subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
        icon: <Payment />,
        key: "direct_sale_mode-" + spMerchantId,
        onClick: () => {
          if (
            this.props.location.pathname.match(`\/${spMerchantId}\/table`) &&
            this.props.location.search.match(`directsalemode=1`)
          ) {
            const [openDialog] = this.context;
            openDialog({
              children: (
                <DialogSelectMenuTag
                  onOkClicked={(menuTag) => {
                    if (menuTag) {
                      const menus = { covers: 0, activeMArticleIds: {}, menuTags: [menuTag] };
                      mynOberStore.forceSetMenus(menus);
                    } else {
                      const menus = { covers: 0, activeMArticleIds: {}, menuTags: null };
                      mynOberStore.forceSetMenus(menus);
                    }
                  }}
                />
              ),
            });
          } else {
            if (this.props.history.action === "PUSH") {
              this.props.history.replace(
                `/${spMerchantId}${HandheldPathNames.TABLE}/SALE-` +
                  uuidv4().substr(0, 4) +
                  `${PathNames.ORDERPAGE}?directsalemode=1`
              );
            } else {
              this.props.history.push(
                `/${spMerchantId}${HandheldPathNames.TABLE}/SALE-` +
                  uuidv4().substr(0, 4) +
                  `${PathNames.ORDERPAGE}?directsalemode=1`
              );
            }
          }
        },
      };
      bottomMenuButtons.push(button);
    });

    Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
      let button = {
        stringId: "table_overview",
        subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
        icon: <FormatListNumbered />,
        key: "table_overview-" + spMerchantId,
        onClick: () => {
          this.props.history.replace("/" + spMerchantId + HandheldPathNames.TABLEOVERVIEW);
        },
      };
      bottomMenuButtons.push(button);
    });

    if (mynOberStore.merchant != null) {
      bottomMenuButtons = bottomMenuButtons.concat(getDrawerButtons(this.props.history));
    }
    if (Object.keys(handheldStore.spMerchants).length > 0) {
      let button = {
        stringId: "bill_history",
        key: "bill_history",
        icon: <History />,
        onClick: () => {
          this.props.history.replace("/history");
        },
      };
      bottomMenuButtons.push(button);
    }

    let match = matchPath(this.props.location.pathname, { path: "/:spMerchantId" });
    if (
      (!match || (isNaN(match.params.spMerchantId) && match.params.spMerchantId !== "history")) &&
      Object.keys(handheldStore.spMerchants).length > 0
    ) {
      Object.keys(handheldStore.spMerchants).some((spMerchantId) => {
        this.props.history.replace("/" + spMerchantId);
        return true;
      });

      return <div></div>;
    }

    return (
      <div className={"handheld-router-root"} style={{ height: "100%" }}>
        <div style={{ display: "flex", height: 0, width: "100%", flex: "1 1 0%" }}>
          <div
            className={"handheld-router-sp"}
            style={{ position: Object.keys(handheldStore.spMerchants).length === 0 ? "absolute" : null }}
          >
            <Switch>
              <Route
                path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId/activate`}
                render={(props) => <ActivateContainer {...props} />}
              />
              <Route
                path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId`}
                render={(props) => <TableContainer {...props} key={props.match.params.tableId} />}
              />
              <Route render={(props) => <HandheldContainer {...props} />} />
            </Switch>
          </div>

          {Object.keys(taHandheldStore.taMerchants).length > 0 &&
          !matchPath(this.props.location.pathname, { path: "/:spMerchantId/floorplan" }) &&
          !this.props.location.pathname.includes(HandheldPathNames.TABLE) ? (
            <div
              className={"handheld-router-ta"}
              style={{
                borderLeft: Object.keys(handheldStore.spMerchants).length > 0 ? "1px solid black" : "",
                overflowY: "auto",
              }}
            >
              {!matchPath(this.props.location.pathname, { path: "/history" }) && <TaHandheldContainer />}
              {matchPath(this.props.location.pathname, { path: "/history" }) && <TaReceiptHistoryContainer />}
            </div>
          ) : null}
        </div>
        {mynOberStore.isWidescreen && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              backgroundColor: this.props.theme.palette.background.default,
              zIndex: 5,
              fontSize: "0.8em",
              height: 64,
              overflowY: "hidden",
              overflowX: "auto",
            }}
          >
            {bottomMenuButtons.map((button) => {
              return (
                <ButtonBase
                  className={"MuiButton-outlined"}
                  component={"div"}
                  style={{
                    height: 64,
                    fontSize: "1.8em",
                    padding: "0 16px",
                    flexDirection: "column",
                    borderRadius: 4,
                  }}
                  onClick={button.onClick}
                  startIcon={button.icon}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {button.icon}
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: 4 }}>
                      <Typography style={{ fontWeight: 800 }}>
                        {this.props.intl.formatMessage({ id: button.stringId })}
                      </Typography>
                      {button.subtitle && (
                        <Typography style={{ fontSize: "0.7em" }}>
                          {this.props.intl.formatMessage({ id: button.subtitle })}
                        </Typography>
                      )}
                    </div>
                  </div>
                </ButtonBase>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

HandheldRouter.contextType = DialogContext;

export default withTheme(withIntl(withRouter(HandheldRouter)));
