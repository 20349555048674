import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogOk(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const [isOpen, setIsOpen] = React.useState(true);

  let timer = null;
  if (props.timer && isOpen) {
    timer = setTimeout(() => {
      closeDialog();
      if (props.dialogClosed) {
        props.dialogClosed();
      }
    }, 5000);
  }

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        {props.content != null ? <h3>{props.intl.formatMessage({ id: props.content })}</h3> : null}
        {props.rawContent != null ? <h3>{props.rawContent}</h3> : null}
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              if (timer) {
                clearTimeout(timer);
                setIsOpen(false);
              }
              closeDialog();
              if (props.onOkClicked) {
                props.onOkClicked();
              }
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogOk);
