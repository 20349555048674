import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import mynOberStore from "../../utils/flux/MynOberStore";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import DialogSuccess from "./DialogSuccess";
import DialogError from "./DialogError";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogServiceArticleRequest(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const serviceArticle = props.serviceArticle;
  return (
    <>
      <DialogTitle>
        <h3>{props.intl.formatMessage({ id: "alert_text_sure_to_send_the_following" })}</h3>
      </DialogTitle>
      <DialogContent>
        <h2 style={{ textAlign: "center" }}>{serviceArticle.name}</h2>
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              requestFunctions
                .sendOrderServiceRequest(mynOberStore.merchant, serviceArticle)
                .then(() => {
                  openDialog({
                    children: <DialogSuccess content={"alert_text_success_order"} />,
                  });
                })
                .catch((text) => {
                  openDialog({
                    children: <DialogError content={text} />,
                  });
                });
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogServiceArticleRequest);
