import React, { Component } from "react";
import { PathNames } from "../../routers/PathNames";
import BottomTabs from "../../components/web/BottomTabs";
import * as listeners from "../../utils/Listeners";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { SpMerchant } from "../../utils/merchant/SpMerchant";
import SmallScreenRouter from "../../routers/SmallScreenRouter";
import WideScreenRouter from "../../routers/WideScreenRouter";
import "./TableOrderContainer.css";
import { matchPath, withRouter } from "react-router";
import TopNavigation from "../../components/web/TopNavigation";
import DrawerMenuApp from "../../pages/DrawerMenuApp";
import { ReactComponent as IconOptions } from "../../../assets/images/ic-menu.svg";
import BottomGradient from "../../components/web/BottomGradient";
import { TaMerchant } from "../../utils/merchant/TaMerchant";
import { AyceMerchant } from "../../utils/merchant/AyceMerchant";
import mynOberStore from "../../utils/flux/MynOberStore";
import handheldStore from "../../utils/flux/HandheldStore";
import { injectIntl } from "react-intl";
import { getValue } from "../../utils/functions/HelperFunctions";
import MenuBottomTab from "../../components/app/BottomBar/MenuBottomTab";
import ReceiptBottomTab from "../../components/app/BottomBar/ReceiptBottomTab";
import OrderBottomTab from "../../components/app/BottomBar/OrderBottomTab";
import ServiceBottomTab from "../../components/app/BottomBar/ServiceBottomTab";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogDeliveryOrTakeaway, {
  takeawayOrDeliverySelected,
} from "../../components/dialogs/DialogDeliveryOrTakeaway";
import DialogOk from "../../components/dialogs/DialogOk";
import { openDrawer } from "../../components/drawer_menu/DrawerMenu";
import InfoMessageForOrdering from "../../components/InfoMessageForOrdering";
import DialogOrderArticlesRemoved from "../../components/dialogs/DialogOrderArticlesRemoved";
import DialogSendSharedOrder from "../../components/dialogs/DialogSendSharedOrder";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import DialogError from "../../components/dialogs/DialogError";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import OrdersBottomTab from "../../components/app/BottomBar/OrdersBottomTab";
import DialogWelcome from "../../components/dialogs/DialogWelcome";
import GetCurrency from "../../utils/functions/GetCurrency";
import DialogOrdergroupFailed, { failedOrdergroupsOpened } from "../../components/dialogs/DialogOrdergroupFailed";
import DialogPincode from "../../components/dialogs/DialogPincode";
import { localStore } from "../../utils/local_storage/LocalStorage";
import DialogProcessingPinTransaction from "../../components/dialogs/DialogProcessingPinTransaction";
import DialogClosedInfo from "../../components/dialogs/DialogClosedInfo";

export const stickyHeaderId = "sticky-header-menu-bar";

class TableOrderContainer extends Component {
  constructor(props) {
    super(props);

    // let firstTimeOrder = localStorage.getItem("firstTimeOrder");

    this.state = {
      countInOrder: mynOberStore.order.count,
      receiptAtCurrentMerchant: mynOberStore.hasReceiptAtCurrentMerchant(),
      isWidescreen: mynOberStore.isWidescreen,
      isTooltipOpen: false,
      merchant: mynOberStore.merchant,
      snackbarOpen: false,
      firstTimeOrder: true,
    };

    window.tableOrderContainer = this;
  }

  onOrderSendSuccessful = (byUser) => {
    // if (byUser) {
    this.setState({ firstTimeOrder: true, snackbarOpen: false });
    // }
  };

  componentDidMount() {
    listeners.registerOrderChangedListeners(this.onOrderChanged);
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, this.onOrderChangedFromSharedOrders);
    listeners.registerWideScreenListeners(this.onWideScreenChanged);
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, this.updateMerchant);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, this.receiptCreated);
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.receiptCreated);
    handheldStore.on(handheldStore.events.RECEIPT_CLOSED, this.receiptCreated);
    mynOberStore.on(mynOberStore.events.MAX_ARTICLES_PER_ORDER, this.showAlertMaxArticlesPerOrderReached);
    mynOberStore.on(mynOberStore.events.MAX_ARTICLES_PER_CATEGORY, this.showAlertMaxArticlesPerCategoryReached);
    mynOberStore.on(
      mynOberStore.events.MAX_TOTAL_ARTICLES_PER_CATEGORY,
      this.showAlertMaxTotalArticlesPerCategoryReached
    );
    mynOberStore.on(mynOberStore.events.MAX_ROUND_PER_CATEGORY, this.showAlertMaxRoundsPerCategoryReached);
    mynOberStore.on(
      mynOberStore.events.MINIMAL_AMOUNT_PRODUCTS_EXCEED_MINIMAL_AMOUNT,
      this.showAlertMinimalAmountExceedsOrder
    );
    mynOberStore.on(
      mynOberStore.events.MAX_DIFFERENT_ARTICLES_PER_ORDER,
      this.showAlertMaxDifferentArticlesPerOrderReached
    );
    mynOberStore.on(mynOberStore.events.LATEST_ORDER_FAILED, this.latestOrderFailedDialog);
    if (!mynOberStore.isHandheld()) {
      mynOberStore.on(mynOberStore.events.WRONG_QR_PINCODE, this.wrongPincodeDialog);
    }
    mynOberStore.on(mynOberStore.events.TIMER_30, this.showTimerWarning);
    mynOberStore.on(mynOberStore.events.TIMER_15, this.showTimerWarning);
    mynOberStore.on(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendSuccessful);
    mynOberStore.on(mynOberStore.events.ORDER_ARTICLES_REMOVED_AUTOMATICALLY, this.showMessageOrderArticlesRemoved);
    mynOberStore.on(mynOberStore.events.SHARED_ORDER_UUID_UPDATED, this.showSharedOrderSendDialog);
    mynOberStore.on(mynOberStore.events.RECEIPT_CLEARED, this.onReceiptCleared);

    if (!mynOberStore.isOrderingPossible()) {
      if (mynOberStore.hasReceiptElsewhere()) {
        const [openDialog] = this.context;
        openDialog({ children: <DialogOk title={"alert_title_failed"} content={"alert_text_receipt_elsewhere"} /> });
      } else {
        let [createDialog] = this.context;
        if (mynOberStore.merchant.info?.closed_message) {
          createDialog({
            children: <DialogClosedInfo />,
          });
        } else {
          createDialog({
            children: <DialogOk title={"alert_title_ordering_possible"} content={"alert_text_ordering_possible"} />,
          });
        }
        return;
      }
    }

    if (mynOberStore.merchant instanceof TaMerchant) {
      let [openDialog, closeDialog] = this.context;
      const ordertype = new URLSearchParams(this.props.location.search).get("ordertype");
      if (ordertype) {
        takeawayOrDeliverySelected(ordertype, openDialog, closeDialog);
      }
      if (
        !ordertype &&
        mynOberStore.merchant.delivery === true &&
        mynOberStore.merchant.takeaway === true &&
        (window.location.pathname === PathNames.MENUPAGE ||
          (window.location.pathname === PathNames.ORDERPAGE && mynOberStore.isWidescreen))
      ) {
        openDialog(
          {
            children: <DialogDeliveryOrTakeaway isOpen={mynOberStore.merchant.isOpen} ordertype={ordertype} />,
          },
          true
        );
      }
    }

    if (
      !mynOberStore.isHandheld() &&
      (mynOberStore.merchant instanceof SpMerchant ||
        mynOberStore.merchant.delivery ^ mynOberStore.merchant.takeaway) &&
      (window.location.pathname === PathNames.MENUPAGE ||
        (window.location.pathname === PathNames.ORDERPAGE && mynOberStore.isWidescreen))
    ) {
      if (mynOberStore.merchant?.info?.welcome_message) {
        let [openDialog] = this.context;
        openDialog({ children: <DialogWelcome />, fullWidth: true });
      }
    }

    if (mynOberStore.sharedOrderUuid) {
      this.showSharedOrderSendDialog();
    }

    if (mynOberStore.merchant instanceof SpMerchant) {
      if (!mynOberStore.isHandheld()) {
        requestFunctions.getReceipt(false, null);
      } else if (mynOberStore.isHandheld() && !handheldStore.isInDirectSaleMode) {
        let handheldReceipt = null;
        const tableId = this.props.match.params.tableId;
        if (
          handheldStore.tables[this.props.match.params["spMerchantId"]][tableId] &&
          handheldStore.tables[this.props.match.params["spMerchantId"]][tableId].receiptId > 0
        ) {
          handheldReceipt =
            handheldStore.handheldReceipts[
              handheldStore.tables[this.props.match.params["spMerchantId"]][tableId].receiptId
            ];
        }

        if (handheldReceipt) {
          mynOberAPIClientManager.getHandheldReceipt(handheldReceipt.id, handheldReceipt.spMerchantId);
        }
      }

      if (
        !mynOberStore.isHandheld() &&
        mynOberStore.merchant.usePincode &&
        !localStore.getItem("pincode") &&
        !mynOberStore.dialogPincodeIsOpen
      ) {
        mynOberStore.isInvalidPincode = true;
        let [openDialog] = this.context;
        mynOberStore.dialogPincodeIsOpen = true;
        openDialog({
          children: <DialogPincode title={"Please enter PIN"} onPasswordOk={(pincode) => {}} />,
          disableBackdropClick: true,
        });
      }
    }
  }

  componentWillUnmount() {
    listeners.unregisterOrderChangedListeners(this.onOrderChanged);
    mynOberStore.removeListener(
      mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS,
      this.onOrderChangedFromSharedOrders
    );
    listeners.unregisterWideScreenListeners(this.onWideScreenChanged);
    mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, this.updateMerchant);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, this.receiptCreated);
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.receiptCreated);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CLOSED, this.receiptCreated);
    mynOberStore.removeListener(mynOberStore.events.MAX_ARTICLES_PER_ORDER, this.showAlertMaxArticlesPerOrderReached);
    mynOberStore.removeListener(
      mynOberStore.events.MAX_ARTICLES_PER_CATEGORY,
      this.showAlertMaxArticlesPerCategoryReached
    );
    mynOberStore.removeListener(
      mynOberStore.events.MAX_TOTAL_ARTICLES_PER_CATEGORY,
      this.showAlertMaxTotalArticlesPerCategoryReached
    );
    mynOberStore.removeListener(mynOberStore.events.MAX_ROUND_PER_CATEGORY, this.showAlertMaxRoundsPerCategoryReached);
    mynOberStore.removeListener(
      mynOberStore.events.MINIMAL_AMOUNT_PRODUCTS_EXCEED_MINIMAL_AMOUNT,
      this.showAlertMinimalAmountExceedsOrder
    );
    mynOberStore.removeListener(
      mynOberStore.events.MAX_DIFFERENT_ARTICLES_PER_ORDER,
      this.showAlertMaxDifferentArticlesPerOrderReached
    );
    mynOberStore.removeListener(mynOberStore.events.LATEST_ORDER_FAILED, this.latestOrderFailedDialog);
    mynOberStore.removeListener(mynOberStore.events.LATEST_ORDER_FAILED, this.wrongPincodeDialog);
    mynOberStore.removeListener(mynOberStore.events.TIMER_30, this.showTimerWarning);
    mynOberStore.removeListener(mynOberStore.events.TIMER_15, this.showTimerWarning);
    mynOberStore.removeListener(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendSuccessful);
    mynOberStore.removeListener(
      mynOberStore.events.ORDER_ARTICLES_REMOVED_AUTOMATICALLY,
      this.showMessageOrderArticlesRemoved
    );
    mynOberStore.removeListener(mynOberStore.events.SHARED_ORDER_UUID_UPDATED, this.showSharedOrderSendDialog);
    mynOberStore.removeListener(mynOberStore.events.RECEIPT_CLEARED, this.onReceiptCleared);

    if (mynOberStore.merchant instanceof AyceMerchant && !mynOberStore.isHandheld()) {
      mynOberActions.clearMerchantUpdateTimer();
    }
  }

  onReceiptCleared = () => {
    const [openDialog] = this.context;
    if (!mynOberStore.isHandheld()) {
      openDialog(
        {
          children: (
            <DialogOk
              title={"alert_title_receipt_cleared"}
              content={"alert_text_receipt_cleared"}
              onOkClicked={() => {
                if (!mynOberStore.isHandheld()) {
                  this.props.history.replace("/");
                }
              }}
            />
          ),
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
        },
        true
      );
    }
  };

  updateMerchant = () => {
    if (this.state.merchant instanceof AyceMerchant) {
      let merchant = Object.assign(new AyceMerchant(), mynOberStore.merchant);
      this.setState({ merchant: merchant });
    } else if (this.state.merchant instanceof SpMerchant) {
      let merchant = Object.assign(new SpMerchant(), mynOberStore.merchant);
      this.setState({ merchant: merchant });
    } else if (this.state.merchant instanceof TaMerchant) {
      let merchant = Object.assign(new TaMerchant(), mynOberStore.merchant);
      this.setState({ merchant: merchant });
    } else {
      this.setState({ merchant: mynOberStore.merchant });
    }
  };

  latestOrderFailedDialog = (ordergroup) => {
    if (!mynOberStore.failedOrderDialogIsOpen && !failedOrdergroupsOpened[ordergroup.ordergroupId]) {
      const [openDialog] = this.context;
      openDialog({
        children: <DialogOrdergroupFailed handheldOrdergroup={ordergroup} />,
        fullWidth: true,
        maxWidth: "md",
        disableBackdropClick: true,
      });
    }
  };

  wrongPincodeDialog = () => {
    if (!mynOberStore.dialogPincodeIsOpen) {
      let [openDialog] = this.context;
      mynOberStore.dialogPincodeIsOpen = true;
      mynOberStore.isInvalidPincode = true;
      openDialog({
        children: <DialogPincode title={"Please enter PIN"} onPasswordOk={(pincode) => {}} />,
        disableBackdropClick: true,
      });
    }
  };

  showTimerWarning = (eventName) => {
    let timeUnit = "";
    if (eventName === mynOberStore.events.TIMER_15) {
      timeUnit = "one_quarter";
    } else if (eventName === mynOberStore.events.TIMER_30) {
      timeUnit = "half_an_hour";
    }

    const [openDialog] = this.context;
    openDialog({
      children: <DialogOk title={"alert_title_warning"} content={"you_have_" + timeUnit + "_left_to_order"} />,
    });
  };

  showAlertMaxArticlesPerOrderReached = () => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "you_can_order_max_articles_at_once",
            },
            {
              count: mynOberStore.merchant.maxWeight,
            }
          )}
        />
      ),
    });
  };

  showAlertMaxArticlesPerCategoryReached = ({ maxCount, category }) => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "you_can_order_max_articles_per_category_at_once",
            },
            {
              maxCount: maxCount,
              category: category,
            }
          )}
        />
      ),
    });
  };

  showAlertMaxTotalArticlesPerCategoryReached = ({ maxCount, category }) => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "you_can_order_max_articles_per_category",
            },
            {
              maxCount: maxCount,
              category: category,
            }
          )}
        />
      ),
    });
  };

  showAlertMaxRoundsPerCategoryReached = ({ maxCount, category }) => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "you_can_order_max_rounds_per_category",
            },
            {
              maxCount: maxCount,
              category: category,
            }
          )}
        />
      ),
    });
  };

  showAlertMinimalAmountExceedsOrder = ({ orderArticle }) => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "remove_minimal_amount_order",
            },
            { min_amount: GetCurrency() + " " + (orderArticle.min_amount / 100).toFixed(2), name: orderArticle.name }
          )}
        />
      ),
    });
  };

  showAlertMaxDifferentArticlesPerOrderReached = () => {
    const [openDialog] = this.context;
    openDialog({
      children: (
        <DialogOk
          title={"alert_title_warning"}
          rawContent={this.props.intl.formatMessage(
            {
              id: "you_can_order_max_different_articles_at_once",
            },
            {
              count: mynOberStore.merchant.ayceSettings.maxDifferentPlus,
            }
          )}
        />
      ),
    });
  };

  showMessageOrderArticlesRemoved = (orderArticles) => {
    const [openDialog] = this.context;
    openDialog({
      children: <DialogOrderArticlesRemoved title={"alert_title_warning"} orderArticles={orderArticles} />,
    });
  };

  showSharedOrderSendDialog = (isFromWebsocket) => {
    if (
      !mynOberAPIClientManager.orderSending &&
      mynOberStore.sharedOrderUuid != null &&
      mynOberStore.transactionUUIDs.length > 0
    ) {
      const [openDialog] = this.context;
      openDialog({
        children: (
          <DialogSendSharedOrder
            onCanceled={() => {
              requestFunctions
                .cancelSharedOrder()
                .then((resolved) => {})
                .catch((error) => {
                  openDialog({ children: <DialogError /> });
                });
            }}
            onSend={() => {}}
          />
        ),
        disableBackdropClick: true,
      });
    }
  };

  receiptCreated = () => {
    this.forceUpdate();
  };

  createBottomTabItems = () => {
    let bottomTabItems = [];

    let isDirectSaleMode = mynOberStore.isHandheld() && handheldStore.isInDirectSaleMode;
    if (
      !mynOberStore.isWidescreen &&
      this.props.location.pathname !== PathNames.TAKEAWAYPAGE &&
      this.props.location.pathname !== PathNames.DELIVERYPAGE
    ) {
      bottomTabItems.push(
        <MenuBottomTab
          key={"MENUBOTTOMTAB"}
          selected={this.props.location.pathname.match(`${PathNames.MENUPAGE}$`) != null}
          onClick={() => this.onClickBottomTab(PathNames.MENUPAGE)}
        />
      );
    }
    if (
      ((mynOberStore.merchant instanceof SpMerchant &&
        mynOberStore.merchant.mustBuildupReceipt === 1 &&
        mynOberStore.receipt != null) ||
        mynOberStore.isHandheld()) &&
      !isDirectSaleMode
    ) {
      bottomTabItems.push(
        <ReceiptBottomTab
          key={"RECEIPTBOTTOMTAB"}
          selected={this.props.location.pathname.match(`${PathNames.RECEIPTPAGE}$`) != null}
          onClick={() => this.onClickBottomTab(PathNames.RECEIPTPAGE)}
        />
      );
    }
    if (mynOberStore.isHandheld() && !isDirectSaleMode) {
      bottomTabItems.push(
        <OrdersBottomTab
          key={"ORDERSBOTTOMTAB"}
          selected={this.props.location.pathname.match(`${PathNames.ORDERSPAGE}$`) != null}
          onClick={() => this.onClickBottomTab(PathNames.ORDERSPAGE)}
        />
      );
    }
    if (
      this.props.location.pathname !== PathNames.TAKEAWAYPAGE &&
      this.props.location.pathname !== PathNames.DELIVERYPAGE
    ) {
      bottomTabItems.push(
        <OrderBottomTab
          key={"ORDERBOTTOMTAB"}
          selected={this.props.location.pathname.match(`${PathNames.ORDERPAGE}$`) != null}
          onClick={() => this.onClickBottomTab(PathNames.ORDERPAGE)}
          isTooltipOpen={this.state.isTooltipOpen}
        />
      );
    }
    if (!mynOberStore.isHandheld() && mynOberStore.merchant instanceof SpMerchant) {
      bottomTabItems.push(<ServiceBottomTab key={"SERVICEBOTTOMTAB"} onClick={openDrawer} />);
    }
    return bottomTabItems;
  };

  onClickBottomTab = (pathname) => {
    if (this.props.location.pathname !== pathname) {
      if (mynOberStore.isHandheld()) {
        // && false) {
        this.props.history.replace(this.props.match.url + pathname);
      } else {
        this.props.history.replace(pathname);
      }
    }
  };

  onOrderChanged = () => {
    let snackbarOpen = this.state.snackbarOpen;
    let isTooltipOpen = this.state.isTooltipOpen;

    if (mynOberStore.order.count > 0) {
      snackbarOpen = true;
    }
    if (this.state.countInOrder < mynOberStore.order.count) {
      if (!mynOberStore.isWidescreen) {
        isTooltipOpen = true;
      }

      if (this.tooltipTimer != null) {
        clearTimeout(this.tooltipTimer);
      }

      if (!mynOberStore.isWidescreen) {
        this.tooltipTimer = setTimeout(() => {
          this.tooltipTimer = null;
          this.setState({ isTooltipOpen: false });
        }, 2000);
      }
    }
    this.setState({ countInOrder: mynOberStore.order.count, snackbarOpen, isTooltipOpen });
  };

  onOrderChangedFromSharedOrders = () => {
    if (this.state.countInOrder < mynOberStore.order.count) {
      this.setState({ isTooltipOpen: true });

      if (this.tooltipTimer != null) {
        clearTimeout(this.tooltipTimer);
      }

      this.tooltipTimer = setTimeout(() => {
        this.tooltipTimer = null;
        this.setState({ isTooltipOpen: false });
      }, 2000);
    }
    this.setState({ countInOrder: mynOberStore.order.count });
  };

  onWideScreenChanged = () => {
    let isWidescreen = mynOberStore.isWidescreen;

    if (this.state.isWidescreen !== isWidescreen) {
      if (this.state.isWidescreen && !isWidescreen) {
        switch (this.props.location.pathname) {
          case PathNames.RECEIPTPAGE:
          case PathNames.ORDERPAGE:
            this.props.history.replace(PathNames.MENUPAGE);
            break;
          default:
            break;
        }
      }

      this.setState({ isWidescreen: isWidescreen });
    }
  };

  render() {
    const bottomTabs = <BottomTabs bottomTabs={this.createBottomTabItems()} />;

    let pathname = this.props.location.pathname;

    let title = mynOberStore.merchant.name;

    if (mynOberStore.isHandheld()) {
      let match = matchPath(this.props.location.pathname, { path: "/:spMerchantId(\\d+)" });
      if (match && handheldStore.spMerchants[match.params.spMerchantId].salesAreaName != null) {
        title += " - " + handheldStore.spMerchants[match.params.spMerchantId].salesAreaName;
      }
    }

    return (
      <div className={"table-order-container-root"}>
        {mynOberStore.isHandheld() ||
        getValue(mynOberStore, "merchant.styling.iframe") === true ||
        !mynOberStore.merchant ? null : (
          <TopNavigation
            iconSrc={<IconOptions />}
            titleId={"button_options"}
            merchantName={mynOberStore.isHandheld() ? "Table " + mynOberStore.webcode : title}
            onClickOptions={openDrawer}
          />
        )}
        {mynOberStore.isHandheld() || !mynOberStore.merchant ? null : ( // && false
          <DrawerMenuApp store={mynOberStore} />
        )}
        <div id={"table-order-container"} className={"table-order-container"}>
          {this.state.isWidescreen && mynOberStore.merchant && mynOberStore.merchant.isOrdering ? (
            <WideScreenRouter
              hasReceiptAtCurrentMerchant={this.state.receiptAtCurrentMerchant}
              BottomTabs={bottomTabs}
            />
          ) : (
            <SmallScreenRouter />
          )}
        </div>
        <div>
          {/*{!mynOberStore.isHandheld() && mynOberStore.order.count > 0 && !this.state.isWidescreen && this.props.location.pathname === PathNames.MENUPAGE*/}
          {/*  ? <Button*/}
          {/*    style={{ width: '100%', height: '48px' }}*/}
          {/*    variant={"contained"}*/}
          {/*    color={"primary"}*/}
          {/*    onClick={() => {*/}
          {/*      this.onClickBottomTab(PathNames.ORDERPAGE)*/}
          {/*    }}>*/}
          {/*    <Badge*/}
          {/*      color={"secondary"}*/}
          {/*      badgeContent={mynOberStore.order.count}>Winkelmandje</Badge></Button> : null}*/}
        </div>
        {this.props.location.pathname === PathNames.MENUPAGE &&
          !mynOberStore.isHandheld() &&
          this.state.firstTimeOrder && (
            <InfoMessageForOrdering
              snackbarClickedaway={() => {
                this.setState({ firstTimeOrder: false });
              }}
              setOpen={() => {
                this.setState({ snackbarOpen: false });
              }}
              open={this.state.snackbarOpen}
            />
          )}
        {mynOberStore.isHandheld() && <DialogProcessingPinTransaction />}
        <BottomGradient
          isWidescreen={this.state.isWidescreen && !mynOberStore.isHandheld()}
          filter={this.state.filter}
          pathname={pathname}
        />
        {!this.state.isWidescreen && mynOberStore.merchant && mynOberStore.merchant.isOrdering && bottomTabs}
      </div>
    );
  }
}

TableOrderContainer.contextType = DialogContext;

export default injectIntl(withRouter(TableOrderContainer));
