import mynOberStore from "./flux/MynOberStore";

export function registerAppListener(onUserChanged) {
  mynOberStore.on(mynOberStore.events.USER_CHANGED, onUserChanged);
}

export function unregisterAppListener(onUserChanged) {
  mynOberStore.removeListener(mynOberStore.events.USER_CHANGED, onUserChanged);
}

export function registerHandheldLoginListeners(onHandheldLogin) {
  mynOberStore.on(mynOberStore.events.LOGIN_HANDHELD_SUCCESSFUL, onHandheldLogin);
}

export function unregisterHandheldLoginListeners(onHandheldLogin) {
  mynOberStore.removeListener(mynOberStore.events.LOGIN_HANDHELD_SUCCESSFUL, onHandheldLogin);
}

export function registerHandheldReceiptListeners(onReceiptCreated) {
  mynOberStore.on(mynOberStore.events.RECEIPT_CHANGED, onReceiptCreated);
  mynOberStore.on(mynOberStore.events.RECEIPT_CLEARED, onReceiptCreated);
}

export function unregisterHandheldReceiptListeners(onReceiptCreated) {
  mynOberStore.removeListener(mynOberStore.events.RECEIPT_CHANGED, onReceiptCreated);
  mynOberStore.removeListener(mynOberStore.events.RECEIPT_CLEARED, onReceiptCreated);
}

export function registerIndexListeners(startApp, login) {
  mynOberStore.on(mynOberStore.events.MENU_CREATED, startApp);
  mynOberStore.once(mynOberStore.events.USER_CHANGED, login);
}

export function unregisterIndexListeners(startApp, login) {
  mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, startApp);
  mynOberStore.removeListener(mynOberStore.events.USER_CHANGED, login);
}

export function registerLoadingListeners(onLoadingChanged) {
  mynOberStore.on(mynOberStore.events.IS_LOADING_CHANGED, onLoadingChanged);
}

export function unregisterLoadingListeners(onLoadingChanged) {
  mynOberStore.removeListener(mynOberStore.events.IS_LOADING_CHANGED, onLoadingChanged);
}

export function registerMerchantInfoPageListener(onOrderTypeSet) {
  mynOberStore.on(mynOberStore.events.ORDER_TYPE_CHANGED, onOrderTypeSet);
}

export function unregisterMerchantInfoPageListener(onOrderTypeSet) {
  mynOberStore.removeListener(mynOberStore.events.ORDER_TYPE_CHANGED, onOrderTypeSet);
}

export function registerMoveListeners(onMoveSuccessful) {
  mynOberStore.on(mynOberStore.events.WEBCODE_CHANGED, onMoveSuccessful);
}

export function unregisterMoveListeners(onMoveSuccessful) {
  mynOberStore.removeListener(mynOberStore.events.WEBCODE_CHANGED, onMoveSuccessful);
}

export function registerOrderChangedListeners(onOrderChanged) {
  mynOberStore.on(mynOberStore.events.ORDER_CHANGED, onOrderChanged);
}

export function unregisterOrderChangedListeners(onOrderChanged) {
  mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED, onOrderChanged);
}

export function registerPhoneNumberListeners(onPhoneNumberChanged) {
  mynOberStore.on(mynOberStore.events.PHONE_NUMBER_CHANGED, onPhoneNumberChanged);
}

export function unregisterPhoneNumberListeners(onPhoneNumberChanged) {
  mynOberStore.removeListener(mynOberStore.events.PHONE_NUMBER_CHANGED, onPhoneNumberChanged);
}

export function registerQrStartListeners(onMenuCreated) {
  mynOberStore.on(mynOberStore.events.MENU_CREATED, onMenuCreated);
}

export function unregisterQrStartListeners(onMenuCreated) {
  mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, onMenuCreated);
}

export function registerReceiptListeners(onReceiptChanged, onReceiptCleared) {
  mynOberStore.on(mynOberStore.events.RECEIPT_CHANGED, onReceiptChanged);
  mynOberStore.on(mynOberStore.events.RECEIPT_CLEARED, onReceiptCleared);
}

export function unregisterReceiptListeners(onReceiptChanged, onReceiptCleared) {
  mynOberStore.removeListener(mynOberStore.events.RECEIPT_CHANGED, onReceiptChanged);
  mynOberStore.removeListener(mynOberStore.events.RECEIPT_CLEARED, onReceiptCleared);
}

export function registerWideScreenListeners(onIsWidescreenChanged) {
  mynOberStore.on(mynOberStore.events.IS_WIDESCREEN_CHANGED, onIsWidescreenChanged);
}

export function unregisterWideScreenListeners(onIsWidescreenChanged) {
  mynOberStore.removeListener(mynOberStore.events.IS_WIDESCREEN_CHANGED, onIsWidescreenChanged);
}
