import React from "react";
import withIntl from "../../utils/withIntl";
import { Paper, Typography } from "@material-ui/core";
import "./ViewMenuArticle.css";
import ViewMenuArticleSimple from "./ViewMenuArticleSimple";
import { withTheme } from "@material-ui/core/styles";

function ViewMenuArticle(props) {
  return (
    <Paper
      className={"view-menu-article"}
      style={{
        height: "120px",
        display: "flex",
        flexDirection: "column",
        paddingTop: "24px",
        paddingBottom: "24px",
        cursor: props.onArticleClicked == null ? "auto" : "pointer",
        position: "relative",
      }}
      onClick={() => {
        if (props.onArticleClicked && !props.article.view_only) {
          props.onArticleClicked();
        }
      }}
    >
      <ViewMenuArticleSimple
        onClickAdd={(e) => {
          if (props.onClickAdd) {
            props.onClickAdd(e);
          }
        }}
        article={props.article}
        descriptionElement={
          <Typography
            color={"textSecondary"}
            style={{
              color: props.theme.article_description?.palette?.text?.secondary,
              fontFamily: props.theme.article_description?.typography?.fontFamily,
              fontSize: "14px",
              margin: "3px 0",
              width: "100%",
            }}
            noWrap={true}
          >
            {props.article.description}
          </Typography>
        }
      />
    </Paper>
  );
}

export default withTheme(withIntl(ViewMenuArticle));
