import React from "react";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import GetCurrency from "../../../utils/functions/GetCurrency";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";
import { injectIntl } from "react-intl";
import { parse } from "date-fns";
import TaReceipt from "../../../utils/receipt/TaReceipt";
import Button from "@material-ui/core/Button";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import {
  printBillTaReceipt,
  printTaReceipt,
  reopenSpReceipt,
  reopenTaReceipt,
} from "../../../utils/functions/RequestFunctions";
import DialogError from "../../dialogs/DialogError";
import DialogOk from "../../dialogs/DialogOk";
import { useDialog } from "../../dialogs/DialogProvider";
import mynOberStore from "../../../utils/flux/MynOberStore";
import DialogPasswordProtectedAction from "../../dialogs/DialogPasswordProtectedAction";
import { getApplicationDate } from "../../../utils/functions/HelperFunctions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {},
  column: {
    flexBasis: "20%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  hide: {
    [theme.breakpoints.down("sm")]: {
      visibility: "hidden",
    },
  },
  div: {
    borderLeft: "1px solid",
    paddingLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      borderLeft: "none",
      paddingLeft: 0,
    },
  },
}));

function HistoryTaReceiptView(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [openDialog] = useDialog();
  // const [passwordChecked, setPasswordChecked] = React.useState(false);

  // const [openDialog] = useDialog();

  // if (passwordChecked) {
  //   if (
  //     handheldStore.tables[props.taHandheldReceipt.spMerchantId]?.[props.taHandheldReceipt.tableId]?.receiptId == null
  //   ) {
  //     reopenSpReceipt(props.taHandheldReceipt.id).catch((text) => {
  //       openDialog({ children: <DialogError content={text} /> });
  //     });
  //   } else {
  //     openDialog({
  //       children: <DialogOk title={"alert_title_warning"} content={"table_busy"} />,
  //     });
  //   }
  //   setPasswordChecked(false);
  // }

  return (
    <div style={{ marginBottom: "10px" }}>
      <Accordion expanded={expanded} variant="outlined" onChange={() => setExpanded(!expanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1c-content" id="panel1c-header">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <Typography className={classes.heading} color="primary">
                T{props.taHandheldReceipt.number}
                {props.taHandheldReceipt.tableId &&
                  typeof props.taHandheldReceipt.tableId === "string" &&
                  props.taHandheldReceipt.tableId.length > 0 &&
                  " - " + props.taHandheldReceipt.tableId}
              </Typography>
              <Typography className={classes.heading} color={"primary"}>
                {props.taHandheldReceipt.receiptType}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className={classes.heading}>
                {parse(props.taHandheldReceipt.updatedAt + "Z", "yyyy-MM-dd HH:mm:ssX", getApplicationDate()).toLocaleString()}
              </Typography>
              <Typography className={clsx(classes.secondaryHeading)}>
                {GetCurrency("€")}{" "}
                {(props.taHandheldReceipt.getAmount() / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                className={classes.secondaryHeading}
                style={{ color: props.taHandheldReceipt.status === "FINISHED" ? "grey" : "red" }}
              >
                {props.taHandheldReceipt.status}
              </Typography>
              <Typography
                className={classes.secondaryHeading}
                style={{ color: props.taHandheldReceipt.status === "FINISHED" ? "grey" : "red" }}
              >
                {props.taHandheldReceipt.paymentMethod}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.hide}>
              <Typography className={clsx(classes.secondaryHeading)}>
                {props.taHandheldReceipt?.contactInformation?.firstName}{" "}
                {props.taHandheldReceipt?.contactInformation?.lastName}{" "}
                {props.taHandheldReceipt?.contactInformation?.name}
              </Typography>
              <Typography className={clsx(classes.secondaryHeading)}>
                {props.taHandheldReceipt?.contactInformation?.address}
              </Typography>
              <Typography className={clsx(classes.secondaryHeading)}>
                {props.taHandheldReceipt?.contactInformation?.city}
              </Typography>
            </Grid>
            <Grid item xs={1} className={classes.hide}>
              <Typography className={clsx(classes.secondaryHeading)}>
                {props.taHandheldReceipt?.contactInformation?.phone}
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails className={classes.details}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {taHandheldStore.taMerchants[props.taHandheldReceipt.taMerchantId].isMynbonnenMerchant && (
                <Button
                  style={{ margin: 5 }}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    printTaReceipt(props.taHandheldReceipt.id)
                      .then(() => {
                        openDialog({
                          children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} />,
                        });
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                >
                  {props.intl.formatMessage({ id: "werkbonnen printen" })}
                </Button>
              )}
              {taHandheldStore.taMerchants[props.taHandheldReceipt.taMerchantId].isMynbonnenMerchant && (
                <Button
                  style={{ margin: 5 }}
                  variant={"outlined"}
                  color={"primary"}
                  onClick={() => {
                    printBillTaReceipt(props.taHandheldReceipt.id)
                      .then(() => {
                        openDialog({
                          children: <DialogOk title={"alert_title_success"} content={"alert_title_success"} />,
                        });
                      })
                      .catch((text) => {
                        openDialog({ children: <DialogError content={text} /> });
                      });
                  }}
                >
                  {props.intl.formatMessage({ id: "rekening printen" })}
                </Button>
              )}
              {taHandheldStore.taMerchants[props.taHandheldReceipt.taMerchantId].isMynbonnenMerchant &&
                props.taHandheldReceipt.status === "FINISHED" &&
                !props.taHandheldReceipt.isPaid && (
                  <Button
                    style={{ margin: 5 }}
                    variant={"outlined"}
                    color={"primary"}
                    onClick={() => {
                      if (
                        mynOberStore.merchant.styling != null &&
                        mynOberStore.merchant.styling.void_password?.length > 0
                      ) {
                        let password = mynOberStore.merchant.styling.void_password;
                        openDialog({
                          children: (
                            <DialogPasswordProtectedAction
                              password={password}
                              title={"reopen"}
                              onPasswordOk={() => {
                                reopenTaReceipt(props.taHandheldReceipt.id).catch((text) => {
                                  openDialog({ children: <DialogError content={text} /> });
                                });
                              }}
                            />
                          ),
                        });
                      } else {
                        reopenTaReceipt(props.taHandheldReceipt.id).catch((text) => {
                          openDialog({ children: <DialogError content={text} /> });
                        });
                      }
                    }}
                  >
                    {props.intl.formatMessage({ id: "reopen" })}
                  </Button>
                )}
            </div>
            {/*{props.taHandheldReceipt.status === "CLOSED" && (*/}
            {/*  <Button*/}
            {/*    style={{ margin: "8px", padding: "15px" }}*/}
            {/*    variant={"outlined"}*/}
            {/*    onClick={() => {*/}
            {/*      if (mynOberStore.merchant.styling != null && mynOberStore.merchant.styling.void_password != null) {*/}
            {/*        let password = mynOberStore.merchant.styling.void_password;*/}
            {/*        openDialog({*/}
            {/*          children: (*/}
            {/*            <DialogPasswordProtectedAction*/}
            {/*              password={password}*/}
            {/*              title={"reopen"}*/}
            {/*              onPasswordOk={() => {*/}
            {/*                setPasswordChecked(true);*/}
            {/*              }}*/}
            {/*            />*/}
            {/*          ),*/}
            {/*        });*/}
            {/*      } else {*/}
            {/*        setPasswordChecked(true);*/}
            {/*      }*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    {props.intl.formatMessage({ id: "reopen" })}*/}
            {/*  </Button>*/}
            {/*)}*/}
            <Grid container>
              <div className={classes.div} style={{ display: "flex", flexDirection: "column", width: "inherit" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ flexBasis: "35%", fontWeight: "bold" }} variant="caption">
                    {props.intl.formatMessage({ id: "name" })}
                  </Typography>
                  <Typography style={{ flexBasis: "25%", fontWeight: "bold", textAlign: "right" }} variant="caption">
                    {props.intl.formatMessage({ id: "price_per_unit" })}
                  </Typography>
                  <Typography style={{ flexBasis: "15%", fontWeight: "bold", textAlign: "right" }} variant="caption">
                    {props.intl.formatMessage({ id: "count" })}
                  </Typography>
                </div>
                <Divider></Divider>
                <br />

                <div>
                  {props.taHandheldReceipt.receiptArticles.map((receiptArticle: ReceiptArticle) => {
                    return (
                      <div key={props.taHandheldReceipt.id + "-" + receiptArticle.id}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Typography style={{ flexBasis: "35%" }} variant="caption">
                            {receiptArticle.displayName}
                          </Typography>
                          <Typography style={{ flexBasis: "25%", textAlign: "right" }} variant="caption">
                            {GetCurrency("€")}{" "}
                            {(receiptArticle.price / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                          <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                            {receiptArticle.count}
                            {receiptArticle.count === "" ? "" : "x"}
                          </Typography>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Typography style={{ flexBasis: "35%" }} variant="caption" />
                          <Typography style={{ flexBasis: "25%", textAlign: "right" }} variant="caption" />
                          <Typography style={{ flexBasis: "15%", textAlign: "right" }} variant="caption">
                            {GetCurrency("€")}{" "}
                            {((receiptArticle.count * receiptArticle.price) / 100).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </div>
                        <div>
                          {receiptArticle.orderModifiergroups.map((orderModifiergroup) => {
                            return orderModifiergroup.orderModifierarticles.map((orderModifierarticle) => {
                              return (
                                <div
                                  style={{ display: "flex", flexDirection: "row", marginTop: "0px" }}
                                  key={orderModifiergroup.id + "-" + orderModifierarticle.id}
                                >
                                  <Typography
                                    style={{ flexBasis: "35%", fontSize: "80%", color: "grey" }}
                                    variant="caption"
                                  >
                                    {orderModifierarticle.name}
                                  </Typography>
                                  <Typography
                                    style={{ flexBasis: "25%", fontSize: "80%", color: "grey" }}
                                    variant="caption"
                                  >
                                    {orderModifierarticle.amount === 0
                                      ? ""
                                      : "+" +
                                        GetCurrency("€") +
                                        " " +
                                        (orderModifierarticle.amount / 100).toLocaleString(undefined, {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        })}
                                  </Typography>
                                </div>
                              );
                            });
                          })}
                        </div>
                      </div>
                    );
                  })}
                  <br />
                  <Divider />
                </div>

                <Divider variant="fullWidth" />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography style={{ flexBasis: "60%" }}>{props.intl.formatMessage({ id: "text_total" })}</Typography>
                  <Typography
                    style={{
                      flexBasis: "15%",
                      textAlign: "right",
                    }}
                  >
                    {GetCurrency("€")}{" "}
                    {(props.taHandheldReceipt.getAmount() / 100).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Typography>
                </div>
              </div>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

HistoryTaReceiptView.propTypes = {
  taHandheldReceipt: PropTypes.instanceOf(TaReceipt).isRequired,
};

export default injectIntl(HistoryTaReceiptView);
