import React from "react";
import { injectIntl } from "react-intl";
import icReceipt from "./../../../../assets/images/bottombar/ic-history.svg";
import icReceiptActive from "./../../../../assets/images/bottombar/ic-history-active.svg";
import BottomTab from "./BottomTab";

function ReceiptBottomTab(props) {
  return (
    <BottomTab
      icon={icReceipt}
      activeIcon={icReceiptActive}
      onClick={() => props.onClick()}
      selected={props.selected}
      badge={null}
      title={props.intl.formatMessage({ id: "tab_receipt" })}
    />
  );
}

export default injectIntl(ReceiptBottomTab);
