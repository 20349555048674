import mynOberStore from "../flux/MynOberStore";

export class TaPostalCode {
  constructor(postalCode, amountWithExtraCosts, extraCosts, minAmount, except) {
    this.postalCode = postalCode;
    this.amountWithExtraCosts = amountWithExtraCosts;
    this.extraCosts = extraCosts;
    this.minAmount = minAmount;
    this.except = except;
  }
}

export function getTaPostalCode(postalCode) {
  postalCode = postalCode.replace(/\s/g, "").toLowerCase();
  return mynOberStore.merchant.taPostalCodes.find((taPostalCode: TaPostalCode) => {
    return (
      postalCode.startsWith(taPostalCode.postalCode) &&
      !taPostalCode.except?.some((except) => postalCode === taPostalCode.postalCode + except.toLowerCase())
    );
  });
}
