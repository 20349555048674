import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle, TextField, Typography } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import DialogDeliveryOrTakeaway from "./DialogDeliveryOrTakeaway";
import { getTaPostalCode } from "../../utils/merchant/TaPostalCode";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  inputText: {
    textAlign: "center",
    padding: "10px",
    fontSize: "20px",
    width: "70%",
  },
}));

function DialogPostalcodeCheck(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();
  const [postalcode, setPostalcode] = React.useState("");
  const [error, setError] = React.useState(false);

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "postalcode_check" })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: "postcode_check_explanation" })}</h3>
        <div className={classes.div}>
          <TextField
            className={classes.inputText}
            type={"text"}
            placeholder={"3032"}
            error={error}
            helperText={error ? props.intl.formatMessage({ id: "postcode_check_failed" }) : null}
            value={postalcode}
            onChange={(e) => {
              let postalCode = e.target.value.replace(/\s/g, "").toLowerCase();
              let deliveryInPostalCode = getTaPostalCode(postalCode) != null;
              if (deliveryInPostalCode) {
                setError(false);
              } else {
                setError(true);
              }

              setPostalcode(postalCode);
            }}
          />
          {!error && postalcode.length > 0 ? (
            <Typography color={"primary"} style={{ fontWeight: 800 }}>
              {props.intl.formatMessage({ id: "postal_code_ordering_ok" })}
            </Typography>
          ) : null}

          <Button
            className={classes.button}
            variant={"contained"}
            color={"primary"}
            disabled={postalcode.length === 0 || error}
            onClick={() => {
              props.onPostalcode(postalcode);
              closeDialog();
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              openDialog({ children: <DialogDeliveryOrTakeaway isOpen={mynOberStore.merchant.isOpen} /> }, true);
            }}
          >
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogPostalcodeCheck);
