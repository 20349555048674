import React, { Component } from "react";
import QrScannerPage from "../../pages/QrScannerPage";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import { ScannerType } from "./HomeContainer";
import * as errorHandler from "../../utils/error_handler/ErrorHandler";
import mynOberStore from "../../utils/flux/MynOberStore";
import DialogError from "../../components/dialogs/DialogError";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogOk from "../../components/dialogs/DialogOk";

class QrScannerContainer extends Component {
  constructor(props) {
    super(props);

    this.qrUrls = ["https://web.mynober.nl/qr/", "https://web.mynober.com/qr/", "https://web.ibutler.online/qr/", "https://ayce.jamezz.app/qr/"];

    this.state = {
      webcode: "",
    };
  }

  handleScan = (data) => {
    if (data !== null) {
      let webcode = null;
      this.qrUrls.find((qrUrl) => {
        // console.log("...");
        // console.log(data);
        // console.log(qrUrl);
        // console.log("...");
        if (data.substr(0, qrUrl.length) === qrUrl) {
          webcode = data.substr(qrUrl.length);
          return true;
        } else {
          return false;
        }
      });

      if (webcode !== null && webcode !== this.state.webcode) {
        // console.log(webcode);
        this.onWebcodeChange(webcode);
        this.sendWebcode(webcode);
      }
    }
  };

  handleError = (error) => {
    errorHandler.sendError(error);
  };

  onWebcodeSend = (event) => {
    if (event !== null) {
      event.preventDefault();
    }

    if (this.state.webcode === "") {
      const [openDialog] = this.context;
      openDialog({ children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_no_code"} /> });
      return;
    }

    this.sendWebcode(this.state.webcode);
  };

  onWebcodeChange = (text) => {
    this.setState({ webcode: text });
  };

  sendWebcode = (webcode) => {
    if (mynOberStore.isHandheld()) {
      mynOberActions.setWebcode(webcode);
      requestFunctions.getReceipt(true, webcode).catch((text) => {
        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={text} /> });
      });
    } else {
      if (this.scannerType === ScannerType.MOVE) {
        requestFunctions
          .checkLocation()
          .then((distance) => {
            requestFunctions.sendMoveRequest(webcode, distance).catch((text) => {
              const [openDialog] = this.context;
              openDialog({ children: <DialogError content={text} /> });
            });
          })
          .catch((error) => {
            if (error.code === 1) {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
              });
            } else {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
              });
            }
          });
      } else {
        requestFunctions.sendWebcode(webcode).catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
      }
    }
  };

  render() {
    return <QrScannerPage handleError={this.handleError} handleScan={this.handleScan} />;
  }
}

QrScannerContainer.contextType = DialogContext;

export default QrScannerContainer;
