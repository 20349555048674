import { useDialog } from "./DialogProvider";
import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import mynOberStore from "../../utils/flux/MynOberStore";
import React from "react";
import withIntl from "../../utils/withIntl";
import DialogSuccess from "./DialogSuccess";
import DialogError from "./DialogError";
import { PaymentMethods } from "../../utils/PaymentMethod";
import { makeStyles } from "@material-ui/core/styles";
import DialogOk from "./DialogOk";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogPaymentRequest(props) {
  const classes = useStyles();
  const [openDialog, closeDialog] = useDialog();

  const onOptionClicked = (paymentMethod) => {
    closeDialog();
    requestFunctions
      .checkLocation()
      .then((distance) => {
        requestFunctions
          .sendPaymentRequest(paymentMethod, mynOberStore.hasReceipt(), mynOberStore.merchant, distance)
          .then(() => {
            openDialog({
              children: <DialogSuccess content={"alert_text_success_payment_request"} />,
            });
          })
          .catch((text) => {
            openDialog({ children: <DialogError content={text} /> });
          });
      })
      .catch((error) => {
        if (error.code === 1) {
          openDialog({
            children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
          });
        } else {
          openDialog({
            children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
          });
        }
      });
  };

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "alert_title_payment_request" })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: "alert_text_payment_request" })}</h3>
        <div className={classes.div}>
          <Button className={classes.button} variant={"outlined"} onClick={() => onOptionClicked(PaymentMethods.CARD)}>
            {props.intl.formatMessage({ id: "button_card" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={() => onOptionClicked(PaymentMethods.CASH)}>
            {props.intl.formatMessage({ id: "button_cash" })}
          </Button>
          <Button className={classes.button} color={"warning"} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogPaymentRequest);
