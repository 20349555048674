import React from "react";
import PropTypes from "prop-types";
import "./ViewArticlegroup.css";
import { Grid } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

export const articlegroupViewId = "articlegroup-";

class ViewArticlegroup extends React.Component {
  constructor(props) {
    super(props);

    this.viewArticlegroupRef = React.createRef();
  }

  render() {
    return (
      <div
        className={"container-articlegroup"}
        id={articlegroupViewId + this.props.index}
        ref={this.viewArticlegroupRef}
      >
        <Typography
          color={"textPrimary"}
          style={{
            color: this.props.theme.articlegroup?.palette?.text?.primary,
            fontFamily: this.props.theme.articlegroup?.typography?.fontFamily,
            fontSize: 20,
            margin: "0 25px",
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingBottom: 3,
          }}
        >
          {this.props.name}
        </Typography>
        <div className={this.props.flexColumn === true ? "view-articlegroup-column" : "view-articlegroup"}>
          <Grid container spacing={2}>
            {this.props.articles.map((article, index) => {
              // return article

              return (
                <Grid item xs={12} sm={this.props.onlyOneColumn ? 12 : 6} key={index}>
                  {article}
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

ViewArticlegroup.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  articles: PropTypes.array.isRequired,
  flexColumn: PropTypes.bool,
};

export default withTheme(ViewArticlegroup);
