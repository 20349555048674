import { Route, Switch } from "react-router-dom";
import React, { Component } from "react";
import OrderContainer from "../containers/smartphone/OrderContainer";
import ReceiptContainer from "../containers/smartphone/ReceiptContainer";
import TakeawayPage from "../pages/web/TakeawayPage";
import DeliveryPage from "../pages/web/DeliveryPage";
import { PathNames } from "./PathNames";
import mynOberStore from "../utils/flux/MynOberStore";
import { HandheldPathNames } from "./handheld/HandheldRouter";
import HandheldReceiptContainer from "../containers/handheld/smartphone/HandheldReceiptContainer";
import CheckoutPage from "../pages/web/CheckoutPage";
import HandheldOrdersContainer from "../containers/handheld/smartphone/HandheldOrdersContainer";
import MenuContainerV2 from "../containers/smartphone/MenuContainerV2";

class SmallScreenRouter extends Component {
  render() {
    return mynOberStore.isHandheld() ? (
      <Switch>
        <Route
          path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.ORDERPAGE}`}
          render={(props) => <OrderContainer {...props} />}
        />
        <Route
          path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.RECEIPTPAGE}`}
          render={(props) => <HandheldReceiptContainer />}
        />
        <Route
          path={`/:spMerchantId${HandheldPathNames.TABLE}/:tableId${PathNames.ORDERSPAGE}`}
          render={(props) => <HandheldOrdersContainer />}
        />
        <Route render={(props) => <MenuContainerV2 {...props} />} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path={PathNames.MENUPAGE} render={(props) => <MenuContainerV2 {...props} />} />
        <Route exact path={PathNames.ORDERPAGE} render={(props) => <OrderContainer {...props} />} />
        <Route exact path={PathNames.RECEIPTPAGE} render={(props) => <ReceiptContainer {...props} />} />
        <Route exact path={PathNames.TAKEAWAYPAGE} render={(props) => <TakeawayPage {...props} />} />
        <Route exact path={PathNames.CHECKOUTPAGE} render={(props) => <CheckoutPage {...props} />} />
        <Route exact path={PathNames.DELIVERYPAGE} render={(props) => <DeliveryPage {...props} />} />
        <Route render={(props) => <MenuContainerV2 {...props} />} />
      </Switch>
    );
  }
}

export default SmallScreenRouter;
