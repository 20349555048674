import React from "react";
import "./TopNavigation.css";
import PropTypes from "prop-types";
import { TaMerchant } from "../../utils/merchant/TaMerchant";
import mynOberStore from "../../utils/flux/MynOberStore";
import MenuIcon from "@material-ui/icons/Menu";
import { Button } from "@material-ui/core";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import withIntl from "../../utils/withIntl";
import { withRouter } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";
import DialogOkOrCancel from "../dialogs/DialogOkOrCancel";
import { useDialog } from "../dialogs/DialogProvider";
import { PathNames } from "../../routers/PathNames";
import Typography from "@material-ui/core/Typography";
import { SpMerchant } from "../../utils/merchant/SpMerchant";

export const topNavigationId = "top-navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.top_navigation?.palette?.primary?.main ?? theme.palette.primary.main,
    zIndex: 140,
  },
}));

function TopNavigation(props) {
  const classes = useStyles();
  const [openDialog] = useDialog();
  return (
    <div id={topNavigationId} className={"top-navigation " + classes.root}>
      <div style={{ position: "absolute", height: "54px" }}>
        {!(mynOberStore.merchant instanceof TaMerchant) || mynOberStore.isHandheld() ? (
          <Button className={"top-navigation-options"} onClick={props.onClickOptions} startIcon={<MenuIcon />}>
            {mynOberStore.isWidescreen && props.intl.formatMessage({ id: props.titleId })}
          </Button>
        ) : null}
        {mynOberStore.merchant instanceof TaMerchant && mynOberStore.merchant.taMasterMerchantId > 0 ? (
          <Button
            className={"top-navigation-options"}
            startIcon={<ArrowBack />}
            onClick={() => {
              if (props.history.location.pathname === PathNames.MENUPAGE) {
                openDialog({
                  children: (
                    <DialogOkOrCancel
                      title={"Leave restaurant"}
                      content={"Are you sure you want to leave the restaurant?"}
                      onOkClicked={() => {
                        props.history.goBack();
                      }}
                    />
                  ),
                });
              } else {
                if (props.history.location.pathname === PathNames.ORDERPAGE) {
                  props.history.replace(PathNames.MENUPAGE);
                } else {
                  props.history.goBack();
                }
              }
            }}
          >
            {props.intl.formatMessage({ id: "button_back" })}
          </Button>
        ) : null}
      </div>
      <div
        className={"top-navigation-merchant-box-name"}
        style={{
          visibility: mynOberStore.isHandheld() && mynOberStore.merchant instanceof TaMerchant ? "hidden" : null,
        }}
      >
        <Typography
          color={"textPrimary"}
          style={{
            color: props.theme.top_navigation?.palette?.text?.primary,
            fontFamily: props.theme.top_navigation?.typography?.fontFamily,
          }}
        >
          {props.merchantName}
          {!mynOberStore.isHandheld() &&
            ((mynOberStore.merchant.isTables === 1 && mynOberStore.merchant instanceof TaMerchant) ||
              mynOberStore.merchant instanceof SpMerchant) &&
            mynOberStore.spQrCode?.table_id &&
            " - " + mynOberStore.spQrCode?.table_id}
        </Typography>
      </div>
    </div>
  );
}

TopNavigation.propTypes = {
  iconSrc: PropTypes.object.isRequired,
  titleId: PropTypes.string.isRequired,
  merchantName: PropTypes.string.isRequired,
  onClickOptions: PropTypes.func.isRequired,
};

export default withTheme(withRouter(withIntl(TopNavigation)));
