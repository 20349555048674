import React from "react";
import "./ButtonDrawerMenu.css";
import PropTypes from "prop-types";
import withIntl from "../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ListItemText } from "@material-ui/core";
import useLongPress from "../utils/useLongPress";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
}));

function ButtonDrawerMenu(props) {
  const classes = useStyles();

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  const onLongPress = () => {};
  const longPressEvent = useLongPress(props.onLongPress ?? onLongPress, props.onClick, defaultOptions);
  return (
    <>
      <Button
        {...longPressEvent}
        className={"button-drawer-menu " + classes.root}
        startIcon={props.icon != null ? props.icon : null}
      >
        <ListItemText
          primaryTypographyProps={{ style: { fontFamily: "inherit", fontWeight: "inherit", fontSize: "inherit" } }}
          secondaryTypographyProps={{ style: { fontFamily: "inherit", fontWeight: "inherit", fontSize: "inherit" } }}
          primary={props.intl.formatMessage({ id: props.stringId })}
          secondary={props.subtitle}
        />
      </Button>
    </>
  );
}

ButtonDrawerMenu.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  stringId: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

export default withIntl(ButtonDrawerMenu);
