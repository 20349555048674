import { useDialog } from "./DialogProvider";
import { Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";
import React from "react";
import withIntl from "../../utils/withIntl";
import Typography from "@material-ui/core/Typography";

function DialogWelcome(props) {
  const [, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle style={{ textAlign: "center" }}>{props.intl.formatMessage({ id: "welcome" })}</DialogTitle>
      <DialogContent style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Typography
          variant={"subtitle1"}
          dangerouslySetInnerHTML={{ __html: mynOberStore.merchant.info?.welcome_message }}
        />
      </DialogContent>

      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={() => {
            closeDialog();
          }}
        >
          {props.intl.formatMessage({ id: "continue" })}
        </Button>
      </DialogActions>
    </>
  );
}

export default withIntl(DialogWelcome);
