export class DayHour {
  constructor(openingHour, closingHour) {
    this.openingHour = openingHour;
    this.closingHour = closingHour;
  }

  isClosed() {
    return this.openingHour === this.closingHour;
  }
}

export function hourToMinutes(hour) {
  let minutes = 0;
  let splitted = hour.split(":");
  minutes += Number.parseInt(splitted[0]) * 60;
  minutes += Number.parseInt(splitted[1]);
  return minutes;
}

export function dayHourRangesIsValid(ranges) {
  for (let i = 0; i < ranges.length; i++) {
    if (!hourIsValid(ranges[i].closingHour) || !hourIsValid(ranges[i].openingHour)) {
      return false;
    }
    if (i > 0) {
      if (hourToMinutes(ranges[i].openingHour) <= hourToMinutes(ranges[i - 1].closingHour)) {
        return false;
      }
    }
  }

  return true;
}

export function hourIsValid(hours) {
  if (typeof hours !== "string") {
    return false;
  }

  if (!hours.includes(":")) {
    return false;
  }

  let splitted = hours.split(":");
  if (splitted.length !== 2) {
    return false;
  }

  let hour = Number.parseInt(splitted[0]);
  let minute = Number.parseInt(splitted[1]);
  if (!(hour >= 0 && hour <= 24)) {
    return false;
  }
  if (!(minute >= 0 && minute <= 59)) {
    return false;
  }
  return true;
}
