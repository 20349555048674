import React, { Component } from "react";
import HeaderMyn from "../../components/HeaderMyn";
import LoginForm from "../../components/web/LoginForm";
import { injectIntl } from "react-intl";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import * as listeners from "../../utils/Listeners";
import "./LoginHandheldPage.css";
import * as errorHandler from "../../utils/error_handler/ErrorHandler";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogError from "../../components/dialogs/DialogError";
import DialogOk from "../../components/dialogs/DialogOk";

const divHandheldLoginStyle = function () {
  return { backgroundImage: 'url("./images/' + window.label.images.folder + '/homepage-qrcode.jpeg")' };
};

class LoginHandheldPage extends Component {
  constructor(props) {
    super(props);

    this.onLoginSuccessful = this.onLoginSuccessful.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.sendHandheldCode = this.sendHandheldCode.bind(this);

    this.state = {
      username: "",
      password: "",
    };
  }

  componentDidMount() {
    listeners.registerHandheldLoginListeners(this.onLoginSuccessful);
  }

  componentWillUnmount() {
    listeners.unregisterHandheldLoginListeners(this.onLoginSuccessful);
  }

  onLoginSuccessful() {
    mynOberAPIClientManager
      .getMerchantWithMenuForWebcode()
      .then((response) => {
        mynOberActions.setIsLoading(false);
        mynOberActions.setMerchantWithMenuAndWebcode(response.merchant, response.menu);
        mynOberAPIClientManager.getAllHandheldReceipts(true);
      })
      .catch((error) => {
        errorHandler.sendError(error);
        mynOberActions.setIsLoading(false);

        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={error.message} /> });
      });
  }

  onPasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  onUsernameChange(e) {
    this.setState({ username: e.target.value });
  }

  sendHandheldCode(e) {
    if (e !== null) {
      e.preventDefault();
    }
    mynOberActions.setIsLoading(true);
    let self = this;
    mynOberAPIClientManager
      .login(mynOberAPIClientManager.constructLoginHandheldData(this.state.password, this.state.username))
      .then((handheldUser) => {
        mynOberActions.loginHandheld(self.state.password, self.state.username);
        mynOberActions.loginHandheldFromIndex(handheldUser);
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        const [openDialog] = this.context;
        openDialog({ children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_login"} /> });
      });
  }

  render() {
    return (
      <div>
        <div className={"login-handheld-page"} style={divHandheldLoginStyle()}>
          <HeaderMyn />
          <LoginForm
            username={this.state.username}
            password={this.state.password}
            onUsernameChange={this.onUsernameChange}
            onPasswordChange={this.onPasswordChange}
            sendHandheldCode={this.sendHandheldCode}
          />
        </div>
      </div>
    );
  }
}

LoginHandheldPage.contextType = DialogContext;

export default injectIntl(LoginHandheldPage);
