import { SpMerchant } from "./SpMerchant";
import mynOberStore from "../flux/MynOberStore";

export class AyceMerchant extends SpMerchant {
  constructor(
    id,
    merchantId,
    name,
    timezone,
    countryCodes,
    currency,
    location,
    isOpen,
    showAllergens,
    showDescription,
    openingHours,
    styling,
    manuallyClosed,
    paymentMethods,
    tip,
    menuTags,
    canMove,
    canNotification,
    canPay,
    canPayHandheld,
    canPayOnline,
    canWaiter,
    canShareOrder,
    useGeoLocation,
    imageMd5,
    sharedOrder,
    floorPlans,
    activeMArticleIds,
    activePlus,
    mustBuildupReceipt,
    isOrdering,
    enableRemarkInOrdergroup,
    askContactDetails,
    maximumReceiptsPerTable,
    ayceSettings,
    couverts,
    salesAreaName,
    image,
    customFields,
    guestRegistration,
    info,
    shareOrderSeconds,
    activeMArticlePlus,
    shieldedReceipt,
    useMenuTags,
    usePincode,
    isMynbonnenMerchant
  ) {
    super(
      id,
      merchantId,
      name,
      timezone,
      countryCodes,
      currency,
      location,
      isOpen,
      showAllergens,
      showDescription,
      openingHours,
      styling,
      manuallyClosed,
      paymentMethods,
      tip,
      menuTags,
      canMove,
      canNotification,
      canPay,
      canPayHandheld,
      canPayOnline,
      canWaiter,
      canShareOrder,
      useGeoLocation,
      imageMd5,
      sharedOrder,
      floorPlans,
      activeMArticleIds,
      activePlus,
      mustBuildupReceipt,
      isOrdering,
      enableRemarkInOrdergroup,
      askContactDetails,
      maximumReceiptsPerTable,
      salesAreaName,
      image,
      customFields,
      guestRegistration,
      info,
      shareOrderSeconds,
      activeMArticlePlus,
      shieldedReceipt,
      useMenuTags,
      usePincode,
      isMynbonnenMerchant
    );

    this._ayceSettings = ayceSettings;
    this._couverts = couverts;
  }

  /**
   *
   * @return {{timeInterval: Number, couvertMultiplier: Number, ordergroups: Number, articleFlags: Array, maxDifferentPlus: Number}}
   */
  get ayceSettings() {
    return this._ayceSettings;
  }

  get maxWeight() {
    let maxWeightForCategory = null;
    let lastArticleFlagUsed = null;
    let articleFlagsIncludingNullCategory = [];

    let activeMArticleIds = mynOberStore.activeMArticleIdsWithLag;

    if (activeMArticleIds == null || mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return 0;
    }

    this.ayceSettings.articleFlags.forEach((articleFlag) => {
      if (activeMArticleIds[articleFlag.articleId] != null) {
        if (articleFlag["categories"] != null) {
          if (articleFlag["categories"]["max_weight_per_cover"] != null) {
            maxWeightForCategory +=
              articleFlag["categories"]["max_weight_per_cover"] * activeMArticleIds[articleFlag.articleId]["count"];
            lastArticleFlagUsed = articleFlag;
            return;
          }
        }
        articleFlagsIncludingNullCategory.push(articleFlag);
      }
    });
    if (lastArticleFlagUsed) {
      articleFlagsIncludingNullCategory.forEach((articleFlag) => {
        maxWeightForCategory +=
          lastArticleFlagUsed["categories"]["max_weight_per_cover"] * activeMArticleIds[articleFlag.articleId]["count"];
      });
    }

    if (Number.isInteger(maxWeightForCategory)) {
      return maxWeightForCategory;
    }

    return this.ayceSettings.couvertMultiplier * mynOberStore.coversWithLag;
  }

  /**
   *
   * @return {number}
   */
  get couverts() {
    return this._couverts;
  }

  /**
   *
   * @param {number} couverts
   */
  set couverts(couverts) {
    this._couverts = couverts;
  }
}
