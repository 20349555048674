import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import handheldStore from "../../../utils/flux/HandheldStore";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { OrderModifiergroup } from "../../../utils/order/OrderModifiergroup";
import { OrderModifierarticle } from "../../../utils/order/OrderModifierarticle";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function SplitTableDialog(props) {
  const classes = useStyles();
  let receiptArticles = props.receipt.receiptArticles.map((receiptArticle) => {
    let copy = new ReceiptArticle();
    Object.assign(copy, receiptArticle);
    copy.count = copy.count - copy.countClosed;
    copy.countClosed = 0;
    return copy;
  });
  const [receipt, setReceipt] = React.useState(props.receipt);
  const [left, setLeft] = React.useState(receiptArticles);
  const [right, setRight] = React.useState([]);

  if (receipt !== props.receipt) {
    setReceipt(props.receipt);
    setLeft(receiptArticles);
    setRight([]);
  }

  const handleArticle = (from, to, receiptArticle) => {
    let otherArticle = to.find((otherNewArticle) => {
      return receiptArticle.equals(otherNewArticle);
    });
    let hasNumerable = receiptArticle.hasNumerableOrderModifiergroup;
    if (!hasNumerable && otherArticle != null) {
      otherArticle.count++;
    } else {
      otherArticle = new ReceiptArticle();
      Object.assign(otherArticle, receiptArticle);
      if (hasNumerable) {
        otherArticle.count = receiptArticle.count;
      } else {
        otherArticle.count = 1;
      }
      to.push(otherArticle);
    }
    if (hasNumerable) {
      from.splice(from.indexOf(receiptArticle), 1);
    } else {
      if (receiptArticle.count > 1) {
        receiptArticle.count--;
      } else {
        from.splice(from.indexOf(receiptArticle), 1);
      }
    }

    return { to: to, from: from };
  };

  const handleToggle = (receiptArticle: ReceiptArticle) => () => {
    if (left.indexOf(receiptArticle) !== -1) {
      const { from, to } = handleArticle(left, right, receiptArticle);
      setLeft([...from]);
      setRight([...to]);
    } else if (right.indexOf(receiptArticle) !== -1) {
      const { from, to } = handleArticle(right, left, receiptArticle);
      setLeft([...to]);
      setRight([...from]);
    }
  };

  const handleAllRight = () => {
    left.forEach((toTransferItem) => {
      let rightArticle = right.find((rightArticle) => rightArticle.equals(toTransferItem));
      if (rightArticle != null) {
        rightArticle.count += toTransferItem.count;
      } else {
        rightArticle = toTransferItem;
        right.push(rightArticle);
      }
    });
    setRight([...right]);
    setLeft([]);
  };

  const handleAllLeft = () => {
    right.forEach((toTransferItem) => {
      let leftArticle = left.find((leftArticle) => leftArticle.equals(toTransferItem));
      if (leftArticle != null) {
        leftArticle.count += toTransferItem.count;
      } else {
        leftArticle = toTransferItem;
        left.push(leftArticle);
      }
    });
    setLeft([...left]);
    setRight([]);
  };

  const customList = (items) => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem
              key={
                "id:" + value.id + "." + value.count + "." + value.countClosed + "." + value.price + "." + Math.random()
              }
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <Grid container direction={"column"}>
                <Grid item>
                  <ListItemText id={labelId} primary={`${value.count} ${value.name}`} />
                </Grid>
                {value.orderModifiergroups.map((orderModifiergroup: OrderModifiergroup) => {
                  return orderModifiergroup.orderModifierarticles.map((orderModifierarticle: OrderModifierarticle) => {
                    let text = orderModifiergroup.numerable
                      ? orderModifierarticle.count + "x " + orderModifierarticle.name
                      : orderModifierarticle.name;
                    return (
                      <Grid item>
                        <ListItemText id={labelId} primary={text} />
                      </Grid>
                    );
                  });
                })}
              </Grid>
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  let tables = handheldStore.tables[props.match.params["spMerchantId"]];
  let tableRows = [];
  Object.keys(tables).forEach((key, index) => {
    let table = tables[key];
    if (index % 3 === 0) {
      tableRows[Math.trunc(index / 3)] = [table];
    } else {
      tableRows[Math.trunc(index / 3)].push(table);
    }
  });

  return (
    <Dialog
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      maxWidth={"lg"}
      fullWidth={true}
      open={props.open}
      aria-labelledby="simple-dialog-title"
      onBackdropClick={props.onClose}
      // onClose={props.onClose}
    >
      <DialogTitle>{props.intl.formatMessage({ id: "SPLIT" })}</DialogTitle>
      <DialogContent>
        <Grid container justify={"space-between"} direction="row">
          <Grid item style={{ padding: "8px" }}>
            <Typography>
              {props.intl.formatMessage({ id: "from_table" })} {props.fromTable.id}
            </Typography>
          </Grid>
          <Grid item style={{ padding: "8px" }}>
            <Typography>
              {props.intl.formatMessage({ id: "to_table" })} {props.toTable != null ? props.toTable.id : null}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
          <Grid item style={{ width: "40%" }}>
            {customList(left)}
          </Grid>
          <Grid item>
            <Grid container direction="column" alignItems="center">
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllRight}
                disabled={left.length === 0}
                aria-label="move all right"
              >
                ≫
              </Button>
              <Button
                variant="outlined"
                size="small"
                className={classes.button}
                onClick={handleAllLeft}
                disabled={right.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item style={{ width: "40%" }}>
            {customList(right)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"contained"}
          onClick={() => {
            props.onSplit(right);
            let receiptArticles = props.receipt.receiptArticles.map((receiptArticle) => {
              let copy = new ReceiptArticle();
              Object.assign(copy, receiptArticle);
              copy.count = copy.count - copy.countClosed;
              copy.countClosed = 0;
              return copy;
            });
            setLeft(receiptArticles);
            setRight([]);
          }}
        >
          {props.intl.formatMessage({ id: "SPLIT" })}
        </Button>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"contained"}
          onClick={() => {
            props.onClose();
          }}
        >
          {props.intl.formatMessage({ id: "button_back" })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withRouter(injectIntl(SplitTableDialog));
