import React from "react";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import GetCurrency from "../utils/functions/GetCurrency";

export function FreeTipSelector(props) {
  const [tipAmount, setTipAmount] = React.useState(0);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <TextField
        variant={"outlined"}
        InputProps={{
          startAdornment: <InputAdornment position="start">{GetCurrency("€")}</InputAdornment>,
        }}
        className={"input-takeaway"}
        style={{ fontSize: "16pt" }}
        onChange={(e) => {
          setTipAmount(e.target.value);
        }}
        type={"number"}
        name={"amount"}
        value={tipAmount}
      />

      <Button
        style={{ fontSize: "16pt", margin: "8px 8px" }}
        variant={"outlined"}
        onClick={() => {
          props.onTipSelect(Math.round(Number.parseFloat(tipAmount) * 100.0));
        }}
      >
        Ok
      </Button>
      <br />
    </div>
  );
}
