import { ReceiptArticle } from "../receipt/ReceiptArticle";
import { OrderModifiergroup } from "./OrderModifiergroup";
import { OrderModifierarticle } from "./OrderModifierarticle";
import * as sessionStorage from "../session_storage/SessionStorage";
import { storageNames } from "../session_storage/SessionStorage";
import mynOberStore from "../flux/MynOberStore";

export const OrderTypes = {
  RECEIPT: "RECEIPT_ORDER",
  TAKEAWAY: "TAKEAWAY_ORDER",
  DELIVERY: "DELIVERY_ORDER",
};

export class Order {
  /**
   *
   */
  constructor(webcode, orderType, customFields = {}) {
    let oldOrder = sessionStorage.getItem(storageNames.ORDER);
    this.extraMessage = "";
    this.orderArticles = [];
    this.orderType = orderType;
    this.webcode = webcode;
    if (oldOrder != null) {
      oldOrder = JSON.parse(oldOrder);
      this.remark = oldOrder["remark"];
    }
    this.customFields = customFields;
  }

  /**
   *
   * @return {number}
   */
  get count() {
    if (mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return 0;
    }
    let totalCount = 0;

    this.orderArticles.forEach((orderArticle) => {
      totalCount += orderArticle.count;
    });

    return totalCount;
  }

  get countItemsWithWeightNull() {
    if (mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return 0;
    }
    let totalCount = 0;

    this.orderArticles.forEach((orderArticle) => {
      if (orderArticle.weight == null) {
        totalCount += orderArticle.count;
      }
    });

    return totalCount;
  }

  get countItemsWithWeightNotNull() {
    if (mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return 0;
    }
    let totalCount = 0;

    this.orderArticles.forEach((orderArticle) => {
      if (orderArticle.weight != null) {
        totalCount += orderArticle.count;
      }
    });

    return totalCount;
  }

  get weight() {
    if (mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return 0;
    }
    let totalWeight = 0;

    this.orderArticles.forEach((orderArticle) => {
      if (Number.isInteger(orderArticle.weight)) {
        totalWeight += orderArticle.count * orderArticle.weight;
      }
    });

    return totalWeight;
  }

  /**
   *
   * @return {number}
   */
  get amount() {
    let totalAmount = 0;
    this.orderArticles.forEach((orderArticle) => {
      totalAmount += orderArticle.amount;
    });

    if (mynOberStore.merchant?.info?.default_order_items instanceof Array) {
      mynOberStore.merchant.info.default_order_items
        .filter((item) => {
          return mynOberStore.menu.articlesMapId[item.id] != null;
        })
        .forEach((orderArticle: OrderArticle) => {
          totalAmount += orderArticle.amount * orderArticle.price;
        });
    }
    if (mynOberStore.userSelectedPackaging?.price) {
      totalAmount += mynOberStore.userSelectedPackaging?.price;
    }
    return totalAmount;
  }

  /**
   *
   * @param {OrderArticle} orderArticleToAdd
   */
  addOrderArticleToOrder(orderArticleToAdd) {
    let index = this.orderArticles.findIndex((orderArticle) => {
      return orderArticleToAdd.equals(orderArticle);
    });

    if (index !== -1) {
      this.orderArticles[index].count += orderArticleToAdd.count;
      if (this.orderArticles[index].count <= 0) {
        this.orderArticles.splice(index, 1);
      }
    } else if (orderArticleToAdd.count > 0) {
      this.orderArticles.push(orderArticleToAdd);
    }
  }

  /**
   *
   * @param {number} id
   * @return {number}
   */
  getCountByArticleId(id) {
    let count = 0;

    this.orderArticles.forEach((orderArticle) => {
      if (orderArticle.id === id) {
        count += orderArticle.count;
      }
    });

    return count;
  }

  /**
   *
   * @param {number} id
   * @return {number}
   */
  getAmountByArticleId(id) {
    let orderArticlesWithId = this.orderArticles.filter(function (orderArticle) {
      return orderArticle.id === id;
    });

    let amount = 0;
    orderArticlesWithId.forEach(function (orderArticle) {
      amount += orderArticle.amount;
    });

    return amount;
  }

  /**
   *
   * @return {Array}
   */
  toJson(onlyWeightZero = false) {
    let json = [];

    mynOberStore.filteredOutOrderArticles = [];
    this.orderArticles
      .filter((orderArticle) => {
        if (onlyWeightZero) {
          if (orderArticle.weight != null) {
            mynOberStore.filteredOutOrderArticles.push(orderArticle);
            return false;
          }
        }
        return true;
      })
      .forEach((orderArticle) => {
        json.push(orderArticle.toApiForm());
      });

    return json;
  }
}

export function receiptArticlesToJSON(receiptArticles: Array) {
  let json = [];
  receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
    let jsonModifiergroups = [];
    receiptArticle.orderModifiergroups.forEach((orderModifiergroup: OrderModifiergroup) => {
      let jsonModifiergroup = {
        m_modifiergroup_id: orderModifiergroup.id,
        m_modifierarticles: [],
      };
      orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle: OrderModifierarticle) => {
        jsonModifiergroup.m_modifierarticles.push({
          m_modifierarticle_id: orderModifierarticle.id,
          amount: orderModifierarticle.count,
        });
      });

      jsonModifiergroups.push(jsonModifiergroup);
    });

    json.push({
      m_article_id: receiptArticle.id,
      amount: receiptArticle.count,
      m_modifiergroups: jsonModifiergroups,
      price: receiptArticle.price,
      name: receiptArticle.name,
    });
  });

  return json;
}
