import React, { Component } from "react";
import PropTypes from "prop-types";
import "./AmountAddRemove.css";
import { IconButton, Typography } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";

class AmountAddRemove extends Component {
  render() {
    return (
      <div
        style={{ justifyContent: this.props.justifyContent != null ? this.props.justifyContent : "space-around" }}
        className={"amount-add-remove"}
      >
        <IconButton onClick={this.props.onRemoveClicked} disabled={this.props.canNotRemove}>
          <Remove />
        </IconButton>
        <Typography style={{ minWidth: "24px", textAlign: "center" }}>{this.props.count}</Typography>
        <IconButton onClick={this.props.onAddClicked}>
          <Add />
        </IconButton>
      </div>
    );
  }
}

AmountAddRemove.propTypes = {
  canNotRemove: PropTypes.bool,
  onRemoveClicked: PropTypes.func.isRequired,
  onAddClicked: PropTypes.func.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  styleButton: PropTypes.object,
  styleHover: PropTypes.object,
};

export default AmountAddRemove;
