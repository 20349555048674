import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles, withTheme } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import { Articlegroup } from "../../utils/menu/Articlegroup";
import Grid from "@material-ui/core/Grid";

export const articlegroupTabId = "articlegroup-tab-";

const useStyles = makeStyles({
  root: {
    padding: "10px 20px",
    userSelect: "none",
    border: "none",
    flex: "0 0 auto",
    fontSize: "1em",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
  },
  button: { fontWeight: (props) => props.fontWeight },
});

function TabArticlegroup(props) {
  const styles = {
    fontWeight: props.selected ? 800 : 500,
  };
  let style = {};
  if (mynOberStore.isWidescreen && mynOberStore.isHandheld()) {
    style.height = mynOberStore.isHandheld() && mynOberStore.isWidescreen ? 80 : null;
    style.margin = "8px 0";
    style.flex = "1 1";
    if (props.articlegroup.handheld_bg_color) {
      style.backgroundColor = props.articlegroup.handheld_bg_color;
    }
  }

  const classes = useStyles(styles);

  if (mynOberStore.isWidescreen && mynOberStore.isHandheld()) {
    return (
      <Grid item container xs={6}>
        <Button
          color={mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "primary" : "default"}
          className={`${classes.root} ${classes.button}`}
          onClick={props.onTabClicked}
          id={articlegroupTabId + props.index}
          variant={mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "contained" : "text"}
          style={style}
        >
          {props.articlegroup.name}
        </Button>
      </Grid>
    );
  } else {
    return (
      <Button
        color={mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "primary" : "default"}
        className={`${classes.root} ${classes.button}`}
        onClick={props.onTabClicked}
        id={articlegroupTabId + props.index}
        variant={mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "contained" : "text"}
        style={{
          ...style,
          fontFamily: props.theme.articlegroup?.typography?.fontFamily,
          color: props.theme.articlegroup?.palette?.text?.primary,
        }}
      >
        {props.articlegroup.name}
      </Button>
    );
  }
}

TabArticlegroup.propTypes = {
  articlegroup: PropTypes.instanceOf(Articlegroup).isRequired,
  selected: PropTypes.bool.isRequired,
  onTabClicked: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};

export default withTheme(TabArticlegroup);
