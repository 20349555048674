import React, { Component } from "react";
import { matchPath, withRouter } from "react-router";
import handheldStore from "../../../utils/flux/HandheldStore";
import mynOberStore from "../../../utils/flux/MynOberStore";
import * as mynOberActions from "../../../utils/flux/MynOberActions";
import TableOrderContainer from "../../smartphone/TableOrderContainer";
import TopNavigation from "../../../components/web/TopNavigation";
import DrawerMenu, { openDrawer } from "../../../components/drawer_menu/DrawerMenu";
import { ReactComponent as IconOptions } from "../../../../assets/images/ic-menu.svg";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";
import "./TableContainer.css";
import { getDrawerButtons } from "../../../components/floorplan/FloorPlan";
import { DialogContext } from "../../../components/dialogs/DialogProvider";
import DialogOk from "../../../components/dialogs/DialogOk";
import { Bill } from "../../../utils/receipt/Bill";
import { isInDirectSaleMode } from "../../../utils/functions/HelperFunctions";
import { setDirectSaleMode } from "../../../utils/flux/HandheldActions";
import { FormatListNumbered } from "@material-ui/icons";

class TableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getState();
    window.tableContainer = this;
  }

  componentDidMount(): void {
    mynOberStore.on(mynOberStore.events.WEBCODE_CHANGED, this.updateState);
    handheldStore.on(handheldStore.events.RECEIPT_CLOSED, this.redirectToHome);
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.updateState);
  }

  componentWillUnmount(): void {
    mynOberStore.removeListener(mynOberStore.events.WEBCODE_CHANGED, this.updateState);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CLOSED, this.redirectToHome);
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.updateState);
  }

  redirectToHome = (receiptId) => {
    if (this.state.receipt != null && receiptId === this.state.receipt.id) {
      const [openDialog] = this.context;
      openDialog(
        {
          children: (
            <DialogOk
              title={"alert_title_receipt_cleared"}
              content={"alert_text_receipt_cleared"}
              timer={true}
              dialogClosed={() => {
                this.props.history.replace("/" + this.props.match.params["spMerchantId"]);
              }}
              onOkClicked={() => {
                this.props.history.replace("/" + this.props.match.params["spMerchantId"]);
              }}
            />
          ),
        },
        true
      );
      // alertHandler.showReceiptClearedAlert();
    }
    // console.log(receiptId);
    // console.log(this.state.receipt);
    // if (receiptId === this.state.receipt.id) {
    //   this.props.history.goBack();
    // }
  };

  getState = () => {
    let bill = null;
    let tableId = this.props.match.params.tableId;
    let handheldTable = handheldStore.tables[this.props.match.params["spMerchantId"]][tableId];
    let handheldReceipt = null;
    if (handheldTable != null) {
      let handheldReceipt =
        handheldStore.handheldReceipts[
          handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].receiptId
        ];
      // console.log(handheldStore.handheldReceipts[handheldStore.tables[this.props.match.params.tableId].receiptId]);
      if (handheldReceipt != null) {
        bill = new Bill(handheldReceipt.id, handheldReceipt.spMerchantId, handheldReceipt);
        handheldReceipt.handheldOrdergroups.forEach((handheldOrdergroup) => {
          handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
            if (receiptArticle.countOpen > 0) {
              bill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
            }
          });
        });
        // if (mynOberStore.receipt == null || receipt.id !== mynOberStore.receipt.id)
        //   mynOberActions.setReceipt(receipt);
      }
    }
    if (!this.state) {
      setDirectSaleMode(isInDirectSaleMode(this.props.location));
    }

    return {
      table: handheldTable,
      handheldReceipt: handheldReceipt,
      receipt: bill,
    };
  };

  updateState = () => {
    this.setState(this.getState());
  };

  componentWillMount(): void {
    mynOberActions.setWebcode(this.props.match.params.tableId);
  }

  render() {
    let drawerMenuButtons = [];

    Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
      drawerMenuButtons.push({
        stringId: "table_overview",
        subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
        icon: <FormatListNumbered />,
        key: "table_overview-" + spMerchantId,
        onClick: () => {
          this.props.history.replace("/" + spMerchantId);
        },
      });
    });

    if (mynOberStore.merchant != null) {
      drawerMenuButtons = drawerMenuButtons.concat(getDrawerButtons(this.props.history));
    }

    let title = mynOberStore.merchant.name + " - ";

    let match = matchPath(this.props.location.pathname, { path: "/:spMerchantId(\\d+)" });
    if (match && handheldStore.spMerchants[match.params.spMerchantId].salesAreaName != null) {
      title += handheldStore.spMerchants[match.params.spMerchantId].salesAreaName + " - ";
    }

    let tableName = "Table " + mynOberStore.webcode;
    if (mynOberStore.isHandheld()) {
      if (!handheldStore.tables[mynOberStore.merchant.id][mynOberStore.webcode]?.hasQrCode) {
        tableName = "Direct Sale Mode";
      }
    }

    return (
      <div className={"table-container"}>
        <TopNavigation
          iconSrc={<IconOptions />}
          titleId={"button_options"}
          merchantName={title + tableName}
          onClickOptions={openDrawer}
        />
        <DrawerMenu
          buttons={
            drawerMenuButtons
            // {
            //   stringId: "button_pay",
            //   icon: icPay,
            //   enabled: this.state.receipt != null,
            //   onClick: () => {
            //     alertHandler.showPaymentHandheldAlert(this.state.receipt.amountOpen, this.sendPaymentHandheld);
            //   }
            // }
          }
        />
        {/*<p>{this.state.table.name}</p>*/}
        {!mynOberStore.isWidescreen || mynOberStore.webcode != null ? (
          <TableOrderContainer store={mynOberStore} />
        ) : null}
      </div>
    );
  }
}

TableContainer.contextType = DialogContext;

export default withRouter(TableContainer);
