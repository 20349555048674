import React, { Component } from "react";
import { PathNames } from "../routers/PathNames";
import * as mynOberActions from "../utils/flux/MynOberActions";
import ButtonMyn from "../components/ButtonMyn";
import { injectIntl } from "react-intl";
import "./HomePage.css";
import * as listeners from "../utils/Listeners";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import QrReader from "react-qr-reader";
import mynOberStore from "../utils/flux/MynOberStore";
import { getUA, isMobile } from "react-device-detect";
import * as requestFunctions from "../utils/functions/RequestFunctions";
import { ScannerType } from "../containers/smartphone/HomeContainer";
import DialogError from "../components/dialogs/DialogError";
import { DialogContext } from "../components/dialogs/DialogProvider";
import DialogOk from "../components/dialogs/DialogOk";
import Typography from "@material-ui/core/Typography";

const divQRStyle = function () {
  let backgroundImageUrl = 'url("./images/' + window.label.images.folder + '/homepage-qrcode.jpeg")';
  if (window.whiteLabel === "vapiano") {
    backgroundImageUrl = 'url("./vapiano/images/vapiano-background.jpg")';
  }
  if (window.whiteLabel === "jamezz") {
    backgroundImageUrl = 'url("./jamezz/images/webLoginBackground.png")';
  }

  return { backgroundImage: backgroundImageUrl };
};

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.isHandheld = props.isHandheld;
    this.location = props.isHandheld ? props.merchant.name : null;

    this.state = {
      bundleVersionVisible: false,
      webcode: "",
    };

    this.qrUrls = [
      "https://web.mynober.nl/qr/",
      "https://web.mynober.com/qr/",
      "https://web.ibutler.online/qr/",
      "https://web.alfred.com/qr/",
      "https://ayce.jamezz.app/qr/",
    ];
  }

  componentDidMount() {
    if (this.props.isHandheld) {
      listeners.registerHandheldReceiptListeners(this.onMenuCreated);
      mynOberStore.on(mynOberStore.events.RECEIPT_NOT_CLEARED, this.onMenuCreated);
    } else {
      listeners.registerQrStartListeners(this.onMenuCreated);
    }
  }

  componentWillUnmount() {
    if (this.props.isHandheld) {
      listeners.unregisterHandheldReceiptListeners(this.onMenuCreated);
      mynOberStore.removeListener(mynOberStore.events.RECEIPT_NOT_CLEARED, this.onMenuCreated);
    } else {
      listeners.unregisterQrStartListeners(this.onMenuCreated);
    }
  }

  // --------------------------------------------------

  onMenuCreated = (event) => {
    // if (this.props.isHandheld && event != null && event.hasReceipt) {
    //   this.props.history.push(PathNames.RECEIPTPAGE);
    // } else {
    // if (mynOberStore.order.orderType == null) {
    //   this.props.history.push(PathNames.MERCHANTINFOPAGE);
    // } else {
    // console.log("OKE")
    this.props.history.push(PathNames.MENUPAGE);
    // }
    // }
  };

  onClickScanCode = (event) => {
    if (event != null) {
      event.preventDefault();
    }

    if (navigator.mediaDevices) {
      this.props.history.push(PathNames.QRSCANNERPAGE);
    } else {
      this.openImageDialog();
    }
  };

  openImageDialog = () => {
    this.refs.qrReader1.els.input.setAttribute("capture", "environment");
    this.refs.qrReader1.openImageDialog();
  };

  handleScan = (data) => {
    if (data !== null) {
      let webcode = null;
      this.qrUrls.find((qrUrl) => {
        // console.log("...");
        // console.log(data);
        // console.log(qrUrl);
        // console.log("...");
        if (data.substr(0, qrUrl.length) === qrUrl) {
          webcode = data.substr(qrUrl.length);
          return true;
        } else {
          return false;
        }
      });

      if (webcode !== null && webcode !== this.state.webcode) {
        // console.log(webcode);
        this.onWebcodeChange(webcode);
        this.sendWebcode(webcode);
      }
    } else {
      const [openDialog] = this.context;
      openDialog({ children: <DialogError content={data} /> });
    }
  };

  onWebcodeSend = (event) => {
    if (event !== null) {
      event.preventDefault();
    }

    if (this.state.webcode === "") {
      const [openDialog] = this.context;
      openDialog({ children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_no_code"} /> });
      return;
    }

    this.sendWebcode(this.state.webcode);
  };

  onWebcodeChange = (text) => {
    this.setState({ webcode: text });
  };

  sendWebcode = (webcode) => {
    if (mynOberStore.isHandheld()) {
      mynOberActions.setWebcode(webcode);
      requestFunctions.getReceipt(true, webcode).catch((text) => {
        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={text} /> });
      });
    } else {
      if (this.scannerType === ScannerType.MOVE) {
        requestFunctions
          .checkLocation()
          .then((distance) => {
            requestFunctions.sendMoveRequest(webcode, distance).catch((text) => {
              const [openDialog] = this.context;
              openDialog({ children: <DialogError content={text} /> });
            });
          })
          .catch((error) => {
            if (error.code === 1) {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
              });
            } else {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
              });
            }
          });
      } else {
        requestFunctions.sendWebcode(webcode).catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
      }
    }
  };

  // --------------------------------------------------

  render() {
    return (
      <div className={"qr-code-page"} style={divQRStyle()}>
        {this.props.HeaderMyn}
        {this.props.QrCodeForm}
        {this.isHandheld ? (
          <div className={"home-page-text"}>
            <Typography>
              {this.props.intl.formatMessage({ id: "text_logged_in_as" }, { location: this.location })}
            </Typography>
            <ButtonMyn stringId={"button_logout"} onClick={mynOberActions.logoutHandheld} />
          </div>
        ) : (
          <div className={"home-page-bottom"}>
            {isMobile ? <ButtonMyn stringId={"button_scan_qr"} onClick={this.onClickScanCode} /> : null}
            {window.matchMedia("(display-mode: standalone)").matches ||
            getUA.includes("android-webview") ||
            getUA.includes("ios-webview")
              ? null
              : null}
            <QrReader
              ref={"qrReader1"}
              className={"qr-scanner-view-home"}
              onScan={this.handleScan}
              facingMode={"environment"}
              legacyMode={true}
            />
          </div>
        )}
        <Typography
          className={this.state.bundleVersionVisible ? "bundle-version-visible" : "bundle-version-hidden"}
          onClick={() => {
            // console.log("bundle version hit");
            this.setState({ bundleVersionVisible: true });
          }}
        >
          Bundle version - <code>v{global.appVersion}</code>
        </Typography>
      </div>
    );
  }
}

HomePage.propTypes = {
  isHandheld: PropTypes.bool.isRequired,
  store: PropTypes.object.isRequired,
  HeaderMyn: PropTypes.element.isRequired,
  QrCodeForm: PropTypes.element.isRequired,
  onBarCodeRead: PropTypes.func.isRequired,
};

HomePage.contextType = DialogContext;

export default withRouter(injectIntl(HomePage));
