import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import mynOberStore from "../../../utils/flux/MynOberStore";
import { PaymentMethod } from "../../../utils/PaymentMethod";
import { injectIntl } from "react-intl";
import GetCurrency from "../../../utils/functions/GetCurrency";
import NumPad from "../../numpad/components/Number";
import { localStore } from "../../../utils/local_storage/LocalStorage";
import { useDialog } from "../../dialogs/DialogProvider";
import CloseIcon from "@material-ui/icons/Close";
import handheldStore from "../../../utils/flux/HandheldStore";
import { Bill } from "../../../utils/receipt/Bill";
import { ReceiptArticle } from "../../../utils/receipt/ReceiptArticle";

const clearPaymentMethods = () => {
  return mynOberStore.merchant.styling.payment_methods.map((paymentMethod) => {
    let copy = new PaymentMethod();
    Object.assign(copy, paymentMethod);
    copy.amount = 0;
    return copy;
  });
};

const getInitialAmountOpen = (receipt) => {
  let amountOpen = 0;
  if (receipt != null) {
    amountOpen = receipt.amountOpen / 100.0;
  }
  return amountOpen;
};

const euros = (cents) => {
  if (cents != null) {
    return cents / 100.0;
  }
  return cents;
};

function PaymentDialogWithTable(props) {
  const [openDialog, closeDialog] = useDialog();
  const [bill, setBill] = useState(null);

  const [amountOpen, setAmountOpen] = React.useState(0);
  const [paymentMethods, setPaymentMethods] = React.useState(clearPaymentMethods());
  const [numPadValue, setNumPadValue] = React.useState(0);
  const printInhouseBillFromLocalStore = localStore.getItem("print_inhouse_bill") ?? "1";
  const [printInhouseBill, setPrintInhouseBill] = React.useState(printInhouseBillFromLocalStore === "1");

  const billUpdated = () => {
    const newBill = new Bill(
      props.table.receiptId,
      handheldStore.handheldReceipts[props.table.receiptId].spMerchantId,
      handheldStore.handheldReceipts[props.table.receiptId]
    );

    handheldStore.handheldReceipts[props.table.receiptId].handheldOrdergroups.forEach((handheldOrdergroup) => {
      handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
        if (receiptArticle.count > 0) {
          newBill.addReceiptArticleToReceipt(Object.assign(new ReceiptArticle(), receiptArticle));
        }
      });
    });

    if (newBill.amountOpen !== bill?.amountOpen) {
      const newInitAmountOpen = getInitialAmountOpen(newBill);

      const amountInPaymentMethods = paymentMethods.reduce((sum, paymentMethod) => {
        return sum + paymentMethod.amount;
      }, 0);
      setAmountOpen(newInitAmountOpen - amountInPaymentMethods);
      setNumPadValue(newInitAmountOpen - amountInPaymentMethods);

      setBill(newBill);
    }
  };

  useEffect(() => {
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, billUpdated);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, billUpdated);
    handheldStore.on(handheldStore.events.RECEIPT_CLOSED, billUpdated);

    billUpdated();
    return () => {
      handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, billUpdated);
      handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, billUpdated);
      handheldStore.removeListener(handheldStore.events.RECEIPT_CLOSED, billUpdated);
    };
  }, []);

  let paymentButtons = [];
  paymentMethods.forEach((paymentMethod, index) => {
    paymentButtons.push(
      <Grid item key={index}>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"outlined"}
          key={paymentMethod.name}
          onClick={() => {
            // console.log("CLICKED ME");
            // console.log(paymentMethod.name);
            if (paymentMethod.default_amount != null) {
              let paymentAmount = paymentMethod.default_amount / 100.0;

              if (paymentMethod.amount == null) {
                paymentMethod.amount = 0;
              }
              let newAmountOpen = amountOpen;
              paymentMethod.amount += paymentAmount;
              newAmountOpen -= paymentAmount;

              setAmountOpen(newAmountOpen);
              setNumPadValue(newAmountOpen);
              setPaymentMethods([...paymentMethods]);
              return;
            }
            if (paymentMethod.default_percentage != null) {
              let paymentAmount = Number(((amountOpen * paymentMethod.default_percentage) / 100.0).toFixed(2));
              if (paymentAmount > amountOpen) {
                return;
              } else {
                if (paymentMethod.amount == null) {
                  paymentMethod.amount = 0;
                }
                let newAmountOpen = amountOpen;
                paymentMethod.amount += paymentAmount;
                newAmountOpen -= paymentAmount;

                setAmountOpen(newAmountOpen);
                setNumPadValue(newAmountOpen);
                setPaymentMethods([...paymentMethods]);
                return;
              }
            }
            if (paymentMethod.free_percentage != null) {
              let paymentAmount = Number(((amountOpen * Number(numPadValue)) / 100.0).toFixed(2));

              if (paymentMethod.amount == null) {
                paymentMethod.amount = 0;
              }
              let newAmountOpen = amountOpen;
              paymentMethod.amount += paymentAmount;
              newAmountOpen -= paymentAmount;

              setAmountOpen(newAmountOpen);
              setNumPadValue(newAmountOpen);
              setPaymentMethods([...paymentMethods]);
              return;
            }

            let newAmountOpen = amountOpen;

            if (paymentMethod.amount == null) {
              paymentMethod.amount = 0;
            }
            paymentMethod.amount += Number(numPadValue);
            newAmountOpen -= Number(numPadValue);

            setAmountOpen(newAmountOpen);
            setNumPadValue(newAmountOpen);
            setPaymentMethods([...paymentMethods]);
          }}
        >
          {paymentMethod.name + ": " + GetCurrency("€") + Number(paymentMethod.amount).toFixed(2)}
        </Button>
      </Grid>
    );
  });

  return (
    <>
      <div style={{ position: "relative", marginLeft: 8 }}>
        <h4>{props.intl.formatMessage({ id: "button_pay_handheld" })}</h4>

        <IconButton
          style={{ position: "absolute", top: 0, right: 0 }}
          aria-label="close"
          onClick={() => {
            closeDialog();
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent>
        <Typography style={{ textAlign: "center", fontWeight: 800 }}>
          {GetCurrency()} {(bill?.amountOpen / 100.0).toFixed(2)}
        </Typography>
        {bill && (
          <NumPad
            inline={true}
            sync={true}
            onChange={(value) => {
              setNumPadValue(Number(value).toFixed(2));
            }}
            value={amountOpen.toString()}
            decimal={2}
            displayRule={(value) => {
              return Number(value).toFixed(2);
            }}
            theme={{
              header: {
                primaryColor: "#263238",
                secondaryColor: "#f9f9f9",
                highlightColor: "#FFC107",
                backgroundColor: "#607D8B",
              },
              body: {
                primaryColor: "#263238",
                secondaryColor: "#32a5f2",
                highlightColor: "#FFC107",
                backgroundColor: "#f9f9f9",
              },
              panel: {
                backgroundColor: "#CFD8DC",
              },
            }}
          >
            <button>Click me!</button>
          </NumPad>
        )}

        <Grid container direction={"row"} justify={"center"}>
          {paymentButtons}
        </Grid>
      </DialogContent>
      <DialogActions style={{ flexWrap: "wrap", justifyContent: "center" }}>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"outlined"}
          onClick={() => {
            setPaymentMethods(clearPaymentMethods());
            setAmountOpen(euros(bill?.amountOpen) ?? getInitialAmountOpen(props.receipt));
            setNumPadValue(euros(bill?.amountOpen) ?? getInitialAmountOpen(props.receipt));
          }}
        >
          C
        </Button>
        <Button
          style={{ margin: "8px", padding: "15px" }}
          variant={"outlined"}
          onClick={() => {
            if (amountOpen > 0) {
              // openDialog({
              //   children: (
              //     <DialogOkOrCancel
              //       content={props.intl.formatMessage(
              //         { id: "there_is_an_amount_open" },
              //         { amountOpen: amountOpen.toFixed(2), currency: GetCurrency() }
              //       )}
              //       onOkClicked={() => {
              //         let paymentMethodInCents = paymentMethods.map((method) => {
              //           let newMethod = { ...method };
              //           newMethod.amount *= 100.0;
              //           newMethod.amount = Math.round(newMethod.amount);
              //           return newMethod;
              //         });
              //         closeDialog();
              //         props.onPayment(paymentMethodInCents, printInhouseBill);
              //       }}
              //       title={"Are you sure?"}
              //     />
              //   ),
              // });
            } else if (amountOpen === 0) {
              let paymentMethodInCents = paymentMethods.map((method) => {
                let newMethod = { ...method };
                newMethod.amount *= 100.0;
                newMethod.amount = Math.round(newMethod.amount);
                return newMethod;
              });
              closeDialog();
              props.onPayment(paymentMethodInCents, printInhouseBill);
            }
          }}
        >
          OK
        </Button>

        <FormControlLabel
          style={{ margin: "8px", padding: "15px" }}
          label={props.intl.formatMessage({ id: "Print bill" })}
          labelPlacement={"top"}
          control={
            <Checkbox
              color={"primary"}
              checked={printInhouseBill}
              onChange={(e) => {
                localStore.setItem("print_inhouse_bill", e.target.checked ? "1" : "0");
                setPrintInhouseBill(e.target.checked);
              }}
            />
          }
        />
      </DialogActions>
    </>
  );
}

export default injectIntl(PaymentDialogWithTable);
