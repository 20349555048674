import React, { useEffect } from "react";
import withIntl from "../utils/withIntl";
import mynOberStore from "../utils/flux/MynOberStore";
import { format } from "date-fns";
import { OrderTypes } from "../utils/order/Order";
import { getTaPostalCode } from "../utils/merchant/TaPostalCode";
import GetCurrency from "../utils/functions/GetCurrency";
import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { AvTimer, DirectionsBike, DirectionsWalk, InfoOutlined, LocalMall, Lock, WatchLater } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { PathNames } from "../routers/PathNames";
import * as mynOberActions from "../utils/flux/MynOberActions";
import { withRouter } from "react-router";
import DialogTakeawayInfo from "./dialogs/DialogTakeawayInfo";
import { useDialog } from "./dialogs/DialogProvider";
import DialogPostalcodeCheck from "./dialogs/DialogPostalcodeCheck";
import { getApplicationDate } from "../utils/functions/HelperFunctions";

const useStyles = makeStyles(() => ({
  div: {
    display: "flex",
    flexFlow: "wrap",
    margin: "0 16px",
    alignItems: "center",
  },
  text: {
    marginLeft: "8px",
  },
  tooltip: {
    fontSize: "0.9rem",
  },
}));

function TaDeInfoView(props) {
  const classes = useStyles();
  const [openDialog] = useDialog();
  const [orderType, setOrderType] = React.useState(mynOberStore.order.orderType);
  const [taPostalCode, setTaPostalCode] = React.useState(
    mynOberStore.contactDetails.postalCode != null ? getTaPostalCode(mynOberStore.contactDetails.postalCode) : null
  );

  const updateOrderType = () => {
    setOrderType(mynOberStore.order.orderType);
  };

  const updatePostalCode = () => {
    let taPostalCode = getTaPostalCode(mynOberStore.contactDetails.postalCode);
    if (taPostalCode != null) {
      setTaPostalCode(taPostalCode);
    }
  };

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.ORDER_TYPE_CHANGED, updateOrderType);
    mynOberStore.on(mynOberStore.events.CONTACT_DETAILS_UPDATED, updatePostalCode);
    return () => {
      mynOberStore.removeListener(mynOberStore.events.ORDER_TYPE_CHANGED, updateOrderType);
      mynOberStore.removeListener(mynOberStore.events.CONTACT_DETAILS_UPDATED, updatePostalCode);
    };
  }, [updateOrderType, updatePostalCode]);

  const taMerchant = mynOberStore.merchant;

  const showOpeningHours = () => {
    let now = getApplicationDate();
    let openinghours = null;

    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        openinghours = taMerchant.openingHours;
        break;
      case OrderTypes.DELIVERY:
        openinghours = taMerchant.deliveryTimes ? taMerchant.deliveryTimes : taMerchant.openingHours;
        break;
      default:
        return "";
    }

    let dayOfWeek = now.getDay();

    let startOpeningTimes = [];
    let endOpeningTimes = [];

    dayLoop: for (let i = 0; i < 7; i++) {
      if (startOpeningTimes.length === 0 && i > 0) {
        // Closed today
        break;
      }
      let openingHoursDay = openinghours[(dayOfWeek + i) % 7];
      if (!openingHoursDay) {
        break;
      }

      for (let k = 0; k < openingHoursDay.length; k++) {
        let openingHoursRange = openingHoursDay[k];
        let openingTime = new Date(openingHoursRange.openingTime);
        let closingTime = new Date(openingHoursRange.closingTime);

        if (
          endOpeningTimes.length > 0 &&
          openingTime.getTime() === endOpeningTimes[endOpeningTimes.length - 1].getTime()
        ) {
          endOpeningTimes[endOpeningTimes.length - 1] = closingTime;
        } else if (startOpeningTimes.length > 0 && i > 0) {
          break dayLoop;
        }

        if (
          (i === 0 && startOpeningTimes.length > 0) ||
          ((openingTime.getTime() <= now.getTime() || i === 0) && closingTime.getTime() > now.getTime())
        ) {
          // if (startOpeningTimes) {
          startOpeningTimes.push(openingTime);
          endOpeningTimes.push(closingTime);
          // }
        }
      }
    }
    if (startOpeningTimes.length > 0) {
      return (
        <Tooltip
          title={props.intl.formatMessage({
            id: orderType === OrderTypes.TAKEAWAY ? "text_takeaway_times_today" : "text_delivery_times_today",
          })}
          placement={"top"}
          classes={{ tooltip: classes.tooltip }}
        >
          <div className={classes.div}>
            <WatchLater />
            <div>
              {startOpeningTimes.map((startOpeningTime, index) => {
                const endOpeningTime = endOpeningTimes[index];
                let hoursString = format(startOpeningTime, "HH:mm") + " - " + format(endOpeningTime, "HH:mm");
                return (
                  <Typography key={hoursString} classes={{ root: classes.text }}>
                    {hoursString}
                  </Typography>
                );
              })}
            </div>
          </div>
        </Tooltip>
      );
    } else {
      return (
        <div className={classes.div}>
          <Lock />
          <Typography classes={{ root: classes.text }}>
            {props.intl.formatMessage({ id: "text_today_closed" })}
          </Typography>
        </div>
      );
    }
  };

  const minAmount = () => {
    switch (orderType) {
      case OrderTypes.TAKEAWAY:
        return taMerchant.minAmount;
      case OrderTypes.DELIVERY:
        if (taPostalCode) {
          return taPostalCode.minAmount;
        } else {
          return taMerchant.minAmount;
        }
      default:
        return 0;
    }
  };

  const deliveryCosts = () => {
    switch (orderType) {
      case OrderTypes.DELIVERY:
        if (taPostalCode) {
          return taPostalCode.extraCosts;
        }
        break;
      default:
        return 0;
    }
    return 0;
  };

  const minPickupTime = () => {
    switch (orderType) {
      case OrderTypes.DELIVERY:
        return taMerchant.deMinimumPickupMinutes;
      case OrderTypes.TAKEAWAY:
        return taMerchant.taMinimumPickupMinutes;
    }
    return 0;
  };

  const convertAmountToString = (amount) => GetCurrency() + " " + parseFloat(amount / 100.0).toFixed(2);

  const deliveryCostsString = () => {
    const amount = deliveryCosts();
    if (amount === 0) {
      return;
    }
    return (
      <Tooltip
        title={props.intl.formatMessage({ id: "text_delivery_costs_for" }, { postalCode: taPostalCode.postalCode })}
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.div}>
          <DirectionsBike />
          <Typography classes={{ root: classes.text }}>{convertAmountToString(amount)}</Typography>
        </div>
      </Tooltip>
    );
  };

  const minAmountString = () => {
    const amount = minAmount();
    if (amount === 0) {
      return;
    }
    return (
      <Tooltip
        title={props.intl.formatMessage({
          id:
            orderType === OrderTypes.TAKEAWAY
              ? "text_min_takeaway_ordering_amount"
              : "text_min_delivery_ordering_amount",
        })}
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.div}>
          <LocalMall />
          <Typography classes={{ root: classes.text }}>{convertAmountToString(amount)}</Typography>
        </div>
      </Tooltip>
    );
  };

  const minPickupString = () => {
    const number = minPickupTime();
    if (number === 0) {
      return;
    }
    return (
      <Tooltip
        title={props.intl.formatMessage({ id: "text_min_wait_time" })}
        placement={"top"}
        classes={{ tooltip: classes.tooltip }}
      >
        <div className={classes.div}>
          <AvTimer />
          <Typography classes={{ root: classes.text }}>{number} min</Typography>
        </div>
      </Tooltip>
    );
  };

  if (orderType == null) {
    return <div></div>;
  }

  const getChangeOrderButton = () => {
    if (
      mynOberStore.merchant.takeaway &&
      mynOberStore.merchant.takeawayActive &&
      mynOberStore.merchant.delivery &&
      mynOberStore.merchant.deliveryActive
    ) {
      if (orderType === OrderTypes.DELIVERY) {
        return (
          <Tooltip
            title={props.intl.formatMessage({ id: "text_pick_up_anyway" })}
            placement={"top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              style={{ margin: "10px" }}
              variant={"outlined"}
              onClick={() => {
                if (props.location.pathname === PathNames.DELIVERYPAGE) {
                  props.history.replace(PathNames.TAKEAWAYPAGE);
                }
                mynOberActions.setOrderType(OrderTypes.TAKEAWAY);
              }}
            >
              <DirectionsWalk />
            </IconButton>
          </Tooltip>
        );
      } else if (orderType === OrderTypes.TAKEAWAY) {
        return (
          <Tooltip
            title={props.intl.formatMessage({ id: "text_deliver_anyway" })}
            placement={"top"}
            classes={{ tooltip: classes.tooltip }}
          >
            <IconButton
              style={{ margin: "10px" }}
              variant={"outlined"}
              onClick={() => {
                if (getTaPostalCode(mynOberStore.contactDetails.postalCode) == null) {
                  openDialog({
                    children: (
                      <DialogPostalcodeCheck
                        onPostalcode={(postalcode) => {
                          mynOberActions.setContactDetails({ postalCode: postalcode });
                        }}
                      />
                    ),
                  });
                }
                if (props.location.pathname === PathNames.TAKEAWAYPAGE) {
                  props.history.replace(PathNames.DELIVERYPAGE);
                }
                mynOberActions.setOrderType(OrderTypes.DELIVERY);
              }}
            >
              <DirectionsBike />
            </IconButton>
          </Tooltip>
        );
      }
    }
  };

  return (
    <div style={{ display: "flex", flexFlow: "wrap", marginTop: mynOberStore.isWidescreen ? 0 : 4 }}>
      {showOpeningHours()}
      {deliveryCostsString()}
      {minAmountString()}
      {minPickupString()}
      {getChangeOrderButton()}
      {!props.hideInfoButton && (
        <div className={classes.div}>
          <IconButton
            onClick={() => {
              openDialog({ children: <DialogTakeawayInfo /> });
            }}
          >
            <InfoOutlined />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default withRouter(withIntl(TaDeInfoView));
