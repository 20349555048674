import { Modifiergroup } from "./Modifiergroup";
import { Modifierarticle } from "./Modifierarticle";
import { Allergen } from "./Allergen";
import { Article } from "./Article";
import mynOberStore from "../flux/MynOberStore";

export class Menu {
  getMenuTags() {
    if (!this.menuTags) {
      this.menuTags = {};
      this.articles.forEach((article) => {
        // this.menuTags[article.menuTags]

        if (article.menuTags != null && Array.isArray(article.menuTags)) {
          article.menuTags.forEach((menuTag) => {
            this.menuTags[menuTag] = true;
          });
        }
      });
    }

    return this.menuTags;
  }

  getArticlegroupsMapId() {
    if (!this.articlegroupsMapId) {
      this.articlegroupsMapId = {};
      this.articlegroups.forEach((articlegroup) => {
        this.articlegroupsMapId[articlegroup.id] = articlegroup;
      });
    }

    return this.articlegroupsMapId;
  }

  getArticlesMapId() {
    if (!this.articlesMapId) {
      this.articlesMapId = {};
      this.articles.forEach((article) => {
        this.articlesMapId[article.id] = article;
      });
    }

    return this.articlesMapId;
  }

  getArticlesMapPlu() {
    if (!this.articlesMapPlu) {
      this.articlesMapPlu = {};
      this.articles.forEach((article) => {
        this.articlesMapPlu[article.plu] = article;
      });
    }

    return this.articlesMapPlu;
  }

  getArticlesMapShortKey() {
    if (!this.articlesMapShortKey) {
      this.articlesMapShortKey = {};
      this.articles.forEach((article) => {
        this.articlesMapShortKey[article.shortKey] = article;
      });
    }

    return this.articlesMapShortKey;
  }

  getModifiergroupsMapId() {
    if (!this.modifiergroupsMapId) {
      this.modifiergroupsMapId = {};
      this.modifiergroups.forEach((modifiergroup) => {
        this.modifiergroupsMapId[modifiergroup.id] = modifiergroup;
      });
    }

    return this.modifiergroupsMapId;
  }

  getModifierarticlesMapId() {
    if (!this.modifierarticlesMapId) {
      this.modifierarticlesMapId = {};
      this.modifierarticles.forEach((modifierarticle) => {
        this.modifierarticlesMapId[modifierarticle.id] = modifierarticle;
      });
    }

    return this.modifierarticlesMapId;
  }

  constructor(allergens, articlegroups, articles, modifierarticles, modifiergroups, serviceArticles, supergroups) {
    this.allergens = allergens;

    this.originalArticlegroups = articlegroups;
    this.articlegroups = articlegroups;
    this.originalArticles = articles;
    this.articles = articles;
    this.modifierarticles = modifierarticles;
    this.modifiergroups = modifiergroups;
    this.serviceArticles = serviceArticles;

    this.supergroups = supergroups;

    this.getArticlesMapId();
    this.getArticlesMapPlu();
    this.getModifiergroupsMapId();
    this.getModifierarticlesMapId();
    let map = this.getArticlegroupsMapId();
    articles.forEach((article) => {
      if (article.isActive) {
        let articlegroup = map[article.articlegroupId];
        articlegroup.articleIds.push(article.id);
        articlegroup.articles.push(article);
      }
    });
  }

  filter = (searchValue) => {
    if (searchValue == null || searchValue === "") {
      this.articles = this.originalArticles;
      this.articlegroups = this.originalArticlegroups;
    } else {
      searchValue = searchValue.toUpperCase();
      let articlegroupIds = [];
      let articlegroups = this.originalArticlegroups.filter((articlegroup) => {
        if (articlegroup?.name?.toUpperCase().includes(searchValue)) {
          articlegroupIds.push(articlegroup.id);
          return true;
        }
        return false;
      });

      this.articles = this.originalArticles.filter((article) => {
        for (let j = 0; j < articlegroups.length; j++) {
          if (articlegroups[j].id === article.articlegroupId) {
            return true;
          }
        }

        if (article.name.toUpperCase().includes(searchValue) || article.description?.toUpperCase().includes(searchValue)) {
          let articlegroupIdFound = false;
          for (let i = 0; i < articlegroupIds.length; i++) {
            if (article.articlegroupId === articlegroupIds[i]) {
              articlegroupIdFound = true;
              break;
            }
          }
          if (!articlegroupIdFound) {
            articlegroupIds.push(article.articlegroupId);
          }

          return true;
        }
        return false;
      });

      this.articlegroups = [];
      this.originalArticlegroups.forEach((articlegroup) => {
        let articlegroupIdFound = false;
        for (let i = 0; i < articlegroupIds.length; i++) {
          if (articlegroup.id === articlegroupIds[i]) {
            articlegroupIdFound = true;
            break;
          }
        }
        if (articlegroupIdFound) {
          this.articlegroups.push(articlegroup);
        }
      });
    }
  };

  /**
   *
   * @param {number} allergenId
   * @return {Allergen|null}
   */
  getAllergenById(allergenId) {
    let index = this.getAllergenIndexById(allergenId);
    if (index !== -1) {
      let allergen = this.allergens[index];
      return Object.assign(new Allergen(), allergen);
    } else {
      return null;
    }
  }

  /**
   *
   * @param {number} allergenId
   * @return {number}
   */
  getAllergenIndexById(allergenId) {
    for (let i = 0; i < this.allergens.length; i++) {
      if (this.allergens[i].id === allergenId) {
        return i;
      }
    }

    return -1;
  }

  /**
   *
   * @param {number[]} allergenIds
   * @return {Allergen[]}
   */
  getAllergensByAllergenIds(allergenIds) {
    if (!(allergenIds instanceof Array)) {
      throw new TypeError("allergenIds should be an Array.");
    }
    if (allergenIds.some(isNaN)) {
      throw new TypeError("allergenIds should only contain numbers.");
    }

    let allergens = [];
    let self = this;
    allergenIds.forEach((allergenId) => {
      let allergen = self.getAllergenById(allergenId);
      if (allergen !== null) {
        allergens.push(allergen);
      }
    });

    return allergens;
  }

  /**
   *
   * @param {number} id
   * @return {Article|null}
   */
  getArticleById(id) {
    return this.getArticlesMapId()[id];
  }

  /**
   *
   * @param {number} id
   * @return {Article|null}
   */
  getArticleByPlu(plu) {
    let article = this.getArticlesMapPlu()[plu];
    if (article) {
      return Object.assign(new Article(), article); // TODO WTF
    } else {
      return null;
    }
  }

  /**
   *
   * @return {Article|null}
   */
  getArticleByShortKey(shortKey) {
    let article = this.getArticlesMapShortKey()[shortKey];
    if (article) {
      return Object.assign(new Article(), article); // TODO WTF
    } else {
      return null;
    }
  }

  /**
   *
   * @param {number} id
   * @return {Modifierarticle|null}
   */
  getModifierarticleById(id) {
    let modifierarticle = this.getModifierarticlesMapId()[id];
    if (modifierarticle) {
      return Object.assign(new Modifierarticle(), modifierarticle);
    }
  }

  /**
   *
   * @param {number} modifierarticleIds
   * @return {Modifierarticle[]}
   */
  getModifierarticlesByModifierarticleIds(modifierarticleIds) {
    if (!(modifierarticleIds instanceof Array)) {
      throw new TypeError("modifierarticleIds should be an Array.");
    }
    if (modifierarticleIds.some(isNaN)) {
      throw new TypeError("modifierarticleIds should only contain numbers.");
    }

    let modifierarticles = [];

    modifierarticleIds.forEach((modifierarticleId) => {
      let modifierarticle = this.getModifierarticleById(modifierarticleId);
      if (modifierarticle !== null) {
        if (!modifierarticle.handheldOnly || mynOberStore.isHandheld()) {
          modifierarticles.push(modifierarticle);
        }
      }
    });

    modifierarticles.sort((a, b) => {
      if (a.priority > b.priority) {
        return -1;
      }
      if (a.priority < b.priority) {
        return 1;
      }
      return 0;
    });

    return modifierarticles;
  }

  /**
   *
   * @param {number} id
   * @return {Modifiergroup|null}
   */
  getModifiergroupById(id) {
    let modifiergroup = this.getModifiergroupsMapId()[id];
    if (modifiergroup) {
      return Object.assign(new Modifiergroup(), modifiergroup);
    } else {
      return null;
    }
  }

  /**
   *
   * @param Number[] modifiergroupIds
   * @return {Modifiergroup[]}
   */
  getModifiergroupsByModifiergroupIds(modifiergroupIds) {
    let modifiergroups = [];
    modifiergroupIds.forEach((modifiergroupId) => {
      let modifiergroup = this.getModifiergroupById(modifiergroupId);
      if (modifiergroup !== null) {
        modifiergroups.push(modifiergroup);
      }
    });

    modifiergroups.sort(function (a, b) {
      if (a.priority > b.priority) {
        return -1;
      }
      if (a.priority < b.priority) {
        return 1;
      }

      return 0;
    });

    return modifiergroups;
  }

  /**
   * Returns modifiergroups with modifiergroup.modifierarticles for given modifiergroupIds.
   * @param modifiergroupIds
   * @return {Modifiergroup[]}
   */
  getModifiergroupsWithModifierarticlesByModifiergroupIds(modifiergroupIds) {
    if (modifiergroupIds.length === 0) {
      return [];
    }

    let modifiergroups = this.getModifiergroupsByModifiergroupIds(modifiergroupIds);

    modifiergroups.forEach((modifiergroup) => {
      modifiergroup.modifierarticles = this.getModifierarticlesByModifierarticleIds(modifiergroup.modifierarticleIds);
    });

    return modifiergroups;
  }
}
