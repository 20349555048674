export class DeliveryOrder {
  /**
   *
   */
  constructor(deliveryTime, paymentMethod, acceptMarketing, deliveryCosts, remark, tip) {
    this.deliveryTime = deliveryTime;
    this.paymentMethod = paymentMethod;
    this.acceptMarketing = acceptMarketing;
    this.deliveryCosts = deliveryCosts;
    this.remark = remark;
    this.tip = tip;
  }
}
