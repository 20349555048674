import React from "react";
import { injectIntl } from "react-intl";
import icMenu from "./../../../../assets/images/bottombar/ic-menu.svg";
import icMenuActive from "./../../../../assets/images/bottombar/ic-menu-active.svg";
import BottomTab from "./BottomTab";

function MenuBottomTab(props) {
  return (
    <BottomTab
      icon={icMenu}
      activeIcon={icMenuActive}
      onClick={() => props.onClick()}
      selected={props.selected}
      badge={null}
      title={props.intl.formatMessage({ id: "tab_menu" })}
    />
  );
}

export default injectIntl(MenuBottomTab);
