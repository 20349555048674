import React, { Component } from "react";
import MenuPage from "../../pages/MenuPage";
import ViewArticlegroup, { articlegroupViewId } from "../../components/menu/ViewArticlegroup";
import HeaderMerchant from "../../components/HeaderMerchant";
import PropTypes from "prop-types";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { OrderArticle } from "../../utils/order/OrderArticle";
import mynOberStore from "../../utils/flux/MynOberStore";
import handheldStore from "../../utils/flux/HandheldStore";
import TabArticlegroup, { articlegroupTabId } from "../../components/web/TabArticlegroup";
import { stickyHeaderId } from "./TableOrderContainer";
import scrollIntoView from "scroll-into-view";
import { getApplicationDate, getValue } from "../../utils/functions/HelperFunctions";
import ViewMenuArticle from "../../components/menu/ViewMenuArticle";
import ViewMenuArticleDetailed from "../../components/menu/ViewMenuArticleDetailed";
import StickyHeaderMenu from "../../components/web/StickyHeaderMenu";
import FreeTPArticleModal from "../../components/dialogs/FreeTPArticleModal";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogOk from "../../components/dialogs/DialogOk";
import ViewArticlegroupHandheld from "../../components/menu/ViewArticlegroupHandheld";
import TaDeInfoView from "../../components/TaDeInfoView";
import { TaMerchant } from "../../utils/merchant/TaMerchant";
import { OrderModifiergroup } from "../../utils/order/OrderModifiergroup";
import { OrderModifierarticle } from "../../utils/order/OrderModifierarticle";
import ViewArticleHandheld from "../../components/menu/ViewArticleHandheld";
import { withRouter } from "react-router-dom";
import DialogGuestRegistration from "../../components/dialogs/DialogGuestRegistration";
import { SpMerchant } from "../../utils/merchant/SpMerchant";
import SpInfoView from "../../components/SpInfoView";
import { localStore } from "../../utils/local_storage/LocalStorage";
import { differenceInHours } from "date-fns";
import MynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import GetCurrency from "../../utils/functions/GetCurrency";
import withIntl from "../../utils/withIntl";

class MenuContainerV2 extends Component {
  constructor(props) {
    super(props);

    let { articles, supergroups } = this.processArticlesForModifierPricesAndAmount(null, 0);

    this.uriMerchantImage = mynOberStore.merchant.imageURL;
    this.isHandheld = mynOberStore.isHandheld();

    this.state = {
      supergroups,
      articles,
      articlegroups: mynOberStore.menu.articlegroups,
      selectedArticleIndex: null,
      selectedArticle: null,
      selectedArticlegroupTabIndex: 0,
      selectedSupergroupIndex: 0,
      filter: "",
      articleViewNodes: articles.map(() => {
        return React.createRef();
      }),
      viewArticlegroupsNodes: mynOberStore.menu.articlegroups.map(() => {
        return React.createRef();
      }),
      tabArticlegroupsNodes: mynOberStore.menu.articlegroups.map(() => {
        return React.createRef();
      }),
      selectedArticlegroupId: null,
    };

    this.createViews(articles);

    window.menuContainer = this;
  }

  addAllArticles = () => {
    this.state.articles.forEach((article) => {
      if (
        mynOberStore.menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(article.modifiergroupIds).length === 0
      ) {
        mynOberActions.addOrderArticle(new OrderArticle(article, 1, []));
      } else {
        let modifiergroups = mynOberStore.menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(
          article.modifiergroupIds
        );
        let orderModifiergroups = [];
        modifiergroups.forEach((modifiergroup) => {
          if (!modifiergroup.optional && modifiergroup.modifierarticles.length > 0) {
            orderModifiergroups.push(
              new OrderModifiergroup(modifiergroup, [new OrderModifierarticle(modifiergroup.modifierarticles[0], 1)])
            );
          }
        });
        mynOberActions.addOrderArticle(new OrderArticle(article, 1, orderModifiergroups));
      }
    });
  };

  componentDidMount() {
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED, this.onOrderChanged);
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, this.onMerchantUpdated);
    mynOberStore.on(mynOberStore.events.MERCHANT_CREATED, this.updateMenu);
    mynOberStore.on(mynOberStore.events.MENU_CREATED, this.updateMenu);
    if (!mynOberStore.isHandheld()) {
      mynOberStore.on(mynOberStore.events.WRONG_QR_PINCODE, this.updateMenu);
    }
    mynOberStore.on(mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS, this.orderChangedFromSharedOrders);

    if (!mynOberStore.isHandheld() && mynOberStore.merchant.guestRegistration) {
      let hasValue =
        mynOberStore.merchant.guestRegistration.title != null && mynOberStore.merchant.guestRegistration.title !== "";

      if (!hasValue) {
        hasValue =
          mynOberStore.merchant.guestRegistration.content != null &&
          mynOberStore.merchant.guestRegistration.content !== "";
      }
      if (!hasValue) {
        hasValue = mynOberStore.merchant.guestRegistration.custom_fields != null;
        if (hasValue) {
          if (Array.isArray(mynOberStore.merchant.guestRegistration.custom_fields)) {
            if (!mynOberStore.merchant.guestRegistration.custom_fields.length > 0) {
              hasValue = false;
            }
          } else {
            hasValue = false;
          }
        }
      }

      if (hasValue) {
        const guestRegistrations = JSON.parse(localStore.getItem("guest_registrations")) ?? {};
        if (
          !guestRegistrations[mynOberStore.webcode] ||
          differenceInHours(getApplicationDate(), new Date(guestRegistrations[mynOberStore.webcode])) > 12
        ) {
          const [openDialog] = this.context;
          openDialog({
            children: <DialogGuestRegistration guestRegistration={mynOberStore.merchant.guestRegistration} />,
            disableBackdropClick: true,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    mynOberStore.removeListener(mynOberStore.events.ORDER_CHANGED, this.onOrderChanged);
    mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, this.onMerchantUpdated);
    mynOberStore.removeListener(
      mynOberStore.events.ORDER_CHANGED_FROM_SHARED_ORDERS,
      this.orderChangedFromSharedOrders
    );
    mynOberStore.removeListener(mynOberStore.events.MERCHANT_CREATED, this.updateMenu);
    mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, this.updateMenu);
    if (!mynOberStore.isHandheld()) {
      mynOberStore.removeListener(mynOberStore.events.WRONG_QR_PINCODE, this.updateMenu);
    }
  }

  updateMenu = (merchantId, newFilter, selectedSupergroupIndex = null) => {
    let newSelectedSupergroupIndex = selectedSupergroupIndex;
    if (newSelectedSupergroupIndex == null) {
      newSelectedSupergroupIndex = this.state.selectedSupergroupIndex ?? 0;
    }
    let { articles, supergroups } = this.processArticlesForModifierPricesAndAmount(newFilter, selectedSupergroupIndex);
    if (newSelectedSupergroupIndex !== this.state.selectedSupergroupIndex) {
      let element = document.getElementById(articlegroupViewId + 0);
      if (element) {
        let topOffset = 0;
        let stickyHeader = document.getElementById(stickyHeaderId);
        if (stickyHeader != null) {
          topOffset += stickyHeader.getBoundingClientRect().height;
        }
        scrollIntoView(element, {
          time: 1000,
          align: {
            top: 0,
            topOffset: topOffset, // margin
          },
          validTarget: (target, parentScrolled) => {
            return target.id === "menu-container"; //&& target === window;
          },
        });
      }
    }
    this.setState({
      articles: articles,
      supergroups,
      selectedSupergroupIndex: newSelectedSupergroupIndex,
      articleViewNodes: articles.map(() => {
        return React.createRef();
      }),
    });

    this.createViews(articles);
  };

  orderChangedFromSharedOrders = () => {
    this.onOrderChanged(false);
  };

  onChangeFilter = (newFilter) => {
    this.setState({ filter: newFilter });
    this.updateMenu(null, newFilter);
  };

  processArticlesForModifierPricesAndAmount = (newFilter, selectedSupergroupIndex) => {
    if (mynOberStore.menu == null || mynOberStore.isInvalidPincode || mynOberStore.dialogPincodeIsOpen) {
      return { articles: [], supergroups: [] };
    }
    mynOberStore.menu.filter(newFilter);

    let articles = mynOberStore.menu.articles;

    let menuTags = null;
    if (
      (!mynOberStore.isHandheld() || this.props.location.search.includes("directsalemode=1")) &&
      mynOberStore.menuTagsWithLag != null
    ) {
      menuTags = mynOberStore.menuTagsWithLag;
    }
    if (
      mynOberStore.isHandheld() &&
      this.props.location.search.includes("directsalemode=1") &&
      menuTags != null &&
      menuTags.length === 0
    ) {
      menuTags = null;
    }

    articles = articles.filter((article) => {
      return (
        (article.type === "ARTICLE" || (article.type === "FREETPARTICLE" && mynOberStore.isHandheld())) &&
        article.isActive &&
        (article.isInOpeningHours || mynOberStore.isHandheld()) &&
        (mynOberStore.isHandheld() || !article.isMenu)
      );
    });

    if (
      mynOberStore.isHandheld() &&
      handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId] != null &&
      handheldStore.handheldReceipts[
        handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].receiptId
      ] != null
    ) {
      menuTags =
        handheldStore.handheldReceipts[
          handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].receiptId
        ].menuTags;
    }
    if (
      mynOberStore.isHandheld() &&
      handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId] != null
    ) {
      if (menuTags == null) {
        menuTags = [];
      }
      if (handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].menuTags) {
        menuTags = menuTags.concat(
          handheldStore.tables[this.props.match.params["spMerchantId"]][this.props.match.params.tableId].menuTags
        );
      }
    }
    if (
      (!mynOberStore.isHandheld() ||
        (mynOberStore.user.handheldUser != null && mynOberStore.user.handheldUser.use_menu_tags)) &&
      menuTags != null
    ) {
      articles = articles.filter((article) => {
        if (article.menuTags.length === 0) {
          return true;
        }
        return menuTags.some((activatedMenuTag) => {
          return article.menuTags.some((articleMenuTag) => {
            return articleMenuTag === activatedMenuTag;
          });
        });
      });
    }

    articles.forEach((article) => {
      let hasModifiergroupsWithPrice = false;

      let modifiergroups = mynOberStore.menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(
        article.modifiergroupIds
      );
      modifiergroups.sort((a, b) => {
        if (a.priority > b.priority) {
          return -1;
        }
        if (a.priority < b.priority) {
          return 1;
        }

        return 0;
      });
      for (let i = 0; i < modifiergroups.length; i++) {
        for (let j = 0; j < modifiergroups[i].modifierarticles.length; j++) {
          if (modifiergroups[i].modifierarticles[j].price !== 0) {
            hasModifiergroupsWithPrice = true;
            break;
          }
        }

        if (hasModifiergroupsWithPrice) {
          break;
        }
      }

      article.count = mynOberStore.order.getCountByArticleId(article.id);
      article.hasModifiergroupsWithPrice = hasModifiergroupsWithPrice;
    });

    const supergroups = mynOberStore.menu.supergroups?.filter((supergroup) => {
      return articles.some((article) => article.getSupergroupTags().includes(supergroup.tag));
    });

    let selectedSupergroup = supergroups[selectedSupergroupIndex ?? this.state?.selectedSupergroupIndex ?? 0];

    articles = articles.filter((article) => {
      if (selectedSupergroup) {
        if (!article.getSupergroupTags().includes(selectedSupergroup.tag)) {
          return false;
        }
      }
      return true;
    });

    return { articles, supergroups };
  };

  createViews = (articles) => {
    this.articlegroupTabs = this.createArticlegroupTabs(mynOberStore.menu.articlegroups, articles);
    this.articleViews = this.createArticleViews(articles);
    this.articlegroupViews = this.createArticlegroupViews(this.articleViews);
  };

  createArticlegroupTabs = (articlegroups, articles) => {
    let articlegroupTabs = [];
    let i = 0;
    articlegroups.forEach((articlegroup) => {
      let isArticlegroupEmpty = null;
      if (articlegroup.articleIds != null) {
        isArticlegroupEmpty = !articlegroup.articleIds.some(
          (articleId) =>
            articles.findIndex((article) => {
              return article.id === articleId;
            }) >= 0
        );
      } else {
        isArticlegroupEmpty =
          articles.findIndex((article) => {
            return article.articlegroupId === articlegroup.id;
          }) === -1;
      }
      if (!isArticlegroupEmpty) {
        let j = i;
        articlegroupTabs.push(
          <TabArticlegroup
            ref={this.state.tabArticlegroupsNodes[j]}
            key={j}
            articlegroup={articlegroup}
            selected={articlegroup.id === this.state.selectedArticlegroupId}
            index={j}
            onTabClicked={() => this.onClickArticlegroupTab(j, articlegroup.id)}
          />
        );
        i++;
      }
    });
    return articlegroupTabs;
  };

  createArticleViews = (articles) => {
    const articleViews = {};
    articles.forEach((article, i) => {
      let enabled = true;
      if (!mynOberStore.isAYCEEnabled() && article.weight !== null && article.weight !== undefined) {
        enabled = false;
      }
      if (!mynOberStore.isHandheld()) {
        articleViews[article.id] = (
          <ViewMenuArticle
            onClickAdd={() => {
              this.onArticleClicked(article.id, i, 1);
            }}
            isInDialog={false}
            key={article.id}
            article={article}
            onArticleClicked={() => {
              this.onArticleClicked(article.id, i);
            }}
            enabled={enabled}
          />
        );
      } else {
        articleViews[article.id] = (
          <ViewArticleHandheld
            ref={this.state.articleViewNodes[i]}
            key={article.id}
            article={article}
            onArticleClicked={() => {
              this.onArticleClicked(article.id, i);
            }}
            enabled={enabled}
            selected={false}
          />
        );
      }
    });
    return articleViews;
  };

  createArticlegroupViews = (articleViews) => {
    let articleGroupViews = [];

    let i = 0;

    let sideMenu = mynOberStore.isWidescreen && mynOberStore.isHandheld();
    let articlegroups = this.state.articlegroups;
    if (sideMenu) {
      let articlegroup = articlegroups.find((articlegroup) => articlegroup.id === this.state.selectedArticlegroupId);
      if (articlegroup) {
        articlegroups = [articlegroup];
      } else {
        articlegroups = [];
      }
    }
    articlegroups.forEach((articlegroup) => {
      let articleViewsList = [];
      if (articlegroup.articleIds != null) {
        articlegroup.articleIds.forEach((id) => {
          if (articleViews[id]) {
            articleViewsList.push(articleViews[id]);
          }
        });

        let index = this.state.articles.length;

        articleViewsList = articleViewsList.map((articleView) => {
          let newIndex = index;

          this.state.articleViewNodes.push(React.createRef());

          let viewArticle = null;
          if (!mynOberStore.isHandheld()) {
            viewArticle = (
              <ViewMenuArticle
                onClickAdd={(e) => {
                  e.stopPropagation();
                  this.onArticleClicked(articleView.props.article.id, i, 1);
                }}
                isInDialog={false}
                key={"copy-" + articleView.props.article.id}
                article={articleView.props.article}
                onArticleClicked={() => {
                  let index = this.state.articles.findIndex((article) => article.id === articleView.props.article.id);
                  this.onArticleClicked(articleView.props.article.id, index);
                }}
                enabled={articleView.props.enabled}
              />
            );
          } else {
            viewArticle = (
              <ViewArticleHandheld
                ref={this.state.articleViewNodes[newIndex]}
                key={"copy-" + articleView.props.article.id}
                article={articleView.props.article}
                onArticleClicked={() => {
                  this.onArticleClicked(articleView.props.article.id, index);
                }}
                enabled={articleView.props.enabled}
                selected={false}
              />
            );
          }
          index++;
          return viewArticle;
        });
      } else {
        articleViewsList = Object.keys(articleViews).reduce((articleViews, articleId) => {
          if (articleViews[articleId].props.article.articlegroupId === articlegroup.id) {
            articleViews.push(articleViews[articleId]);
          }
          return articleViews;
        }, []);
      }
      // articles = articles.filter((article) => {
      //   return !articlesList.some((article2) => {
      //     return article === article2
      //   });
      // });
      if (articleViewsList.length > 0) {
        if (!mynOberStore.isHandheld()) {
          articleGroupViews.push(
            <ViewArticlegroup
              tabLabel={articlegroup.name}
              key={i}
              ref={this.state.viewArticlegroupsNodes[i]}
              index={i}
              name={articlegroup.name}
              articles={articleViewsList}
              onlyOneColumn={false}
            />
          );
        } else {
          articleGroupViews.push(
            <ViewArticlegroupHandheld
              tabLabel={articlegroup.name}
              key={i}
              ref={this.state.viewArticlegroupsNodes[i]}
              index={i}
              name={articlegroup.name}
              articles={articleViewsList}
              onlyOneColumn={false}
            />
          );
        }
        i++;
      }
    });
    return articleGroupViews;
  };

  onArticleClicked = (articleId, index, count = null) => {
    const [openDialog] = this.context;
    let article = mynOberStore.menu.getArticleById(articleId);
    if (!mynOberStore.isAYCEEnabled()) {
      if (article.weight !== null) {
        MynOberAPIClientManager.sendUserLog();

        requestFunctions.getReceipt(false, null);
        openDialog({
          children: <DialogOk title={"alert_title_ordering_possible"} content={"alert_text_no_couverts_on_table"} />,
        });
        return;
      }
    }

    if (this.isHandheld) {
      if (article.type === "FREETPARTICLE") {
        openDialog({
          children: (
            <FreeTPArticleModal
              article={article}
              onClick={(articleName, articlePrice) => {
                let orderArticle = new OrderArticle(article, 1, []);
                orderArticle.name = articleName;
                orderArticle.price = Math.round(articlePrice * 100.0);
                mynOberActions.addOrderArticle(orderArticle);
              }}
            />
          ),
        });
        return;
      }
    }
    if (count != null || this.isHandheld) {
      if (
        mynOberStore.menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(article.modifiergroupIds).length ===
          0 &&
        !article.allowRemark
      ) {
        if (!this.isHandheld) {
          if (article.min_amount > 0) {
            if (mynOberStore.order.amount < article.min_amount) {
              openDialog({
                children: (
                  <DialogOk
                    title={"alert_title_warning"}
                    rawContent={this.props.intl.formatMessage(
                      { id: "minimal_order_amount" },
                      { min_amount: (article.min_amount / 100.0).toFixed(2) + " " + GetCurrency() }
                    )}
                  />
                ),
              });
              return;
            }
          }
        }
        mynOberActions.addOrderArticle(new OrderArticle(article, count ?? 1, []));

        return;
      }
    }

    openDialog({ children: <ViewMenuArticleDetailed article={article} /> });
    this.setState({ selectedArticleIndex: index, selectedArticle: article });
  };

  onClickArticlegroupTab = (i, id) => {
    let element = document.getElementById(articlegroupViewId + i);

    let topOffset = 0;
    let stickyHeader = document.getElementById(stickyHeaderId);
    if (stickyHeader != null) {
      topOffset += stickyHeader.getBoundingClientRect().height;
    }
    this.setState({ selectedArticlegroupId: id });
    if (mynOberStore.isHandheld() && mynOberStore.isWidescreen) {
      this.setState({ selectedArticlegroupTabIndex: i });
    }

    if (element !== null) {
      scrollIntoView(
        element,
        {
          time: 1000,
          align: {
            top: 0,
            topOffset: topOffset, // margin
          },
          validTarget: (target, parentScrolled) => {
            return target.id === "menu-container"; //&& target === window;
          },
        },
        () => {
          if (this.state.selectedArticlegroupTabIndex !== i) {
            this.setState({ selectedArticlegroupTabIndex: i });
          }
        }
      );
    }
  };

  onMerchantUpdated = () => {
    const { articles, supergroups } = this.processArticlesForModifierPricesAndAmount(
      this.state.filter,
      this.state.selectedSupergroupIndex
    );
    this.setState({
      articles,
      supergroups,
      articlegroups: mynOberStore.menu.articlegroups,
    });
  };

  onOrderChanged = (resetSelectedArticleIndex = true) => {
    let articles = this.state.articles;
    articles.forEach((article) => {
      article.count = mynOberStore.order.getCountByArticleId(article.id);
    });

    if (resetSelectedArticleIndex) {
      this.setState({
        articles: articles,
        selectedArticleIndex: null,
        selectedArticle: null,
      });
    } else {
      this.setState({
        articles: articles,
      });
    }

    this.createViews(articles);
  };

  nextNode = (nodes, i, topOffset) => {
    let nextNode = nodes[i];
    if (
      i > 0 &&
      nextNode.current != null &&
      nextNode.current.viewArticlegroupRef.current.getBoundingClientRect().y - topOffset > 0
    ) {
      return this.nextNode(nodes, --i, topOffset);
    } else if (
      i + 1 < nodes.length &&
      nodes[i + 1].current != null &&
      nodes[i + 1].current.viewArticlegroupRef.current.getBoundingClientRect().y - topOffset <= 0
    ) {
      return this.nextNode(nodes, ++i, topOffset);
    } else {
      return i;
    }
  };

  onScrollMenuContainer = (nodes) => {
    let newSelectedIndex = this.state.selectedArticlegroupTabIndex;

    let stickyHeader = document.getElementById(stickyHeaderId);

    let topOffset = 5;
    if (stickyHeader != null) {
      topOffset += stickyHeader.getBoundingClientRect().height;
    }

    newSelectedIndex = this.nextNode(nodes, this.state.selectedArticlegroupTabIndex, topOffset);

    if (newSelectedIndex === this.state.selectedArticlegroupTabIndex) {
      return;
    }

    this.setState({ selectedArticlegroupTabIndex: newSelectedIndex });

    let element = document.getElementById(articlegroupTabId + newSelectedIndex);
    if (element !== null) {
      scrollIntoView(element, {
        time: 500,
        align: {
          left: 0.5,
          top: 0.5,
        },
        validTarget: function (target, parentScrolled) {
          return target.id === "scrollable-tab-bar";
        },
      });
    }
  };

  // --------------------------------------------------

  render() {
    let menuPageBar = (
      <StickyHeaderMenu
        supergroups={this.state.supergroups}
        filter={this.state.filter}
        onChangeFilter={this.onChangeFilter}
        articlegroupTabs={this.articlegroupTabs}
        selectedSupergroupIndex={this.state.selectedSupergroupIndex}
        updateMenu={this.updateMenu}
      />
    );

    return (
      <div
        style={{ overflowY: "auto", flex: "1 1 auto", display: "flex", flexDirection: "column" }}
        id={"menu-container"}
        onScroll={() => this.onScrollMenuContainer(this.state.viewArticlegroupsNodes)}
      >
        <MenuPage
          articlegroups={this.articlegroupViews}
          hasReceiptElsewhere={mynOberStore.hasReceiptElsewhere()}
          isHandheld={mynOberStore.isHandheld()}
          isOrderingPossible={mynOberStore.isOrderingPossible()}
          filter={this.props.filter}
        >
          {!this.isHandheld && !getValue(mynOberStore, "merchant.styling.iframe", false) && (
            <HeaderMerchant name={mynOberStore.merchant.name} uriMerchantImage={this.uriMerchantImage} />
          )}
          {mynOberStore.merchant instanceof TaMerchant && <TaDeInfoView />}
          {mynOberStore.merchant instanceof SpMerchant && !this.isHandheld && <SpInfoView />}
          {menuPageBar}
        </MenuPage>
      </div>
    );
  }
}

MenuContainerV2.propTypes = {
  headerMerchant: PropTypes.element,
};

MenuContainerV2.contextType = DialogContext;

export default withIntl(withRouter(MenuContainerV2));
