export class Allergen {
  /**
   *
   * @param {number} id
   * @param {string|null} description
   * @param {string} name
   * @param {string} path
   */
  constructor(id, description, name, path, merchant_id, translation_id, image) {
    this.id = id;
    this.description = description;
    this.name = name;
    this.path = path;
    this.merchant_id = merchant_id;
    this.translation_id = translation_id;
    this.image = image;
  }

  getImagePath() {
    if (!this.merchant_id) {
      return "https://mynober-api-public.s3.eu-central-1.amazonaws.com/allergens/" + this.id + ".png";
    } else if (this.image) {
      return "https://mynober-api-public.s3.eu-central-1.amazonaws.com/" + this.image;
    }
  }
}
