import React, { useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import MynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import { withRouter } from "react-router-dom";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import DialogError from "../../components/dialogs/DialogError";
import { PathNames } from "../../routers/PathNames";
import video from "../../../assets/videos/ils.mp4";
import scrollIntoView from "scroll-into-view";
import withIntl from "../../utils/withIntl";
import RestaurantListItem from "../../components/ilovesushi/RestaurantListItem";

function TakeawayOverviewContainer(props) {
  const [postalCode, setPostalCode] = React.useState("");
  const [taMerchants, setTaMerchants] = React.useState(null);
  const [otherTaMerchants, setOtherTaMerchants] = React.useState(null);
  const [showOtherTaMerchants, setShowOtherTaMerchants] = React.useState(false);
  const ref = React.createRef();
  const firstElement = React.createRef();
  const firstElementOthers = React.createRef();

  const scrollView = (ref) => {
    let scrollToNode = document.getElementById("takeaway-overview-list");
    if (ref.current != null) {
      scrollToNode = ref.current;
    }
    scrollIntoView(scrollToNode, {
      time: 1000,
      align: {
        top: 0, //taMerchants?.count == null ? 0 : taMerchants?.count * 600,
        topOffset: 60,
        // topOffset: window.innerHeight - 630,
      },
      validTarget: (target, parentScrolled) => {
        return target.id === "takeaway-overview-scroll"; //&& target === window;
      },
    });
  };

  useEffect(() => {
    scrollView(firstElement);
  }, [taMerchants]);

  useEffect(() => {
    if (showOtherTaMerchants) {
      scrollView(firstElementOthers);
    }
  }, [showOtherTaMerchants]);

  return (
    <>
      <div id={"takeaway-overview-scroll"} style={{ overflowY: "auto", height: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography variant={"h6"} style={{ fontWeight: "800", marginTop: 8, textAlign: "center" }}>
            {props.intl.formatMessage(
              { id: "already_number_establishments_in_the_netherlands" },
              { number: window.vestigingen }
            )}
          </Typography>
          <img
            src={"/ilovesushi/images/brand-logo.svg"}
            alt={"logo"}
            style={{ width: "100%", flex: "1 1 0%", height: 0, maxWidth: 600 }}
          />

          <form
            ref={ref}
            onSubmit={(e) => {
              e.preventDefault();
              let data = { data: { postalCode: parseInt(postalCode), taMasterMerchantId: 1 } };
              MynOberAPIClientManager.post(
                MynOberAPIClientManager.api.URL_TA,
                MynOberAPIClientManager.apiGet.GET_MERCHANTS,
                data,
                true,
                true
              )
                .catch((error) => {
                  console.log(error);
                })
                .then((response) => {
                  let taMerchants = {};
                  response.data.taPostalCodes.forEach((taPostalCode) => {
                    taMerchants[taPostalCode.ta_merchant.id] = taPostalCode.ta_merchant;
                  });

                  let otherTaMerchantsArray = [];
                  response.data.otherTaMerchants.forEach((taMerchant) => {
                    otherTaMerchantsArray.push(taMerchant);
                  });

                  otherTaMerchantsArray.sort((a, b) => {
                    let nameA = a.merchant.name.replace("I Love Sushi & Pokébowl ", "");
                    nameA = nameA.replace("I Love Sushi ", "");
                    let nameB = b.merchant.name.replace("I Love Sushi & Pokébowl ", "");
                    nameB = nameB.replace("I Love Sushi ", "");
                    if (nameA.toUpperCase() < nameB.toUpperCase()) {
                      return -1;
                    } else if (nameA.toUpperCase() > nameB.toUpperCase()) {
                      return 1;
                    } else {
                      return 0;
                    }
                  });

                  setOtherTaMerchants(otherTaMerchantsArray);

                  if (Object.keys(taMerchants).length === -1) {
                    taMerchants[Object.keys(taMerchants)[0]].sp_qr_codes.some((spQrCode) => {
                      requestFunctions
                        .sendWebcode(spQrCode.identifier)
                        .then(() => {
                          props.history.push(PathNames.MENUPAGE);
                        })
                        .catch((text) => {
                          const [openDialog] = this.context;
                          openDialog({ children: <DialogError content={text} /> });
                        });
                      return true;
                    });
                  } else {
                    let taMerchantsArray = [];
                    Object.keys(taMerchants).forEach((taMerchantId) => {
                      taMerchantsArray.push(taMerchants[taMerchantId]);
                    });

                    setTaMerchants(taMerchantsArray);
                  }
                });
            }}
            style={{ margin: "30px 10px" }}
          >
            <Grid style={{ maxWidth: 600 }} container spacing={1}>
              <Grid item xs={12} container justify={"center"}>
                <video
                  src={video}
                  loop
                  muted
                  style={{ width: "100%", height: "100%" }}
                  controls={false}
                  autoPlay
                  playsInline
                >
                  Your browser does not support the video tag.
                </video>
              </Grid>
              <Grid item xs={12} container justify={"center"}>
                <Typography variant={"h6"}>{props.intl.formatMessage({ id: "find_your_restaurant" })}</Typography>
              </Grid>
              <Grid item xs={12} container justify={"center"}>
                <TextField
                  placeholder={props.intl.formatMessage({ id: "label_postal_code" })}
                  value={postalCode}
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} container justify={"center"}>
                <Button type={"submit"} variant={"contained"} color={"primary"}>
                  {props.intl.formatMessage({ id: "search" })}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
        {taMerchants && (
          <div
            style={{ margin: "30px 10px", display: "flex", flexDirection: "column", alignItems: "center" }}
            id={"takeaway-overview-list"}
          >
            <Grid style={{ maxWidth: 600 }} container spacing={1}>
              {taMerchants?.length === 0 ? (
                <Grid item xs={12} justify={"center"} container>
                  <Typography>
                    {props.intl.formatMessage({ id: "there_are_not_any_restaurants_found_on_your_postal_code" })}
                  </Typography>
                </Grid>
              ) : null}
              {taMerchants?.length > 0 ? (
                <Grid item xs={12} justify={"center"} container>
                  <Typography>{props.intl.formatMessage({ id: "choose_your_restaurant" })}</Typography>
                </Grid>
              ) : null}

              {taMerchants?.map((taMerchant, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    justify={"center"}
                    container
                    // key={taMerchant.id}
                    innerRef={index === 0 ? firstElement : null}
                  >
                    <RestaurantListItem taMerchant={taMerchant} postalCode={"1234"} />
                  </Grid>
                );
              })}

              <Grid item xs={12} justify={"center"} container>
                <Typography>{props.intl.formatMessage({ id: "or_choose_any_other_establishment" })}</Typography>
              </Grid>

              <Grid item xs={12} justify={"center"} container>
                <Button
                  color={"primary"}
                  variant={"contained"}
                  onClick={() => {
                    setShowOtherTaMerchants(true);
                  }}
                >
                  {props.intl.formatMessage({ id: "show_more_establishments" })}
                </Button>
              </Grid>

              {showOtherTaMerchants &&
                otherTaMerchants?.map((taMerchant, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      justify={"center"}
                      container
                      // key={taMerchant.id}
                      innerRef={index === 0 ? firstElementOthers : null}
                    >
                      <RestaurantListItem taMerchant={taMerchant} postalCode={"1234"} />
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default withIntl(withRouter(TakeawayOverviewContainer));
