import { ReceiptArticle } from "./ReceiptArticle";

class TaReceipt {
  constructor(
    id,
    tableId,
    updatedAt,
    pickupTimestamp,
    number,
    isPaid,
    receiptArticles = [],
    contactInformation,
    paymentMethod,
    deliveryCosts,
    paymentCosts,
    remark,
    receiptType,
    status,
    taMerchantId,
    platform,
    takeawayOrder,
    tipAmount,
    distance,
    ubereatsOrder
  ) {
    this.id = id;
    this.tableId = tableId;
    this.receiptArticles = receiptArticles;
    this.updatedAt = updatedAt;
    this.pickupTimestamp = pickupTimestamp;
    this.number = number;
    this.isPaid = isPaid;
    this.contactInformation = contactInformation;
    this.paymentMethod = paymentMethod;
    this.deliveryCosts = deliveryCosts;
    this.paymentCosts = paymentCosts;
    this.remark = remark;
    this.receiptType = receiptType;
    this.status = status;
    this.taMerchantId = taMerchantId;
    this.platform = platform;
    this.takeawayOrder = takeawayOrder;
    this.tipAmount = tipAmount ?? 0;
    this.distance = distance;
    this.ubereatsOrder = ubereatsOrder;
  }

  get count() {
    let count = 0;

    this.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
      count += receiptArticle.count;
    });

    return count;
  }

  getAmount = () => {
    let amount = 0;
    this.receiptArticles.forEach((receiptArticle) => {
      amount += receiptArticle.amount;
    });

    amount += this.deliveryCosts;
    amount += this.paymentCosts;
    amount += this.tipAmount;
    return amount;
  };
}

export default TaReceipt;
