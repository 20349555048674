import React, { Component } from "react";
import { PathNames } from "../../routers/PathNames";
import { injectIntl } from "react-intl";
import mynOberAPIClientManager from "../../utils/api/MynOberAPIClientManager";
import "./ReturnPage.css";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { setMerchant } from "../../utils/flux/MynOberActions";
import * as sessionStorage from "../../utils/session_storage/SessionStorage";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import { Button, Typography } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";
import ReactGA from "react-ga";
import * as jsonProcessingFunctions from "../../utils/functions/JsonProcessingFunctions";
import { OrderTypes } from "../../utils/order/Order";

const divStyle = function () {
  return {
    // backgroundImage: "url('https://mynober-api-public.s3.eu-central-1.amazonaws.com/ta-merchants/returnpage/8.jpg'), url(\"./images/" + window.label.images.folder + "/homepage-qrcode.jpeg\")"
    // backgroundImage: "url(\"./images/" + window.label.images.folder + "/homepage-qrcode.jpeg\")"
  };
};

const imgPrefix = "https://mynober-api-public.s3.eu-central-1.amazonaws.com/ta-merchants/returnpage/";
const iLoveSushi =
  "<div class=\"return-page\" style='background-color:  black; height: 100%; width: 100%; justify-content: normal'><img style='top: -25%' src='" +
  imgPrefix +
  "ilovesushi/logo.svg' /></div>";
const vapianoHtml =
  "<div class=\"return-page\" style='background-color:  black; height: 100%; width: 100%; justify-content: normal'><img style='top: -25%' src='" +
  imgPrefix +
  "vapiano/vapianologo.jpg' /></div>";
const defaultHtml =
  '<img src="./images/' +
  window.label.images.folder +
  '/homepage-qrcode.jpeg" style="width: 100%; height: 100%; position: absolute; object-fit: cover; top: 0; left: 0; z-index: -10"/>';

class ReturnPage extends Component {
  constructor(props) {
    super(props);

    let hasStorage = true;
    if (
      sessionStorage.getItem(sessionStorage.storageNames.WEBCODE) == null &&
      sessionStorage.getItem(sessionStorage.storageNames.ORDER) == null
    ) {
      hasStorage = false;
    }

    let searchParams = new URLSearchParams(window.location.search);
    if (typeof searchParams.get("taMerchantId") === "string") {
      this.taMerchantId = searchParams.get("taMerchantId");
    } else {
      this.taMerchantId = 0;
    }

    if (typeof searchParams.get("taMasterMerchantId") === "string") {
      this.taMasterMerchantId = searchParams.get("taMasterMerchantId");
    } else {
      this.taMasterMerchantId = 0;
    }

    this.state = {
      checking: true,
      success: false,
      hasStorage: hasStorage,
      taMerchant: null,
      spMerchant: null,
    };
    window.returnPage = this;
  }

  componentWillMount(): void {}

  componentDidMount() {
    let searchParams = new URLSearchParams(window.location.search);
    if (this.taMerchantId !== 0 && (!searchParams.has("orderId") || !searchParams.has("paymentSessionId"))) {
      this.onReturnApproved();
    } else {
      if (window.location.href.split("?")[1] === undefined) {
        this.onReturnApproved();
      } else {
        this.onLoginSuccessful();
      }
    }
  }

  backToHomepage = () => {
    window.history.replaceState(null, "landingpage", PathNames.HOMEPAGE);
    window.location.reload();
  };

  backToOrderpage = () => {
    if (this.state.success) {
      window.history.replaceState(null, "menupage", PathNames.MENUPAGE);
      window.location.reload();
    } else {
      let order = JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER));
      switch (order?.orderType) {
        case OrderTypes.TAKEAWAY:
          window.history.replaceState(null, "takeaway_orderpage", PathNames.TAKEAWAYPAGE);
          break;
        case OrderTypes.DELIVERY:
          window.history.replaceState(null, "delivery_orderpage", PathNames.DELIVERYPAGE);
          break;
        default:
          window.history.replaceState(null, "orderpage", PathNames.ORDERPAGE);
          break;
      }
      window.location.reload();
    }
  };

  onLoginSuccessful = () => {
    if (window.location.href.split("?")[1] !== undefined) {
      if (!mynOberStore.isLoading) {
        mynOberActions.setIsLoading(true);
      }

      mynOberAPIClientManager
        .getOnlinePaymentDetails(window.location.href.split("?")[1])
        .then((response) => {
          let status = response.status;
          if (this.taMerchantId > 0) {
            response.taMerchant.styling = JSON.parse(response.taMerchant.styling);
            setMerchant(response.taMerchant);
            this.setState({ taMerchant: response.taMerchant });
          } else if (response.spMerchant) {
            response.spMerchant.styling = JSON.parse(response.spMerchant.styling);
            setMerchant(response.spMerchant);
            this.setState({ spMerchant: response.spMerchant });
          }
          if (status === "APPROVED") {
            this.onReturnApproved();
          } else if (status === "CANCELED") {
            this.onReturnError();
          } else if (status === "EXPIRED") {
            this.onReturnError();
          } else if (status === "ERROR") {
            this.onReturnError();
          } else if (status === "INIT") {
            this.onReturnErrorStatusUnknown();
          }
        })
        .catch(() => {
          this.onReturnErrorStatusUnknown();
        });
    }
  };

  onReturnCanceled = () => {
    if (sessionStorage.getItem(sessionStorage.storageNames.ORDER) != null && this.taMerchantId > 0) {
      let oldOrder = jsonProcessingFunctions.processJsonOrder(
        JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER))
      );
      let trackerNames = ["MynOber"];
      if (this.state.taMerchant?.ta_master_merchant_id === 1) {
        // trackerNames.push("ILS");
      }

      let order = mynOberStore.takeawayOrder != null ? mynOberStore.takeawayOrder : mynOberStore.deliveryOrder;
      ReactGA.event(
        {
          category: "User Actions",
          action: "Payment Canceled",
          label: order.paymentMethod != null ? order.paymentMethod.name : "-",
          value: Math.round(
            100 *
              parseFloat(
                (oldOrder.amount +
                  order.tip +
                  mynOberStore.defaultOrderAmount +
                  order.paymentMethod.extraCosts +
                  ((oldOrder.amount + order.tip + mynOberStore.defaultOrderAmount) / 100.0) *
                    order.paymentMethod.extraPercentage) /
                  100.0
              )
          ),
        },
        trackerNames
      );
    }
  };

  onReturnApproved = () => {
    mynOberActions.setIsLoading(false);
    if (sessionStorage.getItem(sessionStorage.storageNames.ORDER) != null && this.taMerchantId > 0) {
      let oldOrder = jsonProcessingFunctions.processJsonOrder(
        JSON.parse(sessionStorage.getItem(sessionStorage.storageNames.ORDER))
      );
      let trackerNames = ["MynOber"];
      if (this.state.taMerchant?.ta_master_merchant_id === 1) {
        // trackerNames.push("ILS");
      }

      let order = mynOberStore.takeawayOrder != null ? mynOberStore.takeawayOrder : mynOberStore.deliveryOrder;
      ReactGA.event(
        {
          category: "User Actions",
          action: "Payment",
          label: order.paymentMethod != null ? order.paymentMethod.name : "-",
          value: Math.round(
            100 *
              parseFloat(
                (oldOrder.amount +
                  order.tip +
                  mynOberStore.defaultOrderAmount +
                  order.paymentMethod.extraCosts +
                  ((oldOrder.amount + order.tip + mynOberStore.defaultOrderAmount) / 100.0) *
                    order.paymentMethod.extraPercentage) /
                  100.0
              )
          ),
        },
        trackerNames
      );
    }
    sessionStorage.removeItem(sessionStorage.storageNames.ORDER);
    sessionStorage.removeItem(sessionStorage.storageNames.PICKUP_TIME);
    // sessionStorage.removeItem(sessionStorage.storageNames.CONTACT_DETAILS);
    sessionStorage.removeItem(sessionStorage.storageNames.COMPLETE_METHOD);
    this.setState({ checking: false, success: true });
  };

  onReturnError = () => {
    this.onReturnCanceled();
    mynOberActions.setIsLoading(false);
    this.setState({ checking: false, success: false });
  };

  onReturnErrorStatusUnknown = () => {
    this.onReturnCanceled();
    this.setState({ checking: true, success: false });
    setTimeout(() => {
      this.onLoginSuccessful();
    }, 5000);
  };

  render() {
    let htmlInput = "";
    let taMerchantId = Number.parseInt(this.taMerchantId);
    if (
      (taMerchantId >= 32 && taMerchantId <= 41) ||
      (taMerchantId >= 51 && taMerchantId <= 76) ||
      (taMerchantId >= 79 && taMerchantId <= 79) || this.taMasterMerchantId == 1
    ) {
      htmlInput = iLoveSushi;
    } else {
      htmlInput = defaultHtml;
    }
    if (window.whiteLabel === "vapiano") {
      htmlInput = vapianoHtml;
    }
    // var HtmlToReactParser = require("html-to-react").Parser;
    // var htmlToReactParser = new HtmlToReactParser();
    // var reactElement = htmlToReactParser.parse(htmlInput);

    let titleId = "text_checking_status";
    if (!this.state.checking) {
      if (this.state.success) {
        titleId = "text_payment_success";
      } else {
        titleId = "text_payment_failed";
      }
    }

    return (
      <div className="return-page" style={divStyle()}>
        <div dangerouslySetInnerHTML={{ __html:htmlInput }}/>

        <div className={"payment-status-view"} style={{ position: "absolute" }}>
          <Typography variant={"h3"}>{this.props.intl.formatMessage({ id: titleId })}</Typography>
          {(this.state.taMerchant || this.taMerchantId === 0) && !this.state.checking && this.state.hasStorage ? (
            <Button variant={"contained"} color={"primary"} onClick={this.backToOrderpage}>
              {this.props.intl.formatMessage({ id: "button_back_to_menu" })}
            </Button>
          ) : null}
        </div>
      </div>
    );
  }
}

ReturnPage.contextType = DialogContext;

export default injectIntl(ReturnPage);
