import React, { Component } from "react";
import { withRouter } from "react-router";
import handheldStore from "../../../utils/flux/HandheldStore";
import HistoryReceiptView from "../../../components/handheld/smartphone/HistoryReceiptView";

class ReceiptHistoryContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      handheldReceipts: Object.keys(handheldStore.handheldReceipts)
        .sort(this.sort)
        .map((handheldReceiptId) => handheldStore.handheldReceipts[handheldReceiptId]),
    };

    window.receiptHistoryContainer = this;
  }

  sort = (a, b) => {
    if (handheldStore.handheldReceipts[a].updatedAt < handheldStore.handheldReceipts[b].updatedAt) {
      return 1;
    } else if (handheldStore.handheldReceipts[a].updatedAt > handheldStore.handheldReceipts[b].updatedAt) {
      return -1;
    } else {
      return 0;
    }
  };

  componentDidMount(): void {
    handheldStore.on(handheldStore.events.RECEIPT_CLOSED, this.receiptUpdated);
    handheldStore.on(handheldStore.events.RECEIPT_UPDATED, this.receiptUpdated);
    handheldStore.on(handheldStore.events.RECEIPT_CREATED, this.receiptUpdated);
  }

  componentWillUnmount(): void {
    handheldStore.removeListener(handheldStore.events.RECEIPT_CLOSED, this.receiptUpdated);
    handheldStore.removeListener(handheldStore.events.RECEIPT_UPDATED, this.receiptUpdated);
    handheldStore.removeListener(handheldStore.events.RECEIPT_CREATED, this.receiptUpdated);
  }

  receiptUpdated = () => {
    this.setState({
      handheldReceipts: Object.keys(handheldStore.handheldReceipts)
        .sort(this.sort)
        .map((handheldReceiptId) => handheldStore.handheldReceipts[handheldReceiptId]),
    });
  };

  render() {
    return this.state.handheldReceipts.map((handheldReceipt) => {
      return <HistoryReceiptView key={handheldReceipt.id} handheldReceipt={handheldReceipt} />;
    });
  }
}

export default withRouter(ReceiptHistoryContainer);
