import * as locationManager from "./LocationManager";

export function getDistanceToMerchant(needsDistance, merchantLocation) {
  return new Promise((resolve, reject) => {
    if (needsDistance) {
      locationManager.getDistanceToMerchant(
        merchantLocation,
        (distance) => {
          resolve(distance);
        },
        (event) => {
          reject(event);
        }
      );
    } else {
      resolve(0);
    }
  });
}
