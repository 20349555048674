import React from "react";
import mynOberStore from "../utils/flux/MynOberStore";
import { Button, Typography } from "@material-ui/core";
import { FreeTipSelector } from "./FreeTipSelector";
import withIntl from "../utils/withIntl";

function TipSelector(props) {
  let tipOptions = props.tipOptions ? props.tipOptions : mynOberStore.merchant.tip.options;
  const buttons = [];
  tipOptions.forEach((tipOption) => {
    if (tipOption.percentage != null) {
      buttons.push(
        <Button
          style={{ fontSize: "16pt", margin: "8px 8px" }}
          variant={"outlined"}
          onClick={() => {
            let tipAmount = 0;
            if (tipOption != null && tipOption.percentage != null) {
              tipAmount = Math.round((tipOption.percentage / 100.0) * props.amount);
            }
            props.onTipSelect(tipAmount);
          }}
        >
          {tipOption.percentage} %
        </Button>
      );
    } else if (tipOption.free != null) {
      buttons.push(<FreeTipSelector {...props} />);
    }
  });

  return (
    <div style={{ display: "inline-flex", flexDirection: "column", maxWidth: 340 }}>
      <Typography variant={"h6"}>{props.intl.formatMessage({ id: "tip_the_waiter" })}</Typography>
      <div style={{ display: "flex", flexDirection: "row", flexFlow: "wrap" }}>{buttons}</div>
    </div>
  );
}

export default withIntl(TipSelector);
