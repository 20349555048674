import React from "react";
import { TextField as MTextField } from "@material-ui/core";

export default function TextField(props) {
  const [text, setText] = React.useState("");

  let onChange = (e) => {
    setText(e.target.value);
    props.setText(e.target.value);
  };

  return (
    <MTextField
      style={{ margin: "10px" }}
      autoComplete={"off"}
      InputLabelProps={{
        shrink: true,
      }}
      required={true}
      variant={"outlined"}
      label={props.placeholder}
      value={text}
      onChange={onChange}
    />
  );
}
