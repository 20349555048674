import React, { Component } from "react";
import { matchPath, withRouter } from "react-router";
import { ReactComponent as IconOptions } from "../../../../assets/images/ic-menu.svg";
import mynOberStore from "../../../utils/flux/MynOberStore";
import handheldStore from "../../../utils/flux/HandheldStore";
import TopNavigation from "../../../components/web/TopNavigation";
import DrawerMenu, { openDrawer } from "../../../components/drawer_menu/DrawerMenu";
import { Order } from "../../../utils/order/Order";
import ViewOrderArticle from "../../../components/menu/ViewOrderArticle";
import ViewArticlegroup from "../../../components/menu/ViewArticlegroup";
import MenuPage from "../../../pages/MenuPage";
import { OrderArticle } from "../../../utils/order/OrderArticle";
import "./ActivateContainer.css";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import * as mynOberActions from "../../../utils/flux/MynOberActions";
import { getDrawerButtons } from "../../../components/floorplan/FloorPlan";
import { Button } from "@material-ui/core";
import withIntl from "../../../utils/withIntl";
import { DialogContext } from "../../../components/dialogs/DialogProvider";
import DialogOk from "../../../components/dialogs/DialogOk";
import DialogError from "../../../components/dialogs/DialogError";
import MynOberAPIClientManager from "../../../utils/api/MynOberAPIClientManager";
import { FormatListNumbered } from "@material-ui/icons";

class ActivateContainer extends Component {
  constructor(props) {
    super(props);

    let order = new Order(this.props.match.params.tableId, null);
    mynOberActions.setWebcode(this.props.match.params.tableId);
    mynOberActions.setOrder(order);
    let articleFlags = null;
    let articleIds = {};

    if (mynOberStore.merchant.ayceSettings != null) {
      let articleFlags = mynOberStore.merchant.ayceSettings.articleFlags;

      articleFlags.forEach((articleFlag) => {
        articleIds[articleFlag.articleId] = articleFlag;
      });
    }

    mynOberStore.menu.filter("");

    let articles = mynOberStore.menu.articles.filter((article) => {
      return article.isMenu && article.isInOpeningHours;
    });

    this.viewArticlegroupsNodes = [];
    this.articleViewNodes = [];

    this.state = {
      table: handheldStore.tables[props.match.params["spMerchantId"]][props.match.params.tableId],
      order: order,
      articlegroups: mynOberStore.menu.articlegroups,
      articles: articles,
      articleFlags: articleFlags,
      articleIds: articleIds,
    };
    window.activateContainer = this;
  }

  createArticleViews = () => {
    this.articleViewNodes = [];

    return this.state.articles.map((article, i) => {
      article.count = 0;
      return (
        <ViewOrderArticle
          key={i}
          number_in_order={i}
          name={article.name}
          price={article.price}
          count={this.state.order.getCountByArticleId(article.id).toString()}
          article={article}
          orderModifierarticleViews={[]}
          onRemoveClicked={() => {
            let order = this.state.order;
            order.addOrderArticleToOrder(new OrderArticle(article, -1, []));
            this.setState({ order: order });
          }}
          onAddClicked={() => {
            let order = this.state.order;
            order.addOrderArticleToOrder(new OrderArticle(article, 1, []));
            this.setState({ order: order });
          }}
        />
      );
    });
  };

  // <ViewArticle
  // ref={node => {
  //   this.articleViewNodes[i] = node;
  // }}
  // key={article.id}
  // article={article}
  // onArticleClicked={() => {
  //   this.onArticleClicked(article.id, i);
  // }}
  // enabled={true}
  // isHandheld={true}
  // selected={this.state.selectedArticleIndex === i}
  // ArticleInfoPage={null}
  // />

  createArticlegroupViews = (articles) => {
    this.viewArticlegroupsNodes = [];
    let articleGroupViews = [];

    this.state.articlegroups.forEach((articlegroup, i) => {
      let articlesList = articles.filter((article) => {
        return article.props.article.articlegroupId === articlegroup.id;
      });
      if (articlesList.length > 0) {
        articleGroupViews.push(
          <ViewArticlegroup
            key={i}
            tabLabel={articlegroup.name}
            ref={this.viewArticlegroupsNodes[i]}
            index={i}
            name={articlegroup.name}
            articles={articlesList}
            flexColumn={true}
            onlyOneColumn={true}
          />
        );
      }
    });
    return articleGroupViews;
  };

  sendOrder = () => {
    // console.table(this.state.order);
    requestFunctions
      .sendOrder(null, mynOberStore.merchant, 0)
      .then(() => {
        MynOberAPIClientManager.getAllHandheldReceipts(false);
        const [openDialog] = this.context;
        openDialog(
          {
            children: (
              <DialogOk
                title={"alert_title_success"}
                content={"alert_text_success_order"}
                timer={true}
                dialogClosed={() => {
                  this.props.history.replace("/" + this.props.match.params["spMerchantId"]);
                }}
                onOkClicked={() => {
                  this.props.history.replace("/" + this.props.match.params["spMerchantId"]);
                }}
              />
            ),
          },
          true
        );
      })
      .catch((text) => {
        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={text} /> });
      });
  };

  render() {
    let articles = this.createArticleViews();
    let articlegroups = this.createArticlegroupViews(articles);
    let drawerMenuButtons = [];

    Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
      drawerMenuButtons.push({
        stringId: "table_overview",
        subtitle: handheldStore.spMerchants[spMerchantId].salesAreaName,
        icon: <FormatListNumbered />,
        key: "table_overview-" + spMerchantId,
        onClick: () => {
          this.props.history.replace("/" + spMerchantId);
        },
      });
    });

    drawerMenuButtons = drawerMenuButtons.concat(getDrawerButtons(this.props.history));

    let title = mynOberStore.merchant.name;

    let match = matchPath(this.props.location.pathname, { path: "/:spMerchantId(\\d+)" });
    if (match && handheldStore.spMerchants[match.params.spMerchantId].salesAreaName != null) {
      title += " - " + handheldStore.spMerchants[match.params.spMerchantId].salesAreaName;
    }

    return (
      <div className={"activate-container-root"}>
        <TopNavigation
          iconSrc={<IconOptions />}
          titleId={"button_options"}
          merchantName={title + " - Table " + mynOberStore.webcode}
          onClickOptions={openDrawer}
        />
        <DrawerMenu buttons={drawerMenuButtons} />
        <div className={"activate-container"} style={{ height: "100%", overflow: "hidden" }}>
          <div
            style={{
              margin: "8px 0",
              overflowY: "auto",
              overflowX: "hidden",
              height: "calc( 100% - 60px)",
              // flex: "1 1 auto",
              display: "flex",
              flexDirection: "column",
              borderTop: "1px solid black",
              borderBottom: "1px solid black",
            }}
          >
            <MenuPage
              isLoading={this.state.isLoading}
              articlegroups={articlegroups}
              onScrollMenuPage={() => {
                if (this.props.onScrollMenuPage != null) {
                  this.props.onScrollMenuPage(this.viewArticlegroupsNodes);
                }
              }}
              headerPage={null}
              hasReceiptElsewhere={false}
              isHandheld={true}
              isOrderingPossible={true}
              filter={""}
            ></MenuPage>
          </div>
          <div
            style={{
              textAlign: "center",
              margin: "8px 0",
              height: mynOberStore.isWidescreen ? 60 : null,
            }}
          >
            <Button
              style={{
                width: "66%",
                height: mynOberStore.isWidescreen ? 60 : null,
              }}
              onClick={this.sendOrder}
              disabled={this.state.order.orderArticles.length === 0}
              color={"primary"}
              variant={"contained"}
            >
              {this.props.intl.formatMessage({ id: "button_send_order" })}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

ActivateContainer.contextType = DialogContext;

export default withIntl(withRouter(ActivateContainer));
