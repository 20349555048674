import { ReceiptArticle } from "./ReceiptArticle";
import { HandheldOrdergroup } from "./HandheldOrdergroup";
import { getApplicationDate } from "../functions/HelperFunctions";

export class HandheldReceipt {
  constructor(
    id,
    partyId,
    tableId,
    handheldOrdergroups = [],
    updatedAt,
    menuTags,
    status,
    spMerchantId,
    createdAt,
    transactions,
    randomPincode
  ) {
    this.id = id;
    this.partyId = partyId;
    this.tableId = tableId;
    this.handheldOrdergroups = handheldOrdergroups;
    this.updatedAt = updatedAt;
    this.menuTags = menuTags;
    this.status = status;
    this.spMerchantId = spMerchantId;
    this.createdAt = createdAt;
    this.transactions = transactions;
    this.transactionsObject = {};
    this.hasTransactionable = false;
    if (transactions) {
      transactions.forEach((transaction) => {
        this.transactionsObject[transaction.id] = transaction;
        if (transaction.transactionable_type != null) {
          this.hasTransactionable = true;
        }
      });
    }
    this.randomPincode = randomPincode;
  }

  isTimeLimitExceed = () => {
    let timeLimitExceeded = false;
    if (this.spAyceCover != null && this.spAyceCover.time_limit != null) {
      let date = getApplicationDate();
      if (date.getTime() / 1000 > this.spAyceCover.time_limit) {
        timeLimitExceeded = true;
      }
    }
    return timeLimitExceeded;
  };

  getSecondsBeforeTimeLimitExceeds = () => {
    let timerMiliSeconds = null;

    if (this.spAyceCover != null && this.spAyceCover.time_limit != null) {
      let date = getApplicationDate();
      if (date.getTime() / 1000 > this.spAyceCover.time_limit) {
      } else {
        timerMiliSeconds = this.spAyceCover.time_limit * 1000 - date.getTime() + 2000;
      }
    }
    return timerMiliSeconds;
  };

  get amountOpen() {
    let amountOpen = 0;
    this.handheldOrdergroups.forEach((handheldOrdergroup: HandheldOrdergroup) => {
      handheldOrdergroup.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
        amountOpen += receiptArticle.amountOpen;
      });
    });
    return amountOpen;
  }

  get amountClosed() {
    let amountClosed = 0;
    this.handheldOrdergroups.forEach((handheldOrdergroup: HandheldOrdergroup) => {
      handheldOrdergroup.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
        amountClosed += receiptArticle.amountClosed;
      });
    });
    return amountClosed;
  }

  get countOpen() {
    let countOpen = 0;
    this.handheldOrdergroups.forEach((handheldOrdergroup: HandheldOrdergroup) => {
      handheldOrdergroup.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
        countOpen += receiptArticle.countOpen;
      });
    });
    return countOpen;
  }

  get countClosed() {
    let countClosed = 0;
    this.handheldOrdergroups.forEach((handheldOrdergroup: HandheldOrdergroup) => {
      handheldOrdergroup.receiptArticles.forEach((receiptArticle: ReceiptArticle) => {
        countClosed += receiptArticle.countClosed;
      });
    });
    return countClosed;
  }
}
