import React, { Component } from "react";
import QrReader from "react-qr-reader";
import "./QrScannerPage.css";
import PropTypes from "prop-types";
import { ReactComponent as IconBack } from "../../assets/images/ic-back.svg";
import * as listeners from "../utils/Listeners";
import { PathNames } from "../routers/PathNames";
import { withRouter } from "react-router";

class QrScannerPage extends Component {
  componentDidMount() {
    if (this.props.isHandheld) {
      listeners.registerHandheldReceiptListeners(this.onMenuCreated);
    } else {
      listeners.registerQrStartListeners(this.onMenuCreated);
    }
  }

  componentWillUnmount() {
    if (this.props.isHandheld) {
      listeners.unregisterHandheldReceiptListeners(this.onMenuCreated);
    } else {
      listeners.unregisterQrStartListeners(this.onMenuCreated);
    }
  }

  handleScan = (data) => {
    this.props.handleScan(data);
  };

  onClickBack = (event) => {
    if (event !== null) {
      event.preventDefault();
    }

    this.props.history.goBack();
  };

  onMenuCreated = () => {
    this.props.history.replace(PathNames.MENUPAGE);
  };

  render() {
    return (
      <div className={"qr-scanner-container"}>
        <button className={"qr-scanner-back-button"} onClick={this.onClickBack}>
          <IconBack />
        </button>
        <QrReader
          className={"qr-scanner-view"}
          facingMode={"environment"}
          onError={this.props.handleError}
          onScan={this.handleScan}
          resolution={720}
          showViewFinder={false}
        />
      </div>
    );
  }
}

QrScannerPage.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleScan: PropTypes.func.isRequired,
};

export default withRouter(QrScannerPage);
