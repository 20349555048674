import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import "./ViewReceiptArticle.css";
import GetCurrency from "../../utils/functions/GetCurrency";
import Typography from "@material-ui/core/Typography";
import { DialogContext, useDialog } from "../dialogs/DialogProvider";
import ViewMenuArticleDetailed from "./ViewMenuArticleDetailed";
import mynOberStore from "../../utils/flux/MynOberStore";
import DialogHandheldReceiptArticleActions from "../dialogs/DialogHandheldReceiptArticleActions";
import handheldStore from "../../utils/flux/HandheldStore";

function ReceiptArticleView(props) {
  const [openDialog] = useDialog();
  let paymentMethods = {};
  if (props.showTurnoverGroups && mynOberStore.isHandheld()) {
    paymentMethods = props.receiptArticle.getPaymentMethods(
      handheldStore.handheldReceipts[props.receipt.id].transactionsObject
    );
  } else {
    if (mynOberStore.receipt.transactionsObject) {
      paymentMethods = props.receiptArticle.getPaymentMethods(mynOberStore.receipt.transactionsObject);
    }
  }

  const article = useMemo(() => {
    return mynOberStore.menu.getArticleById(props.receiptArticle.id);
  },[props.receiptArticle]);

  return (
    <div
      className="row-receipt-article"
      onClick={() => {
        if (props.table && props.receipt) {
          const article = mynOberStore.menu.getArticleById(props.receiptArticle.id);
          if (!mynOberStore.isHandheld() && article && article.canBeOrderedByUser) {
            article.count = mynOberStore.order.getCountByArticleId(article.id);
            openDialog({
              children: (
                <ViewMenuArticleDetailed
                  article={article}
                  orderModifiergroups={props.receiptArticle.orderModifiergroups}
                />
              ),
            });
          } else if (mynOberStore.isHandheld() && article) {
            openDialog({
              children: (
                <DialogHandheldReceiptArticleActions
                  handheldReceipt={handheldStore.handheldReceipts[props.receipt.id]}
                  receiptArticle={props.receiptArticle}
                  table={props.table}
                  receipt={props.receipt}
                />
              ),
              maxWidth: "lg",
            });
          }
        }
      }}
    >
      {/*<button onClick={() => {*/}
      {/*  props.onClickNegative();*/}
      {/*}}>-</button>*/}
      <div className="column-receipt-amount">
        <Typography>{props.count + "x"}</Typography>
        {props.showCountClosed &&
          Object.keys(paymentMethods).length > 0 &&
          Object.keys(paymentMethods).map((turnoverGroupKey) => (
            <Typography style={{ fontSize: "0.9em" }}>{"-" + paymentMethods[turnoverGroupKey].count + "x"}</Typography>
          ))}
        {mynOberStore.merchant?.info?.show_article_images_in_order_history && article?.path !== null ? (
          <div
            style={{
              // flex: "0 0 30%",
              // margin: "0 3px",
              textAlign: "center",
              overflow: "hidden",
            }}
          >
            <img
              style={{
                borderRadius: "8px",
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
              alt={article?.name}
              src={article?.path}
            />
          </div>
        ) : null}
      </div>

      <div className="column-receipt-name">
        <div>
          <Typography>{props.name}</Typography>
          {props.orderModifierarticleViews}
          {props.showTurnoverGroups &&
            Object.keys(paymentMethods).length > 0 &&
            Object.keys(paymentMethods).map((turnoverGroupKey) => (
              <Typography style={{ fontSize: "0.9em" }}>{turnoverGroupKey}</Typography>
            ))}
        </div>
      </div>
      <div className="column-receipt-price">
        {props.receiptArticle.oldOpenAmount !== 0 && (
          <Typography style={{ textDecoration: "line-through", fontSize: "1.1em" }}>
            {GetCurrency("€") +
              parseFloat(props.receiptArticle.oldOpenAmount / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </Typography>
        )}
        {(props.amount !== 0 || (props.info != null && props.info.oldPrice != null)) && (
          <Typography>
            {GetCurrency("€")}{" "}
            {parseFloat(props.amount / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        )}
      </div>
    </div>
  );
}

ReceiptArticleView.propTypes = {
  receiptArticle: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  orderModifierarticleViews: PropTypes.array.isRequired,
  info: PropTypes.array,
  onClickNegative: PropTypes.func,
  showTurnoverGroups: PropTypes.bool,
  showCountClosed: PropTypes.bool,
};

ReceiptArticleView.contextType = DialogContext;

export default ReceiptArticleView;
