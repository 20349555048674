import React from "react";
import PropTypes from "prop-types";
import "./ScrollableTabBar.css";
import Grid from "@material-ui/core/Grid";
import mynOberStore from "../../utils/flux/MynOberStore";
import clsx from "clsx";

export const scrollableTabBarId = "scrollable-tab-bar";

export function ScrollableTabBar(props) {
  const content = () => {
    if (mynOberStore.isHandheld() && mynOberStore.isWidescreen) {
      return (
        <Grid container spacing={1}>
          {props.tabs}
        </Grid>
      );
    } else {
      return props.tabs;
    }
  };

  return (
    <div
      id={scrollableTabBarId}
      className={clsx(
        props.tabs?.length > 0 ? "scrollable-tab-bar-root scrollable-tab-bar-root-borders" : "scrollable-tab-bar-root",
        props.orientation === "column" ? "scrollable-tab-bar-root-vertical" : "scrollable-tab-bar-root-horizontal"
      )}
    >
      <div className={"scrollable-tab-bar"} style={{ flexDirection: props.orientation, margin: 8 }}>
        {content()}
      </div>
    </div>
  );
}

ScrollableTabBar.propTypes = {
  orientation: PropTypes.oneOf(["row", "column"]).isRequired,
  tabs: PropTypes.array.isRequired,
};

export default ScrollableTabBar;
