import React from "react";
import PropTypes from "prop-types";
import "./ViewArticlegroupHandheld.css";
import { Grid } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";

export const articlegroupViewId = "articlegroup-";

class ViewArticlegroupHandheld extends React.Component {
  constructor(props) {
    super(props);

    this.viewArticlegroupRef = React.createRef();
  }

  render() {
    return (
      <div
        className={"container-articlegroup-handheld"}
        id={articlegroupViewId + this.props.index}
        ref={this.viewArticlegroupRef}
      >
        <p className={"title-articlegroup-handheld"}>{this.props.name}</p>
        <div
          className={
            this.props.flexColumn === true ? "view-articlegroup-column-handheld" : "view-articlegroup-handheld"
          }
        >
          <Grid container spacing={2}>
            {this.props.articles.map((article, index) => {
              // return article

              if (mynOberStore.isHandheld()) {
                let xs = 6;
                let sm = 3;
                let md = 2;
                let lg = 2;
                let xl = 2;
                return (
                  <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={index}>
                    {article}
                  </Grid>
                );
              } else {
                return (
                  <Grid item xs={12} sm={this.props.onlyOneColumn ? 12 : 6} key={index}>
                    {article}
                  </Grid>
                );
              }
            })}
          </Grid>
        </div>
      </div>
    );
  }
}

ViewArticlegroupHandheld.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  articles: PropTypes.array.isRequired,
  flexColumn: PropTypes.bool,
};

export default ViewArticlegroupHandheld;
