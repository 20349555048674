import { EventEmitter } from "events";
import mynOberDispatcher from "./MynOberDispatcher";
import { Table } from "../Table";

class HandheldStore extends EventEmitter {
  constructor() {
    super();

    this.actions = {
      UPDATE_TABLE: "HandheldStoreActions.UPDATE_TABLE",
      CREATE_TABLE: "HandheldStoreActions.CREATE_TABLE",
      CLOSE_RECEIPT: "HandheldStoreActions.CLOSE_RECEIPT",
      DELETE_RECEIPT: "HandheldStoreActions.DELETE_RECEIPT",
      UPDATE_RECEIPT: "HandheldStoreActions.UPDATE_RECEIPT",
      CREATE_RECEIPT: "HandheldStoreActions.CREATE_RECEIPT",
      CLOSE_TARECEIPT: "HandheldStoreActions.CLOSE_TARECEIPT",
      UPDATE_TARECEIPT: "HandheldStoreActions.UPDATE_TARECEIPT",
      CREATE_TARECEIPT: "HandheldStoreActions.CREATE_TARECEIPT",
      SET_SP_MERCHANTS: "HandheldStoreActions.SET_SP_MERCHANTS",
      UPDATE_IS_SP_MERCHANT: "HandheldStoreActions.UPDATE_IS_SP_MERCHANT",
      SET_DIRECT_SALE_MODE: "HandheldStoreActions.SET_DIRECT_SALE_MODE",
    };

    this.events = {
      SP_MERCHANTS_UPDATED: "SP_MERCHANTS_UPDATED",
      TABLE_UPDATED: "TABLE_UPDATED",
      TABLE_CREATED: "TABLE_CREATED",
      RECEIPT_UPDATED: "RECEIPT_UPDATED",
      RECEIPT_CREATED: "RECEIPT_CREATED",
      RECEIPT_CLOSED: "RECEIPT_CLOSED",
      TARECEIPT_UPDATED: "RECEIPT_UPDATED",
      TARECEIPT_CREATED: "RECEIPT_CREATED",
      TARECEIPT_CLOSED: "RECEIPT_CLOSED",
    };

    this.tables = {};
    this.handheldReceipts = {};
    this.taReceipts = {};
    this.spMerchants = {};
    this.isInDirectSaleMode = false;
  }

  /**
   * @param {object} action
   */
  _handleActions(action) {
    switch (action.type) {
      case this.actions.SET_SP_MERCHANTS:
        action.spMerchants.forEach((spMerchant) => {
          this.spMerchants[spMerchant.id] = spMerchant;
        });
        break;
      case this.actions.UPDATE_TABLE:
        this.tables[action.spMerchantId][action.table.id] = action.table;
        this.emit(this.events.TABLE_UPDATED, action.table.id);
        break;
      case this.actions.CREATE_TABLE:
        if (this.tables[action.spMerchantId] == null) {
          this.tables[action.spMerchantId] = {};
        }
        this.tables[action.spMerchantId][action.table.id] = action.table;
        this.emit(this.events.TABLE_CREATED);
        break;
      case this.actions.UPDATE_RECEIPT:
      case this.actions.CREATE_RECEIPT:
        if (this.handheldReceipts[action.receipt.id] != null) {
          let oldTableId = this.handheldReceipts[action.receipt.id].tableId;
          let newTableId = action.receipt.tableId;
          let oldStatus = this.handheldReceipts[action.receipt.id].status;

          if (oldTableId !== newTableId) {
            // Table Changed, disconnect old table
            this.tables[action.spMerchantId][oldTableId].receiptId = null;
            this.emit(this.events.TABLE_UPDATED, oldTableId);
            // connect new table
            this.tables[action.spMerchantId][newTableId].receiptId = action.receipt.id;
            this.emit(this.events.TABLE_UPDATED, newTableId);
          }

          this.handheldReceipts[action.receipt.id] = action.receipt;
          if (oldStatus !== "CLOSED" && action.receipt.status === "CLOSED") {
            if (this.tables[action.spMerchantId][action.receipt.tableId] != null) {
              this.tables[action.spMerchantId][action.receipt.tableId].receiptId = null;
              this.emit(this.events.TABLE_UPDATED, action.receipt.tableId);
            }
            this.emit(this.events.RECEIPT_CLOSED, action.receipt.id);
          } else if (action.receipt.status !== "CLOSED") {
            if (this.tables[action.spMerchantId][action.receipt.tableId] == null) {
              this.tables[action.spMerchantId][action.receipt.tableId] = new Table(
                action.receipt.tableId,
                action.receipt.tableId,
                action.receipt.tableId,
                action.receipt.id,
                false,
                false,
                []
              );
            }
            if (this.tables[action.spMerchantId][action.receipt.tableId].receiptId == null) {
              this.tables[action.spMerchantId][action.receipt.tableId].receiptId = action.receipt.id;
              this.emit(this.events.TABLE_UPDATED, action.receipt.tableId);
            }
            this.emit(this.events.RECEIPT_UPDATED, action.receipt.id);
          }
          break;
        } else {
          // NO BREAK
          this.handheldReceipts[action.receipt.id] = action.receipt;
          if (action.receipt.status !== "CLOSED") {
            let table = this.tables[action.spMerchantId][action.receipt.tableId];
            if (table != null) {
              table.receiptId = action.receipt.id;
              this.tables[action.spMerchantId][action.receipt.tableId] = table;
              this.emit(this.events.TABLE_UPDATED, action.receipt.tableId);
            } else {
              this.tables[action.spMerchantId][action.receipt.tableId] = new Table(
                action.receipt.tableId,
                action.receipt.tableId,
                action.receipt.tableId,
                action.receipt.id,
                false,
                false,
                []
              );
              this.emit(this.events.TABLE_CREATED, action.receipt.tableId);
            }
          }
          this.emit(this.events.RECEIPT_CREATED, action.receipt.id);
        }
        break;
      case this.actions.CLOSE_RECEIPT:
        let receiptId = action.receiptId;
        let tableId = action.tableId;
        let table = this.tables[action.spMerchantId][tableId];
        if (table != null && table.receiptId === receiptId) {
          table.receiptId = null;
          this.emit(this.events.TABLE_UPDATED, tableId);
        }
        this.emit(this.events.RECEIPT_CLOSED, receiptId);
        break;
      case this.actions.DELETE_RECEIPT:
        {
          let receiptId = action.receiptId;
          let tableId = action.tableId;
          let table = this.tables[action.spMerchantId][tableId];
          delete this.handheldReceipts[receiptId];
          if (table != null && table.receiptId === receiptId) {
            table.receiptId = null;
            this.emit(this.events.TABLE_UPDATED, tableId);
          }
          this.emit(this.events.RECEIPT_CLOSED, receiptId);
        }
        break;
      case this.actions.UPDATE_TARECEIPT:
        if (this.taReceipts[action.receipt.id] != null) {
          this.taReceipts[action.receipt.id] = action.receipt;
          this.emit(this.events.TARECEIPT_UPDATED, action.receipt.id);
          break;
        }
        break;
      case this.actions.CREATE_TARECEIPT:
        this.taReceipts[action.receipt.id] = action.receipt;
        this.emit(this.events.TARECEIPT_CREATED, action.receipt.id);
        break;
      case this.actions.CLOSE_TARECEIPT:
        delete this.taReceipts[action.receiptId];
        this.emit(this.events.TARECEIPT_CLOSED, action.receiptId);
        break;
      case this.actions.SET_DIRECT_SALE_MODE:
        this.isInDirectSaleMode = action.isInDirectSaleMode;
        break;

      default:
        // console.log("Unknown action in Handheld Store");
        break;
    }
  }
}

const handheldStore = new HandheldStore();
mynOberDispatcher.register(handheldStore._handleActions.bind(handheldStore));
export default handheldStore;
window.handheldStore = handheldStore;
