import { Article } from "../menu/Article";
import { OrderModifiergroup } from "./OrderModifiergroup";

export class OrderArticle extends Article {
  constructor(article, count, orderModifiergroups: OrderModifiergroup[], remark = null, name = null, price = null) {
    super(
      article.id,
      article.allergenIds,
      article.articlegroupId,
      article.description,
      article.modifiergroupIds,
      name ?? article.name,
      article.path,
      article.addImagePrefix,
      price ?? article.price,
      article.priority,
      article.weight,
      article.openingHours,
      article.isActive,
      article.menuTags,
      article.category,
      article.isMenu,
      article.type,
      article.originalPrice,
      article.plu,
      article.maxN,
      article.extraMessage,
      article.allowRemark,
      article.handheld_bg_color,
      article.min_amount,
      article.pos_name,
      article.supergroup_tags,
      article.shortKey,
      article.view_only
    );
    this.count = count;
    this.orderModifiergroups = orderModifiergroups;
    this.remark = remark;
  }

  get amount() {
    let totalAmount = this.price * this.count;
    this.orderModifiergroups.forEach((orderModifiergroup: OrderModifiergroup) => {
      orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
        if(orderModifierarticle.isSideDish && false) {
          totalAmount += orderModifierarticle.price * orderModifierarticle.count;
        } else {
          totalAmount += orderModifierarticle.price * orderModifierarticle.count * this.count;
        }
      })
    });
    return totalAmount;
  }

  get hasNumerableOrderModifiergroup() {
    return this.orderModifiergroups.some((orderModifiergroup: OrderModifiergroup) => {
      return orderModifiergroup.orderModifierarticles.some((orderModifierarticle) => {
        return orderModifierarticle.isSideDish
      })
    });
  }

  /**
   *
   * @param {*} obj
   * @returns {boolean}
   */
  equals(obj) {
    if (!(obj instanceof OrderArticle)) {
      return false;
    }
    if (obj.id !== this.id) {
      return false;
    }
    if (obj.name !== this.name) {
      return false;
    }
    if (obj.price !== this.price) {
      return false;
    }
    if (obj.remark !== this.remark) {
      return false;
    }

    if (obj.orderModifiergroups.length !== this.orderModifiergroups.length) {
      return false;
    }

    if (obj !== this && (obj.hasNumerableOrderModifiergroup || this.hasNumerableOrderModifiergroup)) {
      // return false;
    }

    return !obj.orderModifiergroups.some((objOrderModifiergroup: OrderModifiergroup) => {
      return (
        this.orderModifiergroups.findIndex((orderModifiergroup: OrderModifiergroup) => {
          return objOrderModifiergroup.equals(orderModifiergroup);
        }) === -1
      );
    });
  }

  toApiForm = () => {
    let json = {
      m_article_id: this.id,
      amount: this.count,
      remark: this.remark,
      m_modifiergroups: this.orderModifiergroups.map((orderModifiergroup: OrderModifiergroup) =>
        orderModifiergroup.toApiForm()
      ),
    };

    if (this.type == null || this.type === "FREETPARTICLE") {
      json["name"] = this.name;
      json["price"] = this.price;
    }
    return json;
  };
}

window.OrderArticle = OrderArticle;
