import React from "react";
import "./ScrollableTabBar.css";
import { stickyHeaderId } from "../../containers/smartphone/TableOrderContainer";
import SearchBar from "../SearchBar";
import ScrollableTabBar from "./ScrollableTabBar";
import { makeStyles } from "@material-ui/core/styles";
import mynOberStore from "../../utils/flux/MynOberStore";
import { ScrollableTabBarSupergroups } from "./ScrollableTabBarSupergroups";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: theme.palette.background.default },
}));

export default function StickyHeaderMenu(props) {
  const classes = useStyles();

  return (
    <div
      className={classes.root}
      id={stickyHeaderId}
      style={{
        top: "0",
        zIndex: 5,
        paddingTop: "8px",
        width: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? 320 : null,
        overflowY: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "auto" : null,
        height: mynOberStore.isHandheld() && mynOberStore.isWidescreen ? "100%" : null,
      }}
    >
      <SearchBar value={props.filter} onChange={props.onChangeFilter} placeHolder={"input_filter"} />
      {props.supergroups.length > 0 ? (
        <ScrollableTabBarSupergroups
          supergroups={props.supergroups}
          selectedSupergroupIndex={props.selectedSupergroupIndex}
          onTabClicked={(e, index) => {
            props.updateMenu(null, props.filter, index);
          }}
        />
      ) : null}
      <ScrollableTabBar tabs={props.articlegroupTabs} orientation={"row"} />
    </div>
  );
}
