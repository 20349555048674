import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import mynOberStore from "../../utils/flux/MynOberStore";
import { Button } from "@material-ui/core";

export const scrollableTabBarId = "scrollable-tab-bar-supergroups";

export function ScrollableTabBarSupergroups(props) {
  const buttonRefs = useRef([]);
  useEffect(() => {
    buttonRefs.current.forEach((buttonRef) => {
      let buttonWidth = buttonRef.getBoundingClientRect().width;
      let buttonHeight = buttonRef.getBoundingClientRect().height;
      let spanWidth = buttonRef.children[0].scrollWidth;
      let spanHeight = buttonRef.children[0].scrollHeight;

      let zoom = spanWidth / (buttonWidth - 15);
      zoom = Math.max(zoom, spanHeight / (buttonHeight - 15));
      if (zoom > 1) {
        buttonRef.children[0].style.zoom = 1 / zoom;
      }
    });
  }, []);

  return (
    <div
      id={scrollableTabBarId}
      className={
        props.tabs?.length > 0 ? "scrollable-tab-bar-root scrollable-tab-bar-root-borders" : "scrollable-tab-bar-root"
      }
      style={{
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        flex: "1",
        flexWrap: "nowrap",
        overflow: "auto",
      }}
    >
      <div
        style={{
          flexDirection: props.orientation,
          margin: 8,
          display: "flex",
          flex: "1 1 auto",
          width: 0,
          justifyContent: "center",
        }}
      >
        {props.supergroups.map((supergroup, index) => {
          return (
            <Button
              ref={(el) => (buttonRefs.current[index] = el)}
              style={{ marginLeft: 8, padding: 5, minWidth: 64, minHeight: 32 }}
              color={props.selectedSupergroupIndex === index ? "primary" : "default"}
              onClick={(e) => props.onTabClicked(e, index)}
              variant={props.selectedSupergroupIndex === index ? "contained" : "outlined"}
            >
              {supergroup.name}
            </Button>
          );
        })}
      </div>
    </div>
  );
}

ScrollableTabBarSupergroups.propTypes = {
  tabs: PropTypes.array.isRequired,
};

export default ScrollableTabBarSupergroups;
