import React, { Component } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import messages from "./assets/locales/web/messages";
import { BrowserRouter } from "react-router-dom";
import "./app.css";
import ScrollToTop from "./javascript/utils/ScrollToTop";
import * as listeners from "./javascript/utils/Listeners";
import mynOberAPIClientManager from "./javascript/utils/api/MynOberAPIClientManager";
import * as mynOberActions from "./javascript/utils/flux/MynOberActions";
import * as platformFunctions from "./javascript/utils/functions/PlatformFunctions";
import * as errorHandler from "./javascript/utils/error_handler/ErrorHandler";
import ReactGA from "react-ga";
import Loader from "./javascript/components/Loader";
import ClearCache from "react-clear-cache";
import mynOberStore from "./javascript/utils/flux/MynOberStore";
import MainWrapper from "./MainWrapper";
import { localStore } from "./javascript/utils/local_storage/LocalStorage";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import * as requestFunctions from "./javascript/utils/functions/RequestFunctions";
import { SpMerchant } from "./javascript/utils/merchant/SpMerchant";

ReactGA.initialize("UA-131778062-1", { standardImplementation: true });

export const backgroundOverlayId = "background-overlay";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHandheldLoggedIn: props.store.isHandheld() ? props.store.user.loggedIn : false,
      isLoading: false,
      isWidescreen: mynOberStore.isWidescreen,
      isDisconnected: false,
    };

    listeners.registerAppListener(this.login);
    listeners.registerLoadingListeners(this.onLoadingChanged);
    listeners.registerWideScreenListeners(this.onWideScreenChanged);
  }

  componentDidMount(): void {
    document.addEventListener("visibilitychange", this.handleVisibilityChange, false);
    window.addEventListener("resize", this.checkDimensions);
    window.addEventListener("online", this.handleInternetConnectionChange);
    window.addEventListener("offline", this.handleInternetConnectionChange);

    if (mynOberStore.isHandheld()) {
      mynOberStore.on(mynOberStore.events.MENU_CREATED, this.loadHandheldLoginSettings);
      mynOberStore.on(mynOberStore.events.LOGOUT_HANDHELD_SUCCESSFUL, this.onHandheldLogout);
    }
    setTimeout(function () {
      let viewheight = window.innerHeight;
      let viewwidth = window.innerWidth;
      let viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute("content", "height=" + viewheight + ", width=" + viewwidth + ", initial-scale=1.0");
    }, 300);
    // this.checkIfAppIsVisible();
  }

  // checkIfAppIsVisible = () => {
  //   setTimeout(() => {
  //     this.checkIfAppIsVisible();
  //   }, 3000)
  // };

  componentWillUnmount() {
    listeners.unregisterAppListener(this.login);
    listeners.unregisterLoadingListeners(this.onLoadingChanged);
    listeners.unregisterWideScreenListeners(this.onWideScreenChanged);

    document.removeEventListener("visibilitychange", this.handleVisibilityChange, false);
    window.removeEventListener("resize", this.checkDimensions);
    window.removeEventListener("online", this.handleInternetConnectionChange);
    window.removeEventListener("offline", this.handleInternetConnectionChange);

    if (mynOberStore.isHandheld()) {
      mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, this.loadHandheldLoginSettings);
      mynOberStore.removeListener(mynOberStore.events.LOGOUT_HANDHELD_SUCCESSFUL, this.onHandheldLogout);
    }
  }

  checkDimensions = () => {
    if (window.innerWidth >= 960) {
      if (!mynOberStore.isWidescreen) {
        mynOberActions.setIsWidescreen(true);
        this.forceUpdate();
      }
    } else {
      if (mynOberStore.isWidescreen) {
        mynOberActions.setIsWidescreen(false);
        this.forceUpdate();
      }
    }
  };

  handleInternetConnectionChange = (event) => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              if (mynOberStore.merchant instanceof SpMerchant) {
                requestFunctions.getReceipt(false, null);
              }
              return clearInterval(webPing);
            });
          })
          .catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  handleVisibilityChange = (event) => {
    mynOberActions.setIsAppVisible(event.target.visibilityState === "visible");
  };

  loadHandheldLoginSettings = () => {
    this.setState({
      isHandheldLoggedIn: mynOberStore.isHandheld() ? mynOberStore.user.loggedIn : false,
    });
  };

  login = () => {
    mynOberActions.setIsLoading(true);
    platformFunctions.getFirebaseToken().then((firebaseToken) => {
      mynOberAPIClientManager
        .login(mynOberAPIClientManager.constructLoginData(firebaseToken))
        .then(() => {
          mynOberActions.setIsLoading(false);
        })
        .catch((error) => {
          errorHandler.sendError(error);
          mynOberActions.setIsLoading(false);
        });
    });
  };

  onHandheldLogout = () => {
    mynOberAPIClientManager.removeHeader("Authorization");
    this.loadHandheldLoginSettings();
  };

  onLoadingChanged = () => {
    if (this.state.isLoading !== mynOberStore.isLoading) {
      this.setState({ isLoading: mynOberStore.isLoading });
    }
  };

  onWideScreenChanged = () => {
    this.setState({ isWidescreen: mynOberStore.isWidescreen });
  };

  render() {
    let locale = "en";
    if (navigator.language.substr(0, 2) === "nl") {
      locale = "nl";
    } else if (navigator.language.substr(0, 2) === "de") {
      locale = "de";
    } else if (navigator.language.substr(0, 2) === "es") {
      locale = "es";
    }

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;
    var auto = false;
    if (!isIE && !isEdge) {
      auto = true;
    }
    return (
      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <ClearCache auto={auto} localStore={localStore}>
          {({ isLatestVersion, emptyCacheStorage }) => (
            <div>{!isLatestVersion && <div>{!isLatestVersion && auto && emptyCacheStorage()}</div>}</div>
          )}
        </ClearCache>
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
          onError={() => {
            // console.log("error translation")
          }}
        >
          <BrowserRouter>
            <ScrollToTop>
              <div id={backgroundOverlayId} className={"background-overlay"} />
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={this.state.isDisconnected}
                onClose={() => {}}
              >
                <Alert severity="error">
                  <FormattedMessage id="internet_connection_lost" defaultMessage="internet_connection_lost" />
                </Alert>
              </Snackbar>
              <MainWrapper
                isHandheldLoggedIn={this.state.isHandheldLoggedIn}
                forceDarkmode={this.props.forceDarkmode}
              />
              {this.state.isLoading && <Loader />}
            </ScrollToTop>
          </BrowserRouter>
        </IntlProvider>
      </div>
    );
  }
}

export default App;
