export class HandheldOrdergroup {
  constructor(
    id, // sp_ordergroup_id
    isHandheld,
    isUser,
    createdAt,
    receiptArticles = [],
    isAyceOrdergroup,
    status,
    paymentStatus,
    posStatus,
    posAttempts,
    ordergroupId // ordergroup_id
  ) {
    this.id = id;
    this.isHandheld = isHandheld;
    this.isUser = isUser;
    this.createdAt = createdAt;
    this.receiptArticles = receiptArticles;
    this.isAyceOrdergroup = isAyceOrdergroup;
    this.status = status;
    this.paymentStatus = paymentStatus;
    this.posStatus = posStatus;
    this.posAttempts = posAttempts;
    this.ordergroupId = ordergroupId;
  }
}
