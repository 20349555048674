export class Table {
  /**
   *
   * @param {string} id
   * @param {string} qrcode
   * @param {string} name
   * @param {number|null} receiptId
   */
  constructor(id, qrcode, name, receiptId, is_every_order_paid, hasQrCode, menuTags) {
    this.id = id;
    this.qrcode = qrcode;
    this.name = name;
    this.receiptId = receiptId;
    this.is_every_order_paid = is_every_order_paid;
    this.hasQrCode = hasQrCode;
    this.menuTags = menuTags;
  }

  get status() {
    if (this.receiptId == null) {
      return "Inactive";
    } else {
      return "Active";
    }
  }
}

export const status = Object.freeze({ CLOSED: "CLOSED", OPEN: "OPEN" });
