import { Merchant } from "./Merchant";

export class TaMerchant extends Merchant {
  constructor(
    id,
    merchantId,
    name,
    timezone,
    countryCodes,
    currency,
    location,
    isOpen,
    showAllergens,
    showDescription,
    openingHours,
    styling,
    manuallyClosed,
    paymentMethods,
    isMynbonnenMerchant,
    tip,
    menuTags,
    taMinimumPickupMinutes,
    deMinimumPickupMinutes,
    minutesPerBlock,
    numberOfBlocks,
    phone,
    notifications,
    email,
    asap,
    delivery,
    takeaway,
    minAmount,
    taPostalCodes,
    askMarketing,
    askContactDetails,
    askRemark,
    info,
    maxWeightPerTimeBlock,
    timeslots,
    deliveryTimes,
    active,
    orderMaxNumberOfDaysAhead,
    allowOrderingUntilClosing,
    isTables,
    takeawayActive,
    deliveryActive,
    taMasterMerchantId,
    orderRules,
    doTaPostalCodeCheck,
    takeawayOrderHours,
    deliveryOrderHours,
    image,
    max_ta_orders_blocks
  ) {
    super(
      id,
      merchantId,
      name,
      timezone,
      countryCodes,
      currency,
      location,
      isOpen,
      showAllergens,
      showDescription,
      openingHours,
      styling,
      manuallyClosed,
      paymentMethods,
      isMynbonnenMerchant,
      tip,
      menuTags
    );

    this._taMinimumPickupMinutes = taMinimumPickupMinutes;
    this._deMinimumPickupMinutes = deMinimumPickupMinutes;
    this._minutesPerBlock = minutesPerBlock;
    this._numberOfBlocks = numberOfBlocks;
    this._usePhone = phone;
    this._useNotifications = notifications;
    this._useEmail = email;
    this._asap = asap;
    this._delivery = delivery;
    this._takeaway = takeaway;
    this.minAmount = minAmount;
    this.taPostalCodes = taPostalCodes;
    this.askMarketing = askMarketing;
    this.askContactDetails = askContactDetails;
    this.askRemark = askRemark;
    this.info = info;
    this.maxWeightPerTimeBlock = maxWeightPerTimeBlock;
    this.timeslots = timeslots;
    this.deliveryTimes = deliveryTimes;
    this.active = active;
    this.orderMaxNumberOfDaysAhead = orderMaxNumberOfDaysAhead;
    this.allowOrderingUntilClosing = allowOrderingUntilClosing;
    this.isTables = isTables;
    this.takeawayActive = takeawayActive;
    this.deliveryActive = deliveryActive;
    this.isOrdering = true;
    this.taMasterMerchantId = taMasterMerchantId;
    this.orderRules = orderRules;
    this.doTaPostalCodeCheck = doTaPostalCodeCheck;
    this.takeawayOrderHours = takeawayOrderHours;
    this.deliveryOrderHours = deliveryOrderHours;
    this.image = image;
    this.max_ta_orders_blocks = max_ta_orders_blocks;
  }

  /**
   *
   * @return {number}
   */
  get taMinimumPickupMinutes() {
    return this._taMinimumPickupMinutes;
  }

  /**
   *
   * @return {number}
   */
  get deMinimumPickupMinutes() {
    return this._deMinimumPickupMinutes;
  }

  /**
   *
   * @return {number}
   */
  get minutesPerBlock() {
    return this._minutesPerBlock;
  }

  /**
   *
   * @return {number}
   */
  get numberOfBlocks() {
    return this._numberOfBlocks;
  }

  /**
   *
   * @return {boolean}
   */
  get usePhone() {
    return this._usePhone;
  }

  /**
   * @override
   * @return {boolean}
   */
  get isOpen() {
    return this._isOpen;
  }

  /**
   * @returns {boolean}
   */
  get useNotifications(): boolean {
    return this._useNotifications;
  }

  /**
   *
   * @return {boolean}
   */
  get useEmail(): boolean {
    return this._useEmail;
  }

  /**
   * @returns {boolean}
   */
  get asap(): boolean {
    return this._asap;
  }

  get delivery(): boolean {
    return this._delivery;
  }

  get takeaway(): boolean {
    return this._takeaway;
  }
}
