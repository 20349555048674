import * as locationFunctions from "../location_manager/LocationFunctions";
import mynOberAPIClientManager from "../api/MynOberAPIClientManager";
import * as mynOberActions from "../flux/MynOberActions";
import mynOberStore from "../flux/MynOberStore";
import { Merchant } from "../merchant/Merchant";
import { Table } from "../Table";
import { localStore, sessionStore } from "../local_storage/LocalStorage";

/**
 *
 * @param {bool} withLoading
 * @param {string=} webcode
 */
export function getReceipt(withLoading, webcode) {
  return new Promise((resolve, reject) => {
    if (withLoading) {
      mynOberActions.setIsLoading(true);
    }

    mynOberAPIClientManager
      .getReceipt(webcode ?? mynOberStore.webcode)
      .then(({ receipt, wrongPincode }) => {
        if (mynOberStore.isInvalidPincode && !wrongPincode) {
          mynOberStore.isInvalidPincode = false;
          mynOberStore.emit(mynOberStore.events.MENU_CREATED);
        }
        if (withLoading) {
          mynOberActions.setIsLoading(false);
        }
        if (receipt == null) {
          mynOberActions.clearReceipt();
        } else {
          mynOberActions.setReceipt(receipt);
        }
        resolve();
      })
      .catch((error) => {
        if (withLoading) {
          mynOberActions.setIsLoading(false);
        }
        reject(error.message);
      });
  });
}

/**
 *
 * @param {string} webcode
 * @param {{latitude: number, longitude: number}} merchantLocation
 * @param  distance
 */
export function sendMoveRequest(webcode, distance) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_MOVE,
        mynOberAPIClientManager.constructMoveData(distance, webcode)
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        mynOberActions.setWebcode(webcode);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function sendVoid(voidData, tableId, spMerchantId) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_VOID, {
        sp_merchant_id: spMerchantId,
        m_articles: voidData,
        table_identifier: tableId
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function send(suffixUrl, data) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, suffixUrl, data)
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function addPayment(m_articles, tableId, spMerchantId, paymentMethod) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_ADD_PAYMENT, {
        sp_merchant_id: spMerchantId,
        m_articles: m_articles,
        table_identifier: tableId,
        payment_method: paymentMethod
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function updateFloorPlan(jsonFloorPlan, spMerchant) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_UPDATE_FLOOR_PLAN + "/" + spMerchant.id,
        {
          floor_plan: jsonFloorPlan
        }
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {string} phoneNumber
 * @param {Merchant} merchant
 * @param distance
 * @param onURLCreated
 * @param extraData
 */
export function sendOrder(phoneNumber, merchant, distance, onURLCreated, extraData = {}) {
  let returnDomain = window.location.hostname;
  if (mynOberStore.merchant.info != null && mynOberStore.merchant.info.paynl_return_domain) {
    returnDomain = mynOberStore.merchant.info.paynl_return_domain;
  }

  let pincode = localStore.getItem("pincode");

  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);

    try {
      mynOberAPIClientManager
        .post(mynOberAPIClientManager.getApiSuffixForMerchant(merchant), mynOberAPIClientManager.apiPost.POST_ORDER, {
          ...mynOberAPIClientManager.constructOrderData(distance, phoneNumber, returnDomain),
          ...extraData,
          pincode,
          mynOberStore: {
            // merchant: mynOberStore.merchant,
            // menusWithLag: mynOberStore.menusWithLag,
            // menus: mynOberStore.menus,
            // receipt: mynOberStore.receipt,
            // spQrCode: mynOberStore.spQrCode,
            // order: mynOberStore.order,
            // takeawayOrder: mynOberStore.takeawayOrder,
            // deliveryOrder: mynOberStore.deliveryOrder,
            // user: mynOberStore.user,
            // webcode: mynOberStore.webcode
          }
        })
        .then((url) => {
          if (url instanceof Object && url["sharedOrderUuid"] !== undefined) {
            mynOberActions.setIsLoading(false);
            resolve(url);
            return;
          }
          if (url === undefined) {
            mynOberActions.setIsLoading(false);
            mynOberActions.orderSendSuccessful();
            resolve();
          } else if (url instanceof Object) {
            if (url?.message === "waiting for pin transaction") {
              mynOberActions.setIsLoading(false);
              mynOberActions.setPinTransaction(url.transaction);
            } else {
              mynOberActions.setIsLoading(false);
              resolve(url);
            }
          } else {
            onURLCreated(url);
          }
        })
        .catch((error) => {
          mynOberActions.setIsLoading(false);
          reject(error.message);
        });
    } catch (e) {
      mynOberActions.setIsLoading(false);
      throw e
    }
  });
}

export function transferTable(spReceipt, table) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_TRANSFER_TABLE, {
        sp_merchant_id: spReceipt.spMerchantId,
        sp_receipt_id: spReceipt.id,
        table_id: table.id,
        to_qr_code: table.qrcode
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {Merchant} merchant
 * @param {Article} serviceArticle
 */
export function sendOrderServiceRequest(merchant, serviceArticle) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.getApiSuffixForMerchant(merchant), mynOberAPIClientManager.apiPost.POST_ORDER, {
        ...mynOberAPIClientManager.constructOrderServiceRequestData(serviceArticle),
        service: true
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function sendSplit(merchant: Merchant, receiptArticles: Array, toTable: Table, fromTable: Table) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.getApiSuffixForMerchant(merchant), mynOberAPIClientManager.apiPost.POST_SPLIT, {
        m_articles: receiptArticles,
        toQrCode: toTable.qrcode,
        fromQrCode: fromTable.qrcode
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
        // resolve();
      });
  });
}

/**
 *
 * @param {number} pricePayment
 * @param {number} priceReceipt
 * @param {string} paymentType
 */
export function reopenSpReceipt(spReceiptId) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_RECEIPT_REOPEN, {
        spReceiptId: spReceiptId
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function reopenTaReceipt(taReceiptId) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_TA_RECEIPT_REOPEN, {
        ta_receipt_id: taReceiptId
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function tryAgainFailedOrder(ordergroupId: number) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);

    let requestData = {
      sp_merchant_id: mynOberStore.merchant.id,
      qr_code: mynOberStore.webcode,
      ordergroup_id: ordergroupId
    };

    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_TRY_AGAIN_FAILED_ORDER,
        requestData
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function taReceiptClose(id: number, printTakeawayBill = true, paymentMethod) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_TA_RECEIPT_CLOSE + "/" + id, {
        print_ta_bill: printTakeawayBill,
        payment_method: paymentMethod
      })
      .then((response) => {
        if (response?.message === "waiting for pin transaction") {
          mynOberActions.setIsLoading(false);
          mynOberActions.setPinTransaction(response.transaction);
        } else {
          mynOberActions.setIsLoading(false);
          resolve(response);
        }
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function updateTakeawayOrderStatus(id, newStatus, inMinutes = null) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_UPDATE_TAKEAWAY_ORDER_STATUS + "/" + id,
        { newStatus, inMinutes }
      )
      .then((data) => {
        mynOberActions.setIsLoading(false);
        resolve(data);
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function updateUbereatsOrderStatus(id, newStatus, inMinutes = null) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_UPDATE_UBEREATS_ORDER_STATUS + "/" + id,
        { newStatus, inMinutes }
      )
      .then((data) => {
        mynOberActions.setIsLoading(false);
        resolve(data);
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function updatePreorderTakeawayOrderStatus(id, newStatus, relativeMinutes = null) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_UPDATE_PREORDER_TAKEAWAY_ORDER_STATUS + "/" + id,
        { newStatus, relativeMinutes }
      )
      .then((data) => {
        mynOberActions.setIsLoading(false);
        resolve(data);
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function sendTaNotification(taReceiptId, notificationName = null) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_SP,
        mynOberAPIClientManager.apiPost.POST_TA_RECEIPT_NOTIFICATION + "/" + taReceiptId,
        { notification_name: notificationName }
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {number} id
 */
export function taReceiptVoid(id) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_TA_RECEIPT_VOID + "/" + id, {})
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {number} id
 * @param {boolean} mustClose
 * @param {string} choice
 */
export function printClose(id, mustClose, choice, spMerchantId) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PRINT_REQUEST, {
        must_close: mustClose,
        table_identifier: id,
        choice: choice,
        sp_merchant_id: spMerchantId
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function printTaReceipt(id) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PRINT_TA_RECEIPT_REQUEST, {
        ta_receipt_id: id
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function printInhouseOrder(spOrdergroupId) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PRINT_SP_ORDERGROUP_REQUEST, {
        sp_ordergroup_id: spOrdergroupId
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function printBillTaReceipt(id) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PRINT_BILL_TA_RECEIPT_REQUEST, {
        ta_receipt_id: id
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function printBillSpReceipt(id) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PRINT_BILL_SP_RECEIPT_REQUEST, {
        sp_receipt_id: id
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function sendPaymentMethods(id, mustClose, paymentMethods, spMerchantId, bill, printInhouseBill = true) {
  let paymentMethodsToSend = paymentMethods.map((paymentMethod) => {
    return { name: paymentMethod.name, amount: paymentMethod.amount, paynlPinTerminal: paymentMethod.paynlPinTerminal,paynlMerchant: paymentMethod.paynlMerchant  };
  });

  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_PAYMENT_METHODS_REQUEST, {
        must_close: mustClose,
        table_identifier: id,
        payment_methods: paymentMethodsToSend,
        sp_merchant_id: spMerchantId,
        print_inhouse_bill: printInhouseBill,
        updated_at: bill.receipt.updatedAt
      })
      .then((data) => {
        if (data?.message === "waiting for pin transaction") {
          mynOberActions.setIsLoading(false);
          mynOberActions.setPinTransaction(data.transaction);
        } else {
          mynOberActions.setIsLoading(false);
          resolve(data);
        }
      })
      .catch((message) => {
        mynOberActions.setIsLoading(false);
        reject(message);
      });
  });
}

export function getTransaction(id) {
  return new Promise((resolve) => {
    mynOberAPIClientManager
      .get(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.GET_TRANSACTION + "/" + id,"")
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        resolve(error);
      });
  });
}

/**
 *
 * @param {string} type
 */
export function report(type) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_REPORT, {
        type: type
      })
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function opencashdrawer() {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(mynOberAPIClientManager.api.URL_SP, mynOberAPIClientManager.apiPost.POST_COMMAND_OPEN_CASH_DRAWER)
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function sendPayment(paymentMethod, extra) {
  return new Promise((resolve, reject) => {
    let returnDomain = window.location.hostname;
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.getApiSuffixForMerchant(mynOberStore.merchant),
        mynOberAPIClientManager.apiPost.POST_PAYMENT,
        { paymentMethod: { name: paymentMethod }, returnDomain: returnDomain, ...extra }
      )
      .then((url) => {
        mynOberActions.setIsLoading(false);
        if (url === undefined) {
          mynOberActions.orderSendSuccessful();
          resolve();
        } else {
          window.location = url;
        }
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {string} paymentMethod
 * @param {boolean} hasReceipt
 * @param {Merchant} merchant
 * @param {boolean} needsDistance
 */
export function sendPaymentRequest(paymentMethod, hasReceipt, merchant, distance) {
  return new Promise((resolve, reject) => {
    if (!mynOberStore.isLoading) {
      mynOberActions.setIsLoading(true);
    }

    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.getApiSuffixForMerchant(merchant),
        mynOberAPIClientManager.apiPost.POST_PAYMENT_REQUEST,
        mynOberAPIClientManager.constructPaymentRequestData(distance, paymentMethod)
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {string} phoneNumber
 * @param {Merchant} merchant
 */
export function sendPhoneNumber(phoneNumber, merchant) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.getApiSuffixForMerchant(merchant),
        mynOberAPIClientManager.apiPost.POST_PHONE_NUMBER,
        mynOberAPIClientManager.constructPhoneNumberData(phoneNumber)
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        mynOberActions.setPhoneNumber(phoneNumber);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {object} contactDetails
 * @param {number} pickupTime
 * @param {PaymentMethod} paymentMethod
 * @param {string} remark
 * @param {function} onURLCreated
 */
export function sendTakeawayOrder(contactDetails, pickupTime, paymentMethod, remark, takeawayOrder, onURLCreated) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    // mynOberAPIClientManager.post(
    //   mynOberAPIClientManager.api.URL_TA,
    //   mynOberAPIClientManager.apiPost.POST_ORDER,
    //   mynOberAPIClientManager.constructTakeawayOrderData(parseInt((pickupTime / 1000), 10), paymentMethod, contactDetails)
    // ).then((url) => {
    // }).catch((error) => {
    // });
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_TA,
        mynOberAPIClientManager.apiPost.POST_ORDER,
        mynOberAPIClientManager.constructTakeawayOrderData(
          parseInt(pickupTime / 1000, 10),
          paymentMethod,
          contactDetails,
          remark,
          takeawayOrder
        )
      )
      .then((url) => {
        mynOberActions.setIsLoading(false);
        if (url === undefined) {
          mynOberActions.orderSendSuccessful();
          resolve();
        } else {
          onURLCreated(url);
        }
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {object} contactDetails
 * @param {DeliveryOrder} deliveryOrder
 * @param {function} onURLCreated
 */
export function sendDeliveryOrder(contactDetails, deliveryOrder, onURLCreated) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.api.URL_TA,
        mynOberAPIClientManager.apiPost.POST_ORDER_DELIVERY,
        mynOberAPIClientManager.constructDeliveryOrderData(contactDetails, deliveryOrder)
      )
      .then((url) => {
        mynOberActions.setIsLoading(false);
        if (url === undefined) {
          mynOberActions.orderSendSuccessful();
          resolve();
        } else {
          onURLCreated(url);
        }
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

/**
 *
 * @param {Merchant} merchant
 */
export function sendWaiterRequest(merchant, distance) {
  return new Promise((resolve, reject) => {
    if (!mynOberStore.isLoading) {
      mynOberActions.setIsLoading(true);
    }
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.getApiSuffixForMerchant(merchant),
        mynOberAPIClientManager.apiPost.POST_WAITER,
        mynOberAPIClientManager.constructWaiterCallData(distance)
      )
      .then(() => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function checkLocation() {
  return new Promise((resolve, reject) => {
    locationFunctions
      .getDistanceToMerchant(mynOberStore.needsDistanceForActions(), mynOberStore.merchant.location)
      .then((distance) => {
        resolve(distance);
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error);
      });
  });
}

/**
 *
 * @param {string} webcode
 */
export function sendWebcode(webcode) {
  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .getMerchantWithMenuForWebcode(webcode)
      .then((response) => {
        if (mynOberStore.receipt.webcode && webcode !== mynOberStore.receipt.webcode) {
          mynOberActions.clearReceipt();
        }
        mynOberActions.setIsLoading(false);
        mynOberActions.setMerchantWithMenuAndWebcode(response.merchant, response.menu, webcode);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function cancelSharedOrder() {
  let extraData = {};
  if (mynOberStore.sharedOrderUuid != null) {
    extraData.sharedOrderUuid = mynOberStore.sharedOrderUuid;
  }

  return new Promise((resolve, reject) => {
    mynOberActions.setIsLoading(true);
    mynOberAPIClientManager
      .post(
        mynOberAPIClientManager.getApiSuffixForMerchant(mynOberStore.merchant),
        mynOberAPIClientManager.apiPost.POST_CANCEL_SHARED_ORDER + "/" + mynOberStore.webcode,
        extraData
      )
      .then((response) => {
        mynOberActions.setIsLoading(false);
        resolve();
      })
      .catch((error) => {
        mynOberActions.setIsLoading(false);
        reject(error.message);
      });
  });
}

export function getSharedOrderTransactions() {
  return new Promise((resolve, reject) => {
    mynOberAPIClientManager
      .get(
        mynOberAPIClientManager.getApiSuffixForMerchant(mynOberStore.merchant),
        mynOberAPIClientManager.apiPost.GET_SHARED_ORDER + "/" + mynOberStore.webcode,
        ""
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
}
