import * as axios from "axios";
import mynOberStore from "../flux/MynOberStore";
import * as platformFunctions from "../functions/PlatformFunctions";
import * as mynOberActions from "../flux/MynOberActions";
import { setSharedOrderUuid } from "../flux/MynOberActions";
import * as handheldActions from "../flux/HandheldActions";
import { TaMerchant } from "../merchant/TaMerchant";
import * as jsonProcessingFunctions from "../functions/JsonProcessingFunctions";
import * as errorHandler from "../error_handler/ErrorHandler";
import { SpMerchant } from "../merchant/SpMerchant";
import { Order } from "../order/Order";
import Pusher from "pusher-js";
import * as sessionStorage from "../session_storage/SessionStorage";
import { OrderArticle } from "../order/OrderArticle";
import { OrderModifierarticle } from "../order/OrderModifierarticle";
import { OrderModifiergroup } from "../order/OrderModifiergroup";
import { processHandheldReceipt, processHandheldTaReceipt } from "../functions/processHandheldReceipt";
import handheldStore from "../flux/HandheldStore";
import * as taHandheldActions from "../flux/TaHandheldActions";
import taHandheldStore from "../flux/TaHandheldStore";
import * as requestFunctions from "../functions/RequestFunctions";
import { getSharedOrderTransactions } from "../functions/RequestFunctions";
import ReactGA from "react-ga";
import { localStore, sessionStore } from "../local_storage/LocalStorage";
import { getApplicationDate } from "../functions/HelperFunctions";

class MynOberAPIClientManager {
  constructor() {
    this.api = {
      URL: "https://api.mynober.nl", //"http://localhost:8081", //  "http://172.31.1.11:38625", // "http://localhost:8081", //  /*(process.env.NODE_ENV === "development") ? "https://development.mynober.nl" :*/
      URL_S3: "https://mynober-api-public.s3.eu-central-1.amazonaws.com",
      URL_SP: "/sp/v8",
      URL_TA: "/ta/v4",
      URL_AR: "/ar/v1",
    };

    this.api.pusherApiUrl =
      this.apiURL.includes("https://") ||
      this.apiURL.includes("https://api.mynober") ||
      this.apiURL.includes("https://apiv5.mynober") ||
      this.apiURL.includes("https://stagingapi.mynober") ||
      this.apiURL.includes("http://172.31.1.11:38625")
        ? "b15923cfd2476c260ece"
        : "0341ebd13255ac09ce1c";

    this.apiGet = {
      GET_ALLERGEN_IMAGE: "/images/allergens",
      GET_ARTICLE_IMAGE: "/images/m_articles",
      GET_MENU: "/menu",
      GET_MERCHANT: "/merchant",
      GET_MERCHANTS: "/merchants",
      GET_MERCHANT_IMAGE: "/images/merchant",
      GET_RECEIPT: "/receipt",
      GET_TARECEIPT: "/tareceipt",
      GET_ALL_OPEN_RECEIPT_OVERVIEW: "/receipt/alloverview",
      GET_ALL_OPEN_TARECEIPT_OVERVIEW: "/tareceipt/alloverview",
      GET_RETURN: "/return",
      GET_BROADCASTING: "/broadcasting/auth",
    };
    this.apiPost = {
      POST_ARTICLE_IMAGES: "/images/m_articles",
      POST_LOGIN: "/auth",
      POST_MOVE: "/receipt/move",
      POST_RECEIPT_REOPEN: "/receipt/reopen",
      POST_TA_RECEIPT_REOPEN: "/tareceipt/reopen",
      POST_ORDER: "/order",
      POST_TRY_AGAIN_FAILED_ORDER: "/order/tryagain",
      POST_SPLIT: "/handheld/split",
      POST_VOID: "/void",
      POST_ADD_PAYMENT: "/receipt/addPayment",
      POST_TRANSFER_TABLE: "/receipt/transfer",
      POST_ORDER_DELIVERY: "/order",
      POST_PAYMENT_REQUEST: "/pay/waiter",
      POST_PAYMENT: "/pay/online",
      POST_PRINT_REQUEST: "/pay/print",
      POST_PRINT_TA_RECEIPT_REQUEST: "/tareceipt/print",
      POST_PRINT_SP_ORDERGROUP_REQUEST: "/receipt/printordergroup",
      POST_PRINT_BILL_TA_RECEIPT_REQUEST: "/tareceipt/printbill",
      POST_PRINT_BILL_SP_RECEIPT_REQUEST: "/receipt/printbill",
      POST_TA_RECEIPT_CLOSE: "/tareceipt/close",
      POST_UPDATE_TAKEAWAY_ORDER_STATUS: "/tareceipt/takeawayorder/status",
      POST_UPDATE_UBEREATS_ORDER_STATUS: "/tareceipt/ubereatsorder/status",
      POST_UPDATE_PREORDER_TAKEAWAY_ORDER_STATUS: "/tareceipt/preordertakeawayorder/status",
      POST_TA_RECEIPT_VOID: "/tareceipt/void",
      POST_TA_RECEIPT_NOTIFICATION: "/tareceipt/notification",
      POST_PAYMENT_METHODS_REQUEST: "/pay/paymentmethods",
      GET_TRANSACTION: "/handheld/transactions",
      POST_REPORT: "/command/report",
      POST_COMMAND_OPEN_CASH_DRAWER: "/command/opencashdrawer",
      POST_PHONE_NUMBER: "/receipt/mobilenumber",
      POST_REFRESH: "/refresh",
      POST_UPDATE_SHARE_ORDER: "/shareorder/update",
      POST_CANCEL_SHARED_ORDER: "/shareorder/cancel",
      GET_SHARED_ORDER: "/shareorder/webcode",
      POST_WAITER: "/order/waiter",
      POST_BROADCASTING: "/broadcasting/auth",
      POST_UPDATE_FLOOR_PLAN: "/floor_plan",
    };
    this.apiMessages = {
      MENU: "menu",
      MERCHANT: "merchant",
      REDIRECT_LABEL: "redirect_label",
      MOVE_SUCCESSFUL: "move_successful",
      NO_ORDERS_FOUND: "no_orders_found",
      NUMBER_UPDATED: "number_updated",
      OPEN_WEBCODE_IN_WEBAPP: "open_webcode_in_webapp",
      ORDER_CREATED: "order_created",
      PLATFORM_ERROR: "PLATFORM_ERROR",
      PAYMENT_REQUEST_SEND: "payment_request_send",
      RECEIPT_SUCCESS: "receipt_success",
      RETURN: "return",
      SERVER_TIMEOUT: "SERVER_TIMEOUT",
      SP_RECEIPT_ALREADY_ACTIVE: "sp_receipt_already_active",
      SUCCESS: "success",
      TA_RECEIPT_CREATED: "ta_receipt_created",
      UNAUTHORIZED: "UNAUTHORIZED",
      UNKNOWN_ERROR: "UNKNOWN_ERROR",
      URL_CREATED: "url_created",
      WAITER_COMING: "waiter_coming",
      PRINT_SENT: "print_sent",
    };
    this.webUrl = {
      URL: "https://web.mynober.nl",
      WEBCODE: "/qr/",
    };

    // Basic setup for axios.
    this._axios = axios.create({
      baseURL: this.api.URL,
      headers: {
        Accept: "application/json",
      },
    });

    this._timeLimitTimer = null;
    this._pusher = null;
    this._menuIsUpdating = false;
    this._pusherConnectedAsAppUser = false;
    this._shouldMenuUpdate = false;
    this._orderSending = false;
    this.pusherChannels = [];
    window.clientManager = this;
  }

  /**
   * Return API URL constant.
   * @returns {string}
   */
  get apiURL() {
    return this.api.URL;
  }

  /**
   * Returns baseURL of the MynOberAPIClientManager.
   * @returns {string}
   */
  get baseURL() {
    return this._axios.defaults.baseURL;
  }

  /**
   * Sets baseURL with given apiSuffix.
   * @param {string} apiSuffix
   */
  set baseURL(apiURL) {
    this._axios.defaults.baseURL = apiURL;
  }

  /**
   * Returns request data for login and refresh request.
   * @param {string|null} firebaseToken
   * @return {Object}
   */
  constructLoginData(firebaseToken = null) {
    let requestData = {
      version_code: platformFunctions.getVersionCode(),
      device_type: platformFunctions.getDeviceType(),
      device_id: mynOberStore.user.deviceId,
      device_pass: mynOberStore.user.devicePass,
    };

    let dataFirebaseToken = "NO_TOKEN";
    if (firebaseToken !== null) {
      dataFirebaseToken = firebaseToken;
    }

    const refreshToken = mynOberStore.user.refreshToken;
    if (refreshToken !== null) {
      requestData.refresh_token = refreshToken;

      if (mynOberStore.isHandheld()) {
        let password = mynOberStore.user.password;
        let username = mynOberStore.user.username;
        if (username !== null && password !== null) {
          requestData.handheld_password = password;
          requestData.handheld_username = username;
        }
      }
    }

    if (refreshToken === null || dataFirebaseToken !== "NO_TOKEN") {
      requestData.firebase_token = dataFirebaseToken;
    }

    return requestData;
  }

  /**
   * Returns request data for login of handheld user.
   * @param {string} password
   * @param {string} username
   * @return {{firebase_token: string, version_code: number, device_type: string, device_id: string, device_pass: string, handheld_password: string, handheld_username: string}}
   */
  constructLoginHandheldData(password, username) {
    return {
      firebase_token: "NO_TOKEN",
      version_code: platformFunctions.getVersionCode(),
      device_type: platformFunctions.getDeviceType(),
      device_id: mynOberStore.user.deviceId,
      device_pass: mynOberStore.user.devicePass,
      refresh_token: mynOberStore.user.refreshToken,
      handheld_password: password,
      handheld_username: username,
    };
  }

  /**
   * Return request data for move request.
   * @param {number} distance
   * @param {string} webcode
   * @return {{sp_merchant_id: number, distance: number, qr_code: string, party_id: number}}
   */
  constructMoveData(distance, webcode) {
    return {
      sp_merchant_id: mynOberStore.merchant.id,
      distance: distance,
      qr_code: webcode,
      party_id: 0,
    };
  }

  /**
   * Returns request data for order request.
   * @param {number} distance
   * @param {?string} phoneNumber
   * @return {{sp_merchant_id: number, distance: number, m_articles: string, qr_code: string, party_id: number, mobile_number: string=}}
   */
  constructOrderData(distance, phoneNumber, returnDomain) {
    let transactions = {};
    mynOberStore.transactionUUIDs.forEach((uuid) => {
      transactions[uuid] = true;
    });

    let defaultOrderItems = [];

    if (mynOberStore.merchant?.info?.default_order_items instanceof Array) {
      defaultOrderItems = mynOberStore.merchant.info.default_order_items
        .filter((item) => {
          return mynOberStore.menu.articlesMapId[item.id] != null;
        })
        .map((orderArticle) => {
          return { m_article_id: orderArticle.id, amount: orderArticle.amount, m_modifiergroups: [] };
        });
    }

    let mArticles = mynOberStore.order.toJson(mynOberStore.receipt.timeIntervalRemaining > 0);
    if (mArticles.length === 0) {
      throw new Error("No articles to order");
    }
    mArticles = mArticles.concat(defaultOrderItems);

    let requestData = {
      sp_merchant_id: mynOberStore.merchant.id,
      distance: distance,
      m_articles: JSON.stringify(mArticles),
      qr_code: mynOberStore.webcode,
      party_id: 0,
      returnDomain: returnDomain,
      shared_order_transactions: transactions,
    };

    if (phoneNumber !== undefined) {
      requestData.mobile_number = phoneNumber;
    }

    return requestData;
  }

  /**
   * Returns request data for order request.
   * @param {number} distance
   * @param {?string} phoneNumber
   * @return {{sp_merchant_id: number, distance: number, m_articles: string, qr_code: string, party_id: number, mobile_number: string=}}
   */
  constructOrderServiceRequestData(serviceArticle) {
    let requestData = {
      sp_merchant_id: mynOberStore.merchant.id,
      distance: 0,
      m_articles: JSON.stringify([{ m_article_id: serviceArticle.id, amount: 1, m_modifiergroups: [] }]),
      qr_code: mynOberStore.webcode,
      party_id: 0,
    };
    return requestData;
  }

  /**
   * Returns request data for payment request.
   * @param {number} distance
   * @param {string} paymentMethod
   */
  constructPaymentRequestData(distance, paymentMethod) {
    return {
      payment_method: paymentMethod,
      distance: distance,
    };
  }

  /**
   * Returns request data for updating phoneNumber.
   * @param {string} phoneNumber
   * @return {{mobile_number: string}}
   */
  constructPhoneNumberData(phoneNumber) {
    return {
      mobile_number: phoneNumber,
    };
  }

  /**
   * Returns request data for takeaway order request.
   * @param {number} pickupTimestamp
   * @param {PaymentMethod} paymentMethod
   * @param {object} contactDetails
   * @param {string} remark
   * @param {TakeawayOrder} takeawayOrder
   * @return {{ta_merchant_id: number, m_articles: string, qr_code: string, pickup_timestamp: number, receipt_method: string, mobile_number: string}}
   */
  constructTakeawayOrderData(pickupTimestamp, paymentMethod, contactDetails, remark, takeawayOrder) {
    let completeMethod = null;
    if (paymentMethod["name"] === "IDEAL") {
      completeMethod = "ONLINE";
    } else {
      completeMethod = "OFFLINE";
    }

    let returnDomain = window.location.hostname;
    if (mynOberStore.merchant.info != null && mynOberStore.merchant.info.paynl_return_domain) {
      returnDomain = mynOberStore.merchant.info.paynl_return_domain;
    }

    let defaultOrderItems = [];
    if (mynOberStore.merchant.info != null && mynOberStore.merchant.info.default_order_items != null) {
      defaultOrderItems = mynOberStore.merchant.info.default_order_items
        .filter((item) => {
          return mynOberStore.menu.articlesMapId[item.id] != null;
        })
        .map((orderArticle) => {
          return { m_article_id: orderArticle.id, amount: orderArticle.amount, m_modifiergroups: [] };
        });
    }
    if (mynOberStore.userSelectedPackaging) {
      defaultOrderItems.push({ m_article_id: mynOberStore.userSelectedPackaging.id, amount: 1, m_modifiergroups: [] });
    }
    let mArticles = mynOberStore.order.toJson();
    mArticles = mArticles.concat(defaultOrderItems);

    return {
      ta_merchant_id: mynOberStore.merchant.id,
      m_articles: JSON.stringify(mArticles),
      qr_code: mynOberStore.webcode,
      pickup_timestamp: pickupTimestamp,
      receipt_method: completeMethod,
      mobile_number: contactDetails.phone,
      email: contactDetails.email,
      contactDetails: contactDetails,
      paymentMethodKey: paymentMethod["key"],
      returnDomain: returnDomain,
      remark: remark,
      is_takeaway: true,
      is_delivery: false,
      ...takeawayOrder,
    };
  }

  /**
   *
   * @param {object} contactDetails
   * @param {DeliveryOrder} deliveryOrder
   * @returns {{m_articles: string, qr_code: string, deliveryOrder, ta_merchant_id: number}}
   */
  constructDeliveryOrderData(contactDetails, deliveryOrder) {
    let returnDomain = window.location.hostname;
    if (mynOberStore.merchant.info != null && mynOberStore.merchant.info.paynl_return_domain != null) {
      returnDomain = mynOberStore.merchant.info.paynl_return_domain;
    }

    let defaultOrderItems = [];

    if (mynOberStore.merchant.info != null && mynOberStore.merchant.info.default_order_items != null) {
      defaultOrderItems = mynOberStore.merchant.info.default_order_items
        .filter((item) => {
          return mynOberStore.menu.articlesMapId[item.id] != null;
        })
        .map((orderArticle) => {
          return { m_article_id: orderArticle.id, amount: orderArticle.amount, m_modifiergroups: [] };
        });
    }
    if (mynOberStore.userSelectedPackaging) {
      defaultOrderItems.push({ m_article_id: mynOberStore.userSelectedPackaging.id, amount: 1, m_modifiergroups: [] });
    }

    let mArticles = mynOberStore.order.toJson();
    mArticles = mArticles.concat(defaultOrderItems);
    return {
      ta_merchant_id: mynOberStore.merchant.id,
      m_articles: JSON.stringify(mArticles),
      qr_code: mynOberStore.webcode,
      ...deliveryOrder,
      contactDetails: contactDetails,
      returnDomain: returnDomain,
      mobile_number: contactDetails.phone,
      is_delivery: true,
      is_takeaway: false,
      email: contactDetails.email,
      paymentMethodKey: deliveryOrder.paymentMethod.key,
      pickup_timestamp: parseInt(deliveryOrder.deliveryTime / 1000, 10),
    };
  }

  /**
   * Returns request data for waiter call request.
   * @param {number} distance
   * @return {{sp_merchant_id: number, distance: number}}
   */
  constructWaiterCallData(distance) {
    return {
      sp_merchant_id: mynOberStore.merchant.id,
      distance: distance,
    };
  }

  /**
   * Given the allergen path, returns url path to the allergen image.
   * @param {string} path
   * @returns {string}
   */
  createAllergenImageURL(path) {
    if (path != null) {
      return this.baseURL + this.api.URL_AR + this.apiGet.GET_ALLERGEN_IMAGE + "/" + path;
    } else {
      return null;
    }
  }

  /**
   * Given the id and path of the article, returns url path to the article image.
   * @param {number} id
   * @param {string} path
   * @returns {string}
   */
  createArticleImageURL(id, path) {
    if (path != null) {
      return "https://mynober-api-public.s3.eu-central-1.amazonaws.com/" + path;
      // return this.baseURL + this.api.URL_AR + this.apiGet.GET_ARTICLE_IMAGE + "/" + id + "?md5=" + path;
    } else {
      return null;
    }
  }

  /**
   * Given the id of the merchant, returns url path to the merchant image.
   * @param {Merchant} merchant
   * @param {function} resolve
   * @returns {string}
   */
  createMerchantImageURL(merchant, resolve) {
    let apiSuffix = this.getApiSuffixForMerchant(merchant);
    let placeHolderImage = this.baseURL + apiSuffix + this.apiGet.GET_MERCHANT_IMAGE + "/" + 1;
    if (mynOberStore.isHandheld()) {
      resolve(placeHolderImage);
      return;
    }

    let imageURL = this.baseURL + apiSuffix + this.apiGet.GET_MERCHANT_IMAGE + "/" + merchant.id;

    this._axios
      .head(imageURL)
      .then(() => {
        resolve(imageURL);
      })
      .catch((error) => {
        if (error.response != null && error.response.status !== 404) {
          errorHandler.sendError(error);
        }
        resolve(placeHolderImage);
      });
  }

  /**
   * Handles get request to server. Will reauthenticate and retry when response status is 401 if canReauthenticate = true, default true.
   * @param {string} apiSuffix
   * @param {string} url
   * @param {string} data
   * @param {boolean} [canReauthenticate=true]
   * @return {Promise<any>|Promise}
   */
  get(apiSuffix, url, data, canReauthenticate = true) {
    return new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // const timeout = setTimeout(() => {
      //   source.cancel();
      //   reject(new Error(this.apiMessages.SERVER_TIMEOUT));
      // }, 31000);

      let locale = platformFunctions.getLocale();
      let langParam = "?lang=" + locale;
      if (data.indexOf("?") !== -1) {
        langParam = "&lang=" + locale;
      }
      let pincode = localStore.getItem("pincode");
      if (pincode) {
        if (pincode?.length > 0) {
          langParam += "&pincode=" + pincode;
        }
      }

      let labelParam = "&label=" + window.label.name + "&merchant=" + mynOberStore.merchant?.name;
      langParam += labelParam;
      if (process.env.NODE_ENV !== "production") {
        langParam += "&environment=local";
      }

      this._axios
        .get(apiSuffix + url + data + langParam, { cancelToken: source.token })
        .then(function (response) {
          // clearTimeout(timeout);
          resolve(response.data);
        })
        .catch((error) => {
          // clearTimeout(timeout);
          if (error.response != null && error.response.status === 401 && canReauthenticate) {
            platformFunctions.getFirebaseToken().then((firebaseToken) => {
              this.login(this.constructLoginData(firebaseToken))
                .then(() => {
                  this.get(apiSuffix, url, data, false)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
                .catch((error) => {
                  if (error.message === this.apiMessages.UNAUTHORIZED) {
                    mynOberActions.resetUser();
                  }
                  reject(new Error(error.message));
                });
            });
          } else {
            let text;
            if (error.response != null && error.response.data != null && error.response.data.text != null) {
              text = error.response.data.text;
            } else {
              text = this.apiMessages.UNKNOWN_ERROR;
            }
            if (navigator.onLine) {
              reject(new Error(text));
            } else {
              reject(new Error("Check internet connection"));
            }
          }
        });
    });
  }

  /**
   * Returns API Suffix to the corresponding type of merchant given.
   * @return {string}
   */
  getApiSuffixForMerchant(merchant) {
    if (merchant instanceof TaMerchant) {
      return this.api.URL_TA;
    } else {
      return this.api.URL_SP;
    }
  }

  /**
   * Will get the Menu of given Merchant from the server.
   * @param {Merchant} merchant
   * @return {Promise<any>|Promise}
   */
  getMenuForMerchant(merchantParam = null, webcode) {
    let merchant = merchantParam ? merchantParam : mynOberStore.merchant;

    return new Promise((resolve, reject) => {
      this.get(this.getApiSuffixForMerchant(merchant), this.apiGet.GET_MENU, "/" + merchant.id)
        .then((response) => {
          if (response.message === this.apiMessages.MENU) {
            const menu = jsonProcessingFunctions.processJsonMenu(response.data);
            menu.articles.forEach((article) => {
              if (article.addImagePrefix) {
                article.path = this.createArticleImageURL(article.id, article.path);
              }
            });
            menu.allergens.forEach((allergen) => {
              allergen.path = this.createAllergenImageURL(allergen.path);
            });

            if (merchantParam instanceof SpMerchant) {
              if (!mynOberStore.isHandheld() && !merchant.shieldedReceipt && merchant._canShareOrder) {
                if (this._pusher == null) {
                  this._pusher = new Pusher(this.api.pusherApiUrl, { cluster: "mt1", encrypted: true });
                }

                if (webcode == null) {
                  webcode = sessionStorage.getItem(sessionStorage.storageNames.WEBCODE.toUpperCase());
                } else {
                  webcode = webcode.toUpperCase();
                }

                if (!this._pusher.channel("shareorder." + webcode)) {
                  let channel = this._pusher.subscribe("shareorder." + webcode);
                  this.pusherChannels.push({ channelName: "shareorder." + webcode, channel });
                  channel.bind("shareorder-updated", (data) => {
                    if (!mynOberStore.isInvalidPincode && !mynOberStore.dialogPincodeIsOpen) {
                      getSharedOrderTransactions()
                        .then((response) => {
                          this._removeWhenFirstIsMissingTransactions(response);
                        })
                        .catch((error) => {
                          this._processSharedOrderTransactions([data.transaction]);
                        });
                    }
                  });
                  channel.bind("shareorder-cleared", (data) => {
                    mynOberActions.orderSendSuccessful(false);
                  });
                }
                if (!this._pusher.channel("sp-qr-code-identifier." + webcode)) {
                  let channel = this._pusher.subscribe("sp-qr-code-identifier." + webcode);
                  this.pusherChannels.push({ channelName: "sp-qr-code-identifier." + webcode, channel });
                  channel.bind("shared-order-uuid-created", (data) => {
                    getSharedOrderTransactions()
                      .then((response) => {
                        if (!this._removeWhenFirstIsMissingTransactions(response)) {
                          mynOberActions.setSharedOrderUuid(data.shared_order_uuid, true);
                        }
                      })
                      .catch((error) => {});
                  });
                  channel.bind("shared-order-uuid-canceled", (data) => {
                    mynOberActions.setSharedOrderUuid(null, true);
                  });
                  channel.bind("sp-receipt-updated", (data) => {
                    requestFunctions.getReceipt(false, null).catch((text) => {});
                  });
                }

                let sharedOrder = merchant.sharedOrder ?? [];
                let order = new Order(mynOberStore.order.webcode, mynOberStore.order.orderType);
                mynOberActions.setOrder(order);
                this._processSharedOrderTransactions(sharedOrder, menu, order);

                // console.table(order);
              }
            }

            resolve(menu);
          } else {
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  _removeWhenFirstIsMissingTransactions(transactions) {
    let isMissingTransaction =
      mynOberStore.transactionUUIDs.length > 0 &&
      !transactions.some((transaction) => transaction.uuid === mynOberStore.transactionUUIDs[0]);

    if (isMissingTransaction) {
      mynOberActions.clearOrder();
    }
    this._processSharedOrderTransactions(transactions);
    return isMissingTransaction;
  }

  _processSharedOrderTransactions(transactions, menu, order) {
    if (menu == null) {
      menu = mynOberStore.menu;
    }
    transactions.forEach((transaction) => {
      if (transaction != null && transaction.amount != null) {
        if (mynOberStore.transactionUUIDs.includes(transaction.uuid)) {
          return true;
        }
        mynOberStore.transactionUUIDs.push(transaction.uuid);
        let article = menu.articles.find((article) => article.id === transaction.article_id);
        if (article != null) {
          let modifiergroups = menu.getModifiergroupsWithModifierarticlesByModifiergroupIds(article.modifiergroupIds);

          let orderModifiergroups = [];
          transaction.modifiergroups.forEach((modifiergroupTransaction) => {
            let modifiergroup = modifiergroups.find(
              (modifiergroup) => modifiergroup.id === modifiergroupTransaction.modifiergroup_id
            );
            if (modifiergroup == null) {
              return true;
            }
            let orderModifierarticles = [];
            modifiergroupTransaction.modifierarticles.forEach((modifierarticleTransaction) => {
              let modifierarticle = modifiergroup.modifierarticles.find(
                (modifierarticle) => modifierarticle.id === modifierarticleTransaction.modifierarticle_id
              );
              if (modifierarticle != null) {
                orderModifierarticles.push(new OrderModifierarticle(modifierarticle, 1));
                modifierarticle.selected = true;
              }
            });
            if (orderModifierarticles.length > 0) {
              orderModifiergroups.push(new OrderModifiergroup(modifiergroup, orderModifierarticles));
            }
          });
          if (order == null) {
            mynOberActions.addOrderArticle(new OrderArticle(article, transaction.amount, orderModifiergroups), false); // TODO
          } else {
            order.addOrderArticleToOrder(new OrderArticle(article, transaction.amount, orderModifiergroups));
          }
        }
      }
    });
  }

  /**
   * Will get the Merchant of given webcode or Merchant of HandheldUser if webcode is undefined
   * @param {=string} webcode
   * @return {Promise<any> | Promise}
   */
  getMerchantForWebcode(webcode) {
    if (webcode != null) {
      webcode = webcode.toUpperCase();
    }
    return new Promise((resolve, reject) => {
      let data = "";
      if (webcode != null) {
        data = "/" + webcode;
        if (webcode.length === 0) {
          reject();
        }
      }
      let redirectUrl = sessionStore.getItem("redirect_url");
      if (redirectUrl) {
        data += "?redirect_url=" + redirectUrl;
      }
      this.get(this.api.URL_SP, this.apiGet.GET_MERCHANT, data)
        .then((response) => {
          if (response.shareorderuuid?.uuid) {
            setSharedOrderUuid(response.shareorderuuid.uuid, true, response.sp_merchant?.share_order_seconds);
          }
          if (response.message === this.apiMessages.REDIRECT_LABEL) {
            sessionStore.setItem("redirect_url", response.url);
            window.location.replace(response.url);
          } else if (response.message === this.apiMessages.MERCHANT) {
            // console.log(response);
            if (mynOberStore.isHandheld()) {
              let merchant = null;
              let taMerchants = [];
              response.ta_merchants.forEach((ta_merchant) => {
                taMerchants.push(jsonProcessingFunctions.processJsonTaMerchant(ta_merchant));
                merchant = taMerchants[0];
              });
              taHandheldActions.setTaMerchants(taMerchants);

              let spMerchants = [];
              response.sp_merchants.forEach((sp_merchant) => {
                spMerchants.push(jsonProcessingFunctions.processJsonSpMerchant(sp_merchant));
                merchant = spMerchants[0];
              });
              handheldActions.setSpMerchants(spMerchants);

              resolve(merchant);
              return;
            }

            if (response.sp_qr_code?.current_unix_timestamp) {
              window.serverUnixTimestampOffset =
                response.sp_qr_code.current_unix_timestamp * 1000 - new Date().getTime();
              // console.log(new Date().getTime() - (response.sp_qr_code.current_unix_timestamp * 1000));
            }

            if (response.sp_merchant != null) {
              let merchant = jsonProcessingFunctions.processJsonSpMerchant(response.sp_merchant, response.sp_qr_code);
              mynOberActions.setSpQrCode(response.sp_qr_code);

              // this.createMerchantImageURL(merchant, (imageURL) => {
              merchant.imageURL = this.api.URL_S3 + "/" + merchant.image;

              resolve(merchant);
              if (merchant.info?.api_url?.app) {
                this.baseURL = merchant.info.api_url.app;
              }
              // });
            } else if (response.ta_merchant != null) {
              let merchant = jsonProcessingFunctions.processJsonTaMerchant(
                response.ta_merchant,
                response.menu_tag != null ? response.menu_tag : null,
                response.sp_qr_code
              );
              if (merchant.taMasterMerchantId === 1) {
                //// ILS
                ReactGA.addTrackers(
                  [
                    {
                      trackingId: "UA-131778062-1",
                      gaOptions: {
                        name: "MynOber",
                      },
                    },
                    {
                      trackingId: "UA-139846207-1",
                      gaOptions: { name: "ILS" },
                    },
                  ],
                  { debug: true, alwaysSendToDefaultTracker: false }
                );
              }
              mynOberActions.setSpQrCode(response.sp_qr_code);

              merchant.imageURL = this.api.URL_S3 + "/" + merchant.image;
              // this.createMerchantImageURL(merchant, (imageURL) => {
              //   merchant.imageURL = imageURL;
              if (merchant.info?.api_url?.app) {
                this.baseURL = merchant.info.api_url.app;
              }
              resolve(merchant);
              // });
            } else if (response.ta_merchant != null) {
              reject(new Error(this.apiMessages.OPEN_WEBCODE_IN_WEBAPP));
            } else {
              reject(new Error(this.apiMessages.PLATFORM_ERROR));
            }
          } else {
            console.log(response.text);
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          if (this.baseURL !== "https://api.mynober.nl") {
            this.baseURL = "https://api.mynober.nl";
          }
          console.log(error);
          reject(error);
        });
    });
  }

  /**
   * Will get Merchant with corresponding Menu for given webcode,
   * will return Merchant with Menu for HandheldUser if webcode is undefined
   * @param {=string} webcode
   * @return {Promise<any> | Promise}
   */
  getMerchantWithMenuForWebcode(webcode) {
    return new Promise((resolve, reject) => {
      this.getMerchantForWebcode(webcode)
        .then((merchant) => {
          this._menuIsUpdating = true;
          this.connectAppUserPusher(merchant.merchantId);
          this.getMenuForMerchant(merchant, webcode)
            .then((menu) => {
              resolve({ merchant: merchant, menu: menu });
              this._menuIsUpdating = false;
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Returns if payment has been successful for given data.
   * @param {string} returnData
   * @return {Promise<any> | Promise}
   */
  getOnlinePaymentDetails(returnData) {
    return new Promise((resolve, reject) => {
      this.get(this.api.URL_TA, this.apiGet.GET_RETURN, "?" + returnData)
        .then((response) => {
          if (response.message === this.apiMessages.RETURN) {
            resolve(response);
          } else {
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   *
   * @param transaction
   * @param webcode
   * @returns {Promise<any> | Promise<*>}
   */
  updateShareOrder(transaction, webcode) {
    mynOberStore.transactionUUIDs.push(transaction.uuid);
    return new Promise((resolve, reject) => {
      this.post(
        this.api.URL_SP,
        this.apiPost.POST_UPDATE_SHARE_ORDER + "/" + webcode,
        { transaction: transaction },
        true,
        true
      )
        .then((response) => {
          let transactions = response.data;
          transactions.forEach((transaction) => {
            this._processSharedOrderTransactions([transaction]);
          });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendGuestRegistration(webcode, customFields, spMerchantId) {
    return new Promise((resolve, reject) => {
      this.post(this.api.URL_SP, "/merchant/" + webcode + "/contactdetails", {
        customFields: customFields,
        sp_merchant_id: spMerchantId,
      })
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }

  sendUserLog2(extra, type = "user_log_data") {
    if (mynOberStore.merchant?.id === 0) {
      if (mynOberStore.merchant instanceof SpMerchant) {
        this.post(
          this.api.URL_SP,
          "/userLog/" + mynOberStore.merchant.id,
          {
            data: {
              type: type,
              log_data: {
                test: "test",
                ...extra,
                receipt: mynOberStore.receipt,
                merchant: mynOberStore.merchant,
                deliveryOrder: mynOberStore.deliveryOrder,
                menus: mynOberStore.menus,
                menusWithLag: mynOberStore.menusWithLag,
                merchantShouldUpdate: mynOberStore.merchantShouldUpdate,
                order: mynOberStore.order,
                spQrCode: mynOberStore.spQrCode,
                updateMenusTimer: mynOberStore.updateMenusTimer,
                user: mynOberStore.user,
                webcode: mynOberStore.webcode,
              },
            },
          },
          true,
          true
        );
      } else {
        this.post(
          this.api.URL_SP,
          "/userLog/" + 1,
          {
            data: {
              type: type,
              log_data: {
                test: "test",
                ...extra,
                receipt: mynOberStore.receipt,
                merchant: mynOberStore.merchant,
                deliveryOrder: mynOberStore.deliveryOrder,
                menus: mynOberStore.menus,
                menusWithLag: mynOberStore.menusWithLag,
                merchantShouldUpdate: mynOberStore.merchantShouldUpdate,
                order: mynOberStore.order,
                spQrCode: mynOberStore.spQrCode,
                updateMenusTimer: mynOberStore.updateMenusTimer,
                user: mynOberStore.user,
                webcode: mynOberStore.webcode,
              },
            },
          },
          true,
          true
        );
      }
    }
  }

  sendUserLog() {
    if (mynOberStore.merchant instanceof SpMerchant) {
      this.post(
        this.api.URL_SP,
        "/userLog/" + mynOberStore.merchant.id,
        {
          data: {
            type: "Vraag om personeel",
            log_data: {
              test: "test",
              receipt: mynOberStore.receipt,
              merchant: mynOberStore.merchant,
              deliveryOrder: mynOberStore.deliveryOrder,
              menus: mynOberStore.menus,
              menusWithLag: mynOberStore.menusWithLag,
              merchantShouldUpdate: mynOberStore.merchantShouldUpdate,
              order: mynOberStore.order,
              spQrCode: mynOberStore.spQrCode,
              updateMenusTimer: mynOberStore.updateMenusTimer,
              user: mynOberStore.user,
              webcode: mynOberStore.webcode,
            },
          },
        },
        true,
        true
      );
    } else {
      this.post(
        this.api.URL_SP,
        "/userLog/" + 1,
        {
          data: {
            type: "Vraag om personeel",
            log_data: {
              test: "test",
              receipt: mynOberStore.receipt,
              merchant: mynOberStore.merchant,
              deliveryOrder: mynOberStore.deliveryOrder,
              menus: mynOberStore.menus,
              menusWithLag: mynOberStore.menusWithLag,
              merchantShouldUpdate: mynOberStore.merchantShouldUpdate,
              order: mynOberStore.order,
              spQrCode: mynOberStore.spQrCode,
              updateMenusTimer: mynOberStore.updateMenusTimer,
              user: mynOberStore.user,
              webcode: mynOberStore.webcode,
            },
          },
        },
        true,
        true
      );
    }
  }

  /**
   * Returns receipt of MynOberUser or given webcode.
   * @param {=string} webcode
   * @return {Promise<any> | Promise}
   */
  getReceipt(webcode, shareOrder = null) {
    return new Promise((resolve, reject) => {
      let data = "";
      if (mynOberStore.isHandheld() && webcode != null) {
        data = "?table_identifier=" + webcode + "&sp_merchant_id=" + mynOberStore.merchant.id;
      }

      if (mynOberStore.merchant instanceof SpMerchant || shareOrder) {
        if (webcode == null) {
          webcode = sessionStorage.getItem(sessionStorage.storageNames.WEBCODE) ?? mynOberStore.webcode;
        }
        if (data.length === 0) {
          data = "?webcode=" + webcode;
        } else {
          data += "&webcode=" + webcode;
        }
      }

      let pincode = localStore.getItem("pincode");
      if (pincode) {
        if (pincode?.length > 0) {
          if (data.length === 0) {
            data = "?pincode=" + pincode;
          } else {
            data += "&pincode=" + pincode;
          }
        }
      }

      this.get(this.api.URL_SP, this.apiGet.GET_RECEIPT, data)
        .then((response) => {
          if (response.message === "wrong_pincode") {
            mynOberStore.isInvalidPincode = true;
            localStore.removeItem("pincode");
            mynOberStore.emit(mynOberStore.events.WRONG_QR_PINCODE);
            resolve({ receipt: null, wrongPincode: true });
          } else if (response.message === this.apiMessages.RECEIPT_SUCCESS) {
            let receipt = jsonProcessingFunctions.processJsonReceipt(response);
            if (receipt == null) {
              this.sendUserLog2({ RECEIPT_NULL: true }, "RECEIPT_NULL");
            }
            if (
              !mynOberStore.isHandheld() &&
              receipt.ordergroups.length > 0 &&
              receipt.ordergroups[receipt.ordergroups.length - 1].posStatus === "FAILED"
            ) {
              mynOberStore.emit(
                mynOberStore.events.LATEST_ORDER_FAILED,
                receipt.ordergroups[receipt.ordergroups.length - 1]
              );
            }

            if (response.spReceipt?.sp_ayce_couverts?.[0]?.time_limit != null) {
              let timeLimit = response.spReceipt?.sp_ayce_couverts?.[0]?.time_limit;
              if (timeLimit > 0) {
                if (this._timeLimitTimer != null) {
                  clearTimeout(this._timeLimitTimer);
                }

                let date = getApplicationDate();
                let currentTime = Math.floor(date.getTime() / 1000);
                let timeLeftSeconds = timeLimit - currentTime;
                let timeLeftMinutes = Math.floor(timeLeftSeconds / 60);
                if (timeLeftMinutes > 30) {
                  this._timeLimitTimer = setTimeout(() => {
                    mynOberStore.emit(mynOberStore.events.TIMER_30, mynOberStore.events.TIMER_30);

                    let date = getApplicationDate();
                    let currentTime = Math.floor(date.getTime() / 1000);
                    let timeLeftSeconds = timeLimit - currentTime;
                    let timeLeftMinutes = Math.floor(timeLeftSeconds / 60);
                    this._timeLimitTimer = setTimeout(() => {
                      mynOberStore.emit(mynOberStore.events.TIMER_15, mynOberStore.events.TIMER_15);
                    }, (timeLeftMinutes - 15) * 60000);
                  }, (timeLeftMinutes - 30) * 60000);
                } else if (timeLeftMinutes > 15) {
                  this._timeLimitTimer = setTimeout(() => {
                    mynOberStore.emit(mynOberStore.events.TIMER_15, mynOberStore.events.TIMER_15);
                  }, (timeLeftMinutes - 15) * 60000);
                }
              }
            }

            this.createMerchantImageURL(receipt.merchant, (imageURL) => {
              receipt.merchant.imageURL = imageURL;
              resolve({ receipt, wrongPincode: false });
            });
          } else if (response.message === this.apiMessages.NO_ORDERS_FOUND) {
            resolve({ receipt: null, wrongPincode: false });
          } else {
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getHandheldReceipt(receiptId, spMerchantId) {
    if (!this.spHandheldReceiptsMap) {
      this.spHandheldReceiptsMap = {};
    }
    if (this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId]) {
      this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId] += 1;
      return new Promise((resolve) => {
        resolve();
      });
    }
    this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId] = 1;
    return new Promise((resolve, reject) => {
      let data = "";
      this.get(this.api.URL_SP, this.apiGet.GET_RECEIPT + "/" + receiptId, data)
        .then((response) => {
          if (this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId] > 1) {
            delete this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId];
            this.getHandheldReceipt(receiptId, spMerchantId);
          } else {
            delete this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId];
          }
          // if (response.status === "OPEN") {
          if (response.sp_ordergroups) {
            handheldActions.updateReceipt(processHandheldReceipt(response), spMerchantId);
          }
          // }
          resolve();
        })
        .catch((error) => {
          if (this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId] > 1) {
            delete this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId];
            this.getHandheldReceipt(receiptId, spMerchantId);
          } else {
            delete this.spHandheldReceiptsMap[spMerchantId + ":" + receiptId];
          }
          reject(error);
        });
    });
  }

  /**
   * Returns receipt of MynOberUser or given receiptId.
   * @param {number} receiptId
   * @return {Promise<any> | Promise}
   */
  getHandheldTaReceipt(receiptId) {
    return new Promise((resolve, reject) => {
      let data = "";

      this.get(this.api.URL_SP, this.apiGet.GET_TARECEIPT + "/" + receiptId, data)
        .then((response) => {
          if (response.ordergroup) {
            if (response.status === "ORDERED" || response.status === "FINISHED" || response.status === "CANCELED") {
              taHandheldActions.updateTaReceipt(processHandheldTaReceipt(response));
            }
          }
          resolve();
        })
        .catch((error) => {
          // reject(error);
        });
    });
  }

  handheldPusher() {
    if (this._pusher == null) {
      this._pusher = new Pusher(this.api.pusherApiUrl, {
        cluster: "mt1",
        encrypted: true,
        authEndpoint: this.api.URL + this.api.URL_SP + this.apiGet.GET_BROADCASTING,
        auth: {
          headers: {
            Authorization: this._axios.defaults.headers.common["Authorization"],
            Accept: "application/json",
          },
        },
      });

      this._pusher.connection.bind("state_change", (states) => {
        // states = {previous: 'oldState', current: 'newState'}
        console.log("Channels old state is " + states.previous);
        console.log("Channels current state is " + states.current);
      });
    }

    Object.keys(handheldStore.spMerchants).forEach((spMerchantId) => {
      let channel = this._pusher.subscribe("private-sp-merchant." + spMerchantId);
      channel.bind("sp-receipt-updated", (data) => {
        if (data.sp_receipt_id != null) {
          this.getHandheldReceipt(data.sp_receipt_id, spMerchantId).then();
        }
      });
    });

    Object.keys(taHandheldStore.taMerchants).forEach((taMerchantId) => {
      let taChannel = this._pusher.subscribe("private-ta-merchant." + taMerchantId);
      taChannel.bind("ta-receipt-updated", (data) => {
        if (data.ta_receipt_id != null) {
          this.getHandheldTaReceipt(data.ta_receipt_id).then();
        }
      });
    });

    // let sharedOrder = merchant.sharedOrder;
    // let order = new Order(mynOberStore.order.webcode, mynOberStore.order.orderType);
    //
    // this._processSharedOrderTransactions(sharedOrder, menu, order);
    //
    // mynOberActions.setOrder(order);
    // console.table(order);
  }

  connectAppUserPusher(merchantId) {
    if (this._pusherConnectedAsAppUser) {
      return;
    }
    this._pusherConnectedAsAppUser = true;
    if (this._pusher != null) {
      this._pusher.disconnect();
    }

    this._pusher = new Pusher(this.api.pusherApiUrl, {
      cluster: "mt1",
      encrypted: true,
      authEndpoint: this.api.URL + this.api.URL_SP + this.apiGet.GET_BROADCASTING,
      auth: {
        headers: {
          Authorization: this._axios.defaults.headers.common["Authorization"],
          Accept: "application/json",
        },
      },
    });

    this._pusher.connection.bind("state_change", (states) => {
      // console.log("Channels old state is " + states.previous);
      // console.log("Channels current state is " + states.current);
      // states = {previous: 'oldState', current: 'newState'}
      // console.log("Channels current state is " + states.current);
      if (!mynOberStore.isHandheld() && mynOberStore.merchant?.canShareOrder) {
        getSharedOrderTransactions()
          .then((response) => {
            this._removeWhenFirstIsMissingTransactions(response);
          })
          .catch((error) => {
            // this._processSharedOrderTransactions([data.transaction]);
          });
      }
    });

    let channel = this._pusher.subscribe("merchant." + merchantId);
    channel.bind("menu_updated", () => {
      this.refreshMenu();
    });
  }

  refreshMenu() {
    this._shouldMenuUpdate = this._menuIsUpdating;
    if (this._menuIsUpdating) {
      return;
    }

    this._menuIsUpdating = true;
    this.getMenuForMerchant(null, mynOberStore.webcode)
      .then((menu) => {
        mynOberActions.setMerchantWithMenuAndWebcode(mynOberStore.merchant, menu);
        this._menuIsUpdating = false;
        if (this._shouldMenuUpdate) {
          this._shouldMenuUpdate = false;
          this.refreshMenu();
        }
      })
      .catch((error) => {
        console.log(error);
        this._menuIsUpdating = false;
        if (this._shouldMenuUpdate) {
          this._shouldMenuUpdate = false;
          this.refreshMenu();
        }
      });
  }

  getAllHandheldReceiptOverviews(useTimeout = false) {
    if (useTimeout) {
      setTimeout(() => {
        this.getAllHandheldReceiptOverviews(true);
      }, 60000);
    }
    return new Promise((resolve, reject) => {
      let spMerchantIds = Object.keys(handheldStore.spMerchants).map((spMerchantId) => parseInt(spMerchantId));
      let input = {
        closed: 1,
        timezone: mynOberStore.merchant.timezone,
        sp_merchant_ids: spMerchantIds,
      };

      this.post(this.api.URL_SP, this.apiGet.GET_ALL_OPEN_RECEIPT_OVERVIEW, input)
        .then((response) => {
          if (response.message === this.apiMessages.SUCCESS) {
            this.processHandheldReceipts(response.sp_receipts);
            resolve(null);
          } else {
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getAllTaHandheldReceiptOverviews(useTimeout = false) {
    if (useTimeout) {
      setTimeout(() => {
        this.getAllTaHandheldReceiptOverviews(useTimeout);
      }, 60000);
    }
    return new Promise((resolve, reject) => {
      let taMerchantIds = Object.keys(taHandheldStore.taMerchants).map((taMerchantId) => parseInt(taMerchantId));
      this.post(this.api.URL_SP, this.apiGet.GET_ALL_OPEN_TARECEIPT_OVERVIEW, {
        ta_merchant_ids: taMerchantIds,
        closed: true,
      })
        .then((response) => {
          if (response.message === this.apiMessages.SUCCESS) {
            this.processTaHandheldReceipts(response.ta_receipts);
            resolve(null);
          } else {
            reject(new Error(response.text));
          }
        })
        .catch((error) => {
          // reject(error);
        });
    });
  }

  async processHandheldReceipts(jsonSpReceipts) {
    let handheldReceipts = Object.assign({}, handheldStore.handheldReceipts);
    // console.log(handheldReceipts);

    let jsonSpReceiptsSorted = jsonSpReceipts.sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      } else if (a.id < b.id) {
        return 1;
      } else {
        return 0;
      }
    });

    for (let i = 0; i < jsonSpReceiptsSorted.length; i++) {
      let jsonSpReceipt = jsonSpReceiptsSorted[i];
      if (handheldReceipts[jsonSpReceipt.id] != null) {
        delete handheldReceipts[jsonSpReceipt.id];
      }

      if (handheldStore.handheldReceipts[jsonSpReceipt.id] == null) {
        await this.getHandheldReceipt(jsonSpReceipt.id, jsonSpReceipt.sp_merchant_id);
      } else {
        // console.log(handheldStore.handheldReceipts[jsonSpReceipt.id].updatedAt);
        // console.log(jsonSpReceipt.updated_at);
        if (handheldStore.handheldReceipts[jsonSpReceipt.id].updatedAt !== jsonSpReceipt.updated_at) {
          await this.getHandheldReceipt(jsonSpReceipt.id, jsonSpReceipt.sp_merchant_id);
        }
      }
    }

    Object.keys(handheldReceipts).forEach((key) => {
      handheldActions.deleteReceipt(
        handheldReceipts[key].id,
        handheldReceipts[key].tableId,
        handheldReceipts[key].spMerchantId
      );
    });
  }

  async processTaHandheldReceipts(jsonTaReceipts) {
    let taReceipts = Object.assign({}, taHandheldStore.taReceipts);

    let jsonTaReceiptsSorted = jsonTaReceipts.sort((a, b) => {
      if (a.id > b.id) {
        return -1;
      } else if (a.id < b.id) {
        return 1;
      } else {
        return 0;
      }
    });

    // console.log(taReceipts);
    for (let i = 0; i < jsonTaReceiptsSorted.length; i++) {
      let jsonTaReceipt = jsonTaReceiptsSorted[i];
      if (taReceipts[jsonTaReceipt.id] != null) {
        delete taReceipts[jsonTaReceipt.id];
      }

      if (taHandheldStore.taReceipts[jsonTaReceipt.id] == null) {
        await this.getHandheldTaReceipt(jsonTaReceipt.id);
      } else {
        // console.log(taHandheldStore.taReceipts[jsonTaReceipt.id].updatedAt);
        // console.log(jsonTaReceipt.updated_at);
        if (taHandheldStore.taReceipts[jsonTaReceipt.id].updatedAt !== jsonTaReceipt.updated_at) {
          await this.getHandheldTaReceipt(jsonTaReceipt.id);
        }
      }
    }

    Object.keys(taReceipts).forEach((key) => {
      taHandheldActions.deleteTaReceipt(taReceipts[key].id);
    });
  }

  getAllHandheldReceipts(useTimeout = false) {
    if (Object.keys(handheldStore.spMerchants).length > 0) {
      this.getAllHandheldReceiptOverviews(useTimeout);
    }
    if (Object.keys(taHandheldStore.taMerchants).length > 0) {
      this.getAllTaHandheldReceiptOverviews(useTimeout);
    }
    if (useTimeout) {
      this.handheldPusher();
    }
    // return new Promise((resolve, reject) => {
    //   let data = "";
    //
    //   this.get(this.api.URL_SP, this.apiGet.GET_ALL_OPEN_RECEIPT, data).then((response) => {
    //     if (response.message === this.apiMessages.SUCCESS) {
    //       response.sp_receipts.forEach((jsonSpReceipt) => handheldActions.createReceipt(processHandheldReceipt(jsonSpReceipt)));
    //       this.handheldPusher();
    //       resolve(null);
    //     } else {
    //       reject(new Error(response.text));
    //     }
    //   }).catch((error) => {
    //     reject(error);
    //   });
    // });
  }

  /**
   * Login user on server, automatically saves new refreshToken.
   * @param {Object} data
   * @return {Promise<any>|Promise}
   */
  login(data) {
    return new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // const timeout = setTimeout(() => {
      //   source.cancel();
      //   reject(new Error(this.apiMessages.SERVER_TIMEOUT));
      // }, 31000);

      let url = this.apiPost.POST_LOGIN;
      if (data.refresh_token != null) {
        url = this.apiPost.POST_REFRESH;
      }
      let locale = platformFunctions.getLocale();
      let langParam = "?lang=" + locale;

      this._axios
        .post(this.baseURL + this.api.URL_SP + url + langParam, data, { cancelToken: source.token })
        .then((response) => {
          // clearTimeout(timeout);
          if (response.data.message === "auth") {
            this.setHeader("Authorization", "Bearer " + response.data.data.access_token);
            mynOberActions.setRefreshToken(response.data.data.refresh_token);
            if (data.handheld_username != null && data.handheld_password != null && url === this.apiPost.POST_REFRESH) {
              resolve(response.data.data.sp_handheld_user);
            } else {
              resolve(false);
            }
          } else {
            this.removeHeader("Authorization");
            reject(new Error(response.data.text));
          }
        })
        .catch((error) => {
          if (error) {
            // this.baseURL = "https://apiv5.mynober.nl";
          }

          // clearTimeout(timeout);
          this.removeHeader("Authorization");
          if (error.response != null && error.response.data != null) {
            if (error.response.data.message === "refresh_token") {
              mynOberActions.resetUser();
              reject(new Error("Refresh page"));
              return;
            }
          }

          let text;
          if (error.response != null && error.response.status === 401) {
            if (error.response.data != null && error.response.data.text != null) {
              text = error.response.data.text;
            } else {
              text = this.apiMessages.UNAUTHORIZED;
            }
          } else if (error.response != null && error.response.data != null) {
            text = error.response.data.text;
          } else {
            text = this.apiMessages.UNKNOWN_ERROR;
          }
          reject(new Error(text));
        });
    });
  }

  /**
   * Handles post request to server. Will reauthenticate and retry when response status is 401 if canReauthenticate = true, default true.
   * @param {string} apiSuffix
   * @param {string} url
   * @param {Object} data
   * @param {boolean} canReauthenticate
   * @return {Promise<any>|Promise}
   */
  async post(apiSuffix, url, data = {}, canReauthenticate = true, okWhen200 = false) {
    return new Promise((resolve, reject) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      // const timeout = setTimeout(() => {
      //   source.cancel();
      //   reject(new Error(this.apiMessages.SERVER_TIMEOUT));
      // }, 31000);

      let locale = platformFunctions.getLocale();
      let langParam = "?lang=" + locale;

      let labelParam = "&label=" + window.label.name + "&merchant=" + mynOberStore.merchant?.name;
      langParam += labelParam;
      if (process.env.NODE_ENV !== "production") {
        langParam += "&environment=local";
      }

      let pincode = localStore.getItem("pincode");
      if (pincode) {
        if (pincode?.length > 0) {
          langParam += "&pincode=" + pincode;
        }
      }

      let webcode = sessionStorage.getItem(sessionStorage.storageNames.WEBCODE);
      if (webcode != null && data.webcode == null) {
        data.webcode = webcode;
      }

      this._axios
        .post(apiSuffix + url + langParam, data, { cancelToken: source.token })
        .then((response) => {
          // clearTimeout(timeout);
          if (okWhen200 && response.status === 200) {
            resolve(response);
            return;
          }
          switch (response.data.message) {
            case "wrong_pincode":
              mynOberStore.isInvalidPincode = true;
              mynOberStore.emit(mynOberStore.events.WRONG_QR_PINCODE);
              break;
            case this.apiMessages.MOVE_SUCCESSFUL:
            case this.apiMessages.NUMBER_UPDATED:
            case this.apiMessages.ORDER_CREATED:
            case this.apiMessages.PAYMENT_REQUEST_SEND:
            case this.apiMessages.TA_RECEIPT_CREATED:
            case this.apiMessages.WAITER_COMING:
            case this.apiMessages.PRINT_SENT:
            case "shared_order_uuid_canceled":
              resolve();
              break;
            case "receipt_out_of_date":
              resolve("receipt_out_of_date");
              break;
            case "waiting for pin transaction":
              resolve(response.data);
              break;
            case this.apiMessages.SUCCESS:
            case this.apiMessages.SP_RECEIPT_ALREADY_ACTIVE:
              resolve(response.data);
              break;
            case this.apiMessages.URL_CREATED:
              resolve(response.data.data.url);
              break;
            case "timestamp_not_available":
              mynOberStore.merchant.max_ta_orders_blocks = response.data.max_ta_orders_blocks;
              mynOberStore.emit(mynOberStore.events.TIMESTAMP_NOT_AVAILABLE);
              reject(new Error(response.data.text));
              break;
            case "shared_order_updated":
              let sharedOrderData = response.data.shared_order;
              mynOberActions.orderSendSuccessful(false);
              mynOberStore.transactionUUIDs = [];
              this._processSharedOrderTransactions(sharedOrderData);
              reject(new Error("shared_order_has_been_updated"));
              break;
            case "shared_order_uuid_invalid":
              resolve({ sharedOrderUuid: null });
              break;
            case "shared_order_send_order_request":
              let sharedOrderUuid = response.data.uuid;
              resolve({ sharedOrderUuid: sharedOrderUuid });
              break;
            default:
              reject(new Error(response.data.text));
          }
        })
        .catch((error) => {
          // clearTimeout(timeout);
          if (error.response != null && error.response.status === 401 && canReauthenticate) {
            platformFunctions.getFirebaseToken().then((firebaseToken) => {
              this.login(this.constructLoginData(firebaseToken))
                .then(() => {
                  this.post(apiSuffix, url, data, false)
                    .then((response) => {
                      resolve(response);
                    })
                    .catch((error) => {
                      reject(error);
                    });
                })
                .catch((error) => {
                  if (error.message === this.apiMessages.UNAUTHORIZED) {
                    mynOberActions.resetUser();
                  }
                  reject(new Error());
                });
            });
          } else {
            let text;
            if (error.response != null && error.response.data != null) {
              text = error.response.data.text ?? error.response.data.message;
            } else {
              text = this.apiMessages.UNKNOWN_ERROR;
            }
            if (navigator.onLine) {
              reject(new Error(text));
            } else {
              reject(new Error("Check internet connection"));
            }
          }
        });
    });
  }

  /**
   * Removes given header for MynOberAPIClientManager.
   * @param {string} header
   */
  removeHeader(header) {
    delete this._axios.defaults.headers.common[header];
  }

  /**
   * Sets new header for MynOberAPIClientManager.
   * @param {string} header
   * @param {string} value
   */
  setHeader(header, value) {
    this._axios.defaults.headers.common[header] = value;
  }

  resetPusher() {
    while (this.pusherChannels.length > 0) {
      const [{ channel, channelName }] = this.pusherChannels.splice(0, 1);
      channel.unbind();
      this._pusher.unsubscribe(channelName);
    }
  }
}

let mynOberAPIClientManager = new MynOberAPIClientManager();
window.mynOberAPIClientManager = mynOberAPIClientManager;
export default mynOberAPIClientManager;
