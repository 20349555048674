import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import "./ListReceiptArticles.css";
import GetCurrency from "../../utils/functions/GetCurrency";
import Typography from "@material-ui/core/Typography";

class ListReceiptArticles extends Component {
  render() {
    return (
      <div>
        <div id={"receipt-articles-list"} className={"list-receipt-articles"}>
          {this.props.receiptArticles}
          <Typography className={"text-receipt-total"}>
            <FormattedMessage id={this.props.totalStringId} />:{GetCurrency("€")}{" "}
            {parseFloat(this.props.amount / 100.0).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        </div>
      </div>
    );
  }
}

ListReceiptArticles.propTypes = {
  amount: PropTypes.number.isRequired,
  receiptArticles: PropTypes.array.isRequired,
  totalStringId: PropTypes.string.isRequired,
};

export default ListReceiptArticles;
