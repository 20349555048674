import React, { useEffect } from "react";
import ButtonDrawerMenu from "../components/ButtonDrawerMenu";
import * as requestFunctions from "../utils/functions/RequestFunctions";
import "./DrawerMenuApp.css";
import { withRouter } from "react-router";
import { backgroundOverlayId } from "../../App";
import { PathNames } from "../routers/PathNames";
import mynOberStore from "../utils/flux/MynOberStore";
import { useDialog } from "../components/dialogs/DialogProvider";
import withIntl from "../utils/withIntl";
import DialogWaiterRequest from "../components/dialogs/DialogWaiterRequest";
import DialogPaymentRequest from "../components/dialogs/DialogPaymentRequest";
import DialogServiceArticleRequest from "../components/dialogs/DialogServiceArticleRequest";
import DialogOkOrCancel from "../components/dialogs/DialogOkOrCancel";
import DialogError from "../components/dialogs/DialogError";
import DialogOk from "../components/dialogs/DialogOk";
import DialogMove from "../components/dialogs/DialogMove";
import { makeStyles } from "@material-ui/core/styles";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import DirectionsWalkOutlinedIcon from "@material-ui/icons/DirectionsWalkOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";

export const drawerMenuId = "drawer-menu";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}));

function DrawerMenuApp(props) {
  const classes = useStyles();

  const [hasReceiptAtCurrentMerchant, setHasReceiptAtCurrentMerchant] = React.useState(
    props.store.hasReceiptAtCurrentMerchant()
  );
  const [openDialog] = useDialog();

  const handleClickOutside = (event) => {
    if (event.target === document.getElementById(backgroundOverlayId)) {
      closeDrawer();
    }
  };

  const closeDrawer = () => {
    let drawerMenu = document.getElementById(drawerMenuId);

    if (drawerMenu != null) {
      if (drawerMenu.classList.contains("slide-in")) {
        let backgroundOverlay = document.getElementById(backgroundOverlayId);
        if (backgroundOverlay !== null) {
          if (backgroundOverlay.classList.contains("background-overlay-visible")) {
            backgroundOverlay.classList.remove("background-overlay-visible");
          }
        }

        drawerMenu.classList.remove("slide-in");
        drawerMenu.classList.add("slide-out");
      }
    }
  };

  const onReceiptUpdated = () => {
    setHasReceiptAtCurrentMerchant(mynOberStore.hasReceiptAtCurrentMerchant());
  };

  const setHomeRoot = () => {
    let bodyElement = document.getElementsByTagName("body")[0];
    if (bodyElement !== null) {
      bodyElement.dispatchEvent(new Event("leaveLocation"));
    }
    props.history.push(PathNames.HOMEPAGE);
  };

  const openPrivacyPolicy = () => {
    let webAppUrl = props.intl.formatMessage({ id: "privacy_policy_url" });
    window.open(webAppUrl, "_blank");
  };

  const showExitMerchantAlert = () => {
    closeDrawer();
    if (mynOberStore.isHandheld()) {
      setHomeRoot();
    } else {
      openDialog({
        children: (
          <DialogOkOrCancel onOkClicked={setHomeRoot} title={"alert_title_exit_merchant"} content={"alert_text_sure"} />
        ),
      });
    }
  };

  const onMoveClicked = () => {
    closeDrawer();

    openDialog({
      children: (
        <DialogMove
          onOkClicked={(webcode) => {
            requestFunctions
              .checkLocation()
              .then((distance) => {
                requestFunctions.sendMoveRequest(webcode, distance).catch((text) => {
                  openDialog({ children: <DialogError content={text} /> });
                });
              })
              .catch((error) => {
                if (error.code === 1) {
                  openDialog({
                    children: (
                      <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />
                    ),
                  });
                } else {
                  openDialog({
                    children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
                  });
                }
              });
          }}
        />
      ),
    });
  };

  const onWaiterClicked = () => {
    closeDrawer();
    openDialog({ children: <DialogWaiterRequest /> });
  };

  const onPaymentRequestClicked = () => {
    closeDrawer();
    openDialog({ children: <DialogPaymentRequest /> });
  };

  const onPaymentClicked = () => {
    closeDrawer();
    props.history.push(PathNames.CHECKOUTPAGE);
  };

  useEffect(() => {
    mynOberStore.on(mynOberStore.events.MERCHANT_EXITED, setHomeRoot);
    mynOberStore.on(mynOberStore.events.RECEIPT_CHANGED, onReceiptUpdated);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      mynOberStore.removeListener(mynOberStore.events.MERCHANT_EXITED, setHomeRoot);
      mynOberStore.removeListener(mynOberStore.events.RECEIPT_CHANGED, onReceiptUpdated);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  let menuTags = null;
  if (!mynOberStore.isHandheld() && mynOberStore.menuTagsWithLag != null) {
    menuTags = mynOberStore.menuTagsWithLag;
  }

  let serviceButtons = mynOberStore.menu.serviceArticles
    .filter((serviceArticle) => {
      if (!serviceArticle.isActive) {
        return false;
      }
      if (menuTags != null) {
        if (serviceArticle.menuTags.length === 0) {
          return true;
        }
        return menuTags.some((activatedMenuTag) => {
          return serviceArticle.menuTags.some((articleMenuTag) => {
            return articleMenuTag === activatedMenuTag;
          });
        });
      } else {
        return true;
      }
    })
    .map((serviceArticle) => {
      return (
        <ButtonDrawerMenu
          key={serviceArticle.id}
          stringId={serviceArticle.name}
          icon={null}
          // serviceArticle.path !== null ? (
          //   <img
          //     style={{
          //       maxWidth: "20%",
          //       maxHeight: "20%",
          //       objectFit: "contain",
          //     }}
          //     alt={serviceArticle.name}
          //     src={serviceArticle.path}
          //   />
          // ) : null

          onClick={() => {
            closeDrawer();
            openDialog({ children: <DialogServiceArticleRequest serviceArticle={serviceArticle} /> });
          }}
        />
      );
    });

  return (
    <div id={drawerMenuId} className={"drawer-menu " + classes.root}>
      <div className={"drawer-menu-header"}>
        <Button onClick={closeDrawer} startIcon={<MenuIcon />} style={{ height: 54 }}>
          {props.intl.formatMessage({ id: "button_close" })}
        </Button>
      </div>
      <div className={"drawer-menu-content"}>
        {hasReceiptAtCurrentMerchant && serviceButtons.length === 0 ? (
          <div>
            {mynOberStore.merchant.canMove && (
              <ButtonDrawerMenu
                onClick={onMoveClicked}
                icon={<DirectionsWalkOutlinedIcon />}
                stringId={"button_move_request"}
              />
            )}
            {mynOberStore.merchant.canWaiter && (
              <ButtonDrawerMenu
                onClick={onWaiterClicked}
                icon={<HelpOutlineOutlinedIcon />}
                stringId={"button_waiter_request"}
              />
            )}
            {!(mynOberStore.merchant.paymentMethods != null && mynOberStore.merchant.paymentMethods.length > 0) &&
              mynOberStore.merchant.canPay && (
                <ButtonDrawerMenu
                  onClick={onPaymentRequestClicked}
                  icon={<PaymentOutlinedIcon />}
                  stringId={"button_payment_request"}
                />
              )}
          </div>
        ) : null}
        {serviceButtons}
        {mynOberStore.receipt.receiptArticles.length > 0 &&
          mynOberStore.spQrCode &&
          mynOberStore.spQrCode.sp_payment_methods != null &&
          mynOberStore.spQrCode.sp_payment_methods.length > 0 && (
            <ButtonDrawerMenu
              onClick={onPaymentClicked}
              icon={<PaymentOutlinedIcon />}
              stringId={"button_to_payment"}
            />
          )}
        <ButtonDrawerMenu
          onClick={showExitMerchantAlert}
          icon={<ExitToAppIcon />}
          stringId={mynOberStore.isHandheld() ? "text_leave_table" : "text_leave_location"}
        />
        {/*<ButtonDrawerMenu onClick={openPrivacyPolicy} icon={<LockOutlinedIcon />} stringId={"button_privacy_policy"} />*/}
      </div>
    </div>
  );
}

export default withRouter(withIntl(DrawerMenuApp));
