import { DialogActions, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";
import Button from "@material-ui/core/Button";
import { tryAgainFailedOrder } from "../../utils/functions/RequestFunctions";
import ViewOrderModifierarticle from "../menu/ViewOrderModifierarticle";
import ViewReceiptArticle from "../menu/ViewReceiptArticle";
import CloseIcon from "@material-ui/icons/Close";
import DialogSuccess from "./DialogSuccess";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import mynOberStore from "../../utils/flux/MynOberStore";
import DialogOk from "./DialogOk";

export const failedOrdergroupsOpened = {};

function DialogOrdergroupFailed(props) {
  const [openDialog, closeDialog] = useDialog();

  if (!mynOberStore.failedOrderDialogIsOpen) {
    mynOberActions.setFailedOrderDialogIsOpen(true);
    failedOrdergroupsOpened[props.handheldOrdergroup.ordergroupId] = true;
  }

  const createReceiptArticleViews = (receiptArticles) => {
    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      return true; //return receiptArticle.countOpen > 0;
    });

    return filteredReceiptArticles.map((receiptArticle, i) => {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          key={i}
          name={receiptArticle.displayName}
          amount={receiptArticle.amount}
          count={receiptArticle.count}
          orderModifierarticleViews={orderModifierarticleViews}
          receiptArticle={receiptArticle}
        />
      );
    });
  };

  return (
    <>
      <DialogTitle>
        {props.intl.formatMessage({ id: "tab_order" })}
        <IconButton
          style={{ position: "absolute", top: 0, right: 0, zIndex: 10 }}
          aria-label="close"
          onClick={() => {
            closeDialog();
            mynOberActions.setFailedOrderDialogIsOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography>{props.intl.formatMessage({ id: "order_couldnt_be_processed" })}</Typography>
        {createReceiptArticleViews(props.handheldOrdergroup.receiptArticles)}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          variant={"outlined"}
          style={{ margin: 4 }}
          onClick={() => {
            closeDialog();

            mynOberActions.setFailedOrderDialogIsOpen(false);
            tryAgainFailedOrder(props.handheldOrdergroup.ordergroupId).then(() => {
              openDialog({ children: <DialogOk title={"alert_title_success"} content={"alert_text_success_order"} /> });
            });
          }}
        >
          {props.intl.formatMessage({ id: "Try again" })}
        </Button>
      </DialogActions>
    </>
  );
}

DialogOrdergroupFailed.propTypes = {
  handheldOrdergroup: PropTypes.object.isRequired,
};

export default withIntl(DialogOrdergroupFailed);
