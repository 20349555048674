import React from "react";
import { useParams, withRouter } from "react-router";
import { injectIntl } from "react-intl";
import handheldStore from "../../utils/flux/HandheldStore";
import mynOberStore from "../../utils/flux/MynOberStore";
import "./FloorPlanPage.css";
import { HandheldPathNames } from "../../routers/handheld/HandheldRouter";
import FloorPlan from "../../components/floorplan/FloorPlan";
import { Button } from "@material-ui/core";
import { updateFloorPlan } from "../../utils/functions/RequestFunctions";
import DialogError from "../../components/dialogs/DialogError";
import { useDialog } from "../../components/dialogs/DialogProvider";

function FloorPlanPage(props) {
  const spMerchantId = parseInt(useParams().spMerchantId);
  const [tables, setTables] = React.useState(handheldStore.tables[spMerchantId]);
  const [selectedFloorPlan, setSelectedFloorPlan] = React.useState(0);

  const [floorPlans, setFloorPlans] = React.useState(
    handheldStore.spMerchants[spMerchantId].floorPlans?.length > 0
      ? handheldStore.spMerchants[spMerchantId].floorPlans
      : []
  );
  const [spMerchant, setSpMerchant] = React.useState(handheldStore.spMerchants[spMerchantId]);
  const [openDialog] = useDialog();

  if (spMerchant.id !== spMerchantId) {
    setSpMerchant(handheldStore.spMerchants[spMerchantId]);
    setFloorPlans(
      handheldStore.spMerchants[spMerchantId].floorPlans?.length > 0
        ? handheldStore.spMerchants[spMerchantId].floorPlans
        : []
    );
    setSelectedFloorPlan(0);
    setTables(handheldStore.tables[spMerchantId]);
    return <div />;
  }

  // useEffect(() => {
  //   mynOberStore.on(mynOberStore.events.MENU_CREATED, updateFloorPlans);
  //   return () => {
  //     mynOberStore.removeListener(mynOberStore.events.MENU_CREATED, updateFloorPlans);
  //   };
  // });

  let planButtons = floorPlans.map((floorPlan, index) => {
    return (
      <Button
        key={index}
        variant={"contained"}
        style={index === selectedFloorPlan ? { backgroundColor: "rgba(250, 235, 215, 1)" } : {}}
        onClick={() => {
          setSelectedFloorPlan(index);
        }}
      >
        {floorPlan.name}
      </Button>
    );
  });
  return planButtons.length > 0 ? (
    <div key={spMerchant.id} className={"floor-plan-page"} style={{ display: "flex", flex: "1 1" }}>
      {planButtons.length > 1 ? <div className={"floor-plan-buttons"}>{planButtons}</div> : null}
      <FloorPlan
        key={selectedFloorPlan}
        tables={tables}
        floorPlanKey={selectedFloorPlan}
        floorPlan={floorPlans[selectedFloorPlan]}
        updateFloorPlan={() => {
          updateFloorPlan(JSON.stringify(floorPlans), spMerchant).catch((text) => {
            openDialog({ children: <DialogError content={text} /> });
          });
        }}
        onOpen={(tableName) => {
          if (tables[tableName].receiptId == null && mynOberStore.menu.articles.some((article) => article.isMenu)) {
            props.history.push(`/${props.match.params.spMerchantId}${HandheldPathNames.TABLE}/${tableName}/activate`);
          } else {
            props.history.push(
              `/${props.match.params.spMerchantId}${HandheldPathNames.TABLE}/${tableName}/merchant/menupage`
            );
          }
        }}
      />
    </div>
  ) : null;
}

export default withRouter(injectIntl(FloorPlanPage));
