import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ViewArticleHandheld.css";
import { Article } from "../../utils/menu/Article";
import withIntl from "../../utils/withIntl";
import GetCurrency from "../../utils/functions/GetCurrency";
import { ButtonBase, Paper, Typography } from "@material-ui/core";

class ViewArticleHandheld extends Component {
  constructor(props) {
    super(props);

    this.lineHeight = 12;

    this.state = {
      maxLines: 1,
    };
  }

  render() {
    return (
      <div className={this.props.selected ? "article-view-container-selected" : "article-view-container"}>
        {this.props.selected ? (
          this.props.ArticleInfoPage
        ) : (
          <ButtonBase
            component={"div"}
            className={"article-view article-view-handheld"}
            onClick={() => {
              this.props.onArticleClicked();
            }}
          >
            <Paper
              className="article-right-container MuiButton-containedPrimary"
              style={{ backgroundColor: this.props.article.handheld_bg_color }}
            >
              <div
                className={
                  this.props.enabled ? "article-name truncate-overflow" : "article-name text-disabled truncate-overflow"
                }
              >
                <Typography style={{ fontWeight: 800 }}>{this.props.article.name}</Typography>
              </div>
              <div className="article-bottom-info">
                <div className={this.props.enabled ? "article-price" : "article-price text-disabled"}>
                  {this.props.article.originalPrice !== null && (
                    <span style={{ textDecoration: "line-through", marginRight: "6px", fontSize: "1.1em" }}>
                      {GetCurrency("€") +
                        parseFloat(this.props.article.originalPrice / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </span>
                  )}
                  {this.props.article.price !== 0 ? (
                    <span style={{ color: this.props.article.originalPrice !== null ? "red" : null }}>
                      {(this.props.article.hasModifiergroupsWithPrice
                        ? this.props.intl.formatMessage({ id: "text_price_from" }) + " " + GetCurrency("€")
                        : GetCurrency("€")) +
                        parseFloat(this.props.article.price / 100).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    </span>
                  ) : null}
                </div>
                {this.props.article.count !== 0 ? (
                  <div className="column-amount-content">
                    <Typography style={{ fontWeight: 800 }}>{"x" + this.props.article.count}</Typography>
                  </div>
                ) : null}
              </div>
            </Paper>
          </ButtonBase>
        )}
      </div>
    );
  }
}

ViewArticleHandheld.propTypes = {
  article: PropTypes.instanceOf(Article).isRequired,
  enabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onArticleClicked: PropTypes.func.isRequired,
};

export default withIntl(ViewArticleHandheld);
