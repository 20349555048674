import React, { Component } from "react";
import HomePage from "../../pages/HomePage";
import QrCodeForm from "../../components/QrCodeForm";
import HeaderMyn from "../../components/HeaderMyn";
import * as listeners from "../../utils/Listeners";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import * as platformFunctions from "../../utils/functions/PlatformFunctions";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import mynOberStore from "../../utils/flux/MynOberStore";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import DialogError from "../../components/dialogs/DialogError";
import DialogOk from "../../components/dialogs/DialogOk";

export const ScannerType = {
  START: "START",
  MOVE: "MOVE",
  EXIT: "EXIT",
};

class HomeContainer extends Component {
  constructor(props) {
    super(props);

    this.onBarCodeRead = this.onBarCodeRead.bind(this);
    this.onWebcodeChange = this.onWebcodeChange.bind(this);
    this.onWebcodeSend = this.onWebcodeSend.bind(this);
    this.sendWebcode = this.sendWebcode.bind(this);

    this.qrURL = "https://web.mynober.nl/qr/";
    this.qrURL2 = "https://web.mynober.com/qr/";
    this.scannerType = this.props.scannerType === undefined ? ScannerType.START : this.props.scannerType;

    this.state = {
      isLoading: false,
      webcode: "",
    };
  }

  componentDidMount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.registerLoadingListeners(this.onLoadingChanged);
    }
  }

  componentWillUnmount() {
    if (platformFunctions.getDeviceType() !== "WEB") {
      listeners.unregisterLoadingListeners(this.onLoadingChanged);
    }
  }

  // --------------------------------------------------

  onBarCodeRead(result) {
    if (result.data !== null) {
      let webcode;
      if (result.data.length >= 27 && result.data.substr(0, 26) === this.qrURL) {
        webcode = result.data.substr(26);
      } else if (result.data.length >= 28 && result.data.substr(0, 27) === this.qrURL2) {
        webcode = result.data.substr(27);
      } else {
        webcode = result.data;
      }

      if (webcode !== this.state.webcode) {
        this.onWebcodeChange(webcode);
        this.sendWebcode(webcode);
      }
    }
  }

  onLoadingChanged = () => {
    if (this.state.isLoading !== mynOberStore.isLoading) {
      this.setState({ isLoading: mynOberStore.isLoading });
    }
  };

  onWebcodeChange(text) {
    this.setState({ webcode: text });
  }

  onWebcodeSend(event) {
    if (event !== null) {
      event.preventDefault();
    }

    if (this.state.webcode === "") {
      const [openDialog] = this.context;
      openDialog({ children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_no_code"} /> });
      return;
    }

    this.sendWebcode(this.state.webcode);
  }

  sendWebcode(webcode) {
    if (mynOberStore.isHandheld()) {
      mynOberActions.setWebcode(webcode);
      requestFunctions.getReceipt(true, webcode).catch((text) => {
        const [openDialog] = this.context;
        openDialog({ children: <DialogError content={text} /> });
      });
    } else {
      if (this.scannerType === ScannerType.MOVE) {
        requestFunctions
          .checkLocation()
          .then((distance) => {
            requestFunctions.sendMoveRequest(webcode, distance).catch((text) => {
              const [openDialog] = this.context;
              openDialog({ children: <DialogError content={text} /> });
            });
          })
          .catch((error) => {
            if (error.code === 1) {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_location_denied"} content={"alert_text_permission_location"} />,
              });
            } else {
              const [openDialog] = this.context;
              openDialog({
                children: <DialogOk title={"alert_title_failed"} content={"alert_text_failed_location"} />,
              });
            }
          });
      } else {
        requestFunctions.sendWebcode(webcode).catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
      }
    }
  }

  // --------------------------------------------------

  render() {
    return (
      <HomePage
        isHandheld={mynOberStore.isHandheld()}
        merchant={mynOberStore.merchant}
        store={mynOberStore}
        scannerType={this.scannerType}
        onBarCodeRead={this.onBarCodeRead}
        isLoading={this.state.isLoading}
        HeaderMyn={<HeaderMyn />}
        QrCodeForm={
          <QrCodeForm
            isHandheld={mynOberStore.isHandheld()}
            webcode={this.state.webcode}
            onWebcodeChange={this.onWebcodeChange}
            sendWebcode={this.onWebcodeSend}
          />
        }
      />
    );
  }
}

HomeContainer.contextType = DialogContext;

export default HomeContainer;
