import { HandheldOrdergroup } from "./HandheldOrdergroup";
import { getApplicationDate } from "../functions/HelperFunctions";

export class Receipt {
  constructor(
    id,
    identifierName,
    merchant,
    phoneNumber,
    partyId,
    tableId,
    webcode,
    ordergroups = [],
    spAyceCovers,
    transactions,
    randomPincode,
    updatedAt
  ) {
    this.id = id;
    this._identifierName = identifierName;
    this.merchant = merchant;
    this.phoneNumber = phoneNumber;
    this.partyId = partyId;
    this.tableId = tableId;
    this.webcode = webcode;
    this.ordergroups = ordergroups;
    this.spAyceCovers = spAyceCovers;
    this.transactions = transactions;
    this.transactionsObject = {};
    if (transactions) {
      transactions.forEach((transaction) => {
        this.transactionsObject[transaction.id] = transaction;
      });
    }
    this.randomPincode = randomPincode;
    this.updatedAt = updatedAt;
  }

  /**
   * Returns the identifierName for given locale, if it doesn't exist it will return an English identifierName.
   * @param {string} locale
   * @returns {string}
   */
  identifierName(locale) {
    if (this._identifierName[locale] !== undefined) {
      return this._identifierName[locale];
    } else if (this._identifierName["en"] !== undefined) {
      return this._identifierName["en"];
    } else {
      return "Table";
    }
  }

  /**
   *
   * @return {number}
   */
  get amount() {
    let totalAmount = 0;

    this.ordergroups.forEach((ordergroup: HandheldOrdergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        totalAmount += receiptArticle.amount;
      });
    });

    return totalAmount;
  }

  /**
   *
   * @returns {number}
   */
  get amountClosed() {
    let totalAmount = 0;

    this.ordergroups.forEach((ordergroup: HandheldOrdergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        totalAmount += receiptArticle.amountClosed;
      });
    });

    return totalAmount;
  }

  /**
   *
   * @returns {number}
   */
  get amountOpen() {
    let totalAmount = 0;

    this.ordergroups.forEach((ordergroup: HandheldOrdergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        totalAmount += receiptArticle.amountOpen;
      });
    });

    return totalAmount;
  }

  get receiptArticles() {
    let receiptArticles = [];
    this.ordergroups.forEach((ordergroup: HandheldOrdergroup) => {
      ordergroup.receiptArticles.forEach((receiptArticle) => {
        receiptArticles.push(receiptArticle);
      });
    });
    return receiptArticles;
  }

  get ayceTimeLimit() {
    return this.spAyceCovers?.[0]?.time_limit;
  }

  get timeInterval() {
    return this.spAyceCovers?.[0]?.time_interval;
  }

  get timeIntervalRemaining() {
    let latestAyceOrdergroup = null;
    for (let i = this.ordergroups.length - 1; i >= 0; i--) {
      if (this.ordergroups[i].isAyceOrdergroup?.created_at) {
        latestAyceOrdergroup = this.ordergroups[i].isAyceOrdergroup;
        break;
      }
    }
    if (latestAyceOrdergroup) {
      return Math.round(
        (new Date(latestAyceOrdergroup.created_at).getTime() + this.timeInterval * 1000 - getApplicationDate().getTime()) / 1000
      );
    }

    return 0;
  }

  get maxRounds() {
    return this.spAyceCovers?.[0]?.ordergroup_limit;
  }

  get rounds() {
    return this.ordergroups.reduce((count, ordergroup) => {
      if (ordergroup.isAyceOrdergroup) {
        count += 1;
      }
      return count;
    }, 0);
  }
}
