import React, { Component } from "react";
import PropTypes from "prop-types";
import AmountAddRemove from "../AmountAddRemove";
import "./ViewOrderArticle.css";
import GetCurrency from "../../utils/functions/GetCurrency";
import { Typography } from "@material-ui/core";

class ViewOrderArticle extends Component {
  render() {
    return (
      <div className="view-order-article">
        <div className="column-order-amount">
          {this.props.onAddClicked != null ? (
            <AmountAddRemove
              onRemoveClicked={this.props.onRemoveClicked}
              onAddClicked={this.props.onAddClicked}
              count={this.props.count}
            />
          ) : (
            <Typography
              style={{
                padding: "10px 0",
              }}
            >
              {this.props.count}
            </Typography>
          )}
        </div>
        <div className="column-order-name">
          <div>
            <Typography>{this.props.name}</Typography>
            {this.props.orderModifierarticleViews}
            {this.props.remark && (
              <div style={{ display: "flex", flexFlow: "wrap", width: "140%", wordBreak: "break-word" }}>
                <Typography>{this.props.remark}</Typography>
              </div>
            )}
          </div>
        </div>
        <div className="column-order-price">
          {this.props.price !== 0 && (
            <Typography>
              {GetCurrency("€")}{" "}
              {parseFloat(this.props.price / 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </Typography>
          )}
        </div>
      </div>
    );
  }
}

ViewOrderArticle.propTypes = {
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  orderModifierarticleViews: PropTypes.array,
  count: PropTypes.string.isRequired,
  onRemoveClicked: PropTypes.func,
  onAddClicked: PropTypes.func,
};

export default ViewOrderArticle;
