import React, { useEffect, useState } from "react";
import mynOberStore from "../../utils/flux/MynOberStore";
import { MenuItem, Select } from "@material-ui/core";
import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInCalendarDays,
  differenceInDays,
  format,
  subDays,
} from "date-fns";
import withIntl from "../../utils/withIntl";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import Typography from "@material-ui/core/Typography";
import { getApplicationDate } from "../../utils/functions/HelperFunctions";

window.differenceInCalendarDays = differenceInCalendarDays;

function CustomTimestampField(props) {
  const now = getApplicationDate();
  const getAsapElement = () => <MenuItem value={0}>{props.intl.formatMessage({ id: "option_possible" })}</MenuItem>;

  const customField = props.customField;
  const asapOption = !!customField.asap;
  const minimumWaitingTime = parseInt(customField.minimum_waiting_time);
  const maxWeightPerTimeblock = parseInt(customField.max_weight_per_timeblock);
  const rules = customField.order_rules;

  const [value, setValue] = React.useState(customField.value);
  const [allowAsap, setAllowAsap] = useState(
    !!isTimestampInOpeningHoursAndInFutureSimple(addSeconds(now, 1), props.openingHours, 0) &&
      asapOption &&
      props.orderType !== "takeaway"
  );
  const [selectedDate, setSelectedDate] = React.useState(
    mynOberStore.order.customFields && mynOberStore.order.customFields[customField.key]
      ? differenceInCalendarDays(mynOberStore.order.customFields[customField.key].value * 1000, getApplicationDate())
      : customField.value != null
      ? differenceInCalendarDays(customField.value * 1000, getApplicationDate())
      : null
  );

  const [timeOptions, setTimeOptions] = React.useState(null);

  useEffect(() => {
    setTimeOptions(createTimeOptionsFromOrderRules());
  }, []);

  function isTimestampInFuture(timestamp) {
    return timestamp.getTime() > now.getTime();
  }

  function isTimestampInOpeningHoursAndInFutureSimple(timestamp, openingHours, minimumWaitingTime = 0) {
    if (!isTimestampInFuture(timestamp)) {
      return false;
    }
    let dayOfWeek = timestamp.getDay();
    let openingHoursDay = openingHours[dayOfWeek];
    for (let k = 0; k < openingHoursDay.length; k++) {
      let openingHoursRange = openingHoursDay[k];
      if (Math.abs(differenceInCalendarDays(openingHoursRange.openingTime, timestamp)) > 6) {
        openingHoursRange.openingTime = subDays(
          openingHoursRange.openingTime,
          Math.floor(differenceInCalendarDays(openingHoursRange.openingTime, timestamp) / 7) * 7
        );
        openingHoursRange.closingTime = subDays(
          openingHoursRange.closingTime,
          Math.floor(differenceInCalendarDays(openingHoursRange.closingTime, timestamp) / 7) * 7
        );
      }
      let openingTime = new Date(openingHoursRange.openingTime);
      let closingTime = new Date(openingHoursRange.closingTime);
      if (openingTime.getTime() === closingTime.getTime()) {
        return false;
      }

      openingTime = addMinutes(openingTime, minimumWaitingTime);

      if (openingTime.getTime() <= timestamp.getTime() && closingTime.getTime() >= timestamp.getTime()) {
        return timestamp;
      }
      if (openingTime.getTime() <= timestamp.getTime()) {
        if (addMinutes(closingTime, minimumWaitingTime).getTime() >= timestamp.getTime()) {
          if (k + 1 === openingHoursDay.length) {
            if (differenceInCalendarDays(timestamp, getApplicationDate()) === 0) {
              return false;
            } else {
              return closingTime;
            }
          }
        }
      }
    }

    return false;
  }

  function isTimestampInOpeningHoursAndInFuture(timestamp, openingHours, minimumWaitingTime = 0, timeOptions = []) {
    if (!isTimestampInFuture(timestamp)) {
      return false;
    }
    let dayOfWeek = timestamp.getDay();
    let openingHoursDay = openingHours[dayOfWeek];
    for (let k = 0; k < openingHoursDay.length; k++) {
      let openingHoursRange = openingHoursDay[k];
      if (Math.abs(differenceInCalendarDays(openingHoursRange.openingTime, timestamp)) > 6) {
        openingHoursRange.openingTime = subDays(
          openingHoursRange.openingTime,
          Math.floor(differenceInCalendarDays(openingHoursRange.openingTime, timestamp) / 7) * 7
        );
        openingHoursRange.closingTime = subDays(
          openingHoursRange.closingTime,
          Math.floor(differenceInCalendarDays(openingHoursRange.closingTime, timestamp) / 7) * 7
        );
      }
      let openingTime = new Date(openingHoursRange.openingTime);
      let closingTime = new Date(openingHoursRange.closingTime);
      if (openingTime.getTime() === closingTime.getTime()) {
        return false;
      }

      openingTime = addMinutes(openingTime, minimumWaitingTime);

      if (openingTime.getTime() <= timestamp.getTime() && closingTime.getTime() >= timestamp.getTime()) {
        return timestamp;
      }
      if (openingTime.getTime() <= timestamp.getTime()) {
        if (addMinutes(closingTime, minimumWaitingTime).getTime() >= timestamp.getTime()) {
          if (k + 1 === openingHoursDay.length) {
            if (differenceInCalendarDays(timestamp, getApplicationDate()) === 0) {
              if (!(timeOptions?.[0]?.timeOptions.length > 0)) {
                if (props.orderType !== "takeaway") {
                  setAllowAsap(true);
                } else {
                  return closingTime;
                }
              }
              return false;
            } else {
              return closingTime;
            }
          } else {
            if (
              timeOptions?.[0]?.timeOptions.length === 0 &&
              differenceInCalendarDays(timestamp, getApplicationDate()) === 0 &&
              props.orderType !== "takeaway"
            ) {
              setAllowAsap(true);
            }
          }
        }
      } else {
        if (differenceInCalendarDays(timestamp, getApplicationDate()) === 0) {
          setAllowAsap(false);
        }
      }
    }

    return false;
  }

  const startOfDate = getApplicationDate();
  startOfDate.setHours(0);
  startOfDate.setMinutes(0);
  startOfDate.setSeconds(0);
  startOfDate.setMilliseconds(0);

  const createTimeOptionsFromOrderRules = () => {


    let timeOptionsPerDay = [];

    let startingDate = getApplicationDate();
    let currentDayIndex = 0;

    const addTimestamp = (timestamp2: Date) => {
      if (timestamp2.getTime() === 0) {
        let dayIndex = 0;
        if (!timeOptionsPerDay[dayIndex]) {
          timeOptionsPerDay[dayIndex] = {};
          timeOptionsPerDay[dayIndex].timeOptions = [];
          timeOptionsPerDay[dayIndex].date = startingDate;
        }
        timeOptionsPerDay[dayIndex].timeOptions.push(getAsapElement);
        return;
      }

      let timestamp = getApplicationDate();
      timestamp.setTime(timestamp2.getTime());
      let dayIndex = differenceInCalendarDays(timestamp, startingDate);
      if (!timeOptionsPerDay[dayIndex]) {
        timeOptionsPerDay[dayIndex] = {};
        timeOptionsPerDay[dayIndex].timeOptions = [];
        timeOptionsPerDay[dayIndex].date = timestamp;
      }

      if (timeOptionsPerDay[dayIndex].timeOptions.length > 0) {
        if (
          timeOptionsPerDay[dayIndex].timeOptions[timeOptionsPerDay[dayIndex].timeOptions.length - 1].props.value ===
          timestamp.getTime()
        ) {
          return;
        }
      }

      let blockedByTimeslots = false;
      if (
        props.timeslots != null &&
        maxWeightPerTimeblock > 0 &&
        props.timeslots[timestamp.getTime() / 1000] != null &&
        props.timeslots[timestamp.getTime() / 1000] >= maxWeightPerTimeblock
      ) {
        blockedByTimeslots = true;
      }
      if (props.max_ta_orders_blocks?.[timestamp.getTime() / 1000]?.[props.orderType + "_blocked"]) {
        blockedByTimeslots = true;
      }

      timeOptionsPerDay[dayIndex].timeOptions.push(
        <MenuItem value={timestamp.getTime()} key={timestamp.getTime()} disabled={blockedByTimeslots}>
          {format(timestamp, "H:mm")}{" "}
          {blockedByTimeslots ? props.intl.formatMessage({ id: "text_time_slot_is_not_available" }) : null}
        </MenuItem>
      );
    };

    if (rules["timestamps"]) {
      rules["timestamps"].forEach((timestamp) => {
        let time = timestamp["timestamp"] * 1000;

        let startDayTimestamp = new Date(time);
        startDayTimestamp.setHours(0);
        startDayTimestamp.setMinutes(0);
        startDayTimestamp.setSeconds(0);
        startDayTimestamp.setMilliseconds(0);
        let currentTimestamp = new Date(time);
        if (isTimestampInFuture(currentTimestamp)) {
          addTimestamp(currentTimestamp);
        }

        let repeat = timestamp["repeat"];
        let numberOfDaysAhead = timestamp["numberOfDaysAhead"];

        if (repeat) {
          do {
            let valueBiggerThanZero = false;
            Object.keys(repeat).forEach((key) => {
              switch (key) {
                case "days":
                  if (repeat[key] > 0) {
                    valueBiggerThanZero = true;
                  }
                  currentTimestamp = addDays(currentTimestamp, repeat[key]);
                  break;
                case "hours":
                  if (repeat[key] > 0) {
                    valueBiggerThanZero = true;
                  }
                  currentTimestamp = addHours(currentTimestamp, repeat[key]);
                  break;
                case "minutes":
                  if (repeat[key] > 0) {
                    valueBiggerThanZero = true;
                  }
                  currentTimestamp = addMinutes(currentTimestamp, repeat[key]);
                  break;
                default:
                  if (repeat[key] > 0) {
                    valueBiggerThanZero = true;
                  }
                  currentTimestamp = addMinutes(currentTimestamp, repeat[key]);
                  console.log("ERROR");
                  break;
              }
            });
            if (!valueBiggerThanZero) {
              break;
            }

            if (
              differenceInDays(currentTimestamp, startDayTimestamp) <= numberOfDaysAhead &&
              isTimestampInOpeningHoursAndInFuture(currentTimestamp, props.openingHours, timeOptionsPerDay)
            ) {
              addTimestamp(
                isTimestampInOpeningHoursAndInFuture(currentTimestamp, props.openingHours, timeOptionsPerDay)
              );
            }
          } while (differenceInDays(currentTimestamp, startDayTimestamp) <= numberOfDaysAhead);
        }
      });
    }

    if (rules["repeated_patterns"]) {
      rules["repeated_patterns"].forEach((pattern) => {
        let repeat = pattern["repeat"];
        let currentTimestamp = getApplicationDate();
        /// INIT
        Object.keys(repeat).forEach((key) => {
          switch (key) {
            case "hours":
              currentTimestamp = addHours(
                currentTimestamp,
                Math.ceil((currentTimestamp.getHours() + 1) / repeat[key]) * repeat[key] - currentTimestamp.getHours()
              );
              break;
            case "minutes":
              currentTimestamp = addMinutes(
                currentTimestamp,
                Math.ceil((currentTimestamp.getMinutes() + 1) / repeat[key]) * repeat[key] -
                  currentTimestamp.getMinutes()
              );
              break;
            default:
              currentTimestamp = addMinutes(currentTimestamp, repeat[key]);
              console.log("ERROR");
              break;
          }
        });

        currentTimestamp.setSeconds(0);
        currentTimestamp.setMilliseconds(0);

        let defaultValues = pattern["default_values"];
        if (defaultValues) {
          Object.keys(defaultValues).forEach((key) => {
            switch (key) {
              case "hours":
                currentTimestamp.setHours(defaultValues[key]);
                break;
              case "minutes":
                currentTimestamp.setMinutes(defaultValues[key]);
                break;
              default:
                break;
            }
          });
        }

        let offset = pattern["offset"];
        if (offset) {
          Object.keys(offset).forEach((key) => {
            switch (key) {
              case "days":
                currentTimestamp = addDays(currentTimestamp, offset[key]);
                break;
              case "hours":
                currentTimestamp = addHours(currentTimestamp, offset[key]);
                break;
              case "minutes":
                currentTimestamp = addMinutes(currentTimestamp, offset[key]);
                break;
              default:
                break;
            }
          });
        }

        currentTimestamp = addMinutes(currentTimestamp, minimumWaitingTime);

        let numberOfDaysAhead = pattern["numberOfDaysAhead"];
        // let diff = differenceInMinutes(currentTimestamp, getApplicationDate());
        // if (diff < 0) {
        //   currentTimestamp = addMinutes(currentTimestamp, -diff);
        // }
        // diff = differenceInMinutes(currentTimestamp, getApplicationDate());
        // if (diff < minimumWaitingTime) {
        //   currentTimestamp = addMinutes(currentTimestamp, minimumWaitingTime - diff);
        // }

        while (differenceInDays(currentTimestamp, startOfDate) <= numberOfDaysAhead) {
          if (
            isTimestampInOpeningHoursAndInFuture(
              currentTimestamp,
              props.openingHours,
              minimumWaitingTime,
              timeOptionsPerDay
            )
          ) {
            addTimestamp(
              isTimestampInOpeningHoursAndInFuture(
                currentTimestamp,
                props.openingHours,
                minimumWaitingTime,
                timeOptionsPerDay
              )
            );
          }

          Object.keys(repeat).forEach((key) => {
            switch (key) {
              case "days":
                currentTimestamp = addDays(currentTimestamp, repeat[key]);
                break;
              case "hours":
                currentTimestamp = addHours(currentTimestamp, repeat[key]);
                break;
              case "minutes":
                currentTimestamp = addMinutes(currentTimestamp, repeat[key]);
                break;
              default:
                currentTimestamp = addMinutes(currentTimestamp, repeat[key]);
                console.log("ERROR");
                break;
            }
          });
        }
      });
    }
    timeOptionsPerDay.forEach((day) => {
      day.timeOptions.sort((a, b) => {
        return a.props.value - b.props.value;
      });
    });

    return timeOptionsPerDay;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography>{props.intl.formatMessage({ id: "Pick a date" })}</Typography>
      <Select
        value={selectedDate}
        margin={"dense"}
        style={{ maxWidth: "331px" }}
        fullWidth
        required={customField.required}
        variant={"outlined"}
        onChange={(e) => {
          setSelectedDate(e.target.value);
        }}
      >
        {allowAsap && <MenuItem value={0}>{props.intl.formatMessage({ id: "today" })}</MenuItem>}
        {timeOptions?.map((day, index) => {
          let dayString = props.intl.formatMessage({ id: format(day.date, "iiii").toLowerCase() });

          if (differenceInDays(day.date, startOfDate) === 0) {
            if (allowAsap) {
              return null;
            }
            dayString = props.intl.formatMessage({ id: "today" });
          }
          if (differenceInDays(day.date, startOfDate) === 1) {
            dayString = props.intl.formatMessage({ id: "Tomorrow" });
          }
          if (differenceInDays(day.date, startOfDate) > 6) {
            dayString = format(day.date, "dd LLL");
          }
          return <MenuItem value={differenceInCalendarDays(day.date, getApplicationDate())}>{dayString}</MenuItem>;
        })}
      </Select>

      {selectedDate != null && (
        <>
          <Typography>{props.intl.formatMessage({ id: "Pick a time" })}</Typography>
          <Select
            margin={"dense"}
            style={{ maxWidth: "331px" }}
            fullWidth
            variant={"outlined"}
            required={customField.required}
            value={value}
            onChange={(e) => {
              let customFields = Object.assign({}, mynOberStore.order.customFields);
              if (customFields[customField.key] == null) {
                customFields[customField.key] = {};
              }
              customFields[customField.key].value = e.target.value / 1000;
              customFields[customField.key].type = customField.type;
              if (props.onChange) {
                props.onChange(e.target.value / 1000);
              }
              mynOberActions.updateOrder({ customFields: customFields });
              // }
              setValue(e.target.value);
            }}
          >
            {selectedDate === 0 && allowAsap ? getAsapElement() : null}
            {timeOptions?.[selectedDate]?.timeOptions.map((option) => {
              return option;
            })}
          </Select>
        </>
      )}
    </div>
  );
}

export default withIntl(CustomTimestampField);
