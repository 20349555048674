import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";
import mynOberStore from "../../utils/flux/MynOberStore";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  root: {
    color: theme.palette.primary.main,
    border: "1px solid " + theme.palette.primary.main,
  },
  iconOutlined: { color: theme.palette.primary.main },
}));

function DialogSelectMenuTag(props) {
  const classes = useStyles();

  const menuTags = useMemo(() => {
    const obj = mynOberStore.menu.getMenuTags();
    const arr = [];
    Object.keys(obj).forEach((key) => {
      arr.push(key);
    });
    return arr;
  }, []);

  const [, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "Pick a menu" })}</DialogTitle>
      <DialogContent>
        <div className={classes.div}>
          {menuTags.map((menuTag) => (
            <Button
              className={classes.button}
              variant={"outlined"}
              onClick={() => {
                props.onOkClicked(menuTag);
                closeDialog();
              }}
            >
              {menuTag}
            </Button>
          ))}
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              props.onOkClicked(null);
              closeDialog();
            }}
          >
            {props.intl.formatMessage({ id: "All menus" })}
          </Button>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
            }}
          >
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

DialogSelectMenuTag.propTypes = {
  onOkClicked: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default withIntl(DialogSelectMenuTag);
