import { MynOberUser } from "./MynOberUser";

export class HandheldUser extends MynOberUser {
  constructor(deviceId, devicePass, firstAskNotification, firstTimeApp, refreshToken, password, username) {
    super(deviceId, devicePass, firstAskNotification, firstTimeApp, refreshToken);
    this._loggedIn = false;
    this._password = password;
    this._username = username;
  }

  get loggedIn() {
    return this._loggedIn;
  }

  set loggedIn(value) {
    this._loggedIn = value;
  }

  get password() {
    return this._password;
  }

  get username() {
    return this._username;
  }

  login(password, username) {
    this._loggedIn = true;
    this._password = password;
    this._username = username;
  }

  logout() {
    this._loggedIn = false;
    this._password = undefined;
    this._username = undefined;
  }
}
