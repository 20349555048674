import React, { Component } from "react";
import { injectIntl } from "react-intl";
import "./HeaderMyn.css";

class HeaderMyn extends Component {
  render() {
    if (window.whiteLabel === "vapiano") {
      return (
        <div className={"header-myn"} style={{ height: "30vh" }}>
          <img src={"/vapiano/images/logo.png"} alt={this.props.intl.formatMessage({ id: "image_mynober_logo" })} />
        </div>
      );
    } else if (window.whiteLabel === "jamezz") {
      return (
        <div className={"header-myn"} style={{ height: "30vh" }}>
          <img src={"/jamezz/images/logo.png"} alt={"Jamezz Logo"} />
        </div>
      );
    } else {
      return (
        <div className={"header-myn"}>
          <img
            src={"/images/" + window.label.images.folder + "/logo-white.png"}
            alt={this.props.intl.formatMessage({ id: "image_mynober_logo" })}
          />
        </div>
      );
    }
  }
}

export default injectIntl(HeaderMyn);
