import { DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { useDialog } from "./DialogProvider";
import Button from "@material-ui/core/Button";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import DialogError from "./DialogError";
import mynOberStore from "../../utils/flux/MynOberStore";
import { OrderArticle } from "../../utils/order/OrderArticle";
import DialogPasswordProtectedAction from "./DialogPasswordProtectedAction";
import ViewOrderModifierarticle from "../menu/ViewOrderModifierarticle";
import ViewReceiptArticle from "../menu/ViewReceiptArticle";

function DialogHandheldOrdergroupActions(props) {
  const [openDialog, closeDialog] = useDialog();

  const createReceiptArticleViews = (receiptArticles) => {
    let filteredReceiptArticles = receiptArticles.filter((receiptArticle) => {
      return true; //return receiptArticle.countOpen > 0;
    });

    return filteredReceiptArticles.map((receiptArticle, i) => {
      let orderModifierarticleViews = [];
      receiptArticle.orderModifiergroups.forEach((orderModifiergroup) => {
        orderModifiergroup.orderModifierarticles.forEach((orderModifierarticle) => {
          orderModifierarticleViews.push(
            <ViewOrderModifierarticle
              key={orderModifierarticle.id}
              orderModifierarticle={orderModifierarticle}
              orderModifiergroup={orderModifiergroup}
            />
          );
        });
      });

      return (
        <ViewReceiptArticle
          key={i}
          name={receiptArticle.displayName}
          amount={receiptArticle.amount}
          count={receiptArticle.count}
          orderModifierarticleViews={orderModifierarticleViews}
          receiptArticle={receiptArticle}
        />
      );
    });
  };

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: "tab_order" })}</DialogTitle>
      <DialogContent>
        {createReceiptArticleViews(props.handheldOrdergroup.receiptArticles)}
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Button
            // disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const voidItems = () => {
                let voidData = [];
                props.handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
                  let article = mynOberStore.menu.getArticleById(receiptArticle.id);
                  if (article && receiptArticle.countOpen > 0) {
                    let orderArticle = new OrderArticle(
                      article,
                      receiptArticle.countOpen,
                      receiptArticle.orderModifiergroups,
                      null
                    );
                    voidData.push(orderArticle.toApiForm());
                  }
                });

                if (voidData.length > 0) {
                  requestFunctions
                    .sendVoid(voidData, props.table.id, mynOberStore.merchant.id)
                    .then((resolve) => {
                      closeDialog();
                    })
                    .catch((text) => {
                      openDialog({ children: <DialogError content={"ERROR"} /> });
                    });
                }
              };

              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"VOID_ITEMS"}
                      onPasswordOk={() => {
                        voidItems();
                      }}
                    />
                  ),
                });
              } else {
                voidItems();
              }
            }}
          >
            {props.intl.formatMessage({ id: "VOID_ITEMS" })}
          </Button>
          <Button
            // disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const addPayment = () => {
                let MArticles = [];
                let amount = 0;
                props.handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
                  let article = mynOberStore.menu.getArticleById(receiptArticle.id);
                  if (article && receiptArticle.countOpen > 0) {
                    let orderArticle = new OrderArticle(
                      article,
                      receiptArticle.countOpen,
                      receiptArticle.orderModifiergroups,
                      null
                    );
                    MArticles.push(orderArticle.toApiForm());
                    amount += orderArticle.amount;
                  }
                });
                requestFunctions
                  .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                    amount: amount,
                    is_turnover: false,
                    name: props.intl.formatMessage({ id: "On the house" }),
                  })
                  .then((resolve) => {
                    closeDialog();
                  })
                  .catch((text) => {
                    openDialog({ children: <DialogError content={"ERROR"} /> });
                  });
              };

              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"On the house"}
                      onPasswordOk={() => {
                        addPayment();
                      }}
                    />
                  ),
                });
              } else {
                addPayment();
              }
            }}
          >
            {props.intl.formatMessage({ id: "On the house" })}
          </Button>
          <Button
            // disabled={props.receiptArticle.countOpen === 0}
            variant={"outlined"}
            style={{ margin: 4 }}
            onClick={() => {
              const addPayment = () => {
                let MArticles = [];
                let amount = 0;
                props.handheldOrdergroup.receiptArticles.forEach((receiptArticle) => {
                  let article = mynOberStore.menu.getArticleById(receiptArticle.id);
                  if (article) {
                    let orderArticle = new OrderArticle(
                      article,
                      receiptArticle.countOpen,
                      receiptArticle.orderModifiergroups,
                      null
                    );

                    MArticles.push(orderArticle.toApiForm());
                    amount += orderArticle.amount;
                  }
                });
                requestFunctions
                  .addPayment(MArticles, props.table.id, props.receipt.spMerchantId, {
                    amount: amount,
                    is_turnover: false,
                    name: props.intl.formatMessage({ id: "Loss" }),
                  })
                  .then((resolve) => {
                    closeDialog();
                  })
                  .catch((text) => {
                    openDialog({ children: <DialogError content={"ERROR"} /> });
                  });
              };

              let password = mynOberStore.merchant.styling?.void_password;
              if (password?.length > 0) {
                openDialog({
                  children: (
                    <DialogPasswordProtectedAction
                      password={password}
                      title={"Loss"}
                      onPasswordOk={() => {
                        addPayment();
                      }}
                    />
                  ),
                });
              } else {
                addPayment();
              }
            }}
          >
            {props.intl.formatMessage({ id: "Loss" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

DialogHandheldOrdergroupActions.propTypes = {
  handheldOrdergroup: PropTypes.object.isRequired,
};

export default withIntl(DialogHandheldOrdergroupActions);
