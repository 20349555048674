import { Modifiergroup } from "../menu/Modifiergroup";
import { OrderModifierarticle } from "./OrderModifierarticle";

export class OrderModifiergroup extends Modifiergroup {
  /**
   *
   * @param {Modifiergroup} modifiergroup
   * @param {OrderModifierarticle[]} orderModifierarticles
   */
  constructor(modifiergroup, orderModifierarticles) {
    super(
      modifiergroup.id,
      modifiergroup.maxN,
      modifiergroup.modifierarticleIds,
      modifiergroup.multiple,
      modifiergroup.name,
      modifiergroup.numerable,
      modifiergroup.optional,
      modifiergroup.priority
    );
    this.orderModifierarticles = orderModifierarticles;
  }

  get count() {
    return this.orderModifierarticles.reduce((accumulator, orderModifierarticle) => {
      return orderModifierarticle.count + accumulator;
    }, 0);
  }

  /**
   *
   * @param {*} obj
   * @returns {boolean}
   */
  equals(obj) {
    if (!(obj instanceof OrderModifiergroup)) {
      return false;
    }

    if (obj.id !== this.id) {
      return false;
    }

    if (obj.orderModifierarticles.length !== this.orderModifierarticles.length) {
      return false;
    }

    return !obj.orderModifierarticles.some((objOrderModifierarticle: OrderModifierarticle) => {
      return (
        this.orderModifierarticles.findIndex((orderModifierarticle: OrderModifierarticle) => {
          return objOrderModifierarticle.equals(orderModifierarticle);
        }) === -1
      );
    });
  }

  toApiForm = () => {
    return {
      m_modifiergroup_id: this.id,
      m_modifierarticles: this.orderModifierarticles.map((orderModifierarticle: OrderModifierarticle) =>
        orderModifierarticle.toApiForm()
      ),
    };
  };
}
