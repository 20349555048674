export class Modifierarticle {
  /**
   *
   * @param {number} id
   * @param {string|null} description
   * @param {number} maxN
   * @param {string} name
   * @param {number} price
   * @param {number} priority
   * @param {boolean} handheldOnly
   */
  constructor(id, description, maxN, name, price, priority, handheldOnly, isSideDish) {
    this.id = id;
    this.description = description;
    this.maxN = maxN;
    this.name = name;
    this.price = price;
    this.priority = priority;
    this.handheldOnly = handheldOnly;
    this.isSideDish = isSideDish;
  }
}
