import React, { Component } from "react";
import "./BottomGradient.css";
import PropTypes from "prop-types";
import { PathNames } from "../../routers/PathNames";
import mynOberStore from "../../utils/flux/MynOberStore";

class BottomGradient extends Component {
  constructor(props) {
    super(props);
    this.bottomGradientId = "bottom-gradient";
  }

  componentDidMount() {
    document.addEventListener("scroll", this.onScroll);
    this.onScroll();
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.onScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filter !== prevProps.filter || this.props.pathname !== prevProps.pathname) {
      this.onScroll();
    }
  }

  onScroll = () => {
    let bottomGradient = document.getElementById(this.bottomGradientId);
    let scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    if (bottomGradient != null) {
      if (scrollHeight > window.pageYOffset + window.innerHeight) {
        if (bottomGradient.classList.contains("invisible")) {
          bottomGradient.classList.remove("invisible");
        }
      } else {
        if (!bottomGradient.classList.contains("invisible")) {
          bottomGradient.classList.add("invisible");
        }
      }
    }
  };

  render() {
    let className;
    if (mynOberStore.isWidescreen) {
      className = "bottom-gradient bottom-gradient-wide bottom-gradient-low invisible";
    } else if (this.props.pathname === PathNames.TAKEAWAYPAGE || this.props.pathname === PathNames.DELIVERYPAGE) {
      className = "bottom-gradient bottom-gradient-small bottom-gradient-low invisible";
    } else {
      className = "bottom-gradient bottom-gradient-small bottom-gradient-high invisible";
    }

    return <div id={this.bottomGradientId} className={className} />;
  }
}

BottomGradient.propTypes = {
  filter: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

export default BottomGradient;
