import React, { useEffect } from "react";
import { DialogContainer } from "./DialogContainer";

export const DialogContext = React.createContext();

export const useDialog = () => React.useContext(DialogContext);

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = React.useState([]);
  const createDialog = (option) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const closeDialog = (dialog) => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs];
    });
  };

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      // Nope, go back to your page
      // this.props.history.go(1);
      setDialogs([]);
    });
  }, []);

  const contextValue = React.useRef([createDialog, closeDialog]);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        return <DialogContainer key={i} {...dialog} onClose={closeDialog} />;
      })}
    </DialogContext.Provider>
  );
}
