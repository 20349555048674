import React, { Component } from "react";
import { PathNames } from "../../routers/PathNames";
import HeaderPage from "../../components/HeaderPage";
import "./DeliveryPage.css";
import * as mynOberActions from "../../utils/flux/MynOberActions";
import { PropTypes } from "prop-types";
import { injectIntl } from "react-intl";
import * as platformFunctions from "../../utils/functions/PlatformFunctions.web";
import * as requestFunctions from "../../utils/functions/RequestFunctions";
import InputPhoneNumber from "../../components/web/form_fields/InputPhoneNumber";
import InputEmail from "../../components/web/form_fields/InputEmail";
import InputPaymentMethod from "../../components/web/form_fields/InputPaymentMethod";
import mynOberStore from "../../utils/flux/MynOberStore";
import { OrderTypes } from "../../utils/order/Order";
import { getApplicationDate, getValue } from "../../utils/functions/HelperFunctions";
import { Button, Checkbox, Link, TextField } from "@material-ui/core";
import TipSelector from "../../components/TipSelector";
import { hasOnlinePayment } from "../../utils/PaymentMethod";
import GetCurrency from "../../utils/functions/GetCurrency";
import DialogOk from "../../components/dialogs/DialogOk";
import DialogError from "../../components/dialogs/DialogError";
import { DialogContext } from "../../components/dialogs/DialogProvider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormField from "../../components/FormField";
import CustomTimestampField from "../../components/customFields/CustomTimestampField";
import Typography from "@material-ui/core/Typography";
import PackagingInput from "../../components/packaging/PackagingInput";

class DeliveryPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...this._getState(), refreshKey: "" };

    this.paymentMethods = mynOberStore.merchant.paymentMethods.filter((paymentMethod) => {
      if (
        (paymentMethod.delivery !== null || paymentMethod.delivery !== undefined) &&
        paymentMethod.delivery === false
      ) {
        return false;
      } else {
        return true;
      }
    });
  }

  _getState = () => {
    const newState =  {
      paymentMethod: mynOberStore.deliveryOrder.paymentMethod,
      deliveryTime: mynOberStore.deliveryOrder.deliveryTime,
      acceptMarketing: mynOberStore.deliveryOrder.acceptMarketing,
      contactDetails: mynOberStore.contactDetails,
      userSelectedPackaging: mynOberStore.userSelectedPackaging,
      deliveryCosts: mynOberStore.deliveryOrder.deliveryCosts,
      remark: mynOberStore.deliveryOrder.remark == null ? "" : mynOberStore.deliveryOrder.remark,
      max_ta_orders_blocks: mynOberStore.merchant.max_ta_orders_blocks,
    };

    if (this.state?.max_ta_orders_blocks !== mynOberStore.merchant?.max_ta_orders_blocks) {
      newState.refreshKey = new Date().toISOString();
    }

    return newState;
  };

  _updateState = () => {
    this.setState(this._getState());
  };

  componentDidMount() {
    // this.state = this._getState();
    mynOberStore.on(mynOberStore.events.CONTACT_DETAILS_UPDATED, this._updateState);
    mynOberStore.on(mynOberStore.events.DELIVERY_ORDER_UPDATED, this._updateState);
    mynOberStore.on(mynOberStore.events.TIMESTAMP_NOT_AVAILABLE, this._updateState);

    // window.deliveryPage = this;
    mynOberStore.on(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendAlertClosed);
  }

  componentWillUnmount() {
    mynOberStore.removeListener(mynOberStore.events.CONTACT_DETAILS_UPDATED, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.DELIVERY_ORDER_UPDATED, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.TIMESTAMP_NOT_AVAILABLE, this._updateState);
    mynOberStore.removeListener(mynOberStore.events.ORDER_SEND_SUCCESSFUL, this.onOrderSendAlertClosed);
  }

  onOrderSendAlertClosed = () => {
    if (hasOnlinePayment([this.state.paymentMethod])) {
      this.props.history.push(PathNames.RETURNPAGE + "?taMerchantId=" + mynOberStore.merchant.id);
    }
    // window.location.pathname = PathNames.MENUPAGE;
  };

  onContactDetailChanged = (key, value) => {
    mynOberActions.setContactDetails({ [key]: value || "" });
  };

  onPickupTimeChange = (timestamp) => {
    let deliveryOrder = mynOberStore.deliveryOrder;
    deliveryOrder.deliveryTime = timestamp;
    mynOberActions.updateDeliveryOrder(deliveryOrder);
  };

  onPaymentMethodChange = (paymentMethodKey) => {
    let paymentMethod = mynOberStore.merchant.paymentMethods.find((paymentMethod) => {
      return paymentMethod.key === paymentMethodKey;
    });
    let deliveryOrder = mynOberStore.deliveryOrder;
    deliveryOrder.paymentMethod = paymentMethod;
    mynOberActions.updateDeliveryOrder(deliveryOrder);
  };

  onUserSelectedPackagingChange = (articleId) => {
    let packaging = mynOberStore.merchant.info?.packagings?.find((packagingArticle) => {
      return packagingArticle.id === articleId;
    });

    if (packaging) {
      mynOberActions.updateUserSelectedPackaging(packaging);
    }
  };

  onURLCreated = (url) => {
    if (
      mynOberStore.merchant.styling.iframe === true &&
      this.state.paymentMethod["key"] === "IDEAL" &&
      (mynOberStore.merchant.info == null || mynOberStore.merchant.info.paynl_return_domain == null)
    ) {
      this.windowReference = window.open();
    }

    if (mynOberStore.merchant.styling.iframe === true && this.state.paymentMethod["key"] === "IDEAL") {
      if (this.windowReference != null) {
        this.windowReference.location = url;
      } else {
        window.top.location.href = url;
      }
    } else {
      window.location = url;
    }
  };

  sendOrderDelivery = (e) => {
    if (e !== null) {
      e.preventDefault();
    }

    // let taPostalCode =mynOberStore.merchant.taPostalCodes.find((taPostalCode) => {
    //   return mynOberStore.contactDetails.postalCode.includes(taPostalCode.postalCode);
    // });
    //
    // if (taPostalCode != null) {
    //   if (mynOberStore.order.amount < taPostalCode.minAmount) {
    //     return taPostalCode.extraCosts;
    //   } else {
    //     return 0;
    //   }
    // } else {
    //   return 0;
    // }

    if (
      mynOberStore.merchant.info != null &&
      mynOberStore.merchant.info.minimum_amount != null &&
      mynOberStore.merchant.info.minimum_amount.delivery != null
    ) {
      if (mynOberStore.order.amount < mynOberStore.merchant.info.minimum_amount.delivery) {
        const [openDialog] = this.context;
        openDialog({
          children: (
            <DialogOk
              title={"alert_title_warning"}
              rawContent={this.props.intl.formatMessage(
                {
                  id: "minimum_amount",
                },
                {
                  minimumAmount: parseFloat(mynOberStore.merchant.info.minimum_amount.delivery / 100).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  ),
                }
              )}
            />
          ),
        });
        return;
      }
    }

    if (this.state.deliveryTime !== 0 && getApplicationDate().getTime() > this.state.deliveryTime) {
      this.onPickupTimeChange(null);
      this.setState({ deliveryTime: null, refreshKey: (new Date()).toISOString });
      setTimeout(() => {
        const element = document.getElementById("button-delivery-submit");
        if (element) element.click();
      }, 200);
      return;
    }

    if (this.state.deliveryTime !== "") {
      let contactDetails = { ...mynOberStore.contactDetails };

      if (getValue(mynOberStore, "merchant.info.ask_contact_details.delivery.business_name", false)) {
        contactDetails["businessName"] = mynOberStore.contactDetails.businessName;
      } else {
        contactDetails["businessName"] = "";
      }

      requestFunctions
        .sendDeliveryOrder(contactDetails, mynOberStore.deliveryOrder, this.onURLCreated)
        .then(() => {
          const [openDialog] = this.context;
          openDialog(
            {
              children: (
                <DialogOk
                  title={"alert_title_success"}
                  content={"alert_text_success_order"}
                  onOkClicked={() => {
                    this.props.history.goBack();
                  }}
                />
              ),
            },
            true
          );
        })
        .catch((text) => {
          const [openDialog] = this.context;
          openDialog({ children: <DialogError content={text} /> });
        });
    } else {
      const [openDialog] = this.context;
      openDialog({
        children: (
          <DialogOk
            title={"alert_title_warning"}
            rawContent={this.props.intl.formatMessage({
              id: "There is no time slot available, try it again another time!",
            })}
          />
        ),
      });
    }
  };

  render() {
    return (
      <div className="delivery-page">
        <HeaderPage headerStringId={"header_delivery"} />

        {mynOberStore.merchant.takeaway &&
        mynOberStore.merchant.takeawayActive &&
        mynOberStore.merchant.delivery &&
        mynOberStore.merchant.deliveryActive ? (
          <Button
            style={{ margin: "10px", fontSize: "1.3rem" }}
            className={"button-switch-method"}
            variant={"outlined"}
            onClick={() => {
              this.props.history.replace(PathNames.TAKEAWAYPAGE);
              mynOberActions.setOrderType(OrderTypes.TAKEAWAY);
            }}
          >
            {this.props.intl.formatMessage({ id: "text_pick_up_anyway" })}
          </Button>
        ) : null}
        <form onSubmit={this.sendOrderDelivery}>
          <div
            className={"delivery-page-scrollable"}
            style={{
              overflowY: mynOberStore.isWidescreen ? "auto" : "none",
              height: mynOberStore.isWidescreen ? "0" : "auto",
            }}
          >
            {mynOberStore.merchant.askContactDetails ? (
              <FormField
                required={true}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.address?.hide}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.address?.disabled}
                titleLabel={"label_address"}
                pattern={".+ [0-9]+.*"}
                name={"address"}
                value={mynOberStore.contactDetails.address}
                onChange={(value) => this.onContactDetailChanged("address", value)}
              />
            ) : null}
            {mynOberStore.merchant.askContactDetails &&
            getValue(mynOberStore, "merchant.info.ask_contact_details.delivery.postal_code", null) == null ? (
              <FormField
                required={true}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.postalCode?.hide}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.postalCode?.disabled}
                value={mynOberStore.contactDetails.postalCode}
                name={"postal-code"}
                pattern={"[0-9]{4,5} ?[A-Za-z]?[A-Za-z]?"}
                titleLabel={"label_postal_code"}
                onChange={(value) => this.onContactDetailChanged("postalCode", value)}
              />
            ) : null}
            {mynOberStore.merchant.askContactDetails ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.city?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.city?.hide}
                titleLabel={"label_city"}
                pattern={".+"}
                name={"city"}
                value={mynOberStore.contactDetails.city}
                onChange={(value) => this.onContactDetailChanged("city", value)}
              />
            ) : null}
            {mynOberStore.merchant?.info?.ask_contact_details?.delivery?.district === 1 ? (
              <FormField
                required={true}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.district?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.district?.hide}
                titleLabel={"label_district"}
                pattern={".+"}
                name={"district"}
                value={mynOberStore.contactDetails.district}
                onChange={(value) => this.onContactDetailChanged("district", value)}
              />
            ) : null}
            {getValue(mynOberStore, "merchant.info.ask_contact_details.delivery.business_name", false) ? (
              <FormField
                required={
                  mynOberStore.merchant?.info?.default_contact_details?.delivery?.businessName?.required ?? false
                }
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.businessName?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.businessName?.hide}
                name={"business_name"}
                value={mynOberStore.contactDetails.businessName}
                pattern={mynOberStore.merchant?.info?.default_contact_details?.delivery?.businessName?.pattern ?? ".+"}
                titleLabel={"label_business_name"}
                onChange={(value) => this.onContactDetailChanged("businessName", value)}
              />
            ) : null}
            {mynOberStore.merchant.askContactDetails ? (
              <FormField
                required={true}
                titleLabel={"label_name"}
                value={mynOberStore.contactDetails.name}
                pattern={".+"}
                name={"name"}
                disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.name?.disabled}
                hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.name?.hide}
                onChange={(value) => this.onContactDetailChanged("name", value)}
              />
            ) : null}

            <InputEmail
              onEmailChange={(email) => this.onContactDetailChanged("email", email)}
              email={mynOberStore.contactDetails.email}
              disabled={mynOberStore.merchant?.info?.default_contact_details?.delivery?.email?.disabled}
              hide={mynOberStore.merchant?.info?.default_contact_details?.delivery?.email?.hide}
            />

            <InputPhoneNumber
              country={platformFunctions.getCountry()}
              onChange={(phoneNumber) => this.onContactDetailChanged("phone", phoneNumber)}
              header="label_phone"
              value={mynOberStore.contactDetails.phone}
            />

            {mynOberStore.merchant.numberOfBlocks > 0 ? (
              <CustomTimestampField
                key={"DELIVERY" + this.state.refreshKey}
                max_ta_orders_blocks={this.state.max_ta_orders_blocks}
                orderType={"delivery"}
                timeslots={mynOberStore.merchant.timeslots}
                customField={{
                  key: "timestamp",
                  type: "timestamp",
                  required: true,
                  minimum_waiting_time: mynOberStore.merchant.deMinimumPickupMinutes,
                  max_weight_per_timeblock: mynOberStore.merchant.maxWeightPerTimeBlock,
                  asap: mynOberStore.merchant.asap,
                  label: "label_pickup",
                  value: this.state.deliveryTime,
                  order_rules: mynOberStore.merchant.orderRules
                    ? mynOberStore.merchant.orderRules
                    : {
                        repeated_patterns: [
                          {
                            numberOfDaysAhead:
                              mynOberStore.merchant.orderMaxNumberOfDaysAhead === 0
                                ? 0
                                : mynOberStore.merchant.orderMaxNumberOfDaysAhead - 1,
                            repeat: { minutes: mynOberStore.merchant.minutesPerBlock },
                          },
                        ],
                        // timestamps: [{ timestamp: 1613304000, repeat: { minutes: 15 }, numberOfDaysAhead: 0 }],
                      },
                }}
                openingHours={
                  mynOberStore.merchant.deliveryTimes != null
                    ? mynOberStore.merchant.deliveryTimes
                    : mynOberStore.merchant.openingHours
                }
                onChange={(value) => {
                  this.onPickupTimeChange(value * 1000);
                }}
              />
            ) : null}
            {/*<TimeInfoSelector*/}
            {/*  timeslots={mynOberStore.merchant.timeslots}*/}
            {/*  maxWeightPerTimeBlock={mynOberStore.merchant.maxWeightPerTimeBlock}*/}
            {/*  minimumPickupMinutes={mynOberStore.merchant.deMinimumPickupMinutes}*/}
            {/*  minutesPerBlock={mynOberStore.merchant.minutesPerBlock}*/}
            {/*  numberOfBlocks={mynOberStore.merchant.numberOfBlocks}*/}
            {/*  orderMaxNumberOfDaysAhead={mynOberStore.merchant.orderMaxNumberOfDaysAhead}*/}
            {/*  openingHours={*/}
            {/*    mynOberStore.merchant.deliveryTimes != null*/}
            {/*      ? mynOberStore.merchant.deliveryTimes*/}
            {/*      : mynOberStore.merchant.openingHours*/}
            {/*  }*/}
            {/*  onTimeChange={this.onPickupTimeChange}*/}
            {/*  time={this.state.deliveryTime}*/}
            {/*  asap={mynOberStore.merchant.asap}*/}
            {/*  setInitialTime={(time) => {*/}
            {/*    // this.onPickupTimeChange(time);*/}
            {/*    // this.setState({ deliveryTime: time });*/}
            {/*  }}*/}
            {/*  headerMessageId={"label_delivery"}*/}
            {/*  orderRules={mynOberStore.merchant.orderRules}*/}
            {/*/>*/}

            {mynOberStore.merchant.askRemark ? (
              <div>
                <Typography>{this.props.intl.formatMessage({ id: "any_remarks" })}</Typography>
                <TextField
                  margin={"dense"}
                  variant={"outlined"}
                  multiline={true}
                  rows={"5"}
                  placeholder={mynOberStore.merchant.info?.default_remark}
                  value={this.state.remark}
                  onChange={(e) => {
                    mynOberActions.updateDeliveryOrder({ remark: e.target.value });
                  }}
                />
              </div>
            ) : null}

            <PackagingInput
              userSelectedPackaging={this.state.userSelectedPackaging}
              onChange={this.onUserSelectedPackagingChange}
            />

            <InputPaymentMethod
              paymentMethods={this.paymentMethods}
              selectedPaymentMethod={this.state.paymentMethod}
              onChange={this.onPaymentMethodChange}
            />

            {mynOberStore.merchant.tip != null && (
              <TipSelector
                amount={mynOberStore.order.amount}
                onTipSelect={(tipAmount) => {
                  let deliveryOrder = mynOberStore.deliveryOrder;
                  deliveryOrder.tip = tipAmount;
                  mynOberActions.updateDeliveryOrder(deliveryOrder);
                }}
              />
            )}

            {this.state.deliveryCosts !== 0 ||
            this.state.paymentMethod.extraCosts !== 0 ||
            mynOberStore.merchant.tip != null ? (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_subtotal" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(mynOberStore.order.amount / 100.0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            ) : null}

            {mynOberStore.merchant.tip != null && mynOberStore.deliveryOrder.tip != null && (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_tip" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(mynOberStore.deliveryOrder.tip / 100.0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            )}

            {this.state.deliveryCosts !== 0 ? (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_delivery_costs" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(this.state.deliveryCosts / 100.0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            ) : null}
            {this.state.paymentMethod.extraCosts !== 0 || this.state.paymentMethod.extraPercentage !== 0 ? (
              <div className={"horizontal-div"}>
                <Typography>{this.props.intl.formatMessage({ id: "text_payment_costs" })}</Typography>
                <Typography>
                  {GetCurrency("€")}{" "}
                  {parseFloat(
                    this.state.paymentMethod.extraCosts / 100.0 +
                      (((mynOberStore.order.amount + this.state.deliveryCosts) / 100.0) *
                        this.state.paymentMethod.extraPercentage) /
                        100.0
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </Typography>
              </div>
            ) : null}
            <div className={"horizontal-div"}>
              <Typography>{this.props.intl.formatMessage({ id: "text_total" })}</Typography>
              <Typography>
                {GetCurrency("€")}{" "}
                {parseFloat(
                  (mynOberStore.order.amount +
                    mynOberStore.deliveryOrder.tip +
                    this.state.deliveryCosts +
                    this.state.paymentMethod.extraCosts +
                    ((mynOberStore.order.amount + mynOberStore.deliveryOrder.tip + this.state.deliveryCosts) / 100.0) *
                      this.state.paymentMethod.extraPercentage) /
                    100.0
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </Typography>
            </div>

            {mynOberStore.merchant.askMarketing ? (
              <FormControlLabel
                style={{ maxWidth: "331px" }}
                control={
                  <Checkbox
                    color={"primary"}
                    checked={this.state.acceptMarketing}
                    onChange={() => {
                      mynOberActions.updateDeliveryOrder({ acceptMarketing: !this.state.acceptMarketing });
                    }}
                  />
                }
                label={this.props.intl.formatMessage({ id: "mark_checkbox" })}
              />
            ) : null}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Link
                style={{ maxWidth: "331px" }}
                target={"_blank"}
                rel={"noopener noreferrer"}
                href={this.props.intl.formatMessage({ id: "privacy_policy_url" })}
              >
                {this.props.intl.formatMessage({ id: "text_vga_phone" })}
              </Link>
            </div>
            <div className={"filler"} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "10px",
              alignItems: "center",
              justifyContent: "space-evenly",
              paddingBottom: mynOberStore.isWidescreen ? "0" : "16px",
            }}
          >
            <Button
              style={{ fontSize: "1.1rem", margin: "0 8px" }}
              variant={"outlined"}
              onClick={this.props.history.goBack}
            >
              {this.props.intl.formatMessage({ id: "button_back" })}
            </Button>
            <Button
              id={"button-delivery-submit"}
              color={"primary"}
              style={{ fontSize: "1.1rem", margin: "0 8px" }}
              variant={"contained"}
              type={"submit"}
            >
              {this.props.intl.formatMessage({ id: "button_pay" })}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

DeliveryPage.propTypes = {
  headerMerchant: PropTypes.element,
};

DeliveryPage.contextType = DialogContext;

export default injectIntl(DeliveryPage);
