export class MynOberUser {
  constructor(deviceId, devicePass, firstAskNotification, firstTimeApp, refreshToken) {
    this._deviceId = deviceId;
    this._devicePass = devicePass;
    this._firstAskNotification = firstAskNotification;
    this._firstTimeApp = firstTimeApp;
    this._refreshToken = refreshToken;
  }

  get deviceId() {
    return this._deviceId;
  }

  get devicePass() {
    return this._devicePass;
  }

  get firstAskNotification() {
    return this._firstAskNotification;
  }

  set firstAskNotification(value) {
    this._firstAskNotification = value;
  }

  get firstTimeApp() {
    return this._firstTimeApp;
  }

  set firstTimeApp(value) {
    this._firstTimeApp = value;
  }

  get refreshToken() {
    return this._refreshToken;
  }

  set refreshToken(value) {
    this._refreshToken = value;
  }
}
