import React, { Component } from "react";
import PropTypes from "prop-types";
import "./ViewOrderModifierarticle.css";
import { OrderModifierarticle } from "../../utils/order/OrderModifierarticle";
import { OrderModifiergroup } from "../../utils/order/OrderModifiergroup";
import Typography from "@material-ui/core/Typography";

class ViewOrderModifierarticle extends Component {
  render() {
    return (
      <div className={"view-order-modifierarticle"}>
        <Typography>
          {this.props.orderModifiergroup.numerable ? this.props.orderModifierarticle.count + "x " : null}
          {this.props.orderModifierarticle.name}
        </Typography>
      </div>
    );
  }
}

ViewOrderModifierarticle.propTypes = {
  orderModifiergroup: PropTypes.instanceOf(OrderModifiergroup).isRequired,
  orderModifierarticle: PropTypes.instanceOf(OrderModifierarticle).isRequired,
};

export default ViewOrderModifierarticle;
