import nl from "./nl.json";
import es from "./es.json";
import de from "./de.json";
import en from "./en.json";

window.messages = {
  de: de,
  en: en,
  es: es,
  nl: nl,
};
export default window.messages;
