import * as Sentry from "@sentry/browser";

export function captureException(error, options) {
  if (options == null) {
    Sentry.captureException(error);
  } else {
    Sentry.withScope((scope) => {
      Object.keys(options).forEach((key) => {
        scope.setTag(key, options[key]);
      });
      Sentry.captureException(error);
    });
  }
  console.log("error send");
}

export function config(dsn, environment, version) {
  Sentry.init({
    dsn: dsn,
    environment: environment,
    release: version,
  });
}

export function setUser(deviceId, isHandheld) {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: deviceId });
    scope.setTag("handheld", isHandheld);
  });
}
