import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import "./TaReceiptView.css";
import * as requestFunctions from "../../../utils/functions/RequestFunctions";
import { Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import TaReceiptDetailedView from "./../takeaway/TaReceiptDetailedView";
import TaReceipt from "../../../utils/receipt/TaReceipt";
import taHandheldStore from "../../../utils/flux/TaHandheldStore";
import { getLocalDateFromTimestampFormattedString } from "../../../utils/functions/HelperFunctions";
import DialogError from "../../dialogs/DialogError";
import { DialogContext } from "../../dialogs/DialogProvider";
import DialogOk from "../../dialogs/DialogOk";
import DialogOkOrCancel from "../../dialogs/DialogOkOrCancel";
import { injectIntl } from "react-intl";
import Grid from "@material-ui/core/Grid";
import DialogCheckoutTakeawayOrder from "../../dialogs/DialogCheckoutTakeawayOrder";

class TaReceiptView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked: localStorage.getItem("tareceipt-checked-" + this.props.taReceipt.id) === "true",
    };
  }

  render() {
    return (
      <Grid
        container
        spacing={0}
        className={"ta-receipt-view"}
        onClick={(e) => {
          const [openDialog] = this.context;
          openDialog({
            children: <TaReceiptDetailedView taReceipt={this.props.taReceipt} />,
            fullWidth: true,
            maxWidth: "lg",
          });
        }}
      >
        <Grid item xs={1}>
          <Typography style={{ fontWeight: 800 }} noWrap>
            T{this.props.taReceipt.number}
            {taHandheldStore.taMerchants[this.props.taReceipt.taMerchantId].isTables
              ? ", " + this.props.intl.formatMessage({ id: "table" }) + " " + this.props.taReceipt.tableId
              : null}
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checked}
                onChange={(e) => {
                  localStorage.setItem("tareceipt-checked-" + this.props.taReceipt.id, e.target.checked);
                  this.setState({ checked: e.target.checked });
                  e.stopPropagation();
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={2}>
          {this.props.taReceipt.contactInformation != null && this.props.taReceipt.contactInformation.name != null ? (
            <Typography style={{ fontWeight: 800 }} noWrap>
              {this.props.taReceipt.contactInformation.name}
            </Typography>
          ) : null}
          {this.props.taReceipt.contactInformation != null &&
          this.props.taReceipt.contactInformation.address != null ? (
            <Typography style={{ fontWeight: 800 }} noWrap>
              {this.props.taReceipt.contactInformation.address}
            </Typography>
          ) : null}
        </Grid>

        {taHandheldStore.taMerchants[this.props.taReceipt.taMerchantId].isMynbonnenMerchant && (
          <Grid item xs={3}>
            <Button
              style={{ whiteSpace: "nowrap" }}
              variant={"outlined"}
              color={"primary"}
              onClick={(e) => {
                e.stopPropagation();
                const [openDialog] = this.context;
                openDialog({
                  children: (
                    <DialogCheckoutTakeawayOrder
                      taReceipt={this.props.taReceipt}
                      taMerchant={taHandheldStore.taMerchants[this.props.taReceipt.taMerchantId]}
                      title={"alert_title_warning"}
                      content={"alert_text_sure"}
                      onOkClicked={(printTakeawayBill, paymentMethod) => {
                        requestFunctions
                          .taReceiptClose(this.props.taReceipt.id, printTakeawayBill, paymentMethod)
                          .then(() => {
                            const [openDialog] = this.context;
                            openDialog({
                              children: (
                                <DialogOk
                                  title={"alert_title_success"}
                                  content={"alert_text_success_payment_handheld"}
                                />
                              ),
                            });
                          })
                          .catch((text) => {
                            const [openDialog] = this.context;
                            openDialog({ children: <DialogError content={text} /> });
                          });
                      }}
                    />
                  ),
                });
              }}
            >
              {this.props.intl.formatMessage({ id: "PRINT_CLOSE" })}
            </Button>
          </Grid>
        )}
        <Grid item xs={2}>
          {taHandheldStore.taMerchants[this.props.taReceipt.taMerchantId].isMynbonnenMerchant &&
            !this.props.taReceipt.isPaid && (
              <Button
                variant={"outlined"}
                color={"primary"}
                onClick={(e) => {
                  e.stopPropagation();
                  const [openDialog] = this.context;
                  openDialog({
                    children: (
                      <DialogOkOrCancel
                        title={"alert_title_warning"}
                        content={"alert_text_sure"}
                        onOkClicked={() => {
                          requestFunctions
                            .taReceiptVoid(this.props.taReceipt.id)
                            .then(() => {
                              const [openDialog] = this.context;
                              openDialog({
                                children: (
                                  <DialogOk
                                    title={"alert_title_success"}
                                    content={"alert_text_success_payment_handheld"}
                                  />
                                ),
                              });
                            })
                            .catch((text) => {
                              const [openDialog] = this.context;
                              openDialog({ children: <DialogError content={text} /> });
                            });
                        }}
                      />
                    ),
                  });
                }}
              >
                {this.props.intl.formatMessage({ id: "CANCEL" })}
              </Button>
            )}
        </Grid>

        <Grid item xs={2}>
          <Typography noWrap>
            {this.props.taReceipt.pickupTimestamp === 0
              ? this.props.intl.formatMessage({ id: "option_possible" })
              : getLocalDateFromTimestampFormattedString(this.props.taReceipt.pickupTimestamp)}
          </Typography>
          <Typography noWrap>
            {this.props.taReceipt.distance?.length > 0 ? this.props.taReceipt.distance + " km" : ""}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography noWrap>{this.props.taReceipt.receiptType}</Typography>
          <Typography noWrap>{this.props.taReceipt.platform}</Typography>
        </Grid>
      </Grid>
    );
  }
}

TaReceiptView.propTypes = {
  taReceipt: PropTypes.instanceOf(TaReceipt).isRequired,
};

TaReceiptView.contextType = DialogContext;

export default withRouter(injectIntl(TaReceiptView));
