import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import FormField from "../../FormField";

class InputEmail extends Component {
  render() {
    return (
      <FormField
        margin={"dense"}
        variant={"outlined"}
        onChange={(value) => {
          this.props.onEmailChange(value);
        }}
        name={"email"}
        titleLabel={"label_email"}
        value={this.props.email}
        disabled={this.props.disabled}
        hide={this.props.hide}
        required={true}
        type={"email"}
      />
    );
  }
}

InputEmail.propTypes = {
  onEmailChange: PropTypes.func,
  email: PropTypes.string,
};

export default injectIntl(InputEmail);
