import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";
import mynOberStore from "../../utils/flux/MynOberStore";
import ViewArticle2Handheld from "./ViewArticle2Handheld";

export const articlegroupViewId = "articlegroup-";

function ViewArticlegroup2(props) {
  return (
    <div
      className={"container-articlegroup"}
      id={articlegroupViewId + props.index}
      style={{ marginLeft: 8, marginRight: 8 }}
    >
      <Typography
        style={{ fontSize: 20, margin: "0 25px", flex: 1, overflow: "hidden", paddingBottom: 3 }}
        noWrap={true}
      >
        {props.articlegroup.articlegroup.name}
      </Typography>

      <Grid container spacing={2}>
        {props.articlegroup.articles.map((article) => {
          return (
            <Grid item xs={12} sm={6} lg={3} xl={3} key={article.id}>
              {mynOberStore.isHandheld() ? <ViewArticle2Handheld article={article} /> : null}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

ViewArticlegroup2.propTypes = {
  articlegroup: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ViewArticlegroup2;
