export class Articlegroup {
  /**
   *
   * @param {number} id
   * @param {string} name
   * @param {number} priority
   */
  constructor(id, name, priority, handheld_bg_color, articles, articleIds) {
    this.id = id;
    this.name = name;
    this.priority = priority;
    this.handheld_bg_color = handheld_bg_color;
    this.articles = articles;
    this.articleIds = articleIds;
  }
}
