import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";
import ReactCodeInput from "../ReactCodeInput";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogPasswordProtectedAction(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();
  const password = props.password;

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        <ReactCodeInput
          type={"number"}
          inputMode={"numeric"}
          fields={password.length}
          onChange={(value) => {
            if (value === password) {
              props.onPasswordOk();
              closeDialog();
            }
          }}
        />
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
            }}
          >
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

export default withIntl(DialogPasswordProtectedAction);
