import { Merchant } from "./Merchant";

export class SpMerchant extends Merchant {
  constructor(
    id,
    merchantId,
    name,
    timezone,
    countryCodes,
    currency,
    location,
    isOpen,
    showAllergens,
    showDescription,
    openingHours,
    styling,
    manuallyClosed,
    paymentMethods,
    tip,
    menuTags,
    canMove,
    canNotification,
    canPay,
    canPayHandheld,
    canPayOnline,
    canWaiter,
    canShareOrder,
    useGeoLocation,
    imageMd5,
    sharedOrder,
    floorPlans,
    activeMArticleIds,
    activePlus,
    mustBuildupReceipt,
    isOrdering,
    enableRemarkInOrdergroup,
    askContactDetails,
    maximumReceiptsPerTable,
    salesAreaName,
    image,
    customFields,
    guestRegistration,
    info,
    shareOrderSeconds,
    activeMArticlePlus,
    shieldedReceipt,
    useMenuTags,
    usePincode,
    isMynbonnenMerchant,
  ) {
    super(
      id,
      merchantId,
      name,
      timezone,
      countryCodes,
      currency,
      location,
      isOpen,
      showAllergens,
      showDescription,
      openingHours,
      styling,
      manuallyClosed,
      paymentMethods,
      isMynbonnenMerchant,
      tip,
      menuTags,
      guestRegistration,
      info
    );

    this._canMove = canMove;
    this._canNotifications = canNotification;
    this._canPay = canPay;
    this._canPayHandheld = canPayHandheld;
    this._canPayOnline = canPayOnline;
    this._canWaiter = canWaiter;
    this._canShareOrder = canShareOrder;
    this._useGeoLocation = useGeoLocation;
    this._imageMd5 = imageMd5;
    this._sharedOrder = sharedOrder;
    this.floorPlans = floorPlans;
    this.activeMArticleIds = activeMArticleIds;
    this.activePlus = activePlus;
    this.mustBuildupReceipt = mustBuildupReceipt;
    this.isOrdering = isOrdering;
    this.enableRemarkInOrdergroup = enableRemarkInOrdergroup;
    this.askContactDetails = askContactDetails;
    this.maximumReceiptsPerTable = maximumReceiptsPerTable;
    this.salesAreaName = salesAreaName;
    this.image = image;
    this.customFields = customFields;
    this.guestRegistration = guestRegistration;
    this.info = info;
    this._shareOrderSeconds = shareOrderSeconds;
    this.activeMArticlePlus = activeMArticlePlus;
    this.shieldedReceipt = shieldedReceipt;
    this.useMenuTags = useMenuTags;
    this.usePincode = usePincode;
  }

  /**
   *
   * @return {boolean}
   */
  get canMove() {
    return this._canMove;
  }

  /**
   *
   * @return {boolean}
   */
  get canNotifications() {
    return this._canNotifications;
  }

  /**
   *
   * @return {boolean}
   */
  get canPay() {
    return this._canPay;
  }

  /**
   *
   * @return {boolean}
   */
  get canPayHandheld() {
    return this._canPayHandheld;
  }

  /**
   *
   * @return {boolean}
   */
  get canPayOnline() {
    return this._canPayOnline;
  }

  /**
   *
   * @return {boolean}
   */
  get canWaiter() {
    return this._canWaiter;
  }

  /**
   *
   * @returns {boolean}
   */
  get canShareOrder(): boolean {
    return this._canShareOrder;
  }

  /**
   *
   * @return {boolean}
   */
  get useGeoLocation() {
    return this._useGeoLocation;
  }

  /**
   *
   * @returns {string}
   */
  get imageMd5() {
    return this._imageMd5;
  }

  /**
   *
   * @returns {Array}
   */
  get sharedOrder(): Array {
    return this._sharedOrder;
  }

  get shareOrderSeconds() {
    return this._shareOrderSeconds;
  }
}
