import { Button, DialogContent, DialogTitle } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import withIntl from "../../utils/withIntl";
import { makeStyles } from "@material-ui/core/styles";
import { useDialog } from "./DialogProvider";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    margin: "8px",
  },
  div: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function DialogOkOrCancel(props) {
  const classes = useStyles();
  const [, closeDialog] = useDialog();

  return (
    <>
      <DialogTitle>{props.intl.formatMessage({ id: props.title })}</DialogTitle>
      <DialogContent>
        <h3>{props.intl.formatMessage({ id: props.content })}</h3>
        <div className={classes.div}>
          <Button
            className={classes.button}
            variant={"outlined"}
            onClick={() => {
              closeDialog();
              props.onOkClicked();
            }}
          >
            {props.intl.formatMessage({ id: "button_ok" })}
          </Button>
          <Button className={classes.button} variant={"outlined"} onClick={closeDialog}>
            {props.intl.formatMessage({ id: "button_cancel" })}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}

DialogOkOrCancel.propTypes = {
  onOkClicked: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
};

export default withIntl(DialogOkOrCancel);
